import { DataGrid } from '@mui/x-data-grid'
import './datatable.scss'
import { TableContainer, Box, Checkbox, Button } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const DatatableAssignRoles = ({ rows, isEditing, toAssign, updateAssigns, setIsEditingAssign }) => {
  const [roles, setRoles] = useState(rows ?? [])
  const { t } = useTranslation(['common'])

  const handleCheckChange = (checked, obj) => {
    let aux = [...roles]
    if (checked) {
      aux.push(obj)
    } else {
      aux = aux.filter((item) => item._id !== obj._id)
    }
    setRoles([...aux])
  }

  useEffect(() => {
    setRoles(rows)
  }, [rows])

  const columns1 = [
    {
      field: '_id',
      headerName: 'ID',
      flex: 1,
      minWidth: 100,
      hide: true,
    },
    {
      field: 'roleName',
      headerName: t('common:role'),
      flex: 1,
      minWidth: 100,
    },
  ]

  const columns2 = [
    {
      field: '_id',
      headerName: 'ID',
      flex: 1,
      minWidth: 100,
      hide: true,
    },
    {
      field: 'checkbox',
      headerName: t('common:action'),
      renderCell: (params) => {
        return (
          <Box>
            <Checkbox
              checked={roles.find((d) => d._id === params.row._id) !== undefined || false}
              onChange={(e) => {
                handleCheckChange(e.target.checked, params.row)
              }}
            />
          </Box>
        )
      },
    },
    {
      field: 'roleName',
      headerName: t('common:role'),
      flex: 1,
      minWidth: 100,
    },
  ]

  return (
    <Box>
      {!isEditing && (
        <TableContainer>
          <DataGrid
            getRowId={(row) => row._id || row.id}
            rows={Array.isArray(roles) && roles.length ? roles : []}
            columns={columns1}
            hideFooter={true}
            autoHeight={true}
          />
        </TableContainer>
      )}
      {isEditing && (
        <Box className="assign-users">
          <TableContainer>
            <DataGrid
              getRowId={(row) => row._id || row.id}
              rows={Array.isArray(toAssign) && toAssign.length ? toAssign : []}
              columns={columns2}
              hideFooter={true}
              autoHeight={true}
            />
          </TableContainer>
          <Box className="assign-users-button">
            <Button
              onClick={() => {
                setIsEditingAssign(false)
                updateAssigns(roles)
              }}
              size="small"
              variant="contained"
            >
              {t('common:save')}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default DatatableAssignRoles

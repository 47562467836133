import { Box, InputLabel, Paper, TextField, Typography } from '@mui/material'
import '../kytManualCheck.scss'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RenderTooltip } from '../../../../components/utilities/RenderTooltip'

const AchDetails = ({ setPaymentDetails, isReset, setIsReset }) => {
  const [routingNumber, setRoutingNumber] = useState('')
  const [accountNumber, setAccountNumber] = useState('')
  const [bankName, setBankName] = useState('')
  const [beneficiaryName, setBeneficiaryName] = useState('')
  const [name, setName] = useState('')

  const { t } = useTranslation(['common', 'messages'])
  const [change, setChange] = useState(false)

  useEffect(() => {
    if (change) {
      const body = {
        method: 'ACH',
        ...(routingNumber && { routingNumber }),
        ...(accountNumber && { accountNumber }),
        ...(bankName && { bankName }),
        ...(beneficiaryName && { beneficiaryName }),
        ...(name && { name }),
      }

      setPaymentDetails(body)
      setChange(false)
    }
  }, [change])

  useEffect(() => {
    if (isReset) {
      setRoutingNumber('')
      setAccountNumber('')
      setBankName('')
      setBeneficiaryName('')
      setName('')
      setIsReset(false)
    }
  }, [isReset])

  return (
    <Paper elevation={0} className="kyt-form">
      <Box className="kyt-form-pair">
        <Box className="required">
          <InputLabel>{t('common:accountNumber')}</InputLabel>
          <Typography variant="subtitle3">({t('common:required')})</Typography>

          <RenderTooltip title={'Bank account number of the individual.'} />
        </Box>
        <TextField
          required
          fullWidth
          type="text"
          value={accountNumber}
          onChange={(event) => {
            setAccountNumber(event.target.value)
            setChange(true)
          }}
          placeholder={`${t('common:insert')} ${t('common:accountNumber')} ${t('here')}`}
        />
      </Box>
      <Box className="kyt-form-pair">
        <Box className="required">
          <InputLabel>{t('common:routingNumber')}</InputLabel>
          <Typography variant="subtitle3">({t('common:required')})</Typography>
          <RenderTooltip title={'Routing number of the bank.'} />
        </Box>

        <TextField
          required
          fullWidth
          type="text"
          value={routingNumber}
          onChange={(event) => {
            setRoutingNumber(event.target.value)
            setChange(true)
          }}
          placeholder={`${t('common:insert')} ${t('common:routingNumber')} ${t('here')}`}
        />
      </Box>
      <Box className="kyt-form-pair">
        <Box className="kyt-form-pair-label">
          <InputLabel>{t('common:bankName')}</InputLabel>
          <RenderTooltip title={'Name of the bank.'} />
        </Box>
        <TextField
          fullWidth
          type="text"
          value={bankName}
          onChange={(event) => {
            setBankName(event.target.value)
            setChange(true)
          }}
          placeholder={`${t('common:insert')} ${t('common:bankName')} ${t('here')}`}
        />
      </Box>
      <Box className="kyt-form-pair">
        <Box className="kyt-form-pair-label">
          <InputLabel>{t('common:beneficiaryName')}</InputLabel>
          <RenderTooltip title={'Beneficiary name of the account.'} />
        </Box>
        <TextField
          fullWidth
          type="text"
          value={beneficiaryName}
          onChange={(event) => {
            setBeneficiaryName(event.target.value)
            setChange(true)
          }}
          placeholder={`${t('common:insert')} ${t('common:beneficiaryName')} ${t('here')}`}
        />
      </Box>
      <Box className="kyt-form-pair">
        <Box className="kyt-form-pair-label">
          <InputLabel>{t('common:name')}</InputLabel>
          <RenderTooltip title={'Name of the account holder.'} />
        </Box>
        <TextField
          fullWidth
          type="text"
          value={name}
          onChange={(event) => {
            setName(event.target.value)
            setChange(true)
          }}
          placeholder={`${t('common:insert')} ${t('common:name')} ${t('here')}`}
        />
      </Box>
    </Paper>
  )
}

export default AchDetails

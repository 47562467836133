import { useNavigate } from 'react-router-dom'
import './workflows.scss'
import { Box, Button, TextField } from '@mui/material'
import { useState } from 'react'

const WorkflowsAi = () => {
  const navigate = useNavigate()
  const [prompt, setPrompt] = useState('')
  const [id, setId] = useState('F-143')
  return (
    <Box className="workflow-datatable">
      <Box className="workflow-wrapper">
        <Box className="workflow-head">
          <h2 className="workflow-text">Describe your flow</h2>
          <Box></Box>
        </Box>
        <Box className="workflow-subtitle">
          <h4 className="workflow-text">
            In one or two sentenses, describe what you would like your flow to do (or try an example below) and we will
            create a DRAFT FLOW for you.
          </h4>
          <h4 className="workflow-text">Please keep in mind that this feature is still in BETA.</h4>
          <hr />
        </Box>
        <Box className="button-bar">
          <Button
            className="button-config orange"
            onClick={() => {
              setPrompt(
                'Please provide a step-by-step flowchart of the process for upgrading users based on MCC and CNAE codes in Brazil. Include key decision points, data sources, and actions at each stage of the process. Additionally, highlight any legal compliance steps and considerations for data integrity and security.',
              )
              setId('F-143')
            }}
          >
            MCC Upgrade
          </Button>
          <Button
            className="button-config purple"
            onClick={() => {
              setPrompt(
                'Provide a detailed flowchart outline illustrating how groups are created, triggers are defined, and validations are applied. Include clear steps, decision points, and dependencies in the process. Highlight the criteria for creating groups, the events or conditions that trigger actions, and the validation procedures used to ensure data accuracy and compliance.',
              )
              setId('F-126')
            }}
          >
            Decisions per group
          </Button>
          <Button
            className="button-config blue"
            onClick={() => {
              setPrompt(
                'I am currently using Metamap for KYC process, and i need to enhance its performance specifically for Brazil while also implementing additional security layers',
              )
              setId('F-353')
            }}
          >
            third party service improvement
          </Button>
          <Button
            className="button-config pink"
            onClick={() => {
              setPrompt(
                'I am currently using Metamap for KYC process, and i need to enhance its performance specifically for Brazil while also implementing additional security layers',
              )
              setId('F-342')
            }}
          >
            Onboarding Improvement
          </Button>
        </Box>
        <Box>
          <TextField
            fullWidth
            multiline
            type="string"
            size="small"
            value={prompt}
            placeholder="Write your promt here..."
            onChange={(e) => setPrompt(e.target.value)}
          />
        </Box>
        <Box className="generate">
          <Button color="secondary" onClick={() => navigate(`/workflows/${id}`)}>
            Generate a flow
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default WorkflowsAi

import { LoadingButton } from "@mui/lab";
import { Box, TextField, Typography } from "@mui/material";
import { useTranslation } from 'react-i18next'
import { useFindUserMasterQuery, useUpdateUserMasterMutation } from '../../../services/guenoApi'
import { useSelector } from 'react-redux'
import { useEffect, useState } from "react";
import AlertFetchSpinnerAutoClose from "../../../components/common/alertFetchSpinner/AlertFetchSpinnerAutoClose";

const KybNotificationsSettings = ({ setIsProcessing }) => {
    const { emailClient } = useSelector((state) => state.login);
    const { t } = useTranslation(['common', 'messages']);
    const { data } = useFindUserMasterQuery({ emailClient });
    const [updateUserMaster, { isLoading, isError }] = useUpdateUserMasterMutation({ fixedCacheKey: 'userMasterUpdate' });
    const [monthsExpirationLowLevel, setMonthsExpirationLowLevel] = useState(data?.monthsExpirationLowLevel || 18);
    const [monthsExpirationMediumLevel, setMonthsExpirationMediumLevel] = useState(data?.monthsExpirationMediumLevel || 12);
    const [monthsExpirationHighLevel, setMonthsExpirationHighLevel] = useState(data?.monthsExpirationHighLevel || 6);
    const [monthsExpirationVeryHighLevel, setMonthsExpirationVeryHighLevel] = useState(data?.monthsExpirationVeryHighLevel || 3);

    useEffect(() => {
        if (data) {
            setMonthsExpirationLowLevel(data.monthsExpirationLowLevel)
            setMonthsExpirationMediumLevel(data.monthsExpirationMediumLevel)
            setMonthsExpirationHighLevel(data.monthsExpirationHighLevel)
            setMonthsExpirationVeryHighLevel(data.monthsExpirationVeryHighLevel)
        }
    }, [data])


    const handleSubmit = async () => {
        setIsProcessing(true)

        try {
            const { data } = await updateUserMaster({
                emailClient,
                body: {
                    monthsExpirationLowLevel,
                    monthsExpirationMediumLevel,
                    monthsExpirationHighLevel,
                    monthsExpirationVeryHighLevel
                }
            })
            if (data.success && data.data) {
                setIsProcessing(false)
            } else {
                throw new Error(data?.responseBody?.message || data?.info || 'Unexpected')
            }
        } catch (err) {
            setIsProcessing(false)
        }
    }

    return (
        < Box display="flex" flexDirection="column" gap="2rem" >
            <AlertFetchSpinnerAutoClose message={!isError ? 'Update success.' : t('messages:unexpectedErrorValues')} error={isError} spinner={isLoading} />
            <Typography variant="title">{t('common:settings')}</Typography>
            <Box mt={2} className="security-flex" display="flex" flexDirection="row" justifyContent="center" gap="2.5rem">
                <Box className="security-flex-label" display="flex" flexDirection="row" alignItems="center" gap="1rem">
                    <Typography variant="subtitle" fontSize="small">
                        {t('common:expirationDateForLowRisk')}
                    </Typography>
                    <TextField
                        type="number"
                        placeholder={`${t('common:insert')} ${t('common:months')}`}
                        size="small"
                        style={{ width: '9.375rem' }}
                        value={monthsExpirationLowLevel}
                        onChange={(event) => {
                            const value = Math.max(1, Number(event.target.value));
                            setMonthsExpirationLowLevel(value);
                        }}
                    />
                </Box>
                <Box className="security-flex-label" display="flex" flexDirection="row" alignItems="center" gap="1rem">
                    <Typography variant="subtitle" fontSize="small">
                        {t('common:expirationDateForMediumRisk')}
                    </Typography>
                    <TextField
                        type="number"
                        placeholder={`${t('common:insert')} ${t('common:months')}`}
                        size="small"
                        style={{ width: '9.375rem' }}
                        value={monthsExpirationMediumLevel}
                        onChange={(event) => {
                            const value = Math.max(1, Number(event.target.value));
                            setMonthsExpirationMediumLevel(value);
                        }}
                    />
                </Box>
                <Box className="security-flex-label" display="flex" flexDirection="row" alignItems="center" gap="1rem">
                    <Typography variant="subtitle" fontSize="small">
                        {t('common:expirationDateForHighRisk')}
                    </Typography>
                    <TextField
                        type="number"
                        placeholder={`${t('common:insert')} ${t('common:months')}`}
                        size="small"
                        style={{ width: '9.375rem' }}
                        value={monthsExpirationHighLevel}
                        onChange={(event) => {
                            const value = Math.max(1, Number(event.target.value));
                            setMonthsExpirationHighLevel(value);
                        }}
                    />
                </Box>
                <Box className="security-flex-label" display="flex" flexDirection="row" alignItems="center" gap="1rem">
                    <Typography variant="subtitle" fontSize="small">
                        {t('common:expirationDateForVeryHighRisk')}
                    </Typography>
                    <TextField
                        type="number"
                        placeholder={`${t('common:insert')} ${t('common:months')}`}
                        size="small"
                        style={{ width: '9.375rem' }}
                        value={monthsExpirationVeryHighLevel}
                        onChange={(event) => {
                            const value = Math.max(1, Number(event.target.value));
                            setMonthsExpirationVeryHighLevel(value);
                        }}
                    />
                </Box>
            </Box>
            <Box className="modal-filter-buttons" display="flex" justifyContent="flex-end" style={{ marginRight: '1rem' }}>
                <LoadingButton loading={isLoading} variant="contained" size="small"
                    onClick={handleSubmit}>
                    <span>{t('common:save')}</span>
                </LoadingButton>
            </Box>
        </Box >
    )

}

export default KybNotificationsSettings

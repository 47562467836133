import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import './renderTooltip.scss'
import { Tooltip } from '@mui/material'

export const RenderTooltip = ({ title }) => {
  return (
    <Tooltip title={title}>
      <InfoOutlinedIcon fontSize="small" className="tooltip-info-icon" />
    </Tooltip>
  )
}

import { LoadingButton } from '@mui/lab'
import { Box, InputLabel, TextField } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const KybNewCommentModal = ({ onSubmit }) => {
  const { t } = useTranslation(['common', 'messages'])
  const [newCommentMessage, setNewCommentMessage] = useState('')

  return (
    <>
      <Box className="modal-box">
        <InputLabel>{t('common:comment')}:</InputLabel>
        <TextField
          multiline
          placeholder={t('messages:minCharacters')}
          required
          onChange={(e) => setNewCommentMessage(e.target.value)}
        />
      </Box>
      <Box className="modal-button">
        <LoadingButton
          size="small"
          color="secondary"
          variant="contained"
          disabled={newCommentMessage.length < 5}
          onClick={() => onSubmit(newCommentMessage)}
        >
          {t('common:publishComment')}
        </LoadingButton>
      </Box>
    </>
  )
}

export default KybNewCommentModal

import React, { useState } from 'react'
import { Box, Button, Fab, InputLabel, MenuItem, Modal, Paper, Select, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { CloseOutlined } from '@mui/icons-material'
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { FileUploader } from 'react-drag-drop-files'
import { RenderTooltip } from '../../../../components/utilities/RenderTooltip'

function ModalCreteBatch({ showCreateBatch, setShowCreateBatch, handleCreateBatch }) {
  const { t } = useTranslation(['common', 'messages'])
  const [batchType, setBatchType] = useState('CONSUMER')
  const [file, setFile] = useState('')

  const handleModalClose = () => {
    setBatchType('CONSUMER')
    setFile(null)
    setShowCreateBatch(false)
  }

  const handleSubmit = async () => {
    handleCreateBatch(batchType, file)
    setShowCreateBatch(false)
  }

  const canSend = () => !file || !batchType

  return (
    <Modal open={showCreateBatch} onClose={() => handleModalClose()}>
      <Box className="modal" gap="1.5rem">
        <Box className="modal-top">
          <Typography variant="title">{t('common:CREATE_BATCH')}</Typography>‹
          <Fab variant="close" onClick={() => handleModalClose()}>
            <CloseOutlined />
          </Fab>
        </Box>
        <Box className="modal-box-lists" padding="0">
          <Box className="lists-box">
            <Box className="batch-label">
              <InputLabel>{t('common:BATCH_TYPE')}</InputLabel>
              <RenderTooltip title={t('common:BATCH_TYPE_TOOLTIP')} />
            </Box>
            <Select
              value={batchType}
              onChange={(event) => {
                if (event.target.value) {
                  setBatchType(event.target.value)
                } else setBatchType(null)
              }}
            >
              {['CONSUMER', 'BUSINESS', 'TRANSACTION'].map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box className="lists-box">
            <Box className="batch-label">
              <Typography variant="title2">{t('common:BATCH_FILES_MODELS')}</Typography>
              <RenderTooltip title={t('common:BATCH_FILES_MODELS_TOOLTIP')} />
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="space-evenly" gap="0.5rem">
              <Button
                variant="outlined"
                onClick={() =>
                  window.open(
                    'https://docs.google.com/spreadsheets/d/1cYMGXqNxLO3YzzsFnlysQsH99kYR4n77zDRrH_3tWg8/edit?usp=sharing',
                    '_blank',
                    'rel=noopener noreferrer',
                  )
                }
                startIcon={<InsertLinkOutlinedIcon />}
                sx={{ paddingInline: '10px' }}
              >
                {t('common:CONSUMER_BATCH')}
              </Button>
              <Button
                paddingX="10px"
                variant="outlined"
                onClick={() =>
                  window.open(
                    'https://docs.google.com/spreadsheets/d/17TaSHPE3TDjD9AKSXpxiKEVOqfVjG5QvPaCMOmXbDyI/edit?usp=sharing',
                    '_blank',
                    'rel=noopener noreferrer',
                  )
                }
                startIcon={<InsertLinkOutlinedIcon />}
                sx={{ paddingInline: '10px' }}
              >
                {t('common:BUSINESS_BATCH')}
              </Button>
              <Button
                paddingX="10px"
                variant="outlined"
                onClick={() =>
                  window.open(
                    'https://docs.google.com/spreadsheets/d/1bJPV2ybGjCLKuKq42jhPUjJ70qFfQ_qzaK1QfCcKcAQ/edit?usp=sharing',
                    '_blank',
                    'rel=noopener noreferrer',
                  )
                }
                startIcon={<InsertLinkOutlinedIcon />}
                sx={{ paddingInline: '10px' }}
              >
                {t('common:TRANSACTION_BATCH')}
              </Button>
            </Box>
          </Box>
          <Box className="lists-box" width="100%">
            <Box className="batch-label">
              <Typography variant="title2">{t('file')}</Typography>
              <RenderTooltip title={t('common:BATCH_FILE_TOOLTIP')} />
            </Box>
            <FileUploader
              handleChange={setFile}
              name="file"
              label={t('common:BATCH_FILE_UPLOADER_LABEL')}
              types={['csv']}
            >
              <Paper elevation={0} variant="rootDashed" className="draggable-main draggable-small">
                <Box className="draggable-icon" />
                <Typography variant="title2">{t('common:BATCH_FILE_UPLOADER_LABEL')}</Typography>
                <Box>
                  <Typography variant="subtitle4">{t('common:or')} </Typography>
                  <Typography sx={{ textDecoration: 'underline' }} variant="subtitle4" color="secondary">
                    {t('common:browseMedia')}
                  </Typography>
                </Box>
              </Paper>
            </FileUploader>
            <Typography variant="subtitle4">{t('common:supportedMedia')}: CSV.</Typography>
            {file && (
              <Paper elevation={0} variant="colorPrimary" className="onb-file">
                <Box className="onb-file-description">
                  <Box className="color-button" sx={{ background: '#E4DDFF' }}>
                    <Typography variant="subtitle1" sx={{ color: '#7E65D5' }}>
                      CSV
                    </Typography>
                  </Box>

                  <Typography
                    variant="h6"
                    title={file.name}
                    overflow="hidden"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                    maxWidth="160px"
                  >
                    {file.name}
                  </Typography>
                </Box>
                <Box className="onb-file-icons">
                  <Button variant="outlinedBlank" onClick={() => setFile('')}>
                    <DeleteOutlineIcon />
                  </Button>
                </Box>
              </Paper>
            )}
          </Box>
        </Box>
        <Box className="lists-buttons">
          <Button size="small" variant="outlinedGrey" onClick={() => handleModalClose()}>
            {t('common:cancel')}
          </Button>
          <Button disabled={canSend()} size="small" variant="contained" type="submit" onClick={() => handleSubmit()}>
            {t('common:save')}
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default ModalCreteBatch

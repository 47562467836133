import { useParams } from 'react-router-dom'
import './roles.scss'
import { useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'
import IconButton from '@mui/material/IconButton'
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Paper,
} from '@mui/material'
import CreateIcon from '@mui/icons-material/Create'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import ClearIcon from '@mui/icons-material/Clear'
import { useRolesByEmailClient } from '../../pages-hooks/useRoles'
import Spinner from '../../../components/common/spinner/spinner'
import AlertFetchSpinner from '../../../components/common/alertFetchSpinner/alertFetchSpinner'
import { useTranslation } from 'react-i18next'
import Select from '@mui/material/Select'
import OutlinedInput from '@mui/material/OutlinedInput'
import MenuItem from '@mui/material/MenuItem'
import { MenuProps } from '../../../components/utilities/MenuProps'
import DeleteIcon from '@mui/icons-material/Delete'

const RoleDetails = () => {
  const roles = useSelector((state) => state.role?.roles)
  const { accessToken, activeServices, emailClient } = useSelector((state) => state.login)
  const { id } = useParams()
  const [role, setRole] = useState({})
  const [manager, setManager] = useState({})
  const [toRender, setToRender] = useState({})
  const [edit, setEdit] = useState(false)
  const [open, setOpen] = useState([])
  const [error, setError] = useState(false)
  const [servicesTest, setServicesTest] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [canEdit, setCanEdit] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [fetchMessage, setFetchMessage] = useState('')
  const [fetchError, setFetchError] = useState(false)
  const { t } = useTranslation(['common'])
  const [rolesNames, setRolesNames] = useState([])
  const [superRoleName, setSuperRoleName] = useState('')

  useRolesByEmailClient(emailClient, isLoading, setIsLoading)

  useEffect(() => {
    setRolesNames(
      roles.data?.map((r) => {
        return r.roleName
      }),
    )
  }, [roles.data])

  useEffect(() => {
    if (roles && roles?.data?.length !== 0 && id) {
      let aux = roles?.data?.filter((r) => r.roleName === id)
      if (aux.length !== 0) {
        setRole(aux[0])
        setServicesTest(aux[0].services)
        setSuperRoleName(aux[0].superRoleName ?? '')
        setError(false)
      } else {
        setError(true)
      }
    }
    if (roles && roles?.data?.length !== 0 && id && id !== 'MANAGER') {
      let aux = roles?.data?.filter((r) => r.roleName === 'MANAGER')

      if (aux.length !== 0) {
        setManager(aux[0])
        setError(false)
      } else {
        setError(true)
      }
    }
  }, [roles, id])

  useEffect(() => {
    if (role) {
      setToRender(role)
    }
  }, [role])

  useEffect(() => {
    if (edit) {
      setToRender(manager)
      setServicesTest(role?.services)
    } else {
      setToRender(role)
    }
  }, [edit])

  useEffect(() => {
    if (role && role.services && role.services.length !== 0 && open.length === 0) {
      let aux = []
      role.services.forEach((element) => {
        aux.push(false)
      })
      setOpen([...aux])
    }

    if (activeServices.includes('adminroles:id')) {
      setCanEdit(true)
    }
  }, [role])

  const handleOpen = (idx, value) => {
    let aux = [...open]
    aux[idx] = value
    setOpen([...aux])
  }

  const handleRoleEdit = () => {
    setEdit(!edit)
    if (edit) setSuperRoleName(role?.superRoleName)
  }

  const handleEditService = (checked, service, subService) => {
    let arrServices = []
    if (service && checked && !subService) {
      arrServices = [...servicesTest]
      arrServices.push(service)
      setServicesTest([...arrServices])
    } else if (service && !checked && !subService) {
      arrServices = [...servicesTest]
      arrServices = arrServices.filter((a) => a.name !== service.name)
      setServicesTest([...arrServices])
    }
  }

  const handleEditSubService = (checked, service, subService) => {
    let arrServices = []
    if (subService && checked) {
      const exist = servicesTest.filter((s) => s.name === service.name)
      if (!exist.length) {
        let custom = {
          name: service.name,
          status: 'active',
          subServices: [],
        }

        custom.subServices.push(subService)
        arrServices = servicesTest.length !== 0 ? [...servicesTest] : []

        arrServices.push(custom)
        setServicesTest(arrServices)
      } else {
        arrServices = [...servicesTest]

        const mapped = arrServices.map((s) => {
          if (s.name === service.name) {
            const updatedSubServices = [...s.subServices, subService]
            return { ...s, subServices: updatedSubServices }
          }

          return s
        })
        arrServices = mapped
        setServicesTest(arrServices)
      }
    } else if (subService && !checked) {
      arrServices = [...servicesTest]
      let idx = arrServices.map((e) => e.name).indexOf(service.name)
      let idx2 = arrServices[idx].subServices.map((e) => e.name).indexOf(subService.name)
      let subServices = [...arrServices[idx].subServices]
      subServices.splice(idx2, 1)

      let custom = {
        name: service.name,
        status: 'active',
        subServices: subServices,
      }

      if (custom.subServices && custom.subServices.length !== 0) {
        arrServices[idx] = custom
      } else {
        arrServices.splice(idx, 1)
      }
      setServicesTest([...arrServices])
    }
  }

  const handleFetch = (error, message) => {
    setIsFetching(true)
    setFetchError(error)
    setFetchMessage(message)
    setTimeout(() => {
      setIsFetching(false)
    }, 3000)
  }

  const handleUpdate = () => {
    setIsLoading(true)
    let data = {
      roleName: role?.roleName,
      services: servicesTest,
      description: role?.description,
      superRoleName: superRoleName,
    }

    if (!process.env.REACT_APP_IS_DEMO) {
      const urlAdminRoles = `${process.env.REACT_APP_BASEURL}/admin/roles/${role?._id}`
      const options = {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(data),
      }

      fetch(urlAdminRoles, options)
        .then((res) => res.json())
        .then((res) => {
          if (!res.success && res.message && res.message.length !== 0) {
            throw Error(res.message.toString())
          } else if (res.isCPO) {
            setServicesTest(role?.services)
            setSuperRoleName(role?.superRoleName ?? '')
          } else {
            setRole(res.data)
          }
          handleFetch(false, res.message)
          setEdit(false)
        })
        .catch((error) => {
          console.error('[UPDATE ROLE ERROR] --> ', error)
          handleFetch(true, error.message ? error.message : 'Role edition error')
        })
    } else {
      setRole(data)
      handleFetch(false, 'Role update success')
      setEdit(false)
    }
  }

  return (
    <Box>
      <Box className={`${isLoading && 'spinner-transition'} filter component-wrapper`}>
        {isLoading && <Spinner noTransform />}
        {isFetching && <AlertFetchSpinner message={fetchMessage} error={fetchError} />}
        <Box className="component-title-wrapper">
          <Box className="component-title">
            <Typography variant="h2">
              {t('common:role')}: {id}
            </Typography>
            <Box className="user-icons">
              {id !== 'MANAGER' && canEdit && (
                <IconButton
                  onClick={() => {
                    handleRoleEdit()
                  }}
                  style={{ color: '#0DDE4E' }}
                >
                  {!edit ? <CreateIcon style={{ height: '1.45rem' }} /> : <ClearIcon style={{ height: '1.45rem' }} />}
                </IconButton>
              )}
            </Box>
          </Box>
          <Typography variant="subtitle3">
            {t('common:description')}: {role?.description}
          </Typography>
        </Box>
        <Paper elevation={0}>
          {error && (
            <Box>
              <h2>Error to load role: "{id}"</h2>
            </Box>
          )}

          <Box className="select-super-role">
            <p style={{ fontSize: '1.3rem' }}>
              Super Role: {!edit ? (superRoleName !== '' ? superRoleName : '---') : ''}
            </p>
            {edit && (
              <Select
                size="small"
                disabled={!edit}
                displayEmpty
                value={superRoleName}
                onChange={(event) => {
                  setSuperRoleName(event.target.value)
                }}
                input={<OutlinedInput />}
                renderValue={(selected) => {
                  return selected
                }}
                MenuProps={MenuProps}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                {Array.isArray(rolesNames) &&
                  rolesNames
                    .filter((r) => r !== id)
                    .map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
              </Select>
            )}
            {edit && superRoleName !== '' && (
              <IconButton
                onClick={() => {
                  setSuperRoleName('')
                }}
                style={{ color: '#ED0C32' }}
              >
                <DeleteIcon style={{ height: '1.45rem' }} />
              </IconButton>
            )}
          </Box>

          <Table>
            <TableHead>
              <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell></TableCell>
                {id !== 'MANAGER' && edit && <TableCell></TableCell>}
                <TableCell>{t('common:name')}</TableCell>
                <TableCell>{t('common:status')}</TableCell>
                <TableCell>Subservices</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {role?.services &&
                toRender?.services?.map((service, idx) => (
                  <React.Fragment key={idx}>
                    <TableRow>
                      <TableCell style={{ width: '5rem' }}>
                        <IconButton aria-label="expand row" size="small" onClick={() => handleOpen(idx, !open[idx])}>
                          {open[idx] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                      </TableCell>

                      {/* CHECKBOX SERVICE */}
                      {edit && (
                        <TableCell>
                          <Checkbox
                            color="primary"
                            checked={servicesTest?.find((s) => s.name === service.name) !== undefined || false}
                            onChange={(e) => handleEditService(e.target.checked, service)}
                          />
                        </TableCell>
                      )}
                      <TableCell>{service.name.toUpperCase()}</TableCell>
                      <TableCell>{service.status}</TableCell>
                      <TableCell>{service.subServices?.length}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ padding: 0 }} colSpan={6}>
                        <Collapse in={open[idx]} timeout={'auto'} unmountOnExit>
                          <Box className="subservices">
                            <Typography gutterBottom component="Box" className="subservices-title">
                              {service.name.toUpperCase()} SUBSERVICES:
                            </Typography>
                            <Table size={'small'}>
                              <TableHead>
                                <TableRow>
                                  <TableCell style={{ width: '5rem' }}></TableCell>
                                  {edit && <TableCell></TableCell>}
                                  <TableCell>{t('common:name')} - Description</TableCell>
                                  <TableCell>Method</TableCell>
                                  <TableCell>Path</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody style={{ fontFamily: 'inherit' }}>
                                {service.subServices.map((subService, idx2) => (
                                  <TableRow key={idx2}>
                                    <TableCell style={{ width: '5rem' }}></TableCell>
                                    {/* CHECKBOX SUBSERVICE */}
                                    {edit && (
                                      <TableCell key={idx2} id={idx2}>
                                        <Checkbox
                                          key={idx2}
                                          checked={
                                            servicesTest.find((r) =>
                                              r.subServices.find((ss) => ss.name === subService.name),
                                            ) !== undefined || false
                                          }
                                          onChange={(e) => {
                                            handleEditSubService(e.target.checked, service, subService)
                                          }}
                                          color="secondary"
                                        />
                                      </TableCell>
                                    )}
                                    <TableCell className="subservices-cell subservices-size">
                                      {subService.name}
                                    </TableCell>
                                    <TableCell className="subservices-cell">{subService.method}</TableCell>
                                    <TableCell className="subservices-cell">{subService.path}</TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
            </TableBody>
          </Table>
        </Paper>
        {edit && (
          <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', padding: '2rem' }}>
            <Button onClick={handleUpdate} size="large" variant="outlined" type="submit">
              {t('common:save')}
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default RoleDetails

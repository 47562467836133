import { createSlice } from '@reduxjs/toolkit'

export const caseSlice = createSlice({
  name: 'case',
  initialState: {
    cases: {},
    caseDetails: {},
    caseUser: {},
    userCases: {},
    caseAdminPanel: {},
    filters: {
      page: 0,
      limit: 10,
      offset: 0,
      status: '',
      caseId: '',
      userId: '',
      fromDate: null,
      toDate: '',
      firstName: '',
      lastName: '',
      legalName: '',
      userType: '',
      userKey: '',
      userValue: '',
      transactionKey: '',
      transactionValue: '',
      documentNumber: '',
      isMyCases: false,
    },
  },
  reducers: {
    updateCases: (state, cases) => {
      state.cases = cases.payload
    },
    updateCaseDetails: (state, caseDetails) => {
      state.caseDetails = caseDetails.payload
    },
    updateCaseUser: (state, caseUser) => {
      state.caseUser = caseUser.payload
    },
    updateCasesFilters: (state, filters) => {
      state.filters = filters.payload
    },
    updateUserCases: (state, userCases) => {
      state.userCases = userCases.payload
    },
    updateCaseAdminPanel: (state, caseAdminPanel) => {
      state.caseAdminPanel = caseAdminPanel.payload
    },
  },
})

export const {
  updateCases,
  updateCaseDetails,
  updateToAssign,
  updateCaseUser,
  updateCasesFilters,
  updateUserCases,
  updateCaseAdminPanel,
  updateCaseAdminToAssing,
} = caseSlice.actions

export default caseSlice.reducer

import { buildTimeStringFromTimestamp } from '../../time/timeHelper'

export const dashboardRolesReportDataMapperForPDF = (data) => {
  const header = [
    'Action Owner',
    'Role',
    'Action',
    'Status',
    'Date',
    'Previous Record SubServices',
    'New Record SubServices',
  ]
  const parsedData = data.map((report) => {
    return [
      report.emailUser,
      report.activityDetails.newRecord ? report.activityDetails.newRecord.roleName : '---',
      report.activityDetails ? report.activityDetails.abmAction : '---',
      report.activityDetails ? report.activityDetails.actionResult : '---',
      buildTimeStringFromTimestamp(report.createdAt),

      report.activityDetails.previousRecord
        ? report.activityDetails.previousRecord.services.map((service) => {
            return service.subServices.map((subService) => {
              return `${subService.name} -- `
            })
          })
        : '---',

      report.activityDetails.newRecord
        ? report.activityDetails.newRecord.services.map((service) => {
            return service.subServices.map((subService) => {
              return `${subService.path}`
            })
          })
        : '---',
    ]
  })

  return {
    columns: header,
    body: parsedData,
  }
}

export const dashboardRolesReportDataMapper = (data) => {
  return data.map((report) => {
    return {
      ActionOwner: report.emailUser,
      Role: report.activityDetails?.newRecord ? report.activityDetails?.newRecord?.roleName : '---',
      Action: report.activityDetails ? report.activityDetails?.abmAction : '---',
      Status: report.activityDetails ? report.activityDetails?.actionResult : '---',
      Date: buildTimeStringFromTimestamp(report.createdAt),

      PreviousRecordSubServices: report.activityDetails?.previousRecord
        ? report.activityDetails?.previousRecord?.services.map((service) => {
            return service.subServices.map((subService) => {
              return `${subService.name} -- `
            })
          })
        : '---',

      NewRecordSubServices: report.activityDetails?.newRecord
        ? report.activityDetails?.newRecord?.services.map((service) => {
            return service.subServices.map((subService) => {
              return `${subService.path}`
            })
          })
        : '---',
    }
  })
}

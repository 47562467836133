export const ruleQByClientReportDataMapperForPDF = reportKey => (data) => {
    const header = reportKey === 'rules_report_by_client' ? ['UserId', 'RuleId', 'RuleInstaceId', 'ExeQ', 'HitQ', 'HitExeRate'] : ['UserId', 'ExeQ', 'HitQ', 'HitExeRate']
    const parsedData = data.map((report) => {
        return reportKey === 'rules_report_by_client' ?
            [
                report?.userId,
                report?.ruleId,
                report?.ruleInstanceId,
                report?.exeQ,
                report?.hitQ,
                report?.hitExeRate,
            ] :
            [report?.userId,
            report?.exeQ,
            report?.hitQ,
            report?.hitExeRate
            ]
    })

    return {
        columns: header,
        body: parsedData,
    }
}

export const ruleQByClientReportDataMapper = reportKey => (data) => {
    return data.map((report) => {
        return reportKey === 'rules_report_by_client' ? {
            UserId: report?.userId,
            RuleId: report?.ruleId,
            RuleInstanceId: report?.ruleInstanceId,
            ExeQ: report?.exeQ,
            HitQ: report?.hitQ,
            HitExeRatio: report?.hitExeRate,
        } : {
            UserId: report?.userId,
            ExeQ: report?.exeQ,
            HitQ: report?.hitQ,
            HitExeRatio: report?.hitExeRate,
        }
    })
}

import { Box } from '@mui/material'
import RecursiveProperty from './RenderJSON'
import { camelCaseFormatter } from './formatters/camelCase'

export const mapNegativeMedia = (data) => {
  const mappedData = data?.reduce((result, item) => {
    const title = item['Title']
    result[title] = { ...item }
    delete result[title]['Title']
    return result
  }, {})

  return { NewsItems: mappedData }
}

export const renderNegativeMedia = (data, theme) => (
  <Box className="accordion-jap" sx={{ backgroundColor: theme === 'light' ? '#FCFFFD' : '#020D06' }}>
    {data &&
      Object.entries(data)?.map(([key, value]) => {
        return (
          <Box key={key}>
            {key !== 'NewsItems' &&
              key !== 'Next' &&
              key !== 'SearchLabels' &&
              key !== 'EntityStatistics' &&
              key !== 'TotalPages' && (
                <Box className="accordion-jap-row">
                  <span className="accordion-jap-title">{camelCaseFormatter(key)}: </span>
                  <p>{value ?? '---'}</p>
                </Box>
              )}
          </Box>
        )
      })}
    <RecursiveProperty property={data?.SearchLabels} excludeBottomBorder={false} rootProperty={true} />
    <RecursiveProperty property={data?.EntityStatistics} excludeBottomBorder={false} rootProperty={true} />
    <RecursiveProperty property={mapNegativeMedia(data?.NewsItems)} excludeBottomBorder={false} rootProperty={true} />
  </Box>
)

import { createSlice } from '@reduxjs/toolkit'

export const checklistsSlice = createSlice({
  name: 'checklists',
  initialState: {
    checklists: {},
    filters: {
      page: 0,
      limit: 25,
      offset: 0,
      fromDate: null,
      toDate: '',
      id: '',
      emailClient: '',
    },
  },
  reducers: {
    updateChecklists: (state, action) => {
      state.checklists = action.payload
    },
    updateChecklistsFilters: (state, action) => {
      state.filters = action.payload
    },
  },
})

export const { updateChecklists, updateChecklistsFilters } = checklistsSlice.actions
export default checklistsSlice.reducer

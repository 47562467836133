import './datatable.scss'
import { useSelector } from 'react-redux'
import { Box, TableContainer, TablePagination } from '@mui/material'
import { useEvents } from '../../../pages/pages-hooks/useEvents'
import { useState } from 'react'
import { buildTimeStringFromTimestamp } from '../time/timeHelper'
import { DataGrid } from '@mui/x-data-grid'
import { TableIcons } from '../../utilities/TableIcons'
import { useTranslation } from 'react-i18next'

const DatatableEvent = ({ transactionId }) => {
  const { email } = useSelector((state) => state.login)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const { t } = useTranslation(['common'])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const getPaginatedData = (data, page, rowsPerPage) => {
    const startIndex = page * rowsPerPage
    const endIndex = startIndex + rowsPerPage
    const paginatedData = data.slice(startIndex, endIndex)
    return paginatedData
  }

  let transactionsEvents = useEvents(email, transactionId)

  const paginatedTransactions = getPaginatedData(transactionsEvents, page, rowsPerPage)

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 1,
      minWidth: 250,
    },
    {
      field: 'transactionId',
      headerName: t('common:transactionId'),
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'transactionState',
      headerName: t('common:transactionState'),
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return <Box>{params.row.transactionState ? <TableIcons type={params.row.transactionState} /> : '---'}</Box>
      },
      valueGetter: (params) => {
        return params.row.transactionState
      },
    },
    {
      field: 'createdDate',
      headerName: t('common:eventDate'),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => {
        return buildTimeStringFromTimestamp(params.row.createdDate)
      },
    },
    {
      field: 'description',
      headerName: t('common:description'),
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'reason',
      headerName: t('common:reason'),
      flex: 1,
      minWidth: 100,
    },
  ]

  return (
    <TableContainer>
      <DataGrid
        getRowId={(row) => row._id || row.id}
        rows={paginatedTransactions || []}
        columns={columns}
        hideFooter={true}
        autoHeight={true}
      />

      <TablePagination
        labelRowsPerPage={[]}
        component="div"
        count={paginatedTransactions.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  )
}

export default DatatableEvent

import './timeWindow.scss'
import { useEffect, useState } from 'react'
import NumberInput from '../numberInput/numberInput'
import SelectSingle from '../selectSingle/selectSingle'
import CheckboxItem from '../checkbox/checkbox'
import { Box, InputLabel } from '@mui/material'
import { useTranslation } from 'react-i18next'

const TimeWindow = ({
  propertie,
  ruleParameters,
  setRuleParameters,
  granularity,
  desc,
  schema,
  setError,
  errorList,
  setErrorList,
  isEditing,
}) => {
  const [finalParameter, setFinalParameter] = useState({})
  const [change, setChange] = useState(false)
  const { t } = useTranslation(['common'])

  useEffect(() => {
    if (ruleParameters[propertie] && Object.keys(finalParameter).length === 0) {
      setFinalParameter({ ...ruleParameters[propertie] })
    }
  }, [propertie, ruleParameters, finalParameter])

  const hangleChange = (value) => {
    setFinalParameter(value)
    setChange(true)
  }

  useEffect(() => {
    let errors = []
    if (!finalParameter.granularity) errors.push('granularity is required')
    if (!finalParameter.units) errors.push('units is required')
    if (errors.length !== 0) {
      let all = errorList
      all[propertie] = errors
      setErrorList({ ...all })
      setError(true)
    } else {
      let all = errorList
      delete all[propertie]
      setErrorList({ ...all })
      setError(false)
    }

    if (change) {
      let all = { ...ruleParameters }
      all[propertie] = finalParameter
      setRuleParameters({ ...all })
      setChange(false)
    }
  }, [change, finalParameter])

  return (
    <Box className="window-input-container">
      <Box className="window-input-row">
        <Box className="window-label">
          <InputLabel>{t('common:timeGranularity')}</InputLabel>
        </Box>
        {granularity && (
          <SelectSingle
            isEditing={isEditing}
            propertie={'granularity'}
            ruleParameters={finalParameter}
            setRuleParameters={hangleChange}
            posibleValues={granularity}
          />
        )}
      </Box>

      <Box className="window-input-row">
        <Box className="window-label">
          <InputLabel>{t('common:numberOfTimeUnit')}</InputLabel>
        </Box>
        <NumberInput
          isEditing={isEditing}
          propertie={'units'}
          ruleParameters={finalParameter}
          setRuleParameters={hangleChange}
          schema={schema}
        />
      </Box>

      <CheckboxItem
        isEditing={isEditing}
        propertie={'rollingBasis'}
        ruleParameters={finalParameter}
        setRuleParameters={hangleChange}
        label={'Rolling Basis'}
        text={desc}
      />
    </Box>
  )
}

export default TimeWindow

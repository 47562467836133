import { Autocomplete, Box, Button, Input, TextField, Tooltip } from '@mui/material'
import '../kytManualCheck.scss'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InfoIcon from '@mui/icons-material/Info'

const WalletDetails = ({ setPaymentDetails, isReset, setIsReset }) => {
  const [walletType, setWalletType] = useState('')
  const [walletId, setWalletId] = useState('')
  const [walletPhoneNumber, setWalletPhoneNumber] = useState('')
  const [name, setName] = useState('')
  const [paymentChannel, setPaymentChannel] = useState('')

  const { t } = useTranslation(['common', 'messages'])
  const [change, setChange] = useState(false)

  useEffect(() => {
    if (change) {
      const body = {
        method: 'WALLET',
        ...(walletType && { walletType }),
        ...(walletId && { walletId }),
        ...(walletPhoneNumber && { walletPhoneNumber }),
        ...(name && { name }),
        ...(paymentChannel && { paymentChannel }),
      }

      setPaymentDetails(body)
      setChange(false)
    }
  }, [change])

  useEffect(() => {
    if (isReset) {
      setWalletType('')
      setWalletId('')
      setWalletPhoneNumber('')
      setName('')
      setPaymentChannel('')
      setIsReset(false)
    }
  }, [isReset])

  const renderTooltip = (title) => (
    <Tooltip title={title}>
      <Button className="back-tip">
        <InfoIcon fontSize="small" className="security-info-icon" />
      </Button>
    </Tooltip>
  )

  return (
    <Box>
      <Box className="kyt-form-pair">
        <Box className="kyt-form-pair-label">
          <label>{t('common:walletType')}</label>
        </Box>
        {renderTooltip(
          <Box className="back-tip">
            <label className="security-items-info" color="inherit">
              {
                'Wallet type if there are various types of wallets belonging to the same user. E.g. Checking, savings, vault, different currency wallets etc.'
              }
            </label>
          </Box>,
        )}
        <Input
          className="full-width"
          size="small"
          type="text"
          value={walletType}
          onChange={(event) => {
            setWalletType(event.target.value)
            setChange(true)
          }}
          placeholder={`${t('common:insert')} ${t('common:walletType')} ${t('here')}`}
        />
      </Box>
      <Box className="kyt-form-pair">
        <Box className="kyt-form-pair-label">
          <label>{t('common:walletId')}</label>
        </Box>
        {renderTooltip(
          <Box className="back-tip">
            <label className="security-items-info" color="inherit">
              {'Unique ID of the wallet.'}
            </label>
          </Box>,
        )}
        <Input
          className="full-width"
          size="small"
          type="text"
          value={walletId}
          onChange={(event) => {
            setWalletId(event.target.value)
            setChange(true)
          }}
          placeholder={`${t('common:insert')} ${t('common:walletId')} ${t('here')}`}
        />
      </Box>
      <Box className="kyt-form-pair">
        <Box className="kyt-form-pair-label">
          <label>{t('common:walletPhoneNumber')}</label>
        </Box>
        {renderTooltip(
          <Box className="back-tip">
            <label className="security-items-info" color="inherit">
              {'Phone number associated with the wallet, if any.'}
            </label>
          </Box>,
        )}
        <Input
          className="full-width"
          size="small"
          type="text"
          value={walletPhoneNumber}
          onChange={(event) => {
            setWalletPhoneNumber(event.target.value)
            setChange(true)
          }}
          placeholder={`${t('common:insert')} ${t('common:walletPhoneNumber')} ${t('here')}`}
        />
      </Box>
      <Box className="kyt-form-pair">
        <Box className="kyt-form-pair-label">
          <label>{t('common:name')}</label>
        </Box>
        {renderTooltip(
          <Box className="back-tip">
            <label className="security-items-info" color="inherit">
              {'Name of the account holder.'}
            </label>
          </Box>,
        )}
        <Input
          className="full-width"
          size="small"
          type="text"
          value={name}
          onChange={(event) => {
            setName(event.target.value)
            setChange(true)
          }}
          placeholder={`${t('common:insert')} ${t('common:name')} ${t('here')}`}
        />
      </Box>
      <Box className="kyt-form-pair">
        <Box className="kyt-form-pair-label">
          <label>{t('common:paymentChannel')}</label>
        </Box>
        {renderTooltip(
          <Box className="back-tip">
            <label className="security-items-info" color="inherit">
              {'Payment Channel.'}
            </label>
          </Box>,
        )}
        <Input
          className="full-width"
          size="small"
          type="text"
          value={paymentChannel}
          onChange={(event) => {
            setPaymentChannel(event.target.value)
            setChange(true)
          }}
          placeholder={`${t('common:insert')} ${t('common:paymentChannel')} ${t('here')}`}
        />
      </Box>
    </Box>
  )
}

export default WalletDetails

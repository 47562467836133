import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setPaymentMethodDetailData } from '../../features/paymentMethodDetail/paymentMethodDetailSlice'

export const usePaymentMethodDetail = (paymentMethodDetail, setIsLoading) => {
  const { accessToken, emailClient } = useSelector((state) => state.login)
  const queryState = useSelector((state) => state.paymentMethodDetail.filters)

  const dispatch = useDispatch()

  useEffect(() => {
    const baseUrlPaymentMethodDetail = `${process.env.REACT_APP_BASEURL}/kyt/externalPaymentMethods/${emailClient}/detail`
    function buildUrl(baseUrl) {
      return `${baseUrl}?limit=${queryState?.limit}&page=${queryState?.page}&offset=${queryState?.offset}`
    }
  
    let urlPostPaymentMethodDetail = buildUrl(baseUrlPaymentMethodDetail)
    const options = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ paymentMethod: paymentMethodDetail })
    }

    fetch(urlPostPaymentMethodDetail, options)
    .then((res) => {
      return res.json()
    })
    .then((res) => {
      if (res.success) {
        const { items, statistics, paginationResult } = res.data
        dispatch(setPaymentMethodDetailData({ data: items, statistics, pagination: paginationResult }))
      }
      setIsLoading(false)
    })
    .catch((error) => {
      console.error('[HOOK: usePaymentMethodDetail] --> ', error)
      setIsLoading(false)
    })
  }, [queryState, paymentMethodDetail])
}
import { Autocomplete, Box, Button, Input, TextField, Tooltip } from '@mui/material'
import '../kytManualCheck.scss'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InfoIcon from '@mui/icons-material/Info'

const deliveryStatusEnum = ['COMPLETED', 'PENDING', 'SETTLED', 'CANCELED', 'REFUND', 'DECLINED']

const CheckDetails = ({ setPaymentDetails, isReset, setIsReset }) => {
  const [checkNumber, setCheckNumber] = useState('')
  const [checkIdentifier, setCheckIdentifier] = useState('')
  const [name, setName] = useState('')
  const [deliveryStatus, setDeliveryStatus] = useState('')

  const { t } = useTranslation(['common', 'messages'])
  const [change, setChange] = useState(false)

  useEffect(() => {
    if (change) {
      const body = {
        method: 'CHECK',
        ...(checkNumber && { checkNumber }),
        ...(checkIdentifier && { checkIdentifier }),
        ...(name && { name }),
        ...(deliveryStatus && { deliveryStatus }),
      }

      setPaymentDetails(body)
      setChange(false)
    }
  }, [change])

  useEffect(() => {
    if (isReset) {
      setCheckNumber('')
      setCheckIdentifier('')
      setName('')
      setDeliveryStatus('')
      setIsReset(false)
    }
  }, [isReset])

  const renderTooltip = (title) => (
    <Tooltip title={title}>
      <Button className="back-tip">
        <InfoIcon fontSize="small" className="security-info-icon" />
      </Button>
    </Tooltip>
  )

  return (
    <Box>
      <Box className="kyt-form-pair">
        <Box className="kyt-form-pair-label">
          <label>{t('common:checkNumber')}</label>
        </Box>
        {renderTooltip(
          <Box className="back-tip">
            <label className="security-items-info" color="inherit">
              {'Check number.'}
            </label>
          </Box>,
        )}
        <Input
          className="full-width"
          size="small"
          type="text"
          value={checkNumber}
          onChange={(event) => {
            setCheckNumber(event.target.value)
            setChange(true)
          }}
          placeholder={`${t('common:insert')} ${t('common:checkNumber')} ${t('here')}`}
        />
      </Box>
      <Box className="kyt-form-pair">
        <Box className="kyt-form-pair-label">
          <label>{t('common:checkIdentifier')}</label>
        </Box>
        {renderTooltip(
          <Box className="back-tip">
            <label className="security-items-info" color="inherit">
              {'Check Identifier.'}
            </label>
          </Box>,
        )}
        <Input
          className="full-width"
          size="small"
          type="text"
          value={checkIdentifier}
          onChange={(event) => {
            setCheckIdentifier(event.target.value)
            setChange(true)
          }}
          placeholder={`${t('common:insert')} ${t('common:checkIdentifier')} ${t('here')}`}
        />
      </Box>
      <Box className="kyt-form-pair">
        <Box className="kyt-form-pair-label">
          <label>{t('common:name')}</label>
        </Box>
        {renderTooltip(
          <Box className="back-tip">
            <label className="security-items-info" color="inherit">
              {'Name of the check holder.'}
            </label>
          </Box>,
        )}
        <Input
          className="full-width"
          size="small"
          type="text"
          value={name}
          onChange={(event) => {
            setName(event.target.value)
            setChange(true)
          }}
          placeholder={`${t('common:insert')} ${t('common:name')} ${t('here')}`}
        />
      </Box>
      <Box className="kyt-form-pair">
        <Box className="kyt-form-pair-label">
          <label>{t('common:deliveryStatus')}</label>
        </Box>
        {renderTooltip(
          <Box className="back-tip">
            <label className="security-items-info" color="inherit">
              {'Delivery Status.'}
            </label>
          </Box>,
        )}
        <Autocomplete
          size="small"
          className="full-width"
          value={deliveryStatus}
          onChange={(event, value) => setDeliveryStatus(value)}
          disablePortal
          id="combo-box-demo"
          options={deliveryStatusEnum}
          renderInput={(params) => <TextField variant="standard" {...params} placeholder="Select Delivery Status" />}
        />
      </Box>
    </Box>
  )
}

export default CheckDetails

import './datatable.scss'
import { useEffect, useState } from 'react'
import { Box, TableContainer, TablePagination, Typography, Link } from '@mui/material'
import { Flags } from '../flag/flags'
import { buildTimeStringFromTimestamp } from '../time/timeHelper'
import { TableIcons } from '../../utilities/TableIcons'
import { TableChip } from '../../utilities/TableChip'
import { DataGrid } from '@mui/x-data-grid'
import { useTranslation } from 'react-i18next'

const DatatableCaseTransaction = ({ rows }) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [transactions, setTransactions] = useState([])
  const { t } = useTranslation(['common'])

  useEffect(() => {
    if (rows && rows.length !== 0) setTransactions(rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage))
  }, [rows, page, rowsPerPage])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const columns = [
    {
      field: 'transactionId',
      headerName: 'ID',
      flex: 1,
      minWidth: 220,
      renderCell: (params) => {
        return (
          <Link href={`/transactions/${params.row.transactionId}`} style={{ textDecoration: 'none' }}>
            <Typography variant="number">{params.row.transactionId}</Typography>
          </Link>
        )
      },
    },
    {
      field: 'type',
      headerName: t('common:type'),
      flex: 1,
      minWidth: 180,
      renderCell: (params) => {
        return <Box>{params.row.type ? <TableIcons type={params.row.type} /> : '---'}</Box>
      },
      valueGetter: (params) => {
        return params.row.type
      },
    },
    {
      field: 'transactionState',
      headerName: t('common:state'),
      flex: 1,
      minWidth: 140,
      renderCell: (params) => {
        return <Box>{params.row.transactionState ? <TableIcons type={params.row.transactionState} /> : '---'}</Box>
      },
      valueGetter: (params) => {
        return params.row.transactionState
      },
    },
    {
      field: 'timestamp',
      headerName: t('common:date'),
      flex: 1,
      minWidth: 200,
      valueGetter: (params) => {
        return buildTimeStringFromTimestamp(params.row.timestamp)
      },
    },
    {
      field: 'alert',
      headerName: t('common:alert'),
      flex: 1,
      minWidth: 120,
      renderCell: (params) => {
        return <Box>{params.row.alert ? <TableChip action={params.row.alert} /> : '---'}</Box>
      },
      valueGetter: (params) => {
        return params.row.alert
      },
    },
    {
      field: 'hitRules',
      headerName: t('common:hitRules'),
      flex: 1,
      minWidth: 120,
      valueGetter: (params) => {
        return params.row.hitRules.length
      },
    },
    {
      field: 'originUserId',
      headerName: t('common:originUserId'),
      flex: 1,
      minWidth: 250,
    },
    {
      field: 'originPaymentDetails',
      headerName: t('common:originMethod'),
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <Box>
            <TableChip action={params.row.originPaymentDetails?.method} />
          </Box>
        )
      },
      valueGetter: (params) => {
        return params.row.originPaymentDetails?.method
      },
    },
    {
      field: 'transactionAmount',
      headerName: t('common:originAmount'),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => {
        return params.row.originAmountDetails?.transactionAmount?.toLocaleString()
      },
    },
    {
      field: 'transactionCurrency',
      headerName: t('common:originCurrency'),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => {
        return params.row.originAmountDetails?.transactionCurrency
      },
    },
    {
      field: 'country',
      headerName: t('common:originCountry'),

      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return <Box> {Flags(params.row.originAmountDetails?.country, false, true)}</Box>
      },
      valueGetter: (params) => {
        return params.row.originAmountDetails?.country
      },
    },
    {
      field: 'destinationUserId',
      headerName: t('common:destinationUserId'),
      flex: 1,
      minWidth: 250,
    },
    {
      field: 'destinationPaymentDetails',
      headerName: t('common:destinationMethod'),
      flex: 1,
      minWidth: 230,
      renderCell: (params) => {
        return (
          <Box>
            <TableChip action={params.row.destinationPaymentDetails?.method} />
          </Box>
        )
      },
      valueGetter: (params) => {
        return params.row.destinationPaymentDetails?.method
      },
    },
    {
      field: 'destinationAmountDetails.transactionAmount',
      headerName: t('common:destinationAmount'),
      flex: 1,
      minWidth: 180,
      valueGetter: (params) => {
        return params.row.destinationAmountDetails?.transactionAmount?.toLocaleString()
      },
    },
    {
      field: 'destinationAmountDetails.transactionCurrency',
      headerName: t('common:destinationCurrency'),
      flex: 1,
      minWidth: 180,
      valueGetter: (params) => {
        return params.row.destinationAmountDetails?.transactionCurrency
      },
    },
    {
      field: 'destinationAmountDetails.country',
      headerName: t('common:destinationCountry'),
      flex: 1,
      minWidth: 180,
      renderCell: (params) => {
        return <Box> {Flags(params.row.destinationAmountDetails?.country, false, true)}</Box>
      },
    },
  ]

  return (
    <Box>
      <TableContainer>
        <DataGrid
          className="grid-table"
          getRowId={(row) => row._id || row.id}
          rows={transactions || []}
          columns={columns}
          hideFooter={true}
          autoHeight={true}
        />

        <TablePagination
          labelRowsPerPage={[]}
          component="div"
          count={rows ? rows.length : 0}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Box>
  )
}

export default DatatableCaseTransaction

import { useState } from 'react'
import './checklist.scss'
import { Box, Button, Divider, IconButton, Step, StepLabel, Stepper, SwipeableDrawer, TextField } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import CloseIcon from '@mui/icons-material/Close'
import { ChecklistInstanceDetails } from './ChecklistInstanceDetails'

export const ChecklistInstanceDrawer = ({ open, setOpen, check, isCreation, checklists, setChecklists }) => {
  const [isEditing, setIsEditing] = useState(false)
  const handleClose = () => {
    setOpen(false)
  }
  return (
    <SwipeableDrawer anchor="right" open={open} onClose={() => handleClose()} onOpen={() => setOpen(true)}>
      <Box className="checklist-drawer" role="presentation">
        <Box className="checklist-drawer-container">
          <Box className="checklist-drawer-head">
            <h3>{check?._id ? `Editing checklist ${check._id}` : 'Creating new checklist'}</h3>
            {!isCreation && (
              <IconButton
                //   disabled={isFetching || isLoading || (verifyEdition ? !verifyEdition() : false)}
                onClick={() => {
                  setIsEditing(!isEditing)
                }}
                color={!isEditing ? 'secondary' : 'error'}
              >
                {!isEditing ? <EditIcon style={{ height: '1.45rem' }} /> : <CloseIcon style={{ height: '1.2rem' }} />}
              </IconButton>
            )}
          </Box>
          <ChecklistInstanceDetails
            check={check}
            isEdition={check._id || !isCreation}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            checklists={checklists}
            setChecklists={setChecklists}
            setOpen={setOpen}
          />
        </Box>
      </Box>
    </SwipeableDrawer>
  )
}

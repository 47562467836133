import './comingSoon.scss'

const ComingSoon = () => {
  return (
    <div className="comingSonnContainer">
      <img src={require('../../../assets/logos/GuenoLogoBig.png')} width="15%" alt="GuenoLogo" />
      <h1>Coming soon!</h1>
      <div className="divH3">
        <span>We are working on this feature.</span>
        <span>Contact out support team to know more information about it and its release date.</span>
        <span>Thanks!</span>
      </div>
      <img src={require('../../../assets/CodingComingSoonGif.gif')} width="15%" alt="GuenoLogo" />
    </div>
  )
}

export default ComingSoon

import { toFlatPropertyMap } from '../dataExport'

export const securityParameterReportDataMapperForPDF = (data) => {
  return {
    columns: Object.keys(toFlatPropertyMap(data)),
    body: [Object.values(toFlatPropertyMap(data))],
  }
}

export const securityParameterReportDataMapper = (data) => {
  return Object.entries(data).map((report) => {
    const key = report[0]
    const description = report[1].title
    const value = report[1].value !== undefined ? report[1].value : '---'

    return {
      key,
      description,
      value,
    }
  })
}

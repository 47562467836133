import { Autocomplete, Box, Chip, InputLabel, TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const StringArrayInput = ({ propertie, ruleParameters, setRuleParameters, isEditing, filterTemplate }) => {
  const [valueState, setValueState] = useState([])
  const [change, setChange] = useState(false)
  const { t } = useTranslation(['common'])

  const getIndex = () => {
    if (Array.isArray(ruleParameters) && ruleParameters.length === 0) return -1
    if (ruleParameters) return ruleParameters.map((e) => e.filterTemplate).indexOf(filterTemplate)
    return -1
  }

  useEffect(() => {
    const aux = ruleParameters?.find((e) => e.filterTemplate === filterTemplate)

    if (aux) {
      setValueState(aux[propertie])
    }
  }, [propertie])

  useEffect(() => {
    const index = getIndex()

    if (change) {
      let all = [...ruleParameters]
      let previous = index >= 0 && all[index] ? { ...all[index] } : {}

      previous[propertie] = valueState
      previous.filterTemplate = filterTemplate

      if (index !== -1) {
        all.splice(index, 1)
      }
      all.push(previous)

      setRuleParameters([...all])
    }
    setChange(false)
  }, [valueState])

  return (
    <Autocomplete
      disabled={!isEditing}
      value={valueState}
      onChange={(event, newValue) => {
        setValueState(newValue)
        setChange(true)
      }}
      multiple
      id="tags-filled"
      className="tags-chips"
      options={[]}
      freeSolo
      fullWidth
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip variant="filled" color="primary" label={option} {...getTagProps({ index })} />
        ))
      }
      renderInput={(params) => <TextField {...params} variant="outlined" placeholder={t('common:values')} />}
    />
  )
}

export default StringArrayInput

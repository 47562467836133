import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { updateEcommerceRiskDetail, updateEcommerceRisks } from '../../features/ecommerceRisk/ecommerceRiskSlice'

export const useEcommerceRisks = (emailClient, setIsLoading) => {
  const ecommerceRisk = useSelector((state) => state.ecommerceRisk)
  const queryState = useSelector((state) => state.ecommerceRisk?.filters)
  const { limit, offset, fromDate, toDate, page, checkId, firstName, lastName, documentNumber, orderId } = queryState
    ? queryState
    : { ...null }
  const { accessToken } = useSelector((state) => state.login)

  const dispatch = useDispatch()

  function buildUrl(baseUrl) {
    let url = `${baseUrl}?limit=${limit}&page=${page}&offset=${offset}`
    if (fromDate && toDate) url += `&fromDate=${fromDate}&toDate=${toDate}`
    if (checkId?.length) url += `&checkId=${checkId}`
    if (firstName?.length) url += `&firstName=${firstName}`
    if (lastName?.length) url += `&lastName=${lastName}`
    if (documentNumber?.length) url += `&documentNumber=${documentNumber}`
    if (orderId?.length) url += `&orderId=${orderId}`
    return url
  }

  useEffect(() => {
    const baseUrlEcommerceRisk = `${process.env.REACT_APP_BASEURL}/ecommerceRisk/checks/${emailClient}`
    let url = buildUrl(baseUrlEcommerceRisk)

    fetch(process.env.REACT_APP_IS_DEMO ? '../../data/ecommerceRisks.json' : url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res) {
          const { data, paginationResult } = res
          const aux = { data, paginationResult }
          dispatch(updateEcommerceRisks(aux))
          setIsLoading(false)
        }
      })
      .catch((error) => {
        console.error('[HOOK: useEcommerceRisk] --> ', error)
        setIsLoading(false)
      })
  }, [queryState])

  return ecommerceRisk.ecommerceRisks
}

export const useEcommerceRiskDetail = (id, setIsLoading) => {
  const ecommerceRisk = useSelector((state) => state.ecommerceRisk)
  const { accessToken } = useSelector((state) => state.login)

  const dispatch = useDispatch()

  useEffect(() => {
    const url = `${process.env.REACT_APP_BASEURL}/ecommerceRisk/check/${id}`

    fetch(process.env.REACT_APP_IS_DEMO ? '../../data/ecommerceRiskDetails.json' : url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        //tanto success como no se guarda data
        dispatch(updateEcommerceRiskDetail(res.data))
        setIsLoading(false)
      })
      .catch((error) => {
        console.error('[HOOK: useEcommerceRiskDetail] --> ', error)
        dispatch(updateEcommerceRiskDetail({ statusCode: 404 }))
        setIsLoading(false)
      })
  }, [id])

  return ecommerceRisk.ecommerceRisks
}

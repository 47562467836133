export const camelCaseToPhrase = (camelCaseString) => {
  if (!/[A-Z]/.test(camelCaseString)) {
    return camelCaseString.charAt(0).toUpperCase() + camelCaseString.slice(1)
  }

  const words = camelCaseString.replace(/([a-z])([A-Z])/g, '$1 $2').split(/(?=[A-Z])/)

  const resultado = words.map((palabra) => palabra.charAt(0).toUpperCase() + palabra.slice(1))

  return resultado.join(' ')
}

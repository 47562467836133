import { createSlice } from '@reduxjs/toolkit'

export const requestsToAoRSlice = createSlice({
  name: 'requestsToAoR',
  initialState: {
    requestsToAoRRequestedBy: [],
    requestsToAoREvaluatedBy: [],
    requestsToAoRRequestedByPagination: {},
    requestsToAoREvaluatedByPagination: {},
    filters: {
      requestedBy: null,
      evaluatedBy: null,
      requestId: null,
      page: 0,
      limit: 50,
      offset: 0,
      fromDate: null,
      toDate: '',
    },
  },
  reducers: {
    updateRequestsToAoR: (state, action) => {
      if (action?.payload?.isRequestedBy) {
        state.requestsToAoRRequestedBy = action?.payload?.requestedBy
        state.requestsToAoRRequestedByPagination = action?.payload?.requestedByPagination
      } else {
        state.requestsToAoREvaluatedBy = action?.payload?.evaluatedBy
        state.requestsToAoREvaluatedByPagination = action?.payload?.evaluatedByPagination
      }
    },
    updateRequestsToAoRFilters: (state, filters) => {
      state.filters = filters.payload
    },
  },
})

export const { updateRequestsToAoR, updateRequestsToAoRFilters } = requestsToAoRSlice.actions
export default requestsToAoRSlice.reducer

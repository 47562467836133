import { createSlice } from '@reduxjs/toolkit'

export const generalAlertsSlice = createSlice({
  name: 'generalAlerts',
  initialState: {
    alerts: {},
    consumerUser: [],
    filters: {
      emailClient: '',
      id: '',
      page: 0,
      limit: 10,
      offset: 0,
      type: '',
      fromDate: null,
      toDate: '',
    },
  },
  reducers: {
    updateGeneralAlerts: (state, action) => {
      state.alerts = action.payload
    },
    updateConsumerUserGeneralAlert: (state, action) => {
      state.consumerUser = action.payload
    },
    updateGeneralAlertsFilters: (state, filters) => {
      state.filters = filters.payload
    },
  },
})

export const { updateGeneralAlerts, updateConsumerUserGeneralAlert, updateGeneralAlertsFilters } =
  generalAlertsSlice.actions

export default generalAlertsSlice.reducer

import { useNavigate, useParams } from 'react-router-dom'
import './device.scss'
import { Box } from '@mui/material'
import { useEffect, useState } from 'react'
import Spinner from '../../components/common/spinner/spinner'

const DeviceDetails = () => {
  const navigate = useNavigate()
  const { id } = useParams()

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 1500)
  }, [])

  return (
    <Box className="datatable workflows-2">
      {isLoading && <Spinner noTransform />}
      {!isLoading && (
        <img
          className="img-separator"
          src="https://res.cloudinary.com/g-eno/image/upload/v1695825551/Testing/device-details-1.png"
          alt=""
        />
      )}
      {!isLoading && (
        <img
          className="img-separator"
          src="https://res.cloudinary.com/g-eno/image/upload/v1695825551/Testing/device-details-2.png"
          alt=""
        />
      )}
      {!isLoading && (
        <img
          className="img-separator"
          src="https://res.cloudinary.com/g-eno/image/upload/v1695825551/Testing/device-details-3.png"
          alt=""
        />
      )}
      {!isLoading && (
        <img
          className="img-separator"
          src="https://res.cloudinary.com/g-eno/image/upload/v1695825551/Testing/device-details-4.png"
          alt=""
        />
      )}
    </Box>
  )
}

export default DeviceDetails

import { useCallback, useEffect, useState } from 'react'
import Quill from 'quill'
import 'quill/dist/quill.snow.css'

const RichTextEditor = ({ value, onChange, disabled }) => {
  const [quill, setQuill] = useState(null)

  const editorRef = useCallback(
    (container) => {
      if (container && !quill) {
        const newQuill = new Quill(container, {
          theme: 'snow',
          modules: {
            toolbar: [
              [{ header: [1, 2, 3, false] }],
              ['bold', 'italic', 'underline', 'strike'],
              ['image', 'code-block'],
            ],
          },
        })

        newQuill.on('text-change', () => {
          const html = container.querySelector('.ql-editor').innerHTML
          onChange(html)
        })

        setQuill(newQuill)
      }
    },
    [quill, onChange],
  )

  useEffect(() => {
    if (quill) {
      quill.enable(!disabled) // true enables editing, false disables it
    }
  }, [quill, disabled])

  useEffect(() => {
    if (quill && value !== quill.root.innerHTML) {
      quill.root.innerHTML = value
    }
  }, [value, quill])

  return <div ref={editorRef} />
}

export default RichTextEditor

import { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import TypingText from './TypingText';
import TypingTable from './TypingTable';
import './typing_animation.scss';

const SelfTypingUnits = ({ reportItems, setReportItems }) => {
  const [currentItemIndex, setCurrentItemIndex] = useState(0);

  const handleComplete = (c) => {
    setReportItems((prevReportItems) => {
      const newReportItems = [...prevReportItems];
      newReportItems.forEach((reportItem) => {
        reportItem.content.forEach((contentItem) => {
          if (contentItem === c) {
            contentItem.rendered = true;
          }
        });
      });
      return newReportItems;
    });
    setCurrentItemIndex((prevIndex) => prevIndex + 1);
  };

  const renderReportItem = (c, cIndex) => {
    switch (c.type) {
      case 'text':
        return (
          <TypingText
            key={cIndex}
            reportItem={c}
            onComplete={() => {
              handleComplete(c);
            }}
          />
        );
      case 'table':
        return (
          <TypingTable
            key={cIndex}
            reportItem={c}
            onComplete={() => {
              handleComplete(c);
            }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box width='100%' className={'chat_Container'}>
      <div className="chat-container">
        {reportItems.slice(0, currentItemIndex + 1).map((reportItem, bigIndex) => (
          <div key={reportItem.key} className="chat-item">
            <h2 className='reportItem__titleSection'>{`${bigIndex + 1 }. ${reportItem.title}`}</h2>
            {reportItem.content.map((c, cIndex) => (
              <div key={c.id ?? (`${c.userId} - ${cIndex}`)}>
                {renderReportItem(c, cIndex)}
              </div>
            ))}
          </div>
        ))}
      {(currentItemIndex === reportItems.length) && (
        <ButtonGroup variant="contained" aria-label="Basic button group" sx={{
          marginTop: '10px',
        }}>
          <Button disabled>Edit report</Button>
          <Button disabled>Export PDF</Button>
        </ButtonGroup>
      )}
      </div>
    </Box>
  );
};

export default SelfTypingUnits;
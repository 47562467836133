import './processingSpinner.scss'
import { Box, CircularProgress, Typography } from '@mui/material'

const ProcessingSpinner = ({ message }) => {
  return (
    <Box className="processing-wrapper">
      <Typography variant="subtitle3">{message}</Typography>
      <CircularProgress />
    </Box>
  )
}

export default ProcessingSpinner

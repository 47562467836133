import { Box, TextField, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import './stringInput.scss'

const StringInput = ({ propertie, ruleParameters, setRuleParameters, schema, isEditing }) => {
  const [string, setString] = useState('')
  const [change, setChange] = useState(false)

  useEffect(() => {
    if (ruleParameters[propertie]) setString(ruleParameters[propertie])
  }, [propertie])

  const handleChange = (value) => {
    setString(value)
    setChange(true)
  }

  useEffect(() => {
    if (change) {
      let all = { ...ruleParameters }
      if (string !== '') {
        all[propertie] = string
      } else {
        delete all[propertie]
      }
      setRuleParameters({ ...all })
      setChange(false)
    }
  }, [string, change])

  return (
    <Box className="string-input-row">
      {schema?.description && <Typography variant="title3">{schema.description}</Typography>}
      <TextField
        size="small"
        type="text"
        disabled={!isEditing}
        variant="outlined"
        value={string}
        onChange={(event) => handleChange(event.target.value)}
      />
    </Box>
  )
}

export default StringInput

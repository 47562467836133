const kybKycs = {
  "success": true,
  "message": "retrieve_subjects_success",
  "pagination": {
    "totalElements": 8438,
    "elementsPerPage": 100,
    "currentPage": 1
  },
  "data": [
    {
      "_id": "65df9b9059af913ca758dd26",
      "emailClient": "superadmin@gueno.com",
      "name": "BRUNO LUIZ FERREIRA BRAZ",
      "nationality": "Brazil",
      "documentNumber": "35823023855",
      "type": "PERSON",
      "relationship": {
        "type": "QSA",
        "name": "DIRETOR",
        "startDate": "2014-04-03T00:00:00Z",
        "endDate": ""
      },
      "status": "IN_PROGRESS",
      "relatedPersons": [
        "65df9b8f59af913ca758dd08",
        "65df9b8f59af913ca758dd09",
        "65df9b8f59af913ca758dd0a",
        "65df9b8f59af913ca758dd0b",
        "65df9b8f59af913ca758dd0c",
        "65df9b8f59af913ca758dd0d",
        "65df9b8f59af913ca758dd0e",
        "65df9b8f59af913ca758dd0f"
      ],
      "relatedCompanies": [
        "65df9b9059af913ca758dd18",
        "65df9b9059af913ca758dd19",
        "65df9b9059af913ca758dd1a",
        "65df9b9059af913ca758dd1b",
        "65df9b9059af913ca758dd1c",
        "65df9b9059af913ca758dd1d",
        "65df9b9059af913ca758dd1e"
      ],
      "timeline": ["65df9b9659af913ca758dec1"],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:08.242Z",
      "updatedAt": "2024-02-28T20:46:14.750Z",
      "aml": "65df9b9159af913ca758dd9e",
      "negativeMedia": "65df9b9159af913ca758ddd5",
      "registrationStatus": "65df9b9159af913ca758ddfd",
      "kyc": "65df9b9159af913ca758de02",
      "ceis": "65df9b9259af913ca758de39",
      "cnep": "65df9b9259af913ca758de57",
      "ceaf": "65df9b9259af913ca758de6b",
      "relatedCompaniesOwnership": "65df9b9659af913ca758dec0"
    },
    {
      "_id": "65df9b9059af913ca758dd19",
      "emailClient": "superadmin@gueno.com",
      "name": "STARK BANK S.A.- INSTITUICAO DE PAGAMENTO",
      "nationality": "Brazil",
      "documentNumber": "20018183000180",
      "type": "BUSINESS",
      "relationship": {
        "type": "OWNERSHIP",
        "name": "DIRETOR",
        "startDate": "2014-04-03T00:00:00Z",
        "endDate": ""
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:08.239Z",
      "updatedAt": "2024-02-28T20:46:08.239Z"
    },
    {
      "_id": "65df9b9059af913ca758dd1e",
      "emailClient": "superadmin@gueno.com",
      "name": "CONTAAZUL SOFTWARE LTDA",
      "nationality": "Brazil",
      "documentNumber": "05206246000219",
      "type": "BUSINESS",
      "relationship": {
        "type": "EMPLOYMENT",
        "startDate": "2018-11-19T00:00:00Z",
        "endDate": "2020-05-20T00:00:00Z"
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:08.239Z",
      "updatedAt": "2024-02-28T20:46:08.239Z"
    },
    {
      "_id": "65df9b9059af913ca758dd1c",
      "emailClient": "superadmin@gueno.com",
      "name": "STARK SERVICOS DIGITAIS LTDA",
      "nationality": "Brazil",
      "documentNumber": "49411582000198",
      "type": "BUSINESS",
      "relationship": {
        "type": "OWNERSHIP",
        "name": "ADMINISTRADOR",
        "startDate": "2023-01-31T00:00:00Z",
        "endDate": ""
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:08.239Z",
      "updatedAt": "2024-02-28T20:46:08.239Z"
    },
    {
      "_id": "65df9b9059af913ca758dd1d",
      "emailClient": "superadmin@gueno.com",
      "name": "CONTAAZUL SOFTWARE LTDA",
      "nationality": "Brazil",
      "documentNumber": "05206246000219",
      "type": "BUSINESS",
      "relationship": {
        "type": "EMPLOYMENT",
        "startDate": "2018-12-01T00:00:00Z",
        "endDate": "2020-05-20T00:00:00Z"
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:08.239Z",
      "updatedAt": "2024-02-28T20:46:08.239Z"
    },
    {
      "_id": "65df9b9059af913ca758dd1b",
      "emailClient": "superadmin@gueno.com",
      "name": "STARK SOCIEDADE DE CREDITO DIRETO S.A.",
      "nationality": "Brazil",
      "documentNumber": "39908427000128",
      "type": "BUSINESS",
      "relationship": {
        "type": "OWNERSHIP",
        "name": "DIRETOR",
        "startDate": "2020-11-25T00:00:00Z",
        "endDate": ""
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:08.239Z",
      "updatedAt": "2024-02-28T20:46:08.239Z"
    },
    {
      "_id": "65df9b9059af913ca758dd18",
      "emailClient": "superadmin@gueno.com",
      "name": "O LAVRADOR PRODUTOS ALIMENTICIOS LTDA",
      "nationality": "Brazil",
      "documentNumber": "08390024000106",
      "type": "BUSINESS",
      "relationship": {
        "type": "OWNERSHIP",
        "name": "SOCIO-ADMINISTRADOR",
        "startDate": "2006-10-13T00:00:00Z",
        "endDate": ""
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:08.239Z",
      "updatedAt": "2024-02-28T20:46:08.239Z"
    },
    {
      "_id": "65df9b9059af913ca758dd1a",
      "emailClient": "superadmin@gueno.com",
      "name": "OEGS BANK INSTITUICAO DE PAGAMENTO LTDA",
      "nationality": "Brazil",
      "documentNumber": "37265610000127",
      "type": "BUSINESS",
      "relationship": {
        "type": "OWNERSHIP",
        "name": "ADMINISTRADOR",
        "startDate": "2020-05-29T00:00:00Z",
        "endDate": ""
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:08.239Z",
      "updatedAt": "2024-02-28T20:46:08.239Z"
    },
    {
      "_id": "65df9b8f59af913ca758dd0e",
      "emailClient": "superadmin@gueno.com",
      "name": "ALEX GOMES RODRIGUES",
      "nationality": "Brazil",
      "documentNumber": "12425072721",
      "type": "PERSON",
      "relationship": {
        "type": "DIRECT",
        "name": "COWORKER",
        "startDate": "2018-05-11T00:00:00Z",
        "endDate": ""
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:07.822Z",
      "updatedAt": "2024-02-28T20:46:07.822Z"
    },
    {
      "_id": "65df9b8f59af913ca758dd08",
      "emailClient": "superadmin@gueno.com",
      "name": "ROSELI FERREIRA",
      "nationality": "Brazil",
      "documentNumber": "91431638820",
      "type": "PERSON",
      "relationship": {
        "type": "DIRECT",
        "name": "MOTHER",
        "startDate": "2019-01-31T00:00:00Z",
        "endDate": ""
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:07.822Z",
      "updatedAt": "2024-02-28T20:46:07.822Z"
    },
    {
      "_id": "65df9b8f59af913ca758dd0f",
      "emailClient": "superadmin@gueno.com",
      "name": "MARINEI SANTOS OLIVEIRA",
      "nationality": "Brazil",
      "documentNumber": "21966336829",
      "type": "PERSON",
      "relationship": {
        "type": "DIRECT",
        "name": "COWORKER",
        "startDate": "2023-08-24T00:00:00Z",
        "endDate": ""
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:07.822Z",
      "updatedAt": "2024-02-28T20:46:07.822Z"
    },
    {
      "_id": "65df9b8f59af913ca758dd0d",
      "emailClient": "superadmin@gueno.com",
      "name": "DANIELA CAROLINE MUNHOZ QUINTANA",
      "nationality": "Brazil",
      "documentNumber": "21993686886",
      "type": "PERSON",
      "relationship": {
        "type": "DIRECT",
        "name": "COUSIN",
        "startDate": "2020-03-21T00:00:00Z",
        "endDate": ""
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:07.822Z",
      "updatedAt": "2024-02-28T20:46:07.822Z"
    },
    {
      "_id": "65df9b8f59af913ca758dd0c",
      "emailClient": "superadmin@gueno.com",
      "name": "CARLA CRISTINA MUNHOZ",
      "nationality": "Brazil",
      "documentNumber": "13446956808",
      "type": "PERSON",
      "relationship": {
        "type": "DIRECT",
        "name": "COUSIN",
        "startDate": "2020-03-21T00:00:00Z",
        "endDate": ""
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:07.822Z",
      "updatedAt": "2024-02-28T20:46:07.822Z"
    },
    {
      "_id": "65df9b8f59af913ca758dd0b",
      "emailClient": "superadmin@gueno.com",
      "name": "NADIA FERREIRA MUNHOZ",
      "nationality": "Brazil",
      "documentNumber": "09353449855",
      "type": "PERSON",
      "relationship": {
        "type": "DIRECT",
        "name": "UNCLE",
        "startDate": "2020-03-21T00:00:00Z",
        "endDate": ""
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:07.822Z",
      "updatedAt": "2024-02-28T20:46:07.822Z"
    },
    {
      "_id": "65df9b8f59af913ca758dd0a",
      "emailClient": "superadmin@gueno.com",
      "name": "ANTONIO CARLOS FERREIRA",
      "nationality": "Brazil",
      "documentNumber": "70130760820",
      "type": "PERSON",
      "relationship": {
        "type": "DIRECT",
        "name": "UNCLE",
        "startDate": "2019-07-07T00:00:00Z",
        "endDate": ""
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:07.822Z",
      "updatedAt": "2024-02-28T20:46:07.822Z"
    },
    {
      "_id": "65df9b8f59af913ca758dd09",
      "emailClient": "superadmin@gueno.com",
      "name": "ADALIA ADORNI FERREIRA",
      "nationality": "Brazil",
      "documentNumber": "09954714871",
      "type": "PERSON",
      "relationship": {
        "type": "DIRECT",
        "name": "GRANDPARENT",
        "startDate": "2019-07-07T00:00:00Z",
        "endDate": ""
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:07.822Z",
      "updatedAt": "2024-02-28T20:46:07.822Z"
    },
    {
      "_id": "65df9b8e59af913ca758dd06",
      "emailClient": "superadmin@gueno.com",
      "name": "RAFAEL STARK",
      "nationality": "Brazil",
      "documentNumber": "02349014118",
      "type": "PERSON",
      "relationship": {
        "type": "REPRESENTANTELEGAL",
        "name": "",
        "startDate": "2019-06-26T00:00:00Z",
        "endDate": ""
      },
      "status": "IN_PROGRESS",
      "relatedPersons": [
        "65df9b8e59af913ca758dc74",
        "65df9b8e59af913ca758dc75",
        "65df9b8e59af913ca758dc76",
        "65df9b8e59af913ca758dc77"
      ],
      "relatedCompanies": [
        "65df9b8e59af913ca758dcf4",
        "65df9b8e59af913ca758dcf5",
        "65df9b8e59af913ca758dcf6",
        "65df9b8e59af913ca758dcf7",
        "65df9b8e59af913ca758dcf8",
        "65df9b8e59af913ca758dcf9",
        "65df9b8e59af913ca758dcfa"
      ],
      "timeline": ["65df9b9559af913ca758deb2"],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.931Z",
      "updatedAt": "2024-02-28T20:46:13.652Z",
      "aml": "65df9b9159af913ca758dd99",
      "negativeMedia": "65df9b9159af913ca758dde9",
      "kyc": "65df9b9159af913ca758de16",
      "ceaf": "65df9b9259af913ca758de43",
      "cnep": "65df9b9259af913ca758de7a",
      "ceis": "65df9b9259af913ca758de89",
      "registrationStatus": "65df9b9259af913ca758de98",
      "relatedCompaniesOwnership": "65df9b9559af913ca758deb1"
    },
    {
      "_id": "65df9b8e59af913ca758dd04",
      "emailClient": "superadmin@gueno.com",
      "name": "CAIO ROCHA DOTTORI GASPAR",
      "nationality": "Brazil",
      "documentNumber": "12916747729",
      "type": "PERSON",
      "relationship": {
        "type": "QSA",
        "name": "DIRETOR",
        "startDate": "2014-04-03T00:00:00Z",
        "endDate": ""
      },
      "status": "IN_PROGRESS",
      "relatedPersons": [
        "65df9b8e59af913ca758dc96",
        "65df9b8e59af913ca758dc97",
        "65df9b8e59af913ca758dc98",
        "65df9b8e59af913ca758dc99",
        "65df9b8e59af913ca758dc9a",
        "65df9b8e59af913ca758dc9b",
        "65df9b8e59af913ca758dc9c",
        "65df9b8e59af913ca758dc9d"
      ],
      "relatedCompanies": [
        "65df9b8e59af913ca758dcea",
        "65df9b8e59af913ca758dceb",
        "65df9b8e59af913ca758dcec",
        "65df9b8e59af913ca758dced",
        "65df9b8e59af913ca758dcee"
      ],
      "timeline": ["65df9b9559af913ca758dea8"],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.928Z",
      "updatedAt": "2024-02-28T20:46:13.483Z",
      "aml": "65df9b9159af913ca758dd94",
      "negativeMedia": "65df9b9159af913ca758ddb2",
      "kyc": "65df9b9159af913ca758ddee",
      "registrationStatus": "65df9b9159af913ca758ddf8",
      "ceaf": "65df9b9259af913ca758de2a",
      "ceis": "65df9b9259af913ca758de48",
      "cnep": "65df9b9259af913ca758de66",
      "relatedCompaniesOwnership": "65df9b9559af913ca758dea7"
    },
    {
      "_id": "65df9b8e59af913ca758dd02",
      "emailClient": "superadmin@gueno.com",
      "name": "DALTON FELIPE DE MENEZES",
      "nationality": "Brazil",
      "documentNumber": "07639480981",
      "type": "PERSON",
      "relationship": {
        "type": "QSA",
        "name": "DIRETOR",
        "startDate": "2014-04-03T00:00:00Z",
        "endDate": ""
      },
      "status": "IN_PROGRESS",
      "relatedPersons": [
        "65df9b8e59af913ca758dc7c",
        "65df9b8e59af913ca758dc7d",
        "65df9b8e59af913ca758dc7e",
        "65df9b8e59af913ca758dc7f",
        "65df9b8e59af913ca758dc80",
        "65df9b8e59af913ca758dc81",
        "65df9b8e59af913ca758dc82",
        "65df9b8e59af913ca758dc83",
        "65df9b8e59af913ca758dc84"
      ],
      "relatedCompanies": [
        "65df9b8e59af913ca758dcda",
        "65df9b8e59af913ca758dcdb",
        "65df9b8e59af913ca758dcdc",
        "65df9b8e59af913ca758dcdd",
        "65df9b8e59af913ca758dcde",
        "65df9b8e59af913ca758dcdf",
        "65df9b8e59af913ca758dce0",
        "65df9b8e59af913ca758dce1"
      ],
      "timeline": ["65df9b9959af913ca758decb"],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.927Z",
      "updatedAt": "2024-02-28T20:46:17.563Z",
      "aml": "65df9b9159af913ca758dd8f",
      "kyc": "65df9b9159af913ca758ddc6",
      "negativeMedia": "65df9b9159af913ca758ddda",
      "registrationStatus": "65df9b9159af913ca758dddf",
      "cnep": "65df9b9259af913ca758de34",
      "ceis": "65df9b9259af913ca758de5c",
      "ceaf": "65df9b9259af913ca758de70",
      "relatedCompaniesOwnership": "65df9b9959af913ca758deca"
    },
    {
      "_id": "65df9b8e59af913ca758dcf8",
      "emailClient": "superadmin@gueno.com",
      "name": "STARK SOCIEDADE DE CREDITO DIRETO S.A.",
      "nationality": "Brazil",
      "documentNumber": "39908427000128",
      "type": "BUSINESS",
      "relationship": {
        "type": "OWNERSHIP",
        "name": "DIRETOR",
        "startDate": "2020-11-25T00:00:00Z",
        "endDate": "2023-10-05T00:00:00Z"
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.919Z",
      "updatedAt": "2024-02-28T20:46:06.919Z"
    },
    {
      "_id": "65df9b8e59af913ca758dcf7",
      "emailClient": "superadmin@gueno.com",
      "name": "STARK LTDA",
      "nationality": "Brazil",
      "documentNumber": "38232533000171",
      "type": "BUSINESS",
      "relationship": {
        "type": "OWNERSHIP",
        "name": "SOCIO-ADMINISTRADOR",
        "startDate": "2020-08-26T00:00:00Z",
        "endDate": "2023-10-05T00:00:00Z"
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.919Z",
      "updatedAt": "2024-02-28T20:46:06.919Z"
    },
    {
      "_id": "65df9b8e59af913ca758dcf6",
      "emailClient": "superadmin@gueno.com",
      "name": "OEGS BANK INSTITUICAO DE PAGAMENTO LTDA",
      "nationality": "Brazil",
      "documentNumber": "37265610000127",
      "type": "BUSINESS",
      "relationship": {
        "type": "OWNERSHIP",
        "name": "ADMINISTRADOR",
        "startDate": "2020-05-29T00:00:00Z",
        "endDate": "2024-01-03T00:00:00Z"
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.919Z",
      "updatedAt": "2024-02-28T20:46:06.919Z"
    },
    {
      "_id": "65df9b8e59af913ca758dcf9",
      "emailClient": "superadmin@gueno.com",
      "name": "STARK GROUP HOLDING LTDA",
      "nationality": "Brazil",
      "documentNumber": "43853248000108",
      "type": "BUSINESS",
      "relationship": {
        "type": "OWNERSHIP",
        "name": "ADMINISTRADOR",
        "startDate": "2021-10-13T00:00:00Z",
        "endDate": "2023-09-16T00:00:00Z"
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.919Z",
      "updatedAt": "2024-02-28T20:46:06.919Z"
    },
    {
      "_id": "65df9b8e59af913ca758dcfa",
      "emailClient": "superadmin@gueno.com",
      "name": "STARK SERVICOS DIGITAIS LTDA",
      "nationality": "Brazil",
      "documentNumber": "49411582000198",
      "type": "BUSINESS",
      "relationship": {
        "type": "OWNERSHIP",
        "name": "ADMINISTRADOR",
        "startDate": "2023-01-31T00:00:00Z",
        "endDate": "2023-12-11T00:00:00Z"
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.919Z",
      "updatedAt": "2024-02-28T20:46:06.919Z"
    },
    {
      "_id": "65df9b8e59af913ca758dcf5",
      "emailClient": "superadmin@gueno.com",
      "name": "HUMMINGBIRD HEALTH PRODUCTS",
      "nationality": "Brazil",
      "documentNumber": "30134945000167",
      "type": "BUSINESS",
      "relationship": {
        "type": "OWNERSHIP",
        "name": "SOCIO-ADMINISTRADOR",
        "startDate": "2018-04-06T00:00:00Z",
        "endDate": "2019-12-12T00:00:00Z"
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.918Z",
      "updatedAt": "2024-02-28T20:46:06.918Z"
    },
    {
      "_id": "65df9b8e59af913ca758dcf4",
      "emailClient": "superadmin@gueno.com",
      "name": "STARK BANK S.A.- INSTITUICAO DE PAGAMENTO",
      "nationality": "Brazil",
      "documentNumber": "20018183000180",
      "type": "BUSINESS",
      "relationship": {
        "type": "OWNERSHIP",
        "name": "PRESIDENTE",
        "startDate": "2014-04-03T00:00:00Z",
        "endDate": "2023-09-29T00:00:00Z"
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.918Z",
      "updatedAt": "2024-02-28T20:46:06.918Z"
    },
    {
      "_id": "65df9b8e59af913ca758dcee",
      "emailClient": "superadmin@gueno.com",
      "name": "STARK SERVICOS DIGITAIS LTDA",
      "nationality": "Brazil",
      "documentNumber": "49411582000198",
      "type": "BUSINESS",
      "relationship": {
        "type": "OWNERSHIP",
        "name": "ADMINISTRADOR",
        "startDate": "2023-01-31T00:00:00Z",
        "endDate": ""
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.915Z",
      "updatedAt": "2024-02-28T20:46:06.915Z"
    },
    {
      "_id": "65df9b8e59af913ca758dced",
      "emailClient": "superadmin@gueno.com",
      "name": "STARK SOCIEDADE DE CREDITO DIRETO S.A.",
      "nationality": "Brazil",
      "documentNumber": "39908427000128",
      "type": "BUSINESS",
      "relationship": {
        "type": "OWNERSHIP",
        "name": "DIRETOR",
        "startDate": "2020-11-25T00:00:00Z",
        "endDate": ""
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.915Z",
      "updatedAt": "2024-02-28T20:46:06.915Z"
    },
    {
      "_id": "65df9b8e59af913ca758dcea",
      "emailClient": "superadmin@gueno.com",
      "name": "STARK BANK S.A.- INSTITUICAO DE PAGAMENTO",
      "nationality": "Brazil",
      "documentNumber": "20018183000180",
      "type": "BUSINESS",
      "relationship": {
        "type": "OWNERSHIP",
        "name": "DIRETOR",
        "startDate": "2014-04-03T00:00:00Z",
        "endDate": ""
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.915Z",
      "updatedAt": "2024-02-28T20:46:06.915Z"
    },
    {
      "_id": "65df9b8e59af913ca758dcec",
      "emailClient": "superadmin@gueno.com",
      "name": "OEGS BANK INSTITUICAO DE PAGAMENTO LTDA",
      "nationality": "Brazil",
      "documentNumber": "37265610000127",
      "type": "BUSINESS",
      "relationship": {
        "type": "OWNERSHIP",
        "name": "ADMINISTRADOR",
        "startDate": "2020-05-29T00:00:00Z",
        "endDate": ""
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.915Z",
      "updatedAt": "2024-02-28T20:46:06.915Z"
    },
    {
      "_id": "65df9b8e59af913ca758dceb",
      "emailClient": "superadmin@gueno.com",
      "name": "CAIO ROCHA DOTTORI GASPAR SERVICOS DE APOIO ADMINISTRATIVO",
      "nationality": "Brazil",
      "documentNumber": "35547753000104",
      "type": "BUSINESS",
      "relationship": {
        "type": "OWNERSHIP",
        "name": "OWNER",
        "startDate": "2019-11-19T00:00:00Z",
        "endDate": "2022-09-06T00:00:00Z"
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.915Z",
      "updatedAt": "2024-02-28T20:46:06.915Z"
    },
    {
      "_id": "65df9b8e59af913ca758dce0",
      "emailClient": "superadmin@gueno.com",
      "name": "DIAS DE SOUZA - ADVOGADOS ASSOCIADOS",
      "nationality": "Brazil",
      "documentNumber": "69105914000113",
      "type": "BUSINESS",
      "relationship": {
        "type": "EMPLOYMENT",
        "startDate": "2019-05-23T00:00:00Z",
        "endDate": "2019-09-27T00:00:00Z"
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.911Z",
      "updatedAt": "2024-02-28T20:46:06.911Z"
    },
    {
      "_id": "65df9b8e59af913ca758dce1",
      "emailClient": "superadmin@gueno.com",
      "name": "VISIONA TECNOLOGIA ESPACIAL S.A.",
      "nationality": "Brazil",
      "documentNumber": "13944554000199",
      "type": "BUSINESS",
      "relationship": {
        "type": "EMPLOYMENT",
        "startDate": "2016-01-11T00:00:00Z",
        "endDate": "2019-05-10T00:00:00Z"
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.911Z",
      "updatedAt": "2024-02-28T20:46:06.911Z"
    },
    {
      "_id": "65df9b8e59af913ca758dcdf",
      "emailClient": "superadmin@gueno.com",
      "name": "STARK SERVICOS DIGITAIS LTDA",
      "nationality": "Brazil",
      "documentNumber": "49411582000198",
      "type": "BUSINESS",
      "relationship": {
        "type": "OWNERSHIP",
        "name": "ADMINISTRADOR",
        "startDate": "2023-01-31T00:00:00Z",
        "endDate": ""
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.911Z",
      "updatedAt": "2024-02-28T20:46:06.911Z"
    },
    {
      "_id": "65df9b8e59af913ca758dcdd",
      "emailClient": "superadmin@gueno.com",
      "name": "OEGS BANK INSTITUICAO DE PAGAMENTO LTDA",
      "nationality": "Brazil",
      "documentNumber": "37265610000127",
      "type": "BUSINESS",
      "relationship": {
        "type": "OWNERSHIP",
        "name": "ADMINISTRADOR",
        "startDate": "2020-05-29T00:00:00Z",
        "endDate": ""
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.910Z",
      "updatedAt": "2024-02-28T20:46:06.910Z"
    },
    {
      "_id": "65df9b8e59af913ca758dcde",
      "emailClient": "superadmin@gueno.com",
      "name": "STARK SOCIEDADE DE CREDITO DIRETO S.A.",
      "nationality": "Brazil",
      "documentNumber": "39908427000128",
      "type": "BUSINESS",
      "relationship": {
        "type": "OWNERSHIP",
        "name": "DIRETOR",
        "startDate": "2020-11-25T00:00:00Z",
        "endDate": ""
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.910Z",
      "updatedAt": "2024-02-28T20:46:06.910Z"
    },
    {
      "_id": "65df9b8e59af913ca758dcdb",
      "emailClient": "superadmin@gueno.com",
      "name": "COGNITA DATA SCIENCE SERVICOS DE TECNOLOGIA LTDA",
      "nationality": "Brazil",
      "documentNumber": "35607974000112",
      "type": "BUSINESS",
      "relationship": {
        "type": "OWNERSHIP",
        "name": "SOCIO-ADMINISTRADOR",
        "startDate": "2019-11-25T00:00:00Z",
        "endDate": ""
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.910Z",
      "updatedAt": "2024-02-28T20:46:06.910Z"
    },
    {
      "_id": "65df9b8e59af913ca758dcda",
      "emailClient": "superadmin@gueno.com",
      "name": "STARK BANK S.A.- INSTITUICAO DE PAGAMENTO",
      "nationality": "Brazil",
      "documentNumber": "20018183000180",
      "type": "BUSINESS",
      "relationship": {
        "type": "OWNERSHIP",
        "name": "DIRETOR",
        "startDate": "2014-04-03T00:00:00Z",
        "endDate": ""
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.910Z",
      "updatedAt": "2024-02-28T20:46:06.910Z"
    },
    {
      "_id": "65df9b8e59af913ca758dcdc",
      "emailClient": "superadmin@gueno.com",
      "name": "DALTON FELIPE DE MENEZES TECNOLOGIA DA INFORMACAO LTDA",
      "nationality": "Brazil",
      "documentNumber": "35952121000118",
      "type": "BUSINESS",
      "relationship": {
        "type": "OWNERSHIP",
        "name": "SOCIO-ADMINISTRADOR",
        "startDate": "2020-01-09T00:00:00Z",
        "endDate": "2022-09-21T00:00:00Z"
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.910Z",
      "updatedAt": "2024-02-28T20:46:06.910Z"
    },
    {
      "_id": "65df9b8e59af913ca758dcd8",
      "emailClient": "superadmin@gueno.com",
      "name": "RAFAEL STARK",
      "nationality": "Brazil",
      "documentNumber": "02349014118",
      "type": "PERSON",
      "relationship": {
        "type": "QSA",
        "name": "PRESIDENTE",
        "startDate": "2014-04-03T00:00:00Z",
        "endDate": ""
      },
      "status": "IN_PROGRESS",
      "relatedPersons": [
        "65df9b8e59af913ca758dc6c",
        "65df9b8e59af913ca758dc6d",
        "65df9b8e59af913ca758dc6e",
        "65df9b8e59af913ca758dc6f"
      ],
      "relatedCompanies": [
        "65df9b8e59af913ca758dcc8",
        "65df9b8e59af913ca758dcc9",
        "65df9b8e59af913ca758dcca",
        "65df9b8e59af913ca758dccb",
        "65df9b8e59af913ca758dccc",
        "65df9b8e59af913ca758dccd",
        "65df9b8e59af913ca758dcce"
      ],
      "timeline": ["65df9b9559af913ca758deb7"],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.903Z",
      "updatedAt": "2024-02-28T20:46:13.686Z",
      "aml": "65df9b9159af913ca758dd80",
      "negativeMedia": "65df9b9159af913ca758dde4",
      "kyc": "65df9b9159af913ca758de07",
      "ceaf": "65df9b9259af913ca758de2f",
      "cnep": "65df9b9259af913ca758de52",
      "ceis": "65df9b9259af913ca758de75",
      "registrationStatus": "65df9b9259af913ca758de84",
      "relatedCompaniesOwnership": "65df9b9559af913ca758deb6"
    },
    {
      "_id": "65df9b8e59af913ca758dcd6",
      "emailClient": "superadmin@gueno.com",
      "name": "RAFAEL CASTRO DE MATOS",
      "nationality": "Brazil",
      "documentNumber": "02349014118",
      "type": "PERSON",
      "relationship": {
        "type": "QSA",
        "name": "PRESIDENTE",
        "startDate": "2014-04-03T00:00:00Z",
        "endDate": "2023-09-29T00:00:00Z"
      },
      "status": "IN_PROGRESS",
      "relatedPersons": [
        "65df9b8e59af913ca758dc8e",
        "65df9b8e59af913ca758dc8f",
        "65df9b8e59af913ca758dc90",
        "65df9b8e59af913ca758dc91"
      ],
      "relatedCompanies": [
        "65df9b8e59af913ca758dcba",
        "65df9b8e59af913ca758dcbb",
        "65df9b8e59af913ca758dcbc",
        "65df9b8e59af913ca758dcbd",
        "65df9b8e59af913ca758dcbe",
        "65df9b8e59af913ca758dcbf",
        "65df9b8e59af913ca758dcc0"
      ],
      "timeline": [
        "65df9b9859af913ca758dec6",
        "65e0878c3375e20c5ca4c5a7",
        "65e0881ccafc1f034eb4a4d9",
        "65e08849307c4ede36c43114",
        "65e0889e4fc1f7fac12b834c"
      ],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.899Z",
      "updatedAt": "2024-02-29T13:37:34.006Z",
      "aml": "65df9b9159af913ca758dd8a",
      "registrationStatus": "65df9b9159af913ca758ddbc",
      "negativeMedia": "65df9b9159af913ca758ddc1",
      "kyc": "65df9b9159af913ca758ddd0",
      "ceaf": "65df9b9259af913ca758de1b",
      "cnep": "65e0889e4fc1f7fac12b834b",
      "ceis": "65df9b9259af913ca758de93",
      "relatedCompaniesOwnership": "65df9b9859af913ca758dec5"
    },
    {
      "_id": "65df9b8e59af913ca758dcc8",
      "emailClient": "superadmin@gueno.com",
      "name": "STARK BANK S.A.- INSTITUICAO DE PAGAMENTO",
      "nationality": "Brazil",
      "documentNumber": "20018183000180",
      "type": "BUSINESS",
      "relationship": {
        "type": "OWNERSHIP",
        "name": "PRESIDENTE",
        "startDate": "2014-04-03T00:00:00Z",
        "endDate": "2023-09-29T00:00:00Z"
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.893Z",
      "updatedAt": "2024-02-28T20:46:06.893Z"
    },
    {
      "_id": "65df9b8e59af913ca758dccc",
      "emailClient": "superadmin@gueno.com",
      "name": "STARK SOCIEDADE DE CREDITO DIRETO S.A.",
      "nationality": "Brazil",
      "documentNumber": "39908427000128",
      "type": "BUSINESS",
      "relationship": {
        "type": "OWNERSHIP",
        "name": "DIRETOR",
        "startDate": "2020-11-25T00:00:00Z",
        "endDate": "2023-10-05T00:00:00Z"
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.893Z",
      "updatedAt": "2024-02-28T20:46:06.893Z"
    },
    {
      "_id": "65df9b8e59af913ca758dccb",
      "emailClient": "superadmin@gueno.com",
      "name": "STARK LTDA",
      "nationality": "Brazil",
      "documentNumber": "38232533000171",
      "type": "BUSINESS",
      "relationship": {
        "type": "OWNERSHIP",
        "name": "SOCIO-ADMINISTRADOR",
        "startDate": "2020-08-26T00:00:00Z",
        "endDate": "2023-10-05T00:00:00Z"
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.893Z",
      "updatedAt": "2024-02-28T20:46:06.893Z"
    },
    {
      "_id": "65df9b8e59af913ca758dcc9",
      "emailClient": "superadmin@gueno.com",
      "name": "HUMMINGBIRD HEALTH PRODUCTS",
      "nationality": "Brazil",
      "documentNumber": "30134945000167",
      "type": "BUSINESS",
      "relationship": {
        "type": "OWNERSHIP",
        "name": "SOCIO-ADMINISTRADOR",
        "startDate": "2018-04-06T00:00:00Z",
        "endDate": "2019-12-12T00:00:00Z"
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.893Z",
      "updatedAt": "2024-02-28T20:46:06.893Z"
    },
    {
      "_id": "65df9b8e59af913ca758dcce",
      "emailClient": "superadmin@gueno.com",
      "name": "STARK SERVICOS DIGITAIS LTDA",
      "nationality": "Brazil",
      "documentNumber": "49411582000198",
      "type": "BUSINESS",
      "relationship": {
        "type": "OWNERSHIP",
        "name": "ADMINISTRADOR",
        "startDate": "2023-01-31T00:00:00Z",
        "endDate": "2023-12-11T00:00:00Z"
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.893Z",
      "updatedAt": "2024-02-28T20:46:06.893Z"
    },
    {
      "_id": "65df9b8e59af913ca758dcca",
      "emailClient": "superadmin@gueno.com",
      "name": "OEGS BANK INSTITUICAO DE PAGAMENTO LTDA",
      "nationality": "Brazil",
      "documentNumber": "37265610000127",
      "type": "BUSINESS",
      "relationship": {
        "type": "OWNERSHIP",
        "name": "ADMINISTRADOR",
        "startDate": "2020-05-29T00:00:00Z",
        "endDate": "2024-01-03T00:00:00Z"
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.893Z",
      "updatedAt": "2024-02-28T20:46:06.893Z"
    },
    {
      "_id": "65df9b8e59af913ca758dccd",
      "emailClient": "superadmin@gueno.com",
      "name": "STARK GROUP HOLDING LTDA",
      "nationality": "Brazil",
      "documentNumber": "43853248000108",
      "type": "BUSINESS",
      "relationship": {
        "type": "OWNERSHIP",
        "name": "ADMINISTRADOR",
        "startDate": "2021-10-13T00:00:00Z",
        "endDate": "2023-09-16T00:00:00Z"
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.893Z",
      "updatedAt": "2024-02-28T20:46:06.893Z"
    },
    {
      "_id": "65df9b8e59af913ca758dcbb",
      "emailClient": "superadmin@gueno.com",
      "name": "HUMMINGBIRD HEALTH PRODUCTS",
      "nationality": "Brazil",
      "documentNumber": "30134945000167",
      "type": "BUSINESS",
      "relationship": {
        "type": "OWNERSHIP",
        "name": "SOCIO-ADMINISTRADOR",
        "startDate": "2018-04-06T00:00:00Z",
        "endDate": "2019-12-12T00:00:00Z"
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.886Z",
      "updatedAt": "2024-02-28T20:46:06.886Z"
    },
    {
      "_id": "65df9b8e59af913ca758dcbf",
      "emailClient": "superadmin@gueno.com",
      "name": "STARK GROUP HOLDING LTDA",
      "nationality": "Brazil",
      "documentNumber": "43853248000108",
      "type": "BUSINESS",
      "relationship": {
        "type": "OWNERSHIP",
        "name": "ADMINISTRADOR",
        "startDate": "2021-10-13T00:00:00Z",
        "endDate": "2023-09-16T00:00:00Z"
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.886Z",
      "updatedAt": "2024-02-28T20:46:06.886Z"
    },
    {
      "_id": "65df9b8e59af913ca758dcba",
      "emailClient": "superadmin@gueno.com",
      "name": "STARK BANK S.A.- INSTITUICAO DE PAGAMENTO",
      "nationality": "Brazil",
      "documentNumber": "20018183000180",
      "type": "BUSINESS",
      "relationship": {
        "type": "OWNERSHIP",
        "name": "PRESIDENTE",
        "startDate": "2014-04-03T00:00:00Z",
        "endDate": "2023-09-29T00:00:00Z"
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.886Z",
      "updatedAt": "2024-02-28T20:46:06.886Z"
    },
    {
      "_id": "65df9b8e59af913ca758dcbc",
      "emailClient": "superadmin@gueno.com",
      "name": "OEGS BANK INSTITUICAO DE PAGAMENTO LTDA",
      "nationality": "Brazil",
      "documentNumber": "37265610000127",
      "type": "BUSINESS",
      "relationship": {
        "type": "OWNERSHIP",
        "name": "ADMINISTRADOR",
        "startDate": "2020-05-29T00:00:00Z",
        "endDate": "2024-01-03T00:00:00Z"
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.886Z",
      "updatedAt": "2024-02-28T20:46:06.886Z"
    },
    {
      "_id": "65df9b8e59af913ca758dcbd",
      "emailClient": "superadmin@gueno.com",
      "name": "STARK LTDA",
      "nationality": "Brazil",
      "documentNumber": "38232533000171",
      "type": "BUSINESS",
      "relationship": {
        "type": "OWNERSHIP",
        "name": "SOCIO-ADMINISTRADOR",
        "startDate": "2020-08-26T00:00:00Z",
        "endDate": "2023-10-05T00:00:00Z"
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.886Z",
      "updatedAt": "2024-02-28T20:46:06.886Z"
    },
    {
      "_id": "65df9b8e59af913ca758dcbe",
      "emailClient": "superadmin@gueno.com",
      "name": "STARK SOCIEDADE DE CREDITO DIRETO S.A.",
      "nationality": "Brazil",
      "documentNumber": "39908427000128",
      "type": "BUSINESS",
      "relationship": {
        "type": "OWNERSHIP",
        "name": "DIRETOR",
        "startDate": "2020-11-25T00:00:00Z",
        "endDate": "2023-10-05T00:00:00Z"
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.886Z",
      "updatedAt": "2024-02-28T20:46:06.886Z"
    },
    {
      "_id": "65df9b8e59af913ca758dcc0",
      "emailClient": "superadmin@gueno.com",
      "name": "STARK SERVICOS DIGITAIS LTDA",
      "nationality": "Brazil",
      "documentNumber": "49411582000198",
      "type": "BUSINESS",
      "relationship": {
        "type": "OWNERSHIP",
        "name": "ADMINISTRADOR",
        "startDate": "2023-01-31T00:00:00Z",
        "endDate": "2023-12-11T00:00:00Z"
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.886Z",
      "updatedAt": "2024-02-28T20:46:06.886Z"
    },
    {
      "_id": "65df9b8e59af913ca758dcb8",
      "emailClient": "superadmin@gueno.com",
      "name": "MATHEUS COELHO FERRAZ",
      "nationality": "Brazil",
      "documentNumber": "41812844840",
      "type": "PERSON",
      "relationship": {
        "type": "QSA",
        "name": "DIRETOR",
        "startDate": "2014-04-03T00:00:00Z",
        "endDate": ""
      },
      "status": "IN_PROGRESS",
      "relatedPersons": [
        "65df9b8e59af913ca758dca6",
        "65df9b8e59af913ca758dca7",
        "65df9b8e59af913ca758dca8",
        "65df9b8e59af913ca758dca9"
      ],
      "relatedCompanies": [
        "65df9b8e59af913ca758dcae",
        "65df9b8e59af913ca758dcaf",
        "65df9b8e59af913ca758dcb0",
        "65df9b8e59af913ca758dcb1",
        "65df9b8e59af913ca758dcb2"
      ],
      "timeline": ["65df9b9559af913ca758dead"],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.881Z",
      "updatedAt": "2024-02-28T20:46:13.577Z",
      "aml": "65df9b9159af913ca758dda8",
      "kyc": "65df9b9159af913ca758ddad",
      "negativeMedia": "65df9b9159af913ca758ddb7",
      "registrationStatus": "65df9b9159af913ca758ddcb",
      "cnep": "65df9b9259af913ca758de3e",
      "ceis": "65df9b9259af913ca758de4d",
      "ceaf": "65df9b9259af913ca758de61",
      "relatedCompaniesOwnership": "65df9b9559af913ca758deac"
    },
    {
      "_id": "65df9b8e59af913ca758dcb0",
      "emailClient": "superadmin@gueno.com",
      "name": "OEGS BANK INSTITUICAO DE PAGAMENTO LTDA",
      "nationality": "Brazil",
      "documentNumber": "37265610000127",
      "type": "BUSINESS",
      "relationship": {
        "type": "OWNERSHIP",
        "name": "ADMINISTRADOR",
        "startDate": "2020-05-29T00:00:00Z",
        "endDate": ""
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.877Z",
      "updatedAt": "2024-02-28T20:46:06.877Z"
    },
    {
      "_id": "65df9b8e59af913ca758dcb1",
      "emailClient": "superadmin@gueno.com",
      "name": "STARK SOCIEDADE DE CREDITO DIRETO S.A.",
      "nationality": "Brazil",
      "documentNumber": "39908427000128",
      "type": "BUSINESS",
      "relationship": {
        "type": "OWNERSHIP",
        "name": "DIRETOR",
        "startDate": "2020-11-25T00:00:00Z",
        "endDate": ""
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.877Z",
      "updatedAt": "2024-02-28T20:46:06.877Z"
    },
    {
      "_id": "65df9b8e59af913ca758dcae",
      "emailClient": "superadmin@gueno.com",
      "name": "STARK BANK S.A.- INSTITUICAO DE PAGAMENTO",
      "nationality": "Brazil",
      "documentNumber": "20018183000180",
      "type": "BUSINESS",
      "relationship": {
        "type": "OWNERSHIP",
        "name": "DIRETOR",
        "startDate": "2014-04-03T00:00:00Z",
        "endDate": ""
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.877Z",
      "updatedAt": "2024-02-28T20:46:06.877Z"
    },
    {
      "_id": "65df9b8e59af913ca758dcb2",
      "emailClient": "superadmin@gueno.com",
      "name": "STARK SERVICOS DIGITAIS LTDA",
      "nationality": "Brazil",
      "documentNumber": "49411582000198",
      "type": "BUSINESS",
      "relationship": {
        "type": "OWNERSHIP",
        "name": "ADMINISTRADOR",
        "startDate": "2023-01-31T00:00:00Z",
        "endDate": ""
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.877Z",
      "updatedAt": "2024-02-28T20:46:06.877Z"
    },
    {
      "_id": "65df9b8e59af913ca758dcaf",
      "emailClient": "superadmin@gueno.com",
      "name": "MATHEUS COELHO FERRAZ",
      "nationality": "Brazil",
      "documentNumber": "32160637000131",
      "type": "BUSINESS",
      "relationship": {
        "type": "OWNERSHIP",
        "name": "OWNER",
        "startDate": "2018-12-03T00:00:00Z",
        "endDate": "2022-08-21T00:00:00Z"
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.877Z",
      "updatedAt": "2024-02-28T20:46:06.877Z"
    },
    {
      "_id": "65df9b8e59af913ca758dca9",
      "emailClient": "superadmin@gueno.com",
      "name": "MARIA DAS GRACAS PEREIRA COELHO",
      "nationality": "Brazil",
      "documentNumber": "20184107849",
      "type": "PERSON",
      "relationship": {
        "type": "DIRECT",
        "name": "GRANDPARENT",
        "startDate": "2019-11-05T00:00:00Z",
        "endDate": ""
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.503Z",
      "updatedAt": "2024-02-28T20:46:06.503Z"
    },
    {
      "_id": "65df9b8e59af913ca758dca8",
      "emailClient": "superadmin@gueno.com",
      "name": "ISABELLA COELHO FERRAZ",
      "nationality": "Brazil",
      "documentNumber": "41812855885",
      "type": "PERSON",
      "relationship": {
        "type": "DIRECT",
        "name": "BROTHER",
        "startDate": "2016-11-01T00:00:00Z",
        "endDate": ""
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.503Z",
      "updatedAt": "2024-02-28T20:46:06.503Z"
    },
    {
      "_id": "65df9b8e59af913ca758dca6",
      "emailClient": "superadmin@gueno.com",
      "name": "MARIA JULIA PEREIRA COELHO FERRAZ",
      "nationality": "Brazil",
      "documentNumber": "12182211861",
      "type": "PERSON",
      "relationship": {
        "type": "DIRECT",
        "name": "MOTHER",
        "startDate": "2016-11-01T00:00:00Z",
        "endDate": ""
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.503Z",
      "updatedAt": "2024-02-28T20:46:06.503Z"
    },
    {
      "_id": "65df9b8e59af913ca758dca7",
      "emailClient": "superadmin@gueno.com",
      "name": "PEDRO COELHO FERRAZ",
      "nationality": "Brazil",
      "documentNumber": "41812851898",
      "type": "PERSON",
      "relationship": {
        "type": "DIRECT",
        "name": "BROTHER",
        "startDate": "2016-11-01T00:00:00Z",
        "endDate": ""
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.503Z",
      "updatedAt": "2024-02-28T20:46:06.503Z"
    },
    {
      "_id": "65df9b8e59af913ca758dc97",
      "emailClient": "superadmin@gueno.com",
      "name": "GUILHERME DOTTORI GASPAR",
      "nationality": "Brazil",
      "documentNumber": "78537436704",
      "type": "PERSON",
      "relationship": {
        "type": "DIRECT",
        "name": "FATHER",
        "startDate": "2019-07-03T00:00:00Z",
        "endDate": ""
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.493Z",
      "updatedAt": "2024-02-28T20:46:06.493Z"
    },
    {
      "_id": "65df9b8e59af913ca758dc98",
      "emailClient": "superadmin@gueno.com",
      "name": "DANIEL ROCHA DOTTORI GASPAR",
      "nationality": "Brazil",
      "documentNumber": "12916746757",
      "type": "PERSON",
      "relationship": {
        "type": "DIRECT",
        "name": "BROTHER",
        "startDate": "2018-05-11T00:00:00Z",
        "endDate": ""
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.493Z",
      "updatedAt": "2024-02-28T20:46:06.493Z"
    },
    {
      "_id": "65df9b8e59af913ca758dc9c",
      "emailClient": "superadmin@gueno.com",
      "name": "RICARDO DOTTORI GASPAR",
      "nationality": "Brazil",
      "documentNumber": "78290007787",
      "type": "PERSON",
      "relationship": {
        "type": "DIRECT",
        "name": "UNCLE",
        "startDate": "2019-07-07T00:00:00Z",
        "endDate": ""
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.493Z",
      "updatedAt": "2024-02-28T20:46:06.493Z"
    },
    {
      "_id": "65df9b8e59af913ca758dc99",
      "emailClient": "superadmin@gueno.com",
      "name": "LINA MARIANA DA ROCHA ROCHA",
      "nationality": "Brazil",
      "documentNumber": "61840211768",
      "type": "PERSON",
      "relationship": {
        "type": "DIRECT",
        "name": "GRANDPARENT",
        "startDate": "2018-05-12T00:00:00Z",
        "endDate": ""
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.493Z",
      "updatedAt": "2024-02-28T20:46:06.493Z"
    },
    {
      "_id": "65df9b8e59af913ca758dc9d",
      "emailClient": "superadmin@gueno.com",
      "name": "BEATRIZ DOTTORI GASPAR",
      "nationality": "Brazil",
      "documentNumber": "96372230704",
      "type": "PERSON",
      "relationship": {
        "type": "DIRECT",
        "name": "UNCLE",
        "startDate": "2019-07-07T00:00:00Z",
        "endDate": ""
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.493Z",
      "updatedAt": "2024-02-28T20:46:06.493Z"
    },
    {
      "_id": "65df9b8e59af913ca758dc9a",
      "emailClient": "superadmin@gueno.com",
      "name": "MARILIA DOTTORI GASPAR",
      "nationality": "Brazil",
      "documentNumber": "43340164720",
      "type": "PERSON",
      "relationship": {
        "type": "DIRECT",
        "name": "GRANDPARENT",
        "startDate": "2019-07-07T00:00:00Z",
        "endDate": ""
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.493Z",
      "updatedAt": "2024-02-28T20:46:06.493Z"
    },
    {
      "_id": "65df9b8e59af913ca758dc9b",
      "emailClient": "superadmin@gueno.com",
      "name": "LIVIA CRISTINA ROCHA",
      "nationality": "Brazil",
      "documentNumber": "01258288702",
      "type": "PERSON",
      "relationship": {
        "type": "DIRECT",
        "name": "UNCLE",
        "startDate": "2018-05-11T00:00:00Z",
        "endDate": ""
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.493Z",
      "updatedAt": "2024-02-28T20:46:06.493Z"
    },
    {
      "_id": "65df9b8e59af913ca758dc96",
      "emailClient": "superadmin@gueno.com",
      "name": "HULDA CRISTINA ROCHA",
      "nationality": "Brazil",
      "documentNumber": "78537460753",
      "type": "PERSON",
      "relationship": {
        "type": "DIRECT",
        "name": "MOTHER",
        "startDate": "2018-05-11T00:00:00Z",
        "endDate": ""
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.493Z",
      "updatedAt": "2024-02-28T20:46:06.493Z"
    },
    {
      "_id": "65df9b8e59af913ca758dc8e",
      "emailClient": "superadmin@gueno.com",
      "name": "ROSANE DE FATIMA LEITE CASTRO MATOS",
      "nationality": "Brazil",
      "documentNumber": "",
      "type": "PERSON",
      "relationship": {
        "type": "DIRECT",
        "name": "MOTHER",
        "startDate": "2016-08-23T00:00:00Z",
        "endDate": ""
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.488Z",
      "updatedAt": "2024-02-28T20:46:06.488Z"
    },
    {
      "_id": "65df9b8e59af913ca758dc8f",
      "emailClient": "superadmin@gueno.com",
      "name": "RODRIGO CASTRO DE MATOS",
      "nationality": "Brazil",
      "documentNumber": "02349015190",
      "type": "PERSON",
      "relationship": {
        "type": "DIRECT",
        "name": "BROTHER",
        "startDate": "2018-12-08T00:00:00Z",
        "endDate": ""
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.488Z",
      "updatedAt": "2024-02-28T20:46:06.488Z"
    },
    {
      "_id": "65df9b8e59af913ca758dc90",
      "emailClient": "superadmin@gueno.com",
      "name": "FRANCISCO OSMAN PONTES NETO",
      "nationality": "Brazil",
      "documentNumber": "03553203390",
      "type": "PERSON",
      "relationship": {
        "type": "INDIRECT",
        "name": "PARTNER",
        "startDate": "2016-08-23T00:00:00Z",
        "endDate": ""
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.488Z",
      "updatedAt": "2024-02-28T20:46:06.488Z"
    },
    {
      "_id": "65df9b8e59af913ca758dc91",
      "emailClient": "superadmin@gueno.com",
      "name": "ANTONIO DEROMIR NEVES DA SILVA JUNIOR",
      "nationality": "Brazil",
      "documentNumber": "04428410352",
      "type": "PERSON",
      "relationship": {
        "type": "INDIRECT",
        "name": "PARTNER",
        "startDate": "2017-02-28T00:00:00Z",
        "endDate": ""
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.488Z",
      "updatedAt": "2024-02-28T20:46:06.488Z"
    },
    {
      "_id": "65df9b8e59af913ca758dc83",
      "emailClient": "superadmin@gueno.com",
      "name": "PIETRA GIOVANINI MENEZES",
      "nationality": "Brazil",
      "documentNumber": "11396705902",
      "type": "PERSON",
      "relationship": {
        "type": "DIRECT",
        "name": "COUSIN",
        "startDate": "2020-03-21T00:00:00Z",
        "endDate": ""
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.477Z",
      "updatedAt": "2024-02-28T20:46:06.477Z"
    },
    {
      "_id": "65df9b8e59af913ca758dc84",
      "emailClient": "superadmin@gueno.com",
      "name": "VALERIA GOMES DE SOUZA",
      "nationality": "Brazil",
      "documentNumber": "37355713876",
      "type": "PERSON",
      "relationship": {
        "type": "INDIRECT",
        "name": "PARTNER",
        "startDate": "2016-08-23T00:00:00Z",
        "endDate": ""
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.477Z",
      "updatedAt": "2024-02-28T20:46:06.477Z"
    },
    {
      "_id": "65df9b8e59af913ca758dc82",
      "emailClient": "superadmin@gueno.com",
      "name": "AUGUSTO MENEZES",
      "nationality": "Brazil",
      "documentNumber": "46664149915",
      "type": "PERSON",
      "relationship": {
        "type": "DIRECT",
        "name": "UNCLE",
        "startDate": "2020-03-21T00:00:00Z",
        "endDate": ""
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.477Z",
      "updatedAt": "2024-02-28T20:46:06.477Z"
    },
    {
      "_id": "65df9b8e59af913ca758dc7f",
      "emailClient": "superadmin@gueno.com",
      "name": "MITIKO DE MENEZES",
      "nationality": "Brazil",
      "documentNumber": "67420206953",
      "type": "PERSON",
      "relationship": {
        "type": "DIRECT",
        "name": "GRANDPARENT",
        "startDate": "2020-03-21T00:00:00Z",
        "endDate": ""
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.476Z",
      "updatedAt": "2024-02-28T20:46:06.476Z"
    },
    {
      "_id": "65df9b8e59af913ca758dc7e",
      "emailClient": "superadmin@gueno.com",
      "name": "BERENICE ZAPPA SCHANOSKI",
      "nationality": "Brazil",
      "documentNumber": "18544444920",
      "type": "PERSON",
      "relationship": {
        "type": "DIRECT",
        "name": "GRANDPARENT",
        "startDate": "2019-05-05T00:00:00Z",
        "endDate": ""
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.476Z",
      "updatedAt": "2024-02-28T20:46:06.476Z"
    },
    {
      "_id": "65df9b8e59af913ca758dc80",
      "emailClient": "superadmin@gueno.com",
      "name": "LUCIANA ZAPPA SCHANOSKI ARAUJO",
      "nationality": "Brazil",
      "documentNumber": "64013944987",
      "type": "PERSON",
      "relationship": {
        "type": "DIRECT",
        "name": "UNCLE",
        "startDate": "2018-05-11T00:00:00Z",
        "endDate": ""
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.476Z",
      "updatedAt": "2024-02-28T20:46:06.476Z"
    },
    {
      "_id": "65df9b8e59af913ca758dc81",
      "emailClient": "superadmin@gueno.com",
      "name": "ANDREA ZAPPA SCHANOSKI",
      "nationality": "Brazil",
      "documentNumber": "87669595949",
      "type": "PERSON",
      "relationship": {
        "type": "DIRECT",
        "name": "UNCLE",
        "startDate": "2018-05-11T00:00:00Z",
        "endDate": ""
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.476Z",
      "updatedAt": "2024-02-28T20:46:06.476Z"
    },
    {
      "_id": "65df9b8e59af913ca758dc7c",
      "emailClient": "superadmin@gueno.com",
      "name": "CLAUDIA ELIZABETH SCHANOSKI DE MENEZES",
      "nationality": "Brazil",
      "documentNumber": "61030082987",
      "type": "PERSON",
      "relationship": {
        "type": "DIRECT",
        "name": "MOTHER",
        "startDate": "2018-05-11T00:00:00Z",
        "endDate": ""
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.476Z",
      "updatedAt": "2024-02-28T20:46:06.476Z"
    },
    {
      "_id": "65df9b8e59af913ca758dc7d",
      "emailClient": "superadmin@gueno.com",
      "name": "DARIO DE MENEZES",
      "nationality": "Brazil",
      "documentNumber": "49925458900",
      "type": "PERSON",
      "relationship": {
        "type": "DIRECT",
        "name": "FATHER",
        "startDate": "2020-01-18T00:00:00Z",
        "endDate": ""
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.476Z",
      "updatedAt": "2024-02-28T20:46:06.476Z"
    },
    {
      "_id": "65df9b8e59af913ca758dc77",
      "emailClient": "superadmin@gueno.com",
      "name": "ANTONIO DEROMIR NEVES DA SILVA JUNIOR",
      "nationality": "Brazil",
      "documentNumber": "04428410352",
      "type": "PERSON",
      "relationship": {
        "type": "INDIRECT",
        "name": "PARTNER",
        "startDate": "2017-02-28T00:00:00Z",
        "endDate": ""
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.470Z",
      "updatedAt": "2024-02-28T20:46:06.470Z"
    },
    {
      "_id": "65df9b8e59af913ca758dc74",
      "emailClient": "superadmin@gueno.com",
      "name": "ROSANE DE FATIMA LEITE CASTRO MATOS",
      "nationality": "Brazil",
      "documentNumber": "",
      "type": "PERSON",
      "relationship": {
        "type": "DIRECT",
        "name": "MOTHER",
        "startDate": "2016-08-23T00:00:00Z",
        "endDate": ""
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.469Z",
      "updatedAt": "2024-02-28T20:46:06.469Z"
    },
    {
      "_id": "65df9b8e59af913ca758dc75",
      "emailClient": "superadmin@gueno.com",
      "name": "RODRIGO CASTRO DE MATOS",
      "nationality": "Brazil",
      "documentNumber": "02349015190",
      "type": "PERSON",
      "relationship": {
        "type": "DIRECT",
        "name": "BROTHER",
        "startDate": "2018-12-08T00:00:00Z",
        "endDate": ""
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.469Z",
      "updatedAt": "2024-02-28T20:46:06.469Z"
    },
    {
      "_id": "65df9b8e59af913ca758dc76",
      "emailClient": "superadmin@gueno.com",
      "name": "FRANCISCO OSMAN PONTES NETO",
      "nationality": "Brazil",
      "documentNumber": "03553203390",
      "type": "PERSON",
      "relationship": {
        "type": "INDIRECT",
        "name": "PARTNER",
        "startDate": "2016-08-23T00:00:00Z",
        "endDate": ""
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.469Z",
      "updatedAt": "2024-02-28T20:46:06.469Z"
    },
    {
      "_id": "65df9b8e59af913ca758dc6f",
      "emailClient": "superadmin@gueno.com",
      "name": "ANTONIO DEROMIR NEVES DA SILVA JUNIOR",
      "nationality": "Brazil",
      "documentNumber": "04428410352",
      "type": "PERSON",
      "relationship": {
        "type": "INDIRECT",
        "name": "PARTNER",
        "startDate": "2017-02-28T00:00:00Z",
        "endDate": ""
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.462Z",
      "updatedAt": "2024-02-28T20:46:06.462Z"
    },
    {
      "_id": "65df9b8e59af913ca758dc6e",
      "emailClient": "superadmin@gueno.com",
      "name": "FRANCISCO OSMAN PONTES NETO",
      "nationality": "Brazil",
      "documentNumber": "03553203390",
      "type": "PERSON",
      "relationship": {
        "type": "INDIRECT",
        "name": "PARTNER",
        "startDate": "2016-08-23T00:00:00Z",
        "endDate": ""
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.462Z",
      "updatedAt": "2024-02-28T20:46:06.462Z"
    },
    {
      "_id": "65df9b8e59af913ca758dc6d",
      "emailClient": "superadmin@gueno.com",
      "name": "RODRIGO CASTRO DE MATOS",
      "nationality": "Brazil",
      "documentNumber": "02349015190",
      "type": "PERSON",
      "relationship": {
        "type": "DIRECT",
        "name": "BROTHER",
        "startDate": "2018-12-08T00:00:00Z",
        "endDate": ""
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.462Z",
      "updatedAt": "2024-02-28T20:46:06.462Z"
    },
    {
      "_id": "65df9b8e59af913ca758dc6c",
      "emailClient": "superadmin@gueno.com",
      "name": "ROSANE DE FATIMA LEITE CASTRO MATOS",
      "nationality": "Brazil",
      "documentNumber": "",
      "type": "PERSON",
      "relationship": {
        "type": "DIRECT",
        "name": "MOTHER",
        "startDate": "2016-08-23T00:00:00Z",
        "endDate": ""
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T20:46:06.462Z",
      "updatedAt": "2024-02-28T20:46:06.462Z"
    },
    {
      "_id": "65df5c57e6943b8628d500c7",
      "emailClient": "superadmin@gueno.com",
      "name": "TOMAS AGUSTIN RAMELLA SANGUINETTI",
      "nationality": "Brazil",
      "documentNumber": "71867778130",
      "type": "PERSON",
      "relationship": {
        "type": "REPRESENTANTELEGAL",
        "name": "",
        "startDate": "2023-11-27T00:00:00Z",
        "endDate": ""
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": ["65df5c56e6943b8628d500c5"],
      "timeline": ["65df5c57e6943b8628d5013d", "65df9b4c59af913ca758dc4b"],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T16:16:23.001Z",
      "updatedAt": "2024-02-28T20:45:00.562Z",
      "cnep": "65df5c57e6943b8628d500fb",
      "ceis": "65df5c57e6943b8628d50100",
      "ceaf": "65df5c57e6943b8628d50105",
      "aml": "65df5c57e6943b8628d50114",
      "kyc": "65df5c57e6943b8628d5012d",
      "negativeMedia": "65df5c57e6943b8628d50132",
      "registrationStatus": "65df5c57e6943b8628d50137",
      "relatedCompaniesOwnership": "65df5c57e6943b8628d5013c"
    },
    {
      "_id": "65df5c56e6943b8628d500c5",
      "emailClient": "superadmin@gueno.com",
      "name": "GUENO SERVICOS DE TECNOLOGIA LTDA",
      "nationality": "Brazil",
      "documentNumber": "53182966000153",
      "type": "BUSINESS",
      "relationship": {
        "type": "OWNERSHIP",
        "name": "ADMINISTRADOR",
        "startDate": "2023-12-13T00:00:00Z",
        "endDate": ""
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T16:16:22.997Z",
      "updatedAt": "2024-02-28T16:16:22.997Z"
    },
    {
      "_id": "65df5bd0e6943b8628d500b9",
      "emailClient": "superadmin@gueno.com",
      "name": "TOMAS AGUSTIN RAMELLA SANGUINETTI",
      "nationality": "Brazil",
      "documentNumber": "71867778130",
      "type": "PERSON",
      "relationship": {
        "type": "REPRESENTANTELEGAL",
        "name": "",
        "startDate": "2023-11-27T00:00:00Z",
        "endDate": ""
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": ["65df5bd0e6943b8628d500b7"],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T16:14:08.897Z",
      "updatedAt": "2024-02-28T16:14:08.897Z"
    },
    {
      "_id": "65df5bd0e6943b8628d500b7",
      "emailClient": "superadmin@gueno.com",
      "name": "GUENO SERVICOS DE TECNOLOGIA LTDA",
      "nationality": "Brazil",
      "documentNumber": "53182966000153",
      "type": "BUSINESS",
      "relationship": {
        "type": "OWNERSHIP",
        "name": "ADMINISTRADOR",
        "startDate": "2023-12-13T00:00:00Z",
        "endDate": ""
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T16:14:08.890Z",
      "updatedAt": "2024-02-28T16:14:08.890Z"
    },
    {
      "_id": "65df5a5721a1c5c540fc46b6",
      "emailClient": "superadmin@gueno.com",
      "name": "TOMAS AGUSTIN RAMELLA SANGUINETTI",
      "nationality": "Brazil",
      "documentNumber": "71867778130",
      "type": "PERSON",
      "relationship": {
        "type": "REPRESENTANTELEGAL",
        "name": "",
        "startDate": "2023-11-27T00:00:00Z",
        "endDate": ""
      },
      "status": "IN_PROGRESS",
      "relatedPersons": [],
      "relatedCompanies": ["65df5a5721a1c5c540fc46b4"],
      "timeline": ["65df5a5921a1c5c540fc4736"],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T16:07:51.473Z",
      "updatedAt": "2024-02-28T16:07:53.560Z",
      "aml": "65df5a5821a1c5c540fc46ef",
      "negativeMedia": "65df5a5821a1c5c540fc46f9",
      "kyc": "65df5a5821a1c5c540fc46fe",
      "registrationStatus": "65df5a5821a1c5c540fc4703",
      "cnep": "65df5a5821a1c5c540fc4721",
      "ceaf": "65df5a5921a1c5c540fc4726",
      "ceis": "65df5a5921a1c5c540fc472b",
      "relatedCompaniesOwnership": "65df5a5921a1c5c540fc4735"
    },
    {
      "_id": "65df5a5721a1c5c540fc46b4",
      "emailClient": "superadmin@gueno.com",
      "name": "GUENO SERVICOS DE TECNOLOGIA LTDA",
      "nationality": "Brazil",
      "documentNumber": "53182966000153",
      "type": "BUSINESS",
      "relationship": {
        "type": "OWNERSHIP",
        "name": "ADMINISTRADOR",
        "startDate": "2023-12-13T00:00:00Z",
        "endDate": ""
      },
      "status": "NOT_STARTED",
      "relatedPersons": [],
      "relatedCompanies": [],
      "timeline": [],
      "comments": [],
      "assignedUsers": [],
      "files": [],
      "createdAt": "2024-02-28T16:07:51.463Z",
      "updatedAt": "2024-02-28T16:07:51.463Z"
    }
  ]
}

export default kybKycs;

import { buildTimeStringFromTimestamp } from '../../time/timeHelper'

export const kytConsumerUserDataMapperForPDF = (data) => {
  const header = ['User ID', 'Name', 'Date of Birth', 'Country of Residence', 'Country of Nationality', 'Created Time']
  const parsedData = data.map((user) => {
    return [
      user.id,
      `${user.firstName || ''}${user.firstName && user.middleName ? ' ' : ''}${user.middleName || ''}${
        (user.firstName || user.middleName) && user.lastName ? ' ' : ''
      }${user.lastName || ''}`,
      user.dateOfBirth,
      user.countryOfResidence,
      user.countryOfNationality,
      buildTimeStringFromTimestamp(user.createdTimestamp),
    ]
  })

  return {
    columns: header,
    body: parsedData,
  }
}

export const kytConsumerUserDataMapper = (data) => {
  return data.map((user) => {
    return {
      UserID: user.id,
      Name: `${user.firstName || ''}${user.firstName && user.middleName ? ' ' : ''}${user.middleName || ''}${
        (user.firstName || user.middleName) && user.lastName ? ' ' : ''
      }${user.lastName || ''}`,
      DateOfBirth: user.dateOfBirth,
      CountryOfResidence: user.countryOfResidence,
      CountryOfNationality: user.countryOfNationality,
      CreatedTime: buildTimeStringFromTimestamp(user.createdTimestamp),
    }
  })
}

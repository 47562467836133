import { Box, InputLabel, Paper, TextField } from '@mui/material'
import '../kytManualCheck.scss'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RenderTooltip } from '../../../../components/utilities/RenderTooltip'

const GenericBank = ({ setPaymentDetails, isReset, setIsReset }) => {
  const [accountNumber, setAccountNumber] = useState('')
  const [accountType, setAccountType] = useState('')
  const [bankName, setBankName] = useState('')
  const [bankCode, setBankCode] = useState('')
  const [name, setName] = useState('')
  const [specialInstructions, setSpecialInstructions] = useState('')
  const [paymentChannel, setPaymentChannel] = useState('')

  const { t } = useTranslation(['common', 'messages'])
  const [change, setChange] = useState(false)

  useEffect(() => {
    if (change) {
      const body = {
        method: 'GENERIC_BANK_ACCOUNT',
        ...(accountNumber && { accountNumber }),
        ...(accountType && { accountType }),
        ...(bankName && { bankName }),
        ...(bankCode && { bankCode }),
        ...(name && { name }),
        ...(specialInstructions && { specialInstructions }),
        ...(paymentChannel && { paymentChannel }),
      }

      setPaymentDetails(body)
      setChange(false)
    }
  }, [change])

  useEffect(() => {
    if (isReset) {
      setAccountNumber('')
      setAccountType('')
      setBankName('')
      setBankCode('')
      setName('')
      setSpecialInstructions('')
      setPaymentChannel('')
      setIsReset(false)
    }
  }, [isReset])

  return (
    <Paper elevation={0} className="kyt-form">
      <Box className="kyt-form-pair">
        <Box className="kyt-form-pair-label">
          <InputLabel>{t('common:accountNumber')}</InputLabel>
          <RenderTooltip title={'Bank account number.'} />
        </Box>

        <TextField
          fullWidth
          size="small"
          type="text"
          value={accountNumber}
          onChange={(event) => {
            setAccountNumber(event.target.value)
            setChange(true)
          }}
          placeholder={`${t('common:insert')} ${t('common:accountNumber')} ${t('here')}`}
        />
      </Box>
      <Box className="kyt-form-pair">
        <Box className="kyt-form-pair-label">
          <InputLabel>{t('common:accountType')}</InputLabel>
          <RenderTooltip title={'Bank account type. E.g. Checking, Savings etc.'} />
        </Box>

        <TextField
          fullWidth
          size="small"
          type="text"
          value={accountType}
          onChange={(event) => {
            setAccountType(event.target.value)
            setChange(true)
          }}
          placeholder={`${t('common:insert')} ${t('common:accountType')} ${t('here')}`}
        />
      </Box>
      <Box className="kyt-form-pair">
        <Box className="kyt-form-pair-label">
          <InputLabel>{t('common:bankName')}</InputLabel>
          <RenderTooltip title={'Name of the bank.'} />
        </Box>

        <TextField
          fullWidth
          size="small"
          type="text"
          value={bankName}
          onChange={(event) => {
            setBankName(event.target.value)
            setChange(true)
          }}
          placeholder={`${t('common:insert')} ${t('common:bankName')} ${t('here')}`}
        />
      </Box>
      <Box className="kyt-form-pair">
        <Box className="kyt-form-pair-label">
          <InputLabel>{t('common:bankCode')}</InputLabel>
          <RenderTooltip
            title={'Unique identifier of the bank. In some countries, this can be the same as the banks SWIFT code.'}
          />
        </Box>
        <TextField
          fullWidth
          size="small"
          type="text"
          value={bankCode}
          onChange={(event) => {
            setBankCode(event.target.value)
            setChange(true)
          }}
          placeholder={`${t('common:insert')} ${t('common:bankCode')} ${t('here')}`}
        />
      </Box>
      <Box className="kyt-form-pair">
        <Box className="kyt-form-pair-label">
          <InputLabel>{t('common:name')}</InputLabel>
          <RenderTooltip title={'Name of the account holder.'} />
        </Box>
        <TextField
          fullWidth
          size="small"
          type="text"
          value={name}
          onChange={(event) => {
            setName(event.target.value)
            setChange(true)
          }}
          placeholder={`${t('common:insert')} ${t('common:name')} ${t('here')}`}
        />
      </Box>
      <Box className="kyt-form-pair">
        <Box className="kyt-form-pair-label">
          <InputLabel>{t('common:specialInstructions')}</InputLabel>
          <RenderTooltip title={'Special instructions to be specified if any.'} />
        </Box>

        <TextField
          fullWidth
          size="small"
          type="text"
          value={specialInstructions}
          onChange={(event) => {
            setSpecialInstructions(event.target.value)
            setChange(true)
          }}
          placeholder={`${t('common:insert')} ${t('common:specialInstructions')} ${t('here')}`}
        />
      </Box>
      <Box className="kyt-form-pair">
        <Box className="kyt-form-pair-label">
          <InputLabel>{t('common:paymentChannel')}</InputLabel>
          <RenderTooltip title={'Payment Channel.'} />
        </Box>

        <TextField
          fullWidth
          size="small"
          type="text"
          value={paymentChannel}
          onChange={(event) => {
            setPaymentChannel(event.target.value)
            setChange(true)
          }}
          placeholder={`${t('common:insert')} ${t('common:paymentChannel')} ${t('here')}`}
        />
      </Box>
    </Paper>
  )
}

export default GenericBank

import { Box, InputLabel, Paper, TextField } from '@mui/material'
import '../kytManualCheck.scss'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RenderTooltip } from '../../../../components/utilities/RenderTooltip'
import MyDatePicker from '../../../../components/utilities/MyDatePicker'

const PaymentMethodDetails = ({ paymentMethod, paymentMethodAtributes, setPaymentDetails, isReset, setIsReset }) => {
  const initialState = paymentMethodAtributes.reduce((acc, curr) => {
    acc[curr.label] = '';
    return acc;
  }, {});

  const [formValues, setFormValues] = useState(initialState)

  const { t } = useTranslation(['common', 'messages'])

  const componentsByType = {
    text: (value, label, handleChange) => (<TextField
      fullWidth
      size="small"
      type="text"
      value={value !== undefined ? value : ''}
      onChange={(event) => handleChange(label, event.target.value)}
      placeholder={`${t('common:insert')} ${t(`common:${label}`)} ${t('here')}`}
    />),
    date: (value, label, handleChange) => (
      <MyDatePicker
        selectedDate={value !== undefined ? value : ''}
        setSelectedDate={(date) => handleChange(label, date ? date.toISOString() : '')}
      />
    ),
  }

  useEffect(() => {
    const body = {
      method: paymentMethod,
      ...formValues
    }
    setPaymentDetails(body)
  }, [formValues])

  useEffect(() => {
    setFormValues(initialState)
  }, [paymentMethod])

  const handleChange = (label, value) => {
    setFormValues({
      ...formValues,
      [label]: value
    })
  };

  return (
    <Paper elevation={0} className="kyt-form">
      {paymentMethodAtributes.map(item => (
        <Box key={item.label} className="kyt-form-pair">
        <Box className="kyt-form-pair-label">
          <InputLabel>{t(`common:${item.label}`)}</InputLabel>
          <RenderTooltip title={`${item.tooltip}`} />
        </Box>
        {componentsByType[item.type](formValues[item.label], item.label, handleChange)}
      </Box>))}
    </Paper>
  )
}

export default PaymentMethodDetails

import { buildTimeStringFromTimestamp } from '../../time/timeHelper'

export const kytBusinessUserDataMapperForPDF = (data) => {
  const header = [
    'User ID',
    'Legal Name',
    'Exp. Transaction Amount p/Month',
    'Exp. Turnover Amount p/Month',
    'Registration Country',
    'Creation Time',
  ]
  const parsedData = data.map((user) => {
    return [
      user.id,
      user.legalName,
      user.expectedTransactionAmountPerMonth,
      user.expectedTurnoverAmountPerMonth,
      user.registrationCountry,
      buildTimeStringFromTimestamp(user.createdTimestamp),
    ]
  })

  return {
    columns: header,
    body: parsedData,
  }
}

export const kytBusinessUserDataMapper = (data) => {
  return data.map((user) => {
    return {
      UserID: user.id,
      LegalName: user.legalName,
      ExpectedTransactionAmountPerMonth: user.expectedTransactionAmountPerMonth,
      ExpectedTurnoverAmountPerMonth: user.expectedTurnoverAmountPerMonth,
      RegistrationCountry: user.registrationCountry,
      CreatedTime: buildTimeStringFromTimestamp(user.createdTimestamp),
    }
  })
}

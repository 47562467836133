import { buildTimeStringFromTimestamp } from '../../time/timeHelper'

export const loginReportDataMapperForPDF = (data) => {
  const header = ['User', 'Action', 'Status', 'Date']
  const parsedData = data.map((report) => {
    return [
      report.emailUser,
      report.activityDetails.actionType ? report.activityDetails.actionType : 'LOGIN',
      report.activityDetails.actionResult,
      buildTimeStringFromTimestamp(report.createdAt),
    ]
  })

  return {
    columns: header,
    body: parsedData,
  }
}

export const loginReportDataMapper = (data) => {
  return data.map((report) => {
    return {
      User: report.emailUser,
      Action: report.activityDetails.actionType ? report.activityDetails.actionType : 'LOGIN',
      Status: report.activityDetails.actionResult,
      Date: buildTimeStringFromTimestamp(report.createdAt),
    }
  })
}

import { createSlice } from '@reduxjs/toolkit'

export const lagnuageSlice = createSlice({
  name: 'language',
  initialState: {
    lang: 'en',
  },
  reducers: {
    updateLang: (state, action) => {
      state.lang = action.payload
    },
  },
})

export const { updateLang } = lagnuageSlice.actions

export default lagnuageSlice.reducer

import './datatable.scss'
import { Box, Typography, Link, Tooltip, Badge, Icon } from '@mui/material'
import SouthWestIcon from '@mui/icons-material/SouthWest'
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward'
import { buildTimeStringFromTimestamp } from '../time/timeHelper'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { DataGridComponent } from '../datagrid/DataGridComponent'
import { TableIcons } from '../../utilities/TableIcons'
import { setPaymentMethodDetailFilters } from '../../../features/paymentMethodDetail/paymentMethodDetailSlice'

const DatatableExternalPaymentMethodsDetail = ({ setIsLoading }) => {
  const { data: items, pagination } = useSelector((state) => state.paymentMethodDetail)
  const { t } = useTranslation(['common', 'messages'])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(50)
  const dispatch = useDispatch()

  useEffect(() => {
    handleDispatch()
  }, [rowsPerPage, page])

  const handleDispatch = () => {
    const filters = {
      page,
      limit: rowsPerPage,
      offset: rowsPerPage * page,
    }
    dispatch(setPaymentMethodDetailFilters(filters))
  }

  const columnsTransactions = [
    {
      field: 'transactionId',
      flex: 1.8,
      headerName: t('common:transactionId'),
      renderCell: ({ row }) => {
        const transactionIdResume = row.transactionId?.slice(-15)
        return (
          <Tooltip title={row.transactionId}>
            <Link href={`/transactions/${row.transactionId}`} target="_blank">
              <Typography variant="number">..{transactionIdResume}</Typography>
            </Link>
          </Tooltip>
        )
      },
    },
    {
      field: 'type',
      flex: 1,
      headerName: t('common:type'),
      renderCell: (params) => <Box className="cellOverflow">{params.row.type ?? '---'}</Box>,
    },
    {
      field: 'transactionState',
      flex: 1.5,
      headerName: t('common:state'),
      renderCell: (params) => {
        return params.row.transactionState ? <TableIcons type={params.row.transactionState} /> : '---'
      },
    },
    {
      field: 'timestamp',
      flex: 2,
      headerName: t('common:date'),
      renderCell: (params) => (
        <Box className="cellOverflow">{buildTimeStringFromTimestamp(params.row.timestamp) ?? '---'}</Box>
      ),
    },
    {
      field: 'originUserId',
      flex: 1.8,
      headerName: t('common:originUserId'),
      renderCell: ({ row }) => {
        const originUserIdResume = row.originUserId?.slice(-10)
        return (
          <Tooltip title={row.originUserId}>
            <Typography variant="number">{row.originUserId ? `..${originUserIdResume}` : '-'}</Typography>
          </Tooltip>
        )
      },
    },
    {
      field: 'destinationUserId',
      flex: 1.8,
      headerName: t('common:destinationUserId'),
      renderCell: ({ row }) => {
        const destinationUserIdResume = row.destinationUserId?.slice(-10)
        return (
          <Tooltip title={row.destinationUserId}>
            <Typography variant="number">{row.destinationUserId ? `..${destinationUserIdResume}` : '-'}</Typography>
          </Tooltip>
        )
      },
    },
    {
      field: 'direction',
      flex: 1.5,
      headerName: 'Direction',
      renderCell: ({ row }) => {
        const label = row.externalDestination === true ? 'CashOut' : 'CashIn'
        return (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            {row.externalDestination === true ? <ArrowOutwardIcon /> : <SouthWestIcon />}
            <Typography>{label}</Typography>
          </Box>
        )
      },
    },
  ]

  return (
    <DataGridComponent
      rows={Array.isArray(items) && items.length > 0 ? items : []}
      columns={columnsTransactions}
      count={pagination?.totalElements || 0}
      page={page}
      rowsPerPage={rowsPerPage}
      setPage={setPage}
      setRowsPerPage={setRowsPerPage}
      showFilterButton={false}
      showHideColumnsButton={false}
      setIsLoading={setIsLoading}
    />
  )
}

export default DatatableExternalPaymentMethodsDetail

import { useParams } from 'react-router-dom'
import './amlCrypto.scss'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, Button, Fab, InputLabel, Paper, Typography } from '@mui/material'
import Spinner from '../../components/common/spinner/spinner'
import { buildTimeStringFromTimestamp } from '../../components/common/time/timeHelper'
import { Accordion, AccordionDetails, AccordionSummary } from '../../components/common/Accordion/Accordion'
import { useAmlCryptoDetail } from '../pages-hooks/useAmlCrypto'
import { useTranslation } from 'react-i18next'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown'
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp'

const AmlCryptoDetail = () => {
  const { id } = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const amlCryptoDetail = useSelector((state) => state.amlCrypto.amlCryptoDetail)
  useAmlCryptoDetail(id, setIsLoading)
  const { t } = useTranslation(['common', 'messages'])

  const [expanded, setExpanded] = useState([])

  useEffect(() => {
    if (
      amlCryptoDetail?.check?.activity?.suspicious_activity &&
      amlCryptoDetail.check.activity.suspicious_activity.length !== 0
    ) {
      let aux = []
      amlCryptoDetail.check.activity.suspicious_activity.forEach(() => aux.push(false))
      setExpanded(aux)
    }
  }, [amlCryptoDetail])

  const handleChange = (position) => {
    let aux = [...expanded]
    aux[position] = !aux[position]
    setExpanded([...aux])
  }

  const setAllEqual = () => {
    let aux = []
    if (!expanded.includes(true)) {
      expanded.forEach(() => aux.push(true))
    } else {
      expanded.forEach(() => aux.push(false))
    }
    setExpanded([...aux])
  }

  return (
    <Box>
      {isLoading && <Spinner noTransform />}
      {!isLoading && (
        <Box className="aml-manual-wrapper">
          <Paper elevation={0} className="aml-detail-wrapper">
            <Typography variant="title">ID {t('common:detail')}</Typography>

            <Box className="aml-detail-grid">
              <Paper elevation={0} variant="colorPrimary">
                <Box className="aml-detail-id">
                  <Box className="aml-detail-box">
                    <Typography variant="subtitle1">{t('common:reference')} ID</Typography>
                    <Typography variant="number">{id}</Typography>
                  </Box>
                  <Fab
                    size="small"
                    className="icon-global"
                    variant="action"
                    onClick={() => {
                      navigator.clipboard.writeText(id)
                    }}
                  >
                    <ContentCopyOutlinedIcon />
                  </Fab>
                </Box>
              </Paper>

              <Paper elevation={0} variant="colorPrimary">
                <Box className="aml-detail-id">
                  <Box className="aml-detail-box">
                    <Typography variant="subtitle1">{t('common:address')}</Typography>
                    <Typography variant="number">
                      {amlCryptoDetail.address ? amlCryptoDetail.address : '---'}
                    </Typography>
                  </Box>
                  <Fab
                    size="small"
                    className="icon-global"
                    variant="action"
                    onClick={() => {
                      navigator.clipboard.writeText(amlCryptoDetail?.address)
                    }}
                  >
                    <ContentCopyOutlinedIcon />
                  </Fab>
                </Box>
              </Paper>

              <Paper elevation={0} variant="colorPrimary">
                <Box className="aml-detail-id">
                  <Box className="aml-detail-box">
                    <Typography variant="subtitle1">{t('common:protocol')}</Typography>
                    <Typography variant="number">
                      {amlCryptoDetail.protocol ? amlCryptoDetail.protocol : '---'}
                    </Typography>
                  </Box>
                  <Fab
                    size="small"
                    className="icon-global"
                    variant="action"
                    onClick={() => {
                      navigator.clipboard.writeText(amlCryptoDetail?.protocol)
                    }}
                  >
                    <ContentCopyOutlinedIcon />
                  </Fab>
                </Box>
              </Paper>
            </Box>

            {(!amlCryptoDetail || !amlCryptoDetail.address) && (
              <Box className="error-msg">
                <Button size="small" variant="outlined" color="error">
                  {t('messages:checkNotFound')}
                </Button>
              </Box>
            )}
          </Paper>

          <Paper elevation={0} className="aml-detail-wrapper">
            <Box className="aml-detail-id">
              <Typography variant="title">{t('common:basicDetails')}</Typography>
            </Box>

            <Box className="aml-detail-grid">
              <Paper elevation={0} variant="colorPrimary" className="aml-detail-box">
                <Typography variant="subtitle1">{t('common:type')}</Typography>
                <Typography variant="h5">{amlCryptoDetail.type ? amlCryptoDetail.type : '---'}</Typography>
              </Paper>

              <Paper elevation={0} variant="colorPrimary" className="aml-detail-box">
                <Typography variant="subtitle1">
                  {t('common:isValid')} {t('common:address')}
                </Typography>
                <Typography variant="h5">
                  {amlCryptoDetail.check?.is_address_valid ? t('common:valid') : t('common:invalid')}
                </Typography>
              </Paper>

              <Paper elevation={0} variant="colorPrimary" className="aml-detail-box">
                <Typography variant="subtitle1">{t('common:categories')}</Typography>
                <Typography variant="h5">
                  {amlCryptoDetail.check?.self?.category ? amlCryptoDetail.check.self.category.toString() : '---'}
                </Typography>
              </Paper>

              <Paper elevation={0} variant="colorPrimary" className="aml-detail-box">
                <Typography variant="subtitle1">{t('common:score')}</Typography>
                <Typography variant="number">
                  {amlCryptoDetail.check?.risk?.score ? amlCryptoDetail.check.risk.score : '---'}
                </Typography>
              </Paper>

              <Paper elevation={0} variant="colorPrimary" className="aml-detail-box">
                <Typography variant="subtitle1">{t('common:level')}</Typography>
                <Typography variant="number">
                  {amlCryptoDetail.check?.risk?.level ? amlCryptoDetail.check.risk.level : '---'}
                </Typography>
              </Paper>

              <Paper elevation={0} variant="colorPrimary" className="aml-detail-box">
                <Typography variant="subtitle1">{t('common:date')}</Typography>
                <Typography variant="number">
                  {amlCryptoDetail.createdAt ? buildTimeStringFromTimestamp(amlCryptoDetail.createdAt) : '---'}
                </Typography>
              </Paper>
            </Box>
          </Paper>

          <Paper elevation={0} className="aml-detail-wrapper">
            <Box className="aml-detail-id">
              <Typography variant="title">{t('common:suspiciousActivity')}</Typography>

              {amlCryptoDetail?.check?.activity?.suspicious_activity &&
              amlCryptoDetail.check.activity.suspicious_activity.length !== 0 ? (
                <Button variant="outlined" onClick={() => setAllEqual()}>
                  {expanded.includes(true) ? t('common:hideAll') : t('common:expandAll')}
                </Button>
              ) : (
                <Typography variant="subtitle3">{t('common:noHitsToShow')}</Typography>
              )}
            </Box>

            {amlCryptoDetail?.check?.activity?.suspicious_activity &&
              amlCryptoDetail.check.activity.suspicious_activity.length !== 0 &&
              amlCryptoDetail.check.activity.suspicious_activity.map((activity, index) => (
                <Accordion variant="medium" key={index} expanded={expanded[index]} onChange={() => handleChange(index)}>
                  <AccordionSummary expandIcon={null}>
                    <Box className="aml-detail-accordion-wrapper">
                      <Box className="aml-detail-input">
                        <InputLabel>{t('common:category')}:</InputLabel>
                        <Typography variant="subtitle3">
                          {activity.category ? activity.category.toUpperCase() : '---'}
                        </Typography>
                      </Box>
                      <Box className="aml-detail-input">
                        <InputLabel>{t('common:description')}:</InputLabel>
                        <Typography variant="subtitle3">
                          {activity.description ? activity.description : '---'}
                        </Typography>
                      </Box>
                    </Box>
                    <Button
                      variant="outlinedBlank"
                      onChange={(e) => {
                        e.stopPropagation()
                        return handleChange(index)
                      }}
                    >
                      {expanded[index] ? <KeyboardDoubleArrowDownIcon /> : <KeyboardDoubleArrowUpIcon />}
                    </Button>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Paper elevation={0} variant="colorSecondary" className="aml-detail-accordion-wrapper">
                      <Box className="aml-detail-input">
                        <InputLabel>{t('common:aggrType')}:</InputLabel>
                        <Typography variant="subtitle3">{activity.aggr_type ? activity.aggr_type : '---'}</Typography>
                      </Box>
                      <Box className="aml-detail-input">
                        <InputLabel>{t('common:entity')}:</InputLabel>
                        <Typography variant="subtitle3">{activity.entity ? activity.entity : '---'}</Typography>
                      </Box>
                      <Box className="aml-detail-input">
                        <InputLabel>{t('common:transactionCount')}:</InputLabel>
                        <Typography variant="subtitle3">{activity.txn_cnt ? activity.txn_cnt : '---'}</Typography>
                      </Box>
                      <Box className="aml-detail-input">
                        <InputLabel>{t('common:transactionDirect')}:</InputLabel>
                        <Typography variant="subtitle3">{activity.txn_direct ? activity.txn_direct : '---'}</Typography>
                      </Box>
                      <Box className="aml-detail-input">
                        <InputLabel>{t('common:transactionHashes')}:</InputLabel>
                        <Box className="hash-table">
                          {activity.txn_hashes.map((hash, index) => (
                            <Box className="aml-detail-input" key={index}>
                              <InputLabel>{index + 1}:</InputLabel>
                              <Typography variant="subtitle3">{hash}</Typography>
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    </Paper>
                  </AccordionDetails>
                </Accordion>
              ))}
          </Paper>
        </Box>
      )}
    </Box>
  )
}

export default AmlCryptoDetail

import * as React from 'react'
import Avatar from '@mui/material/Avatar'

function stringToColor(string) {
  let hash = 0
  let i

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    let value = (hash >> (i * 8)) & 0xff
    // value = Math.min(255, value + 60)
    color += `00${value.toString(16)}`.slice(-2)
  }
  /* eslint-enable no-bitwise */

  return color
}

function stringToBGColor(string) {
  const color = stringToColor(string)
  const rgb = parseInt(color.slice(1), 16)
  const r = (rgb >> 16) & 0xff
  const g = (rgb >> 8) & 0xff
  const b = rgb & 0xff
  return `rgba(${r}, ${g}, ${b}, 0.1)`
}

function stringAvatar(name, width, height, fontSize) {
  return {
    sx: {
      bgcolor: stringToBGColor(name),
      color: stringToColor(name),
      height: height ?? 'auto',
      width: width ?? 'auto',
      fontSize: fontSize ?? '0.75rem',
      fontWeight: 600,
      fontFamily: 'Roboto',
      border: `1px solid ${stringToColor(name)}`,
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1]?.length > 0 ? name.split(' ')[1][0] : ''}`,
  }
}

export default function LetterAvatar({ fullName, width, height, fontSize }) {
  return <Avatar {...stringAvatar(fullName, width, height, fontSize)} />
}

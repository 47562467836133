import './styles/style.scss'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'
import { CheckJWT } from './CheckJWT'
import ComingSoon from './components/common/comingSoon/comingSoon'
import Home from './pages/home/Home'
import Login from './pages/login/login'
import Rule from './pages/rule/Rule'
import Transaction from './pages/kyt/transaction/Transaction'
import ExternalPaymentMethods from './pages/kyt/externalPaymentMethods/ExternalPaymentMethods'
import TransactionDetail from './pages/kyt/transaction/TransactionDetail'
import User from './pages/kyt/user/User'
import UserDetail from './pages/kyt/user/UserDetail'
import HealthChecks from './pages/health-checks/HealthChecks'
import { useDispatch, useSelector } from 'react-redux'
import { performLogin, performLogout } from './features/auth/loginSlice'
import Users from './pages/admin/users/Users'
import Roles from './pages/admin/roles/Roles'
import OppositionControl from './pages/admin/cpo/OppositionControl'
import Profile from './pages/profile/Profile'
import RoleDetails from './pages/admin/roles/RoleDetails'
import { performLogoutRoles } from './features/roles/rolesSlice'
import { performLogoutUsers } from './features/users/userSlice'
import { performLogoutTransactions } from './features/transactions/transactionSlice'
import { performLogoutRules } from './features/rules/rulesSlice'
import { performLogoutUserAdmin } from './features/usersAdmin/userAdminSlice'
import Consumptions from './pages/admin/consumptions/Consumptions'
import Reports from './pages/reports/reports'
import ReportRouter from './pages/reports/report/reportRouter'
import CaseManagement from './pages/case-management/CaseManagement'
import Case from './pages/case-management/cases/Case'
import SecurityParams from './pages/admin/securityParams/SecurityParams'
import AlertSettings from './pages/admin/alertSettings/AlertSettings'
import Onboarding from './pages/onboarding/Onboarding'
import OnboardingDetail from './pages/onboarding/OnboardingDetail'
import EcommerceRisk from './pages/ecommerceRisk/EcommerceRisk'
import EcommerceRiskDetail from './pages/ecommerceRisk/EcommerceRiskDetail'
import OnboardingManualCheck from './pages/onboarding/OnboardingManualCheck'
import AmlManualCheck from './pages/aml/AmlManualCheck'
import Aml from './pages/aml/Aml'
import AmlDetail from './pages/aml/AmlDetail'
import AmlCrypto from './pages/amlCrypto/AmlCrypto'
import AmlCryptoDetail from './pages/amlCrypto/AmlCryptoDetail'
import AmlCryptoManualCheck from './pages/amlCrypto/AmlCryptoManualCheck'
import Webhooks from './pages/admin/webhooks/Webhooks'
import GovCheck from './pages/govCheck/GovCheck'
import GovCheckManualCheck from './pages/govCheck/GovCheckManualCheck'
import GovCheckDetail from './pages/govCheck/GovCheckDetail'
import Kyb from './pages/kyb/Kyb'
import KybDetailPerson from './pages/kyb/KybDetailPerson'
import Checklist from './pages/admin/checklist/Checklist'
import KybLists from './pages/kyb/KybLists'
import KybNotifications from './pages/kyb/KybNotifications'
import { Suspense } from 'react'
import Lists from './pages/admin/lists/Lists'
import { dsTheme } from './theme'
import ReactGA from 'react-ga'
import DemoHome from './pages/home/DemoHome'
import KytManualCheck from './pages/kyt/manualCheck/KytManualCheck'
import Exploration from './pages/exploration/Exploration'
import Device from './pages/device/device'
import DeviceDetails from './pages/device/deviceDetails'
import WorkflowsBuilder from './pages/workflows/workflowsBuilder'
import WorkflowsOptions from './pages/workflows/workflowsOptions'
import WorkflowsAi from './pages/workflows/workflowsAi'
import Workflows from './pages/workflows/workflows'
import RiskMatrix from './pages/admin/riskMatrix/riskMatrix'
import RiskMatrixCreation from './pages/admin/riskMatrix/riskMatrixCreate'
import Batch from './pages/batch/Batch'
import { dsThemeDark } from './theme-dark'
import BatchDetails from './pages/batch/batchDetails/BatchDetails'
import { LicenseInfo } from '@mui/x-license'
import SuspenseSpinner from './components/common/spinner/suspenseSpinner'
import { LoadScript } from '@react-google-maps/api'
import CaseWorkflows from './pages/case-management/caseWorkflows/CaseWorkflows'
import WorkflowCreation from './pages/case-management/caseWorkflows/CaseWorkflowCreate'
import ExternalPaymentMethodsDetail from './pages/kyt/externalPaymentMethods/ExternalPaymentMethodsDetail'
import KybDetailBusiness from './pages/kyb/KybDetailBusiness'
import { Kyc } from './pages/kyc'

function App() {
  LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY)
  ReactGA.initialize('G-MJ3KBQ70LT')
  const { accessToken, activeServices } = useSelector((state) => state.login)
  const { theme } = useSelector((state) => state.theme)
  const dispatch = useDispatch()

  const handleUserLogin = (loggedUser) => {
    if (loggedUser) {
      dispatch(performLogin(loggedUser.access_token))
    } else {
      dispatch(performLogout())
      dispatch(performLogoutRoles())
      dispatch(performLogoutUsers())
      dispatch(performLogoutTransactions())
      dispatch(performLogoutRules())
      dispatch(performLogoutUserAdmin())
      fetch(`${process.env.REACT_APP_BASEURL}/auth/logout`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
    }
  }

  return (
    <Suspense fallback={<SuspenseSpinner />}>
      <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
        <ThemeProvider theme={theme === 'light' ? dsTheme : dsThemeDark}>
          <BrowserRouter>
            <Routes>
              <Route path="/">
                <Route
                  index
                  element={
                    !accessToken ? (
                      <Login handleUserLogin={handleUserLogin} />
                    ) : process.env.REACT_APP_IS_DEMO ? (
                      <CheckJWT
                        path={'/'}
                        activeServices={activeServices}
                        next={<DemoHome />}
                        name="overview"
                        handleUserLogout={handleUserLogin}
                      />
                    ) : (
                      <CheckJWT
                        path={'/'}
                        activeServices={activeServices}
                        next={<Home />}
                        name="overview"
                        handleUserLogout={handleUserLogin}
                      />
                    )
                  }
                />
              </Route>
              <Route path="/case-management">
                <Route
                  index
                  element={
                    !accessToken ? (
                      <Login handleUserLogin={handleUserLogin} />
                    ) : (
                      <CheckJWT
                        path={'/case-management'}
                        activeServices={activeServices}
                        next={<CaseManagement />}
                        handleUserLogout={handleUserLogin}
                        name="casesAndInvestigation"
                      />
                    )
                  }
                />
                <Route
                  path=":tab"
                  element={
                    !accessToken ? (
                      <Login handleUserLogin={handleUserLogin} />
                    ) : (
                      <CheckJWT
                        path={'/case-management'}
                        activeServices={activeServices}
                        next={<CaseManagement />}
                        handleUserLogout={handleUserLogin}
                        name="casesAndInvestigation"
                      />
                    )
                  }
                />
              </Route>
              <Route
                path="/case-management/case/:id"
                element={
                  !accessToken ? (
                    <Login handleUserLogin={handleUserLogin} />
                  ) : (
                    <CheckJWT
                      path={'/case-management/case/:id'}
                      activeServices={activeServices}
                      next={<Case />}
                      handleUserLogout={handleUserLogin}
                      name="casesAndInvestigation"
                    />
                  )
                }
              />
              <Route path="/case-workflows">
                <Route
                  index
                  element={
                    !accessToken ? (
                      <Login handleUserLogin={handleUserLogin} />
                    ) : (
                      <CheckJWT
                        path={'/case-workflows'}
                        activeServices={activeServices}
                        next={<CaseWorkflows />}
                        handleUserLogout={handleUserLogin}
                        name="caseWorkflows"
                      />
                    )
                  }
                />
                <Route
                  path="creation"
                  element={
                    !accessToken ? (
                      <Login handleUserLogin={handleUserLogin} />
                    ) : (
                      <CheckJWT
                        path={'/case-workflows'}
                        activeServices={activeServices}
                        next={<WorkflowCreation />}
                        handleUserLogout={handleUserLogin}
                        name="caseWorkflows"
                      />
                    )
                  }
                />
                <Route
                  path=":tab"
                  element={
                    !accessToken ? (
                      <Login handleUserLogin={handleUserLogin} />
                    ) : (
                      <CheckJWT
                        path={'/case-workflows'}
                        activeServices={activeServices}
                        next={<CaseWorkflows />}
                        handleUserLogout={handleUserLogin}
                        name="caseWorkflows"
                      />
                    )
                  }
                />
              </Route>
              <Route
                path="/case-workflows/case/:id"
                element={
                  !accessToken ? (
                    <Login handleUserLogin={handleUserLogin} />
                  ) : (
                    <CheckJWT
                      path={'/case-workflows/case/:id'}
                      activeServices={activeServices}
                      next={<WorkflowCreation />}
                      handleUserLogout={handleUserLogin}
                      name="caseWorkflows"
                    />
                  )
                }
              />
              <Route path="/health-checks">
                <Route
                  key="HealthChecks"
                  index
                  element={
                    !accessToken ? (
                      <Login handleUserLogin={handleUserLogin} />
                    ) : (
                      <CheckJWT
                        path={'/health-checks'}
                        activeServices={activeServices}
                        next={<HealthChecks key="HealthChecks" activeServices={activeServices} />}
                        handleUserLogout={handleUserLogin}
                        name="healthchecks"
                      />
                    )
                  }
                />
              </Route>
              <Route path="/onboardings">
                <Route
                  index
                  element={
                    !accessToken ? (
                      <Login handleUserLogin={handleUserLogin} />
                    ) : (
                      <CheckJWT
                        path={'/onboardings'}
                        activeServices={activeServices}
                        next={<Onboarding />}
                        handleUserLogout={handleUserLogin}
                        name="onboarding"
                      />
                    )
                  }
                />
                <Route
                  path=":id"
                  element={
                    !accessToken ? (
                      <Login handleUserLogin={handleUserLogin} />
                    ) : (
                      <CheckJWT
                        path={'/onboardings/:id'}
                        activeServices={activeServices}
                        next={<OnboardingDetail />}
                        handleUserLogout={handleUserLogin}
                        name="onboarding"
                      />
                    )
                  }
                />
                <Route
                  path="manual"
                  element={
                    !accessToken ? (
                      <Login handleUserLogin={handleUserLogin} />
                    ) : (
                      <CheckJWT
                        path={'/onboardings/manual'}
                        activeServices={activeServices}
                        next={<OnboardingManualCheck />}
                        handleUserLogout={handleUserLogin}
                        name="onboardingManual"
                      />
                    )
                  }
                />
              </Route>
              <Route path="/kyb">
                <Route
                  index
                  element={
                    !accessToken ? (
                      <Login handleUserLogin={handleUserLogin} />
                    ) : (
                      <CheckJWT
                        path={'/kyb'}
                        activeServices={activeServices}
                        next={<Kyb />}
                        handleUserLogout={handleUserLogin}
                        name="kyb"
                      />
                    )
                  }
                />
                <Route
                  path=":tab"
                  element={
                    !accessToken ? (
                      <Login handleUserLogin={handleUserLogin} />
                    ) : (
                      <CheckJWT
                        path={'/kyb'}
                        activeServices={activeServices}
                        next={<Kyb />}
                        handleUserLogout={handleUserLogin}
                        name="kyb"
                      />
                    )
                  }
                />
                <Route
                  path=":country/detail/:id"
                  element={
                    !accessToken ? (
                      <Login handleUserLogin={handleUserLogin} />
                    ) : (
                      <CheckJWT
                        path={'/kyb/:country/detail/:id'}
                        activeServices={activeServices}
                        next={<KybDetailBusiness />}
                        handleUserLogout={handleUserLogin}
                        name="kyb"
                      />
                    )
                  }
                />
                <Route path="/kyb/lists">
                  <Route
                    index
                    element={
                      !accessToken ? (
                        <Login handleUserLogin={handleUserLogin} />
                      ) : (
                        <CheckJWT
                          path={'/kyb/lists'}
                          activeServices={activeServices}
                          next={<KybLists />}
                          handleUserLogout={handleUserLogin}
                          name="blacklists"
                        />
                      )
                    }
                  />
                  <Route
                    path=":tab"
                    element={
                      !accessToken ? (
                        <Login handleUserLogin={handleUserLogin} />
                      ) : (
                        <CheckJWT
                          path={'/kyb/lists'}
                          activeServices={activeServices}
                          next={<KybLists />}
                          handleUserLogout={handleUserLogin}
                          name="blacklists"
                        />
                      )
                    }
                  />
                </Route>
                <Route path="/kyb/notifications">
                  <Route
                    index
                    element={
                      !accessToken ? (
                        <Login handleUserLogin={handleUserLogin} />
                      ) : (
                        <CheckJWT
                          path={'/kyb/notifications'}
                          activeServices={activeServices}
                          next={<KybNotifications />}
                          handleUserLogout={handleUserLogin}
                          name="companyNotifications"
                        />
                      )
                    }
                  />
                  <Route
                    path=":tab"
                    element={
                      !accessToken ? (
                        <Login handleUserLogin={handleUserLogin} />
                      ) : (
                        <CheckJWT
                          path={'/kyb/notifications'}
                          activeServices={activeServices}
                          next={<KybNotifications />}
                          handleUserLogout={handleUserLogin}
                          name="companyNotifications"
                        />
                      )
                    }
                  />
                </Route>
              </Route>
              <Route
                path="/kyc/:country/detail/:id"
                element={
                  !accessToken ? (
                    <Login handleUserLogin={handleUserLogin} />
                  ) : (
                    <CheckJWT
                      path={'/kyc/:country/detail/:id'}
                      activeServices={activeServices}
                      next={<KybDetailPerson />}
                      handleUserLogout={handleUserLogin}
                      name="kyc"
                    />
                  )
                }
              />
              <Route path="/checklists">
                <Route
                  index
                  element={
                    !accessToken ? (
                      <Login handleUserLogin={handleUserLogin} />
                    ) : (
                      <CheckJWT
                        path={'/checklists'}
                        activeServices={activeServices}
                        next={<Checklist />}
                        handleUserLogout={handleUserLogin}
                        name="checklists"
                      />
                    )
                  }
                />
              </Route>
              <Route
                index
                element={
                  !accessToken ? (
                    <Login handleUserLogin={handleUserLogin} />
                  ) : (
                    <CheckJWT
                      path={'/checklists'}
                      activeServices={activeServices}
                      next={<Checklist />}
                      handleUserLogout={handleUserLogin}
                      name="checklists"
                    />
                  )
                }
              />
              <Route path="/checklists">
                <Route
                  index
                  element={
                    !accessToken ? (
                      <Login handleUserLogin={handleUserLogin} />
                    ) : (
                      <CheckJWT
                        path={'/checklists'}
                        activeServices={activeServices}
                        next={<Checklist />}
                        handleUserLogout={handleUserLogin}
                        name="checklists"
                      />
                    )
                  }
                />
              </Route>
              <Route path="/aml">
                <Route
                  index
                  element={
                    !accessToken ? (
                      <Login handleUserLogin={handleUserLogin} />
                    ) : (
                      <CheckJWT
                        path={'/aml'}
                        activeServices={activeServices}
                        next={<Aml />}
                        handleUserLogout={handleUserLogin}
                        name="aml"
                      />
                    )
                  }
                />
                <Route
                  path=":id"
                  element={
                    !accessToken ? (
                      <Login handleUserLogin={handleUserLogin} />
                    ) : (
                      <CheckJWT
                        path={'/aml/:id'}
                        activeServices={activeServices}
                        next={<AmlDetail />}
                        handleUserLogout={handleUserLogin}
                        name="aml"
                      />
                    )
                  }
                />
                <Route
                  path="manual"
                  element={
                    !accessToken ? (
                      <Login handleUserLogin={handleUserLogin} />
                    ) : (
                      <CheckJWT
                        path={'/aml/manual'}
                        activeServices={activeServices}
                        next={<AmlManualCheck />}
                        handleUserLogout={handleUserLogin}
                        name="amlManual"
                      />
                    )
                  }
                />
              </Route>
              <Route path="/amlCrypto">
                <Route
                  index
                  element={
                    !accessToken ? (
                      <Login handleUserLogin={handleUserLogin} />
                    ) : (
                      <CheckJWT
                        path={'/amlCrypto'}
                        activeServices={activeServices}
                        next={<AmlCrypto />}
                        handleUserLogout={handleUserLogin}
                        name="amlCrypto"
                      />
                    )
                  }
                />
                <Route
                  path=":id"
                  element={
                    !accessToken ? (
                      <Login handleUserLogin={handleUserLogin} />
                    ) : (
                      <CheckJWT
                        path={'/amlCrypto/:id'}
                        activeServices={activeServices}
                        next={<AmlCryptoDetail />}
                        handleUserLogout={handleUserLogin}
                        name="amlCrypto"
                      />
                    )
                  }
                />
                <Route
                  path="manual"
                  element={
                    !accessToken ? (
                      <Login handleUserLogin={handleUserLogin} />
                    ) : (
                      <CheckJWT
                        path={'/amlCrypto/manual'}
                        activeServices={activeServices}
                        next={<AmlCryptoManualCheck />}
                        handleUserLogout={handleUserLogin}
                        name="amlCryptoManual"
                      />
                    )
                  }
                />
              </Route>
              <Route path="/ecommerceRisk">
                <Route
                  index
                  element={
                    !accessToken ? (
                      <Login handleUserLogin={handleUserLogin} />
                    ) : (
                      <CheckJWT
                        path={'/ecommerceRisk'}
                        activeServices={activeServices}
                        next={<EcommerceRisk />}
                        handleUserLogout={handleUserLogin}
                        name="analysis"
                      />
                    )
                  }
                />
                <Route
                  path=":id"
                  element={
                    !accessToken ? (
                      <Login handleUserLogin={handleUserLogin} />
                    ) : (
                      <CheckJWT
                        path={'/ecommerceRisk/:id'}
                        activeServices={activeServices}
                        next={<EcommerceRiskDetail />}
                        handleUserLogout={handleUserLogin}
                        name="analysis"
                      />
                    )
                  }
                />
              </Route>
              <Route path="/govCheck">
                <Route
                  index
                  element={
                    !accessToken ? (
                      <Login handleUserLogin={handleUserLogin} />
                    ) : (
                      <CheckJWT
                        path={'/govCheck'}
                        activeServices={activeServices}
                        next={<GovCheck />}
                        handleUserLogout={handleUserLogin}
                        name="govcheck"
                      />
                    )
                  }
                />
                <Route
                  path=":id"
                  element={
                    !accessToken ? (
                      <Login handleUserLogin={handleUserLogin} />
                    ) : (
                      <CheckJWT
                        path={'/govCheck/:id'}
                        activeServices={activeServices}
                        next={<GovCheckDetail />}
                        handleUserLogout={handleUserLogin}
                        name="govcheck"
                      />
                    )
                  }
                />
                <Route
                  path="manual"
                  element={
                    !accessToken ? (
                      <Login handleUserLogin={handleUserLogin} />
                    ) : (
                      <CheckJWT
                        path={'/govCheck/manual'}
                        activeServices={activeServices}
                        next={<GovCheckManualCheck />}
                        handleUserLogout={handleUserLogin}
                        name="govcheckManual"
                      />
                    )
                  }
                />
              </Route>
              <Route path="/kyt/manual">
                <Route
                  index
                  element={
                    !accessToken ? (
                      <Login handleUserLogin={handleUserLogin} />
                    ) : (
                      <CheckJWT
                        path={'/kyt/manual'}
                        activeServices={activeServices}
                        next={<KytManualCheck />}
                        handleUserLogout={handleUserLogin}
                        name="kytManualCheck"
                      />
                    )
                  }
                />
                <Route
                  path=":tab"
                  element={
                    !accessToken ? (
                      <Login handleUserLogin={handleUserLogin} />
                    ) : (
                      <CheckJWT
                        path={'/kyt/manual/:tab'}
                        activeServices={activeServices}
                        next={<KytManualCheck />}
                        handleUserLogout={handleUserLogin}
                        name="kytManualCheck"
                      />
                    )
                  }
                />
              </Route>
              <Route
                path="/externalPaymentMethods"
                element={
                  !accessToken ? (
                    <Login handleUserLogin={handleUserLogin} />
                  ) : (
                    <CheckJWT
                      path={'/externalPaymentMethods'}
                      activeServices={activeServices}
                      next={<ExternalPaymentMethods />}
                      handleUserLogout={handleUserLogin}
                      name="externalPaymentMethods"
                    />
                  )
                }
              />
              <Route path="/externalPaymentMethods">
                <Route
                  path=":method/:methodId"
                  element={
                    !accessToken ? (
                      <Login handleUserLogin={handleUserLogin} />
                    ) : (
                      <CheckJWT
                        path={'/externalPaymentMethods/:method/:methodId'}
                        activeServices={activeServices}
                        next={<ExternalPaymentMethodsDetail />}
                        handleUserLogout={handleUserLogin}
                        name="paymentmethodsdetail"
                      />
                    )
                  }
                />
              </Route>
              <Route path="/transactions">
                <Route
                  index
                  element={
                    !accessToken ? (
                      <Login handleUserLogin={handleUserLogin} />
                    ) : (
                      <CheckJWT
                        path={'/transactions'}
                        activeServices={activeServices}
                        next={<Transaction />}
                        handleUserLogout={handleUserLogin}
                        name="transactions"
                      />
                    )
                  }
                />
                <Route
                  path=":id"
                  element={
                    !accessToken ? (
                      <Login handleUserLogin={handleUserLogin} />
                    ) : (
                      <CheckJWT
                        path={'/transactions/:id'}
                        activeServices={activeServices}
                        next={<TransactionDetail />}
                        handleUserLogout={handleUserLogin}
                        name="transactions"
                      />
                    )
                  }
                />
              </Route>
              <Route path="/users">
                <Route
                  index
                  element={
                    !accessToken ? (
                      <Login handleUserLogin={handleUserLogin} />
                    ) : (
                      <CheckJWT
                        path={'/users'}
                        activeServices={activeServices}
                        next={<User />}
                        handleUserLogout={handleUserLogin}
                        name="users"
                      />
                    )
                  }
                />
                <Route
                  path=":id"
                  element={
                    !accessToken ? (
                      <Login handleUserLogin={handleUserLogin} />
                    ) : (
                      <CheckJWT
                        path={'/users/:id'}
                        activeServices={activeServices}
                        next={<UserDetail />}
                        handleUserLogout={handleUserLogin}
                        name="users"
                      />
                    )
                  }
                />
              </Route>
              <Route path="/rules">
                <Route
                  index
                  element={
                    !accessToken ? (
                      <Login handleUserLogin={handleUserLogin} />
                    ) : (
                      <CheckJWT
                        path={'/rules'}
                        activeServices={activeServices}
                        next={<Rule />}
                        handleUserLogout={handleUserLogin}
                        name="rules"
                      />
                    )
                  }
                />
                <Route
                  path=":actualTab"
                  element={
                    !accessToken ? (
                      <Login handleUserLogin={handleUserLogin} />
                    ) : (
                      <CheckJWT
                        path={'/rules'}
                        activeServices={activeServices}
                        next={<Rule />}
                        handleUserLogout={handleUserLogin}
                        name="rules"
                      />
                    )
                  }
                />
              </Route>
              {process.env.REACT_APP_IS_DEMO && (
                <Route path="/exploration">
                  <Route
                    index
                    element={
                      !accessToken ? (
                        <Login handleUserLogin={handleUserLogin} />
                      ) : (
                        <CheckJWT
                          path={'/exploration'}
                          activeServices={activeServices}
                          next={<Exploration />}
                          handleUserLogout={handleUserLogin}
                          name="explorationInDepth"
                        />
                      )
                    }
                  />
                </Route>
              )}
              {process.env.REACT_APP_IS_DEMO && (
                <Route path="/device">
                  <Route
                    index
                    element={
                      !accessToken ? (
                        <Login handleUserLogin={handleUserLogin} />
                      ) : (
                        <CheckJWT
                          path={'/device'}
                          activeServices={activeServices}
                          next={<Device />}
                          handleUserLogout={handleUserLogin}
                          name="deviceIntelligence"
                        />
                      )
                    }
                  />
                  <Route
                    path=":id"
                    element={
                      !accessToken ? (
                        <Login handleUserLogin={handleUserLogin} />
                      ) : (
                        <CheckJWT
                          path={'/device/:id'}
                          activeServices={activeServices}
                          next={<DeviceDetails />}
                          handleUserLogout={handleUserLogin}
                          name="deviceIntelligence"
                        />
                      )
                    }
                  />
                </Route>
              )}
              {process.env.REACT_APP_IS_DEMO && (
                <Route path="/workflows">
                  <Route
                    index
                    element={
                      !accessToken ? (
                        <Login handleUserLogin={handleUserLogin} />
                      ) : (
                        <CheckJWT
                          path={'/workflows'}
                          activeServices={activeServices}
                          next={<Workflows />}
                          handleUserLogout={handleUserLogin}
                          name="workflows"
                        />
                      )
                    }
                  />
                  <Route
                    path="options"
                    element={
                      !accessToken ? (
                        <Login handleUserLogin={handleUserLogin} />
                      ) : (
                        <CheckJWT
                          path={'/workflows/options'}
                          activeServices={activeServices}
                          next={<WorkflowsOptions />}
                          handleUserLogout={handleUserLogin}
                          name="workflowsOptions"
                        />
                      )
                    }
                  />
                  <Route
                    path="ai"
                    element={
                      !accessToken ? (
                        <Login handleUserLogin={handleUserLogin} />
                      ) : (
                        <CheckJWT
                          path={'/workflows/ai'}
                          activeServices={activeServices}
                          next={<WorkflowsAi />}
                          handleUserLogout={handleUserLogin}
                          name="workflowsIa"
                        />
                      )
                    }
                  />
                  <Route
                    path=":id"
                    element={
                      !accessToken ? (
                        <Login handleUserLogin={handleUserLogin} />
                      ) : (
                        <CheckJWT
                          path={'/workflows/:id'}
                          activeServices={activeServices}
                          next={<WorkflowsBuilder />}
                          handleUserLogout={handleUserLogin}
                          name="workflowsBuilder"
                        />
                      )
                    }
                  />
                </Route>
              )}
              <Route path="/admin/users">
                <Route
                  index
                  element={
                    !accessToken ? (
                      <Login handleUserLogin={handleUserLogin} />
                    ) : (
                      <CheckJWT
                        path={'/admin/users'}
                        activeServices={activeServices}
                        next={<Users />}
                        handleUserLogout={handleUserLogin}
                        name="dashboardUsers"
                      />
                    )
                  }
                />
              </Route>
              <Route path="/admin/consumptions">
                <Route
                  index
                  element={
                    !accessToken ? (
                      <Login handleUserLogin={handleUserLogin} />
                    ) : (
                      <CheckJWT
                        path={'/admin/consumptions'}
                        activeServices={activeServices}
                        next={<Consumptions />}
                        handleUserLogout={handleUserLogin}
                        name="consumptions"
                      />
                    )
                  }
                />
              </Route>
              <Route path="/admin/roles">
                <Route
                  index
                  element={
                    !accessToken ? (
                      <Login handleUserLogin={handleUserLogin} />
                    ) : (
                      <CheckJWT
                        path={'/admin/roles'}
                        activeServices={activeServices}
                        next={<Roles />}
                        handleUserLogout={handleUserLogin}
                        name="roles"
                      />
                    )
                  }
                />
                <Route
                  path=":id"
                  element={
                    !accessToken ? (
                      <Login handleUserLogin={handleUserLogin} />
                    ) : (
                      <CheckJWT
                        path={'/admin/roles'}
                        activeServices={activeServices}
                        next={<RoleDetails />}
                        handleUserLogout={handleUserLogin}
                        name="roles"
                      />
                    )
                  }
                />
              </Route>
              <Route path="/admin/oppositionControl">
                <Route
                  index
                  element={
                    !accessToken ? (
                      <Login handleUserLogin={handleUserLogin} />
                    ) : (
                      <CheckJWT
                        path={'/admin/oppositionControl'}
                        activeServices={activeServices}
                        next={<OppositionControl />}
                        handleUserLogout={handleUserLogin}
                        name="oppositionControl"
                      />
                    )
                  }
                />
                <Route
                  path=":requestId"
                  element={
                    !accessToken ? (
                      <Login handleUserLogin={handleUserLogin} />
                    ) : (
                      <CheckJWT
                        path={'/admin/oppositionControl/:requestId'}
                        activeServices={activeServices}
                        next={<OppositionControl />}
                        handleUserLogout={handleUserLogin}
                        name="oppositionControl"
                      />
                    )
                  }
                />
              </Route>
              <Route path="/admin/security">
                <Route
                  index
                  element={
                    !accessToken ? (
                      <Login handleUserLogin={handleUserLogin} />
                    ) : (
                      <CheckJWT
                        path={'/admin/security'}
                        activeServices={activeServices}
                        next={<SecurityParams />}
                        handleUserLogout={handleUserLogin}
                        name="securityParameters"
                      />
                    )
                  }
                />
              </Route>
              <Route path="/admin/riskMatrix">
                <Route
                  index
                  element={
                    !accessToken ? (
                      <Login handleUserLogin={handleUserLogin} />
                    ) : (
                      <CheckJWT
                        path={'/admin/riskMatrix'}
                        activeServices={activeServices}
                        next={<RiskMatrix />}
                        handleUserLogout={handleUserLogin}
                        name="riskMatrix"
                      />
                    )
                  }
                />
                <Route
                  path=":id"
                  element={
                    !accessToken ? (
                      <Login handleUserLogin={handleUserLogin} />
                    ) : (
                      <CheckJWT
                        path={'/admin/riskMatrix'}
                        activeServices={activeServices}
                        next={<RiskMatrixCreation />}
                        handleUserLogout={handleUserLogin}
                        name="riskMatrix"
                      />
                    )
                  }
                />
                <Route
                  path="creation"
                  element={
                    !accessToken ? (
                      <Login handleUserLogin={handleUserLogin} />
                    ) : (
                      <CheckJWT
                        path={'/admin/riskMatrix'}
                        activeServices={activeServices}
                        next={<RiskMatrixCreation />}
                        handleUserLogout={handleUserLogin}
                        name="riskMatrix"
                      />
                    )
                  }
                />
              </Route>
              <Route path="/admin/webhooks">
                <Route
                  index
                  element={
                    !accessToken ? (
                      <Login handleUserLogin={handleUserLogin} />
                    ) : (
                      <CheckJWT
                        path={'/admin/webhooks'}
                        activeServices={activeServices}
                        next={<Webhooks />}
                        handleUserLogout={handleUserLogin}
                        name="webhooks"
                      />
                    )
                  }
                />
              </Route>
              <Route path="/admin/lists">
                <Route
                  index
                  element={
                    !accessToken ? (
                      <Login handleUserLogin={handleUserLogin} />
                    ) : (
                      <CheckJWT
                        path={'/admin/lists'}
                        activeServices={activeServices}
                        next={<Lists />}
                        handleUserLogout={handleUserLogin}
                        name="lists"
                      />
                    )
                  }
                />
                <Route
                  path=":tab"
                  element={
                    !accessToken ? (
                      <Login handleUserLogin={handleUserLogin} />
                    ) : (
                      <CheckJWT
                        path={'/admin/lists'}
                        activeServices={activeServices}
                        next={<Lists />}
                        handleUserLogout={handleUserLogin}
                        name="lists"
                      />
                    )
                  }
                />
              </Route>
              <Route path="/reports">
                <Route
                  index
                  element={
                    !accessToken ? (
                      <Login handleUserLogin={handleUserLogin} />
                    ) : (
                      <CheckJWT
                        path={'/admin/reports'}
                        activeServices={activeServices}
                        next={<Reports />}
                        handleUserLogout={handleUserLogin}
                        name="reports"
                      />
                    )
                  }
                />
                <Route
                  path=":reportKey"
                  element={
                    !accessToken ? (
                      <Login handleUserLogin={handleUserLogin} />
                    ) : (
                      <CheckJWT
                        path={'/admin/reports'}
                        activeServices={activeServices}
                        next={<ReportRouter />}
                        handleUserLogout={handleUserLogin}
                        name="reports"
                      />
                    )
                  }
                />
              </Route>
              <Route path="/coming-soon">
                <Route
                  index
                  element={
                    !accessToken ? (
                      <Login handleUserLogin={handleUserLogin} />
                    ) : (
                      <CheckJWT
                        path={'/coming-soon'}
                        activeServices={activeServices}
                        next={<ComingSoon />}
                        handleUserLogout={handleUserLogin}
                      />
                    )
                  }
                />
              </Route>
              <Route path="/admin/alerts">
                <Route
                  index
                  element={
                    !accessToken ? (
                      <Login handleUserLogin={handleUserLogin} />
                    ) : (
                      <CheckJWT
                        path={'/admin/alerts'}
                        activeServices={activeServices}
                        next={<AlertSettings />}
                        handleUserLogout={handleUserLogin}
                        name="alertSettings"
                      />
                    )
                  }
                />
              </Route>
              <Route path="/profile">
                <Route
                  index
                  element={
                    !accessToken ? (
                      <Login handleUserLogin={handleUserLogin} />
                    ) : (
                      <CheckJWT
                        activeServices={activeServices}
                        next={<Profile />}
                        handleUserLogout={handleUserLogin}
                        name="profile"
                      />
                    )
                  }
                />
              </Route>
              <Route path="/batch">
                <Route
                  index
                  element={
                    !accessToken ? (
                      <Login handleUserLogin={handleUserLogin} />
                    ) : (
                      <CheckJWT
                        path={'/'}
                        activeServices={activeServices}
                        next={<Batch />}
                        name="batch"
                        handleUserLogout={handleUserLogin}
                      />
                    )
                  }
                />
              </Route>
              <Route path="/batchDetails">
                <Route
                  index
                  path=":id"
                  element={
                    !accessToken ? (
                      <Login handleUserLogin={handleUserLogin} />
                    ) : (
                      <CheckJWT
                        path={'/'}
                        activeServices={activeServices}
                        next={<BatchDetails />}
                        name="batchDetails"
                        handleUserLogout={handleUserLogin}
                      />
                    )
                  }
                />
              </Route>
              <Route path="/kyc/persons">
                <Route
                  index
                  element={
                    <CheckJWT
                      path={'/kyc/persons'}
                      activeServices={activeServices}
                      next={<Kyc />}
                      handleUserLogout={handleUserLogin}
                      name="kycPersons"
                    />
                  }
                />
              </Route>
            </Routes>
          </BrowserRouter>
          <ToastContainer
            autoClose={2500}
            draggable={false}
            position="bottom-center"
            closeButton={false}
            toastStyle={{ background: 'transparent', boxShadow: 'none' }}
            hideProgressBar
            stacked
          />
        </ThemeProvider>
      </LoadScript>
    </Suspense>
  )
}

export default App

import { Autocomplete, Box, Button, Input, TextField, Tooltip } from '@mui/material'
import '../kytManualCheck.scss'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InfoIcon from '@mui/icons-material/Info'

const UpiDetails = ({ setPaymentDetails, isReset, setIsReset }) => {
  const [upiID, setUpiID] = useState('')
  const [bankProvider, setBankProvider] = useState('')
  const [interfaceProvider, setInterfaceProvider] = useState('')
  const [name, setName] = useState('')

  const { t } = useTranslation(['common', 'messages'])
  const [change, setChange] = useState(false)

  useEffect(() => {
    if (change) {
      const body = {
        method: 'UPI',
        ...(upiID && { upiID }),
        ...(bankProvider && { bankProvider }),
        ...(interfaceProvider && { interfaceProvider }),
        ...(name && { name }),
      }

      setPaymentDetails(body)
      setChange(false)
    }
  }, [change])

  useEffect(() => {
    if (isReset) {
      setUpiID('')
      setBankProvider('')
      setInterfaceProvider('')
      setName('')
      setIsReset(false)
    }
  }, [isReset])

  const renderTooltip = (title) => (
    <Tooltip title={title}>
      <Button className="back-tip">
        <InfoIcon fontSize="small" className="security-info-icon" />
      </Button>
    </Tooltip>
  )

  return (
    <Box>
      <Box className="kyt-form-pair">
        <Box className="kyt-form-pair-label">
          <span className="input-error">* </span>
          <label>{t('common:upiID')}</label>
        </Box>
        {renderTooltip(
          <Box className="back-tip">
            <label className="security-items-info" color="inherit">
              {'UPI Id number.'}
            </label>
          </Box>,
        )}
        <Input
          className="full-width"
          size="small"
          type="text"
          value={upiID}
          onChange={(event) => {
            setUpiID(event.target.value)
            setChange(true)
          }}
          placeholder={`${t('common:insert')} ${t('common:upiID')} ${t('here')}`}
        />
      </Box>
      <Box className="kyt-form-pair">
        <Box className="kyt-form-pair-label">
          <label>{t('common:bankProvider')}</label>
        </Box>
        {renderTooltip(
          <Box className="back-tip">
            <label className="security-items-info" color="inherit">
              {'Bank provider name.'}
            </label>
          </Box>,
        )}
        <Input
          className="full-width"
          size="small"
          type="text"
          value={bankProvider}
          onChange={(event) => {
            setBankProvider(event.target.value)
            setChange(true)
          }}
          placeholder={`${t('common:insert')} ${t('common:bankProvider')} ${t('here')}`}
        />
      </Box>
      <Box className="kyt-form-pair">
        <Box className="kyt-form-pair-label">
          <label>{t('common:interfaceProvider')}</label>
        </Box>
        {renderTooltip(
          <Box className="back-tip">
            <label className="security-items-info" color="inherit">
              {'Interface provider name.'}
            </label>
          </Box>,
        )}
        <Input
          className="full-width"
          size="small"
          type="text"
          value={interfaceProvider}
          onChange={(event) => {
            setInterfaceProvider(event.target.value)
            setChange(true)
          }}
          placeholder={`${t('common:insert')} ${t('common:interfaceProvider')} ${t('here')}`}
        />
      </Box>
      <Box className="kyt-form-pair">
        <Box className="kyt-form-pair-label">
          <label>{t('common:name')}</label>
        </Box>
        {renderTooltip(
          <Box className="back-tip">
            <label className="security-items-info" color="inherit">
              {'Name of the account holder.'}
            </label>
          </Box>,
        )}
        <Input
          className="full-width"
          size="small"
          type="text"
          value={name}
          onChange={(event) => {
            setName(event.target.value)
            setChange(true)
          }}
          placeholder={`${t('common:insert')} ${t('common:name')} ${t('here')}`}
        />
      </Box>
    </Box>
  )
}

export default UpiDetails

import { useEffect, useState } from 'react'
import './toRender.scss'
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'
import { Box, IconButton } from '@mui/material'

const ImageSlider = ({ images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0)
  const [imageSrc, setImageSrc] = useState(null)

  useEffect(() => {
    setImageSrc(images[currentImageIndex])
  }, [currentImageIndex, images])

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length)
  }

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length)
  }

  return (
    <Box className="image-slider">
      {imageSrc && <img className="slider-image" src={'data:image/jpeg;base64,' + imageSrc} alt="Base64 Image" />}
      <Box className="btns">
        <IconButton onClick={prevImage}>
          <ArrowBackIosRoundedIcon color="secondary" />
        </IconButton>
        <Box className="indicators">
          {images.map((_, index) => (
            <span
              key={index}
              className={index === currentImageIndex ? 'indicator active' : 'indicator'}
              onClick={() => setCurrentImageIndex(index)}
            />
          ))}
        </Box>
        <IconButton onClick={nextImage}>
          <ArrowForwardIosRoundedIcon color="secondary" />
        </IconButton>
      </Box>
    </Box>
  )
}

export default ImageSlider

import { Box, TextField, Button } from '@mui/material'
import './checklist.scss'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import AlertFetchSpinner from '../../../components/common/alertFetchSpinner/alertFetchSpinner'
import ListOfChecklists from './ListOfChecklists'
import { useTranslation } from 'react-i18next'

export const ChecklistInstanceDetails = ({ check, isEdition, isEditing, setIsEditing, checklists, setChecklists }) => {
  const { accessToken } = useSelector((state) => state.login)
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [actions, setActions] = useState([])
  const [actionsObject, setActionsObject] = useState({})
  const [isFetching, setIsFetching] = useState(false)
  const [fetchMessage, setFetchMessage] = useState('')
  const [fetchError, setFetchError] = useState(false)
  const [fetched, setFetched] = useState(false)
  const { t } = useTranslation(['common'])

  useEffect(() => {
    setTitle(check.title)
    setDescription(check.description)
    setActions(check.actions)
    setActionsObject({ actions: check.actions })
  }, [check])

  const handleReset = () => {
    setTitle(check.title)
    setDescription(check.description)
    setActions(check.actions)
    setActionsObject({ actions: check.actions })
  }

  useEffect(() => {
    setActions(actionsObject.actions)
  }, [actionsObject])

  const cantFetch = () => {
    return !isEditing
  }

  const handleFetch = () => {
    setIsFetching(true)

    const url = `${process.env.REACT_APP_BASEURL}/admin/checklistInstances/${`${check._id}`}`

    const body = { actions }

    if (!process.env.REACT_APP_IS_DEMO) {
      const options = {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(body),
      }

      fetch(url, options)
        .then((res) => res.json())
        .then((res) => {
          if (res.isCPO) {
            setIsFetching(false)
            setFetched(true)
            setFetchError(false)
            setFetchMessage(res.message)
            setIsEditing(false)
            setTimeout(() => {
              setFetched(false)
            }, 3000)
            return
          }
          setFetchError(false)
          setFetchMessage('Update checklist instance success')
          setIsFetching(false)
          setIsEditing(false)

          let listAux = [...checklists]

          let idx = listAux.findIndex((element) => {
            return element._id === check._id
          })

          if (idx !== -1) {
            const updatedChecklist = [
              ...listAux.slice(0, idx),
              {
                ...check,
                actions: [...actions],
              },
              ...listAux.slice(idx + 1),
            ]
            setChecklists([...updatedChecklist])
          }
        })
        .catch((error) => {
          console.error(`[HOOK: Update checklist instance error] --> `, error)
          setFetchError(true)
          setFetchMessage(error.message)
          setIsFetching(false)
          setIsEditing(false)
          handleReset()
        })
    } else {
      setFetchError(false)
      setFetchMessage('Update checklist instance success')
      setIsFetching(false)
      setIsEditing(false)

      let listAux = [...checklists]

      let idx = listAux.findIndex((element) => {
        return element._id === check._id
      })

      const updatedChecklist = [
        ...listAux.slice(0, idx),
        {
          ...check,
          actions: [...actions],
        },
        ...listAux.slice(idx + 1),
      ]
      setChecklists([...updatedChecklist])
    }
  }

  return (
    <Box className="checklist-details-wrapper">
      {(isFetching || fetched) && <AlertFetchSpinner message={fetchMessage} error={fetchError} />}

      <Box className="checklist-details-block">
        <strong>Title</strong>
        <TextField
          id="name"
          className="input-checklist-details"
          type="text"
          disabled
          variant="outlined"
          size="small"
          value={title || ''}
          onChange={(event) => {
            event.preventDefault()
            setTitle(event.target.value)
          }}
        />
      </Box>
      <Box className="checklist-details-block">
        <strong>{t('common:description')}</strong>
        <TextField
          multiline
          className="input-checklist-details"
          size="small"
          id="description"
          disabled
          variant="outlined"
          type="text"
          value={description || ''}
          onChange={(event) => {
            event.preventDefault()
            setDescription(event.target.value)
          }}
        />
      </Box>
      <Box className="checklist-details-block">
        <strong>{t('common:actions')}</strong>
        <ListOfChecklists
          propertie={'actions'}
          actions={actions}
          setActions={setActions}
          isEditing={isEdition ? isEditing : true}
        />
      </Box>
      <Button color="secondary" disabled={cantFetch()} onClick={handleFetch}>
        Update
      </Button>
    </Box>
  )
}

import { Box, IconButton, Paper, Typography } from '@mui/material'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'
import { renderNames } from '../toRender/renderNames'
import { Flags } from '../flag/flags'
import { renderAddresses } from '../toRender/renderAddresses'
import { buildTimeStringFromTimestamp } from '../time/timeHelper'
import { camelCaseFormatter } from '../../utilities/formatters/camelCase'
import { useTranslation } from 'react-i18next'
import './userDetails.scss'
import phoneNumberRenderer from '../toRender/phoneNumberRenderer'

const UserDetails = ({ actualUser }) => {
  const { t } = useTranslation(['common'])

  const renderContactDetail = (detailDesc, detail) => {
    if (detailDesc !== 'addresses') {
      return (
        <Box display="flex" flexDirection="column" gap=".25rem">
          <Typography variant="subtitle1">{camelCaseFormatter(detailDesc)}:</Typography>
          {detail &&
            detail.length !== 0 &&
            detail.map((detailItem, index) => {
              return (
                <Box key={index}>
                  <Typography variant={detailItem.includes('@') ? 'h5' : 'number'}>
                    {detailItem.includes('@') ? detailItem : phoneNumberRenderer(detailItem)}
                  </Typography>
                </Box>
              )
            })}
          {!detail || (detail.length === 0 && <p variant="number">---</p>)}
        </Box>
      )
    }
  }

  return (
    <Box className="user-details-top">
      <Box className="user-details-wrapper">
        <Typography variant="subtitle5">{t('common:personalInformation')}</Typography>
        <Box className="user-info-3">
          <Paper elevation={0} variant="colorPrimary" className="user-box">
            <Typography variant="subtitle1">{t('common:createdAt')}</Typography>
            <Typography variant="number">
              {buildTimeStringFromTimestamp(actualUser?.createdTimestamp).split('-')[0]}
            </Typography>
          </Paper>
          <Paper elevation={0} variant="colorPrimary" className="user-box">
            <Typography variant="subtitle1">{t('common:name')}</Typography>
            <Typography variant="h5">
              {actualUser?.userDetails?.name
                ? renderNames(actualUser?.userDetails?.name, 'User')
                : renderNames(actualUser?.legalEntity, 'User')}
            </Typography>
          </Paper>
          <Paper elevation={0} variant="colorPrimary" className="user-box">
            <Typography variant="subtitle1">{t('common:dateOfBirth')}</Typography>
            <Typography variant="number">
              {actualUser.userDetails?.dateOfBirth
                ? actualUser.userDetails.dateOfBirth.split('-').reverse().join('/')
                : '---'}
            </Typography>
          </Paper>
          <Paper elevation={0} variant="colorPrimary" className="user-box">
            <Typography variant="subtitle1">{t('common:gender')}</Typography>
            <Typography variant="h5">
              {actualUser.userDetails?.gender ? actualUser.userDetails.gender : '---'}
            </Typography>
          </Paper>
          <Paper elevation={0} variant="colorPrimary" className="user-box">
            <Typography variant="subtitle1">{t('common:residence')}</Typography>
            <Typography variant="h5">
              {actualUser.userDetails?.countryOfResidence ? Flags(actualUser.userDetails.countryOfResidence) : '---'}
            </Typography>
          </Paper>
          <Paper elevation={0} variant="colorPrimary" className="user-box">
            <Typography variant="subtitle1">{t('common:nationality')}</Typography>
            <Typography variant="h5">
              {actualUser.userDetails?.countryOfNationality
                ? Flags(actualUser.userDetails.countryOfNationality)
                : '---'}
            </Typography>
          </Paper>
          <Paper elevation={0} variant="colorPrimary" className="user-column-expand-3">
            <Box className="user-id">
              <Box className="user-box">
                <Typography variant="subtitle1">{t('common:addresses')}</Typography>
                {renderAddresses(actualUser)}
              </Box>
              {actualUser?.contactDetails?.addresses && (
                <IconButton
                  onClick={() => {
                    navigator.clipboard.writeText(renderAddresses(actualUser))
                  }}
                >
                  <ContentCopyOutlinedIcon style={{ height: '1rem', width: '1rem' }} />
                </IconButton>
              )}
            </Box>
          </Paper>
        </Box>
      </Box>

      <Box className="user-details-wrapper">
        <Typography variant="subtitle5">{t('common:contactDetails')}</Typography>
        <Box className="user-info">
          {actualUser?.contactDetails &&
            Object.keys(actualUser.contactDetails).map((element, index) => {
              return (
                <Paper key={`${index} ${element}`} elevation={0} variant="colorPrimary">
                  {renderContactDetail(element, actualUser.contactDetails[element])}
                </Paper>
              )
            })}
          {!actualUser?.contactDetails && <p>---</p>}
        </Box>
      </Box>

      <Box className="user-details-wrapper">
        <Typography variant="subtitle5">{t('common:accountDetails')}</Typography>
        <Box className="user-info">
          <Paper elevation={0} variant="colorPrimary" className="user-box">
            <Typography variant="subtitle1">{t('common:acquisitionChannel')}</Typography>

            <Typography variant="h5">
              {actualUser?.acquisitionChannel ? actualUser?.acquisitionChannel : '---'}
            </Typography>
          </Paper>
          <Paper elevation={0} variant="colorPrimary" className="user-box">
            <Typography variant="subtitle1">{t('common:userSegment')}</Typography>

            <Typography variant="h5">{actualUser?.userSegment || '---'}</Typography>
          </Paper>
          <Paper elevation={0} variant="colorPrimary" className="user-box user-column-expand-2">
            <Typography variant="subtitle1">{t('common:accountOpening')}</Typography>

            <Typography variant="h5">
              {t('common:reason')}:{' '}
              {actualUser?.reasonForAccountOpening?.length > 0
                ? actualUser?.reasonForAccountOpening?.join(', ')
                : '---'}
            </Typography>
          </Paper>
          <Paper elevation={0} variant="colorPrimary" className="user-box user-column-expand-2">
            <Typography variant="subtitle1">PEP</Typography>
            {actualUser.pepStatus &&
              actualUser.pepStatus?.length !== 0 &&
              actualUser.pepStatus.map((pep, index) => {
                return (
                  <Box className="user-uid" key={index}>
                    <Typography variant="h5">{index + 1}</Typography>
                    {Flags(pep.pepCountry)} {pep.isPepHit ? '- Yes' : '- No'}
                  </Box>
                )
              })}
            {!actualUser.pepStatus || (actualUser.pepStatus.length === 0 && <p>---</p>)}
          </Paper>
        </Box>
      </Box>
    </Box>
  )
}

export default UserDetails

import { Alert, Box, CircularProgress, Typography } from '@mui/material'
import { toast } from 'react-toastify'

const FeedbackAlert = ({ message, isLoading = false, variant = 'success' }) => (
  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    {isLoading ? (
      <Box>
        <Typography variant="subtitle3">{message}</Typography>
        <svg width={0} height={0}>
          <defs>
            <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor="#19DB55" />
              <stop offset="100%" stopColor="#19DB5542" />
            </linearGradient>
          </defs>
        </svg>
        <CircularProgress sx={{ 'svg circle': { stroke: 'url(#my_gradient)' } }} size={20} />
      </Box>
    ) : (
      <Alert severity={variant}>{message}</Alert>
    )}
  </Box>
)

const toaster = (message, toastProps) => toast(<FeedbackAlert message={message} />, { ...toastProps })

toaster.success = (message, toastProps) => toast(<FeedbackAlert message={message} />, { ...toastProps })

toaster.info = (message, toastProps) => toast(<FeedbackAlert message={message} variant="info" />, { ...toastProps })
toaster.error = (message, toastProps) => toast(<FeedbackAlert message={message} variant="error" />, { ...toastProps })
toaster.warning = (message, toastProps) =>
  toast(<FeedbackAlert message={message} variant="warning" />, { ...toastProps })
toaster.loading = (message, toastProps) =>
  toast(<FeedbackAlert message={message} isLoading />, { ...toastProps, position: 'top-right', autoClose: false })
toaster.dismiss = toast.dismiss

export default toaster

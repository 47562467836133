import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import OutlinedInput from '@mui/material/OutlinedInput'
import MenuItem from '@mui/material/MenuItem'
import { useState } from 'react'
import { useEffect } from 'react'
import { MenuProps } from '../../../../utilities/MenuProps'
import { Box } from '@mui/material'
import './selectMultiple.scss'

const SelectMultiple = ({ propertie, ruleParameters, setRuleParameters, posibleValues, isEditing }) => {
  const [selectedValue, setSelectedValue] = useState([])
  const [change, setChange] = useState(false)

  useEffect(() => {
    if (ruleParameters[propertie]) {
      setSelectedValue(ruleParameters[propertie])
    }
  }, [propertie, ruleParameters])

  const handleChange = (event) => {
    const {
      target: { value },
    } = event

    setSelectedValue(typeof value === 'string' ? value.split(',') : value)
    setChange(true)
    let all = { ...ruleParameters }
    all[propertie] = typeof value === 'string' ? value.split(',') : value
    setRuleParameters({ ...all })
  }

  useEffect(() => {
    if (change && selectedValue.length !== ruleParameters[propertie]?.length) {
      let all = { ...ruleParameters }
      all[propertie] = selectedValue
      setRuleParameters({ ...all })
      setChange(true)
    }
  }, [selectedValue, ruleParameters, setRuleParameters])

  return (
    <Select
      fullWidth
      size="small"
      multiple
      displayEmpty
      disabled={!isEditing}
      value={selectedValue}
      onChange={handleChange}
      input={<OutlinedInput />}
      renderValue={(selected) => {
        return selected.join(', ')
      }}
      inputProps={{ 'aria-label': 'Without label' }}
    >
      {posibleValues.map((name) => (
        <MenuItem key={name} value={name}>
          {name}
        </MenuItem>
      ))}
    </Select>
  )
}

export default SelectMultiple

import { Box, Button, Modal, Tooltip, Typography } from '@mui/material'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useUpdateKybSubjectMutation } from '../../../services/guenoApi'
import { EditOutlined } from '@mui/icons-material'
import TextInputForm from '../../../components/common/form/TextInputForm/TextInputForm'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

const schema = yup.object().shape({
  email: yup.string().email('Ingrese un email válido').optional(),
  phoneNumber: yup.string().optional(),
})

const KybSubjectForm = ({ subjectId, initialEmail, initialPhoneNumber, setShowUpdateModal }) => {
  const { t } = useTranslation(['common', 'messages'])
  const [updateKybSubject] = useUpdateKybSubjectMutation()

  const { handleSubmit, control, formState } = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    defaultValues: {
      email: initialEmail,
      phoneNumber: initialPhoneNumber,
    },
  })

  const onSubmit = async (values) => {
    const { email, phoneNumber } = values
    if (subjectId) {
      try {
        await updateKybSubject({
          subjectId,
          body: {
            email,
            phoneNumber,
          },
        })
        setShowUpdateModal(false)
      } catch (error) {
        console.error('Error al actualizar el sujeto:', error)
      }
    }
  }

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Box display="flex" gap="1.5rem">
        <TextInputForm
          name="email"
          control={control}
          label={t('common:email')}
          tooltip={t('messages:update.kyb.subject.email.label')}
          placeholder={t('common:email')}
        />
        <TextInputForm
          name="phoneNumber"
          control={control}
          label={t('common:phoneNumber')}
          tooltip={t('messages:update.kyb.subject.phoneNumber.label')}
          placeholder={t('common:phoneNumber')}
        />
      </Box>
      <Box display="flex" justifyContent="space-evenly" mt="2rem">
        <Button variant="outlined" color="secondary" onClick={() => setShowUpdateModal(false)}>
          {t('common:cancel')}
        </Button>
        <Button variant="contained" color="primary" type="submit" disabled={!formState.isDirty || !formState.isValid}>
          {t('common:update')}
        </Button>
      </Box>
    </Box>
  )
}

const UpdateKybSubjectModal = ({ subjectId, initialEmail, initialPhoneNumber }) => {
  const { t } = useTranslation(['common', 'messages'])
  const [showUpdateModal, setShowUpdateModal] = useState(false)
  const title = `${t('common:update')} ${t('common:shareholder')}`

  return (
    <>
      <Tooltip title={title} arrow>
        <Button
          onClick={() => setShowUpdateModal(true)}
          size="small"
          sx={{
            fontSize: 10,
          }}
          color="success"
          variant="outlined"
        >
          <EditOutlined />
        </Button>
      </Tooltip>
      <Modal open={showUpdateModal} onClose={() => setShowUpdateModal(false)}>
        <Box className="modal" p="2rem" bgcolor="background.paper" borderRadius="8px">
          <Typography variant="title">{title}</Typography>
          <KybSubjectForm
            subjectId={subjectId}
            initialEmail={initialEmail}
            initialPhoneNumber={initialPhoneNumber}
            setShowUpdateModal={setShowUpdateModal}
          />
        </Box>
      </Modal>
    </>
  )
}

export default UpdateKybSubjectModal

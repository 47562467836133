import { useTranslation } from 'react-i18next'
import { Box, Paper, Typography } from '@mui/material'
import './transactionLimits.scss'
import paymentMethodRenderer from '../toRender/paymentMethodRenderer'
import CustomNoRowsOverlay from '../datagrid/CustomNoRowsOverlay'

const TransactionLimits = ({ transactionLimits }) => {
  const { t } = useTranslation(['common'])

  return (
    <Box className="user-details-top">
      <Box className="user-details-wrapper">
        <Typography variant="subtitle5">{t('common:onAllTransactions')}</Typography>
        <Box className="user-info-3">
          <Paper elevation={0} variant="colorPrimary" className="user-box">
            <Typography variant="subtitle1">{t('common:daily')}</Typography>
            <Typography variant="number">
              {transactionLimits?.maximumDailyTransactionLimit
                ? transactionLimits.maximumDailyTransactionLimit.amountValue?.toLocaleString() +
                  ' ' +
                  transactionLimits.maximumDailyTransactionLimit.amountCurrency
                : '---'}
            </Typography>
          </Paper>
          <Paper elevation={0} variant="colorPrimary" className="user-box">
            <Typography variant="subtitle1">{t('common:weekly')}</Typography>
            <Typography variant="number">
              {transactionLimits?.maximumWeeklyTransactionLimit
                ? transactionLimits.maximumWeeklyTransactionLimit.amountValue?.toLocaleString() +
                  ' ' +
                  transactionLimits.maximumWeeklyTransactionLimit.amountCurrency
                : '---'}
            </Typography>
          </Paper>
          <Paper elevation={0} variant="colorPrimary" className="user-box">
            <Typography variant="subtitle1">{t('common:monthly')}</Typography>
            <Typography variant="number">
              {transactionLimits?.maximumMonthlyTransactionLimit
                ? transactionLimits.maximumMonthlyTransactionLimit.amountValue?.toLocaleString() +
                  ' ' +
                  transactionLimits.maximumMonthlyTransactionLimit.amountCurrency
                : '---'}
            </Typography>
          </Paper>
          <Paper elevation={0} variant="colorPrimary" className="user-box">
            <Typography variant="subtitle1">{t('common:quarterly')}</Typography>
            <Typography variant="number">
              {transactionLimits?.maximumQuarterlyTransactionLimit
                ? transactionLimits.maximumQuarterlyTransactionLimit.amountValue?.toLocaleString() +
                  ' ' +
                  transactionLimits.maximumQuarterlyTransactionLimit.amountCurrency
                : '---'}
            </Typography>
          </Paper>
          <Paper elevation={0} variant="colorPrimary" className="user-box">
            <Typography variant="subtitle1">{t('common:yearly')}</Typography>
            <Typography variant="number">
              {transactionLimits?.maximumYearlyTransactionLimit
                ? transactionLimits.maximumYearlyTransactionLimit.amountValue?.toLocaleString() +
                  ' ' +
                  transactionLimits.maximumYearlyTransactionLimit.amountCurrency
                : '---'}
            </Typography>
          </Paper>
          <Paper elevation={0} variant="colorPrimary" className="user-box">
            <Typography variant="subtitle1">{t('common:allTime')}</Typography>
            <Typography variant="number">
              {transactionLimits?.maximumTransactionLimit
                ? transactionLimits.maximumTransactionLimit.amountValue?.toLocaleString() +
                  ' ' +
                  transactionLimits.maximumTransactionLimit.amountCurrency
                : '---'}
            </Typography>
          </Paper>
        </Box>
      </Box>

      <Box className="user-details-wrapper">
        <Typography variant="subtitle5">{t('common:onPaymentMethod')}</Typography>

        {transactionLimits?.paymentMethodLimits ? (
          Object.entries(transactionLimits?.paymentMethodLimits).map(([key], index) => {
            return (
              <Paper key={index} variant="colorPrimary" className="user-box" elevation={0}>
                <Typography variant="subtitle1">{paymentMethodRenderer(key)}</Typography>
                <Box className="user-info-3">
                  <Paper elevation={0} sx={{ padding: '.75rem 1rem' }}>
                    {transactionLimits?.paymentMethodLimits[key].transactionCountLimit
                      ? Object.entries(transactionLimits?.paymentMethodLimits[key].transactionCountLimit).map(
                          ([keyLimit, valueLimit], index) => {
                            return (
                              <Box key={index}>
                                <Typography variant="subtitle1">{t('common:maxTsxCount')}</Typography>
                                <Typography variant="number">
                                  {valueLimit} {t('common:per')} {keyLimit}
                                </Typography>
                              </Box>
                            )
                          },
                        )
                      : '---'}
                  </Paper>
                  <Paper elevation={0} sx={{ padding: '.75rem 1rem' }}>
                    {transactionLimits?.paymentMethodLimits[key].transactionAmountLimit
                      ? Object.entries(transactionLimits?.paymentMethodLimits[key].transactionAmountLimit).map(
                          ([keyLimit, valueLimit], index) => {
                            return (
                              <Box key={index}>
                                <Typography variant="subtitle1">{t('common:maxTsxAmount')}</Typography>
                                <Typography variant="number">
                                  {valueLimit.amountValue} {valueLimit.amountCurrency} {t('common:per')} {keyLimit}
                                </Typography>
                              </Box>
                            )
                          },
                        )
                      : '---'}
                  </Paper>
                  <Paper elevation={0} sx={{ padding: '.75rem 1rem' }}>
                    {transactionLimits?.paymentMethodLimits[key].averageTransactionAmountLimit
                      ? Object.entries(transactionLimits?.paymentMethodLimits[key].averageTransactionAmountLimit).map(
                          ([keyLimit, valueLimit], index) => {
                            return (
                              <Box key={index}>
                                <Typography variant="subtitle1">{t('common:avgTsxAmount')}</Typography>
                                <Typography variant="number">
                                  {valueLimit.amountValue} {valueLimit.amountCurrency} {t('common:per')} {keyLimit}
                                </Typography>
                              </Box>
                            )
                          },
                        )
                      : '---'}
                  </Paper>
                </Box>
              </Paper>
            )
          })
        ) : (
          <Box className="table-custom-overlay">
            <CustomNoRowsOverlay />
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default TransactionLimits

import translateObj from '../../../pages/kyb/translateObject.json'

export const camelCaseFormatter = (str) => {
  if (str) {
    const storageRoot = localStorage.getItem('persist:root')
    const storageLang = storageRoot ? JSON.parse(storageRoot).language : ''
    const lang = storageLang ? JSON.parse(storageLang) : ''

    if (lang.lang === 'pt-BR' && translateObj.hasOwnProperty(str)) {
      return translateObj[str]
    } else {
      let formattedStr = str?.replace(/([a-z])([A-Z])/g, '$1 $2')

      const firstChar = formattedStr?.charAt(0).toUpperCase()

      const remainingChars = formattedStr?.slice(1)

      return `${firstChar}${remainingChars}`
    }
  }
}

/**
 * phoneNumberRenderer is used to add spacing to a phone number with country code
 * @param {string} phoneNum 
 * @returns {string}
 * @throws {''}
 */
export default function phoneNumberRenderer (phoneNum) {
    if(typeof phoneNum !== 'string') return '';
    const countryCode = phoneNum.slice(0, 3);
    const firstSet = phoneNum.slice(3, 5);
    const restOfNum = phoneNum.slice(5);
    return `${countryCode} ${firstSet} ${restOfNum}`;
}
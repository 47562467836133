import { buildTimeStringFromTimestamp } from '../../time/timeHelper'

export const securityParameterDashboardDataMapperForPDF = (data) => {
  const header = [
    'Action Owner',
    'Action',
    'Status',
    'Date',

    'Previous Activity Time',
    'Previous Check Password Expiration Days',
    'Previous Check Password History Length',
    'Previous Dashboard Activity',
    'Previous Max Days to Inactive',
    'Previous Max Failed Attempts',
    'Previous Max Failed Attempts Required',
    'Previous Password Expiration Days',
    'Previous Password History Length',
    'Previous Require Inactive Duration',
    'Previous Require Password Change On First Login',

    'New Activity Time',
    'New Check Password Expiration Days',
    'New Check Password History Length',
    'New Dashboard Activity',
    'New Max Days to Inactive',
    'New Max Failed Attempts',
    'New Max Failed Attempts Required',
    'New Password Expiration Days',
    'New Password History Length',
    'New Require Inactive Duration',
    'New Require Password Change On First Login',
  ]
  const parsedData = data.map((sp) => {
    return [
      sp.emailUser,
      sp.activityDetails.abmAction,
      sp.activityDetails.actionResult,
      buildTimeStringFromTimestamp(sp.createdAt),

      sp.activityDetails.previousRecord.activityTime,
      sp.activityDetails.previousRecord.checkPasswordExpirationDays,
      sp.activityDetails.previousRecord.checkPasswordHistoryLength,
      sp.activityDetails.previousRecord.dashboardActivity,
      sp.activityDetails.previousRecord.maxDaysToInactive,
      sp.activityDetails.previousRecord.maxFailedAttempts,
      sp.activityDetails.previousRecord.maxFailedAttemptsRequired,
      sp.activityDetails.previousRecord.passwordExpirationDays,
      sp.activityDetails.previousRecord.passwordHistoryLength,
      sp.activityDetails.previousRecord.requireInactiveDuration,
      sp.activityDetails.previousRecord.requirePasswordChangeOnFirstLogin,

      sp.activityDetails.newRecord.activityTime,
      sp.activityDetails.newRecord.checkPasswordExpirationDays,
      sp.activityDetails.newRecord.checkPasswordHistoryLength,
      sp.activityDetails.newRecord.dashboardActivity,
      sp.activityDetails.newRecord.maxDaysToInactive,
      sp.activityDetails.newRecord.maxFailedAttempts,
      sp.activityDetails.newRecord.maxFailedAttemptsRequired,
      sp.activityDetails.newRecord.passwordExpirationDays,
      sp.activityDetails.newRecord.passwordHistoryLength,
      sp.activityDetails.newRecord.requireInactiveDuration,
      sp.activityDetails.newRecord.requirePasswordChangeOnFirstLogin,
    ]
  })

  return {
    columns: header,
    body: parsedData,
  }
}

export const securityParameterDashboardDataMapper = (data) => {
  return data.map((sp) => {
    return {
      ActionOwner: sp.emailUser,
      Action: sp.activityDetails.abmAction,
      Status: sp.activityDetails.actionResult,
      Date: buildTimeStringFromTimestamp(sp.createdAt),

      PreviousActivityTime: sp.activityDetails?.previousRecord?.activityTime
        ? sp.activityDetails?.previousRecord?.activityTime
        : '',
      PreviousCheckPasswordExpirationDays: sp.activityDetails?.previousRecord?.checkPasswordExpirationDays
        ? sp.activityDetails?.previousRecord?.checkPasswordExpirationDays
        : '',
      PreviousCheckPasswordHistoryLength: sp.activityDetails?.previousRecord?.checkPasswordHistoryLength
        ? sp.activityDetails?.previousRecord?.checkPasswordHistoryLength
        : '',
      PreviousDashboardActivity: sp.activityDetails?.previousRecord?.dashboardActivity
        ? sp.activityDetails?.previousRecord?.dashboardActivity
        : '',
      PreviousMaxDaysToInactive: sp.activityDetails?.previousRecord?.maxDaysToInactive
        ? sp.activityDetails?.previousRecord?.maxDaysToInactive
        : '',
      PreviousMaxFailedAttempts: sp.activityDetails?.previousRecord?.maxFailedAttempts
        ? sp.activityDetails?.previousRecord?.maxFailedAttempts
        : '',
      PreviousMaxFailedAttemptsRequired: sp.activityDetails?.previousRecord?.maxFailedAttemptsRequired
        ? sp.activityDetails?.previousRecord?.maxFailedAttemptsRequired
        : '',
      PreviousPasswordExpirationDays: sp.activityDetails?.previousRecord?.passwordExpirationDays
        ? sp.activityDetails?.previousRecord?.passwordExpirationDays
        : '',
      PreviousPasswordHistoryLength: sp.activityDetails?.previousRecord?.passwordHistoryLength
        ? sp.activityDetails?.previousRecord?.passwordHistoryLength
        : '',
      PreviousRequireInactiveDuration: sp.activityDetails?.previousRecord?.requireInactiveDuration
        ? sp.activityDetails?.previousRecord?.requireInactiveDuration
        : '',
      PreviousRequirePasswordChangeOnFirstLogin: sp.activityDetails?.previousRecord?.requirePasswordChangeOnFirstLogin
        ? sp.activityDetails?.previousRecord?.requirePasswordChangeOnFirstLogin
        : '',

      NewActivityTime: sp.activityDetails?.newRecord?.activityTime ? sp.activityDetails?.newRecord?.activityTime : '',
      NewCheckPasswordExpirationDays: sp.activityDetails?.newRecord?.checkPasswordExpirationDays
        ? sp.activityDetails?.newRecord?.checkPasswordExpirationDays
        : '',
      NewCheckPasswordHistoryLength: sp.activityDetails?.newRecord?.checkPasswordHistoryLength
        ? sp.activityDetails?.newRecord?.checkPasswordHistoryLength
        : '',
      NewDashboardActivity: sp.activityDetails?.newRecord?.dashboardActivity
        ? sp.activityDetails?.newRecord?.dashboardActivity
        : '',
      NewMaxDaysToInactive: sp.activityDetails?.newRecord?.maxDaysToInactive
        ? sp.activityDetails?.newRecord?.maxDaysToInactive
        : '',
      NewMaxFailedAttempts: sp.activityDetails?.newRecord?.maxFailedAttempts
        ? sp.activityDetails?.newRecord?.maxFailedAttempts
        : '',
      NewMaxFailedAttemptsRequired: sp.activityDetails?.newRecord?.maxFailedAttemptsRequired
        ? sp.activityDetails?.newRecord?.maxFailedAttemptsRequired
        : '',
      NewPasswordExpirationDays: sp.activityDetails?.newRecord?.passwordExpirationDays
        ? sp.activityDetails?.newRecord?.passwordExpirationDays
        : '',
      NewPasswordHistoryLength: sp.activityDetails?.newRecord?.passwordHistoryLength
        ? sp.activityDetails?.newRecord?.passwordHistoryLength
        : '',
      NewRequireInactiveDuration: sp.activityDetails?.newRecord?.requireInactiveDuration
        ? sp.activityDetails?.newRecord?.requireInactiveDuration
        : '',
      NewRequirePasswordChangeOnFirstLogin: sp.activityDetails?.newRecord?.requirePasswordChangeOnFirstLogin
        ? sp.activityDetails?.newRecord?.requirePasswordChangeOnFirstLogin
        : '',
    }
  })
}

import React, { useEffect } from 'react'
import { useState } from 'react'
import { Grid, Paper, Avatar, TextField, Button, Typography, Box } from '@mui/material'
import { ThemeProvider } from '@emotion/react'
import { createTheme } from '@mui/material'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import GuenoLogo from '../../assets/logos/gueno-logo.png'
import './change-password.scss'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { decodeToken } from 'react-jwt'
import { useTranslation } from 'react-i18next'

const theme = createTheme({
  palette: {
    primary: {
      main: '#0ddd4e',
      light: '#67ff7f',
      dark: '#00aa18',
      contrastText: '#000000',
    },
    secondary: {
      main: '#1c1534',
      light: '#453b5e',
      dark: '#00000f',
      contrastText: '#ffffff',
    },
  },
})

const ChangePassword = ({ handleUserLogout }) => {
  const paperStyle = {
    padding: 20,
    width: '25%',
    margin: '10vh auto',
    borderRight: '4px solid #00aa18',
    boxShadow: '2px 4px 10px 1px rgba(201, 201, 201, 0.47)',
    borderRadious: '10px',
  }
  const avatarStyle = {
    width: '48px',
    height: '48px',
    borderRadius: '50%',
  }
  const btnstyle = { margin: '8px 0 32px 0' }
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [showPassword, setShowPassword] = useState('password')
  const [showPassword1, setShowPassword1] = useState('password')
  const [showPassword2, setShowPassword2] = useState('password')
  const [isExpired, setIsExpired] = useState(false)
  const [expiredDate, setIsExpiredDate] = useState(0)
  const { accessToken, email } = useSelector((state) => state.login)
  const navigate = useNavigate()
  const { t } = useTranslation(['common', 'messages'])
  const regex = /(^[A-Z]{4,20}(?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/
  let isSaveBtnOk = errorMessage === '' && oldPassword !== ''

  useEffect(() => {
    if (accessToken) {
      const decodedToken = decodeToken(accessToken)
      if (decodedToken && decodedToken.timeToExpirationPassword && decodedToken.timeToExpirationPassword <= 0) {
        setIsExpired(true)
        setIsExpiredDate(decodedToken.timeToExpirationPassword * -1)
      }
    }
  }, [accessToken])

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && isSaveBtnOk) {
      handleChangePassword(e)
    }
  }

  const handleMouseDownPassword = (e) => {
    e.preventDefault()
  }

  const logout = () => {
    handleUserLogout()
    navigate('/')
  }

  const handleChangePassword = (e) => {
    e.preventDefault()
    if (isSaveBtnOk) {
      const data = {
        email,
        oldPassword,
        password: newPassword,
        confirmNewPassword,
      }

      const urlUser = `${process.env.REACT_APP_BASEURL}/user/changePassword`
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(data),
      }

      fetch(urlUser, options)
        .then((res) => res.json())
        .then((res) => {
          if (!res.success) {
            setError(true)
            setErrorMessage(typeof res.data === 'string' ? res.data : t('messages:unexpectedError'))
          } else {
            setError(false)
            setSuccess(true)
            setErrorMessage('')
            setNewPassword('')
            setConfirmNewPassword('')
          }
        })
        .catch((error) => console.error('[CHANGE PASSWORD ERROR] --> ', error))
    }
  }

  useEffect(() => {
    if (newPassword !== '' || confirmNewPassword !== '') {
      if (!regex.test(newPassword) && newPassword !== confirmNewPassword) {
        setErrorMessage(`${t('messages:passwordTooWeak')}. ${t('messages:passwordDontMatch')}`)
        setError(true)
      } else if (!regex.test(newPassword)) {
        setErrorMessage(t('messages:passwordTooWeak'))
        setError(true)
      } else if (newPassword !== confirmNewPassword) {
        setErrorMessage(t('messages:passwordDontMatch'))
        setError(true)
      } else {
        setError(false)
        setErrorMessage('')
      }
    }
  }, [newPassword, confirmNewPassword])

  return (
    <ThemeProvider theme={theme}>
      <Box className="change-password-container">
        <Grid container>
          <Paper elevation={10} style={paperStyle}>
            <Grid align="center" className="grid">
              <Avatar style={avatarStyle}>
                <img src={GuenoLogo} alt="güeno-logo" className="logo" />
              </Avatar>
              <Box className="flex">
                <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
                  {t('common:welcome')}
                </Typography>
                <Typography variant="h5" component="h1" color="primary" style={{ paddingLeft: 6, fontWeight: 600 }}>
                  Güeno 🚀
                </Typography>
              </Box>
            </Grid>

            {!success ? (
              <Box className="change-password-message">
                <Typography className="title" color="primary">
                  {t('messages:passwordUpdate')}
                </Typography>
                {t('messages:weHaveNoticed')}
                {isExpired
                  ? `${t('messages:yourPasswordHasExpired')} ${Math.trunc(expiredDate)} ${t('messages:daysAgo')}`
                  : t('messages:firstLogin')}
                , {t('messages:securityReasons')}.
              </Box>
            ) : (
              ''
            )}

            {success ? (
              <Box className="change-password-success">
                <p className="title">{t('messages:passwordChanged')}.</p>
                <Button
                  onClick={logout}
                  color="primary"
                  variant="contained"
                  style={{ ...btnstyle, fontWeight: 600, fontSize: 16 }}
                  fullWidth
                >
                  Ok
                </Button>
              </Box>
            ) : (
              <Box>
                <Box className="change-password-inputs">
                  <TextField
                    type={showPassword1}
                    id="old-password"
                    onChange={(e) => {
                      setOldPassword(e.target.value)
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>
                              showPassword1 === 'password' ? setShowPassword1('text') : setShowPassword1('password')
                            }
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword1 === 'text' ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    onKeyDown={(evt) => handleKeyPress(evt)}
                    label={t('messages:oldPassword')}
                    placeholder={t('messages:oldPassword')}
                    fullWidth
                    required
                  />

                  <TextField
                    type={showPassword}
                    id="new-password"
                    onChange={(e) => {
                      setNewPassword(e.target.value)
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>
                              showPassword === 'password' ? setShowPassword('text') : setShowPassword('password')
                            }
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword === 'text' ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    onKeyDown={(evt) => handleKeyPress(evt)}
                    label={t('messages:newPassword')}
                    placeholder={t('messages:newPassword')}
                    fullWidth
                    required
                  />

                  <TextField
                    type={showPassword2}
                    onChange={(e) => {
                      setConfirmNewPassword(e.target.value)
                    }}
                    onKeyDown={(evt) => handleKeyPress(evt)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>
                              showPassword2 === 'password' ? setShowPassword2('text') : setShowPassword2('password')
                            }
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword2 === 'text' ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    placeholder={t('messages:confirmNewPassword')}
                    fullWidth
                    label={t('messages:confirmNewPassword')}
                  />
                </Box>

                {errorMessage ? (
                  <Button className="change-password-error" size="small" variant="outlined" color="error">
                    {errorMessage}
                  </Button>
                ) : (
                  ''
                )}

                <Button
                  disabled={error || oldPassword === ''}
                  onClick={(e) => handleChangePassword(e)}
                  color="primary"
                  variant="contained"
                  style={{ ...btnstyle, fontWeight: 600, fontSize: 16 }}
                  fullWidth
                >
                  {t('common:save')}
                </Button>
              </Box>
            )}
          </Paper>
        </Grid>
      </Box>
    </ThemeProvider>
  )
}

export default ChangePassword

import { Box, Button, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import styles from './styles.module.scss'
import TextInputForm from '../../../../components/common/form/TextInputForm/TextInputForm'
import { useCreateKycPersonMutation } from '../../../../services/guenoApi'
import { LoadingButton } from '@mui/lab'

const schema = yup.object().shape({
  email: yup.string().email('Ingrese un email válido').required(),
  firstname: yup.string().required(),
  lastname: yup.string().required(),
})

const CreatePersonForm = ({ onSubmit = () => {}, onCancel= () => {} }) => {
  const { t } = useTranslation(['common', 'messages'])
  const [_, { isLoading }] = useCreateKycPersonMutation({fixedCacheKey: 'create-person'})

  const { handleSubmit, control, formState } = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      firstname: '',
      lastname: '',
    },
  })



  return (
    <Box component="form" className={styles.container} onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="title">{`${t('common:create')} ${t('common:person')}`}</Typography>
      <Box className={styles.formContainer}>
        <Box className={styles.inputField}>
          <TextInputForm
            name="firstname"
            control={control}
            label={t('common:firstName')}
            placeholder={t('common:firstName')}
          />
        </Box>
        <Box className={styles.inputField}>
          <TextInputForm
            name="lastname"
            control={control}
            label={t('common:lastName')}
            placeholder={t('common:lastName')}
            className={styles.inputField}
          />
        </Box>
        <Box className={styles.inputField} width="250px">
          <TextInputForm
            name="email"
            control={control}
            label={t('common:email')}
            placeholder={t('common:email')}
            className={styles.inputField}
          />
        </Box>
      </Box>
      <Box className={styles.actionButtons}>
        <Button variant="outlined" color="secondary" onClick={onCancel} disabled={isLoading}>
          {t('common:cancel')}
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          type="submit"
          disabled={!formState.isDirty || !formState.isValid}
          loading={isLoading}
        >
          {t('common:create')}
        </LoadingButton>
      </Box>
    </Box>
  )
}

export default CreatePersonForm
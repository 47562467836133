import './kytManualCheck.scss'

import '../../home/home.scss'
import '../../onboarding/onboarding.scss'
import '../../kyb/kyb.scss'
import { Box, Chip, Paper, Tab, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import '../../../components/common/alertFetchSpinner/alertFetchSpinner.scss'
import Spinner from '../../../components/common/spinner/spinner'
import { useTranslation } from 'react-i18next'
import AlertFetchSpinner from '../../../components/common/alertFetchSpinner/alertFetchSpinner'
import { useNavigate, useParams } from 'react-router-dom'
import ConsumerManualCheck from './ConsumerManualCheck'
import BusinessManualCheck from './BusinessManualCheck'
import TransactionManualCheck from './TransactionManualCheck'

const KytManualCheck = () => {
  const { tab } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [fetchError, setFetchError] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [fetchMessage, setFetchMessage] = useState('')
  const [isProcessing, setIsProcessing] = useState(false)
  const navigate = useNavigate()

  const { t } = useTranslation(['common', 'messages'])

  const handleChange = (event, newValue) => {
    navigate(`/kyt/manual/${newValue}`)
  }

  useEffect(() => {
    if (!tab || (tab !== 'consumer' && tab !== 'business' && tab !== 'transaction')) {
      navigate('/kyt/manual/consumer')
    }
  }, [tab])

  return (
    <Box className={`${isLoading && 'spinner-transition'}`}>
      {isLoading && <Spinner noTransform />}
      {isFetching && <AlertFetchSpinner message={fetchMessage} error={fetchError} spinner={isProcessing} />}
      <Box className="component-title-wrapper">
        <Box className="component-title">
          <Typography variant="h2">KYT {t('common:manualCheck')}</Typography>
        </Box>
        <Typography variant="subtitle3">{/* Loren ipsum dolor sit amet Loren ipsum dolor sit amet Loren  */}</Typography>
      </Box>

      <TabContext value={tab}>
        <TabList
          TabIndicatorProps={{
            style: { display: 'none' },
          }}
          onChange={handleChange}
          sx={{ marginBottom: '1rem' }}
        >
          <Tab
            label={<Chip label={t('common:consumer')} variant={tab === 'consumer' ? 'sliderSelected' : 'slider'} />}
            value="consumer"
          ></Tab>
          <Tab
            label={<Chip label={t('common:business')} variant={tab === 'business' ? 'sliderSelected' : 'slider'} />}
            value="business"
          />
          <Tab
            label={
              <Chip label={t('common:transaction')} variant={tab === 'transaction' ? 'sliderSelected' : 'slider'} />
            }
            value="transaction"
          />
        </TabList>
        <Paper elevation={0} variant="rootOutlined">
          <TabPanel value="consumer">
            <ConsumerManualCheck />
          </TabPanel>

          <TabPanel value="business">
            <BusinessManualCheck />
          </TabPanel>

          <TabPanel value="transaction">
            <TransactionManualCheck />
          </TabPanel>
        </Paper>
      </TabContext>
    </Box>
  )
}

export default KytManualCheck

import { Autocomplete, Box, Button, Input, TextField, Tooltip } from '@mui/material'
import '../kytManualCheck.scss'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InfoIcon from '@mui/icons-material/Info'

const transactionTypes = [
  'CustomerPayBillOnline',
  'CustomerBuyGoodsOnline',
  'SalaryPayment',
  'BusinessPayment',
  'PromotionPayment',
]

const MpesaDetails = ({ setPaymentDetails, isReset, setIsReset }) => {
  const [businessShortCode, setBusinessShortCode] = useState('')
  const [transactionType, setTransactionType] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')

  const { t } = useTranslation(['common', 'messages'])
  const [change, setChange] = useState(false)

  useEffect(() => {
    if (change) {
      const body = {
        method: 'MPESA',
        ...(businessShortCode && { businessShortCode }),
        ...(transactionType && { transactionType }),
        ...(phoneNumber && { phoneNumber }),
      }

      setPaymentDetails(body)
      setChange(false)
    }
  }, [change])

  useEffect(() => {
    if (isReset) {
      setBusinessShortCode('')
      setTransactionType('')
      setPhoneNumber('')
      setIsReset(false)
    }
  }, [isReset])

  const renderTooltip = (title) => (
    <Tooltip title={title}>
      <Button className="back-tip">
        <InfoIcon fontSize="small" className="security-info-icon" />
      </Button>
    </Tooltip>
  )

  return (
    <Box>
      <Box className="kyt-form-pair">
        <Box className="kyt-form-pair-label">
          <span className="input-error">* </span>
          <label>{t('common:businessShortCode')}</label>
        </Box>
        {renderTooltip(
          <Box className="back-tip">
            <label className="security-items-info" color="inherit">
              {'Business code.'}
            </label>
          </Box>,
        )}
        <Input
          className="full-width"
          size="small"
          type="text"
          value={businessShortCode}
          onChange={(event) => {
            setBusinessShortCode(event.target.value)
            setChange(true)
          }}
          placeholder={`${t('common:insert')} ${t('common:businessShortCode')} ${t('here')}`}
        />
      </Box>
      <Box className="kyt-form-pair">
        <Box className="kyt-form-pair-label">
          <span className="input-error">* </span>
          <label>{t('common:transactionType')}</label>
        </Box>
        {renderTooltip(
          <Box className="back-tip">
            <label className="security-items-info" color="inherit">
              {'Transaction Type.'}
            </label>
          </Box>,
        )}
        <Autocomplete
          size="small"
          className="full-width"
          value={transactionType}
          onChange={(event, value) => {
            setTransactionType(value)
            setChange(true)
          }}
          disablePortal
          id="combo-box-demo"
          options={transactionTypes}
          renderInput={(params) => <TextField variant="standard" {...params} placeholder="Select Transaction Type" />}
        />
      </Box>
      <Box className="kyt-form-pair">
        <Box className="kyt-form-pair-label">
          <span className="input-error">* </span>
          <label>{t('common:phoneNumber')}</label>
        </Box>
        {renderTooltip(
          <Box className="back-tip">
            <label className="security-items-info" color="inherit">
              {'Contact Number of the account holder.'}
            </label>
          </Box>,
        )}
        <Input
          className="full-width"
          size="small"
          type="text"
          value={phoneNumber}
          onChange={(event) => {
            setPhoneNumber(event.target.value)
            setChange(true)
          }}
          placeholder={`${t('common:insert')} ${t('common:phoneNumber')} ${t('here')}`}
        />
      </Box>
    </Box>
  )
}

export default MpesaDetails

import { createSlice } from '@reduxjs/toolkit'

export const ecommerceRiskSlice = createSlice({
  name: 'ecommerceRisk',
  initialState: {
    ecommerceRisks: {},
    ecommerceRiskDetail: {},
    filters: {
      page: 0,
      limit: 25,
      offset: 0,
      fromDate: null,
      toDate: '',
      checkId: '',
      firstName: '',
      lastName: '',
      documentNumber: '',
      orderId: '',
    },
  },
  reducers: {
    updateEcommerceRisks: (state, action) => {
      state.ecommerceRisks = action.payload
    },
    updateEcommerceRiskDetail: (state, action) => {
      state.ecommerceRiskDetail = action.payload
    },
    updateEcommerceRiskFilters: (state, action) => {
      state.filters = action.payload
    },
  },
})

export const { updateEcommerceRisks, updateEcommerceRiskDetail, updateEcommerceRiskFilters } =
  ecommerceRiskSlice.actions
export default ecommerceRiskSlice.reducer

import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setPaymentMethodDetailUsersData } from '../../features/paymentMethodDetailUsers/paymentMethodDetailUsersSlice'

export const usePaymentMethodDetailUsers = (paymentMethodDetailUsers, userType, setIsLoading) => {
  const { accessToken, emailClient } = useSelector((state) => state.login)
  const queryState = useSelector((state) => state.paymentMethodDetailUsers.filters)

  const dispatch = useDispatch()

  useEffect(() => {
    const baseUrlPaymentMethodDetailUsers = `${process.env.REACT_APP_BASEURL}/kyt/externalPaymentMethods/${emailClient}/users/${userType}`
    function buildUrl(baseUrl) {
      return `${baseUrl}?limit=${queryState?.limit}&page=${queryState?.page}&offset=${queryState?.offset}`
    }
  
    let urlPostPaymentMethodDetailUsers = buildUrl(baseUrlPaymentMethodDetailUsers)
    const options = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ paymentMethod: paymentMethodDetailUsers })
    }

    fetch(urlPostPaymentMethodDetailUsers, options)
    .then((res) => {
      return res.json()
    })
    .then((res) => {
      if (res.success) {
        const { items, statistics, paginationResult } = res.data
        dispatch(setPaymentMethodDetailUsersData({ data: items, statistics, pagination: paginationResult }))
      }
      setIsLoading(false)
    })
    .catch((error) => {
      console.error('[HOOK: usePaymentMethodDetailUsers] --> ', error)
      setIsLoading(false)
    })
  }, [queryState, paymentMethodDetailUsers, userType])
}
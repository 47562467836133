import './caseWorkflows.scss'
import { Box, Button, Paper, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from '../../../components/common/spinner/spinner'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { FilterModal } from '../../../components/utilities/FilterModal'
import { updateWorkflowsFilters } from '../../../features/workflows/workflowsSlice'
import DatatableWorkflows from '../../../components/common/datatable/DatatableWorkflows'

const CaseWorkflows = () => {
  const { emailClient } = useSelector((state) => state.login)
  const queryState = useSelector((state) => state.workflows.filters)
  const [status, setStatus] = useState(queryState?.status)
  const [name, setName] = useState(queryState?.name)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(50)
  const [modalOpen, setModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation(['common'])

  const handleResetButton = () => {
    if (status !== '') setStatus('')
    if (name !== '') setName('')
    setPage(0)
    setIsLoading(true)

    dispatch(
      updateWorkflowsFilters({
        emailClient: emailClient,
        status: '',
        name: '',
        page: 0,
        limit: 50,
        offset: 0,
      }),
    )
  }

  const handleDispatch = () => {
    setIsLoading(true)
    return dispatch(
      updateWorkflowsFilters({
        emailClient: emailClient,
        status: status,
        name,
        page,
        limit: rowsPerPage,
        offset: rowsPerPage * page,
      }),
    )
  }

  useEffect(() => {
    handleDispatch()
  }, [rowsPerPage, page])

  return (
    <Box>
      <Box className={`${isLoading && 'spinner-transition'} filter`}>
        {isLoading && <Spinner noTransform />}

        <Box className="component-title-wrapper">
          <Box className="component-title">
            <Typography variant="h2">{t('common:workflows')}</Typography>

            <Button size="small" variant="contained" onClick={() => navigate('/case-workflows/creation')}>
              {`${t('common:create')} ${t('common:workflow')}`}
            </Button>
          </Box>
          <Typography variant="subtitle3">
            {/* Loren ipsum dolor sit amet Loren ipsum dolor sit amet Loren  */}
          </Typography>
        </Box>

        <Paper elevation={0}>
          <DatatableWorkflows
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            setModalOpen={setModalOpen}
          />
        </Paper>
      </Box>

      <FilterModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        handleResetButton={handleResetButton}
        setPage={setPage}
        handleDispatch={handleDispatch}
      >
        <Box className="modal-box-filters">
          <Box className="modal-box">
            <Typography variant="subtitle3">
              {t('common:by')} {t('common:name')}:
            </Typography>
            <TextField
              placeholder={t('common:searchByName')}
              required
              fullWidth
              value={name}
              onChange={(event) => setName(event.target.value)}
              size="small"
            />
          </Box>
        </Box>
      </FilterModal>
    </Box>
  )
}

export default CaseWorkflows

import { createSlice } from '@reduxjs/toolkit'
import jwt_decode from 'jwt-decode'

export const loginSlice = createSlice({
  name: 'login',
  initialState: {
    accessToken: '',
    email: '',
    activeServices: [],
    emailClient: '',
    roles: [],
    securityParameters: {},
    alertSettings: {},
    expirationMessageAccepted: false,
  },
  reducers: {
    performLogin: (state, action) => {
      const decoded = jwt_decode(action.payload)

      state.accessToken = action.payload
      state.email = decoded.email
      state.emailClient = decoded.emailClient
      state.roles = decoded.roles
    },
    performLogout: (state) => {
      state.accessToken = ''
      state.email = ''
      state.activeServices = []
      state.roles = []
      state.securityParameters = {}
      state.alertSettings = {}
      state.expirationMessageAccepted = false
    },
    performSetServices: (state, action) => {
      state.activeServices = action.payload
    },
    performSetSecurityParameters: (state, action) => {
      state.securityParameters = action.payload
    },
    performSetAlertSettings: (state, action) => {
      state.alertSettings = action.payload
    },
    acceptExpirationPasswordMessage: (state, action) => {
      state.expirationMessageAccepted = action.payload
    },
  },
})

export const {
  performLogin,
  performLogout,
  performSetServices,
  performSetSecurityParameters,
  acceptExpirationPasswordMessage,
  performSetAlertSettings,
} = loginSlice.actions

export default loginSlice.reducer

import { Box, Button, InputLabel, MenuItem, Select, TextField, Tooltip, Typography } from '@mui/material'
import './kytManualCheck.scss'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InfoIcon from '@mui/icons-material/Info'
import { countryDictionary } from '../../../components/common/flag/countries'
import { CURRENCIES_MANUAL } from '../transaction/transaction-constants'
import { RenderTooltip } from '../../../components/utilities/RenderTooltip'

const AmountDetails = ({ setAmountDetails, isReset, setIsReset }) => {
  const [transactionAmount, setTransactionAmount] = useState('')
  const [transactionCurrency, setTransactionCurrency] = useState('')
  const [country, setCountry] = useState('')
  const [countryView, setCountryView] = useState('')

  const { t } = useTranslation(['common', 'messages'])
  const [change, setChange] = useState(false)

  useEffect(() => {
    if (change) {
      const body = {
        ...(transactionAmount && { transactionAmount }),
        ...(transactionCurrency && { transactionCurrency }),
        ...(country && { country }),
      }

      setAmountDetails(body)
      setChange(false)
    }
  }, [change])

  useEffect(() => {
    if (isReset) {
      setTransactionAmount('')
      setTransactionCurrency('')
      setCountry('')
      setCountryView('')
      setIsReset(false)
    }
  }, [isReset])

  const renderTooltip = (title) => (
    <Tooltip title={title}>
      <Button className="back-tip">
        <InfoIcon fontSize="small" className="security-info-icon" />
      </Button>
    </Tooltip>
  )

  const getKeyByValue = (value) => {
    for (const key in countryDictionary) {
      if (countryDictionary[key] === value) {
        return key
      }
    }
    return null
  }

  return (
    <Box className="kyt-sub-form">
      <Box className="kyt-form-pair">
        <Box className="kyt-form-pair-label">
          <InputLabel>{t('common:transactionAmount')}</InputLabel>
          <RenderTooltip title={t('common:transactionAmount')} />
        </Box>

        <TextField
          fullWidth
          type="number"
          placeholder={`${t('common:insert')} ${t('common:transactionAmount')} ${t('here')}`}
          size="small"
          inputProps={{
            step: '0.01',
          }}
          value={transactionAmount}
          onChange={(event) => {
            setTransactionAmount(event.target.value !== '' ? parseFloat(event.target.value) : '')
            setChange(true)
          }}
        />
      </Box>
      <Box className="kyt-form-pair">
        <Box className="kyt-form-pair-label">
          <InputLabel>{t('common:transactionCurrency')}</InputLabel>
          <RenderTooltip title={t('common:transactionCurrency')} />
        </Box>

        <Select
          size="small"
          fullWidth
          value={transactionCurrency}
          onChange={(e) => {
            if (e.target.value) {
              setTransactionCurrency(e.target.value)
            } else {
              setTransactionCurrency(null)
            }
            setChange(true)
          }}
          displayEmpty
        >
          <MenuItem disabled value="">
            <Typography variant="subtitle3">
              {t('common:select')} {t('common:transactionCurrency')}
            </Typography>
          </MenuItem>
          {CURRENCIES_MANUAL.map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box className="kyt-form-pair">
        <Box className="kyt-form-pair-label">
          <InputLabel>{t('common:country')}</InputLabel>
          <RenderTooltip title={t('common:country')} />
        </Box>
        <Select
          size="small"
          fullWidth
          value={countryView}
          onChange={(e) => {
            if (e.target.value) {
              setCountry(getKeyByValue(e.target.value))
              setCountryView(e.target.value)
            } else {
              setCountry(null)
              setCountryView(null)
            }
            setChange(true)
          }}
          displayEmpty
        >
          <MenuItem disabled value="">
            <Typography variant="subtitle3">
              {t('common:select')} {t('common:country')}
            </Typography>
          </MenuItem>
          {Object.values(countryDictionary).map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </Box>
    </Box>
  )
}

export default AmountDetails

let timeoutIds = []

export const setPersistentTimeout = (callback, delay) => {
  const timeoutId = setTimeout(callback, delay)
  timeoutIds.push(timeoutId)
}

export const clearAllPersistentTimeouts = () => {
  timeoutIds.forEach((timeoutId) => clearTimeout(timeoutId))
  timeoutIds = []
}

import './exploration.scss'
import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Highcharts from 'highcharts'
import networkgraph from 'highcharts/modules/networkgraph'

networkgraph(Highcharts)

const Exploration = () => {
  useEffect(() => {
    Highcharts.addEvent(Highcharts.Series, 'afterSetOptions', function (e) {
      var colors = Highcharts.getOptions().colors,
        i = 0,
        nodes = {}

      if (this instanceof Highcharts.seriesTypes.networkgraph && e.options.id === 'lang-tree') {
        e.options.data.forEach(function (link) {
          if (link[0] === 'Güeno.com') {
            nodes['Güeno.com'] = {
              id: 'Güeno.com',
              marker: {
                radius: 50,
              },
            }
            nodes[link[1]] = {
              id: link[1],
              marker: {
                radius: 20,
              },
              color: colors[i++],
            }
          } else if (nodes[link[0]] && nodes[link[0]].color) {
            nodes[link[1]] = {
              id: link[1],
              color: nodes[link[0]].color,
            }
          }
        })

        e.options.nodes = Object.keys(nodes).map(function (id) {
          return nodes[id]
        })
      }
    })

    Highcharts.chart('grafico', {
      chart: {
        type: 'networkgraph',
        height: '50%',
      },
      title: {
        text: 'Gueno',
      },
      subtitle: {
        text: `The exploration graph in Güeno's analysis illustrates the depth of analysis across collected data points. Each node represents a data point; its depth indicates the analysis level. Connections between nodes highlight relationships, aiding in dataset comprehension. By analyzing the graph, Güeno efficiently navigates through the data, identifying patterns and optimizing the exploration strategy.`,
      },
      /*plotOptions: {
    networkgraph: {
      keys: ['from', 'to'],
      layoutAlgorithm: {
        enableSimulation: true,
        friction: -0.9
      }
    }
  },*/ plotOptions: {
        networkgraph: {
          keys: ['from', 'to'],
          layoutAlgorithm: {
            enableSimulation: true,
            integration: 'verlet',
            // integration: 'euler',
            linkLength: 100,
            //friction: -0.9
          },
        },
      },
      series: [
        {
          dataLabels: {
            enabled: true,
            linkFormat: '',
          },
          id: 'lang-tree',
          data: [
            ['Güeno.com', 'BankEntity'],
            ['BankEntity', 'Santander'],
            ['BankEntity', 'Itau'],
            ['BankEntity', 'Starkbank'],
            ['BankEntity', 'BancoDuBrasil'],
            ['BankEntity', 'Macro'],
            ['Güeno.com', 'CardFingerprint'],
            ['CardFingerprint', 'CF-1234-ABCD-5678'],
            ['CardFingerprint', 'CF-9876-EFGH-4321'],
            ['CardFingerprint', 'CF-4567-IJKL-8901'],
            ['CardFingerprint', 'CF-2468-MNOP-1357'],
            ['CardFingerprint', 'CF-7531-QRST-3690'],
            ['CardFingerprint', 'CF-8642-UVWX-2580'],
            ['CardFingerprint', 'CF-7777-CDEF-8888'],
            ['CardFingerprint', 'CF-5555-GHIJ-6666'],
            ['CardFingerprint', 'CF-2222-KLMN-3333'],
            ['UUID', 'AD213Sds92'],
            ['UUID', 'BC451Klp78'],
            ['UUID', 'XY789Fgh23'],
            ['UUID', 'PQ987Jkl56'],
            ['UUID', 'LM123Wxv49'],
            ['UUID', 'ZA567Mnb87'],
            ['UUID', 'RT345Qwe12'],
            ['UUID', 'FG678Hjk34'],
            ['UUID', 'UI901Pqr67'],
            ['UUID', 'OP234Vbn89'],
            ['UUID', 'CD567Yui12'],
            ['UUID', 'VW123Zxc45'],
            ['UUID', 'KL789Abc23'],
            ['UUID', 'EF456Ghi78'],
            ['UUID', 'MN890Def56'],
            ['UUID', 'GH123Tyz49'],
            ['UUID', 'ST234Xcv67'],
            ['UUID', 'WX567Rty12'],
            ['UUID', 'JK901Uio34'],
            ['UUID', 'QR678Nmp89'],
            ['UUID', 'Güeno.com'],
            ['UserEmail', 'juanita.torres@email.com'],
            ['UserEmail', 'elena.ruiz@email.com'],
            ['UserEmail', 'oscar.martinez@email.com'],
            ['UserEmail', 'carmen.soto@email.com'],
            ['UserEmail', 'sergio.perez@email.com'],
            ['UserEmail', 'patricia.lopez@email.com'],
            ['UserEmail', 'andrea.paredes@email.com'],
            ['UserEmail', 'roberto.diaz@email.com'],
            ['UserEmail', 'paula.herrera@email.com'],
            ['UserEmail', 'diego.ramirez@email.com'],
            ['UserEmail', 'julia.castro@email.com'],
            ['UserEmail', 'martin.torres@email.com'],
            ['UserEmail', 'sofia.gonzalez@email.com'],
            ['UserEmail', 'javier.fernandez@email.com'],
            ['UserEmail', 'laura.martinez@email.com'],
            ['UserEmail', 'luis.garcia@email.com'],
            ['UserEmail', 'ana.lopez@email.com'],
            ['UserEmail', 'carlos.sanchez@email.com'],
            ['UserEmail', 'maria.rodriguez@email.com'],
            ['UserEmail', 'maria22.rodriguez@email.com'],
            ['UserEmail', 'juan.perez@email.com'],
            ['UserEmail', 'juan.p@email.com'],
            ['UserEmail', 'd.rrez@email.com'],
            ['UserEmail', 'coto@email.com'],
            ['UserEmail', 'patrez@email.com'],
            ['UserEmail', 'elea.rez@email.com'],
            ['UserEmail', 'julestro@email.com'],
            ['Güeno.com', 'Phone'],
            ['Phone', '+5511432543'],
            ['Phone', '+5311432543'],
            ['Phone', '+5411432543'],
            ['Phone', '+5611432543'],
            ['Phone', '+5411432543'],
            ['Phone', '+5911322543'],
            ['Phone', '+5923463424'],
            ['Phone', '+5411342553'],
            ['Phone', '+5411432543'],
            ['Phone', '+5211934593'],
            ['Phone', '+5111035695'],
            ['Phone', '+5411901232'],
            ['Phone', '+5711923491'],
            ['Phone', '+5811023543'],
            ['Güeno.com', 'Device'],
            ['Güeno.com', 'UserEmail'],
            ['Device', '10.30.0.25'],
            ['Device', '172.18.0.12'],
            ['Device', '192.168.6.62'],
            ['Device', '192.168.0.10'],
            ['Device', '10.30.0.26'],
            ['Device', '10.30.0.28'],
            ['Device', '172.16.1.200'],
            ['Device', '192.168.4.33'],
            ['Device', '192.168.5.17'],
            ['Device', '172.17.0.90'],
            ['Device', '192.168.6.62'],
            ['Device', '172.18.0.12'],
            ['Device', '310.30.0.125'],
            ['Device', '210.30.0.215'],
            ['Device', '310.30.0.525'],
            ['Device', '310.30.0.425'],
            ['Device', '210.30.0.265'],
            ['Device', '210.30.0.325'],
            ['Device', '110.30.0.25'],
            ['Device', '110.30.0.325'],
            ['Device', '192.168.2.50'],
            //Relaciones entre datos Mail y IP
            ['juan.perez@email.com', '192.168.2.50'],
            ['juan.perez@email.com', '110.30.0.325'],
            ['GH123Tyz49', '210.30.0.325'],
            ['diego.ramirez@email.com', '310.30.0.125'],
            ['diego.ramirez@email.com', '172.17.0.90'],
            //Relaciones entre datos IP y UUID
            ['juan.perez@email.com', 'GH123Tyz49'],
            //Testiando
            ['CD567Yui12', '+5511432543', 'javier.fernandez@email.com', 'Starkbank'],
            ['javier.fernandez@email.com', '+5511432543'],
          ],
        },
      ],
    })
  })

  return (
    <Box>
      <Box className="own-style" id="grafico"></Box>
      <img
        className="img-separator"
        src="https://res.cloudinary.com/g-eno/image/upload/v1695828702/Testing/exploration-details.png"
        alt=""
      />
    </Box>
  )
}

export default Exploration

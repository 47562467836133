import { createSlice } from '@reduxjs/toolkit'
export const transactionSlice = createSlice({
  name: 'transaction',
  initialState: {
    transactions: [],
    transactionDetails: {},
    transactionByUserId: {},
    transactionDetailsOrigin: {},
    transactionDetailsDestination: {},
    annualTransactions: [],
    monthTransactions: [],
    filters: {
      viewMode: true,
      page: 0,
      limit: 10,
      offset: 0,
      transactionId: '',
      transactionType: null,
      fromDate: null,
      toDate: '',
      originCurrency: null,
      destinationCurrency: null,
      originMethod: null,
      destinationMethod: null,
      ruleId: '',
      ruleInstanceId: '',
      maxRuleAction: '',
      transactionKey: '',
      transactionValue: '',
      documentNumber: '',
      firstName: '',
      lastName: '',
      legalName: '',
    },
  },
  reducers: {
    updateTransactions: (state, transactions) => {
      state.transactions = transactions.payload
    },
    updateTransactionDetails: (state, details) => {
      state.transactionDetails = details.payload
    },
    updateTransactionByUserId: (state, details) => {
      state.transactionByUserId = details.payload
    },
    updateTransactionDetailsOrigin: (state, details) => {
      state.transactionDetailsOrigin = details.payload
    },
    updateTransactionDetailsDestination: (state, details) => {
      state.transactionDetailsDestination = details.payload
    },
    updateAnnualTransactions: (state, annualTransactions) => {
      state.annualTransactions = annualTransactions.payload
    },
    updateMonthTransactions: (state, monthTransactions) => {
      state.monthTransactions = monthTransactions.payload
    },
    performLogoutTransactions: (state) => {
      state.transactions = []
      state.annualTransactions = []
      state.monthTransactions = []
      state.transactionDetails = {}
      state.transactionByUserId = {}
      state.transactionDetailsOrigin = {}
      state.transactionDetailsDestination = {}
    },
    updateTransactionsFilters: (state, filters) => {
      state.filters = filters.payload
    },
  },
})
export const {
  updateTransactions,
  updateTransactionDetails,
  updateTransactionDetailsOrigin,
  updateTransactionDetailsDestination,
  updateAnnualTransactions,
  updateMonthTransactions,
  performLogoutTransactions,
  updateTransactionsFilters,
  updateTransactionByUserId,
} = transactionSlice.actions
export default transactionSlice.reducer

import { ReactFlowProvider } from 'react-flow-renderer'
import './Main.scss'
import Sidebar from '../Sidebar'
import Flow from '../Flow'
import 'reactflow/dist/style.css'

const DynamicFlow = () => {
  return (
    <div className="dnd-flow">
      <ReactFlowProvider>
        <Sidebar />
        <Flow />
      </ReactFlowProvider>
    </div>
  )
}

export default DynamicFlow

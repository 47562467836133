import './datatable.scss'
import { TableContainer } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'

const DatatableTags = ({ tags }) => {
  const columns = [
    {
      field: 'key',
      headerName: 'Key',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'value',
      headerName: 'Value',
      flex: 1,
      minWidth: 100,
    },
  ]

  return (
    <TableContainer>
      <DataGrid
        getRowId={(row) => row.key}
        rows={tags && tags.length && tags}
        columns={columns}
        hideFooter={true}
        autoHeight={true}
      />
    </TableContainer>
  )
}

export default DatatableTags

import './selectSingle.scss'
import Select from '@mui/material/Select'
import OutlinedInput from '@mui/material/OutlinedInput'
import MenuItem from '@mui/material/MenuItem'
import { useState, useEffect } from 'react'
import { Box, Typography } from '@mui/material'

const SelectSingle = ({ propertie, ruleParameters, setRuleParameters, posibleValues, desc, isEditing, schema }) => {
  const [selected, setSelected] = useState('')
  const [change, setChange] = useState(false)

  useEffect(() => {
    if (ruleParameters[propertie]) {
      setSelected(ruleParameters[propertie])
    }
  }, [propertie, ruleParameters])

  useEffect(() => {
    if (change) {
      let all = { ...ruleParameters }
      all[propertie] = selected

      setRuleParameters({ ...all })
      setChange(false)
    }
  }, [change])

  const handleChange = (event) => {
    const {
      target: { value },
    } = event

    setSelected(value)
    setChange(true)
  }

  return (
    <Box className="select-single-container">
      {desc && <Typography variant="subtitle4">{desc}</Typography>}
      <Select
        fullWidth
        size="small"
        displayEmpty
        disabled={!isEditing}
        value={selected}
        onChange={handleChange}
        input={<OutlinedInput />}
        inputProps={{ 'aria-label': 'Without label' }}
      >
        {posibleValues &&
          posibleValues.map((name) => (
            <MenuItem key={name} value={name}>
              {name}
            </MenuItem>
          ))}
      </Select>
    </Box>
  )
}

export default SelectSingle

import { useState } from 'react'
import { LoadingButton } from '@mui/lab'
import { Box, InputLabel, MenuItem, Select } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { kybRiskLevelEnum } from '../kyb.enums'
import { useUpdateKybMutation } from '../../../services/guenoApi'

const KybEditRiskLevelModal = ({ initialValue, onSubmit }) => {
  const { t } = useTranslation(['common', 'messages'])
  const [_, { isLoading }] = useUpdateKybMutation({ fixedCacheKey: 'kyb-update' })
  const [newRiskLevel, setNewRiskLevel] = useState(initialValue)

  return (
    <>
      <Box className="modal-box">
        <InputLabel>{t('common:riskLevel')}:</InputLabel>
        <Select
          id="risk-level"
          size="small"
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          value={newRiskLevel}
          onChange={(e) => setNewRiskLevel(e.target.value)}
        >
          {kybRiskLevelEnum.map((item) => (
            <MenuItem key={item} value={item}>
              {t(`common:${item}`)}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box className="modal-button">
        <LoadingButton
          size="small"
          color="secondary"
          variant="contained"
          onClick={() => onSubmit(newRiskLevel)}
          loading={isLoading}
        >
          {t('common:save')}
        </LoadingButton>
      </Box>
    </>
  )
}

export default KybEditRiskLevelModal

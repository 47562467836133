import './lowTransactionValues.scss'
import Button from '@mui/material/Button'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { useEffect, useState } from 'react'
import { Box, Fab, InputLabel, MenuItem, OutlinedInput, Select, TextField } from '@mui/material'
import currencies from '../../../json/currencies.json'
import { MenuProps } from '../../../../utilities/MenuProps'
import { useTranslation } from 'react-i18next'

const LowTransactionValues = ({
  propertie,
  ruleParameters,
  setRuleParameters,
  setError,
  errorList,
  setErrorList,
  isRequired,
  isEditing,
}) => {
  const [ltv, setLtv] = useState([])
  const [change, setChange] = useState(false)
  const { t } = useTranslation(['common'])
  const [keyState, setKeyState] = useState('')
  const [minState, setMinState] = useState('')
  const [maxState, setMaxState] = useState('')

  useEffect(() => {
    if (ruleParameters[propertie]) {
      let aux = []
      Object.keys(ruleParameters[propertie]).forEach((element) => {
        let obj = {}
        obj[element] = ruleParameters[propertie][element]
        aux.push(obj)
      })
      setLtv(aux)
    }
  }, [ruleParameters, propertie])

  const handleKeyChange = (value, index, key) => {
    let list = [...ltv]
    let listValue = list[index][key]
    let valueAux = value

    list[index] = {}
    list[index][valueAux] = listValue

    setLtv(list)
    setChange(true)
  }

  const handleValueChange = (valueKey, value, index, key) => {
    let list = [...ltv]
    let aux = { ...list[index][key] }
    aux[valueKey] = value !== '' ? parseInt(value) : ''
    list[index][key] = { ...aux }
    setLtv(list)
    setChange(true)
  }

  const handleServiceRemove = (index) => {
    const list = [...ltv]
    list.splice(index, 1)
    setLtv(list)
    setChange(true)
  }

  const handleServiceAdd = () => {
    let newKey = keyState
    let objAux = {}
    objAux[newKey] = { min: minState, max: maxState }
    setLtv([...ltv, { ...objAux }])
    setChange(true)
    setKeyState('')
    setMinState('')
    setMaxState('')
  }

  useEffect(() => {
    if (ltv.length !== 0 && change) {
      let all = { ...ruleParameters }

      let result = {}
      ltv.forEach((element) => {
        Object.keys(element).forEach((key) => {
          if (element[key].max === '') delete element[key].max
          result[key] = element[key]
        })
      })

      if (ltv.length === 0) delete all[propertie]
      else all[propertie] = result

      setRuleParameters({ ...all })
      setChange(false)
    }
  }, [ltv, change, propertie, ruleParameters, setRuleParameters])

  useEffect(() => {
    let list = []
    if (ltv.length === 0 && isRequired) list.push(`The propertie ${propertie} is required`)
    ltv.forEach((item, index) => {
      let itemError = []
      Object.keys(item).forEach((key) => {
        let aux = ''
        if (key === '') {
          aux = `${propertie} should not have an empty attribute (have one in position ${index + 1}).`
          if (!itemError.includes(aux)) itemError.push(aux)
        }
        if (item[key].min === '') {
          aux = `${propertie}.${key}.min should be integer.`
          if (!itemError.includes(aux)) itemError.push(aux)
        }
      })
      if (itemError.length !== 0) {
        itemError.forEach((element) => {
          if (!list.includes(element)) list.push(element)
        })
      }
    })

    let all = errorList
    if (list.length !== 0) {
      all[propertie] = list
      setErrorList({ ...all })
      setError(true)
    } else {
      delete all[propertie]
      setErrorList({ ...all })
      if (Object.keys(all).length === 0) setError(false)
    }
  }, [ltv])

  return (
    <Box>
      {ltv?.map((singlePair, index) => (
        <Box key={index}>
          {Object.keys(singlePair).map((key, index2) => (
            <Box className="low-row" key={index2}>
              <Box className="low-pair">
                <InputLabel>{t('common:currency')}</InputLabel>
                <Select
                  disabled={!isEditing}
                  className="currency"
                  size="small"
                  displayEmpty
                  value={key}
                  onChange={(e) => handleKeyChange(e.target.value, index, key)}
                  input={<OutlinedInput />}
                  MenuProps={MenuProps}
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  {Object.entries(currencies).map(([key, value]) => (
                    <MenuItem key={key} value={key}>
                      {key} - ({value})
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Box className="low-pair">
                <InputLabel>Max</InputLabel>
                <TextField
                  disabled={!isEditing}
                  variant="outlined"
                  size="small"
                  type="number"
                  value={singlePair[key].max}
                  onChange={(event) => handleValueChange('max', event.target.value, index, key)}
                />
              </Box>
              <Box className="low-pair">
                <Box className="low-label">
                  <InputLabel>Min</InputLabel>
                  <span className="mandatory">* </span>
                </Box>
                <TextField
                  disabled={!isEditing}
                  variant="outlined"
                  size="small"
                  type="number"
                  value={singlePair[key].min}
                  onChange={(event) => handleValueChange('min', event.target.value, index, key)}
                />
              </Box>
              <Box className="low-delete">
                {isEditing && (
                  <Fab variant="close" className="icon-fab" onClick={() => handleServiceRemove(index)}>
                    <DeleteOutlineOutlinedIcon />
                  </Fab>
                )}
              </Box>
            </Box>
          ))}
        </Box>
      ))}
      <Box className="button-right">
        {isEditing && (
          <Button endIcon={<AddCircleOutlineIcon />} variant="outlinedGrey" onClick={handleServiceAdd}>
            {t('common:add')}
          </Button>
        )}
      </Box>
    </Box>
  )
}

export default LowTransactionValues

import { Box, Modal } from '@mui/material'
import ModalTitle from '../../../components/common/Modal/ModalTitle'

const KybModal = ({ children, title, subtitle, open, setOpen }) => {
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div>
        <Box className="modal">
          <ModalTitle setModalOpen={setOpen} title={title} subtitle={subtitle} />
          {children}
        </Box>
      </div>
    </Modal>
  )
}

export default KybModal

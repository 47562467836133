import '../home/home.scss'
import '../onboarding/onboarding.scss'
import './kyb.scss'
import { Box, Checkbox, Chip, CircularProgress, FormControlLabel, Grid, Paper, Tab, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateKybFilters } from '../../features/kyb/kybSlice'
import DatatableKyb from '../../components/common/datatable/DatatableKyb'
import { useNavigate, useParams } from 'react-router-dom'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import DatatableKyc from '../../components/common/datatable/DatatableKyc'
import { updateKycFilters } from '../../features/kyc/kycSlice'
import { useTranslation } from 'react-i18next'
import MyDatePicker from '../../components/utilities/MyDatePicker'
import { kybStatusEnum } from './kyb.enums'
import CountrySelector from '../../components/kyb/countrySelector'
import { selectAvailableCountries, setIsLoading } from '../../features/kyb/kybCountrySlice'
import { FilterModal } from '../../components/utilities/FilterModal'
import KybSearch from './components/KybSearch'
import SettingsIcon from '@mui/icons-material/Settings'
import KybSettingsTabPanel from './components/KybSettingsTabPanel'
import KybExportReport from './components/KybExportReport'
import { useFindUserMasterQuery } from '../../services/guenoApi'

const TabByUrl = {
  AllKybs: 'kyb',
  AllKycs: 'kyc',
  Settings: 'settings',
}

const Kyb = () => {
  const { tab } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation(['common', 'messages'])
  const { emailClient } = useSelector((state) => state.login)
  const kybQueryState = useSelector((state) => state.kyb.filters)
  const kycQueryState = useSelector((state) => state.kyc.filters)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [toDate, setToDate] = useState(null)
  const [filtersOpen, setFiltersOpen] = useState(false)
  const [selectedTab, setSelectedTab] = useState(TabByUrl[tab] || 'kyb')
  const isKybs = selectedTab === 'kyb'
  const [showMyAssignments, setShowMyAssignments] = useState(
    isKybs ? kybQueryState.showMyAssignments : kycQueryState.showMyAssignments,
  )
  const [status, setStatus] = useState(isKybs ? kybQueryState.status : kycQueryState.status)
  const [selectedDate, setSelectedDate] = useState(isKybs ? kybQueryState.fromDate : kycQueryState.fromDate)
  const [searchType, setSearchType] = useState(isKybs ? kybQueryState.searchType : kycQueryState.searchType)
  const [searchText, setSearchText] = useState(isKybs ? kybQueryState.searchText : kycQueryState.searchText)
  const [nationality, setNationality] = useState(kycQueryState.nationality)

  const isDirtySearchText = searchText !== (isKybs ? kybQueryState.searchText : kycQueryState.searchText)

  const { availableCountriesLoading } = useSelector((state) => selectAvailableCountries(state, emailClient))

  const useMasterResponse = useFindUserMasterQuery({ emailClient })

  const { currentCountry } = useSelector((state) => state.kybCountry)

  const handleResetButton = () => {
    if (status) setStatus('ALL')
    if (nationality) setNationality('')
    if (selectedDate) setSelectedDate(null)
    setPage(0)

    const filters = {
      limit: 25,
      offset: 0,
      page: 0,
      status: 'ALL',
      fromDate: null,
      toDate: '',
      searchType,
      searchText,
      showMyAssignments,
    }

    setFiltersOpen(false)

    if (isKybs) {
      dispatch(
        updateKybFilters({
          ...filters,
        }),
      )
    } else {
      dispatch(
        updateKycFilters({
          ...filters,
          nationality: '',
        }),
      )
    }
  }

  const handleDispatch = (extraFilters = {}) => {
    const filters = {
      limit: rowsPerPage,
      offset: rowsPerPage * page,
      page,
      status,
      ...(selectedDate && { fromDate: new Date(selectedDate).toISOString() }),
      toDate: new Date(toDate)?.toISOString(),
      searchType,
      searchText,
      showMyAssignments,
      ...extraFilters,
    }

    // if (selectedDate) {
    //   filters.fromDate = new Date(selectedDate).toISOString()
    // }

    if (isKybs) {
      dispatch(updateKybFilters({ ...filters }))
    } else {
      dispatch(
        updateKycFilters({
          ...filters,
          nationality,
        }),
      )
    }
    dispatch(setIsLoading(false))
  }

  const handleChangeSelectedTab = (_, newValue) => {
    handleResetButton()
    setSelectedTab(newValue)
    if (newValue === 'kyb') navigate(`/kyb/AllKybs`)
    if (newValue === 'kyc') navigate(`/kyb/AllKycs`)
    if (newValue === 'settings') navigate(`/kyb/Settings`)
  }

  useEffect(() => {
    if (currentCountry) {
      handleDispatch()
    }
  }, [rowsPerPage, page, showMyAssignments, currentCountry])

  useEffect(() => {
    const currentSearchType = isKybs ? kybQueryState.searchType : kycQueryState.searchType
    if (searchType !== currentSearchType) {
      setSearchText('')
    }
  }, [isKybs, kybQueryState.searchType, kycQueryState.searchType, searchType])

  useEffect(() => {
    if (selectedDate) {
      const formattedDate = new Date(selectedDate)
      setToDate(selectedDate ? formattedDate.setDate(formattedDate.getDate() + 1) : null)
    }
  }, [selectedDate])

  useEffect(() => {
    setSearchText(isKybs ? kybQueryState.searchText : kycQueryState.searchText)
    setSearchType(isKybs ? kybQueryState.searchType : kycQueryState.searchType)
    setShowMyAssignments(isKybs ? kybQueryState.showMyAssignments : kycQueryState.showMyAssignments)
  }, [tab])

  if (useMasterResponse.isFetching || useMasterResponse.isLoading) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box className={`${availableCountriesLoading && 'spinner-transition'} filter`}>
      <Box className="component-title-wrapper">
        <Box className="component-title">
          <Typography variant="h2">{t(`common:kyb.title.${selectedTab || 'kyb'}`)}</Typography>
          <Box sx={{ display: 'flex', textAlign: 'center', justifyContent: 'center', gap: 2 }}>
            {currentCountry === 'BR' && <KybExportReport />}
            <CountrySelector />
          </Box>
        </Box>
      </Box>
      <TabContext value={selectedTab}>
        <Box display="flex" justifyContent="space-between">
          <TabList
            TabIndicatorProps={{
              style: { display: 'none' },
            }}
            sx={{ marginBottom: '1rem' }}
            onChange={handleChangeSelectedTab}
          >
            <Tab
              label={
                <Chip
                  label={t('common:knowYourBusiness')}
                  variant={selectedTab === 'kyb' ? 'sliderSelected' : 'slider'}
                />
              }
              value="kyb"
            />
            <Tab
              label={
                <Chip
                  label={t('common:knowYourClient')}
                  variant={selectedTab === 'kyc' ? 'sliderSelected' : 'slider'}
                />
              }
              value="kyc"
            />
            <Tab value="settings" />
          </TabList>
          <Button
            variant="outlined"
            onClick={() => handleChangeSelectedTab('', 'settings')}
            endIcon={<SettingsIcon sx={{ fill: 'var(--lime-04)!important' }} />}
          >
            <Typography variant="title2">{t('common:settings')}</Typography>
          </Button>
        </Box>

        {selectedTab !== 'settings' && (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <KybSearch
              viewType={isKybs ? 'kyb' : 'kyc'}
              country={currentCountry}
              handleSearch={handleDispatch}
              searchText={searchText}
              setSearchText={setSearchText}
              searchType={searchType}
              setSearchType={setSearchType}
            />
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    inputProps={{ 'aria-label': 'controlled' }}
                    checked={showMyAssignments}
                    onChange={(e) => setShowMyAssignments(e.target.checked)}
                  />
                }
                label={isKybs ? 'My KYBs' : 'My KYCs'}
              />
            </Box>
          </Box>
        )}

        <Paper elevation={0}>
          <TabPanel value="kyb">
            <DatatableKyb
              setIsLoading={setIsLoading}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              setModalOpen={setFiltersOpen}
              documentNumber={searchText}
              creationOnNoRows={
                !showMyAssignments && searchType === 'documentNumber' && !!searchText && !isDirtySearchText
              }
              hasTabs="27rem"
            />
          </TabPanel>

          <TabPanel value="kyc">
            <DatatableKyc
              isLoading={availableCountriesLoading}
              setIsLoading={setIsLoading}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              setModalOpen={setFiltersOpen}
              documentNumber={searchText}
              creationOnNoRows={
                !showMyAssignments && searchType === 'documentNumber' && !!searchText && !isDirtySearchText
              }
              hasTabs="27rem"
            />
          </TabPanel>

          <TabPanel value="settings">
            <KybSettingsTabPanel currentCountry={currentCountry} />
          </TabPanel>
        </Paper>
      </TabContext>

      <FilterModal
        modalOpen={filtersOpen}
        setModalOpen={setFiltersOpen}
        handleResetButton={handleResetButton}
        setPage={setPage}
        handleDispatch={handleDispatch}
      >
        <Box className="modal-box-filters">
          <Box className="modal-box">
            <Typography variant="subtitle3">
              {t('common:by')} {t('common:status')}:
            </Typography>
            <Grid
              container
              spacing={1}
              sx={{ maxWidth: '28rem', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}
            >
              {kybStatusEnum?.map((item, index) => (
                <Grid item key={index}>
                  <Button onClick={() => setStatus(item)} variant={status === item ? 'action' : 'label'}>
                    {t(`common:${item}`)}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box className="modal-box">
            <Typography variant="subtitle3">
              {t('common:by')} {t('common:date')}:
            </Typography>
            <MyDatePicker selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
          </Box>
        </Box>
      </FilterModal>
    </Box>
  )
}

export default Kyb

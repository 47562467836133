import './listOfObjects.scss'
import { useEffect, useState } from 'react'

import Button from '@mui/material/Button'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { Box, TextField } from '@mui/material'

const ListOfObjects = ({ propertie, ruleParameters, setRuleParameters, defaultListValues, isEditing }) => {
  const [updatedList, setUpdatedList] = useState(defaultListValues)

  const handleValueChange = (value, index, attribute) => {
    let aux = []
    updatedList.forEach((element) => {
      aux.push(element)
    })

    if (attribute === 'month') aux[index].cardExpiry[attribute] = value === '' ? value : parseInt(value)
    else if (attribute === 'years') aux[index].cardExpiry[attribute] = value === '' ? value : parseInt(value)
    else aux[index][attribute] = value

    setUpdatedList(aux)
  }

  const habdleMoovement = (up, index) => {
    let aux = []
    updatedList.forEach((element) => {
      aux.push(element)
    })
    let aux1 = updatedList[index]
    let aux2
    if (up) {
      aux2 = index - 1
    } else {
      aux2 = index + 1
    }
    aux[index] = aux[aux2]
    aux[aux2] = aux1
    setUpdatedList(aux)
  }

  const handleServiceRemove = (index) => {
    const list = [...updatedList]
    list.splice(index, 1)
    setUpdatedList(list)
  }

  const handleServiceAdd = () => {
    setUpdatedList([
      ...updatedList,
      { cardLast4Digits: '', cardFingerprint: '', cardExpiry: { month: '', year: '' }, nameOnCard: '' },
    ])
  }

  useEffect(() => {
    let all = { ...ruleParameters }
    all[propertie] = updatedList
    setRuleParameters({ ...all })
  }, [updatedList])

  return (
    <Box className="childContainer">
      {updatedList.length !== 0 &&
        updatedList.map((item, index) => (
          <Box className="div" key={index}>
            <Box className="pairRisk">
              <Box className="inputs">
                <Box className="pairInput">
                  <p className="Label">cardLast4Digits</p>
                  <TextField
                    disabled={!isEditing}
                    variant="outlined"
                    className="InputRisk"
                    type="text"
                    value={item.cardLast4Digits}
                    onChange={(event) => handleValueChange(event.target.value, index, 'cardLast4Digits')}
                  />
                </Box>
                <Box className="pairInput">
                  <p className="Label">cardFingerprint</p>
                  <TextField
                    className="InputRisk"
                    disabled={!isEditing}
                    variant="outlined"
                    type="text"
                    value={item.cardFingerprint}
                    onChange={(event) => handleValueChange(event.target.value, index, 'cardFingerprint')}
                  />
                </Box>
                <Box className="pairInput">
                  <p className="Label">cardExpiry</p>
                  <Box className="cardExp">
                    <TextField
                      disabled={!isEditing}
                      variant="outlined"
                      className="cardExpItem"
                      type="number"
                      value={item.cardExpiry.month}
                      onChange={(event) => handleValueChange(event.target.value, index, 'month')}
                    />
                    <span>/</span>
                    <TextField
                      disabled={!isEditing}
                      variant="outlined"
                      className="cardExpItem"
                      type="number"
                      value={item.cardExpiry.years}
                      onChange={(event) => handleValueChange(event.target.value, index, 'years')}
                    />
                  </Box>
                </Box>
                <Box className="pairInput">
                  <p className="Label">nameOnCard</p>
                  <TextField
                    disabled={!isEditing}
                    variant="outlined"
                    className="InputRisk"
                    type="text"
                    value={item.nameOnCard}
                    onChange={(event) => handleValueChange(event.target.value, index, 'nameOnCard')}
                  />
                </Box>
              </Box>
              {isEditing && (
                <Box className="Buttons">
                  {index === 0 && updatedList.length > 1 && (
                    <Button variant="outlined" className="ButtonRisk" disabled>
                      <ArrowUpwardIcon />
                    </Button>
                  )}
                  {index !== 0 && updatedList.length > 1 && (
                    <Button variant="outlined" className="ButtonRisk" onClick={() => habdleMoovement(true, index)}>
                      <ArrowUpwardIcon />
                    </Button>
                  )}
                  {index !== updatedList.length - 1 && updatedList.length > 1 && (
                    <Button variant="outlined" className="ButtonRisk" onClick={() => habdleMoovement(false, index)}>
                      <ArrowDownwardIcon />
                    </Button>
                  )}
                  {index === updatedList.length - 1 && updatedList.length > 1 && (
                    <Button variant="outlined" className="ButtonRisk" disabled>
                      <ArrowDownwardIcon />
                    </Button>
                  )}
                  <Button
                    variant="outlined"
                    color="error"
                    className="ButtonRisk"
                    onClick={() => handleServiceRemove(index)}
                  >
                    <DeleteOutlineIcon />
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        ))}
      <Box className="lastRowObjects">
        {isEditing && (
          <Button variant="outlined" onClick={handleServiceAdd}>
            <p>ADD</p>
            <AddCircleOutlineIcon />
          </Button>
        )}
      </Box>
    </Box>
  )
}

export default ListOfObjects

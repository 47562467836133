import './selectSingle.scss'
import Select from '@mui/material/Select'
import OutlinedInput from '@mui/material/OutlinedInput'
import MenuItem from '@mui/material/MenuItem'
import { useState, useEffect } from 'react'
import { Box, Typography } from '@mui/material'

const SelectSingle = ({
  propertie,
  ruleParameters,
  setRuleParameters,
  posibleValues,
  desc,
  isEditing,
  schema,
  filterTemplate,
}) => {
  const [selected, setSelected] = useState(schema?.default ?? '')
  const [change, setChange] = useState(false)

  const getIndex = () => {
    if (Array.isArray(ruleParameters) && ruleParameters.length === 0) return -1
    if (ruleParameters) return ruleParameters.map((e) => e.filterTemplate).indexOf(filterTemplate)
    return -1
  }

  useEffect(() => {
    const aux = ruleParameters?.find((e) => e.filterTemplate === filterTemplate)

    if (aux) {
      setSelected(aux[propertie])
    }
  }, [propertie])

  const handleChange = (event) => {
    const {
      target: { value },
    } = event

    setSelected(value)
    setChange(true)
  }

  useEffect(() => {
    const index = getIndex()

    if (change) {
      let all = [...ruleParameters]
      let previous = index >= 0 && all[index] ? { ...all[index] } : {}

      previous[propertie] = selected
      previous.filterTemplate = filterTemplate

      if (index !== -1) {
        all.splice(index, 1)
      }
      all.push(previous)

      setRuleParameters([...all])
    }
    setChange(false)
  }, [selected])

  return (
    <Box className="select-single-container">
      {desc && <Typography variant="subtitle4">{desc}</Typography>}
      <Select
        fullWidth
        className="single-input"
        size="small"
        displayEmpty
        disabled={!isEditing}
        value={selected}
        onChange={handleChange}
        input={<OutlinedInput />}
        inputProps={{ 'aria-label': 'Without label' }}
      >
        {posibleValues &&
          posibleValues.map((name) => (
            <MenuItem key={name} value={name}>
              {name}
            </MenuItem>
          ))}
      </Select>
    </Box>
  )
}

export default SelectSingle

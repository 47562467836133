export const myRulesDataMapperForPDF = (data) => {
  const header = ['Rule Id', 'Rule Name', 'Rule Description', 'Status', 'Action', 'Hit Rate']
  const parsedData = data.map((rule) => {
    return [rule.idConcat, rule.ruleNameAlias, rule.description, rule.status, rule.action, rule.hitRate]
  })

  return {
    columns: header,
    body: parsedData,
  }
}

export const myRulesDataMapper = (data) => {
  return data.map((rule) => {
    return {
      RuleId: rule.idConcat,
      RuleName: rule.ruleNameAlias,
      RuleDescription: rule.description,
      Status: rule.status,
      Action: rule.action,
      HitRate: rule.hitRate,
    }
  })
}

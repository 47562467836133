import { createSlice } from '@reduxjs/toolkit';

const fromDate = new Date();
fromDate.setMonth(fromDate.getMonth() - 1);

const initialState = {
  data: [],
  pagination: {},
  filters: {
    limit: 50,
    page: 0,
    offset: 0,
    transactionType: '',
    transactionState: '',
    fromDate: fromDate.toISOString(),
    toDate: new Date().toISOString(),
    mode: 'cashout',
    transactions: 0,
    paymentMethod: '',
    paymentMethodId: '',
  },
  sorting: {
    sortTransactions: -1,
  },
}

const externalPaymentMethodsSlice = createSlice({
  name: 'externalPaymentMethods',
  initialState,
  reducers: {
    setExternalPaymentMethodsData: (state, action) => {
      state.data = action.payload.data
      state.pagination = action.payload.pagination
    },
    updateExternalPaymentMethodsFilters: (state, action) => {
      state.filters = { ...state.filters, ...action.payload }
    },
    updateSortTransactions: (state, action) => {
      state.sorting.sortTransactions = action.payload
    },
    resetExternalPaymentMethodsFilters: (state) => {
      state.filters = initialState.filters
      state.sorting.sortTransactions = -1
    },
  },
})

export const {
  setExternalPaymentMethodsData,
  updateExternalPaymentMethodsFilters,
  updateSortTransactions,
  resetExternalPaymentMethodsFilters,
  clearExternalPaymentMethodsData } = externalPaymentMethodsSlice.actions;

export default externalPaymentMethodsSlice.reducer;
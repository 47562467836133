import './tags.scss'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import Button from '@mui/material/Button'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { useEffect, useState } from 'react'
import { Box, TextField, Chip, Autocomplete, Fab, InputLabel } from '@mui/material'
import { useTranslation } from 'react-i18next'

const Tags = ({
  propertie,
  ruleParameters,
  setRuleParameters,
  setError,
  errorList,
  setErrorList,
  isRequired,
  isEditing,
  description,
}) => {
  const [tat, setTat] = useState({})
  const [change, setChange] = useState(false)
  const [keyState, setKeyState] = useState('')
  const [valueState, setValueState] = useState([])
  const { t } = useTranslation(['common'])

  useEffect(() => {
    if (ruleParameters[propertie]) {
      let aux = {}
      Object.keys(ruleParameters[propertie]).forEach((element) => {
        aux[element] = ruleParameters[propertie][element]
      })
      setTat(aux)
    }
  }, [ruleParameters, propertie])

  const handleKeyChange = (value, key) => {
    const aux = { ...tat }
    const aux2 = aux[key]

    delete aux[key]
    aux[value] = aux2
    setTat({ ...aux })
    setChange(true)
  }

  const handleValueChange = (value, key) => {
    const aux = { ...tat }
    aux[key] = value

    setTat({ ...aux })
    setChange(true)
  }

  const handleServiceRemove = (key) => {
    const aux = { ...tat }
    delete aux[key]
    if (Object.keys(aux) > 0) setTat({ ...aux })
    else setTat({})
    setChange(true)
  }

  const handleServiceAdd = () => {
    let objAux = { ...tat }
    objAux[keyState] = [...valueState]
    setTat({ ...objAux })
    setKeyState('')
    setValueState('')
    setChange(true)
  }

  useEffect(() => {
    if (Object.keys(tat).length !== 0 && change) {
      let all = { ...ruleParameters }

      if (Object.keys(tat).length === 0) delete all[propertie]
      else all[propertie] = tat
      setRuleParameters({ ...all })
      setChange(false)
    }
  }, [tat, change])

  useEffect(() => {
    let list = []
    if (Object.keys(tat).length === 0 && isRequired) list.push(`The propertie ${propertie} is required`)

    Object.keys(tat).forEach((item) => {
      let aux = ''
      if (item === '') {
        aux = `${propertie} should not have an empty attribute.`
      }

      if (tat[item] === '') {
        aux = `${propertie}.${item} should not be empty.`
      }

      if (aux !== '' && !list.includes(aux)) {
        list.push(aux)
      }
    })

    let all = errorList
    if (list.length !== 0) {
      all[propertie] = list
      setErrorList({ ...all })
      setError(true)
    } else {
      delete all[propertie]
      setErrorList({ ...all })
      if (Object.keys(all).length === 0) setError(false)
    }
  }, [tat])

  return (
    <Box>
      {description && <span>{description}</span>}
      <Box className="tag-row">
        <Box className="tag-pair">
          <InputLabel>{t('common:key')}</InputLabel>
          <TextField
            disabled={!isEditing}
            variant="outlined"
            size="small"
            placeholder="New Key"
            value={keyState}
            onChange={(event) => setKeyState(event.target.value)}
          />
        </Box>
        <Box className="tag-pair">
          <InputLabel>{t('common:values')}</InputLabel>
          <Autocomplete
            disabled={!isEditing}
            value={valueState}
            onChange={(event, newValue) => {
              setValueState(newValue)
            }}
            multiple
            id="tags-filled"
            className="tags-chips"
            options={[]}
            freeSolo
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip variant="filled" color="primary" label={option} {...getTagProps({ index })} />
              ))
            }
            renderInput={(params) => <TextField {...params} variant="outlined" placeholder={t('common:values')} />}
          />
        </Box>
      </Box>
      <Box className="button-right">
        {isEditing && (
          <Button color="primary" disabled={keyState === '' || valueState === ''} onClick={handleServiceAdd}>
            <p>ADD</p>
            <AddCircleOutlineIcon />
          </Button>
        )}
      </Box>
      {tat &&
        Object.keys(tat).map((tatKey, index) => (
          <Box key={index}>
            <Box className="tag-row">
              <Box className="tag-pair">
                <InputLabel>{t('common:key')}</InputLabel>
                <TextField
                  className="Input"
                  disabled={!isEditing}
                  variant="outlined"
                  size="small"
                  placeholder="New Key"
                  value={tatKey}
                  onChange={(event) => handleKeyChange(event.target.value, tatKey)}
                />
              </Box>
              <Box className="tag-pair">
                <InputLabel>{t('common:value')}</InputLabel>
                <Autocomplete
                  value={tat[tatKey]}
                  onChange={(event, newValue) => {
                    handleValueChange(newValue, tatKey)
                  }}
                  multiple
                  id="tags-filled"
                  options={[]}
                  freeSolo
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip variant="filled" color="primary" label={option} {...getTagProps({ index })} />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" placeholder={t('common:values')} />
                  )}
                />
              </Box>
              <Box className="button-right">
                {isEditing && (
                  <Fab variant="close" className="icon-fab" onClick={() => handleServiceRemove(tatKey)}>
                    <DeleteOutlineOutlinedIcon />
                  </Fab>
                )}
              </Box>
            </Box>
          </Box>
        ))}
    </Box>
  )
}

export default Tags

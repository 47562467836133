/* eslint-disable no-fallthrough */
import './govCheck.scss'
import '../home/home.scss'

import { Box, Button, Select, MenuItem, Typography, Paper, InputLabel } from '@mui/material'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { camelCaseFormatter } from '../../components/utilities/formatters/camelCase'
import { useTranslation } from 'react-i18next'
import GetInputsByCheckType from './GovCheckManualCheckFieldRenderer'
import DatatableGovCheck from '../../components/common/datatable/DatatableGovCheck'
import { Oval } from 'react-loader-spinner'

const GovCheckManualCheck = () => {
  const [checkTypes] = useState([
    'argentinaValidationLight',
    'brazilValidation',
    'brazilValidationRelativeName',
    'rutChile',
    'colombiaValidation',
    'mexicoCurp',
    'mexicoINE',
    'mexicoPersona',
    'mexicoRfc',
    'peruValidationDNI',
    'peruValidationDNIFicha',
    'peruValidationRUC',
  ])
  const { accessToken } = useSelector((state) => state.login)
  const [isFetching, setIsFetching] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [checkType, setCheckType] = useState('')
  const [finish, setFinish] = useState(false)
  const [result, setResult] = useState({})
  const [documento, setDocumento] = useState('')
  const [cpfcnpj, setCpfcnpj] = useState('')
  const [rut, setRut] = useState('')
  const [ndocumento, setNDocumento] = useState('')
  const [documentNumber, setDocumentNumber] = useState('')
  const [expeditionDate, setExpeditionDate] = useState('')
  const [curp, setCurp] = useState('')
  const [instituto, setInstituto] = useState('')
  const [identificadorCiudadano, setIdentificadorCiudadano] = useState('')
  const [cic, setCic] = useState('')
  const [ocr, setOcr] = useState('')
  const [claveElector, setClaveElector] = useState('')
  const [numeroEmision, setNumeroEmision] = useState('')
  const [birthDate, setBirthDate] = useState('')
  const [entityBirth, setEntityBirth] = useState('')
  const [names, setNames] = useState('')
  const [paternalSurname, setPaternalSurname] = useState('')
  const [sex, setSex] = useState('')
  const [mothers_maiden_name, setMothersMaidenName] = useState('')
  const [rfc, setRfc] = useState('')
  const [dni, setDni] = useState('')
  const [ruc, setRuc] = useState('')
  const { t } = useTranslation(['common', 'messages'])

  const canSend = () => {
    switch (checkType) {
      case '':
        return false
      // case 'argentinaValidation':
      //   return documento ? true : false
      case 'argentinaValidationLight':
        return documento ? true : false
      case 'brazilValidation':
        return cpfcnpj ? true : false
      case 'brazilValidationRelativeName':
        return cpfcnpj ? true : false
      case 'rutChile':
        return rut && ndocumento ? true : false
      case 'colombiaValidation':
        return documentNumber && expeditionDate ? true : false
      case 'mexicoCurp':
        return curp ? true : false
      case 'mexicoINE':
        return instituto && identificadorCiudadano && cic ? true : false
      case 'mexicoPersona':
        return birthDate && entityBirth && names && paternalSurname && sex && mothers_maiden_name ? true : false
      case 'mexicoRfc':
        return rfc ? true : false
      case 'peruValidationDNI':
        return dni ? true : false
      case 'peruValidationDNIFicha':
        return dni ? true : false
      case 'peruValidationRUC':
        return ruc ? true : false
      default:
        return false
    }
  }

  const handleSubmit = () => {
    setIsFetching(true)
    setFinish(false)
    setErrorMessage('')

    let url = `${process.env.REACT_APP_BASEURL}/govCheck/${checkType}`
    let body = {}
    let method = 'GET'

    switch (checkType) {
      // case 'argentinaValidation':
      //   url += `/2/${documento}`
      //   break
      case 'argentinaValidationLight':
        url += `/${documento}`
        break
      case 'brazilValidation':
        url += `?cpfcnpj=${cpfcnpj}`
        break
      case 'brazilValidationRelativeName':
        url += `?cpfcnpj=${cpfcnpj}`
        break
      case 'rutChile':
        url += `?rut=${rut}&ndocumento=${ndocumento}`
        break
      case 'colombiaValidation':
        body = { documentNumber, expeditionDate }
        method = 'POST'
        break
      case 'mexicoCurp':
        body = { curp }
        method = 'POST'
        break
      case 'mexicoINE':
        body = { instituto, identificadorCiudadano, cic, ocr, claveElector, numeroEmision }
        method = 'POST'
        break
      case 'mexicoPersona':
        body = { birthDate, entityBirth, names, paternalSurname, sex, mothers_maiden_name }
        method = 'POST'
        break
      case 'mexicoRfc':
        body = { rfc }
        method = 'POST'
        break
      case 'peruValidationDNI':
        body = { dni }
        method = 'POST'
        break
      case 'peruValidationDNIFicha':
        body = { dni }
        method = 'POST'
        break
      case 'peruValidationRUC':
        body = { ruc }
        method = 'POST'
        break
      default:
        break
    }

    let options = {
      method,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }

    if (method !== 'GET') {
      options = {
        method,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      }
    }

    if (!process.env.REACT_APP_IS_DEMO) {
      fetch(url, options)
        .then((res) => res.json())
        .then((res) => {
          setIsFetching(false)
          if (res.success && res.data) {
            setResult(res.data)
            setFinish(true)
          } else {
            throw new Error(
              res.data?.responseBody?.message
                ? res.data?.responseBody?.message
                : res.data?.info
                ? res.data?.info
                : res.message
                ? res.message
                : 'Unexpected',
            )
          }
        })
        .catch((err) => {
          console.error('[DOWNLOAD REPORT ERROR] --> ', err)
          setIsFetching(false)
          setErrorMessage(err.message ? err.message : t('messages:unexpectedErrorValues'))
        })
    } else {
      fetch('../data/govCheckDetail.json', options)
        .then((res) => res.json())
        .then((res) => {
          setResult({ ...res.data, checkId: res?.data?._id })
          setFinish(true)
          setIsFetching(false)
        })
        .catch((err) => {
          console.error('[DOWNLOAD REPORT ERROR] --> ', err)
          setIsFetching(false)
          setErrorMessage(err.message ? err.message : t('messages:unexpectedErrorValues'))
        })
    }
  }

  const renderData = (data) => {
    if (Array.isArray(data)) {
      return (
        <ul style={{ paddingInlineStart: '0px' }}>
          {data.map((item, idx) => {
            return <Box key={idx}>{renderData(item)}</Box>
          })}
        </ul>
      )
    } else if (typeof data === 'object' && data !== null) {
      return (
        <ul>
          {Object.entries(data).map(([key, value], idx) => {
            return (
              <li key={idx} style={{ listStyle: 'circle' }}>
                <strong>{key}:</strong> {renderData(value)}
              </li>
            )
          })}
        </ul>
      )
    } else {
      return <span>{data?.toString()}</span>
    }
  }

  const handleReset = () => {
    emptyValues()
    setCheckType('')

    setFinish(false)
  }

  const emptyValues = () => {
    setDocumento('')
    setCpfcnpj('')
    setRut('')
    setNDocumento('')
    setDocumentNumber('')
    setExpeditionDate('')
    setCurp('')
    setInstituto('')
    setIdentificadorCiudadano('')
    setCic('')
    setOcr('')
    setClaveElector('')
    setNumeroEmision('')
    setBirthDate('')
    setEntityBirth('')
    setNames('')
    setPaternalSurname('')
    setSex('')
    setMothersMaidenName('')
    setRfc('')
    setDni('')
    setRuc('')

    setErrorMessage('')
  }
  return (
    <Box>
      <Box className="component-title-wrapper">
        <Box className="component-title">
          <Typography variant="h2">{t('common:govcheckManual')}</Typography>
        </Box>
        <Typography variant="subtitle3">
          {/* Loren ipsum dolor sit amet Loren ipsum dolor sit amet Loren  */}
        </Typography>
      </Box>
      <Box className="wrapper">
        <Paper elevation={0}>
          <Box className="govcheck-manual-divider">
            <Box className="govcheck-manual-input">
              <Box className="required">
                <InputLabel>{t('common:checkType')}</InputLabel>
                <Typography variant="subtitle3">({t('common:required')})</Typography>
              </Box>
              {checkTypes.length > 0 && (
                <Select
                  value={checkType}
                  onChange={(event) => setCheckType(event.target.value)}
                  displayEmpty
                  fullWidth
                  placeholder={t('common:selectACheckType')}
                >
                  <MenuItem disabled value="">
                    <Typography variant="subtitle3">{`${t('common:select')} ${t('common:checkType')}`}</Typography>
                  </MenuItem>
                  {checkTypes.map((type, index) => (
                    <MenuItem key={index} value={type}>
                      {camelCaseFormatter(type)}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </Box>
            {checkType !== '' && (
              <GetInputsByCheckType
                checkType={checkType}
                values={{
                  documento,
                  cpfcnpj,
                  rut,
                  ndocumento,
                  documentNumber,
                  expeditionDate,
                  curp,
                  instituto,
                  identificadorCiudadano,
                  cic,
                  ocr,
                  claveElector,
                  numeroEmision,
                  birthDate,
                  entityBirth,
                  names,
                  paternalSurname,
                  sex,
                  mothers_maiden_name,
                  rfc,
                  dni,
                  ruc,
                }}
                setters={{
                  setDocumento,
                  setCpfcnpj,
                  setRut,
                  setNDocumento,
                  setDocumentNumber,
                  setExpeditionDate,
                  setCurp,
                  setInstituto,
                  setIdentificadorCiudadano,
                  setCic,
                  setOcr,
                  setClaveElector,
                  setNumeroEmision,
                  setBirthDate,
                  setEntityBirth,
                  setNames,
                  setPaternalSurname,
                  setSex,
                  setMothersMaidenName,
                  setRfc,
                  setDni,
                  setRuc,
                }}
              />
            )}
          </Box>

          <Box className="modal-filter-buttons">
            <Button
              onClick={() => {
                handleReset()
              }}
              size="small"
              disabled={!checkType}
              variant="outlinedGrey"
            >
              {t('common:reset')}
            </Button>
            <Button
              size="small"
              variant="contained"
              type="submit"
              disabled={!canSend()}
              onClick={(e) => handleSubmit()}
            >
              {t('common:apply')}
            </Button>
          </Box>
        </Paper>
        {isFetching && (
          <Box className="manual-processing">
            <span>{t('messages:processing')}</span>
            <Oval
              height={50}
              width={50}
              color="#4fa94d"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#4fa94d"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </Box>
        )}
        {errorMessage !== '' && (
          <Box className="security-error">
            <Button size="small" variant="outlined" color="error">
              {errorMessage}
            </Button>
          </Box>
        )}
        {Object.values(result).length > 0 && (
          <Paper elevation={0}>
            <Box className="check-title">
              <Typography variant="title">{t('common:result')}</Typography>
              <DatatableGovCheck isLoading={isFetching} setIsLoading={setIsFetching} optionalForcedResult={result} />
            </Box>
          </Paper>
        )}
      </Box>
    </Box>
  )
}

export default GovCheckManualCheck

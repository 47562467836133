import { useEffect, useState } from 'react'
import { Box, Input, InputLabel } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useTranslation } from 'react-i18next'
import { useUpdateKybMutation } from '../../../services/guenoApi'

const KybEditFieldModal = ({ initialValue, label, onSubmit }) => {
  const { t } = useTranslation(['common', 'messages'])
  const [_, { isLoading }] = useUpdateKybMutation({ fixedCacheKey: 'kyb-update' })

  const [textValue, setTextValue] = useState('')
  const [change, setChange] = useState(false)

  const onChange = (newValue) => {
    setTextValue(newValue)
    if (newValue !== initialValue) {
      setChange(true)
    } else {
      setChange(false)
    }
  }

  useEffect(() => {
    setTextValue(initialValue)
    setChange(false)
  }, [initialValue])

  return (
    <>
      <Box className="modal-box">
        <InputLabel>{t(`common:${label}`)}:</InputLabel>
        <Input value={textValue} onChange={(event) => onChange(event.target.value)} />
      </Box>
      <Box className="modal-button">
        <LoadingButton
          size="small"
          color="secondary"
          variant="contained"
          disabled={!change}
          onClick={() => onSubmit(label, textValue)}
          loading={isLoading}
        >
          {t('common:save')}
        </LoadingButton>
      </Box>
    </>
  )
}

export default KybEditFieldModal

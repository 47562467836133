import './datatable.scss'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Switch, Link, Typography } from '@mui/material'
import { buildTimeStringFromTimestamp } from '../time/timeHelper'
import { TableChip } from '../../utilities/TableChip'
import { useAml } from '../../../pages/pages-hooks/useAml'
import AlertFetchSpinner from '../alertFetchSpinner/alertFetchSpinner'
import { useState } from 'react'
import { updateAml } from '../../../features/aml/amlSlice'
import { useTranslation } from 'react-i18next'
import { DataGridComponent } from '../datagrid/DataGridComponent'

const DatatableAml = ({ page, setPage, rowsPerPage, setRowsPerPage, setIsLoading, setModalOpen }) => {
  const { emailClient, activeServices, accessToken } = useSelector((state) => state.login)
  const aml = useAml(emailClient, setIsLoading)
  const queryState = useSelector((state) => state.aml.filters)
  const dispatch = useDispatch()
  const [fetchError, setFetchError] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [fetchMessage, setFetchMessage] = useState('')
  const { t } = useTranslation(['common'])

  const handleFetch = (error, message) => {
    setIsFetching(true)
    setFetchError(error)
    setFetchMessage(message)
    setTimeout(() => {
      setIsFetching(false)
    }, 3000)
  }

  const handleUpdate = (e, row) => {
    e.preventDefault()
    let data

    if (row) {
      data = {
        is_monitored: row.is_monitored === true ? false : true,
      }
    }

    if (!process.env.REACT_APP_IS_DEMO) {
      const url = `${process.env.REACT_APP_BASEURL}/aml/updatemonitors/${row.search_id}`
      const options = {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(data),
      }

      fetch(url, options)
        .then((res) => res.json())
        .then((res) => {
          if (!res.success) throw Error(typeof res.data === 'string' ? res.data : res.message)
          handleFetch(false, 'Update monitors success')
          updateAmlList()
        })
        .catch((error) => {
          console.error('[UPDATE MONITORS ERROR] --> ', error)
          handleFetch(true, error.message ? error.message : 'Update monitors error')
        })
    } else {
      handleFetch(false, 'Update monitors success')
      updateAmlList()
    }
  }

  const updateAmlList = () => {
    const { limit, offset, fromDate, toDate, page, id, search_term } = queryState
    const baseUrl = `${process.env.REACT_APP_BASEURL}/aml/getAllChecks/${emailClient}`

    function buildUrl(baseUrl) {
      let url = `${baseUrl}?limit=${limit}&page=${page}&offset=${offset}`
      if (fromDate && toDate) url += `&fromDate=${fromDate}&toDate=${toDate}`
      if (id?.length) url += `&id=${id}`
      if (search_term?.length) url += `&search_term=${search_term}`

      return url
    }

    let url = buildUrl(baseUrl)

    fetch(process.env.REACT_APP_IS_DEMO ? '../data/aml.json' : url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res) {
          const { data, pagination } = res
          const aux = { data, pagination }
          dispatch(updateAml(aux))
        }
      })
      .catch((error) => console.error('[UPDATE: aml] --> ', error))
  }

  const columns = [
    {
      field: '_id',
      headerName: t('common:referenceId'),
      renderCell: (params) => {
        return (
          <Link href={`/aml/${params.row._id}`}>
            <Typography variant="number">{params.row._id}</Typography>
          </Link>
        )
      },
    },
    {
      field: 'search_id',
      headerName: t('common:searchId'),
      renderCell: (params) => {
        return <Typography variant="number">{params.row.search_id ? params.row.search_id : '---'}</Typography>
      },
    },
    {
      field: 'search_term',
      headerName: t('common:searchTerm'),
    },
    {
      field: 'match_status',
      headerName: t('common:matchStatus'),
      renderCell: (params) => {
        return (
          <TableChip
            noIcon={true}
            action={
              params.row.match_status === 'potential_match'
                ? 'potential_match'
                : params.row.match_status === 'no_match'
                ? 'no_match'
                : 'clear'
            }
          />
        )
      },
    },
    {
      field: 'total_hits',
      headerName: t('common:totalHits'),
    },
    {
      field: 'total_matches',
      headerName: t('common:totalMatches'),
    },
    {
      field: 'total_blacklist_hits',
      headerName: t('common:totalBlacklistHits'),
    },
    {
      field: 'createdAt',
      headerName: t('common:searchDate'),
      renderCell: (params) => {
        return <Typography variant="number">{buildTimeStringFromTimestamp(params.row.createdAt)}</Typography>
      },
    },
    {
      field: 'share_url',
      headerName: t('common:source'),
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <Link onClick={() => window.open(params.row.share_url, '_blank', 'rel=noopener noreferrer')}>
            {t('common:viewDetails')}
          </Link>
        )
      },
    },
    {
      field: 'action',
      headerName: t('common:monitored'),
      sortable: false,
      filterable: false,
      hide: !activeServices || !activeServices.includes('amlupdatemonitors:id'),
      renderCell: (params) => {
        return (
          <Box className="table-body-cell">
            <Switch
              disabled={isFetching || !params.row.search_id}
              key={params.row.id}
              checked={params.row.is_monitored}
              onChange={(e) => {
                handleUpdate(e, params.row)
              }}
            />
          </Box>
        )
      },
    },
  ]

  return (
    <Box>
      {isFetching && <AlertFetchSpinner message={fetchMessage} error={fetchError} />}
      <DataGridComponent
        rows={(Array.isArray(aml?.data) && aml?.data?.length && aml?.data) || []}
        columns={columns}
        count={aml?.pagination?.totalElements || 0}
        page={page}
        rowsPerPage={rowsPerPage}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        setIsLoading={setIsLoading}
        setModalOpen={setModalOpen}
      />
    </Box>
  )
}

export default DatatableAml

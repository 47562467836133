import { Box, Link, TableContainer, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { TableChip } from '../../../utilities/TableChip'
import { DataGrid } from '@mui/x-data-grid'
import { useEffect, useState } from 'react'
import { RulesDrawer } from '../rulesLibrary/RulesDrawer'
import { getDescription } from '../../../utilities/formatters/getDescription'

const ArchivedRules = ({ rules, isLoading, setIsLoading, instanceRules, setInstanceRules }) => {
  const { t } = useTranslation(['common'])
  const [selectedRule, setSelectedRule] = useState({})
  const [ruleToEdit, setRuleToEdit] = useState({})
  const [open, setOpen] = useState(false)

  const handleNext = (rule) => {
    const selected = instanceRules.filter((element) => {
      return element.id === rule
    })

    setSelectedRule(selected[0])
    setOpen(true)
  }

  useEffect(() => {
    if (selectedRule && Object.keys(selectedRule).length !== 0 && rules.length !== 0) {
      const schemaSelected = rules.filter((element) => {
        return element.id === selectedRule?.ruleId
      })
      setRuleToEdit(schemaSelected[0])
    }
  }, [selectedRule, rules])

  const columns = [
    {
      field: 'ruleId',
      headerName: t('common:ruleId'),
      flex: 1,
      minWidth: 250,
      renderCell: (params) => {
        return `${params.row.ruleId} (${params.row.id})`
      },
    },
    {
      field: 'ruleName',
      headerName: t('common:ruleName'),
      flex: 1,
      minWidth: 350,
      renderCell: (params) => {
        return `${params.row.ruleNameAlias || params.row.name}`
      },
    },
    {
      field: 'ruleDescription',
      headerName: t('common:ruleDescription'),
      flex: 1,
      minWidth: 450,
      renderCell: (params) => {
        return <Typography variant="subtitle3">{getDescription(params.row, rules)}</Typography>
      },
    },
    {
      field: 'action',
      headerName: t('common:action'),
      flex: 1,
      minWidth: 120,
      renderCell: (params) => {
        return <TableChip action={params.row.action} noIcon={true} />
      },
      valueGetter: (params) => {
        return params.row.action
      },
    },
    {
      field: 'hitRate',
      headerName: t('common:hitRate'),
      flex: 1,
      minWidth: 80,
      renderCell: (params) => {
        return (
          <Typography variant="subtitle1">
            {(params.row.runCount === 0 ? 0 : (params.row.hitCount * 100) / params.row.runCount).toFixed() + '%'}
          </Typography>
        )
      },
      valueGetter: (params) => {
        return (params.row.runCount === 0 ? 0 : (params.row.hitCount * 100) / params.row.runCount).toFixed()
      },
    },
    {
      field: 'details',
      headerName: t('common:details'),
      flex: 1,
      minWidth: 100,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return <Link onClick={() => handleNext(params.row.id)}>{t('common:seeDetails')}</Link>
      },
    },
  ]

  return (
    <Box>
      <Box>
        <TableContainer>
          <DataGrid
            getRowId={(row) => row.id}
            getRowHeight={() => 'auto'}
            rows={(Array.isArray(instanceRules) && instanceRules?.length > 0 && instanceRules) || []}
            columns={columns}
            hideFooter={true}
            autoHeight={true}
          />
        </TableContainer>
      </Box>
      {instanceRules.length !== 0 && (
        <RulesDrawer
          setIsLoading={setIsLoading}
          isLoading={isLoading}
          selectedRule={selectedRule}
          setSelectedRule={setSelectedRule}
          setOpen={setOpen}
          open={open}
          myRule={true}
          ruleToEdit={ruleToEdit}
          ruleList={instanceRules}
          setInstanceRules={setInstanceRules}
        />
      )}
    </Box>
  )
}

export default ArchivedRules

import { useParams } from 'react-router-dom'

/**
 * getCountrySegment toma un pais y devuelve la convencion de ruteos para kyb
 * @param {'BR' | 'CO'} country
 * @returns {string}
 */
export const getCountrySegment = (country) => {
  if (!country) return '/'
  return country.toUpperCase() === 'BR' ? '/' : `/v2/${country}/`
}

/**
 * Adds additional parameters to the data object based on the type.
 * @param {string} type - The type of check.
 * @param {Object} data - The data object to which additional parameters will be added.
 * @returns {Object} The modified data object with additional parameters.
 */
export const addAdditionalParams = (type, data) => {
  const cedula = {
    document_type: 'cedula',
  }

  const mappedData = {
    'policia-co': cedula,
    'cadaveres-co': cedula,
    'procuraduria-co': cedula,
    'desaparecidos-co': cedula,
    'rnmc-co': cedula,
    'rues-co': {},
    'judicatura-co': {},
    'bdme-co': {},
    'procesos-judiciales-co': {
      ...cedula,
    },
    'simit-co': {
      ...cedula,
    },
    'registraduria-co': {},
    'adres-co': {
      ...cedula,
    },
    'contraloria-co': {
      ...cedula,
    },
    'inpec-co': {},
    'fiscalia-co': {},
    'inhabilidades-co': {
      document_type: 'cedula',
    },
    'identity-business-co': {},
    'secop-co': {},
    'experian-hcpn-co': {
      ...cedula,
    },
  }

  const mappedKey = Object.keys(mappedData).find((key) => key === type)

  if (typeof mappedKey === 'undefined') console.error('Unknown check type:', type)

  const dataToSend = {
    ...data,
    ...mappedData[mappedKey],
  }

  return dataToSend
}

/**
 * Separates the company society type from the rest of the company name.
 *
 * @param {string} str - The full company name including the society type.
 * @returns {Object} - An object containing the modified company name and the society type.
 * @throws {Error} - Throws an error if the input string has less than two words.
 */
export function removeLastWord(str) {
  if (!str) return ''
  const words = str.trim().split(' ')
  if (words.length < 2) {
    throw new Error('The string must have at least two words.')
  }
  const lastWord = words.pop()
  const modifiedString = words.join(' ').slice(0, 40)
  return {
    modifiedString,
    lastWord,
  }
}

export function useGetCountrySegmentFromUrl() {
  const { country } = useParams()

  return [country, getCountrySegment(country.toUpperCase())]
}

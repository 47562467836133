import { createSlice } from '@reduxjs/toolkit'

export const webhooksSlice = createSlice({
  name: 'webhooks',
  initialState: {
    webhooks: {},
    filters: {
      page: 0,
      limit: 25,
      offset: 0,
      fromDate: null,
      toDate: '',
      id: '',
      emailClient: '',
    },
  },
  reducers: {
    updateWebhooks: (state, action) => {
      state.webhooks = action.payload
    },
    updateWebhooksFilters: (state, action) => {
      state.filters = action.payload
    },
  },
})

export const { updateWebhooks, updateWebhooksFilters } = webhooksSlice.actions
export default webhooksSlice.reducer

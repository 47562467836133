import './datatable.scss'
import { Box, Button, Link, MenuItem, Select, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useUsersByType } from '../../../pages/pages-hooks/useUsers'
import { useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import EditIcon from '@mui/icons-material/Edit'
import DoneIcon from '@mui/icons-material/Done'
import CloseIcon from '@mui/icons-material/Close'
import { buildTimeStringFromTimestamp } from '../time/timeHelper'
import { Flags } from '../flag/flags'
import UserNotification from '../../../pages/kyt/user/UserNotification'
import { useTranslation } from 'react-i18next'
import ProcessingSpinner from '../alertFetchSpinner/processingSpinner'
import { DataGridComponent } from '../datagrid/DataGridComponent'

const DatatableUser = ({
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  setIsLoading,
  handleDispatch,
  users,
  setModalOpen,
  hasTabs,
}) => {
  const { emailClient, activeServices, accessToken } = useSelector((state) => state.login)
  const [searchParams, setSearchParams] = useSearchParams()
  useUsersByType(emailClient, searchParams.get('type'), setIsLoading)
  const [notiUpdate, setNotiUpdate] = useState({ notiUpdate: false })
  const navigate = useNavigate()
  const [canEdit, setCanEdit] = useState(false)
  const [isEditState, setIsEditState] = useState({})
  const [rowStates, setRowStates] = useState({})
  const [isFetching, setIsFetching] = useState(false)
  const { t } = useTranslation(['common'])

  useEffect(() => {
    if (
      !searchParams.get('type') ||
      searchParams.get('type') === null ||
      (searchParams.get('type') !== 'consumerUsers' && searchParams.get('type') !== 'businessUsers')
    ) {
      navigate('/')
    }
    setIsLoading(true)
  }, [searchParams])

  useEffect(() => {
    if (Array.isArray(activeServices)) {
      if (searchParams.get('type') === 'consumerUsers' && activeServices.indexOf('kytcreateConsumerUserEvent') > -1)
        setCanEdit(true)
      if (searchParams.get('type') === 'businessUsers' && activeServices.indexOf('kytcreateBusinessUserEvent') > -1)
        setCanEdit(true)
    }
  }, [activeServices])

  const userStateValues = ['ACTIVE', 'BLOCKED', 'CREATED', 'DORMANT', 'SUSPENDED', 'TERMINATED', 'UNACCEPTABLE']

  const handleNotiUpdate = (status, isCPO = false) => {
    const newNotiUpdate = { ...notiUpdate, notiUpdate: true, status, isCPO }
    setNotiUpdate(newNotiUpdate)
  }

  const handleChange = (value, id, prevValue) => {
    setIsFetching(true)
    let body
    let url

    if (searchParams.get('type') === 'consumerUsers') {
      url = `${process.env.REACT_APP_BASEURL}/kyt/createConsumerUserEvent`
      body = {
        userId: id,
        updatedConsumerUserAttributes: {
          userStateDetails: {
            state: value,
          },
        },
      }
    } else if (searchParams.get('type') === 'businessUsers') {
      url = `${process.env.REACT_APP_BASEURL}/kyt/createBusinessUserEvent`
      body = {
        userId: id,
        updatedBusinessUserAttributes: {
          userStateDetails: {
            state: value,
          },
        },
      }
    }

    if (!process.env.REACT_APP_IS_DEMO) {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(body),
      }

      fetch(url, options)
        .then((response) => response.json())
        .then((res) => {
          if (res.isCPO) {
            handleNotiUpdate(res.status, true)
          } else {
            if (res.status) {
              handleNotiUpdate(res.status)
            }

            if (res.status === 200) {
              setRowStates((prevRowStates) => ({
                ...prevRowStates,
                [id]: value,
              }))
              handleDispatch()
            } else {
              setRowStates((prevRowStates) => ({
                ...prevRowStates,
                [id]: prevValue,
              }))
            }
          }

          setIsFetching(false)
          setIsEditState((prevRowStates) => ({
            ...prevRowStates,
            [id]: false,
          }))
        })
        .catch((error) => {
          console.error('[UPDATE USER ERROR]', error)
          setIsFetching(false)
          setIsEditState((prevRowStates) => ({
            ...prevRowStates,
            [id]: false,
          }))
        })
    } else {
      //DEMO UPDATE
      setRowStates((prevRowStates) => ({
        ...prevRowStates,
        [id]: value,
      }))
      handleDispatch()
      setTimeout(() => {
        setIsFetching(false)
      }, 2000)
      setIsEditState((prevRowStates) => ({
        ...prevRowStates,
        [id]: false,
      }))
    }
  }

  const columnsConsumer = [
    {
      field: 'id',
      headerName: `${t('common:user')} ID`,
      renderCell: (params) => {
        return (
          <Link href={`users/${params.row.id}?type=consumer`}>
            <Typography variant="number">{params.row.id}</Typography>
          </Link>
        )
      },
    },
    {
      field: 'firstName',
      headerName: t('common:firstName'),
      renderCell: (params) => params.row.firstName ?? '---',
    },
    {
      field: 'lastName',
      headerName: t('common:lastName'),
      renderCell: (params) => params.row.lastName ?? '---',
    },
    {
      field: 'dateOfBirth',
      headerName: t('common:dateOfBirth'),
      renderCell: (params) => params.row.dateOfBirth ?? '---',
    },
    {
      field: 'countryOfResidence',
      headerName: t('common:countryOfResidence'),
      renderCell: (params) => {
        return Flags(params.row.countryOfResidence)
      },
    },
    {
      field: 'countryOfNationality',
      headerName: t('common:countryOfNationality'),
      renderCell: (params) => {
        return Flags(params.row.countryOfNationality)
      },
    },
    {
      field: 'createdTimestamp',
      headerName: t('common:createdAt'),
      renderCell: (params) => {
        return <Typography variant="number">{buildTimeStringFromTimestamp(params.row.createdTimestamp)}</Typography>
      },
    },
    {
      field: 'details',
      headerName: t('common:details'),
      hide: !canEdit,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <Box key={params.row.id}>
            {isEditState[params.row.id] ? (
              <Box className="state-edit-container">
                {isFetching ? (
                  <ProcessingSpinner />
                ) : (
                  <Box className="state-edit-container">
                    <Select
                      key={params.row.id}
                      id={params.row.id}
                      size="small"
                      disabled={!canEdit || isFetching}
                      displayEmpty
                      value={rowStates[params.row.id] || params.row.userStateDetails?.state} // Use rowState here
                      defaultValue={params.row.userStateDetails?.state}
                      onChange={(e) => {
                        setRowStates((prevRowStates) => ({
                          ...prevRowStates,
                          [params.row.id]: e.target.value,
                        }))
                      }}
                    >
                      <MenuItem disabled value="">
                        <Typography variant="subtitle3">{t('common:state')}</Typography>
                      </MenuItem>
                      {Object.entries(userStateValues).map(([key, value]) => (
                        <MenuItem key={key} value={value}>
                          <Typography variant="subtitle1">{value}</Typography>
                        </MenuItem>
                      ))}
                    </Select>

                    <Button
                      variant="outlinedButtonSuccess"
                      onClick={() => {
                        handleChange(rowStates[params.row.id], params.row.id, params.row.userStateDetails?.state)
                      }}
                    >
                      <DoneIcon />
                    </Button>
                    <Button
                      variant="outlinedButtonError"
                      onClick={() => {
                        setIsEditState((prevRowStates) => ({
                          ...prevRowStates,
                          [params.row.id]: false,
                        }))
                      }}
                    >
                      <CloseIcon />
                    </Button>
                  </Box>
                )}
              </Box>
            ) : (
              <Box className="state-container">
                {params.row.userStateDetails?.state ? params.row.userStateDetails?.state : '---'}
                <Button
                  variant="outlinedButtonSuccess"
                  onClick={() => {
                    setIsEditState((prevRowStates) => ({
                      ...prevRowStates,
                      [params.row.id]: true,
                    }))
                  }}
                >
                  <EditIcon />
                </Button>
              </Box>
            )}
          </Box>
        )
      },
    },
  ]

  const columnsBusiness = [
    {
      field: 'id',
      headerName: 'User ID',
      renderCell: (params) => {
        return (
          <Link href={`users/${params.row.id}?type=business`}>
            <Typography variant="number">{params.row.id}</Typography>
          </Link>
        )
      },
    },
    {
      field: 'legalName',
      headerName: t('common:legalName'),
    },
    {
      field: 'expectedTransactionAmountPerMonth',
      headerName: t('common:expectedTransaction'),
      renderCell: (params) => {
        return <Box className="table-body-cell">{params.row.expectedTransactionAmountPerMonth?.toLocaleString()}</Box>
      },
    },
    {
      field: 'expectedTurnoverAmountPerMonth',
      headerName: t('common:expectedTurnover'),
      renderCell: (params) => {
        return <Box className="table-body-cell">{params.row.expectedTurnoverAmountPerMonth?.toLocaleString()}</Box>
      },
    },
    {
      field: 'registrationCountry',
      headerName: t('common:registrationCountry'),
      renderCell: (params) => {
        return Flags(params.row.registrationCountry)
      },
    },
    {
      field: 'createdTimestamp',
      headerName: t('common:creationTime'),
      renderCell: (params) => {
        return <Typography variant="number">{buildTimeStringFromTimestamp(params.row.createdTimestamp)}</Typography>
      },
    },
    {
      field: 'details',
      headerName: t('common:details'),
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <Box key={params.row.id}>
            {isEditState[params.row.id] ? (
              <Box className="state-edit-container">
                {isFetching ? (
                  <ProcessingSpinner />
                ) : (
                  <Box className="state-edit-container">
                    <Select
                      key={params.row.id}
                      id={params.row.id}
                      size="small"
                      disabled={!canEdit || isFetching}
                      displayEmpty
                      value={rowStates[params.row.id] || params.row.userStateDetails?.state}
                      defaultValue={params.row.userStateDetails?.state}
                      onChange={(e) => {
                        setRowStates((prevRowStates) => ({
                          ...prevRowStates,
                          [params.row.id]: e.target.value,
                        }))
                      }}
                    >
                      <MenuItem disabled value="">
                        <Typography variant="subtitle3">{t('common:currency')}</Typography>
                      </MenuItem>
                      {Object.entries(userStateValues).map(([key, value]) => (
                        <MenuItem key={key} value={value}>
                          <Typography variant="subtitle1">{value}</Typography>
                        </MenuItem>
                      ))}
                    </Select>

                    <Button
                      variant="outlinedButtonSuccess"
                      onClick={() => {
                        handleChange(rowStates[params.row.id], params.row.id, params.row.userStateDetails?.state)
                      }}
                    >
                      <DoneIcon />
                    </Button>
                    <Button
                      variant="outlinedButtonError"
                      onClick={() => {
                        setIsEditState((prevRowStates) => ({
                          ...prevRowStates,
                          [params.row.id]: false,
                        }))
                      }}
                    >
                      <CloseIcon />
                    </Button>
                  </Box>
                )}
              </Box>
            ) : (
              <Box className="state-container">
                {params.row.userStateDetails?.state ? params.row.userStateDetails?.state : '---'}
                <Button
                  variant="outlinedButtonSuccess"
                  onClick={() => {
                    setIsEditState((prevRowStates) => ({
                      ...prevRowStates,
                      [params.row.id]: true,
                    }))
                  }}
                >
                  <EditIcon />
                </Button>
              </Box>
            )}
          </Box>
        )
      },
    },
  ]

  return (
    <Box>
      {notiUpdate.notiUpdate && <UserNotification notiUpdate={notiUpdate} setNotiUpdate={setNotiUpdate} />}

      <DataGridComponent
        rows={(Array.isArray(users?.data) && users?.data?.length && users?.data) || []}
        columns={searchParams.get('type') === 'businessUsers' ? columnsBusiness : columnsConsumer}
        count={users?.pagination?.totalElements || 0}
        page={page}
        rowsPerPage={rowsPerPage}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        setIsLoading={setIsLoading}
        setModalOpen={setModalOpen}
        hasTabs={hasTabs}
      />
    </Box>
  )
}

export default DatatableUser

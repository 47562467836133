import { Box, Input, InputLabel, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'

const GetInputsByCheckType = ({ checkType, values, setters }) => {
  const { t } = useTranslation(['common'])

  switch (checkType) {
    case 'argentinaValidation':
    case 'argentinaValidationLight':
      return (
        <ManualGovCheckInput
          label={t('common:document')}
          value={values?.documento}
          onChange={(e) => setters.setDocumento(e.target.value)}
          placeholder={t('messages:insertDocHere')}
        />
      )
    case 'brazilValidation':
    case 'brazilValidationRelativeName':
      return (
        <ManualGovCheckInput
          label="Cpfcnpj"
          value={values?.cpfcnpj}
          onChange={(e) => setters.setCpfcnpj(e.target.value)}
          placeholder={t('common:insertCpfcnpjHere')}
        />
      )
    case 'rutChile':
      return (
        <>
          <ManualGovCheckInput
            label="Rut"
            value={values?.rut}
            onChange={(e) => setters.setRut(e.target.value)}
            placeholder={t('common:insertRutNumberHere')}
          />
          <ManualGovCheckInput
            label={t('common:document')}
            value={values?.ndocumento}
            onChange={(e) => setters.setNDocumento(e.target.value)}
            placeholder={t('messages:insertDocHere')}
          />
        </>
      )
    case 'colombiaValidation':
      return (
        <>
          <ManualGovCheckInput
            label={t('common:document')}
            value={values?.documentNumber}
            onChange={(e) => setters.setDocumentNumber(e.target.value)}
            placeholder={t('messages:insertDocHere')}
          />
          <ManualGovCheckInput
            label="Expedition Date (dd-mm-yyyy)"
            value={values?.expeditionDate}
            onChange={(e) => setters.setExpeditionDate(e.target.value)}
            placeholder={t('common:insertExpeditionDateHere')}
          />
        </>
      )
    case 'mexicoCurp':
      return (
        <ManualGovCheckInput
          label="CURP"
          value={values?.curp}
          onChange={(e) => setters.setCurp(e.target.value)}
          placeholder={t('common:insertCurpHere')}
        />
      )
    case 'mexicoINE':
      return (
        <>
          <ManualGovCheckInput
            label="Instituto"
            value={values?.instituto}
            onChange={(e) => setters.setInstituto(e.target.value)}
            placeholder={t('common:insertInstituteHere')}
          />
          <ManualGovCheckInput
            label="Identificador Ciudadano"
            value={values?.identificadorCiudadano}
            onChange={(e) => setters.setIdentificadorCiudadano(e.target.value)}
            placeholder={t('common:insertCitizenIdentifierHere')}
          />
          <ManualGovCheckInput
            label="CIC"
            value={values?.cic}
            onChange={(e) => setters.setCic(e.target.value)}
            placeholder={t('common:insertCicHere')}
          />
          <ManualGovCheckInput
            label="OCR"
            value={values?.ocr}
            onChange={(e) => setters.setOcr(e.target.value)}
            placeholder={t('common:insertCicHere')}
          />
          <ManualGovCheckInput
            label="Clave Elector"
            value={values?.claveElector}
            onChange={(e) => setters.setClaveElector(e.target.value)}
            placeholder={t('common:insertElectorKeyHere')}
          />
          <ManualGovCheckInput
            label="Número Emisión"
            value={values?.numeroEmision}
            onChange={(e) => setters.setNumeroEmision(e.target.value)}
            placeholder={t('common:insertEmmisionNumberHere')}
          />
        </>
      )
    case 'mexicoPersona':
      return (
        <>
          <ManualGovCheckInput
            label="Birth Date"
            value={values?.birthDate}
            onChange={(e) => setters.setBirthDate(e.target.value)}
            placeholder={t('common:insertBirthDateHere')}
          />
          <ManualGovCheckInput
            label="Entity Birth"
            value={values?.entityBirth}
            onChange={(e) => setters.setEntityBirth(e.target.value)}
            placeholder={t('common:insertEntityBirthHere')}
          />
          <ManualGovCheckInput
            label="Names"
            value={values?.names}
            onChange={(e) => setters.setNames(e.target.value)}
            placeholder={t('common:insertNamesHere')}
          />
          <ManualGovCheckInput
            label="Paternal Surname"
            value={values?.paternalSurname}
            onChange={(e) => setters.setPaternalSurname(e.target.value)}
            placeholder={t('common:insertPaternalSurnameHere')}
          />
          <ManualGovCheckInput
            label="Sex"
            value={values?.sex}
            onChange={(e) => setters.setSex(e.target.value)}
            placeholder={t('common:insertSexHere')}
          />
          <ManualGovCheckInput
            label="Mothers Maiden Name"
            value={values?.mothersMaidenName}
            onChange={(e) => setters.setMothersMaidenName(e.target.value)}
            placeholder={t('common:insertMothersMaidenNameHere')}
          />
        </>
      )
    case 'mexicoRfc':
      return (
        <ManualGovCheckInput
          label="RFC"
          value={values?.rfc}
          onChange={(e) => setters.setRfc(e.target.value)}
          placeholder={t('common:insertRFCHere')}
        />
      )
    case 'peruValidationDNI':
    case 'peruValidationDNIFicha':
      return (
        <ManualGovCheckInput
          label="DNI"
          value={values?.dni}
          onChange={(e) => setters.setDni(e.target.value)}
          placeholder={t('common:insertDniHere')}
        />
      )
    case 'peruValidationRUC':
      return (
        <ManualGovCheckInput
          label="RUC"
          value={values?.ruc}
          onChange={(e) => setters.setRuc(e.target.value)}
          placeholder={t('common:insertRucHere')}
        />
      )
    default:
      return null // Return null when no matching check type
  }
}
export default GetInputsByCheckType

const ManualGovCheckInput = ({ label, value, onChange, placeholder }) => (
  <Box className="govcheck-manual-input">
    <InputLabel>{label}</InputLabel>
    <TextField type="text" size="small" value={value} onChange={onChange} placeholder={placeholder} />
  </Box>
)

import { Box, InputLabel, TextField, Typography } from '@mui/material'
import './numberAndStringInputs.scss'
import { useEffect, useState } from 'react'

const NumberAndStringInputs = ({ propertie, ruleParameters, setRuleParameters, schema, isEditing }) => {
  const [number, setNumber] = useState('')
  const [changeNumber, setChangeNumber] = useState(false)

  const [string, setString] = useState('')
  const [changeString, setChangeString] = useState(false)

  useEffect(() => {
    if (ruleParameters[propertie] && ruleParameters[propertie].value) {
      setNumber(ruleParameters[propertie].value)
    }
    if (ruleParameters[propertie] && ruleParameters[propertie].currency) {
      setString(ruleParameters[propertie].currency)
    }
  }, [propertie, ruleParameters])

  const handleChangeNumber = (value) => {
    setNumber(value)
    setChangeNumber(true)
  }

  const handleChangeString = (value) => {
    setString(value)
    setChangeString(true)
  }

  useEffect(() => {
    if (changeNumber) {
      let all = { ...ruleParameters }
      let aux = { ...all[propertie] }
      if (number !== '') {
        aux['value'] = number
        all[propertie] = { ...aux }
      } else {
        delete aux['value']
        all[propertie] = { ...aux }
      }
      setRuleParameters({ ...all })
    }
  }, [number, changeNumber])

  useEffect(() => {
    if (changeString) {
      let all = { ...ruleParameters }
      let aux = { ...all[propertie] }
      if (string !== '') {
        aux['currency'] = string
        all[propertie] = { ...aux }
      } else {
        delete aux['currency']
        all[propertie] = { ...aux }
      }
      setRuleParameters({ ...all })
    }
  }, [string, changeString])

  return (
    <Box className="number-string-container">
      <Box className="number-string-row">
        {schema?.properties?.value?.title && <InputLabel>{schema.properties.value.title}</InputLabel>}
        {schema?.value?.title && <InputLabel>{schema.value.title}</InputLabel>}
        {schema?.properties?.value?.description && (
          <Typography variant="subtitle4">{schema.properties.value.description}</Typography>
        )}
        {schema?.value?.description && <Typography variant="subtitle4">{schema.value.description}</Typography>}
        <TextField
          type="number"
          size="small"
          value={number}
          disabled={!isEditing}
          variant="outlined"
          onChange={(event) => handleChangeNumber(event.target.value !== '' ? parseInt(event.target.value) : '')}
        />
      </Box>
      <Box className="number-string-row">
        {schema?.properties?.currency?.title && <InputLabel>{schema.properties.currency.title}</InputLabel>}
        {schema?.currency?.title && <InputLabel>{schema.currency.title}</InputLabel>}
        {schema?.currency?.description && <Typography variant="subtitle4">{schema.currency.description}</Typography>}
        <TextField
          disabled={!isEditing}
          variant="outlined"
          type="string"
          size="small"
          value={string}
          onChange={(event) => handleChangeString(event.target.value)}
        />
      </Box>
    </Box>
  )
}

export default NumberAndStringInputs

import { createSlice } from '@reduxjs/toolkit'

export const roleSlice = createSlice({
  name: 'role',
  initialState: {
    roles: [],
    filters: {
      roleName: '',
      status: '',
      viewMode: true,
      page: 0,
      limit: 50,
      offset: 0,
    },
  },
  reducers: {
    updateRoles: (state, action) => {
      state.roles = action.payload
    },
    performLogoutRoles: (state) => {
      state.roles = []
    },
    updateRolesFilters: (state, filters) => {
      state.filters = filters.payload
    },
  },
})

export const { updateRoles, performLogoutRoles, updateRolesFilters } = roleSlice.actions
export default roleSlice.reducer

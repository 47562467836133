import './datatable.scss'
import { TableContainer, TablePagination, Typography } from '@mui/material'
import { useState } from 'react'
import { buildTimeStringFromTimestamp } from '../time/timeHelper'
import { DataGrid } from '@mui/x-data-grid'
import { useTranslation } from 'react-i18next'

const DatatableUserEvent = ({ rows }) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const { t } = useTranslation(['common'])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const getPaginatedData = (data, page, rowsPerPage) => {
    const startIndex = page * rowsPerPage
    const endIndex = startIndex + rowsPerPage
    const paginatedData = data.slice(startIndex, endIndex)
    return paginatedData
  }

  const paginatedEvents = getPaginatedData(rows, page, rowsPerPage)

  const columns = [
    {
      field: 'eventId',
      headerName: 'ID',
      flex: 1,
      minWidth: 280,
      renderCell: (params) => {
        return <Typography variant="number">{params.row.eventId}</Typography>
      },
    },
    {
      field: 'timestamp',
      headerName: t('common:eventDate'),
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        return <Typography variant="number">{buildTimeStringFromTimestamp(params.row.timestamp)}</Typography>
      },
    },
    {
      field: 'eventDescription',
      headerName: t('common:description'),
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'reason',
      headerName: t('common:reason'),
      flex: 1,
      minWidth: 100,
    },
  ]

  return (
    <TableContainer>
      <DataGrid
        getRowId={(row) => row.eventId}
        rows={paginatedEvents || []}
        columns={columns}
        hideFooter={true}
        autoHeight={true}
      />
      <TablePagination
        labelRowsPerPage={[]}
        component="div"
        count={paginatedEvents.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  )
}

export default DatatableUserEvent

import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateChecklists } from '../../features/checklist/checklistSlice'

export const useChecklist = (emailClient, setIsLoading) => {
  const checklists = useSelector((state) => state.checklists)
  const queryState = useSelector((state) => state.checklists?.filters)
  const { limit, offset, fromDate, toDate, page, id } = queryState ? queryState : { ...null }
  const { accessToken } = useSelector((state) => state.login)

  const dispatch = useDispatch()

  function buildUrl(baseUrl) {
    let url = `${baseUrl}?limit=${limit}&page=${page}&offset=${offset}`
    if (fromDate && toDate) url += `&fromDate=${fromDate}&toDate=${toDate}`
    if (id?.length) url += `&id=${id}`
    if (emailClient?.length) url += `&emailClient=${emailClient}`

    return url
  }

  useEffect(() => {
    const baseUrlChecklist = `${process.env.REACT_APP_BASEURL}/admin/checklistTemplates`
    let url = buildUrl(baseUrlChecklist)

    fetch(process.env.REACT_APP_IS_DEMO ? '../../data/checklistTemplates.json' : url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res) {
          const { data, pagination } = res
          const aux = { data, pagination }
          dispatch(updateChecklists(aux))
          if (setIsLoading) setIsLoading(false)
        }
      })
      .catch((error) => {
        console.error('[HOOK: useChecklist] --> ', error)
        if (setIsLoading) setIsLoading(false)
      })
  }, [queryState])

  return checklists.checklists
}

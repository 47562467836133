import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateUsersAdmin } from '../../features/usersAdmin/userAdminSlice'

export const useUsersAdmin = (emailClient) => {
  const usersAdminState = useSelector((state) => state.userAdmin)
  const queryState = useSelector((state) => state.userAdmin.filters)
  const { viewMode, limit, offset, page, email, roles, status } = queryState ? queryState : { ...null }
  const { accessToken } = useSelector((state) => state.login)

  const dispatch = useDispatch()

  useEffect(() => {
    const baseUrlUsers = `${process.env.REACT_APP_BASEURL}/user/all?emailClient=${emailClient}`
    let url = buildUrl(baseUrlUsers)

    function buildUrl(baseUrl) {
      let url = `${baseUrl}&viewMode=${viewMode}&limit=${limit}&page=${page}&offset=${offset}`

      if (email?.length) url += `&email=${email}`

      if (status?.length) url += `&status=${status}`

      if (roles?.length) url += `&roles=${roles}`

      return url
    }

    fetch(process.env.REACT_APP_IS_DEMO ? '../../data/users.json' : url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res) {
          const { data, pagination } = res
          const aux = { data, pagination }
          dispatch(updateUsersAdmin(aux))
        }
      })
      .catch((error) => console.error('[HOOK: useInstanceRules] --> ', error))
  }, [queryState])
  return usersAdminState
}

import './datatable.scss'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, TableContainer, Typography } from '@mui/material'
import { useGeneralAlertsByConsumerUserId } from '../../../pages/pages-hooks/useGeneralAlerts'
import { buildTimeStringFromTimestamp } from '../time/timeHelper'
import { DataGrid } from '@mui/x-data-grid'
import { useTranslation } from 'react-i18next'
import { TableChip } from '../../utilities/TableChip'

const DatatableUserAlerts = ({ consumerUserId }) => {
  const [isLoading, setIsLoading] = useState(true)
  useGeneralAlertsByConsumerUserId(consumerUserId, setIsLoading)
  const { t } = useTranslation(['common'])
  const { consumerUser: consumerUserAlerts } = useSelector((state) => state.generalAlerts)

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return <Typography variant="number">{params.row.id}</Typography>
      },
    },
    {
      field: 'createdAt',
      headerName: t('common:createdAt'),
      flex: 1,
      minWidth: 180,
      valueGetter: (params) => {
        return buildTimeStringFromTimestamp(params.row.createdAt)
      },
    },
    {
      field: 'type',
      headerName: t('common:service'),
      flex: 1,
      minWidth: 120,
      renderCell: (params) => {
        return <TableChip action={params.row.type?.toUpperCase()} noIcon={true} />
      },
      valueGetter: (params) => {
        return params.row.type
      },
    },
    {
      field: 'name',
      headerName: t('common:name'),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => {
        return params.row.details?.name
      },
    },
    {
      field: 'types',
      headerName: t('common:types'),
      flex: 1,
      minWidth: 120,
      valueGetter: (params) => {
        return params.row.details?.types?.join(', ')
      },
    },
    {
      field: 'details',
      headerName: t('common:complyAdvantage'),
      flex: 1,
      minWidth: 120,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <Link
            onClick={() => window.open(params.row.details.shareUrl, '_blank', 'rel=noopener noreferrer')}
            color="secondary"
            disabled={!(params.row.details && params.row.details.shareUrl)}
          >
            {t('common:viewDetails')}
          </Link>
        )
      },
    },
  ]

  return (
    <TableContainer>
      <DataGrid
        getRowId={(row) => row._id || row.id}
        rows={Array.isArray(consumerUserAlerts) && consumerUserAlerts}
        columns={columns}
        hideFooter={true}
        autoHeight={true}
      />
    </TableContainer>
  )
}

export default DatatableUserAlerts

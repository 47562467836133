import React, {useEffect, useCallback } from 'react'
import { DataGridPro, GridColumnMenu, useGridApiRef } from '@mui/x-data-grid-pro'
import { Box, Button, TablePagination } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined'
import { useTranslation } from 'react-i18next'
import { updateSortTransactions } from '../../../features/externalPaymentMethods/externalPaymentMethodsSlice'

export const DataGridExternalPaymentMethodsComponent = ({
  rows,
  columns,
  count,
  page,
  rowsPerPage,
  setPage,
  setRowsPerPage,
  setIsLoading,
  setModalOpen,
  hasTabs,
}) => {
  const apiRef = useGridApiRef()
  const { t } = useTranslation(['common'])
  const { theme } = useSelector((state) => state.theme)
  const dispatch = useDispatch()
  const sortTransactions = useSelector((state) => state.externalPaymentMethods.sorting.sortTransactions)

  const autosizeOptions = {
    includeHeaders: true,
    includeOutliers: true,
    expand: true,
  }

  useEffect(() => {
    if (apiRef.current) {
      apiRef.current.autosizeColumns(autosizeOptions)
    }
  }, [apiRef, rows, theme, autosizeOptions])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    setIsLoading(true)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
    setIsLoading(true)
  }

  function CustomColumnMenu(props) {
    return (
      <GridColumnMenu
        {...props}
        slots={{
          columnMenuColumnsItem: null,
          columnMenuFilterItem: null,
          columnMenuSortItem: null,
        }}
      />
    )
  }

  const handleSortModelChange = useCallback(() => {
    const newSortTransactions = sortTransactions === -1 ? 1 : -1
    dispatch(updateSortTransactions(newSortTransactions))
    setIsLoading(true)
  }, [sortTransactions, dispatch])

  return (
    <Box style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
      <Box className="filter-bar">
        <Button variant="label" endIcon={<MenuOutlinedIcon />} onClick={() => setModalOpen(true)}>
          {t('common:filters')}
        </Button>
        <Button
          variant="label"
          endIcon={<MenuOutlinedIcon />}
          onClick={() => apiRef.current.showPreferences('columns')}
        >
          {t('common:showHideColumns')}
        </Button>
      </Box>
      <DataGridPro
        getRowHeight={() => 'auto'}
        getRowId={(row) => JSON.stringify(row.id)}
        rows={rows}
        columns={columns}
        apiRef={apiRef}
        slots={{ columnMenu: CustomColumnMenu }}
        hideFooter={true}
        sortingMode="server"
        onSortModelChange={handleSortModelChange}
        sx={{
          height: hasTabs ? `calc(100vh - ${hasTabs})` : 'calc(100vh - 19.5rem)',
          '& .MuiDataGrid-virtualScroller': {
            overflow: 'auto',
          },
        }}
        autoHeight
      />
      <TablePagination
        labelRowsPerPage={[]}
        component="div"
        count={count}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  )
}

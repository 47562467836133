import { useCallback, useRef, useState } from 'react'
import { Box, Button, Paper } from '@mui/material'
import { Background, Controls, ReactFlow, applyEdgeChanges, applyNodeChanges } from '@xyflow/react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import StepInput from './case-flow-components/StepInput'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import EditIcon from '@mui/icons-material/Edit'

const customNodeTypes = { customNode: StepInput }

const CaseFlow = ({ steps, setSteps, isEdit }) => {
  const { t } = useTranslation(['common'])
  const { theme } = useSelector((state) => state.theme)
  const [firsStep, setFirstStep] = useState(false)
  const [nodes, setNodes] = useState([])
  const [edges, setEdges] = useState([])
  const yPos = useRef(50)
  const nodeId = useRef(2)

  const startWorkflow = useCallback(() => {
    setFirstStep(true)
    setNodes((els) => {
      return [
        ...els,
        {
          id: '1',
          position: { x: yPos.current, y: 100 },
          type: 'customNode',
          data: { saveSteps, addNode },
        },
      ]
    })
  }, [])

  const editWorkflow = useCallback(() => {
    setFirstStep(true)

    const mappedSteps = steps?.map((step, index) => {
      nodeId.current += 1
      yPos.current += 210
      return {
        id: step.id,
        position: { x: index * 500, y: 100 },
        type: 'customNode',
        data: { deleteStep, saveSteps, addNode, step: step, isEdit: isEdit },
      }
    })
    setNodes(mappedSteps)
  }, [steps])

  const addNode = useCallback(() => {
    yPos.current += 520
    setNodes((els) => {
      return [
        ...els,
        {
          id: nodeId.current.toString(),
          position: { x: yPos.current, y: 100 },
          type: 'customNode',
          data: { deleteStep, saveSteps, addNode, steps, isEdit },
        },
      ]
    })
    nodeId.current += 1
  }, [])

  const addEdge = useCallback(({ source, target }) => {
    setEdges((els) => {
      return [
        ...els,
        {
          id: Math.random(),
          source,
          target,
          animated: true,
          style: { stroke: 'rgb(158, 118, 255)', strokeWidth: 3 },
        },
      ]
    })
  }, [])

  const onNodesChange = useCallback((changes) => setNodes((nds) => applyNodeChanges(changes, nds)), [])
  const onEdgesChange = useCallback((changes) => setEdges((eds) => applyEdgeChanges(changes, eds)), [])

  const deleteStep = useCallback((id) => {
    setNodes((nds) => nds.filter((node) => node.id !== id))
    setEdges((eds) => eds.filter((edge) => edge.source !== id && edge.target !== id))
    setSteps((sts) => sts.filter((step) => step.id !== id))

    yPos.current -= 520
  }, [])

  const saveSteps = (data) => {
    setSteps((stps) => {
      const exists = stps.some((step) => step.id === data.id)

      if (exists) {
        return stps.map((step) => (step.id === data.id ? data : step))
      } else {
        return [...stps, data]
      }
    })
  }

  return (
    <Box className="case-flow">
      {firsStep ? (
        <Box className="case-flow-wrapper">
          <ReactFlow
            nodeTypes={customNodeTypes}
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={addEdge}
            colorMode={theme === 'light' ? 'light' : 'dark'}
          >
            <Background />
            <Controls />
          </ReactFlow>
        </Box>
      ) : (
        <Paper elevation={0} variant="colorDotted" className="case-flow-first-step">
          <Button
            variant="outlinedGrey"
            onClick={() => (isEdit ? editWorkflow() : startWorkflow())}
            endIcon={isEdit ? <EditIcon /> : <AddCircleOutlineOutlinedIcon />}
          >
            {isEdit ? t('common:edit') : t('common:start')}
          </Button>
        </Paper>
      )}
    </Box>
  )
}

export default CaseFlow

import './datatable.scss'
import { useEffect, useState } from 'react'
import { useTransactionsByUserId } from '../../../pages/pages-hooks/useTransactions'
import { useSelector } from 'react-redux'
import { Box, Link, TableContainer, TablePagination, Typography } from '@mui/material'
import { buildTimeStringFromTimestamp } from '../time/timeHelper'
import { Flags } from '../flag/flags'
import { DataGrid } from '@mui/x-data-grid'
import { TableChip } from '../../utilities/TableChip'
import { TableIcons } from '../../utilities/TableIcons'
import { useTranslation } from 'react-i18next'

const DatatableUserTransaction = ({ page, setPage, rowsPerPage, setRowsPerPage, id }) => {
  const { emailClient } = useSelector((state) => state.login)
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation(['common'])

  useTransactionsByUserId(emailClient, id)
  const transactionState = useSelector((state) => state.transaction.transactionByUserId)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  useEffect(() => {
    if (transactionState?.data) {
      setIsLoading(true)
    }
  }, [transactionState])

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return (
          <Link href={`/transactions/${params.row.id}`}>
            <Typography variant="number">{params.row.id}</Typography>
          </Link>
        )
      },
    },
    {
      field: 'transactionType',
      headerName: t('common:type'),
      flex: 1,
      minWidth: 170,
      renderCell: (params) => {
        return <Box>{params.row.transactionType ? <TableIcons type={params.row.transactionType} /> : '---'}</Box>
      },
      valueGetter: (params) => {
        return params.row.transactionType
      },
    },
    {
      field: 'transactionState',
      headerName: t('common:state'),
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return <Box>{params.row.transactionState ? <TableIcons type={params.row.transactionState} /> : '---'}</Box>
      },
      valueGetter: (params) => {
        return params.row.transactionState
      },
    },
    {
      field: 'creationDate',
      headerName: t('common:date'),
      flex: 1,
      minWidth: 134,
      valueGetter: (params) => {
        return buildTimeStringFromTimestamp(params.row.creationDate)
      },
    },
    {
      field: 'originUserId',
      headerName: t('common:originUserId'),
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'originMethod',
      headerName: t('common:originMethod'),
      flex: 1,
      minWidth: 180,
      renderCell: (params) => {
        return <TableChip action={params.row.originMethod} noIcon />
      },
    },
    {
      field: 'originAmount',
      headerName: t('common:originAmount'),
      flex: 1,
      minWidth: 130,
      valueGetter: (params) => {
        return params.row.originAmount?.toLocaleString()
      },
    },
    {
      field: 'originCurrency',
      headerName: t('common:originCurrency'),
      flex: 1,
      minWidth: 130,
    },
    {
      field: 'originCountry',
      headerName: t('common:originCountry'),
      flex: 1,
      minWidth: 130,
      renderCell: (params) => {
        return <Box className="table-body-cell">{Flags(params.row.originCountry, false, true)}</Box>
      },
      valueGetter: (params) => {
        return params.row.originCountry
      },
    },
    {
      field: 'destinationUserId',
      headerName: t('common:destinationUserId'),
      flex: 1,
      minWidth: 180,
    },
    {
      field: 'destinationMethod',
      headerName: t('common:destinationMethod'),
      flex: 1,
      minWidth: 180,
      renderCell: (params) => {
        return <TableChip action={params.row.destinationMethod} noIcon />
      },
      valueGetter: (params) => {
        return params.row.destinationMethod
      },
    },
    {
      field: 'destinationAmount',
      headerName: t('common:destinationAmount'),
      flex: 1,
      minWidth: 130,
      valueGetter: (params) => {
        return params.row.destinationAmount?.toLocaleString()
      },
    },
    {
      field: 'destinationCurrency',
      headerName: t('common:destinationCurrency'),
      flex: 1,
      minWidth: 130,
    },
    {
      field: 'destinationCountry',
      headerName: t('common:destinationCountry'),
      flex: 1,
      minWidth: 130,
      renderCell: (params) => {
        return <Box>{Flags(params.row.destinationCountry, false, true)}</Box>
      },
      valueGetter: (params) => {
        return params.row.destinationCountry
      },
    },
  ]

  return (
    <TableContainer>
      <DataGrid
        getRowId={(row) => row._id || row.id}
        rows={transactionState && Array.isArray(transactionState?.data) && transactionState?.data}
        columns={columns}
        hideFooter={true}
        autoHeight={true}
      />

      <TablePagination
        labelRowsPerPage={[]}
        component="div"
        count={transactionState?.pagination?.totalElements || 0}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  )
}

export default DatatableUserTransaction

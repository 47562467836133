import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { updateLists } from '../../features/lists/listsSlice'

export const useLists = (setIsLoading, isLoading, tab) => {
  const queryState = useSelector((state) => state.lists?.filters)
  const { limit, offset, fromDate, toDate, page, id, status, type, list, context, title, description } = queryState
    ? queryState
    : { ...null }
  const { accessToken } = useSelector((state) => state.login)

  const dispatch = useDispatch()

  function buildUrl(baseUrl) {
    let url = `${baseUrl}?limit=${limit}&page=${page}&offset=${offset}`
    if (fromDate && toDate) url += `&fromDate=${fromDate}&toDate=${toDate}`
    if (tab?.length) url += `&type=${tab === 'whitelist' ? 'white_list' : 'black_list'}`
    if (id?.length) url += `&id=${id}`
    if (status?.length) url += `&status=${status}`
    if (type?.length) url += `&type=${type}`
    if (list?.length) url += `&list=${list}`
    if (context?.length) url += `&context=${context}`
    if (title?.length) url += `&title=${title}`
    if (description?.length) url += `&description=${description}`

    return url
  }

  useEffect(() => {
    if (isLoading) {
      const baseUrlLists = `${process.env.REACT_APP_BASEURL}/admin/lists`
      let url = buildUrl(baseUrlLists)

      fetch(process.env.REACT_APP_IS_DEMO ? '../../data/lists.json' : url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          if (res) {
            const { data, pagination } = res
            const aux = { data, pagination }
            dispatch(updateLists(aux))
            setIsLoading(false)
          }
        })
        .catch((error) => {
          console.error('[HOOK: useLists] --> ', error)
          setIsLoading(false)
        })
    }
  }, [isLoading, tab])
}

import { Box, Button, Modal, Tooltip, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

const KybResetModal = ({
  openModal,
  setOpenModal,
  handleAction,
}) => {
  const { t } = useTranslation(['common', 'messages'])

  const handleConfirm = () => {
    setOpenModal(false)
    handleAction()
  }

  return (
    <Modal open={openModal} onClose={() => setOpenModal(false)}>
      <Box className="modal" gap="1.5rem">
        <Typography variant="title">
          {t('messages:reset.kyb.modal.title')}
        </Typography>
        <Box display="flex" justifyContent="space-evenly" mt="1.5rem">
          <Button variant="outlined" color="secondary" onClick={() => setOpenModal(false)}>
            {t('common:cancel')}
          </Button>
          <Button variant="contained" color="primary" onClick={handleConfirm}>
            {t('common:reset')}
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default KybResetModal

import { useEffect } from 'react'
import { decodeToken } from 'react-jwt'
import { useSelector } from 'react-redux'
import { clearAllPersistentTimeouts, setPersistentTimeout } from './timeoutmanager'

export const LogoutTimer = ({ children, handleUserLogout }) => {
  const { accessToken } = useSelector((state) => state.login)
  const decodedToken = decodeToken(accessToken)
  const events = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress']
  const defaultActivityTime = 28800000 // 8 hours in milliseconds
  const activityTime = decodedToken.dashboardActivityTime
    ? decodedToken.dashboardActivityTime * 60 * 1000
    : defaultActivityTime

  const handleLogoutTimer = () => {
    setPersistentTimeout(() => {
      resetTimer()
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer)
      })

      handleUserLogout()
    }, activityTime)
  }

  const resetTimer = () => {
    clearAllPersistentTimeouts()
  }

  useEffect(() => {
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        resetTimer()
        handleLogoutTimer()
      })
    })

    return () => {
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer)
      })
      resetTimer()
    }
  }, [])

  useEffect(() => {
    if (!accessToken) {
      resetTimer()
    }
  }, [accessToken])

  return children
}

import React, { useState } from 'react'
import { Paper, Box, Button, Typography, Modal, Fab, InputLabel, Autocomplete, TextField } from '@mui/material'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { useTranslation } from 'react-i18next'

export default function StateEditBox({
  subtitle,
  handleStateChange,
  handleUpdateBtn,
  toUpdate,
  btnLoading,
  userState,
  canEdit,
  userStateValues,
}) {
  const [openModal, setOpenModal] = useState(false)
  const { t } = useTranslation(['common'])
  const handleOpen = () => setOpenModal(true)
  const handleClose = () => setOpenModal(false)

  return (
    <>
      <Paper elevation={0} sx={{ padding: '1rem' }}>
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" flexDirection="column" alignItems="flex-start" gap={'4px'}>
            <Typography variant="subtitle2">{subtitle}</Typography>
            <Typography className="StateEditBox_value">{userState}</Typography>
          </Box>
          <Button variant="outlined" onClick={handleOpen} disabled={!canEdit} width="56px">
            {t('common:edit')}
          </Button>
        </Box>
      </Paper>

      <Modal open={openModal} onClose={handleClose}>
        <Box className="modal">
          <Box className="modal-top">
            <Box className="modal-titles">
              <Typography variant="title">
                {t('common:user')} {t('common:state')}
              </Typography>
            </Box>

            <Fab variant="close" onClick={() => setOpenModal(false)}>
              <CloseOutlinedIcon />
            </Fab>
          </Box>
          <Box className="modal-box">
            <InputLabel>
              {t('common:select')} {t('common:user')} {t('common:status')}:
            </InputLabel>
            <Autocomplete
              size="small"
              fullWidth
              value={userState}
              onChange={(event, value) => handleStateChange(value)}
              disablePortal
              id="combo-box-demo"
              options={userStateValues}
              renderInput={(params) => <TextField {...params} variant="outlined" placeholder={t('common:status')} />}
            />
          </Box>
          <Box className="">
            <Button
              size="small"
              color="secondary"
              variant="contained"
              fullWidth
              onClick={(e) => handleUpdateBtn(e, userStateValues)}
              disabled={!toUpdate}
              loading={btnLoading}
              loadingPosition="end"
            >
              {t('common:save')}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  )
}

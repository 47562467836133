import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { updateAmlCrypto, updateAmlCryptoDetail } from '../../features/amlCrypto/amlCryptoSlice'

export const useAmlCrypto = (emailClient, setIsLoading) => {
  const amlCrypto = useSelector((state) => state.amlCrypto)
  const queryState = useSelector((state) => state.amlCrypto?.filters)
  const { limit, offset, fromDate, toDate, page, id, protocol, address } = queryState ? queryState : { ...null }
  const { accessToken } = useSelector((state) => state.login)

  const dispatch = useDispatch()

  function buildUrl(baseUrl) {
    let url = `${baseUrl}?limit=${limit}&page=${page}&offset=${offset}`
    if (fromDate && toDate) url += `&fromDate=${fromDate}&toDate=${toDate}`
    if (id?.length) url += `&id=${id}`
    if (protocol?.length) url += `&protocol=${protocol}`
    if (address?.length) url += `&address=${address}`

    return url
  }

  useEffect(() => {
    const baseUrlAmlCrypto = `${process.env.REACT_APP_BASEURL}/amlCrypto/addressChecks/${emailClient}`
    let url = buildUrl(baseUrlAmlCrypto)

    fetch(process.env.REACT_APP_IS_DEMO ? '../data/amlCryptos.json' : url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res) {
          const { data, pagination } = res
          const aux = { data, pagination }
          dispatch(updateAmlCrypto(aux))
          setIsLoading(false)
        }
      })
      .catch((error) => {
        console.error('[HOOK: useAmlCrypto] --> ', error)
        setIsLoading(false)
      })
  }, [queryState])

  return amlCrypto.amlCrypto
}

export const useAmlCryptoDetail = (id, setIsLoading) => {
  const amlCrypto = useSelector((state) => state.amlCrypto)
  const { accessToken } = useSelector((state) => state.login)

  const dispatch = useDispatch()

  useEffect(() => {
    const url = `${process.env.REACT_APP_BASEURL}/amlCrypto/addressCheckById/${id}`

    fetch(process.env.REACT_APP_IS_DEMO ? '../../data/amlCryptoDetails2.json' : url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        //tanto success como no se guarda data
        dispatch(updateAmlCryptoDetail(res.data))
        setIsLoading(false)
      })
      .catch((error) => {
        console.error('[HOOK: useAmlCryptoDetail] --> ', error)
        dispatch(updateAmlCryptoDetail({ statusCode: 404 }))
        setIsLoading(false)
      })
  }, [id])

  return amlCrypto.amlCrypto
}

import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { updategovCheckDetail, updategovChecks } from '../../features/govCheck/govCheckSlice'

export const useGovChecks = (emailClient, setIsLoading) => {
  const govCheck = useSelector((state) => state.govCheck)
  const queryState = useSelector((state) => state.govCheck?.filters)
  const { limit, offset, fromDate, toDate, page, id, type, document } = queryState ? queryState : { ...null }
  const { accessToken } = useSelector((state) => state.login)
  const dispatch = useDispatch()

  function buildUrl(baseUrl) {
    let url = `${baseUrl}?limit=${limit}&page=${page}&offset=${offset}`
    if (fromDate && toDate) url += `&fromDate=${fromDate}&toDate=${toDate}`
    if (type) url += `&type=${type}`
    if (id) url += `&id=${id}`
    if (document) url += `&document=${document}`
    return url
  }

  useEffect(() => {
    const baseUrlGovCheck = `${process.env.REACT_APP_BASEURL}/govCheck/getAllGovChecks/${emailClient}`
    let url = buildUrl(baseUrlGovCheck)

    fetch(process.env.REACT_APP_IS_DEMO ? '../data/govChecks.json' : url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res) {
          const { data, pagination } = res
          const aux = { data, pagination }
          dispatch(updategovChecks(aux))
          setIsLoading(false)
        }
      })
      .catch((error) => {
        console.error('[HOOK: useGovChecks] --> ', error)
        setIsLoading(false)
      })
  }, [queryState])

  return govCheck.govChecks
}

export const useGovCheckDetail = (id, setIsLoading) => {
  const govCheck = useSelector((state) => state.govCheck)
  const { accessToken } = useSelector((state) => state.login)

  const dispatch = useDispatch()

  useEffect(() => {
    const url = `${process.env.REACT_APP_BASEURL}/govCheck/govCheckById/${id}`

    fetch(process.env.REACT_APP_IS_DEMO ? '../data/govCheckDetail.json' : url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        dispatch(updategovCheckDetail(res.data))
        setIsLoading(false)
      })
      .catch((error) => {
        console.error('[HOOK: useGovCheckDetail] --> ', error)
        dispatch(updategovCheckDetail({ statusCode: 404 }))
        setIsLoading(false)
      })
  }, [id])

  return govCheck.govCheckDetail
}

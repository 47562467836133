import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import React, { useCallback, useEffect, useState } from 'react'
import {
  Switch,
  Fab,
  Box,
  Button,
  Paper,
  Typography,
  Accordion,
  AccordionSummary,
  InputLabel,
  AccordionDetails,
} from '@mui/material'
import { ContentCopyOutlined } from '@mui/icons-material'
import Spinner from '../../components/common/spinner/spinner'
import { KeyboardDoubleArrowDown, KeyboardDoubleArrowUp } from '@mui/icons-material'
import { useGovCheckDetail } from '../pages-hooks/useGovChecks'
import { useTranslation } from 'react-i18next'
import { camelCaseFormatter } from '../../components/utilities/formatters/camelCase'
import { TableChip } from '../../components/utilities/TableChip'
import { buildTimeStringFromTimestamp } from '../../components/common/time/timeHelper'

const GovCheckDetail = () => {
  const { id } = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const [isFetching] = useState(false)
  const [expanded, setExpanded] = useState([])
  const govCheckDetail = useSelector((state) => state.govCheck.govCheckDetail)
  useGovCheckDetail(id, setIsLoading)
  const { t } = useTranslation(['common', 'messages'])

  function customSort(a, b) {
    const isAString = typeof a[1] === 'string'
    const isBString = typeof b[1] === 'string'

    if (isAString === isBString) {
      return a[0].localeCompare(b[0])
    }

    if (isAString && !isBString) {
      return -1
    }

    if (!isAString && isBString) {
      return 1
    }
  }

  const handleChange = (index) => {
    setExpanded((prevExpanded) => {
      const newExpanded = [...prevExpanded]
      newExpanded[index] = !newExpanded[index]
      return newExpanded
    })
  }

  const renderField = (key, value) => {
    if (value === null || value === undefined) {
      return (
        <Box className="govcheck-detail-input" key={key}>
          <InputLabel>{key}:</InputLabel>
          <Typography variant="subtitle3">N/A</Typography>
        </Box>
      )
    }

    switch (typeof value) {
      case 'string':
      case 'number':
        return (
          <Box className="govcheck-detail-input" key={`${key} ${value}`}>
            <InputLabel>{key}:</InputLabel>
            <Typography variant="subtitle3">{value || 'N/A'}</Typography>
          </Box>
        )
      case 'object':
        if (Array.isArray(value)) {
          return value.map((item, idx) => (
            <Box className="govcheck-manual-input" key={`${key}-${idx}`}>
              {idx + 1}
              {Object.entries(item).map(([k, v], idx2) => renderField(k, v))}
            </Box>
          ))
        } else {
          return Object.entries(value).map(([k, v]) => renderField(k, v))
        }
      default:
        return (
          <Box className="govcheck-detail-input" key={key}>
            <InputLabel>{key}:</InputLabel>
            <Typography variant="subtitle3">N/A</Typography>
          </Box>
        )
    }
  }

  const renderObject = useCallback((index, key, value) => {
    return value ? (
      renderField(key, value)
    ) : (
      <Typography key={key} variant="subtitle3">
        N/A
      </Typography>
    )
  }, [])

  const setAllEqual = () => {
    const allExpanded = expanded.every((e) => e)
    const newExpanded = expanded.map(() => !allExpanded)
    setExpanded(newExpanded)
  }

  useEffect(() => {
    if (govCheckDetail && govCheckDetail?.details?.data) {
      const initialExpanded = Object.entries(govCheckDetail?.details?.data).map(() => false)
      setExpanded(initialExpanded)
    }
  }, [govCheckDetail])

  return (
    <Box className="datatable">
      {isLoading && <Spinner noTransform />}
      <Box className="wrapper">
        {!isLoading && (
          <Paper elevation={0}>
            <Box className="check-head">
              <Box className="check-title">
                <Typography variant="title">{t('common:check')}</Typography>
              </Box>
            </Box>
            {!govCheckDetail && (
              <Box className="error-msg">
                <Button size="small" variant="outlined" color="error">
                  {t('messages:checkNotFound')}
                </Button>
              </Box>
            )}

            {govCheckDetail && !govCheckDetail.statusCode && (
              <Box className="check-body">
                <Box className="check-body-item">
                  <Box className="govcheck-detail-grid-1">
                    <Paper elevation={0} variant="colorPrimary">
                      <Box className="govcheck-detail-id">
                        <Box className="govcheck-detail-box">
                          <Typography variant="subtitle1">ID</Typography>
                          <Typography variant="number">{id}</Typography>
                        </Box>

                        <Fab
                          size="small"
                          className="icon-global"
                          variant="action"
                          onClick={() => {
                            navigator.clipboard.writeText(id)
                          }}
                        >
                          <ContentCopyOutlined />
                        </Fab>
                      </Box>
                    </Paper>

                    <Paper elevation={0} variant="colorPrimary">
                      <Box className="govcheck-detail-id">
                        <Box className="govcheck-detail-box">
                          <Typography variant="subtitle1">{t('common:type')}</Typography>
                          <Typography variant="subtitle4">{govCheckDetail?.type ?? '---'}</Typography>
                        </Box>
                        <Fab
                          size="small"
                          className="icon-global"
                          variant="action"
                          onClick={() => {
                            navigator.clipboard.writeText(govCheckDetail?.type)
                          }}
                        >
                          <ContentCopyOutlined />
                        </Fab>
                      </Box>
                    </Paper>

                    <Paper elevation={0} variant="colorPrimary">
                      <Box className="govcheck-detail-id">
                        <Box className="govcheck-detail-box">
                          <Typography variant="subtitle1">{t('common:validation')}</Typography>
                          <Typography variant="subtitle4">
                            {govCheckDetail.details.isValid ? (
                              <TableChip action="valid" />
                            ) : (
                              <TableChip action="invalid" />
                            )}
                          </Typography>
                        </Box>
                      </Box>
                    </Paper>
                  </Box>
                  <Box className="govcheck-detail-grid-2">
                    <Paper elevation={0} variant="colorPrimary">
                      <Box className="govcheck-detail-id">
                        <Box className="govcheck-detail-box">
                          <Typography variant="subtitle1">{t('common:document')}</Typography>
                          <Typography variant="subtitle4">{govCheckDetail.details.document}</Typography>
                        </Box>
                      </Box>
                    </Paper>
                    <Paper elevation={0} variant="colorPrimary">
                      <Box className="govcheck-detail-id">
                        <Box className="govcheck-detail-box">
                          <Typography variant="subtitle1">{t('common:createdAt')}</Typography>
                          <Typography variant="number">
                            {buildTimeStringFromTimestamp(govCheckDetail.createdAt)}
                          </Typography>
                        </Box>
                      </Box>
                    </Paper>
                  </Box>
                </Box>
              </Box>
            )}
          </Paper>
        )}
        <Paper elevation={0}>
          <Box className="govcheck-detail-id" sx={{ marginBottom: '1rem' }}>
            <Typography variant="title">{t('common:response')}</Typography>

            <Box>
              {govCheckDetail &&
              govCheckDetail?.details?.data &&
              Object.keys(govCheckDetail?.details?.data).length > 0 ? (
                <Button variant="outlined" onClick={() => setAllEqual()}>
                  {expanded.includes(true) ? t('common:hideAll') : t('common:expandAll')}
                </Button>
              ) : (
                <Typography variant="subtitle3">{t('common:noHitsToShow')}</Typography>
              )}
            </Box>
          </Box>
          {govCheckDetail &&
            govCheckDetail?.details?.data &&
            Object.entries(govCheckDetail?.details?.data)
              .sort((key, value) => customSort(key, value))
              .map((item, index) => {
                const [key, value] = item
                const isExpanded = expanded[index] ?? false

                return !(typeof value === 'string' || typeof value === 'number') ? (
                  <Box className="govcheck-detail-wrapper">
                    <Accordion
                      variant="medium"
                      key={`${value} ${key}`}
                      expanded={isExpanded}
                      onChange={() => handleChange(index)}
                      style={{ marginBottom: '1.5rem' }}
                    >
                      <AccordionSummary expandIcon={<KeyboardDoubleArrowDown />}>
                        <Box className="govcheck-detail-accordion-wrapper-header">
                          <Box className="govcheck-detail-input-header">
                            <InputLabel>{camelCaseFormatter(key)}</InputLabel>
                          </Box>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Paper elevation={0} className="govcheck-detail-accordion-wrapper">
                          {typeof value === 'string' ? (
                            <Typography variant="subtitle3">{value === '' ? 'N/A' : value}</Typography>
                          ) : (
                            renderObject(index, key, value)
                          )}
                        </Paper>
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                ) : (
                  <Box className="govcheck-detail-string-response" key={`${key}-${index}`}>
                    <Typography variant="title2">{key}:</Typography>
                    <Typography variant="subtitle3">{value}</Typography>
                  </Box>
                )
              })}
        </Paper>
      </Box>
    </Box>
  )
}

export default GovCheckDetail

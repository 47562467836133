import './datatable.scss'
import { useSelector } from 'react-redux'
import { Box, Link, Typography } from '@mui/material'
import { useOnboardings } from '../../../pages/pages-hooks/useOnboardings'
import { buildTimeStringFromTimestamp } from '../time/timeHelper'
import { TableChip } from '../../utilities/TableChip'
import { useTranslation } from 'react-i18next'
import { DataGridComponent } from '../datagrid/DataGridComponent'

const DatatableOnboardings = ({ page, setPage, rowsPerPage, setRowsPerPage, setIsLoading, setModalOpen }) => {
  const { emailClient } = useSelector((state) => state.login)
  const onboardings = useOnboardings(emailClient, setIsLoading)
  const { t } = useTranslation(['common'])

  const columns = [
    {
      field: 'checkId',
      headerName: 'ID',
      renderCell: (params) => {
        return (
          <Box>
            {params.row.outcome !== 'pending' ? (
              <Link href={`/onboardings/${params.row.checkId}`}>
                <Typography variant="number">{params.row.checkId}</Typography>
              </Link>
            ) : (
              <Box>{params.row.checkId}</Box>
            )}
          </Box>
        )
      },
    },
    {
      field: 'type',
      headerName: t('common:type'),
      renderCell: (params) => {
        return <TableChip action={params.row.type} noIcon={true} />
      },
    },
    {
      field: 'entityName',
      headerName: t('common:name'),
    },
    {
      field: 'outcome',
      headerName: t('common:outcome'),
      renderCell: (params) => {
        return (
          <TableChip
            action={
              params.row.outcome === 'clear' ? 'approved' : params.row.outcome === 'pending' ? 'pending' : 'attention'
            }
            noIcon={true}
          />
        )
      },
    },
    {
      field: 'createdAt',
      headerName: t('common:creationDate'),
      renderCell: (params) => {
        return <Typography variant="number">{buildTimeStringFromTimestamp(params.row.createdAt)}</Typography>
      },
    },
  ]

  return (
    <DataGridComponent
      rows={Array.isArray(onboardings?.data) && onboardings?.data?.length && onboardings?.data}
      columns={columns}
      count={onboardings?.pagination?.totalElements || 0}
      page={page}
      rowsPerPage={rowsPerPage}
      setPage={setPage}
      setRowsPerPage={setRowsPerPage}
      setIsLoading={setIsLoading}
      setModalOpen={setModalOpen}
    />
  )
}

export default DatatableOnboardings

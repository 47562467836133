import './listOfCheckIds.scss'
import Select from '@mui/material/Select'
import OutlinedInput from '@mui/material/OutlinedInput'
import MenuItem from '@mui/material/MenuItem'
import { useState } from 'react'
import { Box } from '@mui/material'
import { useSelector } from 'react-redux'

const ListOfCheckIds = ({ checklist, setChecklist, isEditing, myRule }) => {
  const { activeServices } = useSelector((state) => state.login)
  const checkListsState = useSelector((state) => state.checklists.checklists?.data)
  const [selectedCheckList, setSelectedCheckList] = useState(checklist)

  const renderChecklistActions = (key, value) => {
    return value && Array.isArray(value) && value.length > 0 ? (
      <Box>
        {value.map((el, idx) => {
          return (
            <Box key={`${key}-${idx}`} className="ch-item">
              <strong>{idx + 1}:</strong> {el}
            </Box>
          )
        })}
      </Box>
    ) : (
      <span>No actions</span>
    )
  }

  const verifyRuleCreationWithChekclist = () => {
    let aux = false
    activeServices?.map((path) => {
      if (path.includes('checklist')) aux = true
      return aux
    })
    return aux
  }

  const verifyRuleUpdateWithChekclist = () => {
    let aux = false
    activeServices?.map((path) => {
      if (path.includes('checklist')) aux = true
      return aux
    })
    return aux
  }

  const handleChange = (evt) => {
    const {
      target: { value },
    } = evt
    if (checkListsState) {
      const selectedCL = checkListsState.filter((cl) => cl.id === value.id)
      if (selectedCL && selectedCL?.length > 0) {
        setSelectedCheckList(selectedCL[0])
        setChecklist(selectedCL[0])
      }
    }
  }

  return (
    <Box>
      <Select
        size="small"
        displayEmpty
        fullWidth
        disabled={!isEditing || !(myRule ? verifyRuleUpdateWithChekclist() : verifyRuleCreationWithChekclist())}
        value={selectedCheckList?.title ? selectedCheckList : ''}
        onChange={handleChange}
        input={<OutlinedInput />}
        renderValue={(selectedCheckList) => {
          return selectedCheckList?.title ? `${selectedCheckList.title} - ${selectedCheckList.id}` : ''
        }}
        inputProps={{ 'aria-label': 'Without label' }}
      >
        {checkListsState &&
          Array.isArray(checkListsState) &&
          checkListsState?.map((item, index) => (
            <MenuItem key={index} value={item}>
              {`${item.title}(${item.id})`}
            </MenuItem>
          ))}
      </Select>

      {selectedCheckList?.title && (
        <Box className="drawer-block">
          <Box>
            <strong>Title: </strong>
            <span>{selectedCheckList.title}</span>
          </Box>
          <Box>
            <strong>Description: </strong>
            {selectedCheckList.description && selectedCheckList.description.trim() !== '' ? (
              <span>{selectedCheckList.description}</span>
            ) : (
              <span>Not defined</span>
            )}
          </Box>
          <Box>
            <strong>Actions: </strong>
            {renderChecklistActions('actions', selectedCheckList.actions)}
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default ListOfCheckIds

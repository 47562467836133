import './toRender.scss'
import { Box, Typography } from '@mui/material'
import { camelCaseFormatter } from '../../utilities/formatters/camelCase'

export const renderTags = (actualUser) => {
  if (actualUser && actualUser.tags && Array.isArray(actualUser.tags) && actualUser.tags.length !== 0) {
    return (
      <Box>
        {actualUser.tags?.map((tag, index) => {
          return (
            <Box className="tags-flex" key={index}>
              <Typography variant="h6">{camelCaseFormatter(tag.key)}: </Typography> {tag.value}
            </Box>
          )
        })}
      </Box>
    )
  } else if (!actualUser.tags || actualUser.tags.length === 0) {
    return (
      <Box className="tags">
        <Typography className="tags">---</Typography>
      </Box>
    )
  }
}

import { Box, Button, Modal } from '@mui/material'
import { useTranslation } from 'react-i18next'
import ModalTitle from '../common/Modal/ModalTitle'

export const FilterModal = ({ children, modalOpen, setModalOpen, handleResetButton, setPage, handleDispatch }) => {
  const { t } = useTranslation(['common'])

  return (
    <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
      <Box className="modal">
        <ModalTitle setModalOpen={setModalOpen} title={t('common:filters')} />

        {children}

        <Box className="modal-filter-buttons">
          <Button variant="outlinedGrey" size="small" onClick={() => handleResetButton()}>
            {t('common:clearAll')}
          </Button>

          <Button
            variant="contained"
            size="small"
            onClick={() => {
              setPage(0)
              handleDispatch()
              return setModalOpen(false)
            }}
          >
            {t('common:apply')}
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

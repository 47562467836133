import './consumptions.scss'
import {
  Box,
  Button,
  Divider,
  IconButton,
  Input,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import Spinner from '../../../components/common/spinner/spinner'
import ManageSearchIcon from '@mui/icons-material/ManageSearch'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

const Consumptions = () => {
  const { accessToken } = useSelector((state) => state.login)
  const today = new Date()
  const month = today.getMonth()
  const [startDate, setStartDate] = useState(today.setMonth(month - 1))
  const [endDate, setEndDate] = useState(new Date())
  const [open, setOpen] = useState(false)
  const [email, setEmail] = useState('')
  const [selected, setSelected] = useState('')
  const [consumptions, setConsumptions] = useState([])
  const [txsTimes, setTxsTimes] = useState([])
  const [detailedConsumptions, setDetailedConsumptions] = useState([])
  const [detailedConsumption, setDetailedConsumption] = useState([])
  const [successMap, setSuccessMap] = useState({})
  const [errorMap, setErrorMap] = useState({})
  const [successConsumptionCount, setSuccessConsumptionCount] = useState(0)
  const [successConsumptionCountFree, setSuccessConsumptionCountFree] = useState(0)
  const [errorConsumptionCount, setErrorConsumptionCount] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const { t } = useTranslation(['common', 'messages'])

  const consumptionsToRender = (data) => {
    return data?.map(({ email, consumptions }) => {
      const { count, successConsumptions, errorConsumptions } = consumptions

      const success = Object.values(successConsumptions)
        .flatMap(Object.values)
        .reduce((sum, val) => sum + val, 0)

      const error = Object.values(errorConsumptions)
        .flatMap(Object.values)
        .reduce((sum, val) => sum + val, 0)

      return { email, total: count, success, error }
    })
  }

  const handleSearch = () => {
    const initial = startDate ? new Date(startDate).toLocaleDateString('sv-SE') : ''
    const final = endDate ? new Date(endDate).toLocaleDateString('sv-SE') : ''

    try {
      setIsLoading(true)
      let urlConsumption = `${process.env.REACT_APP_BASEURL}/client/allClientConsumptionsBetweenDates?initial=${initial}&final=${final}`
      if (email && email !== '') urlConsumption += `&email=${email}`

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      }

      fetch(process.env.REACT_APP_IS_DEMO ? '../data/clientConsumptions.json' : urlConsumption, options)
        .then((res) => res.json())
        .then((res) => {
          if (res.data) {
            const consumptions = consumptionsToRender(res.data.mappedClientConsumptions)
            setConsumptions(consumptions)
            setTxsTimes(res.data.txsTimes)
            setDetailedConsumptions(res.data.mappedClientConsumptions)
            setIsLoading(false)
          }
        })
        .catch((error) => {
          console.error('[GET CONSUMPTIONS ERROR] --> ', error)
          setErrorMessage(error.message ? error.message : 'Get consumptions error')
          setIsLoading(false)
        })
    } catch (error) {
      if (error.response) {
        console.error('[GET CONSUMPTIONS ERROR] --> ', error)
      }
    }
  }

  useEffect(() => {
    handleSearch()
  }, [])

  const validateString = (str) => {
    const regex = new RegExp('get|retrieve|update|delete', 'i')
    return !regex.test(str)
  }

  useEffect(() => {
    let successConsumptions = detailedConsumption?.consumptions?.successConsumptions ?? {}
    let errorConsumptions = detailedConsumption?.consumptions?.errorConsumptions ?? {}

    let successCount = 0
    let successCountFree = 0
    Object.keys(successConsumptions).forEach((service) => {
      Object.keys(successConsumptions[service]).forEach((subservice) => {
        if (validateString(subservice)) successCount += successConsumptions[service][subservice]
        else successCountFree += successConsumptions[service][subservice]
      })
    })
    setSuccessConsumptionCount(successCount)
    setSuccessConsumptionCountFree(successCountFree)

    let errorCount = 0
    Object.keys(errorConsumptions).forEach((service) => {
      Object.keys(errorConsumptions[service]).forEach((subservice) => {
        if (validateString(subservice)) errorCount += errorConsumptions[service][subservice]
      })
    })
    setErrorConsumptionCount(errorCount)
    handleMap(detailedConsumption?.consumptions?.successConsumptions, true)
    handleMap(detailedConsumption?.consumptions?.errorConsumptions, false)
  }, [detailedConsumption])

  const countByService = (service) => {
    let count = 0
    let countFree = 0
    Object.keys(service).forEach((subservice) => {
      if (validateString(subservice)) count += service[subservice]
      else countFree += service[subservice]
    })

    return `To charge[${count}] - Free [${countFree}]`
  }

  const mapKyt = (kyt) => {
    const mapped = {}
    Object.keys(kyt).forEach((item) => {
      if (item === 'createConsumerUser') mapped['Consumers'] = kyt[item]
      if (item === 'createConsumerUserEvent') mapped['ConsumersUpdate'] = kyt[item]
      else if (item === 'createBusinessUser') mapped['Busiensses'] = kyt[item]
      else if (item === 'createBusinessUserEvent') mapped['BusienssesUpdate'] = kyt[item]
      else if (item === 'verifyTransaction') mapped['Transactions'] = kyt[item]
      else if (item === 'createTransactionEvent') mapped['TransactionsUpdate'] = kyt[item]
      else if (item === 'createClientInstanceRule') mapped['Rules'] = kyt[item]
      else if (item === 'updateClientInstanceRule') mapped['RulesUpdates'] = kyt[item]
      else if (item.includes('retrieve') && !mapped['FrontUsage']) mapped['FrontUsage'] = kyt[item]
      else if (item.includes('retrieve') && mapped['FrontUsage'])
        mapped['FrontUsage'] = mapped['FrontUsage'] + kyt[item]
    })
    return mapped
  }

  const mapKyb = (kyb) => {
    const mapped = {}
    Object.keys(kyb).forEach((item) => {
      if (item === 'createKyb') mapped['KYBs'] = kyb[item]
      else if (item === 'updateKybById' || item === 'updateKybRiskLvlById') mapped['UpdateKYBs'] = kyb[item]
      else if (item === 'deleteKyb') mapped['DeleteKYBs'] = kyb[item]
      else if (item === 'restartKybById') mapped['RestartKYBs'] = kyb[item]
      else if (item === 'updateCheckById') mapped['UpdateChecks'] = kyb[item]
      else if (item === 'updateCheckById') mapped['UpdateChecks'] = kyb[item]
      else if (item === 'createShareholder') mapped['Shareholder'] = kyb[item]
      else if (item === 'updateShareholderById') mapped['UpdateShareholder'] = kyb[item]
      else if (item === 'createKybComment') mapped['KYBComment'] = kyb[item]
      else if (item === 'addKybFile') mapped['KYBFile'] = kyb[item]
      else if (item === 'deleteKybFile') mapped['DeleteKYBFile'] = kyb[item]
      else if (item === 'createShareholderComment') mapped['ShareholderComment'] = kyb[item]
      else if (item === 'addShareholderFile') mapped['ShareholderFile'] = kyb[item]
      else if (item === 'deleteShareholderFile') mapped['DeleteShareholderFile'] = kyb[item]
      else if (item === 'updateNotificationsById') mapped['UpdateNotifications'] = kyb[item]
      else if (item === 'updateList') mapped['UpdateList'] = kyb[item]
      else if (item === 'updateList') mapped['UpdateList'] = kyb[item]
      else if (item.includes('get') && !mapped['FrontUsage']) mapped['FrontUsage'] = kyb[item]
      else if (item.includes('get') && mapped['FrontUsage']) mapped['FrontUsage'] = mapped['FrontUsage'] + kyb[item]
      else if (!mapped['Checks']) mapped['Checks'] = kyb[item]
      else if (mapped['Checks']) mapped['Checks'] = mapped['Checks'] + kyb[item]
    })
    return mapped
  }

  const mapKyc = (kyc) => {
    const mapped = {}
    Object.keys(kyc).forEach((item) => {
      if ((item === 'identityCheck' || item === 'identityCheckJson') && !mapped['BiometricChecks'])
        mapped['BiometricChecks'] = kyc[item]
      else if ((item === 'identityCheck' || item === 'identityCheckJson') && mapped['BiometricChecks'])
        mapped['BiometricChecks'] = mapped['BiometricChecks'] + kyc[item]
      else if ((item === 'documentCheck' || item === 'documentCheckJson') && !mapped['DocumentChecks'])
        mapped['DocumentChecks'] = kyc[item]
      else if ((item === 'documentCheck' || item === 'documentCheckJson') && mapped['DocumentChecks'])
        mapped['DocumentChecks'] = mapped['DocumentChecks'] + kyc[item]
      else if (item === 'identityAndDocumentCheck' || item === 'identityAndDocumentCheckJson') {
        if (!mapped['BiometricChecks']) mapped['BiometricChecks'] = kyc[item]
        else mapped['BiometricChecks'] = mapped['BiometricChecks'] + kyc[item]
        if (!mapped['DocumentChecks']) mapped['DocumentChecks'] = kyc[item]
        else mapped['DocumentChecks'] = mapped['DocumentChecks'] + kyc[item]
      } else mapped['FrontUsage'] = kyc[item]
    })
    return mapped
  }

  const handleMap = (data, isSuccess) => {
    let final = {}
    Object.keys(data ?? {}).forEach((key) => {
      switch (key) {
        case 'kyt':
          final[key] = mapKyt(data[key])
          break

        case 'kyb':
          final[key] = mapKyb(data[key])
          break

        case 'onboarding':
          final[key] = mapKyc(data[key])
          break

        default:
          final[key] = data[key]
          break
      }
    })

    if (isSuccess) setSuccessMap(final)
    else setErrorMap(final)
  }

  return (
    <Box className={`${isLoading && 'spinner-transition'} filter`}>
      <Paper elevation={0} className="consumptions-flex">
        <LocalizationProvider size="small" dateAdapter={AdapterDayjs}>
          <DatePicker
            size="small"
            renderInput={(params) => <TextField size="small" {...params} />}
            value={dayjs(startDate)}
            onChange={(date) => setStartDate(date ? date['$d'] : null)}
          />
          <DatePicker
            size="small"
            renderInput={(params) => <TextField size="small" {...params} />}
            value={dayjs(endDate)}
            onChange={(date) => setEndDate(date ? date['$d'] : null)}
          />
        </LocalizationProvider>
        <TextField
          variant="outlined"
          id="email"
          placeholder="Email (optional)"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button
          color="secondary"
          size="small"
          disabled={isNaN(startDate) || isNaN(endDate)}
          onClick={handleSearch}
          variant="outlined"
          endIcon={<ManageSearchIcon />}
        >
          {t('common:search')}
        </Button>
        {errorMessage ? (
          <Button size="small" variant="outlined" color="error">
            {errorMessage}
          </Button>
        ) : (
          ''
        )}
      </Paper>

      {isLoading ? <Spinner noTransform /> : ''}

      {!isLoading && consumptions?.length ? (
        <Paper elevation={0}>
          <Box sx={{ overflow: 'auto', maxHeight: '35rem' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t('common:email')}</TableCell>
                  <TableCell>
                    {t('common:total')} {t('common:consumptions')}
                  </TableCell>
                  <TableCell>{t('common:success')}</TableCell>
                  <TableCell>{t('common:error')}</TableCell>
                  <TableCell>{t('common:details')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {consumptions.map((row) => {
                  return (
                    <TableRow key={row.email}>
                      <TableCell>{row.email}</TableCell>
                      <TableCell>{row.total}</TableCell>
                      <TableCell>{row.success}</TableCell>
                      <TableCell>{row.error}</TableCell>
                      <TableCell>
                        <Box style={{ display: 'flex', gap: '.5rem', alignItems: 'center' }}>
                          <IconButton
                            onClick={() => {
                              setSelected(row.email)
                              setDetailedConsumption(detailedConsumptions.find((c) => c.email === row.email))
                              setOpen(true)
                            }}
                            style={{ color: '#0DDE4E' }}
                          >
                            <VisibilityIcon style={{ height: '1.45rem' }} />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </Box>
        </Paper>
      ) : (
        ''
      )}

      <br />
      {!isLoading && Object.keys(txsTimes)?.length ? (
        <Paper elevation={0}>
          <p>Aerage transactions times for {email} in seconds</p>
          <Box sx={{ overflow: 'auto', maxHeight: '35rem' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t('common:provider')}</TableCell>
                  <TableCell>{t('common:gueno')}</TableCell>
                  <TableCell>{t('common:total')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{txsTimes.avgProvider}</TableCell>
                  <TableCell>{txsTimes.avgGuenoService}</TableCell>
                  <TableCell>{txsTimes.avgComplete}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </Paper>
      ) : (
        ''
      )}

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="consumption-detail-modal"
      >
        <Box className="consumption-detail">
          <Typography className="detail-title" id="modal-modal-title" variant="h6" component="h2">
            <Box className="details-service">{t('common:user')}: </Box>
            {selected}
          </Typography>

          <Divider style={{ margin: '.6rem 0rem 1.6rem 0rem' }} />

          <Box>
            {detailedConsumption && selected && (
              <Box>
                <Box className="details">
                  <h3>
                    Success Consumptions: To charge[{successConsumptionCount}] - Free [{successConsumptionCountFree}]
                  </h3>
                  <Box>
                    {Object.entries(successMap).map(([key, value]) => (
                      <Box key={key}>
                        <Box className="margin-bottom-small details-service">
                          {key.toUpperCase()}: {countByService(value)} - contract:{' '}
                          {detailedConsumption?.contract?.find((item) => item.service === key)
                            ? detailedConsumption.contract.find((item) => item.service === key)?.limit === 1
                              ? 'Pay as you go'
                              : detailedConsumption.contract.find((item) => item.service === key)?.limit
                            : '---'}
                        </Box>
                        <Box className="margin-bottom">
                          {Object.entries(value)
                            .sort((a, b) => a[0].localeCompare(b[0]))
                            .map(([subKey, subValue]) => (
                              <Box key={subKey} className={validateString(subKey) ? 'key-bold' : ''}>
                                {subKey}: {subValue}
                              </Box>
                            ))}
                        </Box>
                      </Box>
                    ))}
                  </Box>

                  <Divider style={{ margin: '.4rem 0rem 1.4rem 0rem' }} />

                  <h3>Error Consumptions: {errorConsumptionCount}</h3>
                  {Object.entries(errorMap).map(([key, value]) => (
                    <Box key={key}>
                      <Box className="margin-bottom-small details-service">{key.toUpperCase()}</Box>
                      <Box>
                        {Object.entries(value).map(([subKey, subValue]) => (
                          <Box key={subKey}>
                            {subKey}: {subValue}
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}

export default Consumptions

import './datatable.scss'
import { useState } from 'react'
import { Box, Fab, Link, Modal, TableContainer, Typography } from '@mui/material'
import { buildTimeStringFromTimestamp } from '../time/timeHelper'
import { DataGrid } from '@mui/x-data-grid'
import { TableChip } from '../../utilities/TableChip'
import { useTranslation } from 'react-i18next'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'

const DatatableUserReports = ({ rows }) => {
  const { t } = useTranslation(['common'])
  const [selectedReport, setSelectedReport] = useState('')
  const [modalReport, setOpenModalReport] = useState(false)

  const columns = [
    {
      field: 'email',
      headerName: t('common:user'),
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return <Typography variant="number">{params.row.email}</Typography>
      },
    },
    {
      field: 'status',
      headerName: t('common:status'),
      flex: 1,
      minWidth: 170,
      renderCell: (params) => {
        return <TableChip noIcon={true} action={params.row.closed ? 'CLOSED' : 'OPEN'} />
      },
    },
    {
      field: 'createdAt',
      headerName: t('common:createdAt'),
      flex: 1,
      minWidth: 134,
      renderCell: (params) => {
        return buildTimeStringFromTimestamp(params.row.createdAt)
      },
    },
    {
      field: 'updatedAt',
      headerName: t('common:updatedAt'),
      flex: 1,
      minWidth: 134,
      renderCell: (params) => {
        return buildTimeStringFromTimestamp(params.row.updatedAt)
      },
    },
    {
      field: 'actions',
      headerName: t('common:viewDetails'),
      flex: 1,
      minWidth: 134,
      renderCell: (params) => {
        return (
          <Link
            onClick={() => {
              setSelectedReport(params.row)
              return setOpenModalReport(true)
            }}
          >
            {t('common:viewDetails')}
          </Link>
        )
      },
    },
  ]

  return (
    <TableContainer>
      <DataGrid
        getRowId={(row) => row._id || row.id}
        rows={rows && Array.isArray(rows) ? rows : []}
        columns={columns}
        hideFooter={true}
        autoHeight={true}
      />

      <Modal
        open={modalReport}
        onClose={() => {
          setSelectedReport('')
          return setOpenModalReport(false)
        }}
      >
        <Box className="modal">
          <Box className="modal-top">
            <Box className="modal-titles">
              <Typography variant="title">
                {t('common:report')}{' '}
                {selectedReport.updatedAt ? buildTimeStringFromTimestamp(selectedReport.updatedAt) : '---'}
              </Typography>
            </Box>
            <Fab
              variant="close"
              onClick={() => {
                setSelectedReport('')
                return setOpenModalReport(false)
              }}
            >
              <CloseOutlinedIcon />
            </Fab>
          </Box>

          <Box className="modal-box-filters">
            <Box dangerouslySetInnerHTML={{ __html: selectedReport.description }} />
          </Box>
        </Box>
      </Modal>
    </TableContainer>
  )
}

export default DatatableUserReports

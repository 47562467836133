import { createSlice } from '@reduxjs/toolkit'

export const listsSlice = createSlice({
  name: 'lists',
  initialState: {
    lists: {},
    filters: {
      page: 0,
      limit: 25,
      offset: 0,
      fromDate: null,
      toDate: '',
      id: '',
      type: '',
      status: '',
      list: '',
      context: '',
      title: '',
      description: '',
    },
  },
  reducers: {
    updateLists: (state, action) => {
      state.lists = action.payload
    },
    updateListsFilters: (state, action) => {
      state.filters = action.payload
    },
  },
})

export const { updateListsFilters, updateLists } = listsSlice.actions
export default listsSlice.reducer

import { Box } from '@mui/material'
import './downloadIcon.scss'
import { useTranslation } from 'react-i18next'

export const DownloadIcon = () => {
  const { t } = useTranslation(['common'])
  
  return (
    <Box className="download-report">
      <img className="download-icon" alt="gueno-logo" src="/25-folder.png"></img>
      <p>{t('common:exportList')}</p>
    </Box>
  )
}

import { Box, Button, Checkbox, Modal, Tooltip, Typography } from '@mui/material'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useUpdateKybLastBeneficiariesMutation } from '../../../services/guenoApi'

const CheckLastBeneficiaryModal = ({
  kybId,
  beneficiaryDocumentNumber,
  isLastBeneficiary,
  updateDetail = () => {},
}) => {
  const { t } = useTranslation(['common', 'messages'])
  const [showCheckModal, setShowCheckModal] = useState(false)
  const [selectedShareholder, setSelectedShareholder] = useState(null)
  const [updateKybLastBeneficiaries] = useUpdateKybLastBeneficiariesMutation()

  const handleCheckBeneficiary = useCallback(() => {
    setSelectedShareholder({ kybId, beneficiaryDocumentNumber })
    setShowCheckModal(true)
  }, [kybId, beneficiaryDocumentNumber])

  const handleConfirmCheck = async () => {
    if (selectedShareholder) {
      try {
        if (
          await updateKybLastBeneficiaries({
            kybId: selectedShareholder.kybId,
            documentNumber: selectedShareholder.beneficiaryDocumentNumber,
            operation: isLastBeneficiary ? 'remove' : 'add',
          }).unwrap()
        )
          updateDetail()

        setShowCheckModal(false)
      } catch (error) {
        console.error('Error al eliminar el sujeto:', error)
      }
    }
  }

  return (
    <>
      <Tooltip title={t('common:lastBeneficiary')} arrow>
        <Checkbox onClick={() => handleCheckBeneficiary()} checked={isLastBeneficiary} />
      </Tooltip>
      <Modal open={showCheckModal} onClose={() => setShowCheckModal(!showCheckModal)}>
        <Box className="modal" gap="1.5rem">
          <Typography variant="title">
            {t(`common:${!isLastBeneficiary ? 'CHECK' : 'UNCHECK'}_lastBeneficiary_KYB_MODAL_TITLE`)}
          </Typography>
          <Box display="flex" justifyContent="space-evenly" mt="1.5rem">
            <Button variant="outlined" color="secondary" onClick={() => setShowCheckModal(!showCheckModal)}>
              {t('common:cancel')}
            </Button>
            <Button variant="contained" color="primary" onClick={handleConfirmCheck}>
              {t(`common:${!isLastBeneficiary ? 'add' : 'remove'}`)}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  )
}

export default CheckLastBeneficiaryModal

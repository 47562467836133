import { buildTimeStringFromTimestamp } from '../../time/timeHelper'

export const blockedUserReportDataMapperForPDF = (data) => {
  const header = ['Action Owner', 'Affected User', 'Status', 'Activity', 'Date']
  const parsedData = data.map((report) => {
    return [
      report.emailUser,
      report.activityDetails.userCredentials,
      report.activityDetails.actionResult,
      report.activityDetails.actionActivity,
      buildTimeStringFromTimestamp(report.createdAt),
    ]
  })

  return {
    columns: header,
    body: parsedData,
  }
}

export const blockedUserReportDataMapper = (data) => {
  return data.map((report) => {
    return {
      ActionOwner: report.emailUser,
      AffectedUser: report.activityDetails.userCredentials,
      Status: report.activityDetails.actionResult,
      Activity: report.activityDetails.actionActivity,
      Date: buildTimeStringFromTimestamp(report.createdAt),
    }
  })
}

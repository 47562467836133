export const TX_TYPES = ['DEPOSIT', 'TRANSFER', 'EXTERNAL_PAYMENT', 'WITHDRAWAL', 'REFUND']
export const TX_STATES = ['CREATED', 'PROCESSING', 'SENT', 'EXPIRED', 'DECLINED', 'SUSPENDED', 'REFUNDED', 'SUCCESSFUL']
export const FILTER_MODE = ['CASHIN', 'CASHOUT']
export const IDS_BY_PAYMENTMETHOD = {
  NOT_DEFINED: '',
  ACH: 'accountNumber',
  CBU: 'CBU',
  CVU: 'CVU',
  ECHECK: 'checkNumber',
  DEBIN: 'authorizationCode',
  QRCODE: 'qrCode',
  ONLINE_PAYMENT: 'accountNumber',
  CARD: 'cardFingerprint',
  CHECK: 'checkNumber',
  GENERIC_BANK_ACCOUNT: 'accountNumber',
  IBAN: 'IBAN',
  MPESA: 'businessShortCode',
  SWIFT: 'accountNumber',
  UPI: 'upiID',
  WALLET: 'walletID',
}
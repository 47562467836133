import './listOfStrings.scss'
import { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { Box, Fab, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined'
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined'

const ListOfStrings = ({
  propertie,
  ruleParameters,
  setRuleParameters,
  defaultListValues,
  setError,
  errorList,
  setErrorList,
  isEditing,
  caseAdminPanelReload,
}) => {
  const [updatedList, setUpdatedList] = useState([])
  const [change, setChange] = useState(false)
  const { t } = useTranslation(['common'])

  useEffect(() => {
    if (ruleParameters[propertie] && ((updatedList.length === 0 && !change) || caseAdminPanelReload)) {
      setUpdatedList([...ruleParameters[propertie]])
    } else if (defaultListValues && updatedList.length === 0) {
      setUpdatedList([...defaultListValues])
    }
  }, [ruleParameters, propertie])

  const handleCurrenciesChange = (currency, index) => {
    let aux = []
    updatedList.forEach((element) => {
      aux.push(element)
    })
    aux[index] = currency

    setUpdatedList(aux)
    setChange(true)
  }

  const habdleMoovement = (up, index) => {
    let aux = []
    updatedList.forEach((element) => {
      aux.push(element)
    })
    let aux1 = updatedList[index]
    let aux2
    if (up) {
      aux2 = index - 1
    } else {
      aux2 = index + 1
    }
    aux[index] = aux[aux2]
    aux[aux2] = aux1
    setUpdatedList(aux)
    setChange(true)
  }

  const handleServiceRemove = (index) => {
    const list = [...updatedList]
    list.splice(index, 1)
    setUpdatedList(list)
    setChange(true)
  }

  const handleServiceAdd = () => {
    setUpdatedList([...updatedList, ''])
    setChange(true)
  }

  useEffect(() => {
    let list = []
    updatedList.forEach((item, index) => {
      if (item === '') {
        let aux = `Position ${index} should not be empty.`
        if (!list.includes(aux)) {
          list.push(aux)
        }
      }
    })

    let all = errorList
    if (list.length !== 0) {
      all[propertie] = list
      setErrorList({ ...all })
      setError(true)
    } else {
      delete all[propertie]
      setErrorList({ ...all })
      if (Object.keys(all).length === 0) setError(false)
    }
  }, [updatedList])

  useEffect(() => {
    if (change) {
      let all = { ...ruleParameters }
      if (updatedList.length === 0) delete all[propertie]
      else all[propertie] = updatedList
      setRuleParameters({ ...all })
      setChange(false)
    }
  }, [updatedList, change])

  return (
    <Box className="list-strings-container">
      {updatedList.length !== 0 &&
        updatedList.map((item, index) => (
          <Box className="list-row" key={index}>
            <TextField
              fullWidth
              type="text"
              disabled={!isEditing}
              variant="outlined"
              size="small"
              value={item}
              onChange={(event) => handleCurrenciesChange(event.target.value, index)}
            />
            {isEditing && (
              <Box className="buttons">
                {index === 0 && updatedList.length > 1 && (
                  <Fab variant="close" className="icon-fab" disabled>
                    <ExpandLessOutlinedIcon />
                  </Fab>
                )}
                {index !== 0 && updatedList.length > 1 && (
                  <Fab variant="close" className="icon-fab" onClick={() => habdleMoovement(true, index)}>
                    <ExpandLessOutlinedIcon />
                  </Fab>
                )}
                {index !== updatedList.length - 1 && updatedList.length > 1 && (
                  <Fab variant="close" className="icon-fab" onClick={() => habdleMoovement(false, index)}>
                    <ExpandMoreOutlinedIcon />
                  </Fab>
                )}
                {index === updatedList.length - 1 && updatedList.length > 1 && (
                  <Fab variant="close" className="icon-fab" disabled>
                    <ExpandMoreOutlinedIcon />
                  </Fab>
                )}

                <Fab variant="close" className="icon-fab" onClick={() => handleServiceRemove(index)}>
                  <DeleteOutlineOutlinedIcon />
                </Fab>
              </Box>
            )}
          </Box>
        ))}
      <Box className="button-right">
        {isEditing && (
          <Button endIcon={<AddCircleOutlineIcon />} variant="outlinedGrey" onClick={handleServiceAdd}>
            {t('common:add')}
          </Button>
        )}
      </Box>
    </Box>
  )
}

export default ListOfStrings

import { buildTimeStringFromTimestamp } from '../../time/timeHelper'

export const kytUserReportDashboardDataMapperForPDF = (data) => {
  const header = ['Action Owner', 'Action', 'Status', 'Date', 'User Id']
  const parsedData = data.map((user) => {
    return [
      user.EmailUser,
      user.activityDetails?.abmAction,
      user.activityDetails?.actionResult,
      buildTimeStringFromTimestamp(user.createdAt),
      user.activityDetails?.newRecord.userId ? user.activityDetails?.newRecord.userId : '',
    ]
  })

  return {
    columns: header,
    body: parsedData,
  }
}

export const kytUserReportDashboardDataMapper = (data) => {
  return data.map((user) => {
    return {
      ActionOwner: user.EmailUser,
      Action: user.activityDetails?.abmAction,
      Status: user.activityDetails?.actionResult,
      Date: buildTimeStringFromTimestamp(user.createdAt),
      UserId: user.activityDetails?.newRecord?.userId ? user.activityDetails?.newRecord?.userId : '',
    }
  })
}

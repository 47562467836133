import './tags.scss'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import Button from '@mui/material/Button'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { useEffect, useState } from 'react'
import { Box, TextField, Chip, Autocomplete, InputLabel, Fab, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const Tags = ({ propertie, ruleParameters, setRuleParameters, isEditing, filterTemplate, description, type }) => {
  const [tat, setTat] = useState([])
  const [change, setChange] = useState(false)
  const [keyState, setKeyState] = useState('')
  const [valueState, setValueState] = useState([])
  const { t } = useTranslation(['common'])
  const uniqueTransactions = useSelector((state) => state.transaction?.transactions?.unique?.uniqueKeys)
  const uniqueConsumerUsers = useSelector((state) => state.user?.consumerUsers?.unique?.uniqueKeys)
  const uniqueBusinessUsers = useSelector((state) => state.user?.businessUsers?.unique?.uniqueKeys)
  const [uniqueList, setUniqueList] = useState([])

  useEffect(() => {
    if (type === 'transactions' && Array.isArray(uniqueTransactions) && uniqueTransactions.length) {
      setUniqueList([...uniqueTransactions])
    } else if (
      type === 'users' &&
      ((Array.isArray(uniqueConsumerUsers) && uniqueConsumerUsers.length) ||
        (Array.isArray(uniqueBusinessUsers) && uniqueBusinessUsers.length))
    ) {
      setUniqueList([...(uniqueConsumerUsers ?? []), ...(uniqueBusinessUsers ?? [])])
    }
  }, [type])

  const getIndex = () => {
    if (Array.isArray(ruleParameters) && ruleParameters.length === 0) return -1
    if (ruleParameters) return ruleParameters.map((e) => e.filterTemplate).indexOf(filterTemplate)
    return -1
  }

  useEffect(() => {
    const aux = ruleParameters?.find((e) => e.filterTemplate === filterTemplate)

    if (aux) {
      setTat(aux[propertie])
    }
  }, [propertie])

  const handleKeyChange = (value, index) => {
    let list = [...tat]
    let aux = { ...list[index] }

    aux.key = value
    list[index] = aux
    setTat(list)
    setChange(true)
  }

  const handleValueChange = (value, index) => {
    let list = [...tat]
    let aux = { ...list[index] }
    aux.value = value
    list[index] = aux
    setTat(list)
    setChange(true)
  }

  const handleServiceRemove = (index) => {
    const list = [...tat]
    list.splice(index, 1)
    setTat(list)
    setChange(true)
  }

  const handleServiceAdd = () => {
    let objAux = { ...tat }
    objAux = { key: keyState, value: [...valueState] }
    setTat([...tat, { ...objAux }])
    setKeyState('')
    setValueState('')
    setChange(true)
  }

  useEffect(() => {
    const index = getIndex()

    if (change) {
      let all = [...ruleParameters]
      let previous = index >= 0 && all[index] ? { ...all[index] } : {}

      previous[propertie] = tat
      previous.filterTemplate = filterTemplate

      if (index !== -1) {
        all.splice(index, 1)
      }
      all.push(previous)

      setRuleParameters([...all])
    }
    setChange(false)
  }, [tat])

  return (
    <Box>
      {description && <Typography variant="subtitle4">{description}</Typography>}

      {tat &&
        tat?.map((item, index) => (
          <Box key={index}>
            <Box className="tag-row">
              <Box className="tag-pair">
                <InputLabel>{t('common:key')}</InputLabel>
                <TextField
                  className="Input"
                  disabled={!isEditing}
                  variant="outlined"
                  size="small"
                  placeholder="New Key"
                  value={item.key}
                  onChange={(event) => handleKeyChange(event.target.value, index)}
                />
              </Box>
              <Box className="tag-pair">
                <InputLabel>{t('common:values')}</InputLabel>
                <Autocomplete
                  disabled={!isEditing}
                  value={item.value}
                  onChange={(event, newValue) => {
                    handleValueChange(newValue, index)
                  }}
                  multiple
                  id="tags-filled"
                  size="small"
                  options={[]}
                  freeSolo
                  renderTags={(value, getTagProps) =>
                    item.value?.map((option, index) => (
                      <Chip variant="filled" color="primary" label={option} {...getTagProps({ index })} />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" placeholder={t('common:values')} />
                  )}
                />
              </Box>
              {isEditing && (
                <Fab variant="close" className="icon-fab" onClick={() => handleServiceRemove(item.value)}>
                  <DeleteOutlineOutlinedIcon />
                </Fab>
              )}
            </Box>
          </Box>
        ))}
      <Box className="tag-row">
        <Box className="tag-pair">
          <InputLabel>{t('common:key')}</InputLabel>
          <Autocomplete
            disabled={!isEditing}
            freeSolo
            size="small"
            options={uniqueList}
            value={keyState}
            onChange={(event, newValue) => {
              setKeyState(newValue)
            }}
            onInputChange={(event, newInputValue) => {
              setKeyState(newInputValue)
            }}
            renderInput={(params) => <TextField {...params} label={t('common:selectOrType')} variant="outlined" />}
          />
        </Box>
        <Box className="tag-pair">
          <InputLabel>{t('common:values')}</InputLabel>
          <Autocomplete
            disabled={!isEditing}
            value={valueState}
            onChange={(event, newValue) => {
              setValueState(newValue)
              setChange(true)
            }}
            multiple
            id="tags-filled"
            options={[]}
            freeSolo
            size="small"
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip variant="filled" color="primary" label={option} {...getTagProps({ index })} />
              ))
            }
            renderInput={(params) => <TextField {...params} variant="outlined" placeholder={t('common:values')} />}
          />
        </Box>
      </Box>
      <Box className="button-right">
        {isEditing && (
          <Button
            endIcon={<AddCircleOutlineIcon />}
            disabled={keyState === '' || valueState === ''}
            variant="outlinedGrey"
            onClick={handleServiceAdd}
          >
            {t('common:add')}
          </Button>
        )}
      </Box>
    </Box>
  )
}

export default Tags

import { Box } from '@mui/material'
import CheckboxItem from '../checkbox/checkbox'
import NumberInput from '../numberInput/numberInput'
import './numberAndCheck.scss'
import { useEffect, useState } from 'react'

const NumberAndCheck = ({
  propertie,
  ruleParameters,
  setRuleParameters,
  setError,
  errorList,
  setErrorList,
  isEditing,
}) => {
  const [finalParameter, setFinalParameter] = useState({})
  const [change, setChange] = useState(false)

  useEffect(() => {
    if (ruleParameters[propertie]) {
      setFinalParameter(ruleParameters[propertie])
    }
  }, [])

  const hangleChange = (value) => {
    setFinalParameter(value)
    setChange(true)
  }

  useEffect(() => {
    let errors = []
    if (!finalParameter.transactionsCounterPartiesCount) errors.push('transactionsCounterPartiesCount is required')
    if (errors.length !== 0) {
      let all = errorList
      all[propertie] = errors
      setErrorList({ ...all })
      setError(true)
    } else {
      let all = errorList
      delete all[propertie]
      setErrorList({ ...all })
      setError(false)
    }

    if (change) {
      let all = { ...ruleParameters }
      all[propertie] = finalParameter
      setRuleParameters({ ...all })
      setChange(false)
    }
  }, [change, finalParameter])

  return (
    <Box className="div">
      <Box className="number-check-title">
        <span className="mandatory">* </span>
        <p className="number-check-title-p">Unique transaction counterparties count</p>
      </Box>
      <NumberInput
        isEditing={isEditing}
        propertie={'transactionsCounterPartiesCount'}
        ruleParameters={finalParameter}
        setRuleParameters={hangleChange}
        schema=""
      />
      <CheckboxItem
        isEditing={isEditing}
        propertie={'checkPaymentMethodDetails'}
        ruleParameters={finalParameter}
        setRuleParameters={hangleChange}
        label={'Check payment method details'}
        text={'If enabled, system will check only unique users with payment method details'}
      />
    </Box>
  )
}

export default NumberAndCheck

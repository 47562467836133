import { dashboardRolesReportDataMapper, dashboardRolesReportDataMapperForPDF } from './dashboardRolesDataMapper'
import { dashboardUserReportDataMapper, dashboardUserReportDataMapperForPDF } from './dashboardUserReportDataMapper'
import {
  securityParameterDashboardDataMapper,
  securityParameterDashboardDataMapperForPDF,
} from './securityParametersDashboardReportDataMapper'
import {
  kytTransactionDashboardDataMapper,
  kytTransactionDashboardDataMapperForPDF,
} from './kytTransactionReportDataMapper'
import { kytUserReportDashboardDataMapper, kytUserReportDashboardDataMapperForPDF } from './kytUserReportDataMapper'
import { kytRuleReportDashboardDataMapper, kytRuleReportDashboardDataMapperForPDF } from './kytRuleReportDataMapper'

export const abmMapper = (key) => {
  switch (key) {
    case 'abm_security_params':
      return securityParameterDashboardDataMapper

    case 'abm_kyt_transactions':
      return kytTransactionDashboardDataMapper

    case 'abm_kyt_users':
      return kytUserReportDashboardDataMapper

    case 'abm_kyt_rules':
      return kytRuleReportDashboardDataMapper

    case 'abm_users':
      return dashboardUserReportDataMapper

    case 'abm_roles':
      return dashboardRolesReportDataMapper

    default:
      return
  }
}

export const abmMapperForPDF = (key) => {
  switch (key) {
    case 'abm_security_params':
      return securityParameterDashboardDataMapperForPDF

    case 'abm_kyt_transactions':
      return kytTransactionDashboardDataMapperForPDF

    case 'abm_kyt_users':
      return kytUserReportDashboardDataMapperForPDF

    case 'abm_kyt_rules':
      return kytRuleReportDashboardDataMapperForPDF

    case 'abm_users':
      return dashboardUserReportDataMapperForPDF

    case 'abm_roles':
      return dashboardRolesReportDataMapperForPDF

    default:
      return
  }
}

import { Chip } from '@mui/material'
import CircleIcon from '@mui/icons-material/Circle'
import './status.scss'
import { useTranslation } from 'react-i18next'
import IconLib from '../../styles/icons/IconLib'
import { useSelector } from 'react-redux'

export const Status = ({ type, noIcon, sizeBig, variant }) => {
  const { t } = useTranslation(['common'])
  const { theme } = useSelector((state) => state.theme)

  switch (type) {
    case 'DENIED':
      return (
        <Chip
          size={sizeBig ? 'medium' : 'small'}
          label={t('common:denied')}
          icon={noIcon ? <></> : <IconLib svg="shieldCross" classes="svg-icon" />}
          variant={variant && theme === 'dark' ? variant : 'colorPrimary'}
        />
      )
    case 'NOT_STARTED':
      return (
        <Chip
          size={sizeBig ? 'medium' : 'small'}
          label={t('common:notStarted')}
          icon={noIcon ? <></> : <IconLib svg="shieldWarning" classes="svg-icon" />}
          variant={variant && theme === 'dark' ? variant : 'colorPrimary'}
        />
      )
    case 'IN_PROGRESS':
      return (
        <Chip
          size={sizeBig ? 'medium' : 'small'}
          label={t('common:inProgress')}
          icon={noIcon ? <></> : <IconLib svg="shieldUp" classes="svg-icon" />}
          variant={variant && theme === 'dark' ? variant : 'colorPrimary'}
        />
      )
    case 'APPROVED':
      return (
        <Chip
          size={sizeBig ? 'medium' : 'small'}
          label={t('common:approved')}
          icon={noIcon ? <></> : <IconLib svg="shieldStart" classes="svg-icon" />}
          variant={variant && theme === 'dark' ? variant : 'colorPrimary'}
        />
      )
    case 'PENDING':
      return (
        <Chip
          size={sizeBig ? 'medium' : 'small'}
          label={t('common:pending')}
          icon={
            noIcon ? (
              <></>
            ) : (
              <CircleIcon
                style={{ color: 'var(--medium)' }}
                className={theme === 'light' ? 'svg-icon' : 'svg-icon-dark'}
              />
            )
          }
          variant={variant && theme === 'dark' ? variant : 'colorPrimary'}
        />
      )
    case 'CANCELED':
      return (
        <Chip
          size={sizeBig ? 'medium' : 'small'}
          label={t('common:canceled')}
          icon={
            noIcon ? (
              <></>
            ) : (
              <CircleIcon
                style={{ color: 'var(--medium)' }}
                className={theme === 'light' ? 'svg-icon' : 'svg-icon-dark'}
              />
            )
          }
          variant={variant && theme === 'dark' ? variant : 'colorPrimary'}
        />
      )
    case 'COMPLETED':
      return (
        <Chip
          size={sizeBig ? 'medium' : 'small'}
          label={t('common:completed')}
          icon={
            noIcon ? (
              <></>
            ) : (
              <CircleIcon
                style={{ color: 'var(--medium)' }}
                className={theme === 'light' ? 'svg-icon' : 'svg-icon-dark'}
              />
            )
          }
          variant={variant && theme === 'dark' ? variant : 'colorPrimary'}
        />
      )
    default:
      return
  }
}

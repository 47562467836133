import { useParams } from 'react-router-dom'
import './onboardingDetail.scss'
import { useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Fab,
  Tab,
  Chip,
} from '@mui/material'
import { useOnboardingDetail } from '../pages-hooks/useOnboardings'
import Spinner from '../../components/common/spinner/spinner'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'
import InsightsOutlinedIcon from '@mui/icons-material/InsightsOutlined'
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined'
import BalanceOutlinedIcon from '@mui/icons-material/BalanceOutlined'
import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined'
import CameraFrontOutlinedIcon from '@mui/icons-material/CameraFrontOutlined'
import InfoIcon from '@mui/icons-material/Info'
import { Flags } from '../../components/common/flag/flags'
import ImageSlider from '../../components/common/toRender/ImgSlider'
import { useTranslation } from 'react-i18next'
import { TableChip } from '../../components/utilities/TableChip'
import LetterAvatar from '../../components/common/userDetails/LetterAvatar'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { RenderTooltip } from '../../components/utilities/RenderTooltip'

const OnboardingDetail = () => {
  const { id } = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const onboardingDetail = useSelector((state) => state.onboarding.onboardingDetail)
  useOnboardingDetail(id, setIsLoading)
  const [consistencyAnalysis, setConsistencyAnalysis] = useState({})
  const [forensicAnalysis, setForensicAnalysis] = useState({})
  const [contentAnalysis, setContentAnalysis] = useState({})
  const [formatAnalysis, setFormatAnalysis] = useState({})
  const [frontAndBackAnalysis, setFrontAndBackAnalysis] = useState({})
  const [mrzAnalysis, setMrzAnalysis] = useState({})
  const [clientValidation, setClientValidation] = useState({})
  const [extractedData, setExtractedData] = useState({})
  const [allExtractedData, setAllExtractedData] = useState({})
  const [visual, setVisual] = useState({})
  const [mrz, setMrz] = useState({})
  const [barcode, setBarcode] = useState({})
  const [documentDetails, setDocumentDetails] = useState({})
  const [extractedImages, setExtractedImages] = useState([])
  const [faceAnalysis, setFaceAnalysis] = useState({})
  const [authenticityAnalysis, setAuthenticityAnalysis] = useState({})
  const [integrityAnalysis, setIntegrityAnalysis] = useState({})
  const [internalAnalysis, setInternalAnalysis] = useState({})
  const [securityAndPatternAnalysis, setSecurityAndPatternAnalysis] = useState([])
  const [receivedImages, setReceivedImages] = useState({})
  const [selectedTab, setSelectedTab] = useState('analytics')

  const { t } = useTranslation(['common'])

  useEffect(() => {
    setConsistencyAnalysis({})
    setForensicAnalysis({})
    setContentAnalysis({})
    setFormatAnalysis({})
    setFrontAndBackAnalysis({})
    setMrzAnalysis({})
    setClientValidation({})
    setExtractedData({})
    setDocumentDetails({})
    setExtractedImages([])
    setFaceAnalysis({})
    setAuthenticityAnalysis({})
    setIntegrityAnalysis({})
    setInternalAnalysis({})
    setAllExtractedData({})
    setVisual({})
    setMrz({})
    setBarcode({})
    setSecurityAndPatternAnalysis([])
    setReceivedImages({})

    if (onboardingDetail.result) {
      if (onboardingDetail.result.breakdown?.consistencyAnalysis) {
        setConsistencyAnalysis({ ...onboardingDetail.result.breakdown.consistencyAnalysis })
      }
      if (onboardingDetail.result.breakdown?.forensicAnalysis) {
        setForensicAnalysis({ ...onboardingDetail.result.breakdown.forensicAnalysis })
      }
      if (onboardingDetail.result.breakdown?.contentAnalysis) {
        setContentAnalysis({ ...onboardingDetail.result.breakdown.contentAnalysis })
      }
      if (onboardingDetail.result.breakdown?.formatAnalysis) {
        setFormatAnalysis({ ...onboardingDetail.result.breakdown.formatAnalysis })
      }
      if (onboardingDetail.result.breakdown?.frontAndBackAnalysis) {
        setFrontAndBackAnalysis({ ...onboardingDetail.result.breakdown.frontAndBackAnalysis })
      }
      if (onboardingDetail.result.breakdown?.mrzAnalysis) {
        setMrzAnalysis({ ...onboardingDetail.result.breakdown.mrzAnalysis })
      }
      if (onboardingDetail.result.breakdown?.clientValidation) {
        setClientValidation({ ...onboardingDetail.result.breakdown.clientValidation })
      }
      if (onboardingDetail.result.breakdown?.extractedData) {
        setExtractedData({ ...onboardingDetail.result.breakdown.extractedData })
        if (onboardingDetail.result.breakdown.extractedData.documentDetails) {
          setDocumentDetails({ ...onboardingDetail.result.breakdown.extractedData.documentDetails })
        }
        if (onboardingDetail.result.breakdown.extractedData.allExtractedData) {
          setAllExtractedData({ ...onboardingDetail.result.breakdown.extractedData.allExtractedData })
          setVisual({ ...onboardingDetail.result.breakdown.extractedData.allExtractedData.visual })
          setMrz({ ...onboardingDetail.result.breakdown.extractedData.allExtractedData.mrz })
          setBarcode({ ...onboardingDetail.result.breakdown.extractedData.allExtractedData.barcode })
        }
      }
      if (onboardingDetail.result.breakdown?.extractedImages) {
        setExtractedImages(onboardingDetail.result.breakdown.extractedImages)
      }
      if (onboardingDetail.result.breakdown?.faceAnalysis) {
        setFaceAnalysis(onboardingDetail.result.breakdown.faceAnalysis)
      }
      if (onboardingDetail.result.breakdown?.authenticityAnalysis) {
        setAuthenticityAnalysis(onboardingDetail.result.breakdown.authenticityAnalysis)
      }
      if (onboardingDetail.result.breakdown?.integrityAnalysis) {
        setIntegrityAnalysis(onboardingDetail.result.breakdown.integrityAnalysis)
      }
      if (onboardingDetail.result.breakdown?.internalAnalysis) {
        setInternalAnalysis(onboardingDetail.result.breakdown.internalAnalysis)
      }
      if (onboardingDetail.result.breakdown?.securityAndPatternAnalysis) {
        setSecurityAndPatternAnalysis(onboardingDetail.result.breakdown.securityAndPatternAnalysis)
      }
      if (onboardingDetail.selfie || onboardingDetail.documentImage || onboardingDetail.backDocumentImage) {
        setReceivedImages({
          selfie: onboardingDetail.selfie ? onboardingDetail.selfie : null,
          documentImage: onboardingDetail.documentImage ? onboardingDetail.documentImage : null,
          backDocumentImage: onboardingDetail.backDocumentImage ? onboardingDetail.backDocumentImage : null,
        })
      }
    }
  }, [onboardingDetail])

  const checkRender = (str, status) => {
    return (
      <Box className="check-render">
        {(!status || status === 'not_processed') && <RemoveCircleRoundedIcon fontSize="small" className="grey" />}
        {status === 'clear' && <CheckCircleRoundedIcon fontSize="small" color="secondary" />}
        {status && status !== 'clear' && status !== 'not_processed' && (
          <CancelRoundedIcon fontSize="small" className="red" />
        )}
        <p>{str}</p>
      </Box>
    )
  }

  const renderDate = (obj) => {
    return obj.day + '/' + obj.month + '/' + obj.year
  }

  return (
    <Box>
      {isLoading && <Spinner />}
      <Box className={`${isLoading && 'spinner-transition'}`}>
        {!isLoading && (
          <Box className="onboarding-details">
            <Paper elevation={0} className="onboarding-details-top">
              <Box className="onboarding-avatar">
                <LetterAvatar fullName={onboardingDetail.entityName} width="2.5rem" height="2.5rem" fontSize="1rem" />
                <Typography variant="title">
                  {onboardingDetail.entityName ? onboardingDetail.entityName : '---'}
                </Typography>
              </Box>
              <Box className="onboarding-info">
                <Paper elevation={0} variant="colorPrimary" className="onboarding-box">
                  <Box className="onboarding-id">
                    <Box>
                      <Typography variant="subtitle1">{t('common:checkId')}</Typography>
                      <Typography variant="number">{id}</Typography>
                    </Box>
                    <Fab
                      size="small"
                      className="icon-global"
                      variant="action"
                      onClick={() => {
                        navigator.clipboard.writeText(id)
                      }}
                    >
                      <ContentCopyOutlinedIcon />
                    </Fab>
                  </Box>
                </Paper>

                <Paper elevation={0} variant="colorPrimary" className="onboarding-box">
                  <Typography variant="subtitle1">{t('common:type')}</Typography>
                  <Box sx={{ width: 'fit-content' }}>
                    <TableChip noIcon={true} action={onboardingDetail.type ? onboardingDetail.type : '---'} />
                  </Box>
                </Paper>

                <Paper elevation={0} variant="colorPrimary" className="onboarding-box">
                  <Typography variant="subtitle1">{t('common:status')}</Typography>
                  <Typography variant="subtitle3">
                    {onboardingDetail.status ? onboardingDetail.status : '---'}
                  </Typography>
                </Paper>

                <Paper elevation={0} variant="colorPrimary" className="onboarding-box">
                  <Typography variant="subtitle1">{t('common:outcome')}</Typography>
                  <Typography variant="subtitle3">
                    <TableChip
                      noIcon={true}
                      action={onboardingDetail.result?.outcome ? onboardingDetail.result.outcome : t('common:pending')}
                    />
                  </Typography>
                </Paper>
              </Box>
            </Paper>

            <TabContext value={selectedTab}>
              <Box className="onboarding-details-bottom">
                <Paper
                  elevation={0}
                  variant="rootTransparent"
                  className="onboarding-details-top"
                  style={{ height: 'fit-content' }}
                >
                  <Typography variant="title">{t('common:sections')}</Typography>
                  <TabList
                    TabIndicatorProps={{
                      style: { display: 'none' },
                    }}
                    className="onboarding-tablist"
                    onChange={(e, newValue) => setSelectedTab(newValue)}
                  >
                    <Tab
                      label={
                        <Chip
                          icon={selectedTab === 'analytics' ? <InsightsOutlinedIcon /> : <></>}
                          label={t('common:analytics')}
                          variant={selectedTab === 'analytics' ? 'sliderSelectedPurple' : 'sliderTransparent'}
                        />
                      }
                      value="analytics"
                    />
                    {onboardingDetail.type === 'document_check' && (
                      <Tab
                        label={
                          <Chip
                            icon={selectedTab === 'summaryInfo' ? <SummarizeOutlinedIcon /> : <></>}
                            label={t('common:summaryInfo')}
                            variant={selectedTab === 'summaryInfo' ? 'sliderSelectedPurple' : 'sliderTransparent'}
                          />
                        }
                        value="summaryInfo"
                      />
                    )}
                    {onboardingDetail.type === 'document_check' && (
                      <Tab
                        label={
                          <Chip
                            icon={selectedTab === 'legalDocuments' ? <BalanceOutlinedIcon /> : <></>}
                            label={t('common:legalDocuments')}
                            variant={selectedTab === 'legalDocuments' ? 'sliderSelectedPurple' : 'sliderTransparent'}
                          />
                        }
                        value="legalDocuments"
                      />
                    )}
                    {onboardingDetail.type === 'document_check' && (
                      <Tab
                        label={
                          <Chip
                            icon={selectedTab === 'securityAndPatterns' ? <GppGoodOutlinedIcon /> : <></>}
                            label={t('common:securityAndPatterns')}
                            variant={
                              selectedTab === 'securityAndPatterns' ? 'sliderSelectedPurple' : 'sliderTransparent'
                            }
                          />
                        }
                        value="securityAndPatterns"
                      />
                    )}
                    <Tab
                      label={
                        <Chip
                          icon={selectedTab === 'receivedImages' ? <CameraFrontOutlinedIcon /> : <></>}
                          label={t('common:receivedImages')}
                          variant={selectedTab === 'receivedImages' ? 'sliderSelectedPurple' : 'sliderTransparent'}
                        />
                      }
                      value="receivedImages"
                    />
                  </TabList>
                </Paper>
                <Paper elevation={0}>
                  <TabPanel value="analytics" className="onboarding-details-top">
                    <Typography variant="title">{t('common:analytics')}</Typography>

                    <Box>
                      {onboardingDetail.type === 'document_check' && (
                        <Box className="onboarding-info-bottom">
                          {consistencyAnalysis && (
                            <Paper elevation={0} variant="colorPrimary" className="onboarding-analysis">
                              <Box className="onboarding-tooltip">
                                <Typography variant="subtitle1">{t('common:consistencyAnalysis')}</Typography>

                                <RenderTooltip
                                  title={'Comparison of OCR extracted data with data in Machine Readable Zone (MRZ).'}
                                />
                              </Box>
                              {checkRender('Last name', consistencyAnalysis.lastName)}
                              {checkRender('First name', consistencyAnalysis.firstName)}
                              {checkRender('Birth date', consistencyAnalysis.dob)}
                              {checkRender('Document number', consistencyAnalysis.documentNumber)}
                              {checkRender('Personal number', consistencyAnalysis.personalNumber)}
                              {checkRender('Expiration date', consistencyAnalysis.expirationDate)}
                              {checkRender('Issuing date', consistencyAnalysis.issuingDate)}
                            </Paper>
                          )}
                          {forensicAnalysis && (
                            <Paper elevation={0} variant="colorPrimary" className="onboarding-analysis">
                              <Box className="onboarding-tooltip">
                                <Typography variant="subtitle1">{t('common:forensicAnalysis')}</Typography>

                                <RenderTooltip title={'Authentication of ID security elements.'} />
                              </Box>
                              {checkRender('MRZ visual elements', forensicAnalysis.mrzVisualPlacement)}
                              {checkRender('Security elements', forensicAnalysis.securityElements)}
                              {checkRender('Photo location', forensicAnalysis.photoLocation)}
                              {checkRender('MRZ classification', forensicAnalysis.mrzClassification)}
                              {checkRender('Document liveness', forensicAnalysis.documentLivenessCheck)}
                              {checkRender('Tampering detection', forensicAnalysis.tamperingDetected)}
                            </Paper>
                          )}
                          {contentAnalysis && (
                            <Paper elevation={0} variant="colorPrimary" className="onboarding-analysis">
                              <Box className="onboarding-tooltip">
                                <Typography variant="subtitle1">{t('common:contentAnalysis')}</Typography>

                                <RenderTooltip title={'Assertion of the validity of the ID.'} />
                              </Box>
                              {checkRender('Data integrity', contentAnalysis.dataIntegrity)}
                              {checkRender('Issuing date', contentAnalysis.issuingDate)}
                              {checkRender('Expiration date', contentAnalysis.expirationDate)}
                              {checkRender('Specimen check', contentAnalysis.specimenCheck)}
                              {checkRender('Black list check', contentAnalysis.blackListCheck)}
                            </Paper>
                          )}
                          {formatAnalysis && (
                            <Paper elevation={0} variant="colorPrimary" className="onboarding-analysis">
                              <Box className="onboarding-tooltip">
                                <Typography variant="subtitle1">{t('common:formatAnalysis')}</Typography>

                                <RenderTooltip title={'Validation of the ID format.'} />
                              </Box>
                              {checkRender('Model identification', formatAnalysis.modelIdentification)}
                              {checkRender('Country model validity', formatAnalysis.countryModelValidity)}
                              {checkRender('Document model validity', formatAnalysis.documentModelValidity)}
                              {checkRender('Photocopy detection', formatAnalysis.photocopyDetected)}
                            </Paper>
                          )}
                          {frontAndBackAnalysis && (
                            <Paper elevation={0} variant="colorPrimary" className="onboarding-analysis">
                              <Box className="onboarding-tooltip">
                                <Typography variant="subtitle1">{t('common:front&backAnalysis')}</Typography>

                                <RenderTooltip
                                  title={
                                    'Analysis of the front and back sides of the document to assert consistency and validity.'
                                  }
                                />
                              </Box>
                              {checkRender('Format analysis', frontAndBackAnalysis.formatAnalysis)}
                              {checkRender('Data consistency', frontAndBackAnalysis.dataConsistency)}
                            </Paper>
                          )}
                          {mrzAnalysis && (
                            <Paper elevation={0} variant="colorPrimary" className="onboarding-analysis">
                              <Box className="onboarding-tooltip">
                                <Typography variant="subtitle1">{t('common:MRZAnalysis')}</Typography>

                                <RenderTooltip
                                  title={'Checks if Machine Readable Zone (MRZ) is valid and adheres to standards.'}
                                />
                              </Box>
                              {checkRender('MRZ format', mrzAnalysis.mrzFormat)}
                              {checkRender('MRZ checksum', mrzAnalysis.mrzChecksum)}
                            </Paper>
                          )}
                          {clientValidation && (
                            <Paper elevation={0} variant="colorPrimary" className="onboarding-analysis">
                              <Box className="onboarding-tooltip">
                                <Typography variant="subtitle1">{t('common:clientValidation')}</Typography>

                                <RenderTooltip
                                  title={
                                    'Checks if extracted age is greater than the predefined threshold, and if client record data matches extracted names.'
                                  }
                                />
                              </Box>
                              {checkRender('Age verification', clientValidation.ageVerification)}
                              {checkRender('Client data consistency', clientValidation.clientDataConsistency)}
                            </Paper>
                          )}
                          {forensicAnalysis && (
                            <Paper elevation={0} variant="colorPrimary" className="onboarding-analysis">
                              <Box className="onboarding-tooltip">
                                <Typography variant="subtitle1">{t('common:livenessCheckScore')}</Typography>

                                <RenderTooltip title={'Document liveness confidence score.'} />
                              </Box>
                              <Typography variant="number">
                                {forensicAnalysis.breakdown?.documentFrontLivenessScore
                                  ? forensicAnalysis.breakdown?.documentFrontLivenessScore
                                  : 100}
                                % ({t('common:frontSide')})
                              </Typography>
                            </Paper>
                          )}
                        </Box>
                      )}
                      {onboardingDetail.type === 'identity_check' && (
                        <Box className="onboarding-info-bottom">
                          {faceAnalysis && (
                            <Paper elevation={0} variant="colorPrimary" className="onboarding-analysis">
                              <Box className="onboarding-tooltip">
                                <Typography variant="subtitle1">{t('common:faceAnalysis')}</Typography>
                                <RenderTooltip title={'Various analyses performed on detected faces.'} />
                              </Box>
                              {checkRender('Facial similarity', faceAnalysis.facialSimilarity)}
                              {checkRender('Previously enrolled face', faceAnalysis.previouslyEnrolledFace)}
                            </Paper>
                          )}
                          {authenticityAnalysis && (
                            <Paper elevation={0} variant="colorPrimary" className="onboarding-analysis">
                              <Box className="onboarding-tooltip">
                                <Typography variant="subtitle1">{t('common:authenticityAnalysis')}</Typography>
                                <RenderTooltip
                                  title={
                                    'Checks selfie for liveness. Also asserts whether it is spoofed or an image of an image.'
                                  }
                                />
                              </Box>
                              {checkRender('Spoofed image analysis', authenticityAnalysis.spoofedImageAnalysis)}
                              {checkRender('Liveness check', authenticityAnalysis.livenessCheck)}
                            </Paper>
                          )}
                          {Object.keys(internalAnalysis).length !== 0 && (
                            <Paper elevation={0} variant="colorPrimary" className="onboarding-analysis">
                              <Box className="onboarding-tooltip">
                                <Typography variant="subtitle1">{t('common:internalAnalysis')}</Typography>

                                <RenderTooltip
                                  title={
                                    'Güeno verify in government databases, internal records, and verdors/third parties services how likely it is that the person in the selfie is the legitimate holder of the received tax identification number.'
                                  }
                                />
                              </Box>
                              {checkRender('Tax Identification Number Owner', internalAnalysis.taxIdOwner)}
                            </Paper>
                          )}
                          {integrityAnalysis && (
                            <Paper elevation={0} variant="colorPrimary" className="onboarding-analysis">
                              <Box className="onboarding-tooltip">
                                <Typography variant="subtitle1">{t('common:intgrityAnalysis')}</Typography>

                                <RenderTooltip
                                  title={
                                    'Checks integrity of capture and asserts if the expected number of faces are detected.'
                                  }
                                />
                              </Box>
                              {checkRender('Face detection', integrityAnalysis.faceDetection)}
                              {checkRender('VPN detection', integrityAnalysis.vpnDetected)}
                            </Paper>
                          )}
                          {faceAnalysis && faceAnalysis.breakdown && (
                            <Paper elevation={0} variant="colorPrimary" className="onboarding-analysis">
                              <Box className="onboarding-tooltip">
                                <Typography variant="subtitle1">{t('common:facialSimilarityScore')}</Typography>

                                <RenderTooltip title={'Facial similarity confidence score.'} />
                              </Box>
                              <Typography variant="number">
                                {faceAnalysis.breakdown.facialSimilarityScore
                                  ? faceAnalysis.breakdown.facialSimilarityScore
                                  : 0}
                                %
                              </Typography>
                            </Paper>
                          )}
                          {authenticityAnalysis && authenticityAnalysis.breakdown && (
                            <Paper elevation={0} variant="colorPrimary" className="onboarding-analysis">
                              <Box className="onboarding-tooltip">
                                <Typography variant="subtitle1">{t('common:livenessCheckScore')}</Typography>

                                <RenderTooltip title={'Liveness confidence score.'} />
                              </Box>
                              <Typography variant="number">
                                {authenticityAnalysis.breakdown.livenessCheckScore
                                  ? authenticityAnalysis.breakdown.livenessCheckScore
                                  : 0}
                                %
                              </Typography>
                            </Paper>
                          )}
                          {Object.keys(internalAnalysis).length !== 0 && (
                            <Paper elevation={0} variant="colorPrimary" className="onboarding-analysis">
                              <Box className="onboarding-tooltip">
                                <Typography variant="subtitle1">{t('common:internalAnalysisScore')}</Typography>
                                <RenderTooltip
                                  title={
                                    'Number indicating the likelihood that the person in the selfie is the rightful owner of the received tax identification number, ranging from -100 to 100, where -100 signifies a probable fraud and 100 represents the legitimate owner.'
                                  }
                                />
                              </Box>
                              {internalAnalysis.taxIdOwnerScore ? internalAnalysis.taxIdOwnerScore : '---'}
                            </Paper>
                          )}
                        </Box>
                      )}
                    </Box>
                  </TabPanel>

                  <TabPanel value="summaryInfo" className="onboarding-details-top">
                    <Typography variant="title">{t('common:summaryInfo')}</Typography>
                    {onboardingDetail.type === 'document_check' && (
                      <Box>
                        {extractedData && (
                          <Box className="cols">
                            <Box className="col-1">
                              {extractedImages && <ImageSlider images={extractedImages.map((img) => img.data)} />}
                            </Box>
                            {documentDetails && (
                              <Box className="onboarding-summary">
                                <Typography variant="title2">
                                  {documentDetails.documentSubtypes ? documentDetails.documentSubtypes[0] : '---'}
                                </Typography>
                                <Box className="onboarding-summary">
                                  <Box className="onboarding-info-summary">
                                    <Paper elevation={0} variant="colorPrimary" className="onboarding-box">
                                      <Typography variant="subtitle1">{t('common:type')}</Typography>
                                      <Typography variant="subtitle3">
                                        {documentDetails.documentType ? documentDetails.documentType : '---'}
                                      </Typography>
                                    </Paper>

                                    <Paper elevation={0} variant="colorPrimary" className="onboarding-box">
                                      <Typography variant="subtitle1">{t('common:isuuingCountry')}</Typography>
                                      <Typography variant="subtitle3">
                                        {documentDetails.issuingCountry ? Flags(documentDetails.issuingCountry) : '---'}
                                      </Typography>
                                    </Paper>

                                    <Paper elevation={0} variant="colorPrimary" className="onboarding-box">
                                      <Typography variant="subtitle1">ID {t('common:number')}</Typography>
                                      <Typography variant="subtitle3">
                                        {documentDetails.documentNumber ? documentDetails.documentNumber : '---'}
                                      </Typography>
                                    </Paper>
                                  </Box>

                                  <Box className="onboarding-avatar">
                                    <Paper elevation={0} variant="colorPrimary" className="onboarding-box">
                                      <Typography variant="subtitle1">ID {t('common:firstName')}</Typography>
                                      <Typography variant="subtitle3">
                                        {extractedData.holderDetails?.lastName
                                          ? extractedData.holderDetails.lastName.join(' ')
                                          : '---'}
                                      </Typography>
                                    </Paper>

                                    <Paper elevation={0} variant="colorPrimary" className="onboarding-box">
                                      <Typography variant="subtitle1">ID {t('common:lastName')}</Typography>
                                      <Typography variant="subtitle3">
                                        {extractedData.holderDetails?.lastName
                                          ? extractedData.holderDetails.lastName.join(' ')
                                          : '---'}
                                      </Typography>
                                    </Paper>
                                  </Box>

                                  <Box className="onboarding-info-summary">
                                    <Paper elevation={0} variant="colorPrimary" className="onboarding-box">
                                      <Typography variant="subtitle1">ID {t('common:dateOfBirth')}</Typography>
                                      <Typography variant="subtitle3">
                                        {extractedData.holderDetails?.dob
                                          ? renderDate(extractedData.holderDetails.dob)
                                          : '---'}
                                      </Typography>
                                    </Paper>

                                    <Paper elevation={0} variant="colorPrimary" className="onboarding-box">
                                      <Typography variant="subtitle1">ID {t('common:personalNumber')}</Typography>
                                      <Typography variant="number">
                                        {extractedData.allExtractedData?.visual?.personalNumber
                                          ? extractedData.allExtractedData?.visual?.personalNumber
                                          : extractedData.allExtractedData?.barcode
                                          ? extractedData.allExtractedData.barcode.personalNumber
                                          : '---'}
                                      </Typography>
                                    </Paper>

                                    <Paper elevation={0} variant="colorPrimary" className="onboarding-box">
                                      <Typography variant="subtitle1">ID {t('common:taxNumber')}</Typography>
                                      <Typography variant="number">
                                        {extractedData.holderDetails?.taxIdentificationNumber
                                          ? extractedData.holderDetails.taxIdentificationNumber
                                          : '---'}
                                      </Typography>
                                    </Paper>
                                  </Box>

                                  <Box className="onboarding-avatar">
                                    <Paper elevation={0} variant="colorPrimary" className="onboarding-box">
                                      <Typography variant="subtitle1">ID {t('common:expirationDate')}</Typography>
                                      <Typography variant="number">
                                        {documentDetails.expirationDate
                                          ? renderDate(documentDetails.expirationDate)
                                          : '---'}
                                      </Typography>
                                    </Paper>

                                    <Paper elevation={0} variant="colorPrimary" className="onboarding-box">
                                      <Typography variant="subtitle1">ID {t('common:MRZLine')} 1</Typography>
                                      <Typography variant="number">
                                        {extractedData.mrz?.line1 ? extractedData.mrz.line1 : '---'}
                                      </Typography>
                                    </Paper>
                                  </Box>

                                  <Paper elevation={0} variant="colorPrimary" className="onboarding-box">
                                    <Typography variant="subtitle1">ID {t('common:MRZLine')} 2</Typography>
                                    <Typography variant="number">
                                      {extractedData.mrz?.line2 ? extractedData.mrz.line2 : '---'}
                                    </Typography>
                                  </Paper>
                                </Box>
                              </Box>
                            )}
                          </Box>
                        )}
                      </Box>
                    )}
                  </TabPanel>

                  <TabPanel value="legalDocuments" className="onboarding-details-top">
                    <Typography variant="title">{t('common:legalDocuments')}</Typography>
                    {onboardingDetail.type === 'document_check' && allExtractedData && (
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell className="text-container">{t('common:field')}</TableCell>
                              <TableCell className="text-container">
                                <Box className="onboarding-uid">
                                  {t('common:visual')}

                                  <RenderTooltip
                                    title={
                                      'The Visual Inspection Zone (VIZ) of a Machine Readable Travel Document (MRTD) includes essential and other, relevant data elements designed for visual inspection.'
                                    }
                                  />
                                </Box>
                              </TableCell>
                              <TableCell className="text-container">
                                <Box className="onboarding-uid">
                                  MRZ
                                  <RenderTooltip
                                    title={
                                      'The Machine Readable Zone (MRZ) includes essential data about the document and its holder. The data is presented in a standardized format for each type of Machine Readable Travel Document (MRTD).'
                                    }
                                  />
                                </Box>
                              </TableCell>
                              <TableCell className="text-container">
                                <Box className="onboarding-uid">
                                  {t('common:barcode')}

                                  <RenderTooltip
                                    title={
                                      'The Barcode incorporates essential and other relevant data elements in a non-standardized format that can be read using specialized barcode readers.'
                                    }
                                  />
                                </Box>
                              </TableCell>
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            {(visual?.firstName || mrz?.firstName || barcode?.firstName) && (
                              <TableRow>
                                <TableCell className="text-container">{t('common:firstName')}</TableCell>
                                <TableCell className="text-container">
                                  {visual?.firstName ? visual.firstName.join(' ') : '---'}
                                </TableCell>
                                <TableCell className="text-container">
                                  {mrz?.firstName ? mrz.firstName.join(' ') : '---'}
                                </TableCell>
                                <TableCell className="text-container">
                                  {barcode?.firstName ? barcode.firstName.join(' ') : '---'}
                                </TableCell>
                              </TableRow>
                            )}
                            {(visual?.lastName || mrz?.lastName || barcode?.lastName) && (
                              <TableRow>
                                <TableCell className="text-container">{t('common:lastName')}</TableCell>
                                <TableCell className="text-container">
                                  {visual?.lastName ? visual.lastName.join(' ') : '---'}
                                </TableCell>
                                <TableCell className="text-container">
                                  {mrz?.lastName ? mrz.lastName.join(' ') : '---'}
                                </TableCell>
                                <TableCell className="text-container">
                                  {barcode?.lastName ? barcode.lastName.join(' ') : '---'}
                                </TableCell>
                              </TableRow>
                            )}
                            {(visual?.dob || mrz?.dob || barcode?.dob) && (
                              <TableRow>
                                <TableCell className="text-container">{t('common:dateOfBirth')}</TableCell>
                                <TableCell className="text-container">
                                  {visual?.dob ? renderDate(visual.dob) : '---'}
                                </TableCell>
                                <TableCell className="text-container">
                                  {mrz?.dob ? renderDate(mrz.dob) : '---'}
                                </TableCell>
                                <TableCell className="text-container">
                                  {barcode?.dob ? renderDate(barcode.dob) : '---'}
                                </TableCell>
                              </TableRow>
                            )}
                            {(visual?.birthPlace || mrz?.birthPlace || barcode?.birthPlace) && (
                              <TableRow>
                                <TableCell className="text-container">Birth place</TableCell>
                                <TableCell className="text-container">
                                  {visual?.birthPlace ? visual.birthPlace : '---'}
                                </TableCell>
                                <TableCell className="text-container">
                                  {mrz?.birthPlace ? mrz.birthPlace : '---'}
                                </TableCell>
                                <TableCell className="text-container">
                                  {barcode?.birthPlace ? barcode.birthPlace : '---'}
                                </TableCell>
                              </TableRow>
                            )}
                            {(visual?.documentNumber || mrz?.documentNumber || barcode?.documentNumber) && (
                              <TableRow>
                                <TableCell className="text-container">ID number</TableCell>
                                <TableCell className="text-container">
                                  {visual?.documentNumber ? visual.documentNumber : '---'}
                                </TableCell>
                                <TableCell className="text-container">
                                  {mrz?.documentNumber ? mrz.documentNumber : '---'}
                                </TableCell>
                                <TableCell className="text-container">
                                  {barcode?.documentNumber ? barcode.documentNumber : '---'}
                                </TableCell>
                              </TableRow>
                            )}
                            {(visual?.personalNumber || mrz?.personalNumber || barcode?.personalNumber) && (
                              <TableRow>
                                <TableCell className="text-container">Personal number</TableCell>
                                <TableCell className="text-container">
                                  {visual?.personalNumber ? visual.personalNumber : '---'}
                                </TableCell>
                                <TableCell className="text-container">
                                  {mrz?.personalNumber ? mrz.personalNumber : '---'}
                                </TableCell>
                                <TableCell className="text-container">
                                  {barcode?.personalNumber ? barcode.personalNumber : '---'}
                                </TableCell>
                              </TableRow>
                            )}
                            {(visual?.issuingCountry || mrz?.issuingCountry || barcode?.issuingCountry) && (
                              <TableRow>
                                <TableCell className="text-container">Issuing country</TableCell>
                                <TableCell className="text-container">
                                  {visual?.issuingCountry ? visual.issuingCountry : '---'}
                                </TableCell>
                                <TableCell className="text-container">
                                  {mrz?.issuingCountry ? mrz.issuingCountry : '---'}
                                </TableCell>
                                <TableCell className="text-container">
                                  {barcode?.issuingCountry ? barcode.issuingCountry : '---'}
                                </TableCell>
                              </TableRow>
                            )}
                            {(visual?.issuingPlace || mrz?.issuingPlace || barcode?.issuingPlace) && (
                              <TableRow>
                                <TableCell className="text-container">Issuing place</TableCell>
                                <TableCell className="text-container">
                                  {visual?.issuingPlace ? visual.issuingPlace : '---'}
                                </TableCell>
                                <TableCell className="text-container">
                                  {mrz?.issuingPlace ? mrz.issuingPlace : '---'}
                                </TableCell>
                                <TableCell className="text-container">
                                  {barcode?.issuingPlace ? barcode.issuingPlace : '---'}
                                </TableCell>
                              </TableRow>
                            )}
                            {(visual?.issuingDate || mrz?.issuingDate || barcode?.issuingDate) && (
                              <TableRow>
                                <TableCell className="text-container">Issuing date</TableCell>
                                <TableCell className="text-container">
                                  {visual?.issuingDate ? renderDate(visual.issuingDate) : '---'}
                                </TableCell>
                                <TableCell className="text-container">
                                  {mrz?.issuingDate ? renderDate(mrz.issuingDate) : '---'}
                                </TableCell>
                                <TableCell className="text-container">
                                  {barcode?.issuingDate ? renderDate(barcode.issuingDate) : '---'}
                                </TableCell>
                              </TableRow>
                            )}
                            {(visual?.expirationDate || mrz?.expirationDate || barcode?.expirationDate) && (
                              <TableRow>
                                <TableCell className="text-container">Expiration date</TableCell>
                                <TableCell className="text-container">
                                  {visual?.expirationDate ? renderDate(visual.expirationDate) : '---'}
                                </TableCell>
                                <TableCell className="text-container">
                                  {mrz?.expirationDate ? renderDate(mrz.expirationDate) : '---'}
                                </TableCell>
                                <TableCell className="text-container">
                                  {barcode?.expirationDate ? renderDate(barcode.expirationDate) : '---'}
                                </TableCell>
                              </TableRow>
                            )}
                            {(visual?.entityNameNative || mrz?.entityNameNative || barcode?.entityNameNative) && (
                              <TableRow>
                                <TableCell className="text-container">Full name (native)</TableCell>
                                <TableCell className="text-container">
                                  {visual?.entityNameNative
                                    ? visual.entityNameNative + ' ' + visual.lastNameNative
                                    : '---'}
                                </TableCell>
                                <TableCell className="text-container">
                                  {mrz?.entityNameNative ? mrz.entityNameNative + ' ' + mrz.lastNameNative : '---'}
                                </TableCell>
                                <TableCell className="text-container">
                                  {barcode?.entityNameNative
                                    ? barcode.entityNameNative + ' ' + barcode.lastNameNative
                                    : '---'}
                                </TableCell>
                              </TableRow>
                            )}
                            {(visual?.firstNameNative || mrz?.firstNameNative || barcode?.firstNameNative) && (
                              <TableRow>
                                <TableCell className="text-container">First name (native)</TableCell>
                                <TableCell className="text-container">
                                  {visual?.firstNameNative ? visual.firstNameNative : '---'}
                                </TableCell>
                                <TableCell className="text-container">
                                  {mrz?.firstNameNative ? mrz.firstNameNative : '---'}
                                </TableCell>
                                <TableCell className="text-container">
                                  {barcode?.firstNameNative ? barcode.firstNameNative : '---'}
                                </TableCell>
                              </TableRow>
                            )}
                            {(visual?.lastNameNative || mrz?.lastNameNative || barcode?.lastNameNative) && (
                              <TableRow>
                                <TableCell className="text-container">Last name (native)</TableCell>
                                <TableCell className="text-container">
                                  {visual?.lastNameNative ? visual.lastNameNative : '---'}
                                </TableCell>
                                <TableCell className="text-container">
                                  {mrz?.lastNameNative ? mrz.lastNameNative : '---'}
                                </TableCell>
                                <TableCell className="text-container">
                                  {barcode?.lastNameNative ? barcode.lastNameNative : '---'}
                                </TableCell>
                              </TableRow>
                            )}
                            {(visual?.birthPlaceNative || mrz?.birthPlaceNative || barcode?.birthPlaceNative) && (
                              <TableRow>
                                <TableCell className="text-container">Birth place (native)</TableCell>
                                <TableCell className="text-container">
                                  {visual?.birthPlaceNative ? visual.birthPlaceNative : '---'}
                                </TableCell>
                                <TableCell className="text-container">
                                  {mrz?.birthPlaceNative ? mrz.birthPlaceNative : '---'}
                                </TableCell>
                                <TableCell className="text-container">
                                  {barcode?.birthPlaceNative ? barcode.birthPlaceNative : '---'}
                                </TableCell>
                              </TableRow>
                            )}
                            {(visual?.taxIdentificationNumber ||
                              mrz?.taxIdentificationNumber ||
                              barcode?.taxIdentificationNumber) && (
                              <TableRow>
                                <TableCell className="text-container">Tax identification number</TableCell>
                                <TableCell className="text-container">
                                  {visual?.taxIdentificationNumber ? visual.taxIdentificationNumber : '---'}
                                </TableCell>
                                <TableCell className="text-container">
                                  {mrz?.taxIdentificationNumber ? mrz.taxIdentificationNumber : '---'}
                                </TableCell>
                                <TableCell className="text-container">
                                  {barcode?.taxIdentificationNumber ? barcode.taxIdentificationNumber : '---'}
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                  </TabPanel>

                  <TabPanel value="securityAndPatterns" className="onboarding-details-top">
                    <Typography variant="title">{t('common:securityAndPatterns')}</Typography>
                    {onboardingDetail.type === 'document_check' &&
                      securityAndPatternAnalysis &&
                      securityAndPatternAnalysis.length !== 0 && (
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell className="text-container">{t('common:expectedPattern')}</TableCell>
                                <TableCell className="text-container">{t('common:actualPattern')}</TableCell>
                                <TableCell className="text-container">{t('common:similarity')}</TableCell>
                                <TableCell className="text-container">{t('common:outcome')}</TableCell>
                              </TableRow>
                            </TableHead>

                            <TableBody>
                              {securityAndPatternAnalysis.map((item, index) => (
                                <TableRow key={index}>
                                  <TableCell className="text-container">
                                    {item.expectedImageData ? (
                                      <img
                                        className="pattern-image"
                                        src={'data:image/jpeg;base64,' + item.expectedImageData}
                                        alt="Base64"
                                      />
                                    ) : (
                                      '---'
                                    )}
                                  </TableCell>
                                  <TableCell className="text-container">
                                    {item.actualImageData ? (
                                      <img
                                        className="pattern-image"
                                        src={'data:image/jpeg;base64,' + item.actualImageData}
                                        alt="Base64"
                                      />
                                    ) : (
                                      '---'
                                    )}
                                  </TableCell>
                                  <TableCell className="text-container">
                                    {item.similarity || item.similarity === 0 ? item.similarity + '%' : '---'}
                                  </TableCell>
                                  <TableCell className="text-container">
                                    {item.narrative && item.outcome ? checkRender(item.narrative, item.outcome) : '---'}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      )}
                  </TabPanel>

                  <TabPanel value="receivedImages" className="onboarding-details-top">
                    <Typography variant="title">{t('common:receivedImages')}</Typography>
                    {receivedImages && Object.keys(receivedImages).length !== 0 && (
                      <Box className="onboarding-details-top">
                        {receivedImages.selfie && (
                          <Box className="img-pair">
                            <Typography variant="title3">{t('common:selfie')}</Typography>
                            <img className="received-imgs" src={receivedImages.selfie} alt="Base64" />
                          </Box>
                        )}
                        {receivedImages.documentImage && (
                          <Box className="img-pair">
                            <Typography variant="title3">{t('common:frontsideDocumentImage')}</Typography>
                            <img className="received-imgs" src={receivedImages.documentImage} alt="Base64" />
                          </Box>
                        )}

                        {receivedImages.backDocumentImage && (
                          <Box className="img-pair">
                            <Typography variant="title3">{t('common:backsideDocumentImage')}</Typography>
                            <img className="received-imgs" src={receivedImages.backDocumentImage} alt="Base64" />
                          </Box>
                        )}
                      </Box>
                    )}
                  </TabPanel>
                </Paper>
              </Box>
            </TabContext>

            {onboardingDetail.statusCode && (
              <Box className="error-msg">
                <Button size="small" variant="outlined" color="error">
                  Error Check ID not Foud. Please contact our support team.
                </Button>
              </Box>
            )}
            {onboardingDetail && !onboardingDetail.statusCode && (
              <Box className="check-body">
                {/* {onboardingDetail.type === 'document_check' && (
                  <Box className="check-body-item">
                    <Box className="check-body-item-row">
                      <h2>Summary information</h2>
                    </Box>
                    {extractedData && (
                      <Box className="cols">
                        <Box className="col-1">
                          {extractedImages && <ImageSlider images={extractedImages.map((img) => img.data)} />}
                        </Box>
                        {documentDetails && (
                          <Box className="col-2">
                            <h3>{documentDetails.documentSubtypes ? documentDetails.documentSubtypes[0] : '---'}</h3>
                            <Box className="check-body-item-row-4">
                              <Box className="sub-item-column">
                                <InputLabel>{t('common:type')}</InputLabel>
                                <p>{documentDetails.documentType ? documentDetails.documentType : '---'}</p>
                              </Box>
                              <Box className="sub-item-column">
                                <InputLabel>Issuing Country</InputLabel>
                                <Box>
                                  {documentDetails.issuingCountry ? Flags(documentDetails.issuingCountry) : '---'}
                                </Box>
                              </Box>
                              <Box className="sub-item-column">
                                <InputLabel>ID Number</InputLabel>
                                <p>{documentDetails.documentNumber ? documentDetails.documentNumber : '---'}</p>
                              </Box>
                              <Box className="sub-item-column">
                                <InputLabel>Expiration Date</InputLabel>
                                <p>
                                  {documentDetails.expirationDate ? renderDate(documentDetails.expirationDate) : '---'}
                                </p>
                              </Box>
                              <Box className="sub-item-column">
                                <InputLabel>First Name</InputLabel>
                                <p>
                                  {extractedData.holderDetails?.firstName
                                    ? extractedData.holderDetails.firstName.join(' ')
                                    : '---'}
                                </p>
                              </Box>
                              <Box className="sub-item-column">
                                <InputLabel>Last Name</InputLabel>
                                <p>
                                  {extractedData.holderDetails?.lastName
                                    ? extractedData.holderDetails.lastName.join(' ')
                                    : '---'}
                                </p>
                              </Box>
                              <Box className="sub-item-column">
                                <InputLabel>{t('common:dateOfBirth')}</InputLabel>
                                <p>
                                  {extractedData.holderDetails?.dob
                                    ? renderDate(extractedData.holderDetails.dob)
                                    : '---'}
                                </p>
                              </Box>
                              <Box className="sub-item-column">
                                <InputLabel>Issuing Country</InputLabel>
                                <Box>
                                  {extractedData.holderDetails?.nationality
                                    ? Flags(extractedData.holderDetails.nationality)
                                    : '---'}
                                </Box>
                              </Box>
                              {extractedData.allExtractedData?.visual?.personalNumber && (
                                <Box className="sub-item-column">
                                  <InputLabel>Personal Number</InputLabel>
                                  <p>{extractedData.allExtractedData?.visual?.personalNumber}</p>
                                </Box>
                              )}
                              {!extractedData.allExtractedData?.visual?.personalNumber && (
                                <Box className="sub-item-column">
                                  <InputLabel>Personal Number</InputLabel>
                                  <p>
                                    {extractedData.allExtractedData?.barcode
                                      ? extractedData.allExtractedData.barcode.personalNumber
                                      : '---'}
                                  </p>
                                </Box>
                              )}
                              <Box className="sub-item-column">
                                <InputLabel>Tax Number</InputLabel>
                                <p>
                                  {extractedData.holderDetails?.taxIdentificationNumber
                                    ? extractedData.holderDetails.taxIdentificationNumber
                                    : '---'}
                                </p>
                              </Box>
                              <Box className="sub-item-column">
                                <InputLabel>MRZ Line 1</InputLabel>
                                <p className="word-break">
                                  {extractedData.mrz?.line1 ? extractedData.mrz.line1 : '---'}
                                </p>
                              </Box>
                              <Box className="sub-item-column">
                                <InputLabel>MRZ Line 2</InputLabel>
                                <p className="word-break">
                                  {extractedData.mrz?.line2 ? extractedData.mrz.line2 : '---'}
                                </p>
                              </Box>
                            </Box>
                          </Box>
                        )}
                      </Box>
                    )}
                  </Box>
                )} */}
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default OnboardingDetail

import { createSlice } from '@reduxjs/toolkit'

export const userAdminSlice = createSlice({
  name: 'userAdmin',
  initialState: {
    users: [],
    filters: {
      viewMode: true,
      page: 0,
      limit: 10,
      fromDate: null,
      toDate: '',
      offset: 0,
      emailClient: '',
      email: '',
      status: '',
      roles: [],
    },
  },
  reducers: {
    updateUsersAdmin: (state, action) => {
      state.users = action.payload
    },
    performLogoutUserAdmin: (state) => {
      state.users = []
    },
    updateUsersAdminFilters: (state, filters) => {
      state.filters = filters.payload
    },
  },
})

export const { updateUsersAdmin, performLogoutUserAdmin, updateUsersAdminFilters } = userAdminSlice.actions
export default userAdminSlice.reducer

import { useEffect, useState } from 'react'
import './minMaxValues.scss'
import { Box, InputLabel, TextField } from '@mui/material'

const MinMaxValues = ({ rule, propertie, ruleParameters, setRuleParameters, isEditing }) => {
  const [values, setValues] = useState({})
  const [change, setChange] = useState(false)

  const [minTitle, setMinTitle] = useState('')
  const [maxTitle, setMaxTitle] = useState('')
  const [pair, setPair] = useState([])
  const [inputType, setInputType] = useState('')

  useEffect(() => {
    setValues(getMinMaxState())
    setMinTitle(getMinTitle())
    setMaxTitle(getMaxTitle())
    setPair(getMinMaxPair())
    setInputType(getMinMaxType())
  }, [rule])

  const getMinMaxState = () => {
    if (ruleParameters[propertie]) return ruleParameters[propertie]
    if (propertie === 'ageRange') return { minAge: '', maxAge: '' }
    if (propertie === 'checkTimeWindow') return { from: '', to: '' }
    if (
      propertie === 'transactionsNumberThreshold' ||
      propertie === 'transactionNumberThresholdInFirstPeriod' ||
      propertie === 'transactionsNumberThreshold2' ||
      propertie === 'transactionNumberThresholdInSecondPeriod' ||
      propertie === 'averageThreshold' ||
      propertie === 'avgNumberThresholdFirstPeriod' ||
      propertie === 'transactionAmountRange' ||
      propertie === 'valueThresholdPeriod1'
    )
      return { min: '', max: '' }
  }

  const getMinTitle = () => {
    if (propertie === 'ageRange') return rule.parametersSchema.properties[propertie].properties.minAge.title
    if (propertie === 'checkTimeWindow') return rule.parametersSchema.properties[propertie].properties.from.title
    if (propertie === 'transactionAmountRange') return 'Min'
    if (
      propertie === 'transactionsNumberThreshold' ||
      propertie === 'transactionNumberThresholdInFirstPeriod' ||
      propertie === 'transactionsNumberThreshold2' ||
      propertie === 'transactionNumberThresholdInSecondPeriod' ||
      propertie === 'averageThreshold' ||
      propertie === 'avgNumberThresholdFirstPeriod' ||
      propertie === 'valueThresholdPeriod1'
    )
      return rule.parametersSchema.properties[propertie].properties.min.title
  }

  const getMaxTitle = () => {
    if (propertie === 'ageRange') return rule.parametersSchema.properties[propertie].properties.maxAge.title
    if (propertie === 'checkTimeWindow') return rule.parametersSchema.properties[propertie].properties.to.title
    if (propertie === 'transactionAmountRange') return 'Max'
    if (
      propertie === 'transactionsNumberThreshold' ||
      propertie === 'transactionNumberThresholdInFirstPeriod' ||
      propertie === 'transactionsNumberThreshold2' ||
      propertie === 'transactionNumberThresholdInSecondPeriod' ||
      propertie === 'averageThreshold' ||
      propertie === 'avgNumberThresholdFirstPeriod' ||
      propertie === 'valueThresholdPeriod1'
    )
      return rule.parametersSchema.properties[propertie].properties.max.title
  }

  const getMinMaxPair = () => {
    if (propertie === 'ageRange') return ['minAge', 'maxAge']
    if (propertie === 'checkTimeWindow') return ['from', 'to']
    if (
      propertie === 'transactionsNumberThreshold' ||
      propertie === 'transactionNumberThresholdInFirstPeriod' ||
      propertie === 'transactionsNumberThreshold2' ||
      propertie === 'transactionNumberThresholdInSecondPeriod' ||
      propertie === 'transactionAmountRange' ||
      propertie === 'averageThreshold' ||
      propertie === 'avgNumberThresholdFirstPeriod' ||
      propertie === 'valueThresholdPeriod1'
    )
      return ['min', 'max']
  }

  const getMinMaxType = () => {
    if (propertie === 'checkTimeWindow') return 'string'
    if (
      propertie === 'ageRange' ||
      propertie === 'transactionsNumberThreshold' ||
      propertie === 'transactionNumberThresholdInFirstPeriod' ||
      propertie === 'transactionsNumberThreshold2' ||
      propertie === 'transactionNumberThresholdInSecondPeriod' ||
      propertie === 'transactionAmountRange' ||
      propertie === 'averageThreshold' ||
      propertie === 'avgNumberThresholdFirstPeriod' ||
      propertie === 'valueThresholdPeriod1'
    )
      return 'number'
  }

  const handler = (val, item) => {
    if (pair) {
      if (pair[0] === 'from' && item === 'from') {
        setValues({ ...values, from: val ? val : '' })
      } else if (pair[0] === 'minAge' && item === 'minAge') {
        setValues({ ...values, minAge: val ? parseInt(val) : '' })
      } else if (pair[0] === 'min' && item === 'min') {
        setValues({ ...values, min: val ? parseInt(val) : '' })
      } else if (pair[1] === 'to' && item === 'to') {
        setValues({ ...values, to: val ? val : '' })
      } else if (pair[1] === 'maxAge' && item === 'maxAge') {
        setValues({ ...values, maxAge: val ? parseInt(val) : '' })
      } else if (pair[1] === 'max' && item === 'max') {
        setValues({ ...values, max: val ? parseInt(val) : '' })
      }
      setChange(true)
    }
  }

  const getValue = (item) => {
    if (pair) {
      if (pair[0] === 'from' && item === 'from') {
        return values['from']
      } else if (pair[0] === 'minAge' && item === 'minAge') {
        return values['minAge']
      } else if (pair[0] === 'min' && item === 'min') {
        return values['min']
      } else if (pair[1] === 'to' && item === 'to') {
        return values['to']
      } else if (pair[1] === 'maxAge' && item === 'maxAge') {
        return values['maxAge']
      } else if (pair[1] === 'max' && item === 'max') {
        return values['max']
      }
    }
  }

  const deleteEmpty = () => {
    let aux = {}
    Object.keys(values).forEach((value) => {
      if (values[value] !== '') aux[value] = values[value]
    })
    return aux
  }

  useEffect(() => {
    if (change) {
      let all = { ...ruleParameters }
      let filteredValues = deleteEmpty()

      if (Object.keys(filteredValues).length === 0) delete all[propertie]
      else all[propertie] = filteredValues

      setRuleParameters({ ...all })
      setChange(false)
    }
  }, [values, change])

  return (
    <Box>
      {pair && (
        <Box className="minmax-container">
          <Box className="minmax-row">
            <InputLabel>{minTitle}</InputLabel>
            <TextField
              placeholder={`Enter ${inputType}`}
              disabled={!isEditing}
              variant="outlined"
              type={inputType}
              size="small"
              value={getValue(pair[0])}
              onChange={(event) => handler(event.target.value, pair[0])}
            />
          </Box>
          <Box className="minmax-row">
            <InputLabel>{maxTitle}</InputLabel>
            <TextField
              placeholder={`Enter ${inputType}`}
              disabled={!isEditing}
              variant="outlined"
              type={inputType}
              size="small"
              value={getValue(pair[1])}
              onChange={(event) => handler(event.target.value, pair[1])}
            />
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default MinMaxValues

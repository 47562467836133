import { createSlice } from '@reduxjs/toolkit'

export const kybListSlice = createSlice({
  name: 'kybList',
  initialState: {
    list: {},
    filters: {
      page: 0,
      limit: 25,
      offset: 0,
      fromDate: null,
      toDate: '',
      id: '',
      status: null,
      name: '',
      documentNumber: '',
    },
  },
  reducers: {
    updateKybList: (state, action) => {
      state.list = action.payload
    },
    updateKybListFilters: (state, action) => {
      state.filters = action.payload
    },
  },
})

export const { updateKybList, updateKybListFilters } = kybListSlice.actions
export default kybListSlice.reducer

import { buildTimeStringFromTimestamp } from '../../time/timeHelper'

export const kytRuleReportDashboardDataMapperForPDF = (data) => {
  const header = [
    'Action Owner',
    'Action',
    'Status',
    'Date',
    'RuleId',
    'Rule Instance Id',
    'Rule Name',
    'Action',
    'Case Priority',
    'Type',
    'Rule Status',
  ]
  const parsedData = data.map((rule) => {
    return [
      rule.emailUser,
      rule.activityDetails?.abmAction,
      rule.activityDetails?.actionResult,
      buildTimeStringFromTimestamp(rule.createdAt),
      rule.activityDetails?.newRecord?.ruleId,
      rule.activityDetails?.newRecord?.id,
      rule.activityDetails?.newRecord?.ruleNameAlias,
      rule.activityDetails?.newRecord?.action,
      rule.activityDetails?.newRecord?.casePriority,
      rule.activityDetails?.newRecord?.type,
      rule.activityDetails?.newRecord?.status,
    ]
  })

  return {
    columns: header,
    body: parsedData,
  }
}

export const kytRuleReportDashboardDataMapper = (data) => {
  return data.map((rule) => {
    return {
      ActionOwner: rule.emailUser,
      Action: rule.activityDetails?.abmAction,
      Status: rule.activityDetails?.actionResult,
      Date: buildTimeStringFromTimestamp(rule.createdAt),
      RuleId: rule.activityDetails?.newRecord?.ruleId ? rule.activityDetails?.newRecord?.ruleId : '',
      RuleInstanceId: rule.activityDetails?.newRecord?.id ? rule.activityDetails?.newRecord?.id : '',
      RuleName: rule.activityDetails?.newRecord?.ruleNameAlias ? rule.activityDetails?.newRecord?.ruleNameAlias : '',
      RuleAction: rule.activityDetails?.newRecord?.action ? rule.activityDetails?.newRecord?.action : '',
      CasePriority: rule.activityDetails?.newRecord?.casePriority ? rule.activityDetails?.newRecord?.casePriority : '',
      Type: rule.activityDetails?.newRecord?.type ? rule.activityDetails?.newRecord?.type : '',
      RuleStatus: rule.activityDetails?.newRecord?.status ? rule.activityDetails?.newRecord?.status : '',
    }
  })
}

import '../home/home.scss'
import './batch.scss'
import { Box, Button, Paper, Typography } from '@mui/material'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutline'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DatatableBatch from '../../components/common/datatable/DatatableBatch'
import { useBatches } from '../pages-hooks/useBatches'
import Spinner from '../../components/common/spinner/spinner'
import ModalCreteBatch from './components/ModalCreateBatch'
import { useSelector } from 'react-redux'

function Batch() {
  const { accessToken } = useSelector((state) => state.login)
  const { t } = useTranslation(['common'])
  const [isLoading, setIsLoading] = useState(true)
  const [openAlert, setOpenAlert] = useState(false)
  const [showCreateBatch, setShowCreateBatch] = useState(false)

  useBatches(setIsLoading, isLoading)

  const handleCreateBatch = async (batchType, file) => {
    const urlForCreateBatch = `${process.env.REACT_APP_BASEURL}/batch/kyt/${batchType.toLocaleLowerCase()}`

    const formData = new FormData()
    formData.append('file', file)
    const options = {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }

    try {
      fetch(urlForCreateBatch, options).catch((err) => {
        throw new Error(err.message || 'Create batch unexpected error')
      })
      setIsLoading(true)
    } catch (error) {
      console.error('[HOOK: useBatches | sendCorrectionFile] --> ', error)
      setIsLoading(true)
      return {
        message: error.message || 'An error occurred',
        severity: true,
      }
    }
  }

  useEffect(() => {
    if (openAlert) {
      setTimeout(() => {
        setOpenAlert(false)
      }, 2500)
    }
  }, [openAlert])

  return (
    <Box className={`${isLoading && 'spinner-transition'} filter`}>
      {isLoading && <Spinner noTransform />}
      <ModalCreteBatch
        showCreateBatch={showCreateBatch}
        setShowCreateBatch={setShowCreateBatch}
        handleCreateBatch={handleCreateBatch}
      />
      <Box>
        <Box className="component-title-wrapper">
          <Box className="component-title">
            <Typography variant="h2">{t('common:batchLoading')}</Typography>
            <Button
              variant="outlined"
              onClick={() => {
                setShowCreateBatch(true)
              }}
              startIcon={<AddCircleOutlineOutlinedIcon />}
            >
              {`${t('common:CREATE_BATCH')}`}
            </Button>
          </Box>
        </Box>
        <Box className="batch_table">
          <Paper elevation={0}>
            <DatatableBatch isLoading={isLoading} setIsLoading={setIsLoading} />
          </Paper>
        </Box>
      </Box>
    </Box>
  )
}

export default Batch

import { useEffect, useState } from 'react'
import NumberInput from '../numberInput/numberInput'
import TimeWindow from '../timeWindow/timeWindow'

const TransactionCountThreshold = ({
  property,
  ruleParameters,
  setRuleParameters,
  granularity,
  desc,
  numberInputSchema,
  timeWindowSchema,
  rollingBasis = false,
  setError,
  errorList,
  setErrorList,
  isEditing,
}) => {
  const [finalParameter, setFinalParameter] = useState({})
  const [change, setChange] = useState(false)

  useEffect(() => {
    if (ruleParameters[property] && Object.keys(finalParameter).length === 0) {
      setFinalParameter({ ...ruleParameters[property] })
    }
  }, [property, ruleParameters, finalParameter])

  useEffect(() => {
    if (change) {
      let all = { ...ruleParameters }
      all[property] = finalParameter
      setRuleParameters({ ...all })
      setChange(false)
    }
  }, [change, finalParameter])

  const handleChange = (value) => {
    setFinalParameter(value)
    setChange(true)
  }

  return (
    <div className={'childContainer'}>
      <div>
        <div>
          <div className="Label">
            <span className="mandatory">* </span>
            <p>Threshold</p>
          </div>
          <NumberInput
            isEditing={isEditing}
            propertie={'threshold'}
            ruleParameters={finalParameter}
            setRuleParameters={handleChange}
            schema={numberInputSchema}
          />
          <TimeWindow
            isEditing={isEditing}
            propertie={'timeWindow'}
            ruleParameters={finalParameter}
            setRuleParameters={handleChange}
            granularity={granularity}
            desc={desc}
            schema={timeWindowSchema}
            rollingBasis={rollingBasis}
            setError={setError}
            errorList={errorList}
            setErrorList={setErrorList}
          />
        </div>
      </div>
    </div>
  )
}

export default TransactionCountThreshold

import './datatable.scss'
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { renderNames } from '../toRender/renderNames'
import { Flags } from '../flag/flags'
import { useTranslation } from 'react-i18next'
import CustomNoRowsOverlay from '../datagrid/CustomNoRowsOverlay'

const DatatableDocuments = ({ rows }) => {
  const { t } = useTranslation(['common'])

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('common:type')}</TableCell>
            <TableCell>{t('common:number')}</TableCell>
            <TableCell>
              {t('common:document')} {t('common:name')}
            </TableCell>
            <TableCell>{t('common:issuedDate')}</TableCell>
            <TableCell>{t('common:issuedCountry')}</TableCell>
            <TableCell>{t('common:expirationDate')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.length ? (
            rows?.map((row, index) => {
              const back = index % 2 === 0 ? '' : 'gray-back'
              return (
                <TableRow className={back} key={index}>
                  <TableCell>{row.documentType ? row.documentType : '-'}</TableCell>
                  <TableCell>{row.documentNumber ? row.documentNumber : '-'}</TableCell>
                  <TableCell>{row.nameOnDocument ? renderNames(row.nameOnDocument, 'Document') : '-'}</TableCell>
                  <TableCell>
                    {row.documentIssuedDate ? new Date(row.documentIssuedDate).toLocaleDateString() : '-'}
                  </TableCell>
                  <TableCell>{row.documentIssuedCountry ? Flags(row.documentIssuedCountry) : '-'}</TableCell>
                  <TableCell>
                    {row.documentExpirationDate ? new Date(row.documentExpirationDate).toLocaleDateString() : '-'}
                  </TableCell>
                </TableRow>
              )
            })
          ) : (
            <Box className="table-custom-overlay">
              <CustomNoRowsOverlay />
            </Box>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default DatatableDocuments

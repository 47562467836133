export const TX_METHODS = [
  'CARD',
  'GENERIC_BANK_ACCOUNT',
  'IBAN',
  'ACH',
  'SWIFT',
  'MPESA',
  'UPI',
  'WALLET',
  'CHECK',
  'BANK TRANSFER',
]

export const TX_TYPES = ['DEPOSIT', 'TRANSFER', 'EXTERNAL_PAYMENT', 'WITHDRAWAL', 'REFUND']

export const TX_STATES = ['CREATED', 'PROCESSING', 'SENT', 'EXPIRED', 'DECLINED', 'SUSPENDED', 'REFUNDED', 'SUCCESSFUL']

export const COUNTRIES = [
  'AF',
  'AL',
  'DZ',
  'AS',
  'AD',
  'AO',
  'AI',
  'AQ',
  'AG',
  'AR',
  'AM',
  'AW',
  'AU',
  'AT',
  'AZ',
  'BS',
  'BH',
  'BD',
  'BB',
  'BY',
  'BE',
  'BZ',
  'BJ',
  'BM',
  'BT',
  'BO',
  'BQ',
  'BA',
  'BW',
  'BV',
  'BR',
  'IO',
  'BN',
  'BG',
  'BF',
  'BI',
  'CV',
  'KH',
  'CM',
  'CA',
  'KY',
  'CF',
  'TD',
  'CL',
  'CN',
  'CX',
  'CC',
  'CO',
  'KM',
  'CD',
  'CG',
  'CK',
  'CR',
  'HR',
  'CU',
  'CW',
  'CY',
  'CZ',
  'CI',
  'DK',
  'DJ',
  'DM',
  'DO',
  'EC',
  'EG',
  'SV',
  'GQ',
  'ER',
  'EE',
  'SZ',
  'ET',
  'FK',
  'FO',
  'FJ',
  'FI',
  'FR',
  'GF',
  'PF',
  'TF',
  'GA',
  'GM',
  'GE',
  'DE',
  'GH',
  'GI',
  'GR',
  'GL',
  'GD',
  'GP',
  'GU',
  'GT',
  'GG',
  'GN',
  'GW',
  'GY',
  'HT',
  'HM',
  'VA',
  'HN',
  'HK',
  'HU',
  'IS',
  'IN',
  'ID',
  'IR',
  'IQ',
  'IE',
  'IM',
  'IL',
  'IT',
  'JM',
  'JP',
  'JE',
  'JO',
  'KZ',
  'KE',
  'KI',
  'KP',
  'KR',
  'KW',
  'KG',
  'LA',
  'LV',
  'LB',
  'LS',
  'LR',
  'LY',
  'LI',
  'LT',
  'LU',
  'MO',
  'MG',
  'MW',
  'MY',
  'MV',
  'ML',
  'MT',
  'MH',
  'MQ',
  'MR',
  'MU',
  'YT',
  'MX',
  'FM',
  'MD',
  'MC',
  'MN',
  'ME',
  'MS',
  'MA',
  'MZ',
  'MM',
  'NA',
  'NR',
  'NP',
  'NL',
  'NC',
  'NZ',
  'NI',
  'NE',
  'NG',
  'NU',
  'NF',
  'MK',
  'MP',
  'NO',
  'OM',
  'PK',
  'PW',
  'PS',
  'PA',
  'PG',
  'PY',
  'PE',
  'PH',
  'PN',
  'PL',
  'PT',
  'PR',
  'QA',
  'RO',
  'RU',
  'RW',
  'RE',
  'BL',
  'SH',
  'KN',
  'LC',
  'MF',
  'PM',
  'VC',
  'WS',
  'SM',
  'ST',
  'SA',
  'SN',
  'RS',
  'SC',
  'SL',
  'SG',
  'SX',
  'SK',
  'SI',
  'SB',
  'SO',
  'ZA',
  'GS',
  'SS',
  'ES',
  'LK',
  'SD',
  'SR',
  'SJ',
  'SE',
  'CH',
  'SY',
  'TW',
  'TJ',
  'TZ',
  'TH',
  'TL',
  'TG',
  'TK',
  'TO',
  'TT',
  'TN',
  'TM',
  'TC',
  'TV',
  'TR',
  'UG',
  'UA',
  'AE',
  'GB',
  'UM',
  'US',
  'UY',
  'UZ',
  'VU',
  'VE',
  'VN',
  'VG',
  'VI',
  'WF',
  'EH',
  'YE',
  'ZM',
  'ZW',
  'AX',
  'N/A',
]

export const CURRENCIES_AUX = {
  AED: {
    name: 'United Arab Emirates Dirham',
    demonym: 'UAE',
    type: 'fiat',
  },
  AFN: {
    name: 'Afghan Afghani',
    demonym: 'Afghan',
    type: 'fiat',
  },
  ALL: {
    name: 'Albanian Lek',
    demonym: 'Albanian',
    type: 'fiat',
  },
  AMD: {
    name: 'Armenian Dram',
    demonym: 'Armenian',
    type: 'fiat',
  },
  ANG: {
    name: 'Netherlands Antillean Guilder',
    demonym: 'Netherlands Antillean',
    type: 'fiat',
  },
  AOA: {
    name: 'Angolan Kwanza',
    demonym: 'Angolan',
    type: 'fiat',
  },
  ARS: {
    name: 'Argentine Peso',
    demonym: 'Argentine',
    type: 'fiat',
  },
  AUD: {
    name: 'Australian Dollar',
    demonym: 'Australian',
    type: 'fiat',
  },
  AWG: {
    name: 'Aruban Florin',
    demonym: 'Aruban',
    type: 'fiat',
  },
  AZN: {
    name: 'Azerbaijani Manat',
    demonym: 'Azerbaijani',
    type: 'fiat',
  },
  BAM: {
    name: 'Bosnia and Herzegovina Convertible Mark',
    demonym: 'Bosnia-Herzegovina',
    type: 'fiat',
  },
  BBD: {
    name: 'Barbadian Dollar',
    demonym: 'Barbadian',
    type: 'fiat',
  },
  BDT: {
    name: 'Bangladeshi Taka',
    demonym: 'Bangladeshi',
    type: 'fiat',
  },
  BGN: {
    name: 'Bulgarian Lev',
    demonym: 'Bulgarian',
    type: 'fiat',
  },
  BHD: {
    name: 'Bahraini Dinar',
    demonym: 'Bahraini',
    type: 'fiat',
  },
  BIF: {
    name: 'Burundian Franc',
    demonym: 'Burundian',
    type: 'fiat',
  },
  BMD: {
    name: 'Bermudian Dollar',
    demonym: 'Bermudian',
    type: 'fiat',
  },
  BND: {
    name: 'Brunei Dollar',
    demonym: 'Brunei',
    type: 'fiat',
  },
  BOB: {
    name: 'Bolivian Boliviano',
    demonym: 'Bolivian',
    type: 'fiat',
  },
  BRL: {
    name: 'Brazilian Real',
    demonym: 'Brazilian',
    type: 'fiat',
  },
  BSD: {
    name: 'Bahamian Dollar',
    demonym: 'Bahamian',
    type: 'fiat',
  },
  BTN: {
    name: 'Bhutanese Ngultrum',
    demonym: 'Bhutanese',
    type: 'fiat',
  },
  BWP: {
    name: 'Botswana Pula',
    demonym: 'Botswana',
    type: 'fiat',
  },
  BYN: {
    name: 'Belarusian Ruble',
    demonym: 'Belarusian',
    type: 'fiat',
  },
  BZD: {
    name: 'Belize Dollar',
    demonym: 'Belize',
    type: 'fiat',
  },
  CAD: {
    name: 'Canadian Dollar',
    demonym: 'Canadian',
    type: 'fiat',
  },
  CDF: {
    name: 'Congolese Franc',
    demonym: 'Congolese',
    type: 'fiat',
  },
  CHF: {
    name: 'Swiss Franc',
    demonym: 'Swiss',
    type: 'fiat',
  },
  CKD: {
    name: 'Cook Islands Dollar',
    demonym: 'Cook Islands',
    type: 'fiat',
  },
  CLP: {
    name: 'Chilean Peso',
    demonym: 'Chilean',
    type: 'fiat',
  },
  CNY: {
    name: 'Chinese Yuan',
    demonym: 'Chinese',
    type: 'fiat',
  },
  COP: {
    name: 'Colombian Peso',
    demonym: 'Colombian',
    type: 'fiat',
  },
  CRC: {
    name: 'Costa Rican Colon',
    demonym: 'Costa Rican',
    type: 'fiat',
  },
  CUC: {
    name: 'Cuban convertible Peso',
    demonym: 'Cuban Convertible',
    type: 'fiat',
  },
  CUP: {
    name: 'Cuban Peso',
    demonym: 'Cuban',
    type: 'fiat',
  },
  CVE: {
    name: 'Cabo Verdean Escudo',
    demonym: 'Cabo Verdean',
    type: 'fiat',
  },
  CZK: {
    name: 'Czech Koruna',
    demonym: 'Czech',
    type: 'fiat',
  },
  DJF: {
    name: 'Djiboutian Franc',
    demonym: 'Djiboutian',
    type: 'fiat',
  },
  DKK: {
    name: 'Danish Krone',
    demonym: 'Danish',
    type: 'fiat',
  },
  DOP: {
    name: 'Dominican Peso',
    demonym: 'Dominican',
    type: 'fiat',
  },
  DZD: {
    name: 'Algerian Dinar',
    demonym: 'Algerian',
    type: 'fiat',
  },
  EGP: {
    name: 'Egyptian Pound',
    demonym: 'Egyptian',
    type: 'fiat',
  },
  EHP: {
    name: 'Sahrawi Peseta',
    demonym: 'Sahrawi',
    type: 'fiat',
  },
  ERN: {
    name: 'Eritrean Nakfa',
    demonym: 'Eritrean',
    type: 'fiat',
  },
  ETB: {
    name: 'Ethiopian Birr',
    demonym: 'Ethiopian',
    type: 'fiat',
  },
  EUR: {
    name: 'Euro',
    demonym: '',
    type: 'fiat',
  },
  FJD: {
    name: 'Fijian Dollar',
    demonym: 'Fijian',
    type: 'fiat',
  },
  FKP: {
    name: 'Falkland Islands Pound',
    demonym: 'Falkland Islands',
    type: 'fiat',
  },
  FOK: {
    name: 'Faroese Króna',
    demonym: 'Faroese',
    type: 'fiat',
  },
  GBP: {
    name: 'Pound Sterling',
    type: 'fiat',
  },
  GEL: {
    name: 'Georgian Lari',
    demonym: 'Georgian',
    type: 'fiat',
  },
  GGP: {
    name: 'Guernsey Pound',
    demonym: 'Guernsey',
    type: 'fiat',
  },
  GHS: {
    name: 'Ghanaian Cedi',
    demonym: 'Ghanaian',
    type: 'fiat',
  },
  GIP: {
    name: 'Gibraltar Pound',
    demonym: 'Gibraltar',
    type: 'fiat',
  },
  GMD: {
    name: 'Gambian Dalasi',
    demonym: 'Gambian',
    type: 'fiat',
  },
  GNF: {
    name: 'Guinean Franc',
    demonym: 'Guinean',
    type: 'fiat',
  },
  GTQ: {
    name: 'Guatemalan Quetzal',
    demonym: 'Guatemalan',
    type: 'fiat',
  },
  GYD: {
    name: 'Guyanese Dollar',
    demonym: 'Guyanese',
    type: 'fiat',
  },
  HKD: {
    name: 'Hong Kong Dollar',
    demonym: 'Hong Kong',
    type: 'fiat',
  },
  HNL: {
    name: 'Honduran Lempira',
    demonym: 'Honduran',
    type: 'fiat',
  },
  HRK: {
    name: 'Croatian Kuna',
    demonym: 'Croatian',
    type: 'fiat',
  },
  HTG: {
    name: 'Haitian Gourde',
    demonym: 'Haitian',
    type: 'fiat',
  },
  HUF: {
    name: 'Hungarian Forint',
    demonym: 'Hungarian',
    type: 'fiat',
  },
  IDR: {
    name: 'Indonesian Rupiah',
    demonym: 'Indonesian',
    type: 'fiat',
  },
  ILS: {
    name: 'Israeli new Shekel',
    demonym: 'Israeli',
    type: 'fiat',
  },
  IMP: {
    name: 'Manx Pound',
    demonym: 'Manx',
    type: 'fiat',
  },
  INR: {
    name: 'Indian Rupee',
    demonym: 'Indian',
    type: 'fiat',
  },
  IQD: {
    name: 'Iraqi Dinar',
    demonym: 'Iraqi',
    type: 'fiat',
  },
  IRR: {
    name: 'Iranian Rial',
    demonym: 'Iranian',
    type: 'fiat',
  },
  ISK: {
    name: 'Icelandic Krona',
    demonym: 'Icelandic',
    type: 'fiat',
  },
  JEP: {
    name: 'Jersey Pound',
    demonym: 'Jersey',
    type: 'fiat',
  },
  JMD: {
    name: 'Jamaican Dollar',
    demonym: 'Jamaican',
    type: 'fiat',
  },
  JOD: {
    name: 'Jordanian Dinar',
    demonym: 'Jordanian',
    type: 'fiat',
  },
  JPY: {
    name: 'Japanese Yen',
    demonym: 'Japanese',
    type: 'fiat',
  },
  KES: {
    name: 'Kenyan Shilling',
    demonym: 'Kenyan',
    type: 'fiat',
  },
  KGS: {
    name: 'Kyrgyzstani Som',
    demonym: 'Kyrgyzstani',
    type: 'fiat',
  },
  KHR: {
    name: 'Cambodian Riel',
    demonym: 'Cambodian',
    type: 'fiat',
  },
  KID: {
    name: 'Kiribati Dollar',
    demonym: 'Kiribati',
    type: 'fiat',
  },
  KMF: {
    name: 'Comorian Franc',
    demonym: 'Comorian',
    type: 'fiat',
  },
  KPW: {
    name: 'North Korean Won',
    demonym: 'North Korean',
    type: 'fiat',
  },
  KRW: {
    name: 'South Korean Won',
    demonym: 'South Korean',
    type: 'fiat',
  },
  KWD: {
    name: 'Kuwaiti Dinar',
    demonym: 'Kuwaiti',
    type: 'fiat',
  },
  KYD: {
    name: 'Cayman Islands Dollar',
    demonym: 'Cayman Islands',
    type: 'fiat',
  },
  KZT: {
    name: 'Kazakhstani Tenge',
    demonym: 'Kazakhstani',
    type: 'fiat',
  },
  LAK: {
    name: 'Lao Kip',
    demonym: 'Lao',
    type: 'fiat',
  },
  LBP: {
    name: 'Lebanese Pound',
    demonym: 'Lebanese',
    type: 'fiat',
  },
  LKR: {
    name: 'Sri Lankan Rupee',
    demonym: 'Sri Lankan',
    type: 'fiat',
  },
  LRD: {
    name: 'Liberian Dollar',
    demonym: 'Liberian',
    type: 'fiat',
  },
  LSL: {
    name: 'Lesotho Loti',
    demonym: 'Lesotho',
    type: 'fiat',
  },
  LYD: {
    name: 'Libyan Dinar',
    demonym: 'Libyan',
    type: 'fiat',
  },
  MAD: {
    name: 'Moroccan Dirham',
    demonym: 'Moroccan',
    type: 'fiat',
  },
  MDL: {
    name: 'Moldovan Leu',
    demonym: 'Moldovan',
    type: 'fiat',
  },
  MGA: {
    name: 'Malagasy Ariary',
    demonym: 'Malagasy',
    type: 'fiat',
  },
  MKD: {
    name: 'Macedonian Denar',
    demonym: 'Macedonian',
    type: 'fiat',
  },
  MMK: {
    name: 'Myanmar Kyat',
    demonym: 'Myanmar',
    type: 'fiat',
  },
  MNT: {
    name: 'Mongolian Tögrög',
    demonym: 'Mongolian',
    type: 'fiat',
  },
  MOP: {
    name: 'Macanese Pataca',
    demonym: 'Macanese',
    type: 'fiat',
  },
  MRU: {
    name: 'Mauritanian Ouguiya',
    demonym: 'Mauritanian',
    type: 'fiat',
  },
  MUR: {
    name: 'Mauritian Rupee',
    demonym: 'Mauritian',
    type: 'fiat',
  },
  MVR: {
    name: 'Maldivian Rufiyaa',
    demonym: 'Maldivian',
    type: 'fiat',
  },
  MWK: {
    name: 'Malawian Kwacha',
    demonym: 'Malawian',
    type: 'fiat',
  },
  MXN: {
    name: 'Mexican Peso',
    demonym: 'Mexican',
    type: 'fiat',
  },
  MYR: {
    name: 'Malaysian Ringgit',
    demonym: 'Malaysian',
    type: 'fiat',
  },
  MZN: {
    name: 'Mozambican Metical',
    demonym: 'Mozambican',
    type: 'fiat',
  },
  NAD: {
    name: 'Namibian Dollar',
    demonym: 'Namibian',
    type: 'fiat',
  },
  NGN: {
    name: 'Nigerian Naira',
    demonym: 'Nigerian',
    type: 'fiat',
  },
  NIO: {
    name: 'Nicaraguan Córdoba',
    demonym: 'Nicaraguan',
    type: 'fiat',
  },
  NOK: {
    name: 'Norwegian Krone',
    demonym: 'Norwegian',
    type: 'fiat',
  },
  NPR: {
    name: 'Nepalese Rupee',
    demonym: 'Nepalese',
    type: 'fiat',
  },
  NZD: {
    name: 'New Zealand Dollar',
    demonym: 'New Zealand',
    type: 'fiat',
  },
  OMR: {
    name: 'Omani Rial',
    demonym: 'Omani',
    type: 'fiat',
  },
  PAB: {
    name: 'Panamanian Balboa',
    demonym: 'Panamanian',
    type: 'fiat',
  },
  PEN: {
    name: 'Peruvian Sol',
    demonym: 'Peruvian',
    type: 'fiat',
  },
  PGK: {
    name: 'Papua New Guinean Kina',
    demonym: 'Papua New Guinean',
    type: 'fiat',
  },
  PHP: {
    name: 'Philippine Peso',
    demonym: 'Philippine',
    type: 'fiat',
  },
  PKR: {
    name: 'Pakistani Rupee',
    demonym: 'Pakistani',
    type: 'fiat',
  },
  PLN: {
    name: 'Polish Zloty',
    demonym: 'Polish',
    type: 'fiat',
  },
  PND: {
    name: 'Pitcairn Islands Dollar',
    demonym: 'Pitcairn Islands',
    type: 'fiat',
  },
  PRB: {
    name: 'Transnistrian Ruble',
    demonym: 'Transnistrian',
    type: 'fiat',
  },
  PYG: {
    name: 'Paraguayan Guaraní',
    demonym: 'Paraguayan',
    type: 'fiat',
  },
  QAR: {
    name: 'Qatari Riyal',
    demonym: 'Qatari',
    type: 'fiat',
  },
  RON: {
    name: 'Romanian Leu',
    demonym: 'Romanian',
    type: 'fiat',
  },
  RSD: {
    name: 'Serbian Dinar',
    demonym: 'Serbian',
    type: 'fiat',
  },
  RUB: {
    name: 'Russian Ruble',
    demonym: 'Russian',
    type: 'fiat',
  },
  RWF: {
    name: 'Rwandan Franc',
    demonym: 'Rwandan',
    type: 'fiat',
  },
  SAR: {
    name: 'Saudi Riyal',
    demonym: 'Saudi',
    type: 'fiat',
  },
  SBD: {
    name: 'Solomon Islands Dollar',
    demonym: 'Solomon Islands',
    type: 'fiat',
  },
  SCR: {
    name: 'Seychellois Rupee',
    demonym: 'Seychellois',
    type: 'fiat',
  },
  SDG: {
    name: 'Sudanese Pound',
    demonym: 'Sudanese',
    type: 'fiat',
  },
  SEK: {
    name: 'Swedish Krona',
    demonym: 'Swedish',
    type: 'fiat',
  },
  SGD: {
    name: 'Singapore Dollar',
    demonym: 'Singapore',
    type: 'fiat',
  },
  SHP: {
    name: 'Saint Helena Pound',
    demonym: 'Saint Helena',
    type: 'fiat',
  },
  SLL: {
    name: 'Sierra Leonean Leone',
    demonym: 'Sierra Leonean',
    type: 'fiat',
  },
  SLS: {
    name: 'Somaliland Shilling',
    demonym: 'Somaliland',
    type: 'fiat',
  },
  SOS: {
    name: 'Somali Shilling',
    demonym: 'Somali',
    type: 'fiat',
  },
  SRD: {
    name: 'Surinamese Dollar',
    demonym: 'Surinamese',
    type: 'fiat',
  },
  SSP: {
    name: 'South Sudanese Pound',
    demonym: 'South Sudanese',
    type: 'fiat',
  },
  STN: {
    name: 'Sao Tome and Príncipe Dobra',
    demonym: 'Sao Tome',
    type: 'fiat',
  },
  SVC: {
    name: 'Salvadoran Colón',
    demonym: 'Salvadoran',
    type: 'fiat',
  },
  SYP: {
    name: 'Syrian Pound',
    demonym: 'Syrian',
    type: 'fiat',
  },
  SZL: {
    name: 'Swazi Lilangeni',
    demonym: 'Swazi',
    type: 'fiat',
  },
  THB: {
    name: 'Thai Baht',
    demonym: 'Thai',
    type: 'fiat',
  },
  TJS: {
    name: 'Tajikistani Somoni',
    demonym: 'Tajikistani',
    type: 'fiat',
  },
  TMT: {
    name: 'Turkmenistan Manat',
    demonym: 'Turkmenistan',
    type: 'fiat',
  },
  TND: {
    name: 'Tunisian Dinar',
    demonym: 'Tunisian',
    type: 'fiat',
  },
  TOP: {
    name: 'Tongan Paʻanga',
    demonym: 'Tongan',
    type: 'fiat',
  },
  TRY: {
    name: 'Turkish Lira',
    demonym: 'Turkish',
    type: 'fiat',
  },
  TTD: {
    name: 'Trinidad and Tobago Dollar',
    demonym: 'Trinidad and Tobago',
    type: 'fiat',
  },
  TVD: {
    name: 'Tuvaluan Dollar',
    demonym: 'Tuvaluan',
    type: 'fiat',
  },
  TWD: {
    name: 'New Taiwan Dollar',
    demonym: 'New Taiwan',
    type: 'fiat',
  },
  TZS: {
    name: 'Tanzanian Shilling',
    demonym: 'Tanzanian',
    type: 'fiat',
  },
  UAH: {
    name: 'Ukrainian Hryvnia',
    demonym: 'Ukrainian',
    type: 'fiat',
  },
  UGX: {
    name: 'Ugandan Shilling',
    demonym: 'Ugandan',
    type: 'fiat',
  },
  USD: {
    name: 'United States Dollar',
    demonym: 'US',
    type: 'fiat',
  },
  UYU: {
    name: 'Uruguayan Peso',
    demonym: 'Uruguayan',
    type: 'fiat',
  },
  UZS: {
    name: 'Uzbekistani Som',
    demonym: 'Uzbekistani',
    type: 'fiat',
  },
  VED: {
    name: 'Venezuelan bolívar digital',
    demonym: 'Venezuelan',
    type: 'fiat',
  },
  VES: {
    name: 'Venezuelan Bolívar Soberano',
    demonym: 'Venezuelan',
    type: 'fiat',
  },
  VND: {
    name: 'Vietnamese Dong',
    demonym: 'Vietnamese',
    type: 'fiat',
  },
  VUV: {
    name: 'Vanuatu Vatu',
    demonym: 'Vanuatu',
    type: 'fiat',
  },
  WST: {
    name: 'Samoan Tala',
    demonym: 'Samoan',
    type: 'fiat',
  },
  XAF: {
    name: 'Central African CFA Franc BEAC',
    demonym: 'Central African CFA',
    type: 'fiat',
  },
  XCD: {
    name: 'East Caribbean Dollar',
    demonym: 'East Caribbean',
    type: 'fiat',
  },
  XOF: {
    name: 'West African CFA Franc BCEAO',
    demonym: 'West African CFA',
    type: 'fiat',
  },
  XPF: {
    name: 'CFP Franc (Franc Pacifique)',
    demonym: 'CFP',
    type: 'fiat',
  },
  YER: {
    name: 'Yemeni Rial',
    demonym: 'Yemeni',
    type: 'fiat',
  },
  ZAR: {
    name: 'South African Rand',
    demonym: 'South African',
    type: 'fiat',
  },
  ZMW: {
    name: 'Zambian Kwacha',
    demonym: 'Zambian',
    type: 'fiat',
  },
  ZWB: {
    name: 'RTGS Dollar',
    demonym: 'RTGS',
    type: 'fiat',
  },
  ZWL: {
    name: 'Zimbabwean Dollar',
    demonym: 'Zimbabwean',
    type: 'fiat',
  },
  Abkhazia: {
    name: 'Abkhazian Apsar',
    demonym: 'Abkhazian',
    type: 'fiat',
  },
  Artsakh: {
    name: 'Artsakh Dram',
    demonym: 'Artsakh',
    type: 'fiat',
  },
  ADA: {
    name: 'Cardano',
    type: 'crypto',
  },
  ALGO: {
    name: 'Algorand',
    type: 'crypto',
  },
  ATOM: {
    name: 'Atomic Coin',
    type: 'crypto',
  },
  AVAX: {
    name: 'Avalanche',
    type: 'crypto',
  },
  BCH: {
    name: 'Bitcoin Cash',
    type: 'crypto',
  },
  BNB: {
    name: 'Binance Coin',
    type: 'crypto',
  },
  BUSD: {
    name: 'Binance USD',
    type: 'crypto',
  },
  USDC: {
    name: 'USDC Coin',
    type: 'crypto',
  },
  USDT: {
    name: 'Tether',
    type: 'crypto',
  },
  DAI: {
    name: 'Dai',
    type: 'crypto',
  },
  MATIC: { name: 'Polygon', type: 'crypto' },
}

export const CURRENCIES = Object.entries(CURRENCIES_AUX).map((c) => ({
  aka: c[0],
  type: c[1].type.replace(/(^\w{1})/g, (letter) => letter.toUpperCase()) + ' Currencies',
}))

export const CURRENCIES_MANUAL = [
  '1INCH',
  'AAVE',
  'ADA',
  'AED',
  'AFN',
  'ALGO',
  'ALL',
  'AMD',
  'AMP',
  'ANG',
  'AOA',
  'AR',
  'ARS',
  'ATOM',
  'AUD',
  'AVAX',
  'AWG',
  'AXS',
  'AZN',
  'BAM',
  'BAT',
  'BBD',
  'BCH',
  'BDT',
  'BGN',
  'BHD',
  'BIF',
  'BMD',
  'BNB',
  'BND',
  'BOB',
  'BRL',
  'BSD',
  'BSV',
  'BTC',
  'BTCB',
  'BTG',
  'BTN',
  'BUSD',
  'BWP',
  'BYN',
  'BYR',
  'BZD',
  'CAD',
  'CAKE',
  'CDF',
  'CELO',
  'CHF',
  'CHZ',
  'CLF',
  'CLP',
  'CNY',
  'COMP',
  'COP',
  'CRC',
  'CRO',
  'CRV',
  'CUC',
  'CUP',
  'CVE',
  'CVX',
  'CZK',
  'DAI',
  'DIF',
  'LSR',
  'SRC',
  'BRB',
  'DASH',
  'DCR',
  'DFI',
  'DJF',
  'DKK',
  'DOGE',
  'DOP',
  'DOT',
  'DZD',
  'EGLD',
  'EGP',
  'ENJ',
  'EOS',
  'ERN',
  'ETB',
  'ETC',
  'ETH',
  'EUR',
  'FEI',
  'FIL',
  'FJD',
  'FKP',
  'FLOW',
  'FRAX',
  'FTM',
  'FTT',
  'GALA',
  'GBP',
  'GEL',
  'GGP',
  'GHS',
  'GIP',
  'GMD',
  'GNF',
  'GNO',
  'GRT',
  'GT',
  'GTQ',
  'GYD',
  'HBAR',
  'HKD',
  'HNL',
  'HNT',
  'HOT',
  'HRK',
  'HT',
  'HTG',
  'HUF',
  'ICP',
  'IDR',
  'ILS',
  'IMP',
  'INJ',
  'INR',
  'IQD',
  'IRR',
  'ISK',
  'JEP',
  'JMD',
  'JOD',
  'JPY',
  'KAVA',
  'KCS',
  'KDA',
  'KES',
  'KGS',
  'KHR',
  'KLAY',
  'KMF',
  'KNC',
  'KPW',
  'KRW',
  'KSM',
  'KWD',
  'KYD',
  'KZT',
  'LAK',
  'LBP',
  'LEO',
  'LINK',
  'LKR',
  'LRC',
  'LRD',
  'LSL',
  'LTC',
  'LTL',
  'LUNA',
  'LVL',
  'LYD',
  'MAD',
  'MANA',
  'MATIC',
  'MDL',
  'MGA',
  'MINA',
  'MIOTA',
  'MKD',
  'MKR',
  'MMK',
  'MNT',
  'MOP',
  'MRO',
  'MUR',
  'MVR',
  'MWK',
  'MXN',
  'MYR',
  'MZN',
  'NAD',
  'NEAR',
  'NEO',
  'NEXO',
  'NGN',
  'NIO',
  'NOK',
  'NPR',
  'NZD',
  'OKB',
  'OMR',
  'ONE',
  'PAB',
  'PAXG',
  'PEN',
  'PGK',
  'PHP',
  'PKR',
  'PLN',
  'PYG',
  'QAR',
  'QNT',
  'QTUM',
  'RON',
  'RSD',
  'RUB',
  'RUNE',
  'RWF',
  'SAND',
  'SAR',
  'SBD',
  'SCR',
  'SDG',
  'SEK',
  'SGD',
  'SHIB',
  'SHP',
  'SLE',
  'SLL',
  'SOL',
  'SOS',
  'SRD',
  'STD',
  'STX',
  'SVC',
  'SYP',
  'SZL',
  'THB',
  'THETA',
  'TJS',
  'TMT',
  'TND',
  'TOP',
  'TRX',
  'TRY',
  'TTD',
  'TTT',
  'TUSD',
  'TWD',
  'TZS',
  'UAH',
  'UGX',
  'UNI',
  'USD',
  'USDC',
  'USDP',
  'USDT',
  'UYU',
  'UZS',
  'VEF',
  'VET',
  'VND',
  'VUV',
  'WAVES',
  'WBTC',
  'WEMIX',
  'WST',
  'XAF',
  'XAG',
  'XAU',
  'XCD',
  'XCH',
  'XDC',
  'XDR',
  'XEC',
  'XEM',
  'XLM',
  'XMR',
  'XOF',
  'XPF',
  'XRP',
  'XTZ',
  'YER',
  'ZAR',
  'ZEC',
  'ZIL',
  'ZMK',
  'ZMW',
  'ZWL',
]

import { Box, Link, Typography } from '@mui/material'
import * as React from 'react'
import './renderJSON.scss'
import { camelCaseFormatter } from './formatters/camelCase'
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined'
import { useTranslation } from 'react-i18next'

const ExpandableProperty = (props) => {
  const [isOpen, setIsOpen] = React.useState(!!props.expanded)
  const { t } = useTranslation(['common'])

  return (
    <React.Fragment>
      {props.title && (
        <Box className="rjson-wrapper" onClick={() => setIsOpen(!isOpen)}>
          <Typography variant="text2">{props.title}:</Typography>
          {isOpen ? (
            <Box className="opening">
              <Link>{t('common:seeMore')}</Link>
              <KeyboardArrowDownOutlinedIcon color="secondary" className="arrow" />
            </Box>
          ) : (
            <Box className="opening">
              <Link>{t('common:seeMore')}</Link>
              <KeyboardArrowRightOutlinedIcon color="secondary" className="arrow" />
            </Box>
          )}
        </Box>
      )}
      {isOpen ? <Box>{props.children}</Box> : null}
      {React.Children.count(props.children) === 0 && isOpen ? '---' : null}
    </React.Fragment>
  )
}

const RecursiveProperty = (props) => {
  return (
    <Box className="recursive-wrapper" style={{ marginLeft: props.rootProperty ? 0 : 25 }}>
      {props.property !== undefined &&
        props.propertyName !== 'resultados_sugeridos' &&
        props.propertyName !== 'site_receipt' &&
        (typeof props.property === 'number' ||
        typeof props.property === 'string' ||
        typeof props.property === 'boolean' ? (
          <Box className="recursive-pair">
            {props.propertyName && <Typography variant="text2">{camelCaseFormatter(props.propertyName)}: </Typography>}
            <p>{props.property === '' ? '---' : props.property.toString()}</p>
          </Box>
        ) : (
          <ExpandableProperty
            title={props.propertyName && camelCaseFormatter(props.propertyName)}
            expanded={!!props.rootProperty}
          >
            {props.property &&
              Object.values(props.property).map((property, index, { length }) => (
                <RecursiveProperty
                  key={index}
                  property={property}
                  propertyName={Object.getOwnPropertyNames(props.property)[index]}
                  excludeBottomBorder={index === length - 1}
                />
              ))}
          </ExpandableProperty>
        ))}
    </Box>
  )
}

export default RecursiveProperty

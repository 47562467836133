import './widget.scss'

import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined'
import RemoveIcon from '@mui/icons-material/Remove'
import AddIcon from '@mui/icons-material/Add'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import { useNavigate } from 'react-router-dom'
import PersonIcon from '@mui/icons-material/Person'
import { Box, IconButton, Paper, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

const createdByMonth = (all, type, nPrev = 0) => {
  const monthNames = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec']
  let actualDate = new Date()
  let monthToCompare =
    nPrev > 0 ? monthNames[actualDate.setMonth(actualDate.getMonth() - nPrev)] : monthNames[actualDate.getMonth()]

  const filtered = all?.reduce((acc, currentValue) => {
    if (currentValue.month === monthToCompare) {
      if (type === 'users') {
        return currentValue['business-users'] + currentValue['consumer-users']
      }
      return currentValue.transactions
    }
  }, 0)

  if (filtered) {
    return filtered
  }
}

const xPercentageMonthDiff = (type, inputData) => {
  let deltaX
  switch (type) {
    case 'transactions':
      const txsCurrentMonth = createdByMonth(inputData, 'transactions') || 0
      const txsPrevMonth = createdByMonth(inputData, 'transactions', 1) || 0
      if (txsPrevMonth === 0) {
        deltaX = txsCurrentMonth > 0 ? 100 : 0
      }
      if (txsPrevMonth > 0) deltaX = ((txsCurrentMonth - txsPrevMonth) / txsPrevMonth) * 100
      break
    case 'users':
      const usersCurrentMonth = createdByMonth(inputData, 'users') || 0
      const usersPrevMonth = createdByMonth(inputData, 'users', 1) || 0
      if (usersPrevMonth === 0) {
        deltaX = usersCurrentMonth > 0 ? 100 : 0
      }
      if (usersPrevMonth > 0) deltaX = ((usersCurrentMonth - usersPrevMonth) / usersPrevMonth) * 100
      break
    case 'consumptions':
      const consumptionsCurrentMonth =
        createdByMonth(inputData, 'transactions') + createdByMonth(inputData, 'users') || 0
      const consumptionsPrevMonth =
        createdByMonth(inputData, 'transactions', 1) + createdByMonth(inputData, 'users', 1) || 0
      if (consumptionsPrevMonth === 0) {
        deltaX = consumptionsCurrentMonth > 0 ? 100 : 0
      }
      if (consumptionsPrevMonth > 0) {
        deltaX = ((consumptionsCurrentMonth - consumptionsPrevMonth) / consumptionsPrevMonth) * 100
      }
      break
    default:
      break
  }
  return deltaX
}

const Widget = ({ type, inputData }) => {
  let data
  const noAmountDefaultValue = -1
  const { t } = useTranslation(['common'])
  const navigate = useNavigate()

  switch (type) {
    case 'transactions':
      data = {
        route: 'transactions',
        title: t('common:transactions'),
        link: t('common:seeAllTsx'),
        amount: createdByMonth(inputData, 'transactions') || noAmountDefaultValue,
        diff: xPercentageMonthDiff('transactions', inputData).toFixed(2),
        icon: (
          <IconButton size="large" className="icon">
            <MonetizationOnIcon fontSize="large" className="transaction-icon" />
          </IconButton>
        ),
      }
      break

    case 'consumptions':
      const consumptions = createdByMonth(inputData, 'transactions') + createdByMonth(inputData, 'users') || 0
      const hasConsumptions = consumptions >= 0

      data = {
        route: 'coming-soon',
        title: t('common:consumptions'),
        link: t('common:consumptionsDetails'),
        amount: process.env.REACT_APP_IS_DEMO ? 74545834 : hasConsumptions ? consumptions : noAmountDefaultValue,
        diff: xPercentageMonthDiff('consumptions', inputData)?.toFixed(2),
        icon: (
          <IconButton size="large" className="icon">
            <BarChartOutlinedIcon fontSize="large" className="consumption-icon" />
          </IconButton>
        ),
      }
      break

    case 'users':
      data = {
        route: 'users',
        title: t('common:users'),
        link: t('common:seeDetails'),
        amount: createdByMonth(inputData, 'users') || noAmountDefaultValue,
        diff: xPercentageMonthDiff('users', inputData)?.toFixed(2),
        icon: (
          <IconButton size="large" className="icon">
            <PersonIcon fontSize="large" className="user-icon" />
          </IconButton>
        ),
      }
      break
    default:
      break
  }

  return (
    <Paper className="widget" elevation={0}>
      <Box className="left">
        <Typography variant="text4">{data.title}</Typography>
        <span className="counter">{data.amount > 0 ? data.amount : '----'}</span>
        <span onClick={() => navigate(`/${data.route}`)} className="link-widget link-animation">
          {data.link}
        </span>
      </Box>
      <Box className="right">
        {data.diff > 0 && (
          <Box className="percentage positive">
            <AddIcon />
            {data.diff} %
          </Box>
        )}
        {data.diff == 0 && <Box className="percentage zero">{process.env.REACT_APP_IS_DEMO ? 78 : data.diff} %</Box>}
        {data.diff < 0 && (
          <Box className="percentage negative">
            <RemoveIcon />
            {data.diff} %
          </Box>
        )}
        {data.icon}
      </Box>
    </Paper>
  )
}

export default Widget

import {
  Box,
  Button,
  Checkbox,
  Fab,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { CloseOutlined } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import './users.scss'
import { useEffect, useState } from 'react'
import { updateUsersAdmin, updateUsersAdminFilters } from '../../../features/usersAdmin/userAdminSlice'
import DatatableUserAdmin from '../../../components/common/datatable/DatatableUserAdmin'
import { useRolesByEmailClient } from '../../pages-hooks/useRoles'
import { useUsersAdmin } from '../../pages-hooks/useUsersAdmin'
import Spinner from '../../../components/common/spinner/spinner'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import DataExportModal from '../../../components/common/dataExport/DataExportModal'
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined'
import {
  userAdminDataMapper,
  userAdminDataMapperForPDF,
} from '../../../components/common/dataExport/dataMappers/userAdminDataMapper'
import { useTranslation } from 'react-i18next'
import { FilterModal } from '../../../components/utilities/FilterModal'
import AlertFetchSpinner from '../../../components/common/alertFetchSpinner/alertFetchSpinner'

const Users = () => {
  const { emailClient, activeServices, accessToken } = useSelector((state) => state.login)
  const rolesState = useSelector((state) => state.role?.roles)
  const queryState = useSelector((state) => state.userAdmin.filters)
  const [userEmail, setUserEmail] = useState(queryState?.email)
  const [userStatus, setUserStatus] = useState(queryState?.status)
  const [page, setPage] = useState(0)
  const [roles, setRoles] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState(50)
  const [isLoading, setIsLoading] = useState(true)
  const [showDownloadModal, setShowDownloadModal] = useState(false)
  const usersAdminState = useSelector((state) => state.userAdmin.users)
  const roleNames = rolesState.data !== 'Forbidden resource' ? rolesState?.data?.map((r) => r.roleName) : []
  const [usersAdmin, setUsersAdmin] = useState({})
  const [modalOpen, setModalOpen] = useState(false)
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState('')
  const [userToReset, setUserToReset] = useState('')
  const [openReset, setOpenReset] = useState(false)
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [email, setEmail] = useState('')
  const [openDelete, setOpenDelete] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [edit, setEdit] = useState(false)
  const [error, setError] = useState(false)
  const [fetchError, setFetchError] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [fetchMessage, setFetchMessage] = useState('')
  const [idToDelete, setIdToDelete] = useState('')
  const [activeUser, setActiveUser] = useState({ id: null, email: '', status: '' })
  const regex = /(^[A-Z]{4,20}(?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/
  const regexEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
  const [open2, setOpen2] = useState(false)

  const { t } = useTranslation(['common', 'messages'])

  useRolesByEmailClient(emailClient, isLoading, setIsLoading)
  useUsersAdmin(emailClient)

  const handleClose = () => {
    setOpen(false)
    setEdit(false)
    setRoles([])
    setActiveUser({ id: null, email: '', status: '' })
    setErrorMessage('')
    setError(false)
  }

  const handleClose2 = () => {
    setIsLoading(false)
    setOpen2(false)
    setMessage('')
    setIdToDelete('')
  }

  const handleCloseReset = () => {
    setOpenReset(false)
    setErrorMessage('')
    setError(false)
  }

  const handleCloseDelete = () => {
    setOpenDelete(false)
    setEdit(false)
    setActiveUser({ id: null, email: '', status: '' })
    setErrorMessage('')
    setError(false)
  }

  const handleDelete = (e) => {
    e.preventDefault()

    if (!process.env.REACT_APP_IS_DEMO) {
      const urlUser = `${process.env.REACT_APP_BASEURL}/user/${activeUser.id}`
      const options = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      }

      fetch(urlUser, options)
        .then((res) => res.json())
        .then((res) => {
          if (!res?.success) throw Error('Deletion error. Contact support team.')
          handleCloseDelete()
          updateUsers()
          handleFetch(false, res.message)
        })
        .catch((error) => {
          console.error('[DELETE USER ERROR] --> ', error)
          handleFetch(true, error.message ? error.message : `${t('common:user')} ${t('messages:deletionError')}`)
        })
    } else {
      handleCloseDelete()
      updateUsers()
      handleFetch(false, 'User deletion success')
    }
  }

  const handleResetButton = () => {
    if (userEmail !== '') setUserEmail('')
    if (userStatus !== '') setUserStatus('')
    if (roles && roles.length !== 0) setRoles([])
    setPage(0)
    setIsLoading(true)

    dispatch(
      updateUsersAdminFilters({
        email: '',
        status: '',
        roles: [],
        page: 0,
        viewMode: true,
        limit: 50,
        offset: 0,
      }),
    )
  }

  const handleDispatch = () => {
    setIsLoading(true)
    return dispatch(
      updateUsersAdminFilters({
        status: userStatus,
        email: userEmail,
        roles: roles,
        page,
        viewMode: true,
        limit: rowsPerPage,
        offset: rowsPerPage * page,
      }),
    )
  }

  const updateUsers = () => {
    const { viewMode, limit, offset, page, email, roles, status } = queryState
    const baseUrlUsers = `${process.env.REACT_APP_BASEURL}/user/all?emailClient=${emailClient}`
    let urlGetUsersAdmin = buildUrl(baseUrlUsers)

    function buildUrl(baseUrl) {
      let url = `${baseUrl}&viewMode=${viewMode}&limit=${limit}&page=${page}&offset=${offset}`

      if (email?.length) url += `&email=${email}`

      if (status?.length) url += `&status=${status}`

      if (roles?.length) url += `&roles=${roles}`

      return url
    }

    fetch(process.env.REACT_APP_IS_DEMO ? '../data/users.json' : urlGetUsersAdmin, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res) {
          const { data, pagination } = res
          const aux = { data, pagination }
          dispatch(updateUsersAdmin(aux))
        }
      })
      .catch((error) => console.error('[HOOK: useInstanceRules] --> ', error))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!process.env.REACT_APP_IS_DEMO) {
      const formData = e.target

      const data = {
        emailClient: emailClient,
        email: formData.email.value,
        password: formData.password.value,
        confirmPassword: formData.confirmPassword.value,
        roles,
        hasApiKey: false,
      }

      const urlUser = `${process.env.REACT_APP_BASEURL}/user`
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(data),
      }

      fetch(urlUser, options)
        .then((res) => res.json())
        .then((res) => {
          if (!res.success) throw Error(typeof res.data === 'string' ? res.data : res.message)
          handleClose()
          setPage(0)
          updateUsers()
          handleFetch(false, res.message)
        })
        .catch((error) => {
          console.error('[CREATE USER ERROR] --> ', error)
          setError(true)
          setErrorMessage(error.message ? error.message : `${t('common:user')} ${t('messages:creationError')}`)
          handleFetch(true, error.message ? error.message : `${t('common:user')} ${t('messages:creationError')}`)
        })
    } else {
      handleClose()
      setPage(0)
      updateUsers()
      handleFetch(false, 'User creation success')
    }
  }

  const handleFetch = (error, message) => {
    setIsFetching(true)
    setFetchError(error)
    setFetchMessage(message)
    setTimeout(() => {
      setIsFetching(false)
    }, 3000)
  }

  const handleUpdate = (e, row) => {
    e.preventDefault()
    if (!process.env.REACT_APP_IS_DEMO) {
      let data

      if (row) {
        data = {
          status: row.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE',
        }
      } else {
        data = {
          roles,
        }
      }

      const urlUser = `${process.env.REACT_APP_BASEURL}/user/${row ? row.email : activeUser.email}`
      const options = {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(data),
      }

      fetch(urlUser, options)
        .then((res) => res.json())
        .then((res) => {
          if (!res.success) throw Error(typeof res.data === 'string' ? res.data : res.message)
          handleClose()
          updateUsers()
          handleFetch(false, res.message)
        })
        .catch((error) => {
          console.error('[UPDATE USER ERROR] --> ', error)
          handleFetch(true, error.message ? error.message : `${t('common:user')} ${t('messages:updateError')}`)
        })
    } else {
      handleClose()
      updateUsers()
      handleFetch(false, 'User update success')
    }
  }

  const handleReset = (e) => {
    e.preventDefault()
    if (!process.env.REACT_APP_IS_DEMO) {
      const data = {
        email: userToReset,
        password,
        confirmNewPassword: confirmPassword,
      }

      const urlUser = `${process.env.REACT_APP_BASEURL}/user/resetPassword`
      const options = {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(data),
      }

      fetch(urlUser, options)
        .then((res) => res.json())
        .then((res) => {
          if (!res.success) throw Error(typeof res.data === 'string' ? res.data : res.message)
          handleCloseReset()
          updateUsers()
          handleFetch(false, res.message)
        })
        .catch((error) => {
          console.error('[RESET USER PASSWORD ERROR] --> ', error)
          setError(true)
          setErrorMessage(error.message ? error.message : `${t('common:user')} ${t('messages:resetPasswordError')}`)
          handleFetch(true, error.message ? error.message : `${t('common:user')} ${t('messages:resetPasswordError')}`)
        })
    } else {
      handleCloseReset()
      updateUsers()
      handleFetch(false, 'Password reset success')
    }
  }

  useEffect(() => {
    if (!edit && (password !== '' || confirmPassword !== '' || email !== '' || activeUser.email !== '')) {
      if (!regexEmail.test(email) && open) {
        setErrorMessage('Invalid email value')
        setError(true)
      } else if (!regexEmail.test(userToReset) && openReset) {
        setErrorMessage('Invalid email value')
        setError(true)
      } else if (!regex.test(password) && password !== confirmPassword) {
        setErrorMessage(`${t('messages:passwordTooWeak')}. ${t('messages:passwordDontMatch')}`)
        setError(true)
      } else if (!regex.test(password)) {
        setErrorMessage(t('messages:passwordTooWeak'))
        setError(true)
      } else if (password !== confirmPassword) {
        setErrorMessage(t('messages:passwordDontMatch'))
        setError(true)
      } else {
        setError(false)
        setErrorMessage('')
      }
    }
  }, [password, confirmPassword, email, userToReset])

  useEffect(() => {
    handleDispatch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage, page])

  useEffect(() => {
    setUsersAdmin({ ...usersAdminState })
    setIsLoading(false)
  }, [usersAdminState])

  return (
    <Box>
      <Box className={`${isLoading && 'spinner-transition'} filter`}>
        {isLoading && <Spinner noTransform />}
        {isFetching && <AlertFetchSpinner message={fetchMessage} error={fetchError} />}
        <Box className="component-title-wrapper">
          <Box className="component-title">
            <Typography variant="h2">{t('common:dashboardUsers')}</Typography>
            <Box className="user-icons">
              {activeServices.includes('reportsitem') && (
                <Button
                  variant="outlined"
                  disabled={!activeServices.includes('reportsitem')}
                  onClick={() => setShowDownloadModal(true)}
                  endIcon={<IosShareOutlinedIcon sx={{ fill: 'var(--lime-04)' }} />}
                >
                  <Typography variant="title2">{t('common:exportList')}</Typography>
                </Button>
              )}
              <Button
                size="small"
                variant="contained"
                onClick={() => {
                  setOpen(true)
                }}
              >
                {`${t('common:createNew')} ${t('common:user')}`}
              </Button>
            </Box>
          </Box>
          <Typography variant="subtitle3">
            {/* Loren ipsum dolor sit amet Loren ipsum dolor sit amet Loren  */}
          </Typography>
        </Box>

        <Paper elevation={0}>
          <DatatableUserAdmin
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            queryState={queryState}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            rolesState={rolesState}
            setOpen={setOpen}
            setOpenDelete={setOpenDelete}
            handleClose={handleClose}
            setOpenReset={setOpenReset}
            setUserToReset={setUserToReset}
            setEdit={setEdit}
            activeUser={activeUser}
            setActiveUser={setActiveUser}
            setModalOpen={setModalOpen}
          />
        </Paper>

        <FilterModal
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          handleResetButton={handleResetButton}
          setPage={setPage}
          handleDispatch={handleDispatch}
        >
          <Box className="modal-box-filters">
            <Box className="modal-box">
              <InputLabel htmlFor="userStatus">{`${t('common:by')} ${t('common:email')}`}</InputLabel>
              <TextField
                size="small"
                value={userEmail}
                onChange={(event) => setUserEmail(event.target.value)}
                placeholder={`${t('common:insert')} ${t('common:email')}`}
              />
            </Box>
            <Box className="modal-box">
              <InputLabel htmlFor="userStatus">{`${t('common:by')} ${t('common:status')}`}</InputLabel>
              <TextField
                size="small"
                value={userStatus}
                onChange={(event) => setUserStatus(event.target.value)}
                placeholder={`${t('common:insert')} ${t('common:status')}`}
              />
            </Box>
            <Box className="modal-box">
              <InputLabel htmlFor="roles">
                {t('common:by')} {t('common:role')}
              </InputLabel>
              <Select
                required
                fullWidth
                size="small"
                multiple
                value={roles}
                onChange={(e) =>
                  setRoles(typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value)
                }
                renderValue={(selected) => selected.join(', ')}
              >
                {roleNames?.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={roles.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>
        </FilterModal>

        <Modal open={open} onClose={handleClose}>
          <Box className="modal">
            <Box className="modal-top">
              <Box className="modal-titles">
                <Typography variant="title" component="h2">
                  {edit ? `${t('common:edit')} ${t('common:user')}` : `${t('common:create')} ${t('common:user')}`}
                </Typography>
              </Box>
              <Fab
                variant="close"
                onClick={() => {
                  handleClose()
                }}
              >
                <CloseOutlined />
              </Fab>
            </Box>
            <Box component="form" onSubmit={(e) => (edit ? handleUpdate(e) : handleSubmit(e))}>
              <Box gap="1rem" flexDirection="column" display="flex">
                {edit && (
                  <Box className="modal-box">
                    <InputLabel>{t('common:email')}</InputLabel>
                    <Typography variant="title3">{activeUser.email}</Typography>
                  </Box>
                )}
                {!edit && (
                  <Box className="user-box">
                    <InputLabel htmlFor="email">{t('common:email')}</InputLabel>
                    <TextField
                      size="small"
                      placeholder={`${t('common:insert')} ${t('common:email')}`}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      id="email"
                    ></TextField>
                  </Box>
                )}

                {!edit && (
                  <Box className="user-box">
                    <InputLabel htmlFor="password">{t('common:password')}</InputLabel>
                    <TextField
                      size="small"
                      placeholder={`${t('common:insert')} ${t('common:password')}`}
                      type="password"
                      required
                      id="password"
                      onChange={(e) => {
                        setPassword(e.target.value)
                      }}
                    ></TextField>
                  </Box>
                )}

                {!edit && (
                  <Box className="modal-box">
                    <InputLabel htmlFor="confirmPassword">{t('messages:confirmPassword')}</InputLabel>
                    <TextField
                      size="small"
                      placeholder={t('messages:confirmPassword')}
                      type="password"
                      required
                      id="confirmPassword"
                      onChange={(e) => {
                        setConfirmPassword(e.target.value)
                      }}
                    ></TextField>
                  </Box>
                )}

                <Box className="modal-box">
                  <InputLabel htmlFor="roles">{t('common:roles')}</InputLabel>
                  <Select
                    required
                    id="roles"
                    size="small"
                    multiple
                    displayEmpty
                    value={roles}
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return (
                          <Typography variant="subtitle3">{`${t('common:select')} ${t('common:roles')}`}</Typography>
                        ) // This can be a translated string or any placeholder
                      }
                      return selected.join(', ')
                    }}
                    onChange={(e) =>
                      setRoles(typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value)
                    }
                  >
                    {roleNames?.map((name) => (
                      <MenuItem key={name} value={name}>
                        <Checkbox checked={roles.indexOf(name) > -1} />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </Box>
              <Box className="modal-filter-buttons">
                {errorMessage ? (
                  <Button size="small" variant="outlined" color="error">
                    {errorMessage}
                  </Button>
                ) : (
                  ''
                )}
                <Button disabled={error} size="small" variant="contained" type="submit">
                  {edit ? t('common:save') : `${t('common:createNew')} ${t('common:user')}`}
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>

        <Modal open={openReset} onClose={handleCloseReset}>
          <Box className="modal">
            <Box className="modal-top">
              <Box className="modal-titles">
                <Typography variant="title">{t('messages:changePassword')}</Typography>
              </Box>
              <Fab variant="close" onClick={() => handleCloseReset()} aria-label="sidebar-collapse">
                <CloseOutlinedIcon />
              </Fab>
            </Box>

            <Box className="modal-box-filters">
              <Box className="modal-box">
                <InputLabel>{t('common:email')}</InputLabel>
                <Typography variant="title3">{userToReset}</Typography>
              </Box>

              {!edit && (
                <Box className="modal-box">
                  <InputLabel htmlFor="password">{t('messages:newPassword')}</InputLabel>
                  <TextField
                    size="small"
                    type="password"
                    helperText={errorMessage.length > 0 ? errorMessage : null}
                    error={Boolean(errorMessage)}
                    placeholder={`${t('common:type')} ${t('common:here').toLowerCase()}...`}
                    required
                    id="password"
                    onChange={(e) => {
                      setPassword(e.target.value)
                    }}
                  ></TextField>
                </Box>
              )}
              {!edit && (
                <Box className="modal-box">
                  <InputLabel htmlFor="confirmPassword">{t('messages:confirmNewPassword')}</InputLabel>
                  <TextField
                    size="small"
                    type="password"
                    helperText={errorMessage.length > 0 ? errorMessage : null}
                    error={Boolean(errorMessage)}
                    placeholder={`${t('common:type')} ${t('common:here').toLowerCase()}...`}
                    required
                    id="confirmPassword"
                    onChange={(e) => {
                      setConfirmPassword(e.target.value)
                    }}
                  ></TextField>
                </Box>
              )}
            </Box>
            <Box className="modal-filter-buttons">
              <Button
                disabled={error}
                size="small"
                variant="contained"
                type="submit"
                onClick={(e) => {
                  return handleReset(e)
                }}
              >
                {t('common:save')}
              </Button>
            </Box>
          </Box>
        </Modal>

        <Modal open={openDelete} onClose={handleCloseDelete}>
          <Box className="modal">
            <Box className="modal-top">
              <Box className="modal-titles">
                <Typography variant="title">{t('common:deleteUser')}</Typography>
              </Box>
              <Fab variant="close" onClick={() => handleCloseDelete()} aria-label="sidebar-collapse">
                <CloseOutlinedIcon />
              </Fab>
            </Box>

            <Box className="user-text-delete">
              <p>{t('messages:deleteUserSelected')}</p>
              <Box>
                <Typography variant="title">{activeUser?.email}</Typography>?
              </Box>
              <p>{t('messages:actionIrreversible')}</p>
            </Box>
            {errorMessage ? (
              <Button size="small" variant="outlined" color="error">
                {errorMessage}
              </Button>
            ) : (
              ''
            )}
            <Box className="modal-filter-buttons">
              <Button variant="outlined" onClick={() => handleCloseDelete()}>
                {t('common:no')}
              </Button>
              <Button variant="contained" type="submit" onClick={(e) => handleDelete(e)}>
                {t('common:yes')}
              </Button>
            </Box>
          </Box>
        </Modal>

        <Modal open={open2} onClose={handleClose2}>
          <Box className="create-role">
            <Typography id="modal-modal-title" variant="h6" component="h2" style={{ marginBottom: '2rem' }}>
              {t('common:deleteUser')}
            </Typography>
            <Box
              className="form"
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '2.5rem',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {message && <Box>{message}</Box>}
              <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '1rem' }}>
                <Button className="" size="large" variant="outlined" onClick={() => handleDelete(idToDelete)}>
                  {t('common:yes')}
                </Button>
                <Button className="" size="large" variant="outlined" onClick={handleClose2}>
                  {t('common:no')}
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
        <DataExportModal
          show={showDownloadModal}
          setShow={setShowDownloadModal}
          data={usersAdmin.data}
          dataMapper={userAdminDataMapper}
          dataMapperForPDF={userAdminDataMapperForPDF}
          fileName={'dashboard-users'}
          typeDataExport={'users'}
        />
      </Box>
    </Box>
  )
}

export default Users

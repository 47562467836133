import './datatable.scss'
import { useSelector } from 'react-redux'
import { Box, TableContainer, TablePagination, Link, Fab, Typography } from '@mui/material'
import { buildTimeStringFromTimestamp } from '../time/timeHelper'
import { DataGrid } from '@mui/x-data-grid'
import { useChecklist } from '../../../pages/pages-hooks/useChecklist'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { DataGridComponent } from '../datagrid/DataGridComponent'

const DatatableChecklist = ({
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  setIsLoading,
  setCheck,
  setOpen,
  setIsCreation,
  checklists,
  setChecklists,
  setModalOpen,
}) => {
  const { emailClient } = useSelector((state) => state.login)
  const checklist = useChecklist(emailClient, setIsLoading)
  const { t } = useTranslation(['common'])

  useEffect(() => {
    setChecklists(checklist.data)
  }, [checklist])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const columns = [
    {
      field: 'id',
      headerName: 'Reference ID',
      renderCell: (params) => {
        return (
          <Link
            onClick={() => {
              setCheck(params.row)
              setOpen(true)
            }}
          >
            <Box className="copy-container">
              <Typography variant="number">{params.row.id}</Typography>
            </Box>
          </Link>
        )
      },
    },
    {
      field: 'title',
      headerName: t('common:title'),
      renderCell: (params) => {
        return <Box>{params.row.title ? params.row.title : '---'}</Box>
      },
    },
    {
      field: 'description',
      headerName: t('common:description'),
      renderCell: (params) => {
        return <Box>{params.row.description ? params.row.description : '---'}</Box>
      },
    },
    {
      field: 'actions',
      headerName: t('common:actions'),
      renderCell: (params) => {
        return <Box>{params.row.actions ? params.row.actions.length : '---'}</Box>
      },
    },
    {
      field: 'createdAt',
      headerName: t('common:createdAt'),
      renderCell: (params) => {
        return <Typography variant="number">{buildTimeStringFromTimestamp(params.row.createdAt)}</Typography>
      },
    },
    {
      field: 'updatedAt',
      headerName: t('common:updatedAt'),
      renderCell: (params) => {
        return <Typography variant="number">{buildTimeStringFromTimestamp(params.row.updatedAt)}</Typography>
      },
    },
    {
      field: 'details',
      headerName: t('common:details'),
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <Link
            onClick={() => {
              setCheck(params.row)
              setIsCreation(false)
              setOpen(true)
            }}
          >
            <Box className="copy-container">
              {t('common:seeDetails')}
              <Fab variant="close" className="icon-fab">
                <EditOutlinedIcon />
              </Fab>
            </Box>
          </Link>
        )
      },
    },
  ]

  return (
    <DataGridComponent
      rows={(Array.isArray(checklists) && checklists.length && checklists) || []}
      columns={columns}
      count={checklist?.pagination?.totalElements || 0}
      page={page}
      rowsPerPage={rowsPerPage}
      setPage={setPage}
      setRowsPerPage={setRowsPerPage}
      setIsLoading={setIsLoading}
      setModalOpen={setModalOpen}
    />
  )
}

export default DatatableChecklist

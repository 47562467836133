import './lowTransactionValues.scss'
import Button from '@mui/material/Button'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { useEffect, useState } from 'react'
import { Box, Fab, InputLabel, MenuItem, OutlinedInput, Select, TextField, Typography } from '@mui/material'
import currencies from '../../../json/currencies.json'
import { MenuProps } from '../../../../utilities/MenuProps'
import { useTranslation } from 'react-i18next'

const LowTransactionValues = ({ propertie, ruleParameters, setRuleParameters, isEditing, filterTemplate }) => {
  const [ltv, setLtv] = useState([])
  const [change, setChange] = useState(false)
  const { t } = useTranslation(['common'])

  const getIndex = () => {
    if (Array.isArray(ruleParameters) && ruleParameters.length === 0) return -1
    if (ruleParameters) return ruleParameters.map((e) => e.filterTemplate).indexOf(filterTemplate)
    return -1
  }

  useEffect(() => {
    const aux = ruleParameters?.find((e) => e.filterTemplate === filterTemplate)

    if (aux) {
      setLtv(aux[propertie])
    }
  }, [propertie])

  const handleValueChange = (valueKey, value, index, key) => {
    let list = [...ltv]
    let aux = { ...list[index] }
    aux[valueKey] = value !== '' ? value : ''
    list[index] = { ...aux }
    setLtv(list)
    setChange(true)
  }

  const handleServiceRemove = (index) => {
    const list = [...ltv]
    list.splice(index, 1)
    setLtv(list)
    setChange(true)
  }

  const handleServiceAdd = () => {
    let objAux = { currency: '', min: '', max: '' }
    setLtv([...ltv, { ...objAux }])
    setChange(true)
  }

  useEffect(() => {
    const index = getIndex()

    if (change) {
      let all = [...ruleParameters]
      let previous = index >= 0 && all[index] ? { ...all[index] } : {}

      previous[propertie] = ltv
      previous.filterTemplate = filterTemplate

      if (index !== -1) {
        all.splice(index, 1)
      }
      all.push(previous)

      setRuleParameters([...all])
    }
    setChange(false)
  }, [ltv])

  return (
    <Box>
      {ltv?.map((item, index) => (
        <Box key={index}>
          <Box className="low-row">
            <Box className="low-pair">
              <InputLabel>{t('common:currency')}</InputLabel>
              <Select
                disabled={!isEditing}
                size="small"
                displayEmpty
                value={item.currency}
                onChange={(e) => handleValueChange('currency', e.target.value, index)}
                input={<OutlinedInput />}
                MenuProps={MenuProps}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                {Object.entries(currencies).map(([key, value]) => (
                  <MenuItem key={key} value={key}>
                    {key} - ({value})
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box className="low-pair">
              <Box className="low-label">
                <InputLabel>Max</InputLabel>
              </Box>
              <TextField
                disabled={!isEditing}
                variant="outlined"
                size="small"
                type="number"
                value={item.max}
                onChange={(event) => handleValueChange('max', event.target.value, index)}
              />
            </Box>
            <Box className="low-pair">
              <Box className="low-label">
                <InputLabel>Min</InputLabel>
                <span className="mandatory">* </span>
              </Box>
              <TextField
                disabled={!isEditing}
                variant="outlined"
                size="small"
                type="number"
                value={item.min}
                onChange={(event) => handleValueChange('min', event.target.value, index)}
              />
            </Box>
            <Box className="low-delete">
              {isEditing && (
                <Fab variant="close" className="icon-fab" onClick={() => handleServiceRemove(index)}>
                  <DeleteOutlineOutlinedIcon />
                </Fab>
              )}
            </Box>
          </Box>
        </Box>
      ))}
      <Box className="button-right">
        {isEditing && (
          <Button endIcon={<AddCircleOutlineIcon />} variant="outlinedGrey" onClick={handleServiceAdd}>
            {t('common:add')}
          </Button>
        )}
      </Box>
    </Box>
  )
}

export default LowTransactionValues

import { Paper } from '@mui/material'
import './chart.scss'
import { LineChart } from '@mui/x-charts/LineChart'
import { BarChart } from '@mui/x-charts/BarChart'

const Chart = (inputData) => {
  const monthLabels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  const txsAmount = [12400, 50398, 60800, 85908]
  const txsAmountLabels = ['Q1', 'Q2', 'Q3', 'Q4']
  const txsVolume = [1, 4, 3, 4.2, 6.6, 5.1, 7, 6.4, 5.8, 9.7, 8, 9.2]
  const newUsersData = [2400, 1398, 7800, 3908, 4800, 3800, 4300, 2400, 1398, 5800, 3908, 4800]
  const activeUsersData = [1400, 398, 800, 2908, 2800, 3800, 4300, 5400, 3398, 4200, 2308, 1800]

  return (
    <Paper elevation={0} style={{ width: '100%', display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
      <LineChart
        xAxis={[{ scaleType: 'point', data: txsAmountLabels }]}
        series={[
          {
            data: txsAmount,
            label: 'Transactions Amount in $USD',
            color: '#4e79a7',
            options: {
              maintainAspectRatio: false,
            },
          },
        ]}
        
      />

      <LineChart
        xAxis={[{ scaleType: 'point', data: monthLabels }]}
        series={[
          {
            data: txsVolume,
            label: 'Transactions Volume in Millons',
            area: true,
            showMark: false,
            color: '#f28e2c',
            curve: 'linear',
            options: {
              maintainAspectRatio: false,
            },
          },
        ]}
        
      />

      <BarChart
        
        series={[
          {
            data: newUsersData,
            label: 'New Users',
            id: 'pvId',
            color: '#af7aa1',
            options: {
              maintainAspectRatio: false,
            },
          },
        ]}
        xAxis={[{ data: monthLabels, scaleType: 'band' }]}
      />

      <LineChart
        xAxis={[{ scaleType: 'point', data: monthLabels }]}
        series={[
          {
            data: activeUsersData,
            label: 'Monthly Active Users',
            color: '#76b7b2',
            options: {
              maintainAspectRatio: false,
            },
          },
        ]}
        
      />
    </Paper>
  )
}

export default Chart

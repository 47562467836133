import './datatable.scss'
import { Box, TableContainer } from '@mui/material'
import Spinner from '../spinner/spinner'
import { buildTimeStringFromTimestamp } from '../time/timeHelper'
import { DataGrid } from '@mui/x-data-grid'
import { useState } from 'react'
import { ChecklistInstanceDrawer } from '../../../pages/admin/checklist/ChecklistInstanceDrawer'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

const DatatableChecklistInstances = ({ isLoading, checklists, setChecklists, hasPermissions }) => {
  const location = useLocation()
  const [open, setOpen] = useState(false)
  const [check, setCheck] = useState({})
  const { t } = useTranslation(['common'])

  const columns = [
    {
      field: '_id',
      headerName: 'ID',
      flex: 1,
      minWidth: 260,
      renderCell: (params) => {
        return (
          <Box
            className="table-body-cell id-action"
            onClick={() => {
              setCheck(params.row)
              setOpen(true)
            }}
          >
            {params.row._id}
          </Box>
        )
      },
    },
    {
      field: 'title',
      headerName: 'Title',
      flex: 1,
      minWidth: 140,
      renderCell: (params) => {
        return <Box>{params.row.title ? params.row.title : '---'}</Box>
      },
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 3,
      minWidth: 140,
      renderCell: (params) => {
        return <Box>{params.row.description ? params.row.description : '---'}</Box>
      },
    },
    {
      field: 'actions',
      headerName: t('common:actions'),
      hide: location?.pathname?.includes('case-management') && !hasPermissions,
      flex: 1,
      minWidth: 140,
      renderCell: (params) => {
        return <Box>{params.row.actions ? params.row.actions.length : '---'}</Box>
      },
    },
    {
      field: 'createdAt',
      headerName: t('common:createdAt'),
      flex: 1,
      minWidth: 200,
      valueGetter: (params) => {
        return buildTimeStringFromTimestamp(params.row.createdAt)
      },
    },
    {
      field: 'updatedAt',
      headerName: t('common:updatedAt'),
      flex: 1,
      minWidth: 200,
      valueGetter: (params) => {
        return buildTimeStringFromTimestamp(params.row.updatedAt)
      },
    },
  ]

  return (
    <Box>
      {isLoading && <Spinner />}
      <Box className={`${isLoading && 'spinner-transition'} datatable`}>
        <TableContainer>
          <Box className="grid-table-container">
            <DataGrid
              className="grid-table"
              getRowId={(row) => row._id || row.id}
              rows={(Array.isArray(checklists) && checklists.length && checklists) || []}
              columns={columns}
              hideFooter={true}
              autoHeight={true}
            />
          </Box>
        </TableContainer>
        <ChecklistInstanceDrawer
          open={open}
          setOpen={setOpen}
          check={check}
          isCreation={false}
          checklists={checklists}
          setChecklists={setChecklists}
        />
      </Box>
    </Box>
  )
}

export default DatatableChecklistInstances

import Featured from '../../components/featured/Featured'
import Widget from '../../components/common/widget/Widget'
import { useAnnualTransactions } from '../pages-hooks/useTransactions'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Button, Modal, Typography } from '@mui/material'
import { decodeToken } from 'react-jwt'
import { useState } from 'react'
import { acceptExpirationPasswordMessage } from '../../features/auth/loginSlice'
import Chart from '../../components/common/chart/Charts'
import { useTranslation } from 'react-i18next'

const DemoHome = () => {
  const { emailClient, accessToken, expirationMessageAccepted } = useSelector((state) => state.login)
  const transactions = useAnnualTransactions(emailClient)
  const decodedToken = decodeToken(accessToken)
  const [open, setOpen] = useState(!expirationMessageAccepted && decodedToken?.timeToExpirationPassword ? true : false)
  const dispatch = useDispatch()
  const { t } = useTranslation(['common', 'messages'])

  const handleClose = () => {
    setOpen(false)
    dispatch(acceptExpirationPasswordMessage(true))
  }

  return (
    <Box className="overview">
      <Box className="overview-top">
        {/*         <Widget type="transactions" inputData={transactions} />
        <Widget type="users" inputData={transactions} /> */}
        <Widget type="consumptions" inputData={transactions} />
      </Box>
      <Box className="overview-bottom">
        <Box className="featured">
          <Featured />
        </Box>
        <Box className="charts">
          <Chart />
        </Box>
      </Box>
      {/*  <BarChart2 inputData={transactions} /> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-password-days">
          <Typography id="modal-modal-title" variant="h5" component="h2">
            {t('messages:attention')}
          </Typography>
          <p className="modal-password-message">
            {t('messages:passwordExpire')} <span>{Math.trunc(decodedToken?.timeToExpirationPassword)}</span> days.
          </p>
          <Box className="modal-buttons">
            <Button size="large" variant="contained" color="secondary" type="submit" onClick={handleClose}>
              Ok
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}

export default DemoHome

import './alertFetchSpinner.scss'
import { Box, CircularProgress, Snackbar, Typography } from '@mui/material'
import Alert from '@mui/material/Alert'
import { useEffect, useState } from 'react'

const AlertFetchSpinnerAutoClose = ({ message, spinner, error }) => {
  const [open, setOpen] = useState(spinner)

  useEffect(() => {
    if (spinner === true) {
      setOpen(true)
    }
  }, [spinner])

  return (
    <Snackbar
      open={open}
      onClose={() => setOpen(false)}
      autoHideDuration={3000}
      message="This Snackbar will be dismissed in 5 seconds."
    >
      <Box className="alertFetchSpinner">
        {spinner && (
          <Box className="spinnerFetching">
            <Typography variant="subtitle3">{message}</Typography>
            <svg width={0} height={0}>
              <defs>
                <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                  <stop offset="0%" stopColor="#19DB55" />
                  <stop offset="100%" stopColor="#19DB5542" />
                </linearGradient>
              </defs>
            </svg>
            <CircularProgress sx={{ 'svg circle': { stroke: 'url(#my_gradient)' } }} size={20} />
          </Box>
        )}
        {error && <Alert severity="error">{message}</Alert>}
        {!error && !spinner && (
          <Alert severity={message === 'Request pending approval for execution' ? 'warning' : 'success'}>
            {message}
          </Alert>
        )}
      </Box>
    </Snackbar>
  )
}

export default AlertFetchSpinnerAutoClose

import './alertFetchSpinner.scss'
import { Box, CircularProgress, Typography } from '@mui/material'
import Alert from '@mui/material/Alert'

const AlertFetchSpinner = ({ message, spinner, error }) => {
  return (
    <Box className="alertFetchSpinner">
      {spinner && (
        <Box className="spinnerFetching">
          <Typography variant="subtitle3">{message}</Typography>
          <svg width={0} height={0}>
            <defs>
              <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" stopColor="#19DB55" />
                <stop offset="100%" stopColor="#19DB5542" />
              </linearGradient>
            </defs>
          </svg>
          <CircularProgress sx={{ 'svg circle': { stroke: 'url(#my_gradient)' } }} size={20} />
        </Box>
      )}
      {error && <Alert severity="error">{message}</Alert>}
      {!error && !spinner && (
        <Alert severity={message === 'Request pending approval for execution' ? 'warning' : 'success'}>{message}</Alert>
      )}
    </Box>
  )
}

export default AlertFetchSpinner

import { useEffect } from 'react'
import { decodeToken, isExpired } from 'react-jwt'
import { useSelector } from 'react-redux'
import { Navigate, useNavigate } from 'react-router-dom'
import Layout from './components/common/layout/Layout'
import ChangePassword from './pages/login/ChangePassword'
import { LogoutTimer } from './LogoutTimer'

export const CheckJWT = ({ path, next, name, handleUserLogout, activeServices }) => {
  const navigate = useNavigate()
  const { accessToken } = useSelector((state) => state.login)
  const decodedToken = decodeToken(accessToken)

  const subserviceMap = {
    '/health-checks': 'healthCheck',
    '/transactions': 'transactions:email',
    '/transactions/:id': 'retrieveTransaction:transactionId',
    '/users': 'consumerUsers:email,businessUsers:email',
    '/users/:id': 'retrieveBusinessUser:businessUserId,retrieveConsumerUser:consumerUserId',
    '/rules': 'retrieveClientInstanceRules,sendSlackMessage,retrieveClientRules',
    '/admin/users': 'userall',
    '/admin/consumptions': 'allClientConsumptionsBetweenDates',
    '/admin/roles': 'adminrolesemailClient',
    '/admin/reports': 'reportsclienttypes, reportsclient:type',
    '/case-management': 'caseManagement',
    '/onboarding': 'onboarding',
    '/aml': 'aml',
    '/amlCrypto': 'amlCrypto',
    '/kyb': 'kyb',
    '/admin/riskMatrix': 'riskmatrix',
  }

  const isIn = () => {
    if (!activeServices) {
      return null
    }
    return (
      activeServices?.some((subservice) => {
        if (path in subserviceMap) {
          let aux = subserviceMap[path].split(',')
          return aux.some((ss) => subservice.includes(ss))
        }
        return !subservice.includes('healthCheck')
      }) ?? false
    )
  }

  const verifyRoles = () => {
    return activeServices && activeServices.length !== 0
      ? activeServices.some((path) => {
          return (
            path === 'adminrolesemailClient' ||
            (path === 'allClientConsumptionsBetweenDates' && !path.includes('healthCheck'))
          )
        })
      : false
  }

  const verifyRoleCreation = () => {
    return activeServices && activeServices.length !== 0
      ? activeServices.some((path) => {
          return path === 'adminroles' && !path.includes('healthCheck')
        })
      : false
  }

  useEffect(() => {
    if (
      (path && path !== '/' && !isIn()) ||
      (path && path === '/admin/roles' && !verifyRoles()) ||
      (path && path === '/admin/roles/creation' && !verifyRoleCreation())
    ) {
      navigate('/')
    }
  }, [path])

  useEffect(() => {
    return () => {
      if (isExpired(accessToken)) handleUserLogout()
    }
  })

  return (
    <>
      {isExpired(accessToken) ? (
        <Navigate to="/" />
      ) : decodedToken.passwordChangeRequired ? (
        <ChangePassword handleUserLogout={handleUserLogout} />
      ) : (
        <LogoutTimer handleUserLogout={handleUserLogout}>
          <Layout handleUserLogout={handleUserLogout} activeServices={activeServices} name={name}>
            {next}
          </Layout>
        </LogoutTimer>
      )}
    </>
  )
}

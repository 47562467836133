import { createSlice } from '@reduxjs/toolkit'

export const ruleSlice = createSlice({
  name: 'rule',
  initialState: {
    instanceRules: [],
    rules: [],
    filters: {},
    filtersGueno: {},
    creation: false,
  },
  reducers: {
    updateInstanceRules: (state, action) => {
      state.instanceRules = action.payload
    },
    updateRules: (state, action) => {
      state.rules = action.payload
    },
    updateFilters: (state, action) => {
      state.filters = action.payload
    },
    updateFiltersGueno: (state, action) => {
      state.filtersGueno = action.payload
    },
    updateCreation: (state, action) => {
      state.creation = action.payload
    },
    performLogoutRules: (state) => {
      state.instanceRules = []
      state.rules = []
      state.creation = []
    },
  },
})

export const {
  updateInstanceRules,
  updateRules,
  updateFilters,
  updateFiltersGueno,
  updateCreation,
  performLogoutRules,
} = ruleSlice.actions
export default ruleSlice.reducer

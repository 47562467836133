import { buildTimeStringFromTimestamp } from '../../time/timeHelper'

export const kytTransactionDashboardDataMapperForPDF = (data) => {
  const header = ['Action Owner', 'Action', 'Status', 'Date', 'Transaction Id', 'Transaction State']
  const parsedData = data.map((tx) => {
    return [
      tx.emailUser,
      tx.activityDetails?.abmAction,
      tx.activityDetails?.actionResult,
      buildTimeStringFromTimestamp(tx.createdAt),
      tx.activityDetails?.newRecord?.transactionId
        ? tx.activityDetails?.newRecord?.transactionId
        : tx.activityDetails?.previousRecord?.transactionId,
      tx.activityDetails?.newRecord?.transactionState
        ? tx.activityDetails?.newRecord?.transactionState
        : tx.activityDetails?.previousRecord?.transactionState,
    ]
  })

  return {
    columns: header,
    body: parsedData,
  }
}

export const kytTransactionDashboardDataMapper = (data) => {
  return data.map((tx) => {
    return {
      ActionOwner: tx.emailUser,
      Action: tx.activityDetails?.abmAction,
      Status: tx.activityDetails?.actionResult,
      Date: buildTimeStringFromTimestamp(tx.createdAt),
      TransactionId: tx.activityDetails?.newRecord?.transactionId
        ? tx.activityDetails?.newRecord?.transactionId
        : tx.activityDetails?.previousRecord?.transactionId,
      TransactionState: tx.activityDetails?.newRecord?.transactionState
        ? tx.activityDetails?.newRecord?.transactionState
        : tx.activityDetails?.previousRecord?.transactionState,
    }
  })
}

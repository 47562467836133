import './dataExportModal.scss'
import { useEffect, useState } from 'react'
import { Box, Button, Fab, FormControlLabel, MenuItem, Modal, Select, TextField, Typography } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { convertToCSV, downloadCSV, toFlatPropertyMap, transactionCSVtoPDF, transactionCSVtoXML } from './dataExport'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { TransactionExport } from '../../../pages/pages-hooks/useTransactions'
import AlertFetchSpinner from '../alertFetchSpinner/alertFetchSpinner'
import { usersExport } from '../../../pages/pages-hooks/useUsers'
import { exportReports } from '../../../pages/pages-hooks/useReports'

const exportExtensions = ['.CSV', '.PDF', '.XML']
const typeDataExportArray = ['transactions', 'consumerUsers', 'businessUsers', 'reports']

const DataExportModal = ({
  show,
  setShow,
  data,
  fileName,
  dataMapper,
  dataMapperForPDF,
  typeDataExport,
  keyReports,
}) => {
  const { accessToken, email, emailClient } = useSelector((state) => state.login)
  const [windowSize, setWindowSize] = useState('page')
  const [rows, setRows] = useState(5000)
  const [fetchError, setFetchError] = useState(false)
  const [fetchMessage, setFetchMessage] = useState('')
  const [isFetching, setIsFetching] = useState(false)
  const { t } = useTranslation(['common', 'messages'])
  const [extensionType, setExtensionType] = useState(exportExtensions[0])
  const [selectedFormControlLabel, setSelectedFormControlLabel] = useState('page')
  const queryState = useSelector((state) => state.report.filters)
  const queryStateUsers = useSelector((state) => state.user.filters)
  const queryStateTransaction = useSelector((state) => state.transaction.filters)

  const handleChangeWindowSize = (event) => {
    setWindowSize(event.target.value)
  }
  const handleExport = () => {
    const limit = selectedFormControlLabel === 'allData' ? null : rows

    if (typeDataExportArray.includes(typeDataExport)) {
      setIsFetching(true)
      switch (typeDataExport) {
        case 'transactions':
          TransactionExport(limit, accessToken, queryStateTransaction)
          break
        case 'consumerUsers':
          usersExport(limit, accessToken, queryStateUsers, 'consumerUsers')
          break
        case 'businessUsers':
          usersExport(limit, accessToken, queryStateUsers, 'businessUsers')
          break
        case 'reports':
          exportReports(limit, accessToken, queryState, keyReports)
          break
        default:
          console.error('Unsupported typeDataExport:', typeDataExport)
          break
      }
      setFetchMessage('Export success')
      setFetchError(false)
      setTimeout(() => {
        setIsFetching(false)
      }, 5000)
    } else {
      console.error('typeDataExport not in typeDataExportArray')
    }
  }

  const buildQueryStateForReport = () => {
    return {
      abmAction: queryState.abmAction !== undefined ? queryState.abmAction : '',
      actionResult: queryState.actionResult !== undefined ? queryState.actionResult : '',
      ActionType: queryState.ActionType !== undefined ? queryState.ActionType : '',
      affectedUser: queryState.affectedUser !== undefined ? queryState.affectedUser : '',
      affectedUser2: queryState.affectedUser2 !== undefined ? queryState.affectedUser2 : '',
      collection: queryState.collection !== undefined ? queryState.collection : '',
      emailUser: queryState.emailUser !== undefined ? queryState.emailUser : '',
      extension: queryState.extension !== undefined ? queryState.extension : '',
      fromDate: queryState.fromDate !== undefined ? queryState.fromDate : '',
      limit: queryState.limit !== undefined ? queryState.limit : '',
      offset: queryState.offset !== undefined ? queryState.offset : '',
      page: queryState.page !== undefined ? queryState.page : '',
      reportRoleName: queryState.reportRoleName !== undefined ? queryState.reportRoleName : '',
      status: queryState.status !== undefined ? queryState.status : '',
      toDate: queryState.toDate !== undefined ? queryState.toDate : '',
    }
  }

  const sendGenerateReportEvent = ({ fileName, fileExtension }) => {
    if (!process.env.REACT_APP_IS_DEMO) {
      const url = `${process.env.REACT_APP_BASEURL}/reports/item`
      const body = {
        emailClient: emailClient,
        emailUser: email,
        type: 'generated_reports',
        activityDetails: {
          actionResult: 'SUCCESS',
          filters: buildQueryStateForReport(),
          extension: fileExtension,
          data: fileName,
        },
      }

      const options = {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      }

      fetch(url, options)
        .then((res) => res.json())
        .catch((err) => {
          console.error('[DOWNLOAD REPORT ERROR] --> ', err)
        })
    }
  }

  const handleDownloadPage = () => {
    const csvData = convertToCSV(dataMapper(data))

    const name = fileName + ' - ' + new Date().getTime()

    switch (extensionType) {
      case '.CSV':
        downloadCSV(csvData, name)
        sendGenerateReportEvent({ fileName: name, fileExtension: 'CSV' })
        break

      case '.PDF':
        transactionCSVtoPDF(dataMapperForPDF(data), name)
        sendGenerateReportEvent({ fileName: name, fileExtension: 'PDF' })
        break

      case '.XML':
        if (fileName === 'security_parameters_report') {
          data = [data]
        }
        const flatData = data.map((entry) => toFlatPropertyMap(entry, '.'))
        transactionCSVtoXML(convertToCSV(flatData), name)
        sendGenerateReportEvent({ fileName: name, fileExtension: 'XML' })
        break

      default:
        console.error('ERROR on downloading report. Please contact support')
        break
    }
  }

  return (
    <Modal open={show} onClose={() => setShow(false)}>
      <Box className="modal">
        <Box className="modal-top">
          <Box className="modal-titles">
            <Typography variant="title">
              {t('common:export')} {t('common:list')}
            </Typography>
            <Typography variant="subtitle3">{t('messages:formatExport')}</Typography>
          </Box>
          <Fab variant="close" onClick={() => setShow(false)}>
            <CloseOutlinedIcon />
          </Fab>
        </Box>
        <FormControl>
          <Box className="modal-box">
            <Typography variant="title2">{t('common:selectFormat')}</Typography>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={windowSize}
              onChange={handleChangeWindowSize}
            >
              <FormControlLabel
                value="page"
                control={<Radio />}
                label="Actual page"
                onChange={() => {
                  setSelectedFormControlLabel('page')
                }}
              />
              <FormControlLabel
                value="n-rows"
                control={<Radio />}
                label={
                  <Box display="flex" alignItems="center" gap="0.5rem">
                    <Typography variant="subtitle">N rows </Typography>
                    <TextField
                      disabled={selectedFormControlLabel !== 'n-rows'}
                      type="number"
                      value={rows}
                      onChange={(e) => setRows(e.target.value)}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Box>
                }
                onChange={() => {
                  setSelectedFormControlLabel('n-rows')
                }}
              />
              <FormControlLabel
                value="all-rows"
                control={<Radio />}
                label="All data"
                onChange={() => {
                  setSelectedFormControlLabel('allData')
                }}
              />
            </RadioGroup>
            <Select
              fullWidth
              // disablePortal
              value={extensionType}
              onChange={(e) => setExtensionType(e.target.value)}
              sx={{ marginBottom: '1rem' }}
            >
              {selectedFormControlLabel === 'page' ? (
                exportExtensions.map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem key={exportExtensions[0]} value={exportExtensions[0]}>
                  {exportExtensions[0]}
                </MenuItem>
              )}
            </Select>
          </Box>
        </FormControl>
        {isFetching && <AlertFetchSpinner message={fetchMessage} error={fetchError} />}
        <Box className="modal-box">
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              if (selectedFormControlLabel === 'page') {
                handleDownloadPage()
              } else {
                handleExport()
              }
            }}
          >
            {t('common:export')} {t('common:list')}
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default DataExportModal

import '../../../pages/case-management/cases/case.scss'
import React, { useState } from 'react'
import { Box, TableContainer, TablePagination } from '@mui/material'
import { useSelector } from 'react-redux'
import DatatableCases from './DatatableCases'
import { useUserCases } from '../../../pages/pages-hooks/useCases'

const DatatableUserCases = ({ userId, hasPermissions, isFromDetail }) => {
  const [caseToClose, setCaseToClose] = useState('')
  const [openCloseCase, setOpenCloseCase] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  useUserCases(setIsLoading, isLoading, userId, rowsPerPage, page, page * rowsPerPage, origin)
  const userCasesFromSlice = useSelector((state) => state.case.userCases)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <TableContainer>
      <DatatableCases
        cases={userCasesFromSlice?.data ? userCasesFromSlice : []}
        setCaseToClose={setCaseToClose}
        setOpenCloseCase={setOpenCloseCase}
        hasPermissions={hasPermissions}
        isFromDetail={isFromDetail}
      />
      <TablePagination
        labelRowsPerPage={[]}
        component="div"
        count={userCasesFromSlice?.pagination?.totalElements || 0}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  )
}

export default DatatableUserCases

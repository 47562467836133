import { createSlice } from '@reduxjs/toolkit'

export const amlCryptoSlice = createSlice({
  name: 'amlCrypto',
  initialState: {
    amlCrypto: {},
    amlCryptoDetail: {},
    filters: {
      page: 0,
      limit: 25,
      offset: 0,
      fromDate: null,
      toDate: '',
      id: '',
      protocol: '',
      address: '',
    },
  },
  reducers: {
    updateAmlCrypto: (state, action) => {
      state.amlCrypto = action.payload
    },
    updateAmlCryptoDetail: (state, action) => {
      state.amlCryptoDetail = action.payload
    },
    updateAmlCryptoFilters: (state, action) => {
      state.filters = action.payload
    },
  },
})

export const { updateAmlCrypto, updateAmlCryptoDetail, updateAmlCryptoFilters } = amlCryptoSlice.actions
export default amlCryptoSlice.reducer

import './featured.scss'
import 'react-circular-progressbar/dist/styles.css'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import { useTransactionsMonthPercentage } from '../../pages/pages-hooks/useTransactions'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, Paper, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

const Featured = () => {
  const { emailClient } = useSelector((state) => state.login)
  const [success, setSuccess] = useState({})
  const { t } = useTranslation(['messages', 'common'])
  const transactions = useTransactionsMonthPercentage(emailClient)

  useEffect(() => {
    if (transactions && transactions.length > 0) {
      const index = transactions.findIndex((object) => {
        return object._id === 'SUCCESSFUL'
      })
      if (index !== -1 && transactions[index]) setSuccess(transactions[index])
    }
  }, [transactions])

  return (
    <Paper className="featured" elevation={0}>
      <Box className="top">
        <Typography variant="text4">{t('common:processedTransactions')}</Typography>
      </Box>
      <Box className="bottom">
        <Box className="featuredChart">
          <CircularProgressbar
            minValue={0}
            maxValue={100}
            value={Object.keys(success).length > 0 ? success.percentage : 0}
            text={`${Object.keys(success).length > 0 ? Math.trunc(success.percentage) : 0}%`}
            strokeWidth={5}
            styles={buildStyles({
              rotation: 0.25,
              strokeLinecap: 'butt',
              pathTransitionDuration: 0.5,
              textColor: 'var(--blue-60)',
            })}
          />
        </Box>
        <Typography variant="subtitle" style={{ textAlign: 'center' }}>
          {t('messages:monthTsx')}
        </Typography>
        <p className="amount">{Object.keys(success).length > 0 ? success.numberoftransactions : 0}</p>
        <Typography variant="subtitle3">{t('messages:tsxProcessing')}</Typography>

        {transactions &&
          transactions.length !== 0 &&
          transactions.map((tStatus, index) => {
            if (tStatus._id !== 'SUCCESSFUL')
              return (
                <Box className="details" key={index}>
                  <Typography variant="text3">{tStatus._id}</Typography>
                  <Typography variant="number">
                    {Math.trunc(tStatus.percentage)}% ({tStatus.numberoftransactions})
                  </Typography>
                </Box>
              )
          })}
      </Box>
    </Paper>
  )
}

export default Featured

import { NodeCategoryType } from './NodeCategories'

export const NodeType = {
  IFraudEngine: 'intelligence fraud engine',
  DeviceIntelligence: 'device intelligence',
  CompanyManualCheckKYB: 'company manual check - KYB ',
  PersonManualCheckKYB: 'person manual check - KYB ',
  CompanyBlocklistsKYB: 'blocklists - KYB ',
  PersonBlocklistsKYB: 'blocklists - KYB ',
  IdentityCheckKYC: 'identity check - KYC',
  ManualCheckAML: 'data-mapper',
  CryptoCheckAML: 'data-mapper',
  ECommerceRiskAnalizer: 'ecommerce risk analyzer',
  TxsManualCheckKYT: 'txs manual check - KYT',
  ClientsManualCheckKYT: 'clients manual check - KYT',
  TxRulesEngine: 'txs rules engine - KYT',
  TxsOP: 'txs opposition control - KYT',
  GovCheck: 'gov check - GovCheck',
  Slack: 'slack',
  Zappier: 'zappier',
  Jira: 'jira',
  Trello: 'trello',
}

export const Nodes = {
  [NodeType.IFraudEngine]: {
    label: 'Fraud Engine',
    category: NodeCategoryType.Advanced,
    key: NodeType.IFraudEngine,
  },
  [NodeType.DeviceIntelligence]: {
    label: 'Device Intelligence',
    category: NodeCategoryType.Advanced,
    key: NodeType.DeviceIntelligence,
  },
  [NodeType.TxRulesEngine]: {
    label: 'Rules Engine',
    category: NodeCategoryType.Advanced,
    key: NodeType.TxRulesEngine,
  },
  [NodeType.ECommerceRiskAnalizer]: {
    label: 'EC Risk Analizer',
    category: NodeCategoryType.Advanced,
    key: NodeType.ECommerceRiskAnalizer,
  },
  [NodeType.Slack]: {
    label: 'Slack',
    category: NodeCategoryType.Other,
    key: NodeType.Slack,
  },
  [NodeType.Zappier]: {
    label: 'Zappier',
    category: NodeCategoryType.Other,
    key: NodeType.Zappier,
  },
  [NodeType.Trello]: {
    label: 'Trello',
    category: NodeCategoryType.Other,
    key: NodeType.Trello,
  },
  [NodeType.Jira]: {
    label: 'Jira',
    category: NodeCategoryType.Other,
    key: NodeType.Jira,
  },
  [NodeType.CompanyManualCheckKYB]: {
    label: 'Company KYB',
    category: NodeCategoryType.Basic,
    key: NodeType.CompanyManualCheckKYB,
  },
  [NodeType.PersonManualCheckKYB]: {
    label: 'Person KYB',
    category: NodeCategoryType.Basic,
    key: NodeType.PersonManualCheckKYB,
  },
  [NodeType.IdentityCheckKYC]: {
    label: 'Identity KYC',
    category: NodeCategoryType.Basic,
    key: NodeType.IdentityCheckKYC,
  },
  [NodeType.ManualCheckAML]: {
    label: 'AML',
    category: NodeCategoryType.Basic,
    key: NodeType.ManualCheckAML,
  },
  [NodeType.CryptoCheckAML]: {
    label: 'AML Crypto',
    category: NodeCategoryType.Basic,
    key: NodeType.CryptoCheckAML,
  },
  [NodeType.TxsManualCheckKYT]: {
    label: 'Txs Check',
    category: NodeCategoryType.Basic,
    key: NodeType.TxsManualCheckKYT,
  },
  [NodeType.ClientsManualCheckKYT]: {
    label: 'Clients  Check',
    category: NodeCategoryType.Basic,
    key: NodeType.ClientsManualCheckKYT,
  },
  [NodeType.TxsOP]: {
    label: 'Opposition Control',
    category: NodeCategoryType.Basic,
    key: NodeType.TxsOP,
  },
  [NodeType.GovCheck]: {
    label: 'Govs Check',
    category: NodeCategoryType.Basic,
    key: NodeType.GovCheck,
  },
}

import '../home/home.scss'
import '../onboarding/onboarding.scss'
import '../../components/common/alertFetchSpinner/alertFetchSpinner.scss'
import { Box, Tab, Modal, Typography, Paper, InputAdornment, Button, TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { FileUploader } from 'react-drag-drop-files'
import { useTranslation } from 'react-i18next'
import { RiskLevel } from '../../components/utilities/RiskLevel'
import { SearchOutlined } from '@mui/icons-material'
import AlertFetchSpinner from '../../components/common/alertFetchSpinner/alertFetchSpinner'
import Spinner from '../../components/common/spinner/spinner'
import LetterAvatar from '../../components/common/userDetails/LetterAvatar'
import MyDatePicker from '../../components/utilities/MyDatePicker'
import { updateKybListFilters } from '../../features/kybLists/kybListsSlice'
import { useKybList } from '../pages-hooks/useKybList'
import { FilterModal } from '../../components/utilities/FilterModal'
import { DataGridComponent } from '../../components/common/datagrid/DataGridComponent'
const FILE_TYPES = ['XLSX']

const KybLists = () => {
  const { tab } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const queryState = useSelector((state) => state.kybList.filters)
  const { accessToken } = useSelector((state) => state.login)

  const [isLoading, setIsLoading] = useState(true)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [toDate, setToDate] = useState(null)
  const [actualTab, setActualTab] = useState('CNPJ')
  const [id, setId] = useState(queryState.id)
  const [status, setStatus] = useState(queryState.status)
  const [name, setName] = useState(queryState.name)
  const [selectedDate, setSelectedDate] = useState(queryState.fromDate)
  const [documentNumber, setDocumentNumber] = useState(queryState.documentNumber)
  const [modalOpen, setModalOpen] = useState(false)
  const [filterModalOpen, setFilterModalOpen] = useState(false)
  const [file, setFile] = useState('')
  const [fetchError, setFetchError] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [fetchMessage, setFetchMessage] = useState('')
  const [isProcessing, setIsProcessing] = useState(false)
  const { t } = useTranslation(['common', 'messages'])

  useKybList(setIsLoading, isLoading, actualTab)

  const list = useSelector((state) => state.kybList.list)

  const columnsCnpj = [
    {
      field: '_id',
      headerName: 'ID',
      renderCell: (params) => {
        return <Typography variant="number">{params?.row?._id}</Typography>
      },
    },
    {
      field: 'documentNumber',
      headerName: t('common:documentNumber'),
      renderCell: (params) => {
        return <Typography variant="number">{params?.row?.documentNumber ?? '---'}</Typography>
      },
    },
    {
      field: 'name',
      headerName: t('common:name'),
      renderCell: (params) => {
        return (
          <Box display="flex" alignItems="center" gap=".5rem">
            {params?.row?.name && (
              <LetterAvatar fullName={params.row.name.toUpperCase()} height="1.5rem" width="1.5rem" />
            )}
            <p>{params.row.name}</p>
          </Box>
        )
      },
    },
    {
      field: 'address',
      headerName: t('common:address'),
      renderCell: (params) => {
        return <p>{params.row.listDetails.address ?? '---'}</p>
      },
    },
    {
      field: 'relationship',
      headerName: t('common:relationship'),
      renderCell: (params) => {
        return <p>{params.row.listDetails.relationship ?? '---'}</p>
      },
    },
    {
      field: 'expirationDate',
      headerName: t('common:expirationDate'),
      renderCell: (params) => {
        return <p>{params.row.listDetails.expirationDate ?? '---'}</p>
      },
    },
    {
      field: 'description',
      headerName: t('common:description'),
      renderCell: (params) => {
        return <p>{params.row.listDetails.description ?? '---'}</p>
      },
    },
  ]

  const columnsCpf = [
    {
      field: '_id',
      headerName: 'ID',
      renderCell: (params) => {
        return <Typography variant="number">{params?.row?._id}</Typography>
      },
    },
    {
      field: 'documentNumber',
      headerName: t('common:documentNumber'),
      flex: 1,
      minWidth: 140,
      renderCell: (params) => {
        return <Typography variant="number">{params?.row?.documentNumber ?? '---'}</Typography>
      },
    },
    {
      field: 'name',
      headerName: t('common:name'),
      renderCell: (params) => {
        return <p>{params.row.name ?? '---'}</p>
      },
    },
    {
      field: 'relatedCompany',
      headerName: t('common:relatedCompany'),
      renderCell: (params) => {
        return <p>{params.row.listDetails.relatedCompany ?? '---'}</p>
      },
    },
    {
      field: 'relationship',
      headerName: t('common:relationship'),
      renderCell: (params) => {
        return <p>{params.row.listDetails.relationship ?? '---'}</p>
      },
    },
    {
      field: 'isPep',
      headerName: 'Is Pep',
      renderCell: (params) => {
        return <p>{params.row.listDetails.isPep ?? '---'}</p>
      },
    },
    {
      field: 'riskLevel',
      headerName: t('common:riskLevel'),
      renderCell: (params) => {
        const riskLevel = params.row.listDetails?.riskLevel
        const validRiskLevels = ['VERY_HIGH', 'HIGH', 'MEDIUM', 'LOW', 'VERY_LOW', 'NOT_DEFINED']
        const phRiskLevel =
          riskLevel && validRiskLevels.includes(riskLevel.toUpperCase()) ? riskLevel.toUpperCase() : 'NOT_DEFINED'
        return <RiskLevel type={phRiskLevel} />
      },
    },
    {
      field: 'description',
      headerName: t('common:description'),
      renderCell: (params) => {
        return <p>{params.row.listDetails.description ?? '---'}</p>
      },
    },
  ]

  const handleResetButton = () => {
    setId('')
    setStatus('')
    setName('')
    setDocumentNumber('')
    setSelectedDate(null)
    setPage(0)
    setIsLoading(true)

    return dispatch(
      updateKybListFilters({
        page: 0,
        limit: 25,
        offset: 0,
        id: '',
        status: null,
        name: '',
        fromDate: null,
        toDate: '',
        documentNumber: '',
      }),
    )
  }

  const handleDispatch = () => {
    setIsLoading(true)
    return dispatch(
      updateKybListFilters({
        limit: rowsPerPage,
        offset: rowsPerPage * page,
        page,
        id,
        status,
        name,
        fromDate: selectedDate?.toISOString(),
        toDate: new Date(toDate)?.toISOString(),
        documentNumber,
      }),
    )
  }

  const handleChange = (event, newValue) => {
    handleResetButton()
    navigate(`/kyb/lists/${newValue}`)
  }

  useEffect(() => {
    handleDispatch()
  }, [rowsPerPage, page])

  useEffect(() => {
    if (selectedDate) {
      const formattedDate = new Date(selectedDate)
      setToDate(selectedDate ? formattedDate.setDate(formattedDate.getDate() + 1) : null)
    }
  }, [selectedDate])

  useEffect(() => {
    if (!tab || (tab !== 'CNPJ' && tab !== 'CPF')) {
      navigate('/kyb/lists/CNPJ')
      setActualTab('CNPJ')
    } else {
      setActualTab(tab)
    }
  }, [tab])

  const handleUpdate = () => {
    setIsFetching(true)
    setFetchMessage('')
    setIsProcessing(true)
    let formData = new FormData()
    if (file !== '') formData.append('file', file)

    if (!process.env.REACT_APP_IS_DEMO) {
      let url = `${process.env.REACT_APP_BASEURL}/kyb/list`

      const options = {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      }

      fetch(url, options)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            setIsProcessing(false)
            setModalOpen(false)
            setFetchMessage('Update success.')
          } else {
            throw new Error(res.message ? res.message : t('messages:unexpectedError'))
          }
        })
        .catch((err) => {
          console.error('[DOWNLOAD REPORT ERROR] --> ', err)
          setIsProcessing(false)
          setFetchError(true)
          setFetchMessage(err.message ? err.message : t('messages:unexpectedErrorValues'))
        })
    } else {
      setIsProcessing(false)
      setModalOpen(false)
      setFetchMessage('Update success.')
    }

    setTimeout(() => {
      setIsFetching(false)
    }, 3000)
  }

  return (
    <Box className={`${isLoading && 'spinner-transition'} filter`}>
      {isLoading && <Spinner noTransform />}
      {isFetching && <AlertFetchSpinner message={fetchMessage} error={fetchError} spinner={isProcessing} />}
      <Box display="flex" justifyContent="space-between">
        <Box className="component-title-wrapper">
          <Box className="component-title">
            <Typography variant="h2">
              {t('common:company')} {t('common:blocklists')}
            </Typography>
          </Box>
          <Typography variant="subtitle3">
            {/* Loren ipsum dolor sit amet Loren ipsum dolor sit amet Loren  */}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" gap={1}>
          <Button size="small" onClick={() => setModalOpen(true)} variant="contained">
            {t('common:update')}
          </Button>
          <Button size="small" variant="contained">
            <a
              href={`${process.env.PUBLIC_URL}/exampleBlockList.xlsx`}
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              {t('common:downloadExample')}
            </a>
          </Button>
        </Box>
      </Box>
      <Paper
        elevation={0}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
        }}
      >
        <TabContext value={actualTab}>
          <TabList onChange={handleChange}>
            <Tab label="CNPJ" value="CNPJ" />
            <Tab label="CPF" value="CPF" />
          </TabList>

          <TabPanel value="CNPJ">
            <DataGridComponent
              rows={Array.isArray(list?.data) && list?.data?.length ? list?.data : []}
              columns={columnsCnpj}
              count={list?.pagination?.totalElements || 0}
              page={page}
              rowsPerPage={rowsPerPage}
              setPage={setPage}
              setRowsPerPage={setRowsPerPage}
              setIsLoading={setIsLoading}
              setModalOpen={setFilterModalOpen}
              hasTabs="23rem"
            />
          </TabPanel>
          <TabPanel value="CPF">
            <DataGridComponent
              rows={Array.isArray(list?.data) && list?.data?.length ? list?.data : []}
              columns={columnsCpf}
              count={list?.pagination?.totalElements || 0}
              page={page}
              rowsPerPage={rowsPerPage}
              setPage={setPage}
              setRowsPerPage={setRowsPerPage}
              setIsLoading={setIsLoading}
              setModalOpen={setFilterModalOpen}
              hasTabs="23rem"
            />
          </TabPanel>
        </TabContext>
      </Paper>
      <FilterModal
        modalOpen={filterModalOpen}
        setModalOpen={setFilterModalOpen}
        handleResetButton={handleResetButton}
        setPage={setPage}
        handleDispatch={handleDispatch}
      >
        <Box className="modal-box-filters">
          <Box className="modal-box">
            <Typography variant="subtitle3">
              {t('common:by')} {t('common:date')}:
            </Typography>

            <MyDatePicker selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
          </Box>

          <Box className="modal-box">
            <Typography variant="subtitle3">{t('common:by')} ID:</Typography>
            <TextField
              variant="outlined"
              size="small"
              value={id}
              onChange={(event) => setId(event.target.value)}
              placeholder={t('common:searchById')}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    onClick={(e) => {
                      e.preventDefault()
                      handleDispatch()
                    }}
                  >
                    <SearchOutlined />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box className="modal-box">
            <Typography variant="subtitle3">
              {t('common:by')} {t('common:documentNumber')}:
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              value={documentNumber}
              onChange={(event) => setDocumentNumber(event.target.value)}
              placeholder={t('common:searchByDocument')}
            />
          </Box>
          <Box className="modal-box">
            <Typography variant="subtitle3">
              {t('common:by')} {t('common:name')}:
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              value={name}
              onChange={(event) => setName(event.target.value)}
              placeholder={t('common:searchByName')}
            />
          </Box>
        </Box>
      </FilterModal>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="create-role">
          <Typography variant="title" component="h2" style={{ marginBottom: '2rem' }}>
            {t('common:update')} {t('common:blacklist')?.toLowerCase()} {t('common:data')?.toLowerCase()}
          </Typography>
          <Box
            className="form"
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '2.5rem',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <p>{t('common:addExcelFileForData')}</p>
            <Box className="draggable-main">
              <FileUploader handleChange={setFile} name="file" types={FILE_TYPES} />
            </Box>
            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '1rem' }}>
              <Button size="small" variant="contained" onClick={() => handleUpdate()}>
                {t('common:yes')}
              </Button>
              <Button size="small" variant="outlined" onClick={() => setModalOpen(false)}>
                {t('common:no')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}

export default KybLists

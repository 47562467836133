import kybs from "../data/kybs";
import kybDetails from "../data/kybDetails";
import kybKycs from "../data/kybKycs";

const demoData = {
  GET: {
    '/api/kyb/': kybs,
    '/api/kyb/v2/CO/': kybs,
    '/api/kyb/all/subjects': kybKycs,
    '/api/kyb/v2/CO/all/subjects': kybKycs,
  },
  POST: {
    '/api/kyb/': kybDetails,
    '/api/kyb/subject': kybDetails
  }
}

export default demoData;
import './userDetail.scss'
import { Box, Button, Paper, Chip, Typography, Tab } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import Spinner from '../../../components/common/spinner/spinner'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateTransactionsFilters } from '../../../features/transactions/transactionSlice'
import { useUsersById } from '../../pages-hooks/useUsers'
import { renderNames } from '../../../components/common/toRender/renderNames'
import PersonIcon from '@mui/icons-material/Person'
import FolderIcon from '@mui/icons-material/Folder'
import BusinessIcon from '@mui/icons-material/Business'
import GroupIcon from '@mui/icons-material/Group'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import LinkIcon from '@mui/icons-material/Link'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import WorkIcon from '@mui/icons-material/Work'
import WarningIcon from '@mui/icons-material/Warning'
import HistoryIcon from '@mui/icons-material/History'
import EventIcon from '@mui/icons-material/Event'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'
import LetterAvatar from '../../../components/common/userDetails/LetterAvatar'
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined'
import StateEditBox from './StateEditBox'
import { useTranslation } from 'react-i18next'
import { camelCaseFormatter } from '../../../components/utilities/formatters/camelCase'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import DatatableContact from '../../../components/common/datatable/DatatableContact'
import LinkedEntities from '../../../components/common/userDetails/LinkedEntities'
import TransactionLimits from '../../../components/common/transactionLimits/TransactionsLimits'
import DatatableUserCases from '../../../components/common/datatable/DatatableUserCases'
import DatatableUserAlerts from '../../../components/common/datatable/DatatableUserAlerts'
import DatatableUserTransaction from '../../../components/common/datatable/DatatableUserTransaction'
import { RenderLegalEntity } from '../../../components/common/toRender/legalEntity.jsx'
import UserDetails from '../../../components/common/userDetails/UserDetails'
import DatatableUserEvent from '../../../components/common/datatable/DatatableUserEvent'
import FeedIcon from '@mui/icons-material/Feed'
import DatatableDocuments from '../../../components/common/datatable/DatatableDocuments'
import DatatableUserReports from '../../../components/common/datatable/DatatableUserReports'
import DatatableUserActions from '../../../components/common/datatable/DatatableUserActions.jsx'
import DatatableTags from '../../../components/common/datatable/DatatableTags.jsx'

const UserDetail = () => {
  const [btnLoading, setBtnLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const { accessToken, activeServices, emailClient } = useSelector((state) => state.login)
  const { id } = useParams()
  const queryState = useSelector((state) => state.transaction.filters)
  const query = new URLSearchParams(window.location.search)
  const type = query.get('type')
  const navigate = useNavigate()
  const [actualUser, setActualUser] = useState([])
  const dispatch = useDispatch()
  const [canEdit, setCanEdit] = useState(false)
  const [selectedKey, setSelectedKey] = useState(type === 'consumer' ? 'userDetails' : 'businessEntity')
  const { t } = useTranslation()

  const sections = [
    {
      key: 'userDetails',
      name: t('common:userDetails'),
      icon: <PersonIcon />,
      condition: actualUser && type === 'consumer',
    },
    {
      key: 'legalDocuments',
      name: t('common:legalDocuments'),
      icon: <FolderIcon />,
      condition: actualUser && type === 'consumer',
    },
    {
      key: 'businessEntity',
      name: t('common:businessEntity'),
      icon: <BusinessIcon />,
      condition: actualUser && type === 'business',
    },
    {
      key: 'shareHolders',
      name: t('common:shareholders'),
      icon: <GroupIcon />,
      condition: actualUser && actualUser?.shareHolders,
    },
    {
      key: 'directors',
      name: t('common:directors'),
      icon: <AccountBalanceIcon />,
      condition: actualUser && actualUser?.directors,
    },
    {
      key: 'linkedEntities',
      name: t('common:linkedEntities'),
      icon: <LinkIcon />,
      condition: actualUser && actualUser?.linkedEntities,
    },
    {
      key: 'transactionLimits',
      name: t('common:transactionLimits'),
      icon: <AttachMoneyIcon />,
      condition: actualUser && actualUser.transactionLimits,
    },
    { key: 'cases', name: t('common:cases'), icon: <WorkIcon />, condition: actualUser },
    {
      key: 'alerts',
      name: t('common:alerts'),
      icon: <WarningIcon />,
      condition: activeServices && activeServices.includes('generalAlertsamlconsumerUser') && type !== 'business',
    },
    {
      key: 'transactionHistory',
      name: t('common:transactionHistory'),
      icon: <HistoryIcon />,
      condition: actualUser,
    },
    {
      key: 'userEvents',
      name: t('common:userEvents'),
      icon: <EventIcon />,
      condition: actualUser && actualUser?.events?.length,
    },
    {
      key: 'userActions',
      name: t('common:userActions'),
      icon: <EventIcon />,
      condition: actualUser,
    },
    {
      key: 'reports',
      name: t('common:reports'),
      icon: <FeedIcon />,
      condition: actualUser,
    },
    {
      key: 'tags',
      name: t('common:tags'),
      icon: <LocalOfferOutlinedIcon />,
      condition: actualUser,
    },
  ].filter((section) => section.condition)

  useEffect(() => {
    if (Array.isArray(activeServices)) {
      if (type === 'consumer' && activeServices.indexOf('kytcreateConsumerUserEvent') > -1) setCanEdit(true)
      if (type === 'business' && activeServices.indexOf('kytcreateBusinessUserEvent') > -1) setCanEdit(true)
    }
  }, [activeServices, type])

  if (!type || (type !== 'consumer' && type !== 'business')) navigate('/users?type=consumerUsers', { replace: true })

  useUsersById(id, type === 'business' ? 'Business' : 'Consumer')
  const userStateFromSlice = useSelector((state) => state.user)

  useEffect(() => {
    if (userStateFromSlice.userDetails) {
      setActualUser(userStateFromSlice.userDetails)
    }
  }, [userStateFromSlice])

  const [userState, setUserState] = useState(null)
  const [kycStatus, setKycStatus] = useState(null)
  const [userType, setUserType] = useState(null)

  const userStateValues = ['ACTIVE', 'BLOCKED', 'CREATED', 'DORMANT', 'SUSPENDED', 'TERMINATED', 'UNACCEPTABLE']

  const kycStatusValues = ['SUCCESSFUL', 'FAILED', 'NOT_STARTED', 'IN_PROGRESS', 'MANUAL_REVIEW']

  const [toUpdate, setToUpdate] = useState(false)

  useEffect(() => {
    if (type === 'consumer') {
      setUserType('updatedConsumerUserAttributes')
    }
    if (type === 'business') {
      setUserType('updatedBusinessUserAttributes')
    }

    if (actualUser?.userStateDetails?.state) {
      setUserState(actualUser?.userStateDetails?.state)
    }
    if (actualUser?.kycStatusDetails?.status) {
      setKycStatus(actualUser?.kycStatusDetails?.status)
    }
  }, [actualUser])

  const handleKycChange = (newKycStatus) => {
    if (kycStatusValues.find((s) => s === newKycStatus)) {
      if (newKycStatus !== actualUser?.kycStatusDetails?.status) {
        setKycStatus(newKycStatus)
        setToUpdate(true)
      }
      if (newKycStatus === actualUser?.kycStatusDetails?.status) {
        setKycStatus(newKycStatus)
        setToUpdate(false)
      }
    } else {
      if (actualUser?.kycStatusDetails?.status) setKycStatus(actualUser?.kycStatusDetails?.status)
      setToUpdate(false)
    }
  }

  const handleUserStateChange = (newUserState) => {
    if (userStateValues.find((s) => s === newUserState)) {
      if (newUserState && newUserState !== actualUser?.userStateDetails?.state) {
        setUserState(newUserState)
        setToUpdate(true)
      }
      if (newUserState && newUserState === actualUser?.userStateDetails?.state) {
        setUserState(newUserState)
        setToUpdate(false)
      }
    } else {
      if (actualUser?.userStateDetails?.state) setUserState(actualUser?.userStateDetails?.state)
      setToUpdate(false)
    }
  }

  const handleResetBtn = () => {
    setUserState(actualUser?.userStateDetails?.state)
    setKycStatus(actualUser?.kycStatusDetails?.status)
    setToUpdate(false)
  }

  const handleUpdateBtn = () => {
    setBtnLoading(true)

    if (!process.env.REACT_APP_IS_DEMO) {
      let body = {
        userId: id,
      }
      let url = `${process.env.REACT_APP_BASEURL}/kyt/${
        userType === 'updatedConsumerUserAttributes' ? 'createConsumerUserEvent' : 'createBusinessUserEvent'
      }`

      if (userState && userState !== '') {
        if (!body[userType]) body[userType] = {}
        if (!body[userType]['userStateDetails']) body[userType]['userStateDetails'] = {}
        body[userType]['userStateDetails']['state'] = userState
      }
      if (kycStatus && kycStatus !== '') {
        if (!body[userType]) body[userType] = {}
        if (!body[userType]['kycStatusDetails']) body[userType]['kycStatusDetails'] = {}
        body[userType]['kycStatusDetails']['status'] = kycStatus
      }

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(body),
      }
      fetch(url, options)
        .then((res) => res.json())
        .then((res) => {
          if (res.isCPO) {
            handleResetBtn()
          } else {
            if (res.status === 200) {
              const newActualUser = { ...actualUser }

              if (userState && userState !== '') newActualUser.userStateDetails = { state: userState }
              if (kycStatus && kycStatus !== '') newActualUser.kycStatusDetails = { status: kycStatus }

              setActualUser(newActualUser)
              setToUpdate(false)
            } else {
              handleResetBtn()
            }
          }

          setBtnLoading(false)
          return res
        })
        .catch((error) => {
          console.error('[UPDATE USER ERROR]', error)
          handleResetBtn()
          setBtnLoading(false)
        })
    } else {
      const newActualUser = { ...actualUser }

      if (userState && userState !== '') newActualUser.userStateDetails = { state: userState }
      if (kycStatus && kycStatus !== '') newActualUser.kycStatusDetails = { status: kycStatus }

      setActualUser(newActualUser)
      setToUpdate(false)
    }
  }

  const handleDispatch = () => {
    return dispatch(
      updateTransactionsFilters({
        viewMode: true,
        limit: rowsPerPage,
        offset: rowsPerPage * page,
        page,
        originCurrency: queryState.originCurrency,
        destinationCurrency: queryState.destinationCurrency,
        originMethod: queryState.originMethod,
        destinationMethod: queryState.destinationMethod,
      }),
    )
  }

  useEffect(() => {
    handleDispatch()
  }, [rowsPerPage, page])

  useEffect(() => {
    setIsLoading(false)
  }, [])

  return (
    <Box>
      {isLoading && <Spinner />}
      <Box className="user-details">
        <Box className="user-details-grid">
          <Paper elevation={0} className="user-details-top">
            <Box className="user-id">
              <Box className="user-title">
                <LetterAvatar
                  fullName={renderNames(actualUser?.userDetails?.name, 'User')}
                  width="2rem"
                  height="2rem"
                />
                <Typography variant="title">
                  {actualUser?.userDetails?.name
                    ? renderNames(actualUser?.userDetails?.name, 'User')
                    : renderNames(actualUser?.legalEntity, 'User')}
                </Typography>
              </Box>

              {type ? (
                <Box>
                  <Chip
                    sx={{
                      backgroundColor: 'var(--lime-03)',
                    }}
                    icon={<PersonIcon />}
                    label={camelCaseFormatter(type)}
                  />
                </Box>
              ) : (
                ''
              )}
            </Box>

            <Box className="user-info">
              <Paper elevation={0} variant="colorPrimary" className="user-box">
                <Typography variant="subtitle1">{t('common:email')}</Typography>
                <Box sx={{ width: 'fit-content' }}>
                  <p>
                    {actualUser?.contactDetails?.emailIds?.length ? actualUser?.contactDetails?.emailIds[0] : '---'}
                  </p>
                </Box>
              </Paper>

              <Paper elevation={0} variant="colorPrimary" className="user-box">
                <Typography variant="subtitle1">{t('common:userId')}</Typography>
                <Box sx={{ width: 'fit-content' }}>
                  <Typography variant="number">{actualUser?.userId ? actualUser?.userId : '---'}</Typography>{' '}
                  <IconButton
                    onClick={() => {
                      navigator.clipboard.writeText(actualUser?.userId)
                    }}
                  >
                    <ContentCopyOutlinedIcon style={{ height: '1rem', width: '1rem' }} />
                  </IconButton>
                </Box>
              </Paper>

              {id && emailClient && emailClient === 'bancodecomercio@gueno.com' && (
                <Paper elevation={0} variant="colorPrimary" className="user-box">
                  <Typography variant="subtitle1">{t('common:revealInformation')}</Typography>
                  <Box sx={{ width: 'fit-content' }}>
                    <Button
                      variant="outlinedGrey"
                      onClick={() => window.open(`${process.env.REACT_APP_REVEAL_INFORMATION_URL}${id}`, '_blank')}
                    >
                      {t('common:revealInformation')}
                    </Button>
                  </Box>
                </Paper>
              )}
            </Box>
          </Paper>
          <Box className="user-details-states">
            <StateEditBox
              subtitle="User State"
              handleStateChange={handleUserStateChange}
              handleUpdateBtn={handleUpdateBtn}
              toUpdate={toUpdate}
              btnLoading={btnLoading}
              userState={userState}
              canEdit={canEdit}
              userStateValues={userStateValues}
            />
            <StateEditBox
              subtitle="KYC Status"
              handleStateChange={handleKycChange}
              handleUpdateBtn={handleUpdateBtn}
              toUpdate={toUpdate}
              btnLoading={btnLoading}
              userState={kycStatus}
              canEdit={canEdit}
              userStateValues={kycStatusValues}
            />
          </Box>
        </Box>

        <TabContext value={selectedKey}>
          <Box className="user-details-bottom">
            <Paper
              variant="rootTransparent"
              elevation={0}
              className="user-details-top"
              style={{ height: 'fit-content' }}
            >
              <Typography variant="title">{t('common:sections')}</Typography>
              <TabList
                TabIndicatorProps={{
                  style: { display: 'none' },
                }}
                className="user-tablist"
                onChange={(e, newValue) => setSelectedKey(newValue)}
              >
                {sections.map((section, index) => (
                  <Tab
                    key={index}
                    label={
                      <Chip
                        icon={selectedKey === section.key ? section.icon : <></>}
                        label={section.name}
                        variant={selectedKey === section.key ? 'sliderSelectedPurple' : 'sliderTransparent'}
                      />
                    }
                    value={section.key}
                  ></Tab>
                ))}
              </TabList>
            </Paper>
            <Paper elevation={0}>
              <TabPanel value="userDetails" className="user-details-top">
                <Typography variant="title">{t('common:userDetails')}</Typography>

                <UserDetails actualUser={actualUser} />
              </TabPanel>
              <TabPanel value="legalDocuments" className="user-details-top">
                <Typography variant="title">{t('common:legalDocuments')}</Typography>
                <DatatableDocuments rows={actualUser?.legalDocuments ? actualUser?.legalDocuments : []} />
              </TabPanel>
              <TabPanel value="businessEntity" className="user-details-top">
                <Typography variant="title">{t('common:businessEntity')}</Typography>

                <RenderLegalEntity
                  legalEntity={actualUser.legalEntity}
                  mccDetails={actualUser.mccDetails}
                  tags={actualUser.tags}
                  date={actualUser.createdTimestamp}
                  allowedPaymentMethods={actualUser.allowedPaymentMethods}
                  acquisitionChannel={actualUser.acquisitionChannel}
                />
              </TabPanel>
              <TabPanel value="shareHolders" className="user-details-top">
                <Typography variant="title">{t('common:shareholders')}</Typography>

                <DatatableContact rows={actualUser?.shareHolders ? actualUser?.shareHolders : []} />
              </TabPanel>
              <TabPanel value="directors" className="user-details-top">
                <Typography variant="title">{t('common:directors')}</Typography>

                <DatatableContact rows={actualUser?.directors ? actualUser?.directors : []} />
              </TabPanel>
              <TabPanel value="linkedEntities" className="user-details-top">
                <Typography variant="title">{t('common:linkedEntities')}</Typography>

                <LinkedEntities linkedEntities={actualUser?.linkedEntities} />
              </TabPanel>
              <TabPanel value="transactionLimits" className="user-details-top">
                <Typography variant="title">{t('common:transactionLimits')}</Typography>

                <TransactionLimits transactionLimits={actualUser.transactionLimits} />
              </TabPanel>
              <TabPanel value="cases" className="user-details-top">
                <Typography variant="title">{t('common:cases')}</Typography>

                <DatatableUserCases userId={id} isFromDetail={true} />
              </TabPanel>
              <TabPanel value="alerts" className="user-details-top">
                <Typography variant="title">{t('common:alerts')}</Typography>

                <DatatableUserAlerts consumerUserId={actualUser.userId} />
              </TabPanel>

              <TabPanel value="transactionHistory" className="user-details-top">
                <Typography variant="title">{t('common:transactionHistory')}</Typography>

                <DatatableUserTransaction
                  id={actualUser.userId}
                  page={page}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                />
              </TabPanel>

              <TabPanel value="reports" className="user-details-top">
                <Typography variant="title">{t('common:reports')}</Typography>

                <DatatableUserReports rows={actualUser?.reports ? actualUser?.reports : []} />
              </TabPanel>

              <TabPanel value="userEvents" className="user-details-top">
                <Typography variant="title">{t('common:userEvents')}</Typography>
                <DatatableUserEvent rows={actualUser?.events} />
              </TabPanel>

              <TabPanel value="userActions" className="user-details-top">
                <Typography variant="title">{t('common:userActions')}</Typography>
                <DatatableUserActions userId={actualUser?.userId} />
              </TabPanel>
              <TabPanel value="tags" className="user-details-top">
                <Typography variant="title">{t('common:tags')}</Typography>
                <DatatableTags tags={Array.isArray(actualUser?.tags) ? actualUser.tags : []} />
              </TabPanel>
            </Paper>
          </Box>
        </TabContext>
      </Box>
    </Box>
  )
}

export default UserDetail

import { Box, InputLabel, Paper, TextField } from '@mui/material'
import '../kytManualCheck.scss'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RenderTooltip } from '../../../../components/utilities/RenderTooltip'

const IbanDetails = ({ setPaymentDetails, isReset, setIsReset }) => {
  const [BIC, setBIC] = useState('')
  const [country, setCountry] = useState('')
  const [bankName, setBankName] = useState('')
  const [IBAN, setIBAN] = useState('')
  const [name, setName] = useState('')
  const [bankBranchCode, setBankBranchCode] = useState('')
  const [paymentChannel, setPaymentChannel] = useState('')

  const { t } = useTranslation(['common', 'messages'])
  const [change, setChange] = useState(false)

  useEffect(() => {
    if (change) {
      const body = {
        method: 'IBAN',
        ...(BIC && { BIC }),
        ...(country && { country }),
        ...(bankName && { bankName }),
        ...(IBAN && { IBAN }),
        ...(name && { name }),
        ...(bankBranchCode && { bankBranchCode }),
        ...(paymentChannel && { paymentChannel }),
      }

      setPaymentDetails(body)
      setChange(false)
    }
  }, [change])

  useEffect(() => {
    if (isReset) {
      setBIC('')
      setCountry('')
      setBankName('')
      setIBAN('')
      setName('')
      setBankBranchCode('')
      setPaymentChannel('')
      setIsReset(false)
    }
  }, [isReset])

  return (
    <Paper elevation={0} className="kyt-form">
      <Box className="kyt-form-pair">
        <Box className="kyt-form-pair-label">
          <InputLabel>{t('common:BIC')}</InputLabel>
          <RenderTooltip
            title={'Identifier for the bank. Can be routing number, BIK number, SWIFT code, BIC number etc.'}
          />
        </Box>

        <TextField
          fullWidth
          type="text"
          value={BIC}
          onChange={(event) => {
            setBIC(event.target.value)
            setChange(true)
          }}
          placeholder={`${t('common:insert')} ${t('common:BIC')} ${t('here')}`}
        />
      </Box>
      <Box className="kyt-form-pair">
        <Box className="kyt-form-pair-label">
          <InputLabel>{t('common:country')}</InputLabel>
          <RenderTooltip title={'Bank Country.'} />
        </Box>
        <TextField
          fullWidth
          type="text"
          value={country}
          onChange={(event) => {
            setCountry(event.target.value)
            setChange(true)
          }}
          placeholder={`${t('common:insert')} ${t('common:country')} ${t('here')}`}
        />
      </Box>
      <Box className="kyt-form-pair">
        <Box className="kyt-form-pair-label">
          <InputLabel>{t('common:bankName')}</InputLabel>
          <RenderTooltip title={'Name of the bank.'} />
        </Box>
        <TextField
          fullWidth
          type="text"
          value={bankName}
          onChange={(event) => {
            setBankName(event.target.value)
            setChange(true)
          }}
          placeholder={`${t('common:insert')} ${t('common:bankName')} ${t('here')}`}
        />
      </Box>
      <Box className="kyt-form-pair">
        <Box className="kyt-form-pair-label">
          <InputLabel>{t('common:IBAN')}</InputLabel>
          <RenderTooltip title={'Account number of the user. Can be account number, IBAN number etc.'} />
        </Box>
        <TextField
          fullWidth
          type="text"
          value={IBAN}
          onChange={(event) => {
            setIBAN(event.target.value)
            setChange(true)
          }}
          placeholder={`${t('common:insert')} ${t('common:IBAN')} ${t('here')}`}
        />
      </Box>
      <Box className="kyt-form-pair">
        <Box className="kyt-form-pair-label">
          <InputLabel>{t('common:name')}</InputLabel>
          <RenderTooltip title={'Name of the account holder.'} />
        </Box>
        <TextField
          fullWidth
          type="text"
          value={name}
          onChange={(event) => {
            setName(event.target.value)
            setChange(true)
          }}
          placeholder={`${t('common:insert')} ${t('common:name')} ${t('here')}`}
        />
      </Box>
      <Box className="kyt-form-pair">
        <Box className="kyt-form-pair-label">
          <InputLabel>{t('common:bankBranchCode')}</InputLabel>
          <RenderTooltip
            title={'Branch code of the bank. In some countries, this can be the same as the banks SWIFT code.'}
          />
        </Box>
        <TextField
          fullWidth
          type="text"
          value={bankBranchCode}
          onChange={(event) => {
            setBankBranchCode(event.target.value)
            setChange(true)
          }}
          placeholder={`${t('common:insert')} ${t('common:bankBranchCode')} ${t('here')}`}
        />
      </Box>
      <Box className="kyt-form-pair">
        <Box className="kyt-form-pair-label">
          <InputLabel>{t('common:paymentChannel')}</InputLabel>
          <RenderTooltip title={'Payment Channel.'} />
        </Box>
        <TextField
          fullWidth
          type="text"
          value={paymentChannel}
          onChange={(event) => {
            setPaymentChannel(event.target.value)
            setChange(true)
          }}
          placeholder={`${t('common:insert')} ${t('common:paymentChannel')} ${t('here')}`}
        />
      </Box>
    </Paper>
  )
}

export default IbanDetails

import './amlCrypto.scss'
import { Box, MenuItem, Paper, Select, Typography } from '@mui/material'
import { TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DatatableAmlCrypto from '../../components/common/datatable/DatatableAmlCrypto'
import { updateAmlCryptoFilters } from '../../features/amlCrypto/amlCryptoSlice'
import { useTranslation } from 'react-i18next'
import Spinner from '../../components/common/spinner/spinner'
import MyDatePicker from '../../components/utilities/MyDatePicker'
import { FilterModal } from '../../components/utilities/FilterModal'

const AddressProtocols = [
  'btc',
  'eth',
  'xrp',
  'egld',
  'algo',
  'sol',
  'trx',
  'flow',
  'one',
  'ltc',
  'bch',
  'zec',
  'dash',
  'bsv',
  'bnb',
  'xvg',
  'btg',
  'matic',
  'avax',
  'xlm',
  'klay',
]

const AmlCrypto = () => {
  const queryState = useSelector((state) => state.transaction.filters)
  const [isLoading, setIsLoading] = useState(true)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [id, setId] = useState(queryState.id)
  const [protocol, setProtocol] = useState(queryState.protocol ?? '')
  const [address, setAddress] = useState(queryState.address)
  const [selectedDate, setSelectedDate] = useState(queryState.fromDate)
  const [toDate, setToDate] = useState(null)
  const dispatch = useDispatch()
  const { t } = useTranslation(['common'])
  const [modalOpen, setModalOpen] = useState(false)

  let isQueryOk = !id && !protocol && !selectedDate

  const handleResetButton = () => {
    if (id !== '') setId('')
    if (protocol) setProtocol('')
    if (address) setAddress('')
    if (selectedDate) setSelectedDate(null)
    setPage(0)
    setIsLoading(true)

    return dispatch(
      updateAmlCryptoFilters({
        page: 0,
        limit: 25,
        offset: 0,
        id: '',
        protocol: '',
        address: '',
        fromDate: null,
        toDate: '',
      }),
    )
  }

  const handleDispatch = () => {
    setIsLoading(true)
    return dispatch(
      updateAmlCryptoFilters({
        limit: rowsPerPage,
        offset: rowsPerPage * page,
        page,
        id,
        protocol,
        address,
        fromDate: selectedDate?.toISOString(),
        toDate: new Date(toDate)?.toISOString(),
      }),
    )
  }

  useEffect(() => {
    handleDispatch()
  }, [rowsPerPage, page])

  useEffect(() => {
    if (selectedDate) {
      const formattedDate = new Date(selectedDate)
      setToDate(selectedDate ? formattedDate.setDate(formattedDate.getDate() + 1) : null)
    }
  }, [selectedDate])

  return (
    <Box className={`${isLoading && 'spinner-transition'} filter`}>
      {isLoading && <Spinner />}

      <Box className="component-title-wrapper">
        <Box className="component-title">
          <Typography variant="h2">{t('common:amlCrypto')}</Typography>
        </Box>
        <Typography variant="subtitle3">
          {/* Loren ipsum dolor sit amet Loren ipsum dolor sit amet Loren  */}
        </Typography>
      </Box>

      <Paper elevation={0}>
        <DatatableAmlCrypto
          setIsLoading={setIsLoading}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          setModalOpen={setModalOpen}
        />
      </Paper>

      <FilterModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        handleResetButton={handleResetButton}
        setPage={setPage}
        handleDispatch={handleDispatch}
      >
        <Box className="modal-box-filters">
          <Box className="modal-box">
            <Typography variant="subtitle3">
              {t('common:by')} {t('common:searchId')}:
            </Typography>
            <TextField
              required
              fullWidth
              value={id}
              onChange={(event) => setId(event.target.value)}
              placeholder={`${t('common:insert')} ${t('common:searchId')}`}
            />
          </Box>

          <Box className="modal-box">
            <Typography variant="subtitle3">{t('common:date')}:</Typography>

            <MyDatePicker selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
          </Box>

          <Box className="modal-box">
            <Typography variant="subtitle3">
              {t('common:by')} {t('common:protocol')}:
            </Typography>
            <Select
              displayEmpty
              size="small"
              value={protocol}
              onChange={(e) => setProtocol(e.target.value)}
              options={AddressProtocols}
            >
              <MenuItem disabled value="">
                {t('common:select')} {t('common:protocol')}
              </MenuItem>
              {AddressProtocols.map((name, index) => (
                <MenuItem key={index} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </Box>

          <Box className="modal-box">
            <Typography variant="subtitle3">
              {t('common:by')} {t('common:address')}
            </Typography>
            <TextField
              size="small"
              value={address}
              onChange={(event) => setAddress(event.target.value)}
              placeholder={`${t('common:insert')} ${t('common:address')}`}
            />
          </Box>
        </Box>
      </FilterModal>
    </Box>
  )
}

export default AmlCrypto

import '../home/home.scss'
import './ecommerceRisk.scss'
import { Box, Paper, TextField, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateEcommerceRiskFilters } from '../../features/ecommerceRisk/ecommerceRiskSlice'
import Spinner from '../../components/common/spinner/spinner'
import DatatableEcommerceRisk from '../../components/common/datatable/DatatableEcommerceRisks'
import { useTranslation } from 'react-i18next'
import MyDatePicker from '../../components/utilities/MyDatePicker'
import { FilterModal } from '../../components/utilities/FilterModal'

const EcommerceRisk = () => {
  const queryState = useSelector((state) => state.ecommerceRisk.filters)
  const [isLoading, setIsLoading] = useState(true)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [checkId, setCheckId] = useState(queryState.checkId)
  const [firstName, setFirstName] = useState(queryState.firstName)
  const [lastName, setLastName] = useState(queryState.lastName)
  const [documentNumber, setDocumentNumber] = useState(queryState.documentNumber)
  const [orderId, setOrderId] = useState(queryState.orderId)
  const [selectedDate, setSelectedDate] = useState(queryState.fromDate)
  const [toDate, setToDate] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)
  const dispatch = useDispatch()
  const { t } = useTranslation(['common', 'messages'])

  let isQueryOk = !checkId && !selectedDate && !firstName && !lastName && !documentNumber && !orderId

  const handleResetButton = () => {
    if (checkId !== '') setCheckId('')
    if (firstName !== '') setFirstName('')
    if (lastName !== '') setLastName('')
    if (documentNumber !== '') setDocumentNumber('')
    if (orderId !== '') setOrderId('')
    if (selectedDate) setSelectedDate(null)
    setPage(0)
    setIsLoading(true)

    return dispatch(
      updateEcommerceRiskFilters({
        page: 0,
        limit: 25,
        offset: 0,
        checkId: '',
        firstName: '',
        lastName: '',
        documentNumber: '',
        orderId: '',
        fromDate: null,
        toDate: '',
      }),
    )
  }

  const handleDispatch = () => {
    setIsLoading(true)
    return dispatch(
      updateEcommerceRiskFilters({
        limit: rowsPerPage,
        offset: rowsPerPage * page,
        page,
        checkId,
        firstName,
        lastName,
        documentNumber,
        orderId,
        fromDate: selectedDate?.toISOString(),
        toDate: new Date(toDate)?.toISOString(),
      }),
    )
  }

  useEffect(() => {
    handleDispatch()
  }, [rowsPerPage, page])

  useEffect(() => {
    if (selectedDate) {
      const formattedDate = new Date(selectedDate)
      setToDate(selectedDate ? formattedDate.setDate(formattedDate.getDate() + 1) : null)
    }
  }, [selectedDate])

  return (
    <Box>
      <Box className={`${isLoading && 'spinner-transition'} filter`}>
        {isLoading && <Spinner />}

        <Box className="component-title-wrapper">
          <Box className="component-title">
            <Typography variant="h2">{t('common:ecommerceRisk')}</Typography>
          </Box>
          <Typography variant="subtitle3">
            {/* Loren ipsum dolor sit amet Loren ipsum dolor sit amet Loren  */}
          </Typography>
        </Box>

        <Paper elevation={0}>
          <DatatableEcommerceRisk
            setIsLoading={setIsLoading}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            setModalOpen={setModalOpen}
          />
        </Paper>
      </Box>

      <FilterModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        handleResetButton={handleResetButton}
        setPage={setPage}
        handleDispatch={handleDispatch}
      >
        <Box className="modal-box-filters">
          <Box className="modal-box">
            <Typography variant="subtitle3">
              {t('common:by')} {t('common:date')}:
            </Typography>

            <MyDatePicker selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
          </Box>

          <Box className="modal-box">
            <Typography variant="subtitle3">{t('common:by')} ID:</Typography>

            <TextField
              variant="outlined"
              size="small"
              value={checkId}
              onChange={(event) => setCheckId(event.target.value)}
              placeholder={`${t('common:insert')} ID`}
            />
          </Box>

          <Box className="modal-box">
            <Typography variant="subtitle3">
              {t('common:by')} {t('common:firstName')}:
            </Typography>

            <TextField
              variant="outlined"
              size="small"
              value={firstName}
              onChange={(event) => setFirstName(event.target.value)}
              placeholder={`${t('common:insert')} ${t('common:firstName')}`}
            />
          </Box>

          <Box className="modal-box">
            <Typography variant="subtitle3">
              {t('common:by')} {t('common:lastName')}:
            </Typography>

            <TextField
              variant="outlined"
              size="small"
              value={lastName}
              onChange={(event) => setLastName(event.target.value)}
              placeholder={`${t('common:insert')} ${t('common:lastName')}`}
            />
          </Box>

          <Box className="modal-box">
            <Typography variant="subtitle3">
              {t('common:by')} {t('common:documentNumber')}:
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              value={documentNumber}
              onChange={(event) => setDocumentNumber(event.target.value)}
              placeholder={`${t('common:insert')} ${t('common:documentNumber')}`}
            />

            <Box className="modal-box">
              <Typography variant="subtitle3">
                {t('common:by')} {t('common:order')} ID:
              </Typography>
              <TextField
                variant="outlined"
                size="small"
                value={orderId}
                onChange={(event) => setOrderId(event.target.value)}
                placeholder={`${t('common:insert')} ${t('common:order')} ID`}
              />
            </Box>
          </Box>
        </Box>
      </FilterModal>
    </Box>
  )
}

export default EcommerceRisk

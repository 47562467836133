import './diffComponent.scss'
import { Box, Button, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary } from '../common/Accordion/Accordion'
import { buildTimeStringFromTimestamp } from '../common/time/timeHelper'
import { useTranslation } from 'react-i18next'
import html2pdf from 'html2pdf.js'
import { useSelector } from 'react-redux'
const deepEqual = require('deep-equal')

// Clean keys
function deleteUnwantedKeys(obj, reportType) {
  const unwantedKeys = [
    '__v',
    '_id',
    reportType !== 'abm_users' ? 'email' : '',
    'password',
    'hasApikey',
    'deleted',
    'passwordLastChanged',
    'previousPasswords',
    'emailClient',
    'createdAt',
    'updatedAt',
    'createdUser',
    'updatedUser',
    'apiKey',
    'globalPrexi',
    'ruleTemplate',
    'filterTemplate',
  ]

  const cleanedObject = Object.assign({}, obj)
  for (const key of unwantedKeys) {
    delete cleanedObject[key]
  }

  return cleanedObject
}

// Deep object/arr differenciation
function deepObjectDiff(previous, newImage) {
  const diff = []

  function deepArrayEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) return false

    const sortedArr1 = JSON.stringify(arr1.slice().sort())
    const sortedArr2 = JSON.stringify(arr2.slice().sort())

    return sortedArr1 === sortedArr2
  }

  function compareSubServices(prevSubServices, newSubServices) {
    prevSubServices.forEach((prevSubService) => {
      const newSubService = newSubServices.find((subService) => subService.name === prevSubService.name)

      if (!newSubService) {
        diff.push({
          key: 'subService',
          action: 'deleted',
          data: prevSubService,
        })
      }
    })

    newSubServices.forEach((newSubService) => {
      if (!prevSubServices.some((subService) => subService.name === newSubService.name)) {
        diff.push({
          key: 'subService',
          action: 'added',
          data: newSubService,
        })
      }
    })
  }

  function compareServicesAndSubservices(prevServices, newServices) {
    prevServices.forEach((prevService) => {
      const newService = newServices.find((service) => service.name === prevService.name)

      if (!newService) {
        diff.push({
          key: 'services',
          action: 'deleted',
          data: prevService,
        })
      } else {
        const isServiceUpdated =
          prevService.status !== newService.status || !deepArrayEqual(prevService.subServices, newService.subServices)

        if (isServiceUpdated) {
          diff.push({
            key: 'services',
            action: 'updated',
            data: {
              name: newService.name,
              status: newService.status,
            },
          })
        }

        compareSubServices(prevService.subServices, newService.subServices)
      }
    })

    newServices.forEach((newService) => {
      if (!prevServices.some((service) => service.name === newService.name)) {
        diff.push({
          key: 'services',
          action: 'added',
          data: newService,
        })
      }
    })
  }

  function isObject(val) {
    return val != null && typeof val === 'object' && !Array.isArray(val)
  }

  for (const key in previous) {
    if (!newImage?.hasOwnProperty(key)) {
      diff.push({
        key: key,
        action: 'deleted',
        data: previous[key],
      })
    } else if (Array.isArray(previous[key]) && Array.isArray(newImage[key])) {
      if (key === 'services') {
        // If object have services and subservices (e.g. abm_roles)
        compareServicesAndSubservices(previous[key], newImage[key])
      } else {
        if (!deepEqual(previous[key], newImage[key], { strict: true })) {
          diff.push({
            key: key,
            action: 'updated',
            data: newImage[key],
          })
        }
      }
    } else if (isObject(previous[key]) && isObject(newImage[key])) {
      const nestedDiff = deepObjectDiff(previous[key], newImage[key])
      if (Object.keys(nestedDiff).length > 0) {
        diff.push({
          key: key,
          action: 'updated',
          data: nestedDiff,
        })
      }
    } else if (!deepEqual(previous[key], newImage[key])) {
      diff.push({
        key: key,
        action: 'updated',
        data: `${previous[key]} --> ${newImage[key]}`,
      })
    }
  }

  for (const key in newImage) {
    if (!previous?.hasOwnProperty(key)) {
      diff.push({
        key: key,
        action: 'added',
        data: newImage[key],
      })
    }
  }

  return groupedData(diff)
}

// Function to group information by added, updated and deleted
function groupedData(obj) {
  const groupedData = {}
  obj.forEach((item) => {
    const action = item.action
    if (!groupedData[action]) {
      groupedData[action] = []
    }
    groupedData[action].push(item)
  })

  return groupedData
}

function DiffComponent({ previousData, newData, reportKey, info }) {
  const { email } = useSelector((state) => state.login)
  const [expanded, setExpanded] = useState([false, false])
  const [userId, setUserId] = useState('')
  const [ruleId, setRuleId] = useState('')
  const [ruleInstanceId, setRuleInstanceId] = useState('')
  const { t } = useTranslation(['common'])

  const handleChange = (position) => {
    let aux = [...expanded]
    aux[position] = !aux[position]
    setExpanded([...aux])
  }

  // Delete unwanted keys
  const previous = deleteUnwantedKeys(previousData, reportKey)
  const newImage = deleteUnwantedKeys(newData, reportKey)

  // Get differences between objects
  const diffResults = deepObjectDiff(previous, newImage)

  const getColorByAction = (action) => {
    switch (action) {
      case 'added':
        return 'green'
      case 'updated':
        return 'orange'
      case 'deleted':
        return 'red'
      default:
        return 'black'
    }
  }

  // Render data
  const renderData = (data) => {
    if (Array.isArray(data)) {
      return (
        <ul style={{ paddingInlineStart: '0px' }}>
          {data.map((item, idx) => {
            return <Box key={idx}>{renderData(item)}</Box>
          })}
        </ul>
      )
    } else if (typeof data === 'object' && data !== null) {
      return (
        <ul>
          {Object.entries(data).map(([key, value], idx) => {
            return (
              <li key={idx} style={{ listStyle: 'circle' }}>
                <strong>{key}:</strong> {renderData(value)}
              </li>
            )
          })}
        </ul>
      )
    } else {
      return <span>{data.toString()}</span>
    }
  }

  const generatePDF = () => {
    const element = document.getElementById('contentToConvertReport') // ID of the HTML element
    const options = {
      margin: [20, 15],
      filename: `${reportKey}_${new Date().getTime()}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2, letterRendering: true },
      jsPDF: { unit: 'pt', format: 'a4', orientation: 'landscape' },
      pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
    }
    const today = new Date()
    html2pdf()
      .set(options)
      .from(element)
      .toPdf()
      .get('pdf')
      .then((pdf) => {
        let totalPages = pdf.internal.getNumberOfPages()

        for (let i = 1; i <= totalPages; i++) {
          // set footer to every page
          pdf.setPage(i)
          // set footer font
          pdf.setFontSize(10)
          pdf.setTextColor(150)
          // this example gets internal pageSize just as an example to locate your text near the borders in case you want to do something like "Page 3 out of 4"
          if (i === 1) {
            pdf.text(
              // pdf.internal.pageSize.getWidth() - 580,
              pdf.internal.pageSize.getWidth() - 825,
              // pdf.internal.pageSize.getHeight() - 825,
              pdf.internal.pageSize.getHeight() - 580,
              `Güeno - PDF Generated on ${buildTimeStringFromTimestamp(today)} by ${email} - ${today.getTime()}`,
            )
          }
          pdf.text(
            pdf.internal.pageSize.getWidth() - 60,
            pdf.internal.pageSize.getHeight() - 10,
            `Page ${i} of ${totalPages}`,
          )
        }
      })
      .save()
  }

  useEffect(() => {
    if (previousData?.userId || newData?.userId) {
      setUserId(previousData?.userId ?? newData?.userId)
    }
    if (previousData?.ruleId || newData?.ruleId) {
      setRuleId(previousData?.ruleId ?? newData?.ruleId)
    }
    if (previousData?.instanceId || newData?.instanceId) {
      setRuleInstanceId(previousData?.instanceId ?? newData?.instanceId)
    }
  }, [previousData, newData])

  return (
    <Box>
      <Button onClick={() => generatePDF()} variant="contained" size="small" color="primary">
        {t('common:exportToPdf')}
      </Button>
      <Box id="contentToConvertReport">
        {info && (
          <Table className="table">
            <TableHead>
              <TableRow>
                <TableCell>
                  {t('common:action')} {t('common:owner')}
                </TableCell>
                <TableCell>{t('common:action')}</TableCell>
                <TableCell>{t('common:status')}</TableCell>
                <TableCell>{t('common:date')}</TableCell>
                {userId !== '' && <TableCell>{t('common:userId')}</TableCell>}
                {ruleId !== '' && <TableCell>{t('common:ruleId')}</TableCell>}
                {ruleId !== '' && <TableCell>{t('common:ruleInstanceId')}</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{info.email}</TableCell>
                <TableCell>{info.action}</TableCell>
                <TableCell>{info.result}</TableCell>
                <TableCell>{buildTimeStringFromTimestamp(info.date)}</TableCell>
                {userId !== '' && <TableCell>{userId}</TableCell>}
                {ruleId !== '' && <TableCell>{ruleId}</TableCell>}
                {ruleInstanceId !== '' && <TableCell>{ruleInstanceId}</TableCell>}
              </TableRow>
            </TableBody>
          </Table>
        )}
        <Box className="diff-grid">
          <Box className="diff-container">
            <Box className="diff-title">Modifications</Box>
            {/* UPDATED ITEMS */}
            {diffResults && diffResults?.updated?.length && (
              <Box>
                <p className="diff-title-item">Updated:</p>
                {diffResults?.updated?.map((diffResult, index) => (
                  <Box key={index}>
                    <li key={index} style={{ color: getColorByAction(diffResult.action), marginLeft: '1.5rem' }}>
                      <strong>{diffResult.key}: </strong>
                      {diffResult.data && renderData(diffResult.data)}
                    </li>
                  </Box>
                ))}
              </Box>
            )}

            {/* DELETED ITEMS */}
            {diffResults && diffResults?.deleted?.length && (
              <Box>
                <p className="diff-title-item">Deleted:</p>
                {diffResults?.deleted?.map((diffResult, index) => (
                  <Box key={index}>
                    <li key={index} style={{ color: getColorByAction(diffResult.action), marginLeft: '1.5rem' }}>
                      <strong>{diffResult.key}: </strong>
                      {diffResult.data && renderData(diffResult.data, true)}
                    </li>
                  </Box>
                ))}
              </Box>
            )}

            {/* ADDED ITEMS */}
            {diffResults && diffResults?.added?.length && (
              <Box>
                <p className="diff-title-item">Added:</p>
                {diffResults?.added?.map((diffResult, index) => (
                  <Box key={index}>
                    <li key={index} style={{ color: getColorByAction(diffResult.action), marginLeft: '1.5rem' }}>
                      <strong>{diffResult.key}: </strong>
                      {diffResult.data && renderData(diffResult.data)}
                    </li>
                  </Box>
                ))}
              </Box>
            )}
          </Box>
          <Box>
            <Accordion className="diff-accordion" expanded={expanded[0]} onChange={() => handleChange(0)}>
              <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                <Typography>Previous Image</Typography>
              </AccordionSummary>
              <AccordionDetails className="ud">
                {Object.keys(previous).length ? renderData(previous) : 'No previous data'}
              </AccordionDetails>
            </Accordion>
            <Accordion className="diff-accordion" expanded={expanded[1]} onChange={() => handleChange(1)}>
              <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                <Typography>New Image</Typography>
              </AccordionSummary>
              <AccordionDetails className="ud">
                {Object.keys(newImage).length ? renderData(newImage) : 'No new data'}
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default DiffComponent

import './kytManualCheck.scss'

import { Box, Button, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import TagsManualCheck from './tagsManualCheck'
import { countryDictionary } from '../../../components/common/flag/countries'
import ProcessingSpinner from '../../../components/common/alertFetchSpinner/processingSpinner'
import { RenderTooltip } from '../../../components/utilities/RenderTooltip'

const riskLevels = ['VERY_HIGH', 'HIGH', 'MEDIUM', 'LOW', 'VERY_LOW']
const acquisitionChannels = ['ORGANIC', 'PAID', 'REFERRAL', 'OFFLINE', 'GATHERING', 'UNKNOWN']
const userSegments = ['SOLE_PROPRIETORSHIP', 'LIMITED', 'SMB', 'SMALL', 'MEDIUM', 'LARGE', 'UNKNOWN']
const userStateDetailsStates = ['UNACCEPTABLE', 'TERMINATED', 'ACTIVE', 'DORMANT', 'CREATED', 'SUSPENDED', 'BLOCKED']
const kycStatusDetailsStatusEnum = ['SUCCESSFUL', 'FAILED', 'NOT_STARTED', 'IN_PROGRESS', 'MANUAL_REVIEW']

const BusinessManualCheck = () => {
  const { accessToken } = useSelector((state) => state.login)
  const [isFetching, setIsFetching] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const navigate = useNavigate()
  const [finish, setFinish] = useState(false)
  const [result, setResult] = useState({})
  const { t } = useTranslation(['common', 'messages'])
  //atributos:
  const [userId, setUserId] = useState('')
  const [legalName, setLegalName] = useState('')
  const [createdTimestamp, setCreatedTimestamp] = useState('')
  const [userSegment, setUserSegment] = useState('')
  const [registrationIdentifier, setRegistrationIdentifier] = useState('')
  const [registrationCountry, setRegistrationCountry] = useState('')
  const [registrationCountryView, setRegistrationCountryView] = useState('')
  const [taxIdentifier, setTaxIdentifier] = useState('')
  const [riskLevel, setRiskLevel] = useState('')
  const [userStateDetailsState, setUserStateDetailsState] = useState('')
  const [kycStatusDetailsStatus, setKycStatusDetailsStatus] = useState('')
  const [acquisitionChannel, setAcquisitionChannel] = useState('')
  const [tags, setTags] = useState([])
  const [isReset, setIsReset] = useState(false)

  const handleSubmit = () => {
    setIsFetching(true)
    setFinish(false)
    setErrorMessage('')

    if (!process.env.REACT_APP_IS_DEMO) {
      const body = {
        userId,
        legalEntity: {
          companyGeneralDetails: {
            legalName,
            ...(userSegment && { userSegment }),
          },
          companyRegistrationDetails: {
            ...(registrationIdentifier && { registrationIdentifier }),
            ...(registrationCountry && { registrationCountry }),
            ...(taxIdentifier && { taxIdentifier }),
          },
        },
        createdTimestamp,
        ...(userStateDetailsState && { userStateDetails: { state: userStateDetailsState } }),
        ...(kycStatusDetailsStatus && { kycStatusDetails: { status: kycStatusDetailsStatus } }),
        ...(riskLevel && { riskLevel }),
        ...(acquisitionChannel && { acquisitionChannel }),
        ...(tags && { tags }),
      }

      let url = `${process.env.REACT_APP_BASEURL}/kyt/createBusinessUser`
      const options = {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      }
      fetch(url, options)
        .then((res) => res.json())
        .then((res) => {
          setIsFetching(false)
          if (res.success && res.data) {
            setResult(res.data)
            setFinish(true)
          } else {
            throw new Error(
              res.data?.responseBody?.message
                ? res.data?.responseBody?.message
                : res.data?.info
                ? res.data?.info
                : typeof res.data === 'string'
                ? res.data
                : res.message
                ? res.message
                : 'Unexpected',
            )
          }
        })
        .catch((err) => {
          console.error('[DOWNLOAD REPORT ERROR] --> ', err)
          setIsFetching(false)
          setErrorMessage(err.message ? err.message : t('common:unexpectedErrorValues'))
        })
    } else {
      setIsFetching(false)
      setResult({
        userId: '650db405662c0902c8981bb8',
        hitRules: [],
        executedRules: [],
      })
      setFinish(true)
    }
  }

  const handleReset = () => {
    setUserId('')
    setLegalName('')
    setCreatedTimestamp('')
    setUserSegment('')
    setRegistrationIdentifier('')
    setRegistrationCountry('')
    setRegistrationCountryView('')
    setTaxIdentifier('')
    setRiskLevel('')
    setUserStateDetailsState('')
    setKycStatusDetailsStatus('')
    setAcquisitionChannel('')
    setTags([])
    setIsReset(true)
  }

  const getKeyByValue = (value) => {
    for (const key in countryDictionary) {
      if (countryDictionary[key] === value) {
        return key
      }
    }
    return null
  }

  return (
    <Box className="kyt-manual">
      <Box className="kyt-form kyt-manual-overflow">
        <Box className="kyt-form-pair">
          <Box className="kyt-form-pair-label">
            <InputLabel>{t('common:userId')}</InputLabel>
            <Typography variant="subtitle3">({t('common:required')})</Typography>
            <RenderTooltip title={'Unique user ID.'} />
          </Box>

          <TextField
            fullWidth
            size="small"
            value={userId}
            onChange={(event) => setUserId(event.target.value)}
            placeholder={`${t('common:insert')} ${t('common:userId')} ${t('here')}`}
          />
        </Box>
        <Box className="kyt-form-pair">
          <Box className="kyt-form-pair-label">
            <InputLabel>{t('common:createdTimestamp')}</InputLabel>
            <Typography variant="subtitle3">({t('common:required')})</Typography>
            <RenderTooltip title={'Timestamp when userId is created >= 1262300400000.'} />
          </Box>

          <TextField
            fullWidth
            type="number"
            placeholder={`${t('common:insert')} ${t('common:createdTimestamp')} ${t('here')}`}
            size="small"
            value={createdTimestamp}
            onChange={(event) => setCreatedTimestamp(event.target.value !== '' ? parseInt(event.target.value) : '')}
          />
        </Box>
        <Box className="kyt-form-pair">
          <Box className="kyt-form-pair-label">
            <InputLabel>{t('common:legalName')}</InputLabel>
            <Typography variant="subtitle3">({t('common:required')})</Typography>
            <RenderTooltip title={'Legal name of the user.'} />
          </Box>

          <TextField
            fullWidth
            size="small"
            value={legalName}
            onChange={(event) => setLegalName(event.target.value)}
            placeholder={`${t('common:insert')} ${t('common:legalName')} ${t('here')}`}
          />
        </Box>
        <Box className="kyt-form-pair">
          <Box className="kyt-form-pair-label">
            <InputLabel>{t('common:registrationIdentifier')}</InputLabel>
            <Typography variant="subtitle3">({t('common:required')})</Typography>
            <RenderTooltip title={'Tax ID number of the registered entity.'} />
          </Box>

          <TextField
            fullWidth
            size="small"
            value={registrationIdentifier}
            onChange={(event) => setRegistrationIdentifier(event.target.value)}
            placeholder={`${t('common:insert')} ${t('common:registrationIdentifier')} ${t('here')}`}
          />
        </Box>
        <Box className="kyt-form-pair">
          <Box className="kyt-form-pair-label">
            <InputLabel>{t('common:registrationCountry')}</InputLabel>
            <Typography variant="subtitle3">({t('common:required')})</Typography>
            <RenderTooltip title={'Tax ID number of the registered entity.'} />
          </Box>

          <Select
            size="small"
            fullWidth
            value={registrationCountryView}
            onChange={(e) => {
              if (e.target.value) {
                setRegistrationCountry(getKeyByValue(e.target.value))
                setRegistrationCountryView(e.target.value)
              } else {
                setRegistrationCountry(null)
                setRegistrationCountryView(null)
              }
            }}
            displayEmpty
          >
            <MenuItem disabled value="">
              <Typography variant="subtitle3">
                {t('common:select')} {t('common:registrationCountry')}
              </Typography>
            </MenuItem>
            {Object.values(countryDictionary).map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box className="kyt-form-pair">
          <Box className="kyt-form-pair-label">
            <InputLabel>{t('common:taxIdentifier')}</InputLabel>
            <RenderTooltip title={'Tax ID number of the registered entity.'} />
          </Box>

          <TextField
            fullWidth
            size="small"
            value={taxIdentifier}
            onChange={(event) => setTaxIdentifier(event.target.value)}
            placeholder={`${t('common:insert')} ${t('common:taxIdentifier')} ${t('here')}`}
          />
        </Box>
        <Box className="kyt-form-pair">
          <Box className="kyt-form-pair-label">
            <InputLabel>{t('common:userStateDetailsState')}</InputLabel>
            <RenderTooltip title={'User State.'} />
          </Box>

          <Select
            size="small"
            fullWidth
            value={userStateDetailsState}
            onChange={(e) => setUserStateDetailsState(e.target.value)}
            displayEmpty
          >
            <MenuItem disabled value="">
              <Typography variant="subtitle3">
                {t('common:select')} {t('common:userState')}
              </Typography>
            </MenuItem>
            {userStateDetailsStates.map((item) => (
              <MenuItem key={item} value={item}>
                {t(`common:${item}`)}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box className="kyt-form-pair">
          <Box className="kyt-form-pair-label">
            <InputLabel>{t('common:kycStatusDetailsStatus')}</InputLabel>
            <RenderTooltip title={'User KYC Status.'} />
          </Box>
          <Select
            size="small"
            fullWidth
            value={kycStatusDetailsStatus}
            onChange={(e) => setKycStatusDetailsStatus(e.target.value)}
            displayEmpty
            options={kycStatusDetailsStatusEnum}
          >
            <MenuItem disabled value="">
              <Typography variant="subtitle3">
                {t('common:select')} {t('common:user')} KYC {t('common:status')}
              </Typography>
            </MenuItem>
            {kycStatusDetailsStatusEnum.map((item) => (
              <MenuItem key={item} value={item}>
                {t(`common:${item}`)}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box className="kyt-form-pair">
          <Box className="kyt-form-pair-label">
            <InputLabel>{t('common:riskLevel')}</InputLabel>
            <RenderTooltip title={'Risk Level of the User.'} />
          </Box>
          <Select
            size="small"
            fullWidth
            value={riskLevel}
            onChange={(e) => setRiskLevel(e.target.value)}
            displayEmpty
          >
            <MenuItem disabled value="">
              <Typography variant="subtitle3">
                {t('common:select')} {t('common:riskLevel')}
              </Typography>
            </MenuItem>
            {riskLevels.map((item) => (
              <MenuItem key={item} value={item}>
                {t(`common:${item}`)}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box className="kyt-form-pair">
          <Box className="kyt-form-pair-label">
            <InputLabel>{t('common:acquisitionChannel')}</InputLabel>
            <RenderTooltip title={'Acquisition Channel of the User.'} />
          </Box>

          <Select
            size="small"
            fullWidth
            value={acquisitionChannel}
            onChange={(e) => setAcquisitionChannel(e.target.value)}
            displayEmpty
          >
            <MenuItem disabled value="">
              <Typography variant="subtitle3">
                {t('common:select')} {t('common:acquisitionChannel')}
              </Typography>
            </MenuItem>
            {acquisitionChannels.map((item) => (
              <MenuItem key={item} value={item}>
                {t(`common:${item}`)}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box className="kyt-form-pair">
          <Box className="kyt-form-pair-label">
            <InputLabel>{t('common:tags')}</InputLabel>
            <RenderTooltip title={'Additional information that can be added via tags.'} />
          </Box>

          <TagsManualCheck setRuleParameters={setTags} isReset={isReset} setIsReset={setIsReset} />
        </Box>
        <Box className="kyt-form-pair">
          <Box className="kyt-form-pair-label">
            <InputLabel>{t('common:userSegment')}</InputLabel>
            <RenderTooltip title={'Segment of the User.'} />
          </Box>

          <Select
            size="small"
            fullWidth
            value={userSegment}
            onChange={(e) => setUserSegment(e.target.value)}
            displayEmpty
          >
            <MenuItem disabled value="">
              <Typography variant="subtitle3">
                {t('common:select')} {t('common:userSegment')}
              </Typography>
            </MenuItem>
            {userSegments.map((item) => (
              <MenuItem key={item} value={item}>
                {t(`common:${item}`)}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>

      <Box className="modal-filter-buttons">
        <Button
          onClick={() => {
            handleReset()
          }}
          size="small"
          variant="outlinedGrey"
        >
          {t('common:reset')}
        </Button>
        <Button
          size="small"
          variant="contained"
          type="submit"
          disabled={
            userId === '' ||
            legalName === '' ||
            createdTimestamp === '' ||
            registrationIdentifier === '' ||
            registrationCountry === ''
          }
          onClick={handleSubmit}
        >
          {t('common:create')}
        </Button>
      </Box>
      {isFetching && <ProcessingSpinner message={t('common:processing')} />}
      {errorMessage !== '' && (
        <Box className="security-error">
          <Button size="small" variant="outlined" color="error">
            {errorMessage}
          </Button>
        </Box>
      )}

      {result && finish && errorMessage === '' && (
        <Box>
          <Typography variant="title">{t('common:result')}:</Typography>

          <Box className="sub-item">
            <InputLabel>{t('common:referenceId')}</InputLabel>
            <p className="id-action" onClick={() => navigate(`/users/${result.userId}?type=business`)}>
              {result.userId ? result.userId : '---'}
            </p>
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default BusinessManualCheck

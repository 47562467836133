import './ruleAdvancedSettings.scss'
import {
  Box,
  Divider,
  RadioGroup,
  FormControl,
  Radio,
  Select,
  MenuItem,
  OutlinedInput,
  TextField,
  FormControlLabel,
  Button,
  Typography,
  InputLabel,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const userStatusChangeReason = [
  'Fake document',
  'Blurry document',
  'Suspected fraud',
  'Adverse media',
  'Pep',
  'Sanctions hit',
  'Risky profile',
  'Other',
]

const userKYCStatus = ['SUCCESSFUL', 'FAILED', 'NOT_STARTED', 'IN_PROGRESS', 'MANUAL_REVIEW']
const userKYCStatusChangeReason = [
  'Fake document',
  'Blurry document',
  'Suspected fraud',
  'Adverse media',
  'Pep',
  'Sanctions hit',
  'Risky profile',
  'Other',
]

const userStatus = ['UNACCEPTABLE', 'TERMINATED', 'ACTIVE', 'DORMANT', 'CREATED', 'SUSPENDED', 'BLOCKED']

const txState = ['CREATED', 'PROCESSING', 'SENT', 'EXPIRED', 'DECLINED', 'SUSPENDED', 'REFUNDED', 'SUCCESSFUL']

const RulesAdvanceSettings = ({ isEditing, ras, setRas }) => {
  const { t } = useTranslation(['common'])
  const [change, setChange] = useState(false)
  const [txStateUpdateState, setTxStateUpdateState] = useState('')
  const [txStateUpdateDesc, setTxStateUpdateDesc] = useState('')
  const [usersToUpdate, setUsersToUpdate] = useState('')
  const [userStatusUpdateStatus, setUserStatusUpdateStatus] = useState('')
  const [userStatusUpdateReason, setUserStatusUpdateReason] = useState('')
  const [userStatusUpdateDesc, setUserStatusUpdateDesc] = useState('')
  const [userKYCStatusUpdateStatus, setUserKYCStatusUpdateStatus] = useState('')
  const [userKYCStatusUpdateReason, setUserKYCStatusUpdateReason] = useState('')
  const [userKYCStatusUpdateDesc, setUserKYCStatusUpdateDesc] = useState('')

  useEffect(() => {
    if (ras.txStateUpdate?.updateTo) setTxStateUpdateState(ras.txStateUpdate?.updateTo)
    if (ras.txStateUpdate?.description) setTxStateUpdateDesc(ras.txStateUpdate?.description)
    if (ras.usersToUpdate) setUsersToUpdate(ras.usersToUpdate)
    if (ras.userStatusUpdate?.updateTo) setUserStatusUpdateStatus(ras.userStatusUpdate?.updateTo)
    if (ras.userStatusUpdate?.reason) setUserStatusUpdateReason(ras.userStatusUpdate?.reason)
    if (ras.userStatusUpdate?.description) setUserStatusUpdateDesc(ras.userStatusUpdate?.description)
    if (ras.userKYCStatusUpdate?.updateTo) setUserKYCStatusUpdateStatus(ras.userKYCStatusUpdate?.updateTo)
    if (ras.userKYCStatusUpdate?.reason) setUserKYCStatusUpdateReason(ras.userKYCStatusUpdate?.reason)
    if (ras.userKYCStatusUpdate?.description) setUserKYCStatusUpdateDesc(ras.userKYCStatusUpdate?.description)
  }, [ras])

  const handleTxClear = () => {
    setTxStateUpdateState('')
    setTxStateUpdateDesc('')
    setChange(true)
  }

  const handleUserClear = () => {
    setUsersToUpdate('')
    setUserStatusUpdateStatus('')
    setUserStatusUpdateReason('')
    setUserStatusUpdateDesc('')
    setUserKYCStatusUpdateStatus('')
    setUserKYCStatusUpdateReason('')
    setUserKYCStatusUpdateDesc('')
    setChange(true)
  }

  useEffect(() => {
    if (change) {
      let all = {
        ...ras,
        txStateUpdate: { updateTo: setTxStateUpdateState, description: setTxStateUpdateDesc },
        usersToUpdate,
        userStatusUpdate: {
          updateTo: userStatusUpdateStatus,
          reason: userStatusUpdateReason,
          description: userStatusUpdateDesc,
        },
        userKYCStatusUpdate: {},
      }

      if (txStateUpdateState === '' && txStateUpdateDesc === '') {
        delete all.txStateUpdate
      } else {
        all['txStateUpdate'] = { updateTo: txStateUpdateState, description: txStateUpdateDesc }
      }

      if (usersToUpdate === '') delete all.usersToUpdate
      else all['usersToUpdate'] = usersToUpdate

      if (userStatusUpdateStatus === '' && userStatusUpdateReason === '' && userStatusUpdateDesc === '') {
        delete all.userStatusUpdate
      } else {
        all['userStatusUpdate'] = {
          updateTo: userStatusUpdateStatus,
          reason: userStatusUpdateReason,
          description: userStatusUpdateDesc,
        }
      }

      if (userKYCStatusUpdateStatus === '' && userKYCStatusUpdateReason === '' && userKYCStatusUpdateDesc === '') {
        delete all.userKYCStatusUpdate
      } else {
        all['userKYCStatusUpdate'] = {
          updateTo: userKYCStatusUpdateStatus,
          reason: userKYCStatusUpdateReason,
          description: userKYCStatusUpdateDesc,
        }
      }

      all.updated = true

      setRas({ ...all })
      setChange(false)
    }
  }, [change])

  return (
    <Box>
      <Divider style={{ margin: '.6rem 0rem 1.6rem 0rem' }} />

      <Box className="ras-box">
        <Box className="ras-pair">
          <Typography variant="title3">{t('common:transactionDetails')}</Typography>

          <Button variant="contained" disabled={!isEditing} onClick={() => handleTxClear()}>
            {t('common:clearAll')}
          </Button>
        </Box>
        <Box className="ras-box">
          <InputLabel>Update transaction state to</InputLabel>
          <Select
            className="ras-box-select"
            size="small"
            disabled={!isEditing}
            value={txStateUpdateState}
            onChange={(event) => {
              setTxStateUpdateState(event.target.value)
              setChange(true)
            }}
            input={<OutlinedInput />}
            inputProps={{ 'aria-label': 'Without label' }}
          >
            {txState?.map((name) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box className="ras-box">
          <InputLabel>Description</InputLabel>
          <TextField
            multiline
            size="small"
            id="description"
            disabled={!isEditing}
            variant="outlined"
            type="text"
            value={txStateUpdateDesc}
            onChange={(event) => {
              setTxStateUpdateDesc(() => event.target.value)
              setChange(true)
            }}
          />
        </Box>
      </Box>

      <Divider style={{ margin: '1rem 0rem' }} />

      <Box>
        <Box className="ras-pair">
          <Typography variant="title3">{t('common:userDetails')}</Typography>
          <Button color="secondary" variant="contained" disabled={!isEditing} onClick={() => handleUserClear()}>
            {t('common:clearAll')}
          </Button>
        </Box>
        <Box className="ras-box">
          <Box className="block-title">
            <InputLabel>{t('common:usersToUpdate')}</InputLabel>

            {!ras?.usersToUpdate && (ras?.userStatusUpdate?.updateTo || ras?.userKYCStatusUpdate?.updateTo) && (
              <span className="mandatory">*</span>
            )}
          </Box>
          <Typography variant="subtitle4">
            Select users of a transaction direction below for which the user/KYC status needs to be updated.
          </Typography>

          <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
            <Box className="radio-block">
              <FormControlLabel
                disabled={!isEditing}
                checked={usersToUpdate === 'ALL' ? true : false}
                value="ALL"
                control={<Radio />}
                label={<Box style={{ color: 'var(--flag)', fontWeight: '500' }}>All</Box>}
                onChange={() => {
                  setUsersToUpdate('ALL')
                  setChange(true)
                }}
              />
            </Box>
            <Box className="radio-block">
              <FormControlLabel
                disabled={!isEditing}
                checked={usersToUpdate === 'ORIGIN' ? true : false}
                value="ORIGIN"
                control={<Radio />}
                label={<Box style={{ color: 'var(--flag)', fontWeight: '500' }}>Origin</Box>}
                onChange={() => {
                  setUsersToUpdate('ORIGIN')
                  setChange(true)
                }}
              />
            </Box>
            <Box className="radio-block">
              <FormControlLabel
                disabled={!isEditing}
                checked={usersToUpdate === 'DESTINATION' ? true : false}
                value="DESTINATION"
                control={<Radio />}
                label={<Box style={{ color: 'var(--flag)', fontWeight: '500' }}>Destination</Box>}
                onChange={() => {
                  setUsersToUpdate('DESTINATION')
                  setChange(true)
                }}
              />
            </Box>
          </RadioGroup>
        </Box>
      </Box>

      <Divider style={{ margin: '1rem 0rem' }} />

      <Box className="threshold-container">
        <Box className="threshold-row">
          <Box className="ras-box">
            <Typography variant="title">
              Update user status to
              {ras?.usersToUpdate && !ras?.userStatusUpdate?.updateTo && !ras?.userKYCStatusUpdate?.updateTo && (
                <span className="mandatory">*</span>
              )}
            </Typography>

            <Select
              className="currency"
              size="small"
              disabled={!isEditing}
              renderValue={() => {
                return userStatusUpdateStatus
              }}
              value={userStatusUpdateStatus}
              onChange={(event) => {
                setUserStatusUpdateStatus(event.target.value)
                setChange(true)
              }}
              input={<OutlinedInput />}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {userStatus?.map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box className="ras-box">
            <InputLabel>{t('common:reason')}</InputLabel>
            <Select
              className="currency"
              size="small"
              disabled={!isEditing}
              renderValue={() => {
                return userStatusUpdateReason
              }}
              value={userStatusUpdateReason}
              onChange={(event) => {
                setUserStatusUpdateReason(event.target.value)
                setChange(true)
              }}
              input={<OutlinedInput />}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {userStatusChangeReason?.map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
      </Box>
      <Box className="ras-box">
        <InputLabel>{t('common:description')}</InputLabel>
        <TextField
          multiline
          size="small"
          id="description"
          disabled={!isEditing}
          variant="outlined"
          type="text"
          value={userStatusUpdateDesc}
          onChange={(event) => {
            setUserStatusUpdateDesc(event.target.value)
            setChange(true)
          }}
        />
      </Box>

      <Divider style={{ margin: '1rem 0rem' }} />

      <Box className="ras-box">
        <Box className="ras-box">
          <Typography variant="title">
            Update KYC status to
            {ras?.usersToUpdate && !ras?.userStatusUpdate?.updateTo && !ras?.userKYCStatusUpdate?.updateTo && (
              <span className="mandatory">*</span>
            )}
          </Typography>
          <Select
            className="currency"
            size="small"
            disabled={!isEditing}
            renderValue={() => {
              return userKYCStatusUpdateStatus
            }}
            value={userKYCStatusUpdateStatus}
            onChange={(event) => {
              setUserKYCStatusUpdateStatus(event.target.value)
              setChange(true)
            }}
            input={<OutlinedInput />}
            inputProps={{ 'aria-label': 'Without label' }}
          >
            {userKYCStatus?.map((name) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box className="ras-box">
          <InputLabel>{t('common:reason')}</InputLabel>
          <Select
            className="currency"
            size="small"
            disabled={!isEditing}
            required
            renderValue={() => {
              return userKYCStatusUpdateReason
            }}
            value={userKYCStatusUpdateReason}
            onChange={(event) => {
              setUserKYCStatusUpdateReason(event.target.value)
              setChange(true)
            }}
            input={<OutlinedInput />}
            inputProps={{ 'aria-label': 'Without label' }}
          >
            {userKYCStatusChangeReason?.map((name) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>

      <Box className="ras-box">
        <InputLabel>{t('common:description')}</InputLabel>
        <TextField
          multiline
          size="small"
          id="description"
          disabled={!isEditing}
          variant="outlined"
          type="text"
          value={userKYCStatusUpdateDesc}
          onChange={(event) => {
            setUserKYCStatusUpdateDesc(event.target.value)
            setChange(true)
          }}
        />
      </Box>
    </Box>
  )
}

export default RulesAdvanceSettings

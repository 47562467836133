import React from 'react'
import svgs from './svg-icons'
import './iconLib.scss'

const IconLib = ({ svg, classes }) => {
  const svgRender = svgs[svg] || svgs.default
  return <div className={classes}>{svgRender.svg}</div>
}

export default IconLib

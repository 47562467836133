import { createSlice } from '@reduxjs/toolkit'

export const workflowsSlice = createSlice({
  name: 'workflows',
  initialState: {
    workflows: [],
    toAssign: [],
    filters: {
      emailClient: '',
      name: '',
      status: '',
      steps: [],
      page: 0,
      limit: 50,
      offset: 0,
    },
  },
  reducers: {
    updateWorkflows: (state, action) => {
      state.workflows = action.payload
    },
    performLogoutWorkflows: (state) => {
      state.workflows = []
    },
    updateWorkflowsFilters: (state, filters) => {
      state.filters = filters.payload
    },
  },
})

export const { updateWorkflows, performLogoutWorkflows, updateWorkflowsFilters } = workflowsSlice.actions
export default workflowsSlice.reducer

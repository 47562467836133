import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import './kytManualCheck.scss'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import ProcessingSpinner from '../../../components/common/alertFetchSpinner/processingSpinner'
import CustomNoRowsOverlay from '../../../components/common/datagrid/CustomNoRowsOverlay'

const UserSearch = ({ selectedUser, setSelectedUser }) => {
  const { accessToken } = useSelector((state) => state.login)

  const [userType, setUserType] = useState('consumer')
  const [userName, setUserName] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [pageModal, setPageModal] = useState(0)
  const [rowsPerPageModal, setRowsPerPageModal] = useState(5)
  const [usersList, setUsersList] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')
  //   const [selectedUser, setSelectedUser] = useState('')
  const [change, setChange] = useState(false)

  const { t } = useTranslation(['common', 'messages'])

  const handleSearch = () => {
    try {
      setIsLoading(true)
      let url = `${process.env.REACT_APP_BASEURL}/kyt/users?limit=${rowsPerPageModal}&offset=${
        rowsPerPageModal * pageModal
      }&type=${userType}&value=${userName}&page=${pageModal}`

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      }

      fetch(
        process.env.REACT_APP_IS_DEMO
          ? userType === 'consumer'
            ? '../../../data/kyt.consumer.view.json'
            : '../../../data/kyt.business.view.json'
          : url,
        options,
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.data) {
            setUsersList(res)
            setIsLoading(false)
          }
        })
        .catch((error) => {
          console.error('[GET USERS ERROR] --> ', error)
          setErrorMessage(error.message ? error.message : 'Get users error')
          setIsLoading(false)
        })
    } catch (error) {
      if (error.response) {
        console.error('[GET USERS ERROR] --> ', error)
      }
    }
  }

  useEffect(() => {
    if (change) {
      handleSearch()
      setChange(false)
    }
  }, [pageModal, rowsPerPageModal])

  const handleCheckboxChange = (userId) => {
    if (selectedUser === userId) {
      setSelectedUser('')
    } else {
      setSelectedUser(userId)
    }
  }

  const handleChangePageModal = (event, newPage) => {
    setPageModal(newPage)
    setChange(true)
  }

  const handleChangeRowsPerPageModal = (event) => {
    setRowsPerPageModal(parseInt(event.target.value, 10))
    setPageModal(0)
    setChange(true)
  }

  return (
    <Box className="lists-box-users">
      <Box className="kyt-user-wrapper">
        <Box className="lists-box">
          <Typography variant="subtitle3">{t('common:type')}:</Typography>
          <Select
            fullWidth
            value={userType}
            onChange={(e) => {
              setUserType(e.target.value)
            }}
            displayEmpty
          >
            {['consumer', 'business']?.map((name) => (
              <MenuItem key={name} value={name}>
                {name?.toUpperCase()}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box className="lists-box">
          <Typography variant="subtitle3">{t('common:user')}:</Typography>
          <TextField
            size="small"
            fullWidth
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            placeholder={`${t('common:insert')} ${t('common:userName')} ${t('here')}`}
          ></TextField>
        </Box>

        <Button
          size="small"
          onClick={() => {
            setUserName('')
          }}
          variant="outlinedGrey"
        >
          {t('common:clear')}
        </Button>
        <Button
          size="small"
          onClick={() => handleSearch()}
          variant="contained"
          disabled={!userName && usersList?.length === 0}
        >
          {t('common:search')}
        </Button>

        {isLoading && <ProcessingSpinner message={t('common:processing')} />}

        {Array.isArray(usersList?.data) && usersList?.data?.length > 0 ? (
          <Paper elevation={0} className="kyt-user-search">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('common:userId')}</TableCell>
                    <TableCell>{t('common:name')}</TableCell>
                    <TableCell>{t('common:action')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="table-body">
                  {usersList?.data?.map((user, index) => {
                    return user?.legalName ? (
                      <TableRow key={index}>
                        <TableCell>{user.userId}</TableCell>
                        <TableCell>{user.legalName}</TableCell>
                        <TableCell>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={selectedUser === user.userId}
                                onChange={() => handleCheckboxChange(user.userId)}
                              />
                            }
                          />
                        </TableCell>
                      </TableRow>
                    ) : user?.firstName || user?.middleName ? (
                      <TableRow key={index}>
                        <TableCell>{user.userId}</TableCell>
                        <TableCell>
                          {`${user.firstName ? user.firstName : ''} ${user.middleName ? user.middleName : ''} ${
                            user.lastName ? user.lastName : ''
                          }`}
                        </TableCell>
                        <TableCell>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={selectedUser === user.userId}
                                onChange={() => handleCheckboxChange(user.userId)}
                              />
                            }
                          />
                        </TableCell>
                      </TableRow>
                    ) : (
                      <Box className="table-custom-overlay">
                        <CustomNoRowsOverlay />
                      </Box>
                    )
                  })}
                </TableBody>
              </Table>

              <TablePagination
                labelRowsPerPage={t('common:rows')}
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={usersList?.pagination?.totalElements || 0}
                page={pageModal}
                onPageChange={handleChangePageModal}
                rowsPerPage={rowsPerPageModal}
                onRowsPerPageChange={handleChangeRowsPerPageModal}
              />
            </TableContainer>
          </Paper>
        ) : usersList === null ? (
          ''
        ) : (
          <Paper elevation={0} className="kyt-user-search">
            <span>{t('common:noMatchesFound')}</span>
          </Paper>
        )}
      </Box>

      {selectedUser !== '' && (
        <Paper variant="rootDashed" elevation={0} className="kyt-user-search">
          <InputLabel>{t('common:selectedUser')}</InputLabel>
          <Typography variant="subtitle3">{selectedUser !== '' ? selectedUser : '---'}</Typography>
        </Paper>
      )}
    </Box>
  )
}

export default UserSearch

import { useState } from "react";
import { Box, Button, Modal, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined'
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import dayjs from 'dayjs';
import fileDownload from "js-file-download";
import ModalTitle from "../../../components/common/Modal/ModalTitle";
import MyDatePicker from "../../../components/utilities/MyDatePicker";
import { useGetKybsReportMutation } from "../../../services/guenoApi";

const initialDate = new Date();
initialDate.setFullYear(initialDate.getFullYear() - 1);

const KybExportReport = () => {
  const { countrySegment } = useSelector((state) => state.kybCountry);
  const [modalOpen, setModalOpen] = useState(false)
  const [fromDate, setFromDate] = useState(initialDate);
  const [endDate, setEndDate] = useState(new Date());
  const { t } = useTranslation(['common', 'messages'])

  const [getKybsReport, { isLoading, isError }] = useGetKybsReportMutation();
  
  const handleCreate = async () => {
    const { data } = await getKybsReport({
      countrySegment,
      fromDate: dayjs(fromDate).startOf('date').toISOString(),
      endDate: dayjs(endDate).endOf('date').toISOString()
    })
    if (data) {
      fileDownload(data, `report-${dayjs().format('YYYY-MM-DD')}.csv`, 'text/csv;charset=utf-8', "\uFEFF");
    }
  }

  return (
    <>
      <Button
        variant="outlined"
        onClick={() => setModalOpen(true)}
        endIcon={<IosShareOutlinedIcon sx={{ fill: 'var(--lime-04)!important' }} />}
      >
        <Typography variant="title2">{t('common:exportReport')}</Typography>
      </Button>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        keepMounted={false}
      >
        <Box className="modal">
          <ModalTitle setModalOpen={setModalOpen} title="Export KYBs report" />
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, marginBottom: 2 }}>
            <Box className="modal-box">
              <Typography variant="subtitle3">
                {t('common:initialDate')}:
              </Typography>
              <MyDatePicker
                selectedDate={dayjs(fromDate)}
                setSelectedDate={(newValue) => setFromDate(newValue)}
              />
            </Box>
            <Box className="modal-box">
              <Typography variant="subtitle3">
                {t('common:endDate')}:
              </Typography>
              <MyDatePicker
                selectedDate={dayjs(endDate)}
                setSelectedDate={(newValue) => setEndDate(newValue)}
              />
            </Box>
          </Box>
          {isError && (
            <Box>
              An error ocurred generating the report. Try again please.
            </Box>
          )}
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <LoadingButton
              size="small"
              color="secondary"
              variant="contained"
              loading={isLoading}
              onClick={() => setModalOpen(false)}
            >
              <span>{t('common:cancel')}</span>
            </LoadingButton>
            <LoadingButton
              size="small"
              color="secondary"
              variant="contained"
              loading={isLoading}
              onClick={handleCreate}
            >
              <span>{t('common:create')}</span>
            </LoadingButton>
          </Box>
        </Box>
      </Modal>
    </>
  )
}

export default KybExportReport;

import Box from '@mui/material/Box'
import Spinner from '../../../common/spinner/spinner'
import RulesLibraryTable from './RulesLibraryTable'

const RulesLibrary = ({ rules, isLoading, setIsLoading, setInstanceRules, instanceRules }) => {
  return (
    <Box>
      {isLoading ? (
        <Spinner noTransform />
      ) : (
        <RulesLibraryTable
          rows={rules}
          setIsLoading={setIsLoading}
          instanceRules={instanceRules}
          setInstanceRules={setInstanceRules}
        />
      )}
    </Box>
  )
}

export default RulesLibrary

import { useNavigate } from 'react-router-dom'
import './workflows.scss'
import { Box } from '@mui/material'

const Workflows = () => {
  const navigate = useNavigate()
  return (
    <Box
      className="datatable workflows"
      onClick={() => {
        navigate('/workflows/options')
      }}
    >
      <img
        className="img-separator"
        src="https://res.cloudinary.com/g-eno/image/upload/v1695827215/Testing/workflows-table.png"
        alt=""
      />
    </Box>
  )
}

export default Workflows

import { Box, Typography } from '@mui/material'
import { renderTags } from './renderTags'
import { camelCaseFormatter } from '../../utilities/formatters/camelCase'

export const renderAddresses = (actualUser) => {
  if (
    actualUser &&
    actualUser.contactDetails &&
    actualUser.contactDetails.addresses &&
    actualUser.contactDetails.addresses.length !== 0
  ) {
    return (
      <Box>
        {actualUser.contactDetails.addresses.map((element, index) => {
          return (
            <Box className="subsection-item" key={index}>
              {Object.keys(element).map((item, index) => {
                if (item !== 'tags') {
                  return (
                    <Box key={index} className="subsection-item-title">
                      <Typography variant="subtitle1">{camelCaseFormatter(item)}:</Typography>{' '}
                      <Typography variant="h5">{element[item]}</Typography>
                    </Box>
                  )
                } else {
                  return (
                    <Box sx={{ mt: 2 }} key={index}>
                      {renderTags({ tags: element[item] })}
                    </Box>
                  )
                }
              })}
            </Box>
          )
        })}
      </Box>
    )
  } else if (!actualUser || !actualUser.contactDetails || !actualUser.contactDetails.addresses) {
    return (
      <Box className="tags">
        <Typography variant="title3" className="subsection-item-title">
          ---
        </Typography>
      </Box>
    )
  }
}

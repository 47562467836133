import {
  Box,
  Button,
  Checkbox,
  Fab,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Typography,
} from '@mui/material'
import './caseFlow.scss'
import { memo, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import SkipNextOutlinedIcon from '@mui/icons-material/SkipNextOutlined'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { CaseStatus } from '../../case.status'
import { Handle, Position } from '@xyflow/react'

const StepInput = ({ data, id }) => {
  const caseDetailsSlice = useSelector((state) => state.case)
  const [category, setCategory] = useState(data?.step?.category ? data?.step?.category : '')
  const [label, setLabel] = useState(data?.step?.label ? data?.step?.label : '')
  const [caseStatus, setCaseStatus] = useState(data?.step?.caseStatus ? data?.step?.caseStatus : '')
  const rolesState = useSelector((state) => state.role?.roles)
  const rolesItems = rolesState.data !== 'Forbidden resource' ? rolesState?.data : []
  const [roles, setRoles] = useState(data?.step?.assignedRoles ? data?.step?.assignedRoles : [])
  const { t } = useTranslation(['common', 'messages'])

  const handleSaveStep = useCallback(() => {
    data.saveSteps({ id, assignedRoles: roles, category, label, caseStatus })
  }, [data, id, roles, category, label, caseStatus])

  useEffect(() => {
    handleSaveStep()
  }, [category, label, roles, caseStatus])

  return (
    <>
      <Paper className="step-input">
        {id !== '1' && <Handle type="target" position={Position.Left} />}

        <Box>
          <Box className="step-input-top">
            <Typography variant="title">{t('common:step')}</Typography>

            {id !== '1' && (
              <Fab variant="close" onClick={() => data.deleteStep(id)}>
                <CloseOutlinedIcon />
              </Fab>
            )}
          </Box>
          <Box className="step-input-box-filters">
            <Box className="step-input-grid">
              <Box className="step-input-box">
                <InputLabel htmlFor="email">{t('common:category')}</InputLabel>
                <Select
                  fullWidth
                  className="nowheel nodrag nopan step-select"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                  displayEmpty
                >
                  <MenuItem disabled value="">
                    <Typography variant="subtitle3">
                      {t('common:select')} {t('common:category')}
                    </Typography>
                  </MenuItem>
                  {caseDetailsSlice?.caseAdminPanel?.categories?.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </Box>

              <Box className="step-input-box">
                <InputLabel>{t('common:newLabel')}:</InputLabel>
                <Select
                  fullWidth
                  className="nowheel nodrag nopan step-select"
                  value={label}
                  onChange={(e) => setLabel(e.target.value)}
                  displayEmpty
                >
                  <MenuItem disabled value="">
                    <Typography variant="subtitle3">
                      {t('common:select')} {t('common:newLabel')}
                    </Typography>
                  </MenuItem>
                  {caseDetailsSlice?.caseAdminPanel?.labels?.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </Box>

              <Box className="step-input-box">
                <InputLabel htmlFor="service">{t('common:status')}:</InputLabel>
                <Select
                  fullWidth
                  className="nowheel nodrag nopan step-select"
                  displayEmpty
                  value={caseStatus}
                  onChange={(e) => setCaseStatus(e.target.value)}
                >
                  <MenuItem disabled value="">
                    {t('common:select')} {t('common:status')}
                  </MenuItem>
                  {CaseStatus.map((item) => (
                    <MenuItem key={item} value={item}>
                      {t(`common:${item}`)}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Box className="step-input-box">
                <InputLabel htmlFor="roles">
                  {t('common:by')} {t('common:role')}
                </InputLabel>
                <Select
                  required
                  fullWidth
                  className="nowheel nodrag nopan step-select"
                  multiple
                  value={roles}
                  onChange={(e) =>
                    setRoles(typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value)
                  }
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      {selected.map((id) => {
                        const role = rolesItems.find((r) => r._id === id)
                        return <Box key={id}>{role ? role.roleName : ''}</Box>
                      })}
                    </Box>
                  )}
                >
                  {rolesItems?.map((r) => (
                    <MenuItem key={r.roleName} value={r._id}>
                      <Checkbox checked={roles.indexOf(r._id) > -1} />
                      <ListItemText primary={r.roleName} />
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </Box>
          </Box>

          <Box className="step-input-filter-buttons">
            <Button
              variant="outlinedGrey"
              onClick={() => {
                return data.addNode()
              }}
              endIcon={<SkipNextOutlinedIcon />}
            >
              {t('common:next')}
            </Button>
          </Box>
        </Box>
        <Handle type="source" position={Position.Right} />
      </Paper>
    </>
  )
}

export default memo(StepInput)

import './caseManagement.scss'
import { useEffect, useState } from 'react'
import { Box, Chip, MenuItem, Paper, Select, Tab, TextField, Typography } from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { useDispatch, useSelector } from 'react-redux'
import CasesDatatable from './cases/CasesTable'
import { updateCasesFilters } from '../../features/cases/caseSlice'
import { updateAlertsFilters } from '../../features/alerts/alertSlice'
import { useNavigate, useParams } from 'react-router-dom'
import GeneralAlertsDatatable from './alerts/GeneralAlertsTable'
import { updateGeneralAlertsFilters } from '../../features/generalAlerts/generalAlertsSlice'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import Spinner from '../../components/common/spinner/spinner'
import CaseAdminPanel from './adminPanel/CaseAdminPanel'
import { CaseStatus } from './case.status'
import { FilterModal } from '../../components/utilities/FilterModal'

const CaseManagement = () => {
  const { activeServices, emailClient } = useSelector((state) => state.login)
  const caseQueryState = useSelector((state) => state.case.filters)
  const caseState = useSelector((state) => state.case)
  const alertQueryState = useSelector((state) => state.alert.filters)
  const generalAlertsQueryState = useSelector((state) => state.generalAlerts.filters)
  const { tab } = useParams()
  const [actualTab, setActualTab] = useState('MyCases')
  const [isLoading, setIsLoading] = useState(true)
  const [caseId, setCaseId] = useState(caseQueryState.caseId)
  const [ruleId, setRuleId] = useState(alertQueryState.ruleId)
  const [alertId, setAlertId] = useState(alertQueryState.alertId)
  const [alertType, setAlertType] = useState('')
  const [generalAlertId, setGeneralAlertId] = useState(generalAlertsQueryState.id)
  const [userId, setUserId] = useState(caseQueryState.userId)
  const [firstName, setFirstName] = useState(caseQueryState.firstName)
  const [lastName, setLastName] = useState(caseQueryState.lastName)
  const [legalName, setLegalName] = useState(caseQueryState.legalName)
  const [userKey, setUserKey] = useState(caseQueryState.userKey)
  const [userValue, setUserValue] = useState(caseQueryState.userValue)
  const [transactionKey, setTransactionKey] = useState(caseQueryState.transactionKey)
  const [documentNumber, setDocumentNumber] = useState(caseQueryState.documentNumber)
  const [transactionValue, setTransactionValue] = useState(caseQueryState.transactionValue)
  const [userType, setUserType] = useState(caseQueryState.userType ? caseQueryState.userType : 'ALL')
  const [status, setStatus] = useState('')
  const [transactionId, setTransactionId] = useState(alertQueryState.transactionId)
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(50)
  const [unique, setUnique] = useState({})
  const [selectedFilters, setSelectedFilters] = useState([])
  const [modalOpen, setModalOpen] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation(['common', 'messages'])
  const languageState = useSelector((state) => state.language.lang)

  useEffect(() => {
    dayjs.locale(languageState)
  }, [languageState])

  useEffect(() => {
    if (!tab || (tab !== 'CaseHistory' && tab !== 'MyCases' && tab !== 'AllAlerts' && tab !== 'AdminPanel')) {
      navigate('/case-management/MyCases')
      setActualTab('MyCases')
    } else {
      setActualTab(tab)
    }
  }, [tab])

  useEffect(() => {
    if (caseState?.cases) setUnique(caseState.cases.unique)
  }, [caseState])

  const handleCaseResetButton = () => {
    if (caseId !== '') setCaseId('')
    if (userId !== '') setUserId('')
    if (fromDate !== '') setFromDate(null)
    if (toDate !== '') setToDate(null)
    if (firstName !== '') setFirstName('')
    if (lastName !== '') setLastName('')
    if (legalName !== '') setLegalName('')
    if (userType !== '') setUserType('ALL')
    if (userKey !== '') setUserKey('')
    if (userValue !== '') setUserValue('')
    if (transactionKey !== '') setTransactionKey('')
    if (transactionValue !== '') setTransactionValue('')
    if (documentNumber !== '') setDocumentNumber('')
    if (status !== '') setStatus(null)

    setPage(0)
    setIsLoading(true)

    return dispatch(
      updateCasesFilters({
        viewMode: true,
        page: 0,
        limit: 50,
        offset: 0,
        status: '',
        caseId: '',
        userId: '',
        fromDate: null,
        toDate: '',
        firstName: '',
        lastName: '',
        legalName: '',
        userType: 'ALL',
        userKey: '',
        userValue: '',
        transactionKey: '',
        transactionValue: '',
        documentNumber: '',
      }),
    )
  }

  const handleResetButton = () => {
    if (actualTab === 'AllAlerts') {
      handleGeneralAlertResetButton()
    } else {
      handleCaseResetButton()
    }
  }

  const handleDispatch = () => {
    setPage(0)

    if (actualTab === 'AllAlerts') {
      handleGeneralAlertsDispatch()
    } else {
      handleCaseDispatch()
    }

    return setModalOpen(false)
  }

  const handleAlertResetButton = () => {
    if (ruleId !== '') setRuleId('')
    if (alertId !== '') setAlertId('')
    if (transactionId !== '') setTransactionId('')
    if (fromDate !== '') setFromDate(null)
    setPage(0)
    setIsLoading(true)

    return dispatch(
      updateAlertsFilters({
        viewMode: true,
        page: 0,
        limit: 10,
        offset: 0,
        alertId: '',
        ruleId: '',
        transactionId: '',
        fromDate: null,
        toDate: '',
      }),
    )
  }

  const handleGeneralAlertResetButton = () => {
    if (generalAlertId !== '') setGeneralAlertId('')
    if (alertType !== '') setAlertType('')
    if (fromDate !== '') setFromDate(null)
    setPage(0)
    setIsLoading(true)

    return dispatch(
      updateGeneralAlertsFilters({
        viewMode: true,
        emailClient: emailClient,
        id: '',
        limit: rowsPerPage,
        offset: rowsPerPage * page,
        page,
        type: '',
        fromDate: null,
        toDate: '',
      }),
    )
  }

  const handleCaseDispatch = () => {
    setIsLoading(true)
    return dispatch(
      updateCasesFilters({
        viewMode: true,
        limit: rowsPerPage,
        offset: rowsPerPage * page,
        page,
        status,
        caseId,
        userId,
        firstName,
        lastName,
        legalName,
        userType,
        userKey,
        userValue,
        transactionKey,
        transactionValue,
        documentNumber,
        fromDate: fromDate?.toISOString(),
        toDate: toDate?.toISOString(),
      }),
    )
  }

  const handleGeneralAlertsDispatch = () => {
    setIsLoading(true)
    return dispatch(
      updateGeneralAlertsFilters({
        viewMode: true,
        emailClient: emailClient,
        id: generalAlertId,
        limit: rowsPerPage,
        offset: rowsPerPage * page,
        page,
        type: alertType,
        fromDate: fromDate?.toISOString(),
        toDate: new Date(toDate)?.toISOString(),
      }),
    )
  }

  useEffect(() => {
    handleCaseDispatch()
    handleGeneralAlertsDispatch()
  }, [rowsPerPage, page, status])

  const handleChange = (event, newValue) => {
    if (newValue === 'AllAlerts') handleAlertResetButton()
    else handleCaseResetButton()
    navigate(`/case-management/${newValue}`)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  useEffect(() => {
    if (fromDate) {
      const formattedDate = new Date(fromDate)
      setToDate(fromDate ? formattedDate.setDate(formattedDate.getDate() + 1) : null)
    }
  }, [fromDate])

  useEffect(() => {
    if (!fromDate && toDate) {
      const formattedDate = new Date(toDate)
      formattedDate?.setHours(0)
      formattedDate?.setMinutes(0)
      formattedDate?.setSeconds(0)
      setFromDate(formattedDate)
    }
  }, [toDate])

  useEffect(() => {
    if (fromDate && !toDate) {
      const formattedDate = new Date(fromDate)
      formattedDate?.setHours(23)
      formattedDate?.setMinutes(59)
      formattedDate?.setSeconds(59)
      setToDate(formattedDate ?? null)
    }
  }, [fromDate])

  return (
    <Box>
      {isLoading && <Spinner noTransform />}
      <Box className={`${isLoading && 'spinner-transition'} filter`}>
        <Box className="component-title-wrapper">
          <Box className="component-title">
            <Typography variant="h2">{t('common:casesAndInvestigation')}</Typography>
            {/* {activeServices.includes('reportsitem') && (
              <Button
                variant="outlined"
                disabled={!activeServices.includes('reportsitem') || !cases?.data?.length}
                onClick={() => setShowDownloadModal(true)}
                endIcon={<IosShareOutlinedIcon sx={{ fill: 'var(--lime-04)' }} />}
              >
                <Typography variant="title2">Export Report</Typography>
              </Button>
            )} */}
          </Box>
          <Typography variant="subtitle3">
            {/* Loren ipsum dolor sit amet Loren ipsum dolor sit amet Loren  */}
          </Typography>
        </Box>

        <TabContext value={tab}>
          <TabList
            onChange={handleChange}
            TabIndicatorProps={{
              style: { display: 'none' },
            }}
            sx={{ marginBottom: '1rem' }}
          >
            <Tab
              label={<Chip label={t('common:myCases')} variant={tab === 'MyCases' ? 'sliderSelected' : 'slider'} />}
              value="MyCases"
            />
            <Tab
              label={
                <Chip label={t('common:caseHistory')} variant={tab === 'CaseHistory' ? 'sliderSelected' : 'slider'} />
              }
              value="CaseHistory"
            />
            <Tab
              label={<Chip label={t('common:allAlerts')} variant={tab === 'AllAlerts' ? 'sliderSelected' : 'slider'} />}
              value="AllAlerts"
            />
            {activeServices.includes('caseAdminPanel:emailClient') && (
              <Tab
                label={
                  <Chip label={t('common:adminPanel')} variant={tab === 'AdminPanel' ? 'sliderSelected' : 'slider'} />
                }
                value="AdminPanel"
              />
            )}
          </TabList>

          <Paper elevation={0}>
            <TabPanel value="CaseHistory">
              <CasesDatatable
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                setPage={setPage}
                setCount={setCount}
                count={count}
                actualTab="CaseHistory"
                setModalOpen={setModalOpen}
                page={page}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                hasTabs="23rem"
              />
            </TabPanel>
            <TabPanel value="MyCases">
              <CasesDatatable
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                setPage={setPage}
                setCount={setCount}
                count={count}
                actualTab="MyCases"
                setModalOpen={setModalOpen}
                page={page}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                hasTabs="23rem"
              />
            </TabPanel>
            {activeServices.includes('generalAlerts') && (
              <TabPanel value="AllAlerts">
                <GeneralAlertsDatatable
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  setPage={setPage}
                  setCount={setCount}
                  count={count}
                  type={alertType}
                  withPagination={true}
                  setModalOpen={setModalOpen}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  hasTabs="23rem"
                />
              </TabPanel>
            )}
            {activeServices.includes('caseAdminPanel:emailClient') && (
              <TabPanel value="AdminPanel">
                <CaseAdminPanel isLoading={isLoading} setIsLoading={setIsLoading} />
              </TabPanel>
            )}
          </Paper>
        </TabContext>

        {/* MODAL */}
        <FilterModal
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          handleResetButton={handleResetButton}
          setPage={setPage}
          handleDispatch={handleDispatch}
        >
          <Box className="modal-box-filters">
            <Box className="modal-box">
              <Typography variant="subtitle3">{t('common:by')} ID:</Typography>
              <TextField
                placeholder={t('common:searchById')}
                required
                value={caseId}
                onChange={(event) => setCaseId(event.target.value)}
                size="small"
              />
            </Box>

            <Box className="modal-box">
              <Typography variant="subtitle3">
                {t('common:by')} {t('common:state')}:
              </Typography>
              <Select
                fullWidth
                size="small"
                displayEmpty
                value={status}
                onChange={(e) => {
                  if (e.target.value) {
                    setStatus(e.target.value)
                    setPage(0)
                  } else setStatus(null)
                }}
              >
                <MenuItem disabled value="">
                  <Typography variant="subtitle3">
                    {t('common:select')} {t('common:state')}
                  </Typography>
                </MenuItem>
                {CaseStatus.map((name, index) => (
                  <MenuItem key={index} value={name}>
                    {name.replaceAll('_', ' ')}
                  </MenuItem>
                ))}
              </Select>
            </Box>

            <Box className="modal-box">
              <Typography variant="subtitle3">{t('common:initialDate')}:</Typography>

              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={languageState === 'es-ES' ? 'es' : languageState === 'pt-BR' ? 'pt-br' : languageState}
              >
                <DatePicker
                  value={dayjs(fromDate)}
                  onChange={(newValue) => {
                    setSelectedFilters([...selectedFilters, newValue])
                    return setFromDate(newValue ? newValue['$d'] : null)
                  }}
                />
              </LocalizationProvider>
            </Box>

            <Box className="modal-box">
              <Typography variant="subtitle3">{t('common:endDate')}:</Typography>
              <LocalizationProvider
                size="small"
                dateAdapter={AdapterDayjs}
                adapterLocale={languageState === 'es-ES' ? 'es' : languageState === 'pt-BR' ? 'pt-br' : languageState}
              >
                <DatePicker
                  size="small"
                  value={dayjs(toDate)}
                  onChange={(newValue) => {
                    let aux = newValue ? new Date(newValue['$d']) : null
                    aux?.setHours(23)
                    aux?.setMinutes(59)
                    aux?.setSeconds(59)
                    setToDate(aux)
                  }}
                  renderInput={(params) => <TextField size="small" {...params} />}
                />
              </LocalizationProvider>
            </Box>

            {(actualTab === 'CaseHistory' || actualTab === 'MyCases') && (
              <>
                <Box className="modal-box">
                  <Typography variant="subtitle3">
                    {t('common:by')} {t('common:user')} ID:
                  </Typography>

                  <TextField
                    variant="outlined"
                    size="small"
                    value={userId}
                    onChange={(event) => setUserId(event.target.value)}
                    placeholder={`${t('common:user')} ID`}
                  />
                </Box>

                <Box className="modal-box">
                  <Typography variant="subtitle3">
                    {t('common:by')} {t('common:firstName')} ({t('common:consumer')}):
                  </Typography>

                  <TextField
                    variant="outlined"
                    size="small"
                    value={firstName}
                    onChange={(event) => setFirstName(event.target.value)}
                    placeholder={t('common:firstName')}
                  />
                </Box>

                <Box className="modal-box">
                  <Typography variant="subtitle3">
                    {t('common:legalName')} ({t('common:business')}):
                  </Typography>

                  <TextField
                    variant="outlined"
                    size="small"
                    value={legalName}
                    onChange={(event) => setLegalName(event.target.value)}
                    placeholder={t('common:legalName')}
                  />
                </Box>

                <Box className="modal-box">
                  <Typography variant="subtitle3">
                    {t('common:by')} {t('common:documentNumber')} ({t('common:consumer')}):
                  </Typography>

                  <TextField
                    variant="outlined"
                    size="small"
                    value={documentNumber}
                    onChange={(event) => setDocumentNumber(event.target.value)}
                    placeholder={t('common:documentNumber')}
                  />
                </Box>

                <Box className="modal-box">
                  <Typography variant="subtitle3">
                    {t('common:by')} {t('common:userType')}:
                  </Typography>

                  <Select
                    displayEmpty
                    value={userType}
                    onChange={(e) => setUserType(e.target.value)}
                    size="small"
                    options={['CONSUMER', 'BUSINESS', 'ALL']}
                  >
                    <MenuItem disabled value="">
                      <Typography variant="subtitle3">
                        {t('common:select')} {t('common:userType')}:
                      </Typography>
                    </MenuItem>
                    {['CONSUMER', 'BUSINESS', 'ALL'].map((name, index) => (
                      <MenuItem key={index} value={name}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>

                <Box className="modal-box">
                  <Typography variant="subtitle3">
                    {t('common:by')} {t('common:tagKey')}:
                  </Typography>

                  <Select
                    displayEmpty
                    fullWidth
                    value={userKey}
                    onChange={(e) => setUserKey(e.target.value)}
                    size="small"
                  >
                    <MenuItem disabled value="">
                      <Typography variant="subtitle3">
                        {t('common:select')} {t('common:tagKey')}:
                      </Typography>
                    </MenuItem>

                    {unique?.uniqueUserKeys &&
                    Array.isArray(unique.uniqueUserKeys[0]) &&
                    Array.isArray(unique.uniqueUserKeys[1])
                      ? [...unique.uniqueUserKeys[0], ...unique.uniqueUserKeys[1]]
                      : unique?.uniqueUserKeys && Array.isArray(unique.uniqueUserKeys[0])
                      ? [...unique.uniqueUserKeys[0]]
                      : ['---'].map((name, index) => (
                          <MenuItem key={index} value={name}>
                            {name}
                          </MenuItem>
                        ))}
                  </Select>
                </Box>

                <Box className="modal-box">
                  <Typography variant="subtitle3">
                    {t('common:by')} {t('common:tagValue')}:
                  </Typography>

                  <Select displayEmpty value={userValue} onChange={(e) => setUserValue(e.target.value)} size="small">
                    <MenuItem disabled value="">
                      <Typography variant="subtitle3">
                        {t('common:select')} {t('common:tagValue')}:
                      </Typography>
                    </MenuItem>
                    {unique?.uniqueUserValues &&
                    Array.isArray(unique.uniqueUserValues[0]) &&
                    Array.isArray(unique.uniqueUserValues[1])
                      ? [...unique.uniqueUserValues[0], ...unique.uniqueUserValues[1]]
                      : unique?.uniqueUserValues && Array.isArray(unique.uniqueUserValues[0])
                      ? [...unique.uniqueUserValues[0]]
                      : ['---'].map((name, index) => (
                          <MenuItem key={index} value={name}>
                            {name}
                          </MenuItem>
                        ))}
                  </Select>
                </Box>

                <Box className="modal-box">
                  <Typography variant="subtitle3">
                    {t('common:by')} {t('common:transactionTagKey')}:
                  </Typography>

                  <Select
                    displayEmpty
                    value={transactionKey}
                    onChange={(e) => setTransactionKey(e.target.value)}
                    size="small"
                    options={unique?.uniqueTransactionKeys ? unique.uniqueTransactionKeys : []}
                  >
                    <MenuItem disabled value="">
                      <Typography variant="subtitle3">
                        {t('common:select')} {t('common:transactionTagKey')}:
                      </Typography>
                    </MenuItem>
                    {unique?.uniqueTransactionKeys
                      ? unique.uniqueTransactionKeys
                      : ['---'].map((name, index) => (
                          <MenuItem key={index} value={name}>
                            {name}
                          </MenuItem>
                        ))}
                  </Select>
                </Box>

                <Box className="modal-box">
                  <Typography variant="subtitle3">
                    {t('common:by')} {t('common:transactionTagValue')}:
                  </Typography>

                  <Select
                    value={transactionValue}
                    onChange={(e) => setTransactionValue(e.target.value)}
                    size="small"
                    displayEmpty
                  >
                    <MenuItem disabled value="">
                      <Typography variant="subtitle3">
                        {t('common:select')} {t('common:transactionTagValue')}:
                      </Typography>
                    </MenuItem>
                    {unique?.uniqueTransactionValues
                      ? unique.uniqueTransactionValues
                      : ['---'].map((name, index) => (
                          <MenuItem key={index} value={name}>
                            {name}
                          </MenuItem>
                        ))}
                  </Select>
                </Box>
              </>
            )}

            {actualTab === 'AllAlerts' && (
              <>
                <Box className="modal-box">
                  <Typography variant="subtitle3">
                    {t('common:by')} {t('common:alert')} ID:
                  </Typography>

                  <TextField
                    variant="outlined"
                    size="small"
                    value={generalAlertId}
                    onChange={(event) => setGeneralAlertId(event.target.value)}
                    placeholder={`${t('common:alert')} ID`}
                  />
                </Box>

                <Box className="modal-box">
                  <Typography variant="subtitle3">
                    {t('common:by')} {t('common:type')}:
                  </Typography>

                  <Select
                    value={alertType}
                    onChange={(e) => setAlertType(e.target.value)}
                    fullWidth
                    size="small"
                    displayEmpty
                  >
                    <MenuItem disabled value="">
                      <Typography variant="subtitle3">
                        {t('common:select')} {t('common:type')}
                      </Typography>
                    </MenuItem>
                    {['aml', 'amlCrypto', 'transactionRule'].map((name, index) => (
                      <MenuItem key={index} value={name}>
                        {name.toUpperCase()}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </>
            )}
          </Box>
        </FilterModal>
      </Box>
    </Box>
  )
}

export default CaseManagement

import '../../components/common/datatable/datatable.scss'
import '../health-checks/healthChecks.scss'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, Paper } from '@mui/material'
import health from '../../data/healthchecks.json'

const HealthChecks = (activeServices) => {
  const [services, setServices] = useState([])
  const [activeServicesAux, setActiveServicesAux] = useState([])
  const [urlByService, setUrl] = useState([])
  const [isReady, setIsReady] = useState(null)
  const [secondReady, setSecondReady] = useState(null)
  const [healthBar, setHealthBar] = useState([])
  const { accessToken } = useSelector((state) => state.login)

  useEffect(() => {
    if (activeServices && activeServices.activeServices) {
      let aux = []
      activeServices.activeServices?.forEach((element) => {
        if (element.includes('admin') && !aux.includes('roles')) aux.push('roles')
        if (element.includes('user') && !aux.includes('admin')) aux.push('admin')
        if (element.includes('healthCheck') && !aux.includes('healthCheck')) aux.push('healthCheck')
        if (element.includes('client') && !aux.includes('client')) aux.push('client')
        if (element.includes('kyt') && !aux.includes('kyt')) aux.push('kyt')
        if (element.includes('onboarding') && !aux.includes('onboarding')) aux.push('onboarding')
        if (element.includes('validation') && !aux.includes('validation')) aux.push('validation')
        if (element.includes('govCheck') && !aux.includes('govCheck')) aux.push('govCheck')
        if (element.includes('govCheckargentinaValidation') && !aux.includes('govCheckArgentinaValidation'))
          aux.push('govCheckArgentinaValidation')
        if (element.includes('govCheckbrazilValidation') && !aux.includes('govCheckBrazilValidation'))
          aux.push('govCheckBrazilValidation')
        if (element.includes('govCheckrutChile') && !aux.includes('govCheckRutChile')) aux.push('govCheckRutChile')
        if (element.includes('govCheckperu') && !aux.includes('govCheckPeru')) aux.push('govCheckPeru')
        if (element.includes('govCheckcolombiaValidation') && !aux.includes('govCheckColombiaValidation'))
          aux.push('govCheckColombiaValidation')
        if (element.includes('govCheckmexicoCurp') && !aux.includes('govCheckMexicoCURP'))
          aux.push('govCheckMexicoCURP')
        if (element.includes('govCheckmexicoINE') && !aux.includes('govCheckMexicoINE')) aux.push('govCheckMexicoINE')
        if (element.includes('govCheckmexicoRfc') && !aux.includes('govCheckMexicoRfc')) aux.push('govCheckMexicoRfc')
        if (element.includes('amlcomplyV1') && !aux.includes('aml')) aux.push('aml')
        if (element.includes('amlCrypto') && !aux.includes('amlCrypto')) aux.push('amlCrypto')
      })
      setActiveServicesAux(aux)
    }
  }, [activeServices])

  function filterServices() {
    if (activeServicesAux) {
      const filteredServices = activeServicesAux.filter((service) => {
        return service !== 'client' && service !== 'govCheck'
      })
      setServices(filteredServices)
      setIsReady(true)
    }
  }

  useEffect(() => {
    filterServices()
  }, [activeServicesAux])

  async function buildUrl() {
    const urls = await services.map((service) => {
      const url = `${process.env.REACT_APP_BASEURL}/healthCheck/getStatus/${service}/90`

      return { url, service: service }
    })
    setUrl(urls)
    setSecondReady(true)
  }

  useEffect(() => {
    if (isReady === null) {
      return
    }
    buildUrl()
  }, [services, isReady])

  function buildHealthBar() {
    let healthChecksWrapper = []
    urlByService.forEach(async (service) => {
      try {
        const res = await fetch(service.url, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        })

        if (res.ok) {
          const resJson = await res.json()

          if (resJson.data.length) {
            let uniqueDates = [...new Map(resJson.data.map((item) => [item.date, item])).values()]
            const buildDaysArray = createTimeline()
            const joinedDates = [...buildDaysArray.reverse(), ...uniqueDates]
            const timeline = Object.values(
              joinedDates.reduce((acc, obj) => {
                const key = new Date(obj.date).toLocaleDateString('en-US')
                acc[key] = {
                  date: key,
                  service: obj.serviceData?.service || '',
                  status1: obj.serviceData?.status1,
                  status2: obj.serviceData?.status2,
                  message1: obj.serviceData?.message1
                    ? obj.serviceData?.message1
                    : obj.serviceData?.status1
                    ? 'Operational'
                    : `${obj.serviceData?.service ?? ''} out of serivice`,
                  message2: obj.serviceData?.message2
                    ? obj.serviceData?.message2
                    : obj.serviceData?.status2
                    ? 'Operational'
                    : `${obj.serviceData?.service ?? ''} out of serivice`,
                }
                return acc
              }, {}),
            )

            const healthChecks = { timeline, service: resJson.data[0].serviceData.service }

            healthChecksWrapper = [...healthChecksWrapper, healthChecks]

            const uniqueServicesData = [...new Map(healthChecksWrapper.map((item) => [item['service'], item])).values()]

            setHealthBar(uniqueServicesData.sort((a, b) => a.service.localeCompare(b.service)))
          }
        }
      } catch (error) {
        console.error('[GET HEALTHCHECKS ERROR] --> ', error)
      }
    })
  }

  useEffect(() => {
    if (secondReady === null) {
      return
    }
    buildHealthBar()
  }, [urlByService, secondReady])

  function createTimeline() {
    const now = new Date()
    const length = 90
    const days = Array.from({ length }, (_, days) => {
      let day = new Date(now)
      day.setDate(now.getDate() - days)
      return { date: day.setHours(0, 0, 0, 0) }
    })

    return days
  }

  return (
    <Paper elevation={0} className="healthbar-margin">
      <Box className="healthbar-wrapper">
        <Box className="datatable-b fullHeight">
          <Box className="cell-wrapper">
            {(process.env.REACT_APP_IS_DEMO ? health : healthBar).map((item) => {
              return (
                <Box key={item.service} className="healthBar">
                  <span className="healthBarTitle">{item.service}</span>
                  <Box id={item.service} className="timelineWrapper">
                    {item.timeline.map((t) => {
                      return (
                        <Box key={t.date} className="timeline-size">
                          <Box
                            id="status1"
                            className="timelineTop"
                            title={`${new Date(t.date).toLocaleDateString('en-US')} - ${t.message1}`}
                            style={{
                              backgroundColor: `${
                                t.status1 === true ? '#0DDE4E' : t.status1 === false ? '#ff8a00' : '#afb9b2'
                              }`,
                            }}
                          ></Box>
                          <Box
                            id="status2"
                            className="timeline-Bottom"
                            title={`${new Date(t.date).toLocaleDateString('en-US')} - ${t.message2}`}
                            style={{
                              backgroundColor: `${
                                t.status2 === true ? '#0DDE4E' : t.status2 === false ? '#ff8a00' : '#afb9b2'
                              }`,
                            }}
                          ></Box>
                        </Box>
                      )
                    })}
                  </Box>
                  <Box className="timelineDescription">
                    <span>90 days ago</span>
                    <hr />
                    <span>Today</span>
                  </Box>
                </Box>
              )
            })}
          </Box>
        </Box>
      </Box>
    </Paper>
  )
}

export default HealthChecks

import './kyb.scss'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  Box,
  Button,
  Chip,
  Fab,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from '@mui/material'
import { Accordion, AccordionDetails, AccordionSummary } from '../../components/common/Accordion/Accordion'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined'
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown'
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp'
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab'
import { addAdditionalParams, getCountrySegment, removeLastWord } from './kyb.hook-helpers'
import {
  useCheckKybMutation,
  useCreateNewCommentMutation,
  useDeleteFilePersonMutation,
  useFindAvailableUsersQuery,
  useFindKybPersonByIdQuery,
  useFindUserMasterQuery,
  useUpdateCheckMutation,
  useUploadFilePersonMutation,
  useUpdateKybPersonMutation,
} from '../../services/guenoApi'
import KybModal from './components/KybModal'
import KybTitle from './components/KybTitle'
import { buildTimeStringFromTimestamp } from '../../components/common/time/timeHelper'
import { StatusIndicator } from '../../components/utilities/StatusIndicator'
import KybNewCommentModal from './components/KybNewCommentModal'
import KybEditCheckStatusModal from './components/KybEditCheckStatusModal'
import KybEditStatusModal from './components/KybEditStatusModal'
import KybToPdf from './components/KybToPdf'
import { colorsByStatus, iconByCountry } from './constants'
import { KybChip } from '../../components/utilities/KybChip'
import KybChecksDefaultOrder from './components/KybChecksDefaultOrder.json'
import KybChecks from './components/KybChecks.json'
import KybCheckDetail from './components/KybCheckDetail'
import KybPreviousChecks from './components/KybPreviousChecks'
import { camelCaseFormatter } from '../../components/utilities/formatters/camelCase'
import toaster from '../../toaster'
import { FileUploader } from 'react-drag-drop-files'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import TabComponent from './components/TabComponent'
import CustomNoRowsOverlay from '../../components/common/datagrid/CustomNoRowsOverlay'
import DatatableAssignUsers from '../../components/common/datatable/DatatableAssignedUsers'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { Status } from '../../components/utilities/Status'
import ProcessingSpinner from '../../components/common/alertFetchSpinner/processingSpinner'

const getStyles = (type) => {
  let color
  let backgroundColor

  switch (type.toLowerCase()) {
    case 'creation':
      backgroundColor = '#4184c3'
      color = '#fafafa'
      break
    case 'complete':
      backgroundColor = '#00B85F'
      color = '#FBFFFC'
      break
    default:
      backgroundColor = '#FF8A00'
      color = '#FBFFFC'
      break
  }

  return { color, backgroundColor }
}

const KybDetailPerson = () => {
  const { id, country } = useParams()
  const currentCountry = country.toUpperCase()
  const countrySegment = useMemo(() => getCountrySegment(currentCountry), [currentCountry])
  const navigate = useNavigate()
  const { t } = useTranslation(['common', 'messages'])
  const { emailClient, email } = useSelector((state) => state.login)
  const languageState = useSelector((state) => state.language.lang)
  const { theme } = useSelector((state) => state.theme)
  const [selectedTab, setSelectedTab] = useState('checks')
  const [editStatusModal, setEditStatusModal] = useState(false)
  const [showDownloadModal, setShowDownloadModal] = useState(false)
  const [modalNewCommentOpen, setModalNewCommentOpen] = useState(false)
  const [checkId, setCheckId] = useState('')
  const [checkStatus, setCheckStatus] = useState('')
  const [checkType, setCheckType] = useState('')
  const [openModalCheckStatus, setOpenModalCheckStatus] = useState(false)
  const [isEditingAssign, setIsEditingAssign] = useState(false)

  const { data, isFetching, isLoading } = useFindKybPersonByIdQuery({
    id,
    languageState,
    countrySegment,
  })
  const [updateKybPerson] = useUpdateKybPersonMutation()
  const [createCheck, { isLoading: createCheckLoading, originalArgs: createCheckArgs }] = useCheckKybMutation()
  const [createNewComment] = useCreateNewCommentMutation()
  const [updateCheck] = useUpdateCheckMutation()
  const [uploadFile, { isLoading: isProcessingFile }] = useUploadFilePersonMutation()
  const [deleteFile, { isLoading: isProcessingFileDelete }] = useDeleteFilePersonMutation()

  const kybPerson = data?.data
  const { data: dataForAvailableUsers } = useFindAvailableUsersQuery()
  const availableUsers = useMemo(() => dataForAvailableUsers?.data || [], [dataForAvailableUsers])
  const { data: userMasters } = useFindUserMasterQuery({ emailClient })

  const identityVerificationId = useMemo(
    () => kybPerson?.identityVerificationId ?? '',
    [kybPerson?.identityVerificationId],
  )

  const nationalityIconUrl = useMemo(() => {
    const nationality = (kybPerson?.nationality || '').toLowerCase()
    if (!nationality) return null

    return `https://flagcdn.com/16x12/${iconByCountry[nationality] || nationality}.png`
  }, [kybPerson?.nationality])

  const timelineList = useMemo(() => {
    const timeline = kybPerson?.timeline || []
    return [...timeline].reverse()
  }, [kybPerson?.timeline])

  const CustomTabList = styled(TabList)({
    borderBottom: '1px solid #1C282026',
    '& ::-webkit-scrollbar-thumb': {
      background: theme === 'light' ? '#c9d2cc' : '#57635b',
    },
  })

  const checks = useMemo(() => {
    if (!currentCountry) return []

    let sortedList = KybChecksDefaultOrder[currentCountry]['PERSON']

    if (userMasters?.kybChecks?.[currentCountry]?.['PERSON'])
      sortedList = userMasters.kybChecks?.[currentCountry]?.['PERSON']

    return KybChecks[currentCountry]['PERSON'].sort((a, b) => sortedList.indexOf(a.title) - sortedList.indexOf(b.title))
  }, [userMasters?.kybChecks, currentCountry])

  const [expandedMain, setExpandedMain] = useState([false, false, false, false, false, false])
  const [expanded, setExpanded] = useState({})

  const handleChangeExpandedMain = (indexMain) => {
    const newState = { ...expandedMain }
    newState[indexMain] = !newState[indexMain]
    setExpandedMain(newState)
  }

  const handleChangeExpanded = (indexMain, index) => {
    const key = `${indexMain}-${index}`
    const newState = { ...expanded, [key]: !expanded[key] }
    setExpanded(newState)
  }

  const handleFetch = () => {
    setOpenModalCheckStatus(false)
    setModalNewCommentOpen(false)
    setEditStatusModal(false)
  }

  const handleCreateCheck = async (e, type) => {
    e.preventDefault()
    const requiresNameAndLast = type === 'procesos-judiciales-co' || type === 'fiscalia-co' || type === 'secop-co'
    const requiresOnlyLastName = type === 'inpec-co' || type === 'experian-hcpn-co'

    const splitName = kybPerson?.name ? kybPerson.name.split(' ') : []
    const lastName = splitName.length ? splitName[splitName.length - 1] : undefined
    const firstName = splitName.length ? splitName[0] : undefined

    let data = {
      id: id,
      documentNumber: kybPerson?.documentNumber,
      entity: 'PERSON',
      type,
      ...(requiresNameAndLast && {
        name: firstName ?? removeLastWord(kybPerson?.name).modifiedString,
      }),
      ...((requiresOnlyLastName || requiresNameAndLast) && {
        last_name: lastName ?? removeLastWord(kybPerson?.name).lastWord,
      }),
      date_expedition:
        type === 'registraduria-co' || type === 'inhabilidades-co' || type === 'rmnc-co'
          ? kybPerson?.dateOfRegistration ?? '1994-05-03'
          : undefined,
    }

    data = addAdditionalParams(type, data)

    try {
      await createCheck({ countrySegment, body: data }).unwrap()
      toaster.success(t('messages:creationSuccess'))
    } catch (error) {
      console.error('[CREATE NEW COMMENT ERROR] --> ', error?.data?.data)
      toaster.error(error.message ? error.message : t('messages:creationError'))
    } finally {
      handleFetch()
    }
  }

  const postNewComment = async (newComment) => {
    try {
      await createNewComment({
        countrySegment,
        id,
        body: {
          emailClient: emailClient,
          email: email,
          description: newComment,
        },
      }).unwrap()
      toaster.success(t('messages:createCommentSuccess'))
      setModalNewCommentOpen(false)
    } catch (error) {
      console.error('[CREATE NEW COMMENT ERROR] --> ', error?.data?.data)
      toaster.error(t('messages:createCommentError'))
    } finally {
      handleFetch()
    }
  }

  const handleUpdate = async (body) => {
    try {
      await updateKybPerson({ id, countrySegment, body }).unwrap()
      toaster.success(t('messages:updateSuccess'))
    } catch (error) {
      console.error('[UPDATE STATUS ERROR] --> ', error?.data?.data)
      toaster.error(t('messages:updateError'))
    } finally {
      handleFetch()
    }
  }

  const handleCheckUpdate = async (checkId, data) => {
    const body = addAdditionalParams(checkType, data)
    try {
      await updateCheck({ countrySegment, checkId, body: { ...body, entity: 'PERSON', entityId: id } }).unwrap()
      toaster.success(t('messages:updateSuccess'))
      setCheckId('')
      setCheckStatus('')
      setCheckType('')
    } catch (error) {
      console.error('[UPDATE STATUS ERROR] --> ', error?.data?.data)
      toaster.error(error.message ? error.message : t('messages:updateError'))
    } finally {
      handleFetch()
    }
  }

  const updateAssigns = async (toAssign) => {
    try {
      await updateKybPerson({
        id,
        countrySegment,
        body: {
          assignedUsers: toAssign,
        },
      }).unwrap()
      toaster.success(t('messages:updateSuccess'))
    } catch (error) {
      console.error('[UPDATE CASE ASSIGNED USERS ERROR] --> ', error?.data?.data)
      toaster.error(t('messages:editAssigUserError'))
    }
  }

  const handleUploadFile = async (file) => {
    const formData = new FormData()
    formData.append('file', file)
    try {
      await uploadFile({ countrySegment, id, body: formData }).unwrap()
      toaster.success(t('messages:uploadFileSuccess'))
    } catch (error) {
      toaster.error(t('messages:uploadFileError'))
      console.error('[UPLOAD KYB FILE ERROR] --> ', error?.data?.data)
    } finally {
      handleFetch()
    }
  }

  const handleDeleteFile = async (e, file) => {
    e.preventDefault()
    const splittedName = file.split('/kyb/')[1].split('?')
    const key = splittedName[splittedName.length - 2]
    try {
      await deleteFile({ countrySegment, id, key }).unwrap
      toaster.success(t('messages:deletionSuccess'))
    } catch (error) {
      console.error('[DELETE KYB FILE ERROR] --> ', error?.data?.data)
      toaster.error(t('messages:deletionSuccess'))
    }
  }

  return (
    <Box className={`${(isFetching || isLoading) && 'spinner-transition'} filter`}>
      <Box className="kyb-detail">
        {/* Header Section */}
        <Box className="kyb-detail-head">
          <Paper elevation={0} sx={{ mr: '1rem', padding: '1rem' }}>
            <Box className="kyb-info">
              <Box className="kyb-info-section-head">
                <Box className="kyb-info-section-1">
                  <Typography variant="h4">{kybPerson?.name || '---'}</Typography>
                  {/* Account Type Chip */}
                  <Chip
                    label={t('common:personAccount')}
                    icon={<PersonOutlineOutlinedIcon sx={{ fill: '#fbfffc' }} />}
                    sx={{ backgroundColor: '#00bfe9' }}
                  />
                </Box>
              </Box>
              <Box className="kyb-info-section-2">
                {/* Document Number */}
                <KybTitle name={t('common:documentNumber')} value={kybPerson?.documentNumber ?? '---'} copyable />

                {/* Date of Registration */}
                <KybTitle
                  name={t('common:createdAt')}
                  value={kybPerson?.createdAt ? buildTimeStringFromTimestamp(kybPerson.createdAt) : '---'}
                />

                {/* Last Updated */}
                <KybTitle
                  name={t('common:lastUpdated')}
                  value={kybPerson?.updatedAt ? buildTimeStringFromTimestamp(kybPerson.updatedAt) : '---'}
                />

                {/* Country */}
                <KybTitle
                  name={t('common:country')}
                  value={
                    <>
                      {kybPerson?.nationality}
                      {'  '}
                      {!!nationalityIconUrl && <img alt="img" src={nationalityIconUrl} width="16" height="12" />}
                    </>
                  }
                />
              </Box>
            </Box>
          </Paper>
          <Box className="details-sub">
            <Button
              sx={{ width: '100%' }}
              size="small"
              onClick={() => setModalNewCommentOpen(true)}
              variant="contained"
            >
              {t('common:postComment')}
            </Button>
            <Button
              variant="outlined"
              onClick={() => setShowDownloadModal(true)}
              endIcon={<IosShareOutlinedIcon sx={{ fill: 'var(--lime-04)!important' }} />}
            >
              <Typography variant="title2">{t('common:exportReport')}</Typography>
            </Button>
            <Button
              sx={{ width: '100%' }}
              size="small"
              onClick={() => {
                if (identityVerificationId === '') {
                  navigate(`/onboardings/manual?kyc=${id}`)
                } else {
                  navigate(`/onboardings/${identityVerificationId}`)
                }
              }}
              variant="outlined"
            >
              {identityVerificationId
                ? t('common:SEE_KYC_IDENTITY_VERIFICATION')
                : t('common:MAKE_KYC_IDENTITY_VERIFICATION')}
            </Button>
            <Paper elevation={0} sx={{ p: '1rem' }} className="status-level">
              <Box className="status-level-top">
                <Typography variant="subtitle2">{t('common:status')}</Typography>
                <Button size="small" onClick={() => setEditStatusModal(true)} variant="outlined">
                  {t('common:edit')}
                </Button>
              </Box>
              {kybPerson?.status ? (
                <StatusIndicator type={kybPerson.status} color={colorsByStatus[kybPerson.status]} />
              ) : (
                <StatusIndicator type={'NOT_STARTED'} />
              )}
            </Paper>
          </Box>
        </Box>
        <Box className="kyb-subdetail">
          <Box sx={{ paddingRight: '1rem' }}>
            <Paper className="kyb-detail-timeline" elevation={0} sx={{ padding: '1rem' }}>
              <Typography variant="h4">{t('common:timelineAndActivity')}</Typography>
              {Array.isArray(timelineList) && (
                <Box className="timeline-wrapper">
                  {timelineList.map((timeline) => {
                    const { color, backgroundColor } = getStyles(timeline?.type)
                    return (
                      <Paper key={timeline._id} className="timeline-card" elevation={0} variant="colorSecondary">
                        <Box className="timeline-box">
                          <Box title={t(`common:${timeline.type}`)}>
                            <KybChip
                              action={t(`common:${timeline.type}`)}
                              color={color}
                              backgroundColor={backgroundColor}
                            />
                          </Box>
                        </Box>
                        {buildTimeStringFromTimestamp(timeline.createdAt)}
                        <Box className="timeline-box">
                          <p>{t('common:status')}:</p>
                          <Typography variant="text2">
                            {t(
                              `common:${
                                timeline.status === 'SUCCESS'
                                  ? 'ACTION_FINISHED_WITHOUT_ERROR'
                                  : timeline.status === 'ERROR'
                                  ? 'ACTION_FINISHED_WITH_ERROR'
                                  : timeline.status
                              }`,
                            )}
                          </Typography>
                        </Box>
                        {[
                          'SHAREHOLDER_CREATION',
                          'RELATEDPERSON_DELETION',
                          'RELATEDPERSON_CREATION',
                          'SHAREHOLDER_DELETION',
                        ].includes(timeline.type) &&
                          timeline.detail && (
                            <Box className="timeline-box">
                              <p>{t('common:details')}:</p>
                              <Typography variant="text2">
                                {`${t('common:documentN')}: ${timeline.detail?.documentNumber}`}
                              </Typography>
                            </Box>
                          )}
                        {['RELATED_COMPANIES_ADDED', 'RELATED_COMPANIES_REMOVED'].includes(timeline.type) && (
                          <Box className="timeline-box">
                            <p>{t('common:details')}:</p>
                            <Typography variant="text2">{timeline.description}</Typography>
                          </Box>
                        )}
                        <Box className="timeline-box">
                          <p>{t('common:operationalAgent')}:</p>
                          <Typography variant="text2">{timeline.email}</Typography>
                        </Box>
                      </Paper>
                    )
                  })}
                </Box>
              )}
            </Paper>
          </Box>
          <Box className="kyb-subgrid">
            <TabContext value={selectedTab}>
              <CustomTabList
                TabIndicatorProps={{ style: { display: 'none' } }}
                onChange={(e, newValue) => setSelectedTab(newValue)}
                variant="scrollable"
              >
                <Tab
                  label={
                    <Chip
                      label={t('common:checks')}
                      sx={{ fontSize: 16 }}
                      variant={selectedTab === 'checks' ? 'sliderBlack' : 'sliderWhite'}
                    />
                  }
                  sx={{ maxWidth: '100%' }}
                  value="checks"
                ></Tab>
                <Tab
                  label={
                    <Chip
                      label={t('common:documents')}
                      sx={{ fontSize: 16 }}
                      variant={selectedTab === 'documents' ? 'sliderBlack' : 'sliderWhite'}
                    />
                  }
                  value="documents"
                />
                {
                  <Tab
                    label={
                      <Chip
                        label={t('common:relatedPersons')}
                        sx={{ fontSize: 16 }}
                        variant={selectedTab === 'relatedPersons' ? 'sliderBlack' : 'sliderWhite'}
                      />
                    }
                    value="relatedPersons"
                  />
                }
                <Tab
                  label={
                    <Chip
                      label={t('common:relatedCompanies')}
                      sx={{ fontSize: 16 }}
                      variant={selectedTab === 'relatedCompanies' ? 'sliderBlack' : 'sliderWhite'}
                    />
                  }
                  value="relatedCompanies"
                />
                <Tab
                  label={
                    <Chip
                      label={t('common:assignedUsers')}
                      sx={{ fontSize: 16 }}
                      variant={selectedTab === 'assignedUsers' ? 'sliderBlack' : 'sliderWhite'}
                    />
                  }
                  value="assignedUsers"
                />
                <Tab
                  label={
                    <Chip
                      label={t('common:comments')}
                      sx={{ fontSize: 16 }}
                      variant={selectedTab === 'comments' ? 'sliderBlack' : 'sliderWhite'}
                    />
                  }
                  value="comments"
                />
              </CustomTabList>
              <Box>
                <TabPanel value="checks">
                  <Box className="kyb-checks-flex">
                    {checks?.map((mainChecks, indexMain) => (
                      <Accordion
                        key={indexMain}
                        expanded={expandedMain[indexMain]}
                        onChange={() => handleChangeExpandedMain(indexMain)}
                      >
                        <AccordionSummary expandIcon={null}>
                          <Box className="accordion-wrapper">
                            <Box className="accordion-status">
                              <Typography variant="title">{t(`common:${mainChecks?.title}`)}</Typography>
                              <Typography variant="subtitle3">{t(`messages:${mainChecks?.subtitle}`)}</Typography>
                            </Box>
                            <Button
                              variant="outlinedBlank"
                              onChange={(e) => {
                                e.stopPropagation()
                                return handleChangeExpandedMain(indexMain)
                              }}
                            >
                              {expandedMain[indexMain] ? (
                                <KeyboardDoubleArrowDownIcon />
                              ) : (
                                <KeyboardDoubleArrowUpIcon />
                              )}
                            </Button>
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                          {mainChecks?.items?.map((check, index) => {
                            const key = `${indexMain}-${index}`
                            return (
                              <Accordion
                                key={key}
                                expanded={expanded[key] || false}
                                onChange={(e) => {
                                  e.stopPropagation()
                                  handleChangeExpanded(indexMain, index)
                                }}
                                variant="medium"
                                slotProps={{ transition: { unmountOnExit: true } }}
                              >
                                <AccordionSummary expandIcon={null}>
                                  <Box className="accordion-wrapper">
                                    <Box className="accordion-status">
                                      <Typography variant="text2">{t(`common:${check.title}`)}</Typography>
                                      <Box>
                                        {kybPerson?.[check?.name] ? (
                                          <Box className="accordion-status-icon">
                                            <Status
                                              type={kybPerson[check.name].status}
                                              noIcon={true}
                                              sizeBig={true}
                                              variant="colorSecondary"
                                            />
                                          </Box>
                                        ) : (
                                          <Box className="accordion-status-icon">
                                            <Status
                                              type={'NOT_STARTED'}
                                              noIcon={true}
                                              sizeBig={true}
                                              variant="colorSecondary"
                                            />
                                          </Box>
                                        )}
                                      </Box>
                                    </Box>
                                    <Button
                                      variant="outlinedBlank"
                                      onChange={(e) => {
                                        e.stopPropagation()
                                        return handleChangeExpanded(index)
                                      }}
                                    >
                                      {expanded[index] ? (
                                        <KeyboardDoubleArrowDownIcon />
                                      ) : (
                                        <KeyboardDoubleArrowUpIcon />
                                      )}
                                    </Button>
                                  </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Box>
                                    {kybPerson?.[check?.name] ? (
                                      <KybCheckDetail name={check.name} value={kybPerson[check.name]} />
                                    ) : (
                                      <Box>
                                        <LoadingButton
                                          size="small"
                                          variant="contained"
                                          color="secondary"
                                          loading={!!createCheckLoading && createCheckArgs?.body?.type === check.key}
                                          onClick={(e) => handleCreateCheck(e, check.key)}
                                        >
                                          {t('common:run')} {camelCaseFormatter(check.name)}
                                        </LoadingButton>
                                      </Box>
                                    )}
                                    <Box className="kyb-close">
                                      {kybPerson?.[check?.name] && (
                                        <Box className="kyb-check-status">
                                          <KybPreviousChecks
                                            kybId={id}
                                            type={check.key}
                                            checks={checks}
                                            countrySegment={countrySegment}
                                          />
                                          <Button
                                            size="small"
                                            fullWidth
                                            onClick={() => {
                                              setOpenModalCheckStatus(!openModalCheckStatus)
                                              setCheckId(kybPerson[check.name]?._id)
                                              setCheckStatus(kybPerson[check.name]?.status)
                                              setCheckType(check.key)
                                            }}
                                            variant="contained"
                                          >
                                            {t('common:changeStatus')}
                                          </Button>
                                          <LoadingButton
                                            size="small"
                                            fullWidth
                                            variant="outlined"
                                            loading={!!createCheckLoading && createCheckArgs?.body?.type === check.key}
                                            onClick={(e) => handleCreateCheck(e, check.key)}
                                          >
                                            {t('common:run')} {camelCaseFormatter(check.name)} {t('common:again')}
                                          </LoadingButton>
                                        </Box>
                                      )}
                                    </Box>
                                  </Box>
                                </AccordionDetails>
                              </Accordion>
                            )
                          })}
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </Box>
                </TabPanel>
                <TabPanel value="documents">
                  <Paper elevation={0}>
                    <Box className="kyb-detail-documents">
                      <Box className="kyb-documents-top">
                        <Typography variant="title">{t('common:documents')}</Typography>
                        <Typography variant="subtitle3">{t('messages:updatePDFRelated')}</Typography>
                      </Box>
                      <Box className="kyb-files-drag">
                        <Typography variant="title2">{t('common:addAFile')}</Typography>
                        <FileUploader
                          handleChange={(file) => handleUploadFile(file)}
                          name="file"
                          label={t('common:fileUploaderLabel')}
                          types={['JPG', 'JPEG', 'PNG', 'GIF', 'PDF', 'DOC', 'CSV']}
                        >
                          <Paper elevation={0} variant="rootDashedTransparent" className="draggable-main">
                            <Box className="draggable-icon"></Box>
                            <Typography variant="title2">{t('common:fileUploaderLabel')}</Typography>
                            <Box>
                              <Typography variant="subtitle4">{t('common:or')} </Typography>
                              <Typography sx={{ textDecoration: 'underline' }} variant="subtitle4" color="secondary">
                                {t('common:browseMedia')}
                              </Typography>
                            </Box>
                          </Paper>
                        </FileUploader>
                        <Typography variant="subtitle4">
                          {t('common:supportedMedia')}: JPG, PNG, GIF, MP4, MPEG, WEBM, MP3, WAV, PDF.
                        </Typography>
                      </Box>
                      <Box className="kyb-file-list-wrapper">
                        <Typography variant="title"> {t('common:uploadedDocuments')}</Typography>
                        <Box className="kyb-file-list">
                          {isProcessingFile || isProcessingFileDelete ? (
                            <ProcessingSpinner message={t('common:processing')} />
                          ) : (
                            <Box>
                              {Array.isArray(kybPerson?.files) && kybPerson.files.length > 0 && (
                                <Box className="kyb-files">
                                  {typeof kybPerson?.files[0] === 'string' &&
                                    kybPerson.files.map((file, index) => {
                                      const splittedName = file.split('/kyb/')[1].split('_')
                                      const fileName = splittedName[splittedName.length - 2]?.replaceAll('%20', ' ')
                                      return (
                                        <Paper elevation={0} variant="colorPrimary" className="kyb-file" key={index}>
                                          <Box className="kyb-file-description">
                                            <Box className="color-button">
                                              <Typography variant="subtitle1" sx={{ color: '#FF2B1B' }}>
                                                DOC
                                              </Typography>
                                            </Box>
                                            <Typography variant="text2" title={fileName}>
                                              {fileName}
                                            </Typography>
                                          </Box>
                                          <Box className="kyb-file-icons">
                                            <Button
                                              variant="outlined"
                                              size="small"
                                              onClick={() => window.open(file, '_blank')}
                                            >
                                              {t('common:view')}
                                            </Button>
                                            <Button variant="outlinedBlank" onClick={(e) => handleDeleteFile(e, file)}>
                                              <DeleteOutlineIcon />
                                            </Button>
                                          </Box>
                                        </Paper>
                                      )
                                    })}
                                </Box>
                              )}
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Paper>
                </TabPanel>
                <TabPanel value="relatedPersons">
                  <Paper elevation={0}>
                    <TabComponent
                      entityType={'PERSON'}
                      subjectType="relatedPerson"
                      selectedTab={selectedTab}
                      setSelectedTab={setSelectedTab}
                      documentNumber={kybPerson?.documentNumber}
                      handleFetch={handleFetch}
                    />
                  </Paper>
                </TabPanel>
                <TabPanel value="relatedCompanies">
                  <Paper elevation={0}>
                    <TabComponent
                      entityType={'PERSON'}
                      subjectType="relatedCompany"
                      selectedTab={selectedTab}
                      setSelectedTab={setSelectedTab}
                      documentNumber={kybPerson?.documentNumber}
                      handleFetch={handleFetch}
                    />
                  </Paper>
                </TabPanel>
                <TabPanel value="assignedUsers">
                  <Paper elevation={0}>
                    <Box className="kyb-card">
                      <Box className="card-top">
                        <Typography variant="title">{t('common:assignedUsers')}</Typography>
                        {!isEditingAssign ? (
                          <Button
                            onClick={() => {
                              setIsEditingAssign(true)
                            }}
                            size="small"
                            variant="contained"
                          >
                            {t('common:addUser')}
                          </Button>
                        ) : (
                          <Fab variant="close" onClick={() => setIsEditingAssign(false)}>
                            <CloseOutlinedIcon />
                          </Fab>
                        )}
                      </Box>
                      <DatatableAssignUsers
                        rows={kybPerson?.assignedUsers || []}
                        toAssign={availableUsers}
                        isEditing={isEditingAssign}
                        updateAssigns={updateAssigns}
                        setIsEditingAssign={setIsEditingAssign}
                      />
                    </Box>
                  </Paper>
                </TabPanel>
                <TabPanel value="comments">
                  <Paper elevation={0}>
                    <Box className="kyb-card">
                      <Box className="card-top">
                        <Typography variant="title">{t('common:comments')}</Typography>
                      </Box>
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>{t('common:email')}</TableCell>
                              <TableCell>{t('common:createdAt')}</TableCell>
                              <TableCell>{t('common:comment')}</TableCell>
                            </TableRow>
                          </TableHead>
                          {Array.isArray(kybPerson?.comments) && kybPerson?.comments?.length > 0 ? (
                            kybPerson.comments.map((row, index) => (
                              <TableBody key={index}>
                                <TableRow>
                                  <TableCell>
                                    <Box>{row.email ?? '---'}</Box>
                                  </TableCell>
                                  <TableCell>
                                    {row.createdAt ? buildTimeStringFromTimestamp(row.createdAt) : '---'}
                                  </TableCell>
                                  <TableCell>{row.description ?? '---'}</TableCell>
                                </TableRow>
                              </TableBody>
                            ))
                          ) : (
                            <Box className="table-custom-overlay">
                              <CustomNoRowsOverlay />
                            </Box>
                          )}
                        </Table>
                      </TableContainer>
                    </Box>
                  </Paper>
                </TabPanel>
              </Box>
            </TabContext>
          </Box>
        </Box>

        <KybModal title="" open={showDownloadModal} setOpen={setShowDownloadModal}>
          <KybToPdf kyb={kybPerson} />
        </KybModal>

        <KybModal
          title={t('common:postComment')}
          subtitle={t('messages:commentDesc')}
          open={modalNewCommentOpen}
          setOpen={setModalNewCommentOpen}
        >
          <KybNewCommentModal onSubmit={postNewComment} />
        </KybModal>

        <KybModal
          title={`${t('common:edit')} KYB ${t('common:status')}`}
          open={editStatusModal}
          setOpen={setEditStatusModal}
        >
          <KybEditStatusModal defaultValue={kybPerson?.status || 'NOT_STARTED'} onSubmit={handleUpdate} />
        </KybModal>

        <KybModal
          title={`${t('common:edit')} ${t('common:check')} ${t('common:status')}`}
          open={openModalCheckStatus}
          setOpen={setOpenModalCheckStatus}
        >
          <KybEditCheckStatusModal defaultValue={checkStatus} checkId={checkId} onSubmit={handleCheckUpdate} />
        </KybModal>
      </Box>
    </Box>
  )
}

export default KybDetailPerson


export default function paymentMethodRenderer(paymentMethod) {
    switch(paymentMethod) {
        case 'CARD':
            return '💳 By Card';
        case 'GENERIC_BANK_ACCOUNT':
            return '🏦 By Bank Transfer';
        case 'IBAN':
            return '🏦 By IBAN Transfer';
        case 'ACH':
            return '🏦 By ACH Transfer';
        case 'SWIFT':
            return '🏦 By SWIFT Transfer';
        case 'WALLET':
            return '👛 By Crypto Wallet';
        default:
            break;
    }
    return '';
}
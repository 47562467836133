import './aml.scss'

import {
  Box,
  Button,
  MenuItem,
  Select,
  Checkbox,
  FormControlLabel,
  TextField,
  ListItemText,
  Chip,
  Paper,
  Typography,
  InputLabel,
  Link,
} from '@mui/material'
import OutlinedInput from '@mui/material/OutlinedInput'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { countryDictionary } from '../../components/common/flag/countries'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { RenderTooltip } from '../../components/utilities/RenderTooltip'
import { TableChip } from '../../components/utilities/TableChip'
import ProcessingSpinner from '../../components/common/alertFetchSpinner/processingSpinner'

const TYPES = [
  'sanction',
  'warning',
  'fitness-probity',
  'pep',
  'pep-class-1',
  'pep-class-2',
  'pep-class-3',
  'pep-class-4',
  'adverse-media',
  'adverse-media-financial-crime',
  'adverse-media-violent-crime',
  'adverse-media-sexual-crime',
  'adverse-media-terrorism',
  'adverse-media-fraud',
  'adverse-media-narcotics',
  'adverse-media-general',
  'adverse-media-v2-property',
  'adverse-media-v2-financial-aml-cft',
  'adverse-media-v2-fraud-linked',
  'adverse-media-v2-narcotics-aml-cft',
  'adverse-media-v2-violence-aml-cft',
  'adverse-media-v2-terrorism',
  'adverse-media-v2-cybercrime',
  'adverse-media-v2-general-aml-cft',
  'adverse-media-v2-regulatory',
  'adverse-media-v2-financial-difficulty',
  'adverse-media-v2-violence-non-aml-cft',
  'adverse-media-v2-other-financial',
  'adverse-media-v2-other-serious',
  'adverse-media-v2-other-minor',
]

const DEFAULT_TYPES = [
  'sanction',
  'warning',
  'fitness-probity',
  'pep',
  'pep-class-1',
  'pep-class-2',
  'pep-class-3',
  'pep-class-4',
]

const ENTITY_TYPES = ['person', 'company', 'organisation', 'vessel', 'aircraft']

const AmlManualCheck = () => {
  const { accessToken } = useSelector((state) => state.login)
  const [isFetching, setIsFetching] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const navigate = useNavigate()
  const { t } = useTranslation(['common', 'messages'])
  const [search_term, setSearch_term] = useState('')
  const [fuzziness, setFuzziness] = useState(1)
  const [limit, setLimit] = useState(10)
  const [country_codes, setCountry_codes] = useState([])
  const [exact_match, setExact_match] = useState(true)
  const [types, setTypes] = useState(DEFAULT_TYPES)
  const [birth_year, setBirth_year] = useState(1990)
  const [remove_deceased, setRemove_deceased] = useState(1)
  const [entity_type, setEntity_type] = useState('')
  const [finish, setFinish] = useState(false)
  const [result, setResult] = useState({})

  const cantSend = () => {
    return search_term === '' || (birth_year !== '' && birth_year < 1600)
  }

  const handleSubmit = () => {
    setIsFetching(true)
    setFinish(false)
    setErrorMessage('')

    if (!process.env.REACT_APP_IS_DEMO) {
      const body = {}
      Object.assign(body, { search_term })
      if (fuzziness !== '') Object.assign(body, { fuzziness })
      if (limit !== '') Object.assign(body, { limit })
      Object.assign(body, { exact_match })

      const filters = {}
      if (types.length !== 0) Object.assign(filters, { types })
      if (birth_year !== '') Object.assign(filters, { birth_year })
      Object.assign(filters, { remove_deceased })
      if (entity_type !== '') Object.assign(filters, { entity_type })
      if (country_codes.length !== 0) Object.assign(filters, { country_codes })

      if (Object.keys(filters).length !== 0) Object.assign(body, { filters })

      let url = `${process.env.REACT_APP_BASEURL}/aml/complyV1`

      const options = {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...body, share_url: 1 }),
      }

      fetch(process.env.REACT_APP_IS_DEMO ? '../../data/amlDetails.json' : url, options)
        .then((res) => res.json())
        .then((res) => {
          setIsFetching(false)
          if (res.success && res.data) {
            setResult(res.data)
            setFinish(true)
          } else {
            throw new Error(
              res.data?.responseBody?.message
                ? res.data?.responseBody?.message
                : res.data?.info
                ? res.data?.info
                : res.message
                ? res.message
                : 'Unexpected',
            )
          }
        })
        .catch((err) => {
          console.error('[DOWNLOAD REPORT ERROR] --> ', err)
          setIsFetching(false)
          setErrorMessage(err.message ? err.message : t('messages:unexpectedErrorValues'))
        })
    } else {
      const options = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }

      fetch('../data/amlDetails.json', options)
        .then((res) => res.json())
        .then((res) => {
          setIsFetching(false)
          setResult(res.data)
          setFinish(true)
        })
        .catch((err) => {
          console.error('[DOWNLOAD REPORT ERROR] --> ', err)
          setIsFetching(false)
          setErrorMessage(err.message ? err.message : t('messages:unexpectedErrorValues'))
        })
    }
  }

  const handleReset = () => {
    setSearch_term('')
    setFuzziness(1)
    setLimit(10)
    setCountry_codes([])
    setExact_match(true)
    setTypes(DEFAULT_TYPES)
    setBirth_year(1990)
    setRemove_deceased(1)
    setEntity_type(false)

    setFinish(false)
    setErrorMessage('')
  }

  return (
    <Box>
      <Box className="component-title-wrapper">
        <Box className="component-title">
          <Typography variant="h2">
            {t('common:aml')} {t('common:manualCheck')}
          </Typography>
        </Box>
        <Typography variant="subtitle3">
          {/* Loren ipsum dolor sit amet Loren ipsum dolor sit amet Loren  */}
        </Typography>
      </Box>

      <Box className="aml-manual-wrapper-grid">
        <Box className="aml-manual-divider">
          <Paper
            elevation={0}
            variant="rootOutlined"
            sx={{ marginRight: '1rem', padding: '1.5rem 1rem 1.5rem 1.5rem' }}
          >
            <Box className="aml-manual-overflow">
              <Box sx={{ marginBottom: '1.5rem' }}>
                <Typography variant="title">{t('common:information')}</Typography>
              </Box>

              <Box className="aml-manual-wrapper">
                <Box className="aml-manual-flex">
                  <Box className="aml-manual-input">
                    <Box className="required">
                      <InputLabel>{t('common:fullName')}</InputLabel>
                      <Typography variant="subtitle3">({t('common:required')})</Typography>
                      <RenderTooltip title={'A string representing the name of the entity.'} />
                    </Box>

                    <TextField
                      required
                      size="small"
                      value={search_term}
                      onChange={(event) => setSearch_term(event.target.value)}
                      placeholder={t('common:searchTerm')}
                    />
                  </Box>
                  <Box className="aml-manual-input">
                    <InputLabel>{t('common:birthYear')}</InputLabel>
                    <TextField
                      size="small"
                      type="number"
                      value={birth_year}
                      onChange={(event) =>
                        setBirth_year(
                          event.target.value && parseInt(event.target.value) >= 1
                            ? parseInt(event.target.value, 10)
                            : '',
                        )
                      }
                      placeholder={t('common:birthYear')}
                    />
                  </Box>
                </Box>

                <Box className="aml-manual-input">
                  <Box className="required">
                    <InputLabel>{t('common:fuzziness')}</InputLabel>
                    <RenderTooltip
                      title={
                        'Determines how closely the returned results must match the supplied name. Overridden by exact_match.'
                      }
                    />
                  </Box>
                  <TextField
                    inputProps={{
                      min: 0.0,
                      max: 1.0,
                      step: '0.01',
                    }}
                    fullWidth
                    type="number"
                    size="small"
                    required
                    value={fuzziness}
                    onChange={(e) =>
                      setFuzziness(
                        e.target.value && parseFloat(e.target.value) >= 0 && parseFloat(e.target.value) <= 1
                          ? parseFloat(e.target.value)
                          : '',
                      )
                    }
                  />
                </Box>

                <Box className="aml-manual-input">
                  <Box className="required">
                    <InputLabel>{t('common:limit')}</InputLabel>
                    <RenderTooltip title={'Limit for search results. Max and default value is 100.'} />
                  </Box>
                  <TextField
                    size="small"
                    type="number"
                    value={limit}
                    required
                    onChange={(event) =>
                      setLimit(
                        event.target.value && parseInt(event.target.value) >= 1 && parseInt(event.target.value) <= 100
                          ? parseInt(event.target.value, 10)
                          : '',
                      )
                    }
                    placeholder={t('common:limit')}
                  />
                </Box>
                <Box className="aml-manual-input">
                  <Box className="required">
                    <InputLabel>{t('common:countryCodes')}</InputLabel>
                    <RenderTooltip
                      title={
                        'Array of ISO 3166-1 alpha-2 strings. applies to: Entity of type PEP only. Entity of type PEP with Adverse Media. Entity that has 1 country value or more (only one has to match).'
                      }
                    />
                  </Box>
                  <Select
                    required
                    multiple
                    size="small"
                    displayEmpty
                    value={country_codes}
                    onChange={(e) => setCountry_codes(e.target.value)}
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return (
                          <Typography variant="subtitle3">{`${t('common:select')} ${t(
                            'common:countryCodes',
                          )}`}</Typography>
                        )
                      }
                      return (
                        <Box className="aml-manual-wrap">
                          {selected.map((option, index) => (
                            <Chip variant="outlined" label={option} key={index} />
                          ))}
                        </Box>
                      )
                    }}
                  >
                    {Object.keys(countryDictionary).map((key) => (
                      <MenuItem key={key} value={key}>
                        {countryDictionary[key]}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                <Box className="aml-manual-input">
                  <InputLabel>{t('common:entityTypes')}</InputLabel>
                  <Select
                    size="small"
                    displayEmpty
                    value={entity_type}
                    onChange={(e) => setEntity_type(e.target.value)}
                    input={<OutlinedInput />}
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return (
                          <Typography variant="subtitle3">{`${t('common:select')} ${t(
                            'common:entityTypes',
                          )}`}</Typography>
                        )
                      }
                      return selected
                    }}
                  >
                    {ENTITY_TYPES.map((name) => (
                      <MenuItem key={name} value={name}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                <Box className="aml-manual-input">
                  <Box className="required">
                    <InputLabel>{t('common:types')}</InputLabel>
                    <RenderTooltip title={'Types of lists to be checked.'} />
                  </Box>
                  <Select
                    required
                    multiple
                    rows={4}
                    size="small"
                    value={types}
                    onChange={(e) => setTypes(e.target.value)}
                    renderValue={(selected) => (
                      <Box className="aml-manual-wrap">
                        {selected.map((option, index) => (
                          <Chip variant="outlined" label={option} key={index} />
                        ))}
                      </Box>
                    )}
                  >
                    {TYPES.map((type) => {
                      return (
                        <MenuItem key={type} value={type}>
                          <ListItemText primary={type} />
                        </MenuItem>
                      )
                    })}
                  </Select>
                </Box>

                <Box className="aml-manual-flex">
                  <Paper elevation={0}>
                    <Box className="aml-manual-input">
                      <Box className="required">
                        <InputLabel>{t('common:exactMatch')}</InputLabel>
                        <RenderTooltip title={'Exact match for the search_term.'} />
                      </Box>

                      <FormControlLabel
                        control={<Checkbox checked={exact_match} />}
                        label={<Typography variant="h5">Click to enable/disalbe exact match search</Typography>}
                        onChange={() => setExact_match(!exact_match)}
                      />
                    </Box>
                  </Paper>
                  <Paper elevation={0}>
                    <Box className="aml-manual-input">
                      <Box className="required">
                        <InputLabel>{t('common:removeDeceased')}</InputLabel>
                        <RenderTooltip title={'Removes deceased people from search results.'} />
                      </Box>

                      <FormControlLabel
                        control={<Checkbox checked={remove_deceased === 1} />}
                        label={<Typography variant="h5">Click to enable/disalbe deceased search</Typography>}
                        onChange={() => setRemove_deceased(remove_deceased === 1 ? 0 : 1)}
                      />
                    </Box>
                  </Paper>
                </Box>
              </Box>
            </Box>
          </Paper>

          <Paper elevation={0} variant="rootOutlined" className="aml-manual-results-wrapper" sx={{ padding: '1.5rem' }}>
            <Box sx={{ marginBottom: '1.5rem' }}>
              <Typography variant="title">
                {t('common:check')} {t('common:results')}
              </Typography>
            </Box>

            {result && finish && errorMessage === '' ? (
              <Box className="aml-manual-results">
                <Paper elevation={0}>
                  <Box className="aml-manual-sub-results">
                    <Box className="aml-manual-grid">
                      <Typography variant="subtitle3">{t('common:referenceId')}</Typography>
                      {result.reference_id ? (
                        <Link href={`/aml/${result.reference_id}`}>
                          <Typography variant="number" color="action">
                            {result.reference_id}
                          </Typography>
                        </Link>
                      ) : (
                        '---'
                      )}
                    </Box>
                    <Box className="aml-manual-grid">
                      <Typography variant="subtitle3">{t('common:search')} ID</Typography>
                      <Typography variant="title2">{result.search_id ? result.search_id : '---'}</Typography>
                    </Box>
                    <Box className="aml-manual-grid">
                      <Typography variant="subtitle3">{t('common:searchTerm')}</Typography>
                      <Typography variant="title2">{result.search_term ? result.search_term : '---'}</Typography>
                    </Box>
                    <Box className="aml-manual-grid">
                      <Typography variant="subtitle3">{t('common:matchStatus')}</Typography>
                      <Typography variant="title2">
                        {result.match_status ? <TableChip noIcon={true} action={result.match_status} /> : '---'}
                      </Typography>
                    </Box>
                    <Box className="aml-manual-grid">
                      <Typography variant="subtitle3">{t('common:totalMatches')}</Typography>
                      <Typography variant="title2">{result.total_matches ? result.total_matches : '---'}</Typography>
                    </Box>
                    <Box className="aml-manual-grid">
                      <Typography variant="subtitle3">{t('common:totalHits')}</Typography>
                      <Typography variant="title2">
                        {result.total_hits || result.total_hits === 0 ? result.total_hits : '---'}
                      </Typography>
                    </Box>
                    <Box className="aml-manual-grid">
                      <Typography variant="subtitle3">{t('common:totalBlacklistHits')}</Typography>
                      <Typography variant="title2">
                        {result.total_blacklist_hits || result.total_blacklist_hits === 0
                          ? result.total_blacklist_hits
                          : '---'}
                      </Typography>
                    </Box>
                  </Box>
                </Paper>

                <Paper elevation={0}>
                  <Box className="aml-manual-grid">
                    <Typography variant="subtitle3">{t('common:sourceInfo')}</Typography>
                    {result.share_url ? (
                      <Link onClick={() => window.open(result.share_url, '_blank', 'rel=noopener noreferrer')}>
                        <Typography color="action" variant="title2">
                          {t('common:viewSource')}
                        </Typography>
                      </Link>
                    ) : (
                      '---'
                    )}
                  </Box>
                </Paper>
              </Box>
            ) : (
              <Paper elevation={0} className="aml-manual-legend">
                <Typography variant="legend" textAlign="center">
                  {t('messages:manualCheckPreview')}
                </Typography>
              </Paper>
            )}
          </Paper>
        </Box>

        <Box className="modal-filter-buttons">
          <Button
            onClick={() => {
              handleReset()
            }}
            size="small"
            variant="outlinedGrey"
          >
            {t('common:reset')}
          </Button>
          <Button size="small" variant="contained" type="submit" disabled={cantSend()} onClick={(e) => handleSubmit()}>
            {t('common:startCheck')}
          </Button>
        </Box>
      </Box>

      {isFetching && <ProcessingSpinner message={t('common:processing')} />}
      {errorMessage !== '' && (
        <Box className="security-error">
          <Button size="small" variant="outlined" color="error">
            {errorMessage}
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default AmlManualCheck

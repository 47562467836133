import { createSlice } from '@reduxjs/toolkit'

export const riskMatrixSlice = createSlice({
  name: 'riskMatrix',
  initialState: {
    riskMatrixs: [],
    filters: {
      name: '',
      status: '',
      viewMode: true,
      page: 0,
      limit: 50,
      offset: 0,
    },
  },
  reducers: {
    updateRiskMatrix: (state, action) => {
      state.riskMatrixs = action.payload
    },
    performLogoutRiskMatrix: (state) => {
      state.riskMatrixs = []
    },
    updateRiskMatrixFilters: (state, filters) => {
      state.filters = filters.payload
    },
  },
})

export const { updateRiskMatrix, performLogoutRiskMatrix, updateRiskMatrixFilters } = riskMatrixSlice.actions
export default riskMatrixSlice.reducer

import { Box, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import DatatableOppositionControl from './DatatableOppositionControl'
import Spinner from '../../../components/common/spinner/spinner'
import { useTranslation } from 'react-i18next'
import { useRequestsToAoR } from '../../pages-hooks/useRequestsToAoR'
import { updateRequestsToAoRFilters } from '../../../features/requestsToAoRSlice/requestsToAoRSlice'
import './oppositionControl.scss'

const OppositionControl = () => {
  const { requestId: specificRequest } = useParams()
  const navigate = useNavigate()
  const currentFilters = useSelector((state) => state.requestsToAoR.filters)
  const [requestId, setRequestId] = useState(specificRequest ? specificRequest : '')
  const [requestBy, setRequestBy] = useState('')
  const [selectedDate, setSelectedDate] = useState(null)
  const [toDate, setToDate] = useState(null)
  const [requestStatus, setRequestStatus] = useState('')

  const [isLoading, setIsLoading] = useState(true)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(50)

  const dispatch = useDispatch()
  const { t } = useTranslation(['common']) // TODO: acomodar uso en varias partes

  useRequestsToAoR(setIsLoading)

  const handleResetButton = () => {
    setRequestId('')
    setRequestBy('')
    setSelectedDate(null)
    setRequestStatus('')
    setIsLoading(true)

    dispatch(
      updateRequestsToAoRFilters({
        requestId: null,
        requestedBy: null,
        evaluatedBy: null,
        fromDate: null,
        toDate: '',
        page: 0,
        limit: 50,
        offset: 0,
      }),
    )

    navigate('/admin/oppositionControl')
  }

  const handleSearchButton = () => {
    if (requestId || requestBy || requestBy || selectedDate) {
      let filters = { ...currentFilters }
      if (requestId) filters = { ...filters, requestId }
      if (requestBy) filters = { ...filters, requestedBy: requestBy }
      if (selectedDate)
        filters = { ...filters, fromDate: selectedDate?.toISOString(), toDate: new Date(toDate)?.toISOString() }
      setIsLoading(true)
      dispatch(updateRequestsToAoRFilters(filters))
    }
  }

  const handleTablePagination = () => {
    setIsLoading(true)

    return dispatch(
      updateRequestsToAoRFilters({
        ...currentFilters,
        page,
        limit: rowsPerPage,
        offset: rowsPerPage * page,
        status: requestStatus,
      }),
    )
  }

  useEffect(() => {
    handleTablePagination()
  }, [rowsPerPage, page])

  useEffect(() => {
    if (specificRequest) {
      let filters = { ...currentFilters }
      filters['requestId'] = specificRequest
      setIsLoading(true)
      dispatch(updateRequestsToAoRFilters(filters))
    }
  }, [])

  useEffect(() => {
    if (selectedDate) {
      const formattedDate = new Date(selectedDate)
      setToDate(selectedDate ? formattedDate.setDate(formattedDate.getDate() + 1) : null)
    }
  }, [selectedDate])

  return (
    <Box>
      <Box className={`${isLoading && 'spinner-transition'} filter`}>
        {isLoading && <Spinner noTransform />}
        <Box className="component-title-wrapper">
          <Box className="component-title">
            <Typography variant="h2">{t('common:oppositionControl')}</Typography>
          </Box>
          <Typography variant="subtitle3">
            {/* Loren ipsum dolor sit amet Loren ipsum dolor sit amet Loren  */}
          </Typography>
        </Box>

        <DatatableOppositionControl
          setIsLoading={setIsLoading}
          requestStatus={requestStatus}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          specificRequest={specificRequest}
          handleSearchButton={handleSearchButton}
          handleResetButton={handleResetButton}
          requestId={requestId}
          setRequestId={setRequestId}
          setRequestStatus={setRequestStatus}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          requestBy={requestBy}
          setRequestBy={setRequestBy}
        />
      </Box>
    </Box>
  )
}

export default OppositionControl

import '../../home/home.scss'
import './transaction.scss'
import { Autocomplete, Box, Grid, Paper, Typography } from '@mui/material'
import { CURRENCIES, TX_METHODS, TX_TYPES } from './transaction-constants'
import { TextField } from '@mui/material'
import { darken, lighten, styled } from '@mui/system'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import Button from '@mui/material/Button'
import Datatable from '../../../components/common/datatable/Datatable'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateTransactionsFilters } from '../../../features/transactions/transactionSlice'
import { useTransactions } from '../../pages-hooks/useTransactions'
import DataExportModal from '../../../components/common/dataExport/DataExportModal'
import {
  kytTransactionDataMapper,
  kytTransactionDataMapperForPDF,
} from '../../../components/common/dataExport/dataMappers/kytTransactionDataMapper'
import Spinner from '../../../components/common/spinner/spinner'
import { useTranslation } from 'react-i18next'
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined'
import dayjs from 'dayjs'
import { FilterModal } from '../../../components/utilities/FilterModal'

const GroupHeader = styled('Box')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  textAlign: 'center',
  fontWeight: 700,
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === 'light'
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}))

const GroupItems = styled('ul')({
  padding: 0,
})

const Transaction = () => {
  const { emailClient } = useSelector((state) => state.login)
  const queryState = useSelector((state) => state.transaction.filters)
  const [isLoading, setIsLoading] = useState(true)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(50)
  const [transactionId, setTransactionId] = useState(queryState.transactionId)
  const [transactionType, setTransactionType] = useState(queryState.transactionType)
  const [originCurrency, setOriginCurrency] = useState(queryState.originCurrency)
  const [destinationCurrency, setDestinationCurrency] = useState(queryState.destinationCurrency)
  const [originMethod, setOriginMethod] = useState(queryState.originMethod)
  const [destinationMethod, setDestinationMethod] = useState(queryState.destinationMethod)
  const [ruleId, setRuleId] = useState(queryState.ruleId)
  const [ruleInstanceId, setRuleInstanceId] = useState(queryState.ruleInstanceId)
  const [maxRuleAction, setMaxRuleAction] = useState(queryState.ruleInstanceId)
  const [transactionKey, setTransactionKey] = useState(queryState.transactionKey)
  const [transactionValue, setTransactionValue] = useState(queryState.transactionValue)
  const [documentNumber, setDocumentNumber] = useState(queryState.documentNumber)
  const [firstName, setFirstName] = useState(queryState.firstName)
  const [lastName, setLastName] = useState(queryState.lastName)
  const [legalName, setLegalName] = useState(queryState.legalName)
  const [fromDate, setFromDate] = useState(null)
  const [fromTime, setFromTime] = useState('00:00')
  const [toDate, setToDate] = useState(null)
  const [toTime, setToTime] = useState('23:59')
  const [modalOpen, setModalOpen] = useState(false)
  const dispatch = useDispatch()
  const { t } = useTranslation(['common'])
  const [showDownloadModal, setShowDownloadModal] = useState(false)
  const allTransactions = useTransactions(emailClient, setIsLoading)
  const { activeServices } = useSelector((state) => state.login)
  const languageState = useSelector((state) => state.language.lang)

  useEffect(() => {
    dayjs.locale(languageState)
  }, [languageState])

  const handleResetButton = () => {
    if (transactionId !== '') setTransactionId('')
    if (transactionType) setTransactionType(null)
    if (fromDate) setFromDate(null)
    if (toDate) setToDate(null)
    if (originCurrency) setOriginCurrency(null)
    if (destinationCurrency) setDestinationCurrency(null)
    if (originMethod) setOriginMethod(null)
    if (destinationMethod) setDestinationMethod(null)
    if (ruleId !== '') setRuleId('')
    if (ruleInstanceId !== '') setRuleInstanceId('')
    if (maxRuleAction !== '') setMaxRuleAction('')
    if (transactionKey !== '') setTransactionKey('')
    if (transactionValue !== '') setTransactionValue('')
    if (documentNumber !== '') setDocumentNumber('')
    if (firstName !== '') setFirstName('')
    if (lastName !== '') setLastName('')
    if (legalName !== '') setLegalName('')
    setPage(0)
    setIsLoading(true)

    return dispatch(
      updateTransactionsFilters({
        viewMode: true,
        page: 0,
        limit: 50,
        offset: 0,
        transactionId: '',
        transactionType: null,
        fromDate: null,
        toDate: null,
        originCurrency: null,
        destinationCurrency: null,
        originMethod: null,
        destinationMethod: null,
        ruleId: '',
        ruleInstanceId: '',
        maxRuleAction: '',
        transactionKey: '',
        transactionValue: '',
        documentNumber: '',
        firstName: '',
        lastName: '',
        legalName: '',
      }),
    )
  }

  const handleDispatch = () => {
    setIsLoading(true)
    return dispatch(
      updateTransactionsFilters({
        viewMode: true,
        limit: rowsPerPage,
        offset: rowsPerPage * page,
        page,
        transactionId,
        transactionType,
        fromDate: fromDate?.toISOString(),
        toDate: toDate?.toISOString(),
        originCurrency,
        destinationCurrency,
        originMethod,
        destinationMethod,
        ruleId,
        ruleInstanceId,
        maxRuleAction,
        transactionKey,
        transactionValue,
        documentNumber,
        firstName,
        lastName,
        legalName,
      }),
    )
  }

  useEffect(() => {
    handleDispatch()
  }, [rowsPerPage, page])

  useEffect(() => {
    if (!fromDate && toDate) {
      const formattedDate = new Date(toDate)
      formattedDate?.setHours(0)
      formattedDate?.setMinutes(0)
      formattedDate?.setSeconds(0)
      setFromDate(formattedDate)
    }
  }, [toDate])

  useEffect(() => {
    if (fromDate && !toDate) {
      const formattedDate = new Date(fromDate)
      formattedDate?.setHours(23)
      formattedDate?.setMinutes(59)
      formattedDate?.setSeconds(59)
      setToDate(formattedDate ?? null)
    }
  }, [fromDate])

  const handleFromTimeChange = (time) => {
    let newDate = new Date(fromDate)
    const [hs, mins] = time.split(':')
    newDate.setHours(hs)
    newDate.setMinutes(mins)
    setFromTime(time)
    setFromDate(newDate)
  }

  const handleToTimeChange = (time) => {
    let newDate = new Date(toDate)
    const [hs, mins] = time.split(':')
    newDate.setHours(hs)
    newDate.setMinutes(mins)
    setToTime(time)
    setToDate(newDate)
  }

  return (
    <Box>
      <Box className={`${isLoading && 'spinner-transition'} filter`}>
        {isLoading && <Spinner noTransform />}

        <Box className="component-title-wrapper">
          <Box className="component-title">
            <Typography variant="h2">{t('common:transactions')}</Typography>
            {activeServices.includes('reportsitem') && (
              <Button
                variant="outlined"
                disabled={!activeServices.includes('reportsitem') || !allTransactions?.data?.length}
                onClick={() => setShowDownloadModal(true)}
                endIcon={<IosShareOutlinedIcon sx={{ fill: 'var(--lime-04)' }} />}
              >
                <Typography variant="title2">{t('common:exportReport')}</Typography>
              </Button>
            )}
          </Box>
          <Typography variant="subtitle3">
            {/* Loren ipsum dolor sit amet Loren ipsum dolor sit amet Loren  */}
          </Typography>
        </Box>
        <Paper elevation={0}>
          <Datatable
            setIsLoading={setIsLoading}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            setModalOpen={setModalOpen}
          />
        </Paper>
      </Box>

      <DataExportModal
        show={showDownloadModal}
        setShow={setShowDownloadModal}
        data={allTransactions.data}
        fileName={'transactions'}
        dataMapper={kytTransactionDataMapper}
        dataMapperForPDF={kytTransactionDataMapperForPDF}
        typeDataExport={'transactions'}
      />

      <FilterModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        handleResetButton={handleResetButton}
        setPage={setPage}
        handleDispatch={handleDispatch}
      >
        <Box className="modal-box-filters">
          {/* TSX DETAILS */}
          <Box className="modal-box">
            <Typography variant="subtitle3">{t('common:by')} ID:</Typography>
            <TextField
              required
              value={transactionId}
              onChange={(event) => setTransactionId(event.target.value)}
              size="small"
              placeholder={`${t('common:insert')} ID`}
            />
          </Box>

          <Box className="modal-box">
            <Typography variant="subtitle3">
              {t('common:by')} {t('common:initialDate')}:
            </Typography>
            <LocalizationProvider
              size="small"
              dateAdapter={AdapterDayjs}
              adapterLocale={languageState === 'es-ES' ? 'es' : languageState === 'pt-BR' ? 'pt-br' : languageState}
            >
              <Box className="modal-flex">
                <DatePicker
                  size="small"
                  value={dayjs(fromDate)}
                  onChange={(newValue) => {
                    setFromDate(newValue ? newValue['$d'] : null)
                  }}
                  renderInput={(params) => <TextField size="small" {...params} />}
                />
                <TextField
                  disabled={!fromDate}
                  type="time"
                  step="1"
                  value={fromTime}
                  onChange={(e) => handleFromTimeChange(e.target.value)}
                />
              </Box>
            </LocalizationProvider>
          </Box>

          <Box className="modal-box">
            <Typography variant="subtitle3">
              {t('common:by')} {t('common:endDate')}:
            </Typography>
            <LocalizationProvider
              size="small"
              dateAdapter={AdapterDayjs}
              adapterLocale={languageState === 'es-ES' ? 'es' : languageState === 'pt-BR' ? 'pt-br' : languageState}
            >
              <Box className="modal-flex">
                <DatePicker
                  size="small"
                  value={dayjs(toDate)}
                  onChange={(newValue) => {
                    let aux = newValue ? new Date(newValue['$d']) : null
                    aux?.setHours(23)
                    aux?.setMinutes(59)
                    aux?.setSeconds(59)
                    setToDate(aux)
                  }}
                  renderInput={(params) => <TextField size="small" {...params} />}
                />

                <TextField
                  disabled={!toDate}
                  type="time"
                  step="1"
                  value={toTime}
                  onChange={(e) => handleToTimeChange(e.target.value)}
                />
              </Box>
            </LocalizationProvider>
          </Box>

          <Box className="modal-box">
            <Typography variant="subtitle3">
              {t('common:by')} {t('common:status')}:
            </Typography>
            <Grid
              container
              spacing={1}
              sx={{ maxWidth: '28rem', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}
            >
              {TX_TYPES?.map((item, index) => (
                <Grid item key={index}>
                  <Button
                    onClick={() => {
                      return setTransactionType(item)
                    }}
                    variant={transactionType === item ? 'action' : 'label'}
                  >
                    {t(`common:${item}`)}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Box>

          <Box className="modal-box">
            <Typography variant="subtitle3">
              {t('common:by')} {t('common:originCurrency')}:
            </Typography>

            <Autocomplete
              value={originCurrency}
              onChange={(event, value) => {
                if (value) {
                  setOriginCurrency(value)
                } else setOriginCurrency(null)
              }}
              size="small"
              groupBy={(option) => option.type}
              getOptionLabel={(option) => option.aka}
              options={CURRENCIES}
              renderInput={(params) => (
                <TextField
                  variant="outlined"
                  {...params}
                  placeholder={`${t('common:select')} ${t('common:originCurrency')}`}
                />
              )}
              renderGroup={(params) => (
                <li key={params.children.length}>
                  <GroupHeader key="group-ori">{params.group}</GroupHeader>
                  <GroupItems key="options-ori">{params.children}</GroupItems>
                </li>
              )}
            />
          </Box>

          <Box className="modal-box">
            <Typography variant="subtitle3">
              {t('common:by')} {t('common:originMethod')}:
            </Typography>

            <Autocomplete
              size="small"
              value={originMethod}
              onChange={(event, value) => {
                if (value) {
                  setOriginMethod(value)
                } else setOriginMethod(null)
              }}
              disablePortal
              id="combo-box-demo"
              options={TX_METHODS}
              renderInput={(params) => (
                <TextField
                  variant="outlined"
                  {...params}
                  placeholder={`${t('common:select')} ${t('common:originMethod')}`}
                />
              )}
            />
          </Box>

          <Box className="modal-box">
            <Typography variant="subtitle3">
              {t('common:by')} {t('common:destinationCurrency')}:
            </Typography>

            <Autocomplete
              value={destinationCurrency}
              onChange={(event, value) => {
                if (value) {
                  setDestinationCurrency(value)
                } else setDestinationCurrency(null)
              }}
              size="small"
              groupBy={(option) => option.type}
              getOptionLabel={(option) => option.aka}
              options={CURRENCIES}
              renderInput={(params) => (
                <TextField
                  variant="outlined"
                  {...params}
                  placeholder={`${t('common:select')} ${t('common:destinationCurrency')}`}
                />
              )}
              renderGroup={(params) => (
                <li key={params.children.length}>
                  <GroupHeader key="group-dest">{params.group}</GroupHeader>
                  <GroupItems key="options-dest">{params.children}</GroupItems>
                </li>
              )}
            />
          </Box>

          <Box className="modal-box">
            <Typography variant="subtitle3">
              {t('common:by')} {t('common:destinationMethod')}:
            </Typography>
            <Autocomplete
              size="small"
              value={destinationMethod}
              onChange={(event, value) => {
                if (value) {
                  setDestinationMethod(value)
                } else setDestinationMethod(null)
              }}
              disablePortal
              id="combo-box-demo"
              options={TX_METHODS}
              renderInput={(params) => (
                <TextField
                  variant="outlined"
                  {...params}
                  placeholder={`${t('common:select')} ${t('common:destinationMethod')}`}
                />
              )}
            />
          </Box>

          {/* RULES INFO */}
          <Box className="modal-box">
            <Typography variant="subtitle3">{t('common:ruleId')}:</Typography>

            <TextField
              variant="outlined"
              size="small"
              value={ruleId}
              onChange={(event) => setRuleId(event.target.value)}
              placeholder={`${t('common:insert')} ${t('common:ruleId')}`}
            />
          </Box>

          <Box className="modal-box">
            <Typography variant="subtitle3">
              {t('common:by')} {t('common:ruleInstanceId')}:
            </Typography>

            <TextField
              variant="outlined"
              size="small"
              value={ruleInstanceId}
              onChange={(event) => setRuleInstanceId(event.target.value)}
              placeholder={`${t('common:insert')} ${t('common:ruleInstanceId')}`}
            />
          </Box>

          <Box className="modal-box">
            <Typography variant="subtitle3">
              {t('common:by')} {t('common:maxRuleAction')}:
            </Typography>
            <Autocomplete
              size="small"
              value={maxRuleAction}
              onChange={(event, value) => {
                if (value) {
                  setMaxRuleAction(value)
                } else {
                  setMaxRuleAction(null)
                }
              }}
              disablePortal
              id="combo-box-demo"
              options={['FLAG', 'SUSPEND', 'BLOCK']}
              renderInput={(params) => (
                <TextField
                  variant="outlined"
                  {...params}
                  placeholder={`${t('common:select')} ${t('common:maxRuleAction')}`}
                />
              )}
            />
          </Box>

          {/* USER INFO */}
          <Box className="modal-box">
            <Typography variant="subtitle3">
              {t('common:user')} {t('common:documentNumber')}:
            </Typography>

            <TextField
              variant="outlined"
              size="small"
              value={documentNumber}
              onChange={(event) => setDocumentNumber(event.target.value)}
              placeholder={`${t('common:insert')} ${t('common:documentNumber')}`}
            />
          </Box>
          <Box className="modal-box">
            <Typography variant="subtitle3">
              {t('common:by')} {t('common:legalName')} ({t('common:business')}):
            </Typography>

            <TextField
              variant="outlined"
              size="small"
              value={legalName}
              onChange={(event) => setLegalName(event.target.value)}
              placeholder={`${t('common:insert')} ${t('common:legalName')}`}
            />
          </Box>

          {/* TAGS */}
          <Box className="modal-box">
            <Typography variant="subtitle3">
              {t('common:by')} {t('common:transactionTagValue')}:
            </Typography>
            <Autocomplete
              size="small"
              value={transactionValue}
              onChange={(event, value) => {
                if (value) {
                  setTransactionValue(value)
                } else {
                  setTransactionValue(null)
                }
              }}
              disablePortal
              id="combo-box-demo"
              options={allTransactions?.unique?.uniqueValues ? allTransactions.unique.uniqueValues : []}
              renderInput={(params) => (
                <TextField
                  variant="outlined"
                  {...params}
                  placeholder={`${t('common:select')} ${t('common:transactionTagValue')}`}
                />
              )}
            />
          </Box>
        </Box>
      </FilterModal>
    </Box>
  )
}

export default Transaction

import { buildTimeStringFromTimestamp } from '../../time/timeHelper'

export const dashboardUserReportDataMapperForPDF = (data) => {
  const header = [
    'Action Owner',
    'User',
    'Action',
    'Status',
    'Date',
    'Previous Record Roles',
    'Previous Record Status',
    'Previous Record Created At',
    'Previous Record UpdatedAt',
    'Previous Record Failed Attempts',
    'Previous Record Roles',
    'Previous Record Status',
    'Previous Record Created At',
    'Previous Record UpdatedAt',
    'Previous Record Failed Attempts',
  ]
  const parsedData = data.map((report) => {
    return [
      report.emailUser,
      report.activityDetails?.userABM,
      report.activityDetails?.abmAction ? report.activityDetails?.abmAction : '---',
      report.activityDetails?.actionResult ? report.activityDetails?.actionResult : '---',
      buildTimeStringFromTimestamp(report.createdAt),

      report.activityDetails?.previousRecord
        ? report.activityDetails?.previousRecord?.roles.map((role) => role.concat(' - '))
        : '---',
      report.activityDetails?.previousRecord ? report.activityDetails?.previousRecord?.status : '---',
      report.activityDetails?.previousRecord
        ? buildTimeStringFromTimestamp(report.activityDetails?.previousRecord?.createdAt)
        : '---',
      report.activityDetails?.previousRecord
        ? buildTimeStringFromTimestamp(report.activityDetails?.previousRecord?.updatedAt)
        : '---',
      report.activityDetails?.previousRecord ? report.activityDetails?.previousRecord?.failedAttempts : '---',

      report.activityDetails?.newRecord
        ? report.activityDetails?.newRecord.roles.map((role) => role.concat(' - '))
        : '---',
      report.activityDetails?.newRecord ? report.activityDetails?.newRecord.status : '---',
      report.activityDetails?.newRecord
        ? buildTimeStringFromTimestamp(report.activityDetails?.newRecord?.createdAt)
        : '---',
      report.activityDetails?.newRecord
        ? buildTimeStringFromTimestamp(report.activityDetails?.newRecord?.updatedAt)
        : '---',
      report.activityDetails?.newRecord ? report.activityDetails?.newRecord?.failedAttempts : '---',
    ]
  })

  return {
    columns: header,
    body: parsedData,
  }
}

export const dashboardUserReportDataMapper = (data) => {
  return data.map((report) => {
    return {
      ActionOwner: report.emailUser,
      User: report.activityDetails.userABM,
      Action: report.activityDetails.abmAction ? report.activityDetails.abmAction : '---',
      Status: report.activityDetails.actionResult ? report.activityDetails.actionResult : '---',
      Date: buildTimeStringFromTimestamp(report.createdAt),

      PreviousRecordRoles: report.activityDetails.previousRecord
        ? report.activityDetails.previousRecord.roles.map((role) => role.concat(' - '))
        : '---',
      PreviousRecordStatus: report.activityDetails.previousRecord
        ? report.activityDetails.previousRecord.status
        : '---',
      PreviousRecordCreatedAt: report.activityDetails.previousRecord
        ? buildTimeStringFromTimestamp(report.activityDetails.previousRecord.createdAt)
        : '---',
      PreviousRecordUpdatedAt: report.activityDetails.previousRecord
        ? buildTimeStringFromTimestamp(report.activityDetails.previousRecord.updatedAt)
        : '---',
      PreviousRecordFailedAttempts: report.activityDetails.previousRecord
        ? report.activityDetails.previousRecord.failedAttempts
        : '---',

      NewRecordRoles: report.activityDetails.newRecord
        ? report.activityDetails.newRecord.roles.map((role) => role.concat(' - '))
        : '---',
      NewRecordStatus: report.activityDetails.newRecord ? report.activityDetails.newRecord.status : '---',
      NewRecordCreatedAt: report.activityDetails.newRecord
        ? buildTimeStringFromTimestamp(report.activityDetails.newRecord.createdAt)
        : '---',
      NewRecordUpdatedAt: report.activityDetails.newRecord
        ? buildTimeStringFromTimestamp(report.activityDetails.newRecord.updatedAt)
        : '---',
      NewRecordFailedAttempts: report.activityDetails.newRecord
        ? report.activityDetails.newRecord.failedAttempts
        : '---',
    }
  })
}

import { Box } from '@mui/material'
import CircleIcon from '@mui/icons-material/Circle'
import './riskLevel.scss'
import { useTranslation } from 'react-i18next'

export const RiskLevel = ({ type }) => {
  const { t } = useTranslation(['common'])
  switch (type) {
    case 'VERY_HIGH':
      return (
        <Box style={{ backgroundColor: 'var(--error-light)' }} className="risk-level-icon" color="primary">
          <CircleIcon style={{ color: 'var(--error-dark)' }} className="rl-icon" />
          <p style={{ color: 'var(--error-dark)' }}>{t('common:veryHighRisk')}</p>
        </Box>
      )
    case 'HIGH':
      return (
        <Box style={{ backgroundColor: 'var(--error-light)' }} className="risk-level-icon">
          <CircleIcon style={{ color: 'var(--error-dark)' }} className="rl-icon" />
          <p style={{ color: 'var(--error-dark)' }}>{t('common:highRisk')}</p>
        </Box>
      )
    case 'MEDIUM':
      return (
        <Box style={{ backgroundColor: 'var(--alert-light)' }} className="risk-level-icon">
          <CircleIcon style={{ color: 'var(--alert-dark)' }} className="rl-icon" />
          <p style={{ color: 'var(--alert-dark)' }}>{t('common:mediumRisk')}</p>
        </Box>
      )
    case 'LOW':
      return (
        <Box style={{ backgroundColor: 'var(--success-light)' }} className="risk-level-icon">
          <CircleIcon style={{ color: 'var(--success-dark)' }} className="rl-icon" />
          <p style={{ color: 'var(--success-dark)' }}>{t('common:lowRisk')}</p>
        </Box>
      )
    case 'VERY_LOW':
      return (
        <Box style={{ backgroundColor: 'var(--success-light)' }} className="risk-level-icon">
          <CircleIcon style={{ color: 'var(--success-dark)' }} className="rl-icon" />
          <p style={{ color: 'var(--success-dark)' }}>{t('common:noRisk')}</p>
        </Box>
      )
    case 'NOT_DEFINED':
      return (
        <Box style={{ backgroundColor: 'var(--gray-40)' }} color="secondary" className="risk-level-icon">
          <CircleIcon style={{ color: 'var(--gray-70)' }} className="rl-icon" />
          <p style={{ color: 'var(--gray-70)' }}>{t('common:notDefined')}</p>
        </Box>
      )
    default:
      return
  }
}

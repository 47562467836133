import { useMemo, useState } from "react";
import { Box, Button, Modal, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLazyFindChecksByKybAndTypeQuery } from "../../../services/guenoApi";
import KybCheckDetail from "./KybCheckDetail";
import ProcessingSpinner from "../../../components/common/alertFetchSpinner/processingSpinner";
import './KybPreviousChecks.scss'
import { buildTimeStringFromTimestamp } from "../../../components/common/time/timeHelper";
import ModalTitle from "../../../components/common/Modal/ModalTitle";

const KybPreviousChecks = ({ kybId, type, checks, countrySegment }) => {
  const [findChecks, { isFetching, isLoading, data }] = useLazyFindChecksByKybAndTypeQuery();
  const [current, setCurrent] = useState(0);
  const [modalOpen, setModalOpen] = useState(false)
  const { t } = useTranslation(['common', 'messages'])

  const checkNamesMapByKey = useMemo(() => {
    return checks.reduce((acc, check) => {
      if (Array.isArray(check.items)) {
        check.items.forEach((item) => {
          acc[item.key] = {
            name: item.name,
            title: item.title
          }
        })
      }
      return acc;
    }, {})
  }, [checks]);

  const handleFindChecks = () => {
    setModalOpen(true)
    findChecks({ kybId, type, countrySegment })
  }

  const handleNext = () => {
    setCurrent(prev => prev + 1);
  }

  const handlePrev = () => {
    setCurrent(prev => prev - 1);
  }

  const [hasPrev, hasNext] = useMemo(() => {
    if (Array.isArray(data?.data) && data?.data?.length) {
      return [
        current > 0,
        current < (data?.data?.length - 1)
      ]
    }
    return [false, false];
  }, [data, current]);

  const checkInfo = useMemo(() => checkNamesMapByKey[type], [checkNamesMapByKey, type]);

  const currentItem = useMemo(() => {
    if (data?.data?.length && data?.data[current]) {
      return { ...data?.data?.[current] };
    }
    
    return null;
  }, [data, current]);

  return (
    <>
      <Button
        size="small"
        variant="outlined"
        onClick={handleFindChecks}
        fullWidth
      >
        {t('common:seePreviousChecks')}
      </Button>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        keepMounted={false}
      >
        <Box className="modal">
          <ModalTitle setModalOpen={setModalOpen} title={checkInfo?.title} />

          <Box className="modal-box">
            {(isLoading || isFetching) && (
              <ProcessingSpinner message={t('common:processing')} />
            )}
            {!(isLoading || isFetching) && !!currentItem && checkInfo?.name && (
              <Box className="modal-body">
                <Typography style={{ marginBottom: '1rem' }}>{t('common:creationDate')}: {buildTimeStringFromTimestamp(currentItem.createdAt)}</Typography>
                <KybCheckDetail name={checkInfo.name} value={currentItem} /> 
              </Box>
            )}
            {!data?.data?.length && (
              <Typography>{t('common:noPreviousChecksFound')}</Typography>
            )}
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            {!!data?.data?.length && (
              <>
                <Button
                  size="small"
                  color="secondary"
                  variant="contained"
                  onClick={handlePrev}
                  disabled={!hasPrev}
                >
                  {t('common:prev')}
                </Button>
                <Button
                  size="small"
                  color="secondary"
                  variant="contained"
                  onClick={handleNext}
                  disabled={!hasNext}
                >
                  {t('common:next')}
                </Button>
              </>
            )}
          </Box>
        </Box>
      </Modal>
    </>
  )
}

export default KybPreviousChecks;

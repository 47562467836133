import { useState, useEffect } from 'react'
import ListOfStrings from '../listOfStrings/listOfStrings'
import { Box } from '@mui/material'

const FatherListOfStrings = ({
  propertie,
  ruleParameters,
  setRuleParameters,
  setError,
  errorList,
  setErrorList,
  isEditing,
}) => {
  const [finalParameter, setFinalParameter] = useState({})
  const [change, setChange] = useState(false)

  useEffect(() => {
    if (ruleParameters[propertie] && Object.keys(finalParameter).length === 0) {
      setFinalParameter({ ...ruleParameters[propertie] })
    }
  }, [propertie, ruleParameters])

  const hangleChange = (value) => {
    setFinalParameter(value)
    setChange(true)
  }

  useEffect(() => {
    if (change) {
      let all = { ...ruleParameters }
      if (Object.keys(finalParameter).length !== 0) {
        all[propertie] = finalParameter
      } else {
        delete all[propertie]
      }
      setRuleParameters({ ...all })
      setChange(false)
    }
  }, [change, finalParameter])

  return (
    <Box className="childContainer">
      <ListOfStrings
        isEditing={isEditing}
        propertie={'userIds'}
        ruleParameters={finalParameter}
        setRuleParameters={hangleChange}
        setError={setError}
        errorList={errorList}
        setErrorList={setErrorList}
        defaultListValues={[]}
      />
    </Box>
  )
}

export default FatherListOfStrings

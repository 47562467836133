import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateWebhooks } from '../../features/webhooks/webhooksSlice'

export const useWebhooks = (emailClient, setIsLoading) => {
  const webhooks = useSelector((state) => state.webhooks)
  const queryState = useSelector((state) => state.webhooks?.filters)
  const { limit, offset, fromDate, toDate, page, id } = queryState ? queryState : { ...null }
  const { accessToken } = useSelector((state) => state.login)

  const dispatch = useDispatch()

  function buildUrl(baseUrl) {
    let url = `${baseUrl}?limit=${limit}&page=${page}&offset=${offset}`
    if (fromDate && toDate) url += `&fromDate=${fromDate}&toDate=${toDate}`
    if (id?.length) url += `&id=${id}`
    if (emailClient?.length) url += `&emailClient=${emailClient}`

    return url
  }

  useEffect(() => {
    const baseUrlWebhooks = `${process.env.REACT_APP_BASEURL}/webhooks`
    let url = buildUrl(baseUrlWebhooks)

    fetch(process.env.REACT_APP_IS_DEMO ? '../data/webhooks.json' : url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res) {
          const { data, pagination } = res
          const aux = { data, pagination }
          dispatch(updateWebhooks(aux))
          setIsLoading(false)
        }
      })
      .catch((error) => {
        console.error('[HOOK: useWebhooks] --> ', error)
        setIsLoading(false)
      })
  }, [queryState])

  return webhooks.webhooks
}

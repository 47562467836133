import { buildTimeStringFromTimestamp } from '../../time/timeHelper'

export const kytTransactionDataMapperForPDF = (data) => {
  const header = [
    'Id',
    'Type',
    'State',
    'Date',
    'Origin User ID',
    'Origin Method',
    'Origin Amount',
    'Origin Currency',
    'Origin Country',
    'Destination User ID',
    'Destination Method',
    'Destination Amount',
    'Destination Currency',
    'Destination Country',
  ]
  const parsedData = data.map((tx) => {
    return [
      tx.id,
      tx.transactionType,
      tx.transactionState,
      buildTimeStringFromTimestamp(tx.createdTimestamp),
      tx.originUserId,
      tx.originMethod,
      tx.originAmount,
      tx.originCurrency,
      tx.originCountry,
      tx.destinationUserId,
      tx.destinationMethod,
      tx.destinationAmount,
      tx.destinationCurrency,
      tx.destinationCountry,
    ]
  })

  return {
    columns: header,
    body: parsedData,
  }
}

export const kytTransactionDataMapper = (data) => {
  return data.map((tx) => {
    return {
      Id: tx.id,
      Type: tx.transactionType,
      State: tx.transactionState,
      Date: buildTimeStringFromTimestamp(tx.createdTimestamp),
      OriginUserId: tx.originUserId,
      OriginMethod: tx.originMethod,
      OriginAmount: tx.originAmount,
      OriginCurrency: tx.originCurrency,
      OriginCountry: tx.originCountry,
      DestinationUserId: tx.destinationUserId,
      DestinationMethod: tx.destinationMethod,
      DestinationAmount: tx.destinationAmount,
      DestinationCurrency: tx.destinationCurrency,
      DestinationCountry: tx.destinationCountry,
    }
  })
}

import { useState, useEffect } from 'react';
import './typing_animation.scss';

/**
 * 
 * @param {
 * reportItem: {
 *    key: string,
 *    title: string,
 *    content: {
 *      type: 'text' | 'table',
 *      itemContent: {
 *        headers: string[],
 *        rows: (string | number | boolean)[][]
 *      } | string,
 *      rendered: boolean
 *    }[]
 *  },
 *  onComplete: () => void
 * } props 
 * @returns 
 */
const TypingText = ({reportItem, onComplete}) => {
  const [displayedText, setDisplayedText] = useState('');
  const keyStrokeTime = 25; // Typing speed in milliseconds

  useEffect(() => {
    if (reportItem.itemContent.length > displayedText.length) {
      setTimeout(() => {
        setDisplayedText(reportItem.itemContent.substr(0, displayedText.length + 1));
      }, keyStrokeTime);
    } else if (reportItem.itemContent.length === displayedText.length && !reportItem.rendered) {
      // Call onComplete when all text has been displayed
      onComplete();
    }
  }, [displayedText, reportItem.itemContent, onComplete]);

  return (
    <div className='TypingText__outerContainer'>
      {displayedText}
    </div>
  );
}

export default TypingText;
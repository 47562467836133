import { createSlice } from '@reduxjs/toolkit'

export const govCheckSlice = createSlice({
  name: 'govCheck',
  initialState: {
    govChecks: {},
    govCheckDetail: {},
    filters: {
      page: 0,
      limit: 25,
      offset: 0,
      fromDate: null,
      toDate: '',
      type: '',
      id: '',
      document: '',
    },
  },
  reducers: {
    updategovChecks: (state, action) => {
      state.govChecks = action.payload
    },
    updategovCheckDetail: (state, action) => {
      state.govCheckDetail = action.payload
    },
    updategovCheckFilters: (state, action) => {
      state.filters = action.payload
    },
  },
})

export const { updategovChecks, updategovCheckDetail, updategovCheckFilters } = govCheckSlice.actions
export default govCheckSlice.reducer

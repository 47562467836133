import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateWorkflows } from '../../features/workflows/workflowsSlice'

export const useWorkflows = (setIsLoading, isLoading) => {
  const workflowsState = useSelector((state) => state.workflows)
  const queryState = useSelector((state) => state.workflows.filters)
  const { limit, offset, page, status, name } = queryState ? queryState : { ...null }
  const { accessToken, emailClient } = useSelector((state) => state.login)
  const dispatch = useDispatch()

  useEffect(() => {
    if (isLoading) {
      const baseUrlWorkflows = `${process.env.REACT_APP_BASEURL}/workflows`
      let url = buildUrl(baseUrlWorkflows)

      function buildUrl(baseUrl) {
        let url = `${baseUrl}?emailClient=${emailClient}&limit=${limit}&page=${page}&offset=${offset}`

        if (status?.length) url += `&status=${status}`

        if (name?.length) url += `&name=${name}`

        return url
      }

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      }

      fetch(process.env.REACT_APP_IS_DEMO ? '../../data/riskMatrixs.json' : url, options)
        .then((res) => res.json())
        .then((res) => {
          if (res) {
            const { data, pagination } = res
            const aux = { data, pagination }
            dispatch(updateWorkflows(aux))
            setIsLoading(false)

            if (!res.data?.length) {
              dispatch(updateWorkflows([]))
              setIsLoading(false)
            }
          }
        })
        .catch((error) => {
          console.error('[HOOK: useWorkflows] --> ', error)
          setIsLoading(false)
        })
    }
  }, [isLoading, queryState])

  return workflowsState
}

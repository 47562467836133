import { useState } from 'react'
import { Box, Fab, InputLabel, MenuItem, Modal, Select, TextField, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { useTranslation } from 'react-i18next'
import { rolesTypesShareHolder } from '../constants'

const AddShareholderModal = ({ isOpen, onChangeIsOpen, onSubmit, statusAddSubject }) => {
  const { t } = useTranslation(['common', 'messages'])

  const [shareholderEntity, setShareholderEntity] = useState('PERSON')
  const [shareholderDocumentNumber, setShareholderDocumentNumber] = useState('')
  const [roleTypeShareholder, setRoleTypeShareholder] = useState('')
  const [relationshipShareHolder, setRelationshipShareHolder] = useState('')
  const [isEmptyShareHolder, setIsEmptyShareHolder] = useState(true)
  const { isFetchingModal } = statusAddSubject

  return (
    <Modal
      open={isOpen}
      onClose={() => onChangeIsOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="modal">
        <Box className="modal-top">
          <Box className="modal-titles">
            <Typography variant="title">{t('messages:addNewShareholder')}</Typography>
            <Typography variant="subtitle3">{t('messages:editLater')}</Typography>
          </Box>
          <Fab variant="close" onClick={() => onChangeIsOpen(false)}>
            <CloseOutlinedIcon />
          </Fab>
        </Box>
        <Box className="modal-box">
          <InputLabel>{t('common:documentNumber')}:</InputLabel>
          <TextField
            placeholder={t('messages:insertDocHere')}
            required
            type="text"
            value={shareholderDocumentNumber}
            onChange={(e) => {
              const value = e.target.value.replace(/[^0-9]/g, '')
              setShareholderDocumentNumber(value)
            }}
            onBlur={() => setIsEmptyShareHolder(shareholderDocumentNumber.trim() === '')}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            error={isEmptyShareHolder}
            helperText={isEmptyShareHolder ? t('messages:fieldCannotBeEmpty') : ''}
          />
        </Box>
        <Box className="modal-box">
          <InputLabel>{t('common:entityType')}:</InputLabel>
          <Select
            size="small"
            value={'PERSON'}
            disabled
            onChange={(e) => setShareholderEntity(e.target.value)}
            inputProps={{ 'aria-label': 'Without label' }}
          >
            {['PERSON', 'BUSINESS']?.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
          <Typography variant="subtitle4">{t('messages:shareholderDesc')}</Typography>
        </Box>
        <Box className="modal-box">
          <InputLabel>{t('common:roleType')}</InputLabel>
          <Select
            value={roleTypeShareholder}
            onChange={(e) => setRoleTypeShareholder(e.target.value)}
            placeholder={`${t('common:insert')} ${t('common:roleType')}`}
          >
            {rolesTypesShareHolder.map((roleType) => (
              <MenuItem key={roleType} value={roleType}>
                {t(`messages:kyb.roleType.${roleType}`)}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box className="modal-box">
          <InputLabel>{t('common:relationship')}:</InputLabel>
          <TextField
            size="small"
            value={relationshipShareHolder}
            onChange={(e) => setRelationshipShareHolder(e.target.value)}
            placeholder={`${t('common:insert')} ${t('common:relationship')}`}
          />
        </Box>
        <Box className="modal-button ">
          <LoadingButton
            size="small"
            color="secondary"
            variant="contained"
            loading={isFetchingModal}
            disabled={isEmptyShareHolder}
            onClick={(e) =>
              onSubmit(e, shareholderDocumentNumber, shareholderEntity, roleTypeShareholder, relationshipShareHolder)
            }
          >
            <span>{t('common:save')}</span>
          </LoadingButton>
          <LoadingButton
            size="small"
            color="secondary"
            variant="outlined"
            loading={isFetchingModal}
            onClick={() => onChangeIsOpen(false)}
          >
            <span>{t('common:cancel')}</span>
          </LoadingButton>
        </Box>
      </Box>
    </Modal>
  )
}

export default AddShareholderModal

export const kybStatusEnum = ['APPROVED', 'DENIED', 'IN_PROGRESS', 'NOT_STARTED']

export const kybRiskLevelEnum = ['NOT_DEFINED', 'LOW', 'MEDIUM', 'HIGH', 'VERY_HIGH']

export const kybChecksEnum = {
  BR: [
    'SANCTIONS_CNEP',
    'SANCTIONS_CEIS',
    'JUDICIAL_ADMINISTRATIVE_PROCESSES',
    'OWNERS_LAWSUITS',
    'GOVERNMENT_DEBTORS',
    'DOMAINS',
    'KYC_BUSINESS',
    'NEGATIVE_DEBT_CERTIFICATES',
    'SANCTIONS_CEPIM',
    'SANCTIONS_LENIENCY_AGREEMENTS',
    'NEGATIVE_MEDIA_BUSINESS',
    'SERASA_SCORE',
    'CADE',
    'BLACKLISTS',
    'PROTESTOS_BUSINESS',
  ],
  CO: [
    'fiscalia-co',
    'judicatura-co',
    'procesos-judiciales-co',
    'bdme-co',
    'simit-co',
    'secop-co',
    'identity-business-co',
  ],
}

export const shareholderChecksEnum = {
  BR: [
    'SANCTIONS_CNEP',
    'SANCTIONS_CEIS',
    'REGISTRATION_STATUS',
    'KYC_PERSON',
    'SANCTIONS_CEAF',
    'NEGATIVE_MEDIA_PERSON',
    'PROTESTOS_PERSON',
  ],
  CO: [
    'fiscalia-co',
    'judicatura-co',
    'procesos-judiciales-co',
    'contraloria-co',
    'procuraduria-co',
    'bdme-co',
    'simit-co',
    'registraduria-co',
    'situacion-militar-co',
    'adres-co',
    'sigep-co',
    'experian-hcpn-co',
    'policia-co',
    'cadaveres-co',
    'inpec-co',
    'desaparecidos-co',
    'rnmc-co',
    'inhabilidades-co',
  ],
}

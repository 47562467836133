import './datatable.scss'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box,
  Button,
  Divider,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  Switch,
  TableContainer,
  TablePagination,
  TextField,
  Typography,
} from '@mui/material'
import Spinner from '../spinner/spinner'
import { buildTimeStringFromTimestamp } from '../time/timeHelper'
import { DataGrid } from '@mui/x-data-grid'
import { useWebhooks } from '../../../pages/pages-hooks/useWebhooks'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import { useState } from 'react'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { updateWebhooks } from '../../../features/webhooks/webhooksSlice'
import AlertFetchSpinner from '../alertFetchSpinner/alertFetchSpinner'
import { MenuProps } from '../../utilities/MenuProps'
import { useTranslation } from 'react-i18next'
import { DataGridComponent } from '../datagrid/DataGridComponent'

const DatatableWebhooks = ({
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  setIsLoading,
  setModalOpen,
  setOpenDelete,
  setOpen,
  setEdit,
  setError,
  setErrorMessage,
  setActiveWebhook,
  activeWebhook
}) => {
  const { emailClient, accessToken, activeServices } = useSelector((state) => state.login)
  const webhooks = useWebhooks(emailClient, setIsLoading)
  const queryState = useSelector((state) => state.webhooks.filters)
  const dispatch = useDispatch()
  const [webhookUrl, setWebhookUrl] = useState('')
  const [serviceName, setServiceName] = useState('')
  const [fetchError, setFetchError] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [fetchMessage, setFetchMessage] = useState('')
  const { t } = useTranslation(['common', 'messages'])

  const handleTest = (e, row) => {
    e.preventDefault()

    if (!process.env.REACT_APP_IS_DEMO) {
      const data = {
        emailClient,
        active: true,
        webhookUrl: row.webhookUrl,
        service: row.service,
      }

      const urlWebhooks = `${process.env.REACT_APP_BASEURL}/webhooks/test/${row.id}`
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(data),
      }

      fetch(urlWebhooks, options)
        .then((res) => res.json())
        .then((res) => {
          if (!res.success) throw Error(typeof res.data === 'string' ? res.data : res.message)
          handleClose()
          handleFetch(false, `Webhook ${t('messages:testSuccess')}`)
        })
        .catch((error) => {
          console.error('[TEST WEBHOOK ERROR] --> ', error)
          handleFetch(true, error.message ? error.message : `Webhook ${t('messages:testError')}`)
        })
    } else {
      handleClose()
      handleFetch(false, `Webhook ${t('messages:testSuccess')}`)
    }
  }

  const handleClose = () => {
    setOpen(false)
    setEdit(false)
    setWebhookUrl('')
    setServiceName('')
    setActiveWebhook({ id: null })
    setErrorMessage('')
    setError(false)
  }

  const handleFetch = (error, message) => {
    setIsFetching(true)
    setFetchError(error)
    setFetchMessage(message)
    setTimeout(() => {
      setIsFetching(false)
    }, 3000)
  }

  const handleUpdate = (e, row) => {
    e.preventDefault()
    let data

    if (row) {
      data = {
        active: row.active === true ? false : true,
      }
    } else {
      data = {
        service: serviceName,
        webhookUrl,
      }
    }

    const urlWebhooks = `${process.env.REACT_APP_BASEURL}/webhooks/${row ? row.id : activeWebhook.id}`
    if (!process.env.REACT_APP_IS_DEMO) {
      const options = {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(data),
      }

      fetch(urlWebhooks, options)
        .then((res) => res.json())
        .then((res) => {
          if (!res.success) throw Error(typeof res.data === 'string' ? res.data : res.message)
          handleClose()
          updateWebhookList()
          handleFetch(false, `Webhook ${t('messages:updateSuccess')}`)
        })
        .catch((error) => {
          console.error('[UPDATE WEBHOOK ERROR] --> ', error)
          handleFetch(true, error.message ? error.message : `Webhook ${t('messages:updateError')}`)
        })
    } else {
      handleClose()
      updateWebhookList()
      handleFetch(false, `Webhook ${t('messages:updateSuccess')}`)
    }
  }

  const updateWebhookList = () => {
    const { limit, offset, fromDate, toDate, page, id } = queryState
    const baseUrlWebhooks = `${process.env.REACT_APP_BASEURL}/webhooks`

    function buildUrl(baseUrl) {
      let url = `${baseUrl}?limit=${limit}&page=${page}&offset=${offset}`
      if (fromDate && toDate) url += `&fromDate=${fromDate}&toDate=${toDate}`
      if (id?.length) url += `&id=${id}`
      if (emailClient?.length) url += `&emailClient=${emailClient}`

      return url
    }
    let url = buildUrl(baseUrlWebhooks)

    fetch(process.env.REACT_APP_IS_DEMO ? '../data/webhooks.json' : url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res) {
          const { data, pagination } = res
          const aux = { data, pagination }
          dispatch(updateWebhooks(aux))
        }
      })
      .catch((error) => console.error('[UPDATE: webhooks] --> ', error))
  }

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      renderCell: (params) => {
        return <Typography variant="number">{params.row.id}</Typography>
      },
    },
    {
      field: 'active',
      headerName: t('common:active'),
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <Box className="table-body-cell">
            {activeServices && activeServices.includes('webhooks') && (
              <Switch
                key={params.row.id}
                checked={params.row.active === true ? true : false}
                onChange={(e) => {
                  handleUpdate(e, params.row)
                }}
                disabled={!activeServices || !activeServices.includes('webhooks:id')}
              />
            )}
          </Box>
        )
      },
    },
    {
      field: 'service',
      headerName: t('common:service'),
      renderCell: (params) => {
        return <p>{params.row.service ?? '---'}</p>
      },
    },
    {
      field: 'createdAt',
      headerName: t('common:createdAt'),
      renderCell: (params) => {
        return <Typography variant="number">{buildTimeStringFromTimestamp(params.row.createdAt)}</Typography>
      },
    },
    {
      field: 'updatedAt',
      headerName: t('common:updatedAt'),
      renderCell: (params) => {
        return <Typography variant="number">{buildTimeStringFromTimestamp(params.row.updatedAt)}</Typography>
      },
    },
    {
      field: 'webhookUrl',
      headerName: 'Webhook Url',
      renderCell: (params) => {
        return <p>{params.row.webhookUrl ?? '---'}</p>
      },
    },
    {
      field: 'edit',
      headerName: t('common:edit'),
      hide: !activeServices || !activeServices.includes('webhooks:id'),
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <Button
            variant="outlinedButtonSuccess"
            onClick={() => {
              setOpen(true)
              setEdit(true)
              setWebhookUrl(params.row.webhookUrl)
              setServiceName(params.row.service)
              setActiveWebhook({ id: params.row.id })
            }}
          >
            <EditIcon />
          </Button>
        )
      },
    },
    {
      field: 'delete',
      headerName: t('common:delete'),
      hide: !activeServices || !activeServices.includes('webhooks:id'),
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <Button
            variant="outlinedButtonError"
            onClick={() => {
              setOpenDelete(true)
              setActiveWebhook({
                id: params.row.id,
              })
            }}
          >
            <DeleteIcon />
          </Button>
        )
      },
    },
    {
      field: 'test',
      headerName: t('common:action'),
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <Button onClick={(e) => handleTest(e, params.row)} size="small" variant="label">
            Test Webhook
          </Button>
        )
      },
    },
  ]

  return (
    <Box>
      {isFetching && <AlertFetchSpinner message={fetchMessage} error={fetchError} />}

      <DataGridComponent
        rows={(Array.isArray(webhooks?.data) && webhooks?.data?.length && webhooks?.data) || []}
        columns={columns}
        count={webhooks?.pagination?.totalElements || 0}
        page={page}
        rowsPerPage={rowsPerPage}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        setIsLoading={setIsLoading}
        setModalOpen={setModalOpen}
      />
    </Box>
  )
}

export default DatatableWebhooks

import {
  Box,
  Button,
  InputLabel,
  Modal,
  Switch,
  TableContainer,
  TablePagination,
  TextField,
  Typography,
  Fab,
  Chip,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateUsersAdmin } from '../../../features/usersAdmin/userAdminSlice'
import { EditOutlined, KeyOutlined, DeleteOutlined } from '@mui/icons-material'
import { buildTimeStringFromTimestamp } from '../time/timeHelper'
import { useTranslation } from 'react-i18next'
import AlertFetchSpinner from '../alertFetchSpinner/alertFetchSpinner'
import { DataGridComponent } from '../datagrid/DataGridComponent'

const DatatableUserAdmin = ({
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  setIsLoading,
  setOpen,
  setOpenDelete,
  handleClose,
  setOpenReset,
  setEdit,
  setUserToReset,
  setActiveUser,
  activeUser,
  setModalOpen,
}) => {
  const { accessToken, emailClient, activeServices } = useSelector((state) => state.login)
  const usersAdminState = useSelector((state) => state.userAdmin.users)
  const queryState = useSelector((state) => state.userAdmin.filters)
  const [usersAdmin, setUsersAdmin] = useState({})
  const [roles, setRoles] = useState([])
  const [fetchError, setFetchError] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [fetchMessage, setFetchMessage] = useState('')

  const dispatch = useDispatch()
  const { t } = useTranslation(['common', 'messages'])

  const handleFetch = (error, message) => {
    setIsFetching(true)
    setFetchError(error)
    setFetchMessage(message)
    setTimeout(() => {
      setIsFetching(false)
    }, 3000)
  }

  const handleUpdate = (e, row) => {
    e.preventDefault()
    if (!process.env.REACT_APP_IS_DEMO) {
      let data

      if (row) {
        data = {
          status: row.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE',
        }
      } else {
        data = {
          roles,
        }
      }

      const urlUser = `${process.env.REACT_APP_BASEURL}/user/${row ? row.email : activeUser.email}`
      const options = {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(data),
      }

      fetch(urlUser, options)
        .then((res) => res.json())
        .then((res) => {
          if (!res.success) throw Error(typeof res.data === 'string' ? res.data : res.message)
          handleClose()
          updateUsers()
          handleFetch(false, res.message)
        })
        .catch((error) => {
          console.error('[UPDATE USER ERROR] --> ', error)
          handleFetch(true, error.message ? error.message : `${t('common:user')} ${t('messages:updateError')}`)
        })
    } else {
      handleClose()
      updateUsers()
      handleFetch(false, 'User update success')
    }
  }

  const updateUsers = () => {
    const { viewMode, limit, offset, page, email, roles, status } = queryState
    const baseUrlUsers = `${process.env.REACT_APP_BASEURL}/user/all?emailClient=${emailClient}`
    let urlGetUsersAdmin = buildUrl(baseUrlUsers)

    function buildUrl(baseUrl) {
      let url = `${baseUrl}&viewMode=${viewMode}&limit=${limit}&page=${page}&offset=${offset}`

      if (email?.length) url += `&email=${email}`

      if (status?.length) url += `&status=${status}`

      if (roles?.length) url += `&roles=${roles}`

      return url
    }

    fetch(process.env.REACT_APP_IS_DEMO ? '../data/users.json' : urlGetUsersAdmin, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res) {
          const { data, pagination } = res
          const aux = { data, pagination }
          dispatch(updateUsersAdmin(aux))
        }
      })
      .catch((error) => console.error('[HOOK: useInstanceRules] --> ', error))
  }

  useEffect(() => {
    setUsersAdmin({ ...usersAdminState })
    setIsLoading(false)
  }, [usersAdminState])

  const columns = [
    {
      field: '_id',
      headerName: t('common:userId'),
      renderCell: (params) => {
        return <Typography variant="number">{params.row._id}</Typography>
      },
    },
    {
      field: 'email',
      headerName: 'Email',
    },
    {
      field: 'roles',
      headerName: t('common:roles'),
      renderCell: (params) => {
        const rolesText = params.row.roles
          .filter((a) => a !== 'APIKEYROLE')
          .map((roleTextItem, index) => <Chip variant="transparent" label={roleTextItem} key={index} />)
        return <Box className="user-admin-chip-wrap">{rolesText}</Box>
      },
    },
    {
      field: 'status',
      headerName: t('common:status'),
      renderCell: (params) => {
        return (
          <Box className="user-admin-flex">
            {activeServices && activeServices.includes('user:email') && (
              <Switch
                key={params.row.id}
                checked={params.row.status === 'ACTIVE' ? true : false}
                onChange={(e) => {
                  handleUpdate(e, params.row)
                }}
              />
            )}
            <p>{`${params.row.status[0].toUpperCase()}${params.row.status.slice(1).toLowerCase()}`}</p>
          </Box>
        )
      },
    },
    {
      field: 'createdAt',
      headerName: t('common:createdAt'),
      renderCell: (params) => {
        return (
          <Typography variant="number">{buildTimeStringFromTimestamp(params.row.createdAt).slice(0, -4)}</Typography>
        )
      },
    },
    {
      field: 'updatedAt',
      headerName: t('common:updatedAt'),
      renderCell: (params) => {
        return (
          <Typography variant="number">{buildTimeStringFromTimestamp(params.row.updatedAt).slice(0, -4)}</Typography>
        )
      },
    },
    {
      field: 'actions',
      headerName: t('common:actions'),
      hide: !activeServices,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <Box className="user-admin-flex">
            {activeServices.includes('user:email') && (
              <Fab
                variant="close"
                onClick={() => {
                  setOpen(true)
                  setEdit(true)
                  setRoles(params.row.roles)
                  setActiveUser({ id: params.row._id, email: params.row.email, status: params.row.status })
                }}
              >
                <EditOutlined />
              </Fab>
            )}

            {activeServices.includes('userresetPassword') && (
              <Fab
                variant="close"
                onClick={() => {
                  setOpenReset(true)
                  setUserToReset(params.row.email)
                }}
              >
                <KeyOutlined />
              </Fab>
            )}

            {params.row.email !== params.row.emailClient && activeServices.includes('user:id') && (
              <Fab
                variant="close"
                onClick={() => {
                  setOpenDelete(true)
                  setActiveUser({ id: params.row._id, email: params.row.email, status: params.row.status })
                }}
              >
                <DeleteOutlined />
              </Fab>
            )}
          </Box>
        )
      },
    },
  ]

  return (
    <Box>
      {isFetching && <AlertFetchSpinner message={fetchMessage} error={fetchError} />}

      <DataGridComponent
        rows={(usersAdmin && Array.isArray(usersAdmin?.data) && usersAdmin?.data) || []}
        columns={columns}
        count={usersAdmin?.pagination?.totalElements || 0}
        page={page}
        rowsPerPage={rowsPerPage}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        setIsLoading={setIsLoading}
        setModalOpen={setModalOpen}
      />
    </Box>
  )
}

export default DatatableUserAdmin

import { useNavigate, useParams } from 'react-router-dom'
import './ecommerceRisk.scss'
import React, { useState } from 'react'
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import Spinner from '../../components/common/spinner/spinner'
import { useTranslation } from 'react-i18next'
import CustomNoRowsOverlay from '../../components/common/datagrid/CustomNoRowsOverlay'

const EcommerceRiskDetail = () => {
  const { id } = useParams()
  const [isLoading, setIsLoading] = useState(false)

  const detailsList = require('../../data/ecommerce.json')
  const filtered = detailsList.data.filter((detail) => detail._id === id)
  const ecommerceRiskDetail = filtered[0]

  const navigate = useNavigate()
  const { t } = useTranslation(['common'])

  return (
    <Box className="datatable">
      {isLoading && <Spinner noTransform />}
      <Box className="wrapper">
        {!isLoading && (
          <Box className="user">
            <Box className="check-head">
              <Box className="check-title">
                <h3>Check: {id}</h3>
              </Box>
              <Box></Box>
            </Box>
            {ecommerceRiskDetail && !ecommerceRiskDetail.statusCode && (
              <Box>
                <Box>
                  <h2>
                    {t('common:user')} {t('common:details')}
                  </h2>

                  <Box>
                    <label>{t('common:fullName')}</label>
                    <p>
                      {ecommerceRiskDetail.userDetails?.name?.lastName}{' '}
                      {ecommerceRiskDetail.userDetails?.name?.firstName}
                    </p>
                  </Box>
                  <Box>
                    <label>{t('common:documentNumber')}</label>
                    <p>
                      {ecommerceRiskDetail.userDetails?.documentNumber
                        ? ecommerceRiskDetail.userDetails.documentNumber
                        : '---'}
                    </p>
                  </Box>
                  <Box>
                    <label>{t('common:email')}</label>
                    <p>{ecommerceRiskDetail.userDetails?.email ? ecommerceRiskDetail.userDetails.email : '---'}</p>
                  </Box>
                  <Box>
                    <label>{t('common:phone')}</label>
                    <p>{ecommerceRiskDetail.userDetails?.phone ? ecommerceRiskDetail.userDetails.phone : '---'}</p>
                  </Box>
                  <Box>
                    <label>{t('common:address')}</label>
                    <p>
                      {ecommerceRiskDetail.userDetails?.address
                        ? ecommerceRiskDetail.userDetails.address.firstLine +
                          ' ' +
                          ecommerceRiskDetail.userDetails.address.city +
                          ' ' +
                          ecommerceRiskDetail.userDetails.address.state +
                          ' ' +
                          ecommerceRiskDetail.userDetails.address.postcode +
                          ' ' +
                          ecommerceRiskDetail.userDetails.address.country
                        : '---'}
                    </p>
                  </Box>
                </Box>
                <Box>
                  <h2>
                    {t('common:order')} {t('common:details')}
                  </h2>

                  <Box>
                    <Box>
                      <label>ID</label>
                      <p>{ecommerceRiskDetail.orderDetails?.id ? ecommerceRiskDetail.orderDetails.id : '---'}</p>
                    </Box>
                    <Box>
                      <label>{t('common:amount')}</label>
                      <p>
                        {ecommerceRiskDetail.orderDetails?.amount ? ecommerceRiskDetail.orderDetails.amount : '---'}
                      </p>
                    </Box>
                    <Box>
                      <label>{t('common:currency')}</label>
                      <p>
                        {ecommerceRiskDetail.orderDetails?.currency ? ecommerceRiskDetail.orderDetails.currency : '---'}
                      </p>
                    </Box>
                    <Box>
                      <label>BIN</label>
                      <p>{ecommerceRiskDetail.orderDetails?.bin ? ecommerceRiskDetail.orderDetails.bin : '---'}</p>
                    </Box>
                    <Box>
                      <label>IP</label>
                      <p>{ecommerceRiskDetail.orderDetails?.ip ? ecommerceRiskDetail.orderDetails.ip : '---'}</p>
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <h2>{t('common:alerts')}</h2>

                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className="text-container">{t('common:name')}</TableCell>
                          <TableCell className="text-container">{t('common:description')}</TableCell>
                          <TableCell className="text-container">Recomended Action</TableCell>
                          <TableCell className="text-container">{t('common:type')}</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {Array.isArray(ecommerceRiskDetail?.alerts) && ecommerceRiskDetail?.alerts.length !== 0 ? (
                          ecommerceRiskDetail?.alerts?.map((row, index) => {
                            const back = index % 2 === 0 ? '' : 'gray-back'
                            return (
                              <TableRow className={back} key={index}>
                                <TableCell className="text-container">{row.name}</TableCell>
                                <TableCell className="text-container">{row.description}</TableCell>
                                <TableCell className="text-container">{row.action}</TableCell>
                                <TableCell className="text-container">{row.type}</TableCell>
                              </TableRow>
                            )
                          })
                        ) : (
                          <Box className="table-custom-overlay">
                            <CustomNoRowsOverlay />
                          </Box>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default EcommerceRiskDetail

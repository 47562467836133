import './datatable.scss';
import { useSelector } from 'react-redux';
import { Typography, Box, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DataGridExternalPaymentMethodsComponent } from '../datagrid/DataGridExternalPaymentMethodsComponent';
import { IDS_BY_PAYMENTMETHOD } from '../../../pages/kyt/externalPaymentMethods/externalPaymentMethods-constants';
import RecursiveProperty from '../../utilities/RenderJSON';
import { Link as RouterLink } from 'react-router-dom';

const DatatableExternalPaymentMethods = ({ page, setPage, rowsPerPage, setRowsPerPage, setIsLoading, setModalOpen }) => {
    const data = useSelector(state => state.externalPaymentMethods.data);
    const pagination = useSelector(state => state.externalPaymentMethods.pagination);
    const { t } = useTranslation(['common']);

    const columns = [
        {
            field: 'id',
            headerName: 'id',
            flex: 2,
            renderCell: ({ row }) => {
                const method = row.id?.method || 'NOT_DEFINED';
                const fieldKey = IDS_BY_PAYMENTMETHOD[method];
                const fieldValue = row.id?.[fieldKey] !== undefined ? row.id[fieldKey] : '-';

                return (
                    <Link
                        component={RouterLink}
                        to={`/externalPaymentMethods/${method}/${fieldValue}`}
                        title={fieldValue}
                        state={row.id}
                    >
                        <Typography variant="number">{method === 'NOT_DEFINED' ? method : `${method}(${fieldKey}:${fieldValue})`}</Typography>
                    </Link>
                );
            },
            sortable: false
        },
        {
            field: 'details',
            headerName: t('common:details'),
            flex: 3,
            renderCell: ({ row }) => {
                if (!row.id) {
                    return <></>
                }

                const filteredDetails = Object.keys(row.id)
                    .filter(key => key !== 'method' && key !== IDS_BY_PAYMENTMETHOD[row.id.method])
                    .reduce((obj, key) => {
                        obj[key] = row.id[key];
                        return obj;
                    }, {});

                return (
                    <Box>
                        <RecursiveProperty property={filteredDetails} rootProperty={false} propertyName={t('common:details')} />
                    </Box>
                );
            },
            sortable: false
        },
        {
            field: 'transactions',
            headerName: t('common:transactions'),
            flex: 1,
            renderCell: (params) => {
                return (
                    <Typography variant="number">
                        {params.row.transactions}
                    </Typography>
                );
            },
        },
    ];

    return (
        <DataGridExternalPaymentMethodsComponent
            rows={Array.isArray(data) ? data : []}
            columns={columns}
            count={pagination?.totalElements || 0}
            page={page}
            rowsPerPage={rowsPerPage}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            setIsLoading={setIsLoading}
            setModalOpen={setModalOpen}
        />
    );
};

export default DatatableExternalPaymentMethods;

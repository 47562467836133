import { createTheme } from '@mui/material'
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown'
import CustomNoRowsOverlay from './components/common/datagrid/CustomNoRowsOverlay'

export const dsTheme = createTheme({
  palette: {
    background: {
      default: '#FCFFFD',
    },
    action: {
      main: '#19db55',
    },
    primary: {
      main: '#00c43d',
    },
    secondary: {
      main: '#19DB55',
    },
    tertiary: {
      main: '#66766B',
    },
    disabled: {
      main: 'hsl(236, 7%, 61%)',
    },
    error: {
      main: '#ff2b1b',
    },
    text: {
      primary: '#020D06',
    },
    toggle: {
      main: '#F4F8F6',
    },
  },
  drawer: {
    backgroundColor: '#ECEFED',
  },
  typography: {
    root: {
      fontFamily: 'Roboto, sans-serif',
    },
    text: {
      fontSize: '1rem',
    },
    text2: {
      fontSize: '1rem',
      fontWeight: '600',
    },
    text3: {
      fontSize: '0.75rem',
      fontWeight: '500',
    },
    text4: {
      fontSize: '1.25rem',
      fontWeight: '500',
    },
    h2: {
      fontSize: '2.5rem',
      fontWeight: '500',
    },
    h3: {
      fontSize: '2rem',
      fontWeight: '500',
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: '500',
    },
    h5: {
      fontSize: '0.875rem',
      fontWeight: '400',
    },
    h6: {
      fontSize: '0.75rem',
      fontWeight: '600',
    },
    title: {
      fontSize: '1.25rem',
      fontWeight: '600',
    },
    title2: {
      fontSize: '0.875rem',
      fontWeight: '600',
    },
    title3: {
      fontSize: '1rem',
      fontWeight: '400',
      color: '#66766B',
    },
    title4: {
      fontSize: '1.25rem',
      fontWeight: '400',
    },
    subtitle1: {
      fontSize: '0.75rem',
      fontWeight: '600',
      color: '#66766B',
    },
    subtitle2: {
      fontSize: '0.875rem',
      fontWeight: '600',
      color: '#66766B',
    },
    subtitle3: {
      fontSize: '0.875rem',
      fontWeight: '400',
      color: '#66766B',
    },
    subtitle4: {
      fontSize: '0.75rem',
      fontWeight: '400',
      color: '#66766B',
    },
    subtitle5: {
      fontSize: '1.25rem',
      fontWeight: '400',
      color: '#66766B',
    },
    legend: {
      fontSize: '1rem',
      fontWeight: '600',
      color: '#66766B',
    },
    number: {
      fontFamily: 'Roboto Mono, monospace',
      fontSize: '0.75rem',
      lineHeight: '1.25rem',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },

  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '1rem!important',
          backgroundColor: '#F4F8F6',
          padding: '1.5rem',
          '&.MuiPopover-paper': {
            padding: '0px',
          },
          '&.MuiPickersPopper-paper': {
            padding: '0px',
          },
          '&.MuiAccordion-root': {
            padding: '0px',
            border: 'none',
            borderRadius: '0.75rem!important',
          },
          '&.MuiDrawer-paper': {
            padding: '1rem!important',
          },
          '& ::-webkit-scrollbar-thumb': {
            background: '#c9d2cc !important',
          },
        },
        rootTransparent: {
          backgroundColor: '#F4F8F6',
          padding: '1rem 1.5rem',
          borderRadius: '.75rem',
          border: '1px solid #1C282026',
        },
        rootOutlined: {
          backgroundColor: 'transparent',
          padding: '1rem 1.5rem',
          borderRadius: '.75rem',
          border: '1px solid #1C282026',
        },
        rootDashed: {
          backgroundColor: '#FCFFFD',
          padding: '1.5rem 1rem',
          borderRadius: '1rem',
          border: '1px dashed #1C282026',
        },
        rootDashedTransparent: {
          backgroundColor: 'transparent',
          padding: '1.5rem 1rem',
          borderRadius: '1rem',
          border: '1px dashed #1C282026',
        },
        colorPrimary: {
          backgroundColor: '#ECEFED',
          padding: '.75rem 1rem',
          borderRadius: '1rem',
        },
        colorPrimarySmall: {
          backgroundColor: '#ECEFED',
          padding: '.5rem .75rem',
          borderRadius: '1rem',
        },
        colorSecondary: {
          backgroundColor: '#FCFFFD',
          padding: '1.5rem 1rem',
          borderRadius: '1rem',
        },
        colorTertiary: {
          backgroundColor: '#FCFFFD',
          padding: '1rem 1.5rem',
          borderRadius: '.75rem',
          border: '1px solid #1C282026',
        },
        colorDotted: {
          padding: '1rem 1.5rem',
          background: 'radial-gradient(circle at 1px 1px, #b1b2b8 calc(1px - 1px), #FCFFFD 1px) 0 0 / 22px 22px',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          lineHeight: 'initial',
          fontSize: '0.75rem',
          fontWeight: '500',
          display: 'flex',
          alignItems: 'center',
          gap: '.25rem',
          padding: '0.25rem 0.75rem',
          width: 'fit-content',
          '& .MuiChip-icon': {
            margin: '0',
            color: '#020D06',
            height: '1rem',
            width: '1rem',
          },
          '& .MuiChip-label': {
            padding: '0 .25rem',
          },
        },
        colorPrimary: {
          backgroundColor: '#FCFFFD',
        },
        colorSecondary: {
          backgroundColor: '#3A423D',
        },
        sliderSelected: {
          backgroundColor: '#19DB55',
          color: '#020D06',
          borderRadius: '100px',
          padding: '0.75rem 1.5rem',
          minHeight: '44px',
          fontSize: '1rem',
          textTransform: 'math-auto',
          '&:hover': {
            cursor: 'pointer',
          },
        },
        sliderTransparent: {
          backgroundColor: 'transparent',
          color: '#020D06',
          fontSize: '1rem',
          fontWeight: '400',
          textTransform: 'math-auto',
          '&:hover': {
            cursor: 'pointer',
          },
        },
        sliderSelectedPurple: {
          backgroundColor: '#7E65D5',
          color: '#E4DDFF',
          fontWeight: '500',
          borderRadius: '0.75rem',
          padding: '0.75rem',
          minHeight: '44px',
          fontSize: '1rem',
          textTransform: 'math-auto',
          '&:hover': {
            cursor: 'pointer',
          },
          '& .MuiChip-icon': {
            margin: '0',
            color: '#E4DDFF',
            height: '1.25rem',
            width: '1.25rem',
          },
        },
        slider: {
          backgroundColor: 'transparent',
          border: '1px solid #19DB55',
          color: '#19DB55',
          borderRadius: '100px',
          padding: '12px 24px 12px 24px',
          minHeight: '44px',
          fontSize: '1rem',
          textTransform: 'math-auto',
          fontWeight: '400',
          '& .MuiChip-label': {
            padding: '0',
          },
          '&:hover': {
            cursor: 'pointer',
          },
        },
        sliderWhite: {
          backgroundColor: '#FCFFFD',
          border: '1px solid #C9D2CC',
          color: '#020D06',
          borderRadius: '1.25rem',
          padding: '0.15rem 0.75rem',
          width: '100%',
          minHeight: '2.25rem',
          fontSize: '1.15rem',
          textTransform: 'math-auto',
          '&:hover': {
            cursor: 'pointer',
          },
        },
        sliderBlack: {
          backgroundColor: '#020D06',
          color: '#FBFFFC',
          borderRadius: '1.25rem',
          padding: '0.15rem 0.75rem',
          width: '100%',
          minHeight: '2.25rem',
          fontSize: '1.15rem!important',
          textTransform: 'math-auto',
          '&:hover': {
            cursor: 'pointer',
          },
          '&.Mui-selected': {
            color: '#19DB55',
            padding: '0.5rem 1.5rem',
            display: 'flex',
            alignItems: 'start',
            fontWeight: '500',
          },
        },
        transparent: {
          backgroundColor: 'transparent',
          border: '1px solid #C9D2CC',
          fontSize: '0.75rem',
          fontWeight: '400',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '1rem',
          background: '#FCFFFD',
          color: '#66766B',
          fontSize: '0.875rem',
          padding: '0.5rem 1rem',
          '&.Mui-disabled': {
            background: '#ECEFED',
          },
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          lineHeight: 'normal',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          minWidth: '2rem',
          display: 'flex',
          alignItems: 'center',
          padding: '.5rem 1.5rem',
          borderRadius: '0.75rem',
          lineHeight: 'normal',
          fontSize: '1rem',
          textTransform: 'math-auto',
          height: '2.25rem',

          '&.Mui-disabled': {
            backgroundColor: '#C9D2CC',
            color: '#66766B',
            border: 'none',
          },
        },
        contained: {
          backgroundColor: '#00C43D',
          color: '#020D06',
          '&:hover': {
            backgroundColor: '#58ED87',
          },
        },
        outlinedChip: {
          fontSize: '0.75rem!important',
          textTransform: 'capitalize',
          height: '1.5rem!important',
          padding: '.25rem .5rem',
          backgroundColor: 'transparent',
          color: '#00b85f',
          border: '1px solid #00b85f',
          '&:hover': {
            border: '1px solid #58ED87',
            color: '#58ED87',
          },
        },
        outlinedChipError: {
          fontSize: '0.75rem!important',
          textTransform: 'capitalize',
          height: '1.5rem!important',
          padding: '.25rem .5rem',
          backgroundColor: 'transparent',
          border: '1px solid #FF2B1B',
          color: '#ff2b1b',
          '&:hover': {
            border: '1px solid #ff2b1b',
            color: '#ff2b1b',
          },
        },
        outlined: {
          borderWidth: '2px',
          '&:hover': {
            borderWidth: '2px',
          },
        },
        outlinedGrey: {
          backgroundColor: 'transparent',
          color: '#020D06',
          border: '2px solid #C9D2CC',
          '&:hover': {
            border: '2px solid #C9D2CC',
            color: '#020D06',
          },
        },
        outlinedBlank: {
          padding: '0.2rem',
          borderRadius: '0.5rem',
          border: '1.5px solid rgba(28, 40, 32, 0.15)',
          background: '#fcfffd',
          width: '2rem !important',
          height: '2rem !important',
          color: '#66766B',
          '&:hover': {
            border: '2px solid #C9D2CC',
            color: '#020D06',
          },
        },
        label: {
          border: '1px solid #C9D2CC',
          padding: '0.5rem 1rem',
          fontSize: '0.875rem',
          borderRadius: '6.25rem',
          fontWeight: '400',
        },
        action: {
          backgroundColor: '#020D06',
          padding: '0.5rem 1rem',
          border: '1px solid #020D06',
          color: '#FBFFFC',
          fontSize: '0.875rem',
          borderRadius: '1.5rem',
          '&:hover': {
            backgroundColor: '#020D06',
            border: '1px solid #020D06',
            color: '#FBFFFC',
          },
        },
        link: {
          fontSize: '1rem',
          color: '#020D06',
          textDecoration: 'underline',
          fontWeight: '600',
          padding: '.5rem 1rem',
          '&:hover': {
            textDecoration: 'underline',
          },
        },
        outlinedButtonError: {
          padding: '0.2rem',
          borderRadius: '0.5rem',
          border: '1.5px solid #ffd7d4',
          width: '2rem !important',
          height: '2rem !important',
          background: '#fcfffd',
          svg: {
            fill: '#ff2b1b',
          },
        },
        outlinedButtonSuccess: {
          padding: '0.2rem',
          borderRadius: '0.5rem',
          border: '1.5px solid #cdf9e7',
          width: '2rem !important',
          height: '2rem !important',
          background: '#fcfffd',
          svg: {
            fill: '#00b85f',
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontWeight: '600',
          color: '#19DB55',
          '&:hover': {
            cursor: 'pointer',
          },
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          borderRadius: '0.5rem',
          padding: '0.5rem',
          boxShadow: 'none',
          backgroundColor: '#F4F8F6',
          color: '#66766B',
        },
        close: {
          color: '#020D06',
          backgroundColor: '#FCFFFD',
          border: '1.5px solid rgba(28, 40, 32, 0.15)',
          height: '2rem',
          width: '2.3rem',
          padding: '0.4rem',
        },
        action: {
          color: '#020D06',
          backgroundColor: '#C9D2CC',
          height: 'auto',
          width: 'auto',
          padding: '0.4rem',
        },
      },
    },
    MuiDataGrid: {
      defaultProps: {
        components: {
          NoRowsOverlay: CustomNoRowsOverlay,
        },
        slots: {
          noRowsOverlay: CustomNoRowsOverlay,
        },
      },
      styleOverrides: {
        root: {
          fontSize: '0.75rem',
          border: 'none',
          '&.MuiDataGrid-row': {
            '&:hover': {
              backgroundColor: '#f0f0f0',
            },
            height: '3rem',
          },
          '& .MuiDataGrid-columnHeaderTitleContainer': {
            height: '48px',
            alignItems: 'center',
          },
          '& .MuiDataGrid-columnHeaderDraggableContainer': {
            height: '48px',
            alignItems: 'center',
          },
          '& .MuiDataGrid-columnsContainer': {
            background: '#010310',
          },
          '& .MuiDataGrid-columnHeaders': {
            color: '#66766B',
            borderBottom: '1px solid rgba(28, 40, 32, 0.15)',
          },
          '& .MuiDataGrid-container--top [role=row]': {
            background: '#F4F8F6',
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            height: '18px',
            lineHeight: '18px',
          },
          '& .MuiDataGrid-cell': {
            borderBottom: '1px solid rgba(28, 40, 32, 0.05)',
            minHeight: '3rem !important',
            display: 'flex',
            alignItems: 'center',
            whiteSpace: 'pre-wrap !important',
          },
          '& ::-webkit-scrollbar-thumb': {
            background: '#c9d2cc !important',
          },
          '.MuiDataGrid-overlayWrapper': {
            height: 'auto !important',
          },
          '.MuiDataGrid-overlayWrapperInner': {
            height: 'auto !important',
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          '& .MuiSelect-icon': {
            right: '5px',
          },
        },
        actions: {
          margin: '0',
        },
        select: {
          border: '1px solid #C9D2CC',
          borderRadius: '24px',
          margin: '0px',
          width: '3rem',
          display: 'flex',
          justifyContent: 'center',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '& input:-webkit-autofill, & input:-webkit-autofill:hover, & input:-webkit-autofill:focus, & input:-webkit-autofill:active':
            {
              transition: 'background-color 5000s ease-in-out 0s',
              backgroundColor: 'transparent !important',
              '-webkit-text-fill-color': 'inherit !important',
            },
        },
        input: {
          '&.MuiOutlinedInput-input': {
            padding: '0px',
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            background: '#ECEFED',
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          '& .MuiTabPanel': {
            padding: '0px',
          },
          '& .MuiTabs-flexContainer': {
            minWidth: '80px',
            gap: '.5rem',
          },
        },
        indicator: {
          backgroundColor: '#19DB55',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          margin: '0',
          padding: '0',
          fontWeight: '400',
          textTransform: 'capitalize',
          minWidth: '0',
          '&.Mui-selected': {
            color: '#19DB55',
            padding: '0',
            display: 'flex',
            alignItems: 'start',
            fontWeight: '500',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#020D06',
          fontSize: '0.875rem',
          fontWeight: '600',
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          '& .MuiTableCell-root': {
            color: '#66766B',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '0.75rem 0.5rem',
        },
      },
    },
    MuiAccordion: {
      variants: [
        {
          props: { variant: 'light' },
          style: {
            backgroundColor: '#FCFFFD',
          },
        },
        {
          props: { variant: 'medium' },
          style: {
            backgroundColor: '#ECEFED',
          },
        },
      ],
      styleOverrides: {
        root: {
          backgroundColor: '#F4F8F6',
        },
      },
    },
    MuiAccordionSummary: {
      defaultProps: {
        expandIcon: <KeyboardDoubleArrowDownIcon />, // Replace YourCustomIcon with the icon component you want to use
      },
      styleOverrides: {
        root: {
          backgroundColor: 'transparent!important',
          padding: '1.25rem',
        },
        content: {
          margin: '0px!important',
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          borderRadius: '1rem',
          border: 'none!important',
          display: 'flex',
          flexDirection: 'column',
          gap: '0.5rem',
          padding: '0rem 1.25rem 1.25rem 1.25rem!important',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          paddingRight: '0!important',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          paddingRight: '0',
          paddingLeft: '0',
        },
      },
    },

    ListItemText: {
      styleOverrides: {
        root: {
          fontFamily: 'Roboto',
          fontSize: '16px',
          fontWeight: '600',
          lineHeight: '20px',
          letterSpacing: '0em',
          textAlign: 'left',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          '&:hover': {
            cursor: 'pointer',
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: '2rem',
          height: '1rem',
          padding: 0,
          '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
              transform: 'translateX(16px)',
              color: '#fff',
              '& + .MuiSwitch-track': {
                backgroundColor: '#00C43D',
                opacity: 1,
                border: 0,
              },
              '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
              },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
              color: '#33cf4d',
              border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
              color: '#afb9b2',
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.7,
            },
          },
          '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: '.75rem',
            height: '.75rem',
          },
          '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: '#E9E9EA',
            opacity: 1,
          },
        },
        large: {
          width: '2rem',
          height: '2rem',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-focusVisible': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          '& .modal': {
            backgroundColor: '#f4f8f6',
          },
          '& ::-webkit-scrollbar-thumb': {
            background: '#c9d2cc !important',
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: '#66766B',
        },
      },
    },
    MuiPopper: {
      styleOverrides: {
        root: {
          '& .MuiPaper-root': {
            padding: '.25rem',
          },
        },
      },
    },
    MuiSelect: {
      variants: [
        {
          props: { variant: 'custom' },
          style: {
            padding: '.25rem 1rem',
          },
        },
      ],
    },
  },
})

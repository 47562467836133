import { Autocomplete, Box, Chip, InputLabel, TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const StringArrayInput = ({ propertie, ruleParameters, setRuleParameters, isEditing }) => {
  const [valueState, setValueState] = useState([])
  const { t } = useTranslation(['common'])

  useEffect(() => {
    if (ruleParameters[propertie]) {
      setValueState(ruleParameters[propertie])
    }
  }, [propertie])

  useEffect(() => {
    if (valueState.length !== 0) {
      let all = { ...ruleParameters }
      all[propertie] = valueState

      setRuleParameters({ ...all })
    }
  }, [valueState])

  return (
    <Autocomplete
      disabled={!isEditing}
      value={valueState}
      onChange={(event, newValue) => {
        setValueState(newValue)
      }}
      multiple
      id="tags-filled"
      className="tags-chips"
      options={[]}
      freeSolo
      fullWidth
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip variant="filled" color="primary" label={option} {...getTagProps({ index })} />
        ))
      }
      renderInput={(params) => <TextField {...params} variant="outlined" placeholder={t('common:values')} />}
    />
  )
}

export default StringArrayInput

import './checklist.scss'
import { useEffect, useState } from 'react'

import { Box, TextField, Checkbox, FormControlLabel } from '@mui/material'

const ListOfChecklists = ({ actions, setActions, isEditing }) => {
  const [updatedList, setUpdatedList] = useState([])
  const [change, setChange] = useState(false)

  useEffect(() => {
    if (actions && updatedList.length === 0 && !change) {
      setUpdatedList([...actions])
    }
  }, [actions])

  const checkHandler = (completed, idx) => {
    const list = [...updatedList]
    list[idx] = { ...list[idx], completed }
    setUpdatedList(list)
    setChange(true)
  }

  useEffect(() => {
    if (change) {
      setActions(updatedList)
      setChange(false)
    }
  }, [updatedList, change])

  return (
    <Box className="list-checklist-container">
      {updatedList.length !== 0 &&
        updatedList.map((item, index) => (
          <Box className="list-row" key={index}>
            <Box className="buttons">
              <FormControlLabel
                disabled={!isEditing}
                control={<Checkbox checked={item.completed} />}
                onChange={() => checkHandler(!item.completed, index)}
              />
            </Box>
            <TextField className="Input" type="text" disabled variant="standard" size="small" value={item.action} />
          </Box>
        ))}
    </Box>
  )
}

export default ListOfChecklists

import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { kybChecksEnum, shareholderChecksEnum } from '../kyb.enums'
import { Box, Checkbox, MenuItem, Select, TextField, Tooltip, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { documentByTypeAndCountry } from '../constants'
import { useCreateKybMutation } from '../../../services/guenoApi'
import { countryDictionary } from '../../../components/common/flag/countries'
import toaster from '../../../toaster'

const DocumentValidation = {
  kyb: {
    BR: /^([0-9]){14}$/,
    CO: /^([0-9]){8,15}$/,
  },
  kyc: {
    BR: /^([0-9]){11}$/,
    CO: /^([0-9]){8,11}$/,
  },
}

const KybNoRowsOverlay = ({ isKyb = true, defaultDocumentNumber = '' }) => {
  const navigate = useNavigate()
  const { t } = useTranslation(['common'])
  const [createKyb, { isLoading }] = useCreateKybMutation({ fixedCacheKey: 'create-kyb' })
  const { currentCountry, countrySegment } = useSelector((state) => state.kybCountry)
  const [documentNumber, setDocumentNumber] = useState(defaultDocumentNumber)
  const [isValidDocument, setIsValidDocument] = useState(false)
  const [nationality, setNationality] = useState(currentCountry)
  const [runChecks, setRunChecks] = useState(true)
  const allKybChecks = kybChecksEnum[currentCountry]
  const allShareholderChecks = shareholderChecksEnum[currentCountry]
  const [kybChecks, setKybChecks] = useState(allKybChecks)
  const [shareholderChecks, setShareholderChecks] = useState(allShareholderChecks)

  const entityType = isKyb ? 'kyb' : 'kyc'
  const documentTypes = documentByTypeAndCountry[entityType][currentCountry] || []
  const documentTypesText = `(${documentTypes?.join(', ')})`

  const buildBody = () => {
    const body = { documentNumber }

    if (runChecks) {
      body.runChecks = runChecks
      body.shareholderChecks = shareholderChecks
    }

    if (runChecks && isKyb) {
      body.kybChecks = kybChecks
    }

    if (!isKyb) {
      body.nationality = nationality
    }

    return body
  }

  const handleSubmit = async () => {
    try {
      const { data, error } = await createKyb({
        countrySegment,
        isKyb,
        body: buildBody(),
      })
      if (data?.success && data?.data) {
        navigate(`/${isKyb ? 'kyb' : 'kyc'}/${currentCountry}/detail/${data.data._id}`)
      } else if (error?.data) {
        throw new Error(error?.data?.message || t('messages:unexpectedErrorValues'))
      } else {
        throw new Error(data?.responseBody?.message || data?.info || 'Unexpected')
      }
    } catch (err) {
      toaster.error(err.message ? t('messages:documentNotFound') : t('messages:unexpectedErrorValues'))
    }
  }

  useEffect(() => {
    if (documentNumber) {
      const regex = DocumentValidation[entityType][currentCountry]
      setIsValidDocument(documentNumber.match(regex))
    }
  }, [currentCountry, documentNumber, entityType])

  return (
    <Box
      className="custom-overlay"
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 2,
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 2 }}>
        <Typography sx={{ fontSize: '1rem', fontWeight: '500', color: '#66766B' }}>
          {t('messages:kyb.nothingToShow')}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 2 }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: 2 }}>
          <Box>
            <TextField
              size="medium"
              value={documentNumber}
              error={!isValidDocument}
              fullWidth
              className="forceWidth"
              onChange={(event) => setDocumentNumber(event.target.value?.replace(/[^0-9]/g, ''))}
              disabled={isLoading}
              style={{ width: 350 }}
              InputProps={{
                placeholder: t('common:kyb.documentNumberInput', { documentTypes: documentTypesText }),
              }}
            />
          </Box>
          {!isKyb && (
            <Box>
              <Select
                size="small"
                className="forceWidth"
                value={nationality}
                onChange={(e) => setNationality(e.target.value)}
                placeholder={`${t('common:select')} ${t('common:nationality')}`}
                displayEmpty
                fullWidth
                disabled={isLoading}
                renderValue={(value) =>
                  value?.length ? countryDictionary[value] : `${t('common:select')} ${t('common:nationality')}`
                }
              >
                {Object.keys(countryDictionary)?.map((key) => {
                  return (
                    <MenuItem key={key} value={key}>
                      {countryDictionary[key]}
                    </MenuItem>
                  )
                })}
              </Select>
            </Box>
          )}
          <Box>
            <Tooltip placement="top" title={t('common:runChecks')}>
              <Checkbox disabled={isLoading} checked={runChecks} onChange={() => setRunChecks(!runChecks)} />
            </Tooltip>
          </Box>
          <Box>
            <LoadingButton
              size="small"
              variant="contained"
              type="submit"
              disabled={!documentNumber || !isValidDocument}
              loading={isLoading}
              onClick={handleSubmit}
            >
              <span>{t('common:create')}</span>
            </LoadingButton>
          </Box>
        </Box>
        {isKyb && runChecks && kybChecks && (
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
            <Box sx={{ width: 160, textAlign: 'right' }}>
              <Typography variant="body1">KYB checks:</Typography>
            </Box>
            <Select
              className="forceWidth"
              size="small"
              multiple
              fullWidth
              value={kybChecks}
              onChange={(e) =>
                setKybChecks(typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value)
              }
              renderValue={(selected) => selected.join(', ')}
              disabled={isLoading}
            >
              {allKybChecks.map((name) => (
                <MenuItem key={name} value={name}>
                  <Checkbox checked={kybChecks.indexOf(name) > -1} />
                  <Typography>{name}</Typography>
                </MenuItem>
              ))}
            </Select>
          </Box>
        )}
        {runChecks && shareholderChecks && (
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
            <Box sx={{ width: 160, textAlign: 'right' }}>
              <Typography variant="body1">Shareholders checks:</Typography>
            </Box>
            <Select
              className="forceWidth"
              size="small"
              multiple
              fullWidth
              value={shareholderChecks}
              onChange={(e) =>
                setShareholderChecks(typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value)
              }
              renderValue={(selected) => selected.join(', ')}
              disabled={isLoading}
            >
              {allShareholderChecks.map((name) => (
                <MenuItem key={name} value={name}>
                  <Checkbox checked={shareholderChecks.indexOf(name) > -1} />
                  <Typography>{name}</Typography>
                </MenuItem>
              ))}
            </Select>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default KybNoRowsOverlay

import './flags.scss'
import { countryDictionary } from './countries'
import { Box, Tooltip, Typography } from '@mui/material'

export const Flags = (country, noName, shortName) => {
  return (
    <Box className="flag-child">
      {noName ? (
        ''
      ) : shortName ? (
        <Typography variant="h5">{country}</Typography>
      ) : (
        <Tooltip title={countryDictionary[country]}>
          <Typography variant="h5">{countryDictionary[country]?.split('(')[0].trim() || ''}</Typography>
        </Tooltip>
      )}
      {country && (
        <img alt="img" src={`https://flagcdn.com/16x12/${country?.toLowerCase()}.png`} width="16" height="12" />
      )}
      {!country && <p>---</p>}
    </Box>
  )
}

import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateCreation, updateInstanceRules } from '../../features/rules/rulesSlice'

export const useInstanceRules = (isLoading, setIsLoading, rules, instanceRules, setInstanceRules, isArchived) => {
  const instanceRulesState = useSelector((state) => state.rule)
  const [instanceRulesAux, setInstanceRulesAux] = useState([...instanceRulesState.instanceRules])
  const { accessToken } = useSelector((state) => state.login)
  const creationState = useSelector((state) => state.rule.creation)
  const dispatch = useDispatch()

  useEffect(() => {
    if (instanceRules.length !== 0) {
      dispatch(updateInstanceRules(instanceRules))
    }
  }, [instanceRules])

  useEffect(() => {
    if (isLoading || creationState) {
      dispatch(updateCreation(false))
      const urlGuenoInstanceRules = `${process.env.REACT_APP_BASEURL}/rules?viewMode=true${
        isArchived ? '&status=ARCHIVED' : ''
      }`

      const options = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }

      let allInstanceRules = []

      fetch(process.env.REACT_APP_IS_DEMO ? '../../data/instanceRules.json' : urlGuenoInstanceRules, options)
        .then((res) => res.json())
        .then((res) => {
          const { data } = res
          if (data && Array.isArray(data)) {
            const guenoData = data.map((item) => ({ ...item, gueno: true }))
            allInstanceRules.push(...guenoData)
          }
          setInstanceRulesAux(allInstanceRules)
          dispatch(updateInstanceRules(allInstanceRules))
          setIsLoading(false)
        })
        .catch((error) => {
          setIsLoading(false)
          console.error('[HOOK: useRules] --> ', error)
        })
    }
    setInstanceRulesAux(instanceRulesState.instanceRules)
  }, [isArchived, isLoading])

  useEffect(() => {
    if (!instanceRules.length || instanceRules.length !== instanceRulesAux.length) {
      let rls = []
      instanceRulesAux.forEach((element) => {
        let ruleData = rules.filter((rule) => rule.id === element.ruleId)
        let ruleNameAlias = element.ruleNameAlias ? element.ruleNameAlias : ruleData[0]?.name
        let description = ruleData[0]?.description
        let idConcat = `${element.ruleId} (${element.id})`

        rls.push({ ...element, ruleNameAlias, description, idConcat })
      })

      rls = rls.sort((a, b) =>
        parseInt(a.idConcat.split(/[\s$-]+/)[1]) > parseInt(b.idConcat.split(/[\s$-]+/)[1]) ? 1 : -1,
      )

      setInstanceRules(rls)
    }
  }, [rules, instanceRulesAux])

  return instanceRulesState.instanceRules
}

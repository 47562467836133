import { useEffect, useState } from 'react'
import './device.scss'
import { Box } from '@mui/material'
import Spinner from '../../components/common/spinner/spinner'
import { useNavigate } from 'react-router-dom'

const Device = () => {
  const [isLoading, setIsLoading] = useState(true)
  const navigate = useNavigate()

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 1500)
  }, [])

  return (
    <Box className="datatable workflows-2">
      {isLoading && <Spinner noTransform />}
      {!isLoading && (
        <img
          className="img-separator"
          src="https://res.cloudinary.com/g-eno/image/upload/v1695826686/Testing/device-head.png"
          alt=""
        />
      )}
      {!isLoading && (
        <img
          className="img-separator device-table"
          onClick={() => navigate('/device/:id')}
          src="https://res.cloudinary.com/g-eno/image/upload/v1695826686/Testing/device-table.png"
          alt=""
        />
      )}
    </Box>
  )
}

export default Device

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: {},
  pagination: {},
  statistics: {},
  filters: {
    limit: 50,
    page: 0,
    offset: 0,
  },
}

const paymentMethodDetailUsersSlice = createSlice({
  name: 'paymentMethodDetailUsers',
  initialState,
  reducers: {
    setPaymentMethodDetailUsersData: (state, action) => {
      state.data = action.payload.data
      state.statistics = action.payload.statistics
      state.pagination = action.payload.pagination
    },
    setPaymentMethodDetailUsersFilters: (state, action) => {
      state.filters = { ...state.filters, ...action.payload }
    },
    resetPaymentMethodDetailUsersFilters: (state) => {
      state.filters = initialState.filters
    },
  },
})

export const {
  setSelectedPaymentMethodDetailUsers,
  setPaymentMethodDetailUsersData,
  setPaymentMethodDetailUsersFilters,
  resetPaymentMethodDetailUsersFilters,
} = paymentMethodDetailUsersSlice.actions

export default paymentMethodDetailUsersSlice.reducer

import { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Check, Close } from '@mui/icons-material';

/**
 * TypingTable renders a table with the given reportItem one row at a time with a typing effect.
 * @param {{
*  reportItem: {
  *    key: string,
  *    title: string,
  *    content: {
  *      type: 'text' | 'table',
  *      itemContent: {
  *        headers: string[],
  *        rows: (string | number | boolean)[][]
  *      } | string,
  *      rendered: boolean
  *    }[]
  *  }
  * }} props
  * @returns {JSX.Element}
  */
const TypingTable = ({ reportItem, onComplete }) => {

  const [displayedRows, setDisplayedRows] = useState([]);
  const keyStrokeTime = 25;
  
  useEffect(() => {
    if (reportItem.itemContent.rows.length > displayedRows.length) {
      setTimeout(() => {
        setDisplayedRows(reportItem.itemContent.rows.slice(0, displayedRows.length + 1));
      }, keyStrokeTime);
    } else if (reportItem.itemContent.rows.length === displayedRows.length && (reportItem && !reportItem.rendered)) {
      onComplete();
    }
  }, [displayedRows, reportItem.itemContent.rows]);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {reportItem.itemContent.headers.map((header, index) => (
              <TableCell key={index}>{header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {displayedRows.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {row.map((cell, cellIndex) => (
                <TableCell key={cellIndex}>
                  {cell === true ? <Check /> : cell === false ? <Close /> : cell}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TypingTable;
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { updateFilters, updateFiltersGueno } from '../../features/rules/rulesSlice'

export const useRuleFilters = (isLoading, setIsLoading, filters, setFilters) => {
  const { accessToken } = useSelector((state) => state.login)
  const rulesState = useSelector((state) => state.rule)
  const dispatch = useDispatch()

  useEffect(() => {
    if (Object.keys(filters).length === 0 && !isLoading && !Object.keys(rulesState.filters).length) {
      const urlKytFilters = `${process.env.REACT_APP_BASEURL}/kyt/ruleFilters`

      const options = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }

      let allFilters = []

      if (setIsLoading) {
        setIsLoading(true)
      }

      const kytFiltersPromise = fetch(
        process.env.REACT_APP_IS_DEMO ? '../../data/ruleFilters.json' : urlKytFilters,
        options,
      )
        .then((res) => res.json())
        .then((res) => res)
        .catch((error) => {
          console.error('[HOOK: useRules] --> ', error)
        })

      Promise.all([kytFiltersPromise]).then((responses) => {
        if (responses[0]) {
          const { data } = responses[0]
          if (data) {
            allFilters.push({ data })
          }
        }

        dispatch(updateFilters(allFilters))
        setIsLoading(false)
      })
    }
  }, [])

  useEffect(() => {
    if (Object.keys(rulesState.filters).length !== 0) {
      setFilters(rulesState.filters)
      setIsLoading(false)
    }
  }, [rulesState])

  return rulesState.filters
}

export const useRuleFiltersGueno = (isLoading, setIsLoading, filters, setFilters) => {
  const { accessToken } = useSelector((state) => state.login)
  const rulesState = useSelector((state) => state.rule)
  const dispatch = useDispatch()

  useEffect(() => {
    if (Object.keys(filters).length === 0 && !isLoading && !Object.keys(rulesState.filtersGueno).length) {
      const urlGuenoFilters = `${process.env.REACT_APP_BASEURL}/filters-templates`

      const options = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }

      let allFilters = []

      if (setIsLoading) {
        setIsLoading(true)
      }

      const guenoFiltersPromise = fetch(
        process.env.REACT_APP_IS_DEMO ? '../../data/filter-templates.json' : urlGuenoFilters,
        options,
      )
        .then((res) => res.json())
        .then((res) => res)
        .catch((error) => {
          console.error('[HOOK: useRules] --> ', error)
        })

      Promise.all([guenoFiltersPromise]).then((responses) => {
        if (responses[0]) {
          if (responses[0] && Array.isArray(responses[0])) {
            const data = responses[0]
            allFilters.push({ data })
          }
        }

        dispatch(updateFiltersGueno(allFilters))
        setIsLoading(false)
      })
    }
  }, [])

  useEffect(() => {
    if (Object.keys(rulesState.filtersGueno).length !== 0) {
      setFilters(rulesState.filtersGueno)
      setIsLoading(false)
    }
  }, [rulesState])

  return rulesState.filtersGueno
}

import './RequestRule.scss'
import { useEffect, useState } from 'react'

import { useTheme } from '@mui/material/styles'
import { FormControl, Input, InputAdornment, MenuItem, OutlinedInput, Select } from '@mui/material'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import Tooltip from '@mui/material/Tooltip'
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert'
import IconButton from '@mui/material/IconButton'
import Collapse from '@mui/material/Collapse'
import CloseIcon from '@mui/icons-material/Close'
import { useSelector } from 'react-redux'
import { MenuProps } from '../../../utilities/MenuProps'
import { useTranslation } from 'react-i18next'

function getStyles(name, selectedValue, theme) {
  return {
    fontWeight:
      selectedValue.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  }
}

const RequestRule = ({ aux }) => {
  const theme = useTheme()

  const [title, setTitle] = useState('')
  const [name, setName] = useState('')
  const [desc, setDesc] = useState('')
  const [detail, setDetail] = useState('')
  const [selectedValue, setSelectedValue] = useState([aux])

  const [disabled, setDisabled] = useState(false)
  const [open, setOpen] = useState(false)
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const { accessToken, email } = useSelector((state) => state.login)
  const { t } = useTranslation(['common'])

  useEffect(() => {
    if (title === '' || name === '' || desc === '') setDisabled(true)
    else setDisabled(false)
  }, [title, name, desc])

  const reset = () => {
    setTitle('')
    setName('')
    setDesc('')
    setDetail('')
    setSelectedValue([aux])
  }

  const submit = () => {
    if (!process.env.REACT_APP_IS_DEMO) {
      const urlLogin = `${process.env.REACT_APP_BASEURL}/kyt/sendSlackMessage`
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          email: email,
          title,
          name,
          desc,
          aditional: detail,
          priority: selectedValue[0],
        }),
      }

      fetch(urlLogin, options)
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode >= 400) throw Error(res.message)
          setOpen(true)
          reset()
        })
        .catch((error) => {
          setError(true)
          setErrorMessage(JSON.stringify(error.message))
        })
    } else {
      setOpen(true)
      reset()
    }
  }

  const posibleValues = ['P1', 'P2', 'P3', 'P4']

  const handleChange = (event) => {
    const {
      target: { value },
    } = event
    setSelectedValue(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    )
  }

  return (
    <div className="requestRuleContainer">
      <Collapse in={open}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false)
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          Rule request successfully submitted!
        </Alert>
      </Collapse>
      <Collapse in={error}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="error"
              size="small"
              onClick={() => {
                setError(false)
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          severity="error"
          sx={{ mb: 2 }}
        >
          Something went wrong! {errorMessage}!
        </Alert>
      </Collapse>
      <div className="pair">
        <label className="Label">
          <span className="mandatory">* </span>Title
        </label>
        <Input
          color="secondary"
          id="standard-adornment-amount"
          startAdornment={<InputAdornment position="start"></InputAdornment>}
          multiline={true}
          placeholder="Title of your new rule request"
          value={title}
          onChange={(event) => setTitle(event.target.value)}
        />
      </div>
      <div className="pair">
        <label className="Label">
          <span className="mandatory">* </span>Rule Name
        </label>
        <Input
          color="secondary"
          id="standard-adornment-amount"
          startAdornment={<InputAdornment position="start"></InputAdornment>}
          multiline={true}
          placeholder="Name of your new rule"
          value={name}
          onChange={(event) => setName(event.target.value)}
        />
      </div>
      <div className="pair">
        <label className="Label">
          <span className="mandatory">* </span>Rule Description
        </label>
        <Input
          color="secondary"
          id="standard-adornment-amount"
          startAdornment={<InputAdornment position="start"></InputAdornment>}
          multiline={true}
          placeholder="Brief description of your new rule"
          value={desc}
          onChange={(event) => setDesc(event.target.value)}
        />
      </div>
      <div className="pair">
        <label className="Label">Additional Rule Details</label>
        <Input
          color="secondary"
          id="standard-adornment-amount"
          startAdornment={<InputAdornment position="start"></InputAdornment>}
          multiline={true}
          placeholder="Details of the rule you need (thresholds, action type, use case, etc)"
          value={detail}
          onChange={(event) => setDetail(event.target.value)}
        />
      </div>
      <div className="pair">
        <label className="Label">
          <span className="mandatory">* </span>Priority{' '}
          {
            <Tooltip title="P1 highest. P4 lowest" placement="top">
              <HelpOutlineOutlinedIcon color="secondary" fontSize="small" />
            </Tooltip>
          }
        </label>
        <div>
          <FormControl sx={{ m: 1, width: '100%', mt: 3 }}>
            <Select
              color="secondary"
              displayEmpty
              value={selectedValue}
              onChange={handleChange}
              input={<OutlinedInput />}
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return <em>Select User Type</em>
                }

                return selected.join(', ')
              }}
              MenuProps={MenuProps}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {posibleValues.map((name) => (
                <MenuItem key={name} value={name} style={getStyles(name, selectedValue, theme)}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
      <div className="buttonPanel">
        <Button disabled={disabled} variant="outlinedGrey" onClick={submit}>
          {t('common:submit')}
        </Button>
        <Button variant="contained" onClick={reset}>
          {t('common:reset')}
        </Button>
      </div>
    </div>
  )
}

export default RequestRule

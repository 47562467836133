import { useParams } from 'react-router-dom'
import './aml.scss'
import './amlDetail.scss'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAmlDetail } from '../pages-hooks/useAml'
import { Box, Button, Fab, InputLabel, Link, Paper, Switch, Typography } from '@mui/material'
import Spinner from '../../components/common/spinner/spinner'
import { buildTimeStringFromTimestamp } from '../../components/common/time/timeHelper'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'
import { Accordion, AccordionDetails, AccordionSummary } from '../../components/common/Accordion/Accordion'
import { updateAmlDetail } from '../../features/aml/amlSlice'
import AlertFetchSpinner from '../../components/common/alertFetchSpinner/alertFetchSpinner'
import { useTranslation } from 'react-i18next'
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown'
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp'
import { TableChip } from '../../components/utilities/TableChip'

const AmlDetail = () => {
  const { id } = useParams()
  const { accessToken } = useSelector((state) => state.login)
  const dispatch = useDispatch()
  const [fetchError, setFetchError] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [fetchMessage, setFetchMessage] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const amlDetail = useSelector((state) => state.aml.amlDetail)
  useAmlDetail(id, setIsLoading)
  const { t } = useTranslation(['common', 'messages'])

  const [expanded, setExpanded] = useState([])

  useEffect(() => {
    if (amlDetail && amlDetail.hits?.length !== 0) {
      let aux = []
      amlDetail?.hits?.forEach(() => aux.push(false))
      setExpanded(aux)
    }
  }, [amlDetail])

  const handleChange = (position) => {
    let aux = [...expanded]
    aux[position] = !aux[position]
    setExpanded([...aux])
  }

  const setAllEqual = () => {
    let aux = []
    if (!expanded.includes(true)) {
      expanded.forEach(() => aux.push(true))
    } else {
      expanded.forEach(() => aux.push(false))
    }
    setExpanded([...aux])
  }

  const handleFetch = (error, message) => {
    setIsFetching(true)
    setFetchError(error)
    setFetchMessage(message)
    setTimeout(() => {
      setIsFetching(false)
    }, 3000)
  }

  const updateDetail = () => {
    const url = `${process.env.REACT_APP_BASEURL}/aml/getByReferenceId/${id}?viewMode=true`

    fetch(process.env.REACT_APP_IS_DEMO ? '../data/amlDetails.json' : url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        dispatch(updateAmlDetail(res.data))
        setIsLoading(false)
      })
      .catch((error) => {
        console.error('[HOOK: useAmlDetail] --> ', error)
        dispatch(updateAmlDetail({ statusCode: 404 }))
        setIsLoading(false)
      })
  }

  const handleUpdate = (e) => {
    e.preventDefault()
    if (!process.env.REACT_APP_IS_DEMO) {
      let data = {
        is_monitored: !amlDetail.is_monitored,
      }
      const urlUser = `${process.env.REACT_APP_BASEURL}/aml/updatemonitors/${amlDetail?.search_id}`
      const options = {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(data),
      }

      fetch(urlUser, options)
        .then((res) => res.json())
        .then((res) => {
          if (!res.success) throw Error(typeof res.data === 'string' ? res.data : res.message)
          handleFetch(false, t('messages:updateSuccess'))
          updateDetail()
        })
        .catch((error) => {
          console.error('[UPDATE MONITORS ERROR] --> ', error)
          handleFetch(true, error.message ? error.message : t('messages:updateError'))
        })
    } else {
      handleFetch(false, t('messages:updateSuccess'))
      updateDetail()
    }
  }

  return (
    <Box>
      {isFetching && <AlertFetchSpinner message={fetchMessage} error={fetchError} />}
      {isLoading && <Spinner noTransform />}
      {!isLoading && (
        <Box className="aml-manual-wrapper">
          <Paper elevation={0} className="aml-detail-wrapper">
            <Box className="aml-detail-id">
              <Typography variant="title">ID {t('common:detail')}</Typography>

              <Button
                onClick={() => window.open(amlDetail.share_url, '_blank', 'rel=noopener noreferrer')}
                disabled={!amlDetail.share_url}
                size="small"
                variant="contained"
              >
                {t('common:viewDetails')}
              </Button>
            </Box>
            <Box className="aml-detail-grid">
              <Paper elevation={0} variant="colorPrimary">
                <Box className="aml-detail-id">
                  <Box className="aml-detail-box">
                    <Typography variant="subtitle1">{t('common:reference')} ID</Typography>
                    <Typography variant="number">{id}</Typography>
                  </Box>

                  <Fab
                    size="small"
                    className="icon-global"
                    variant="action"
                    onClick={() => {
                      navigator.clipboard.writeText(id)
                    }}
                  >
                    <ContentCopyOutlinedIcon />
                  </Fab>
                </Box>
              </Paper>

              <Paper elevation={0} variant="colorPrimary">
                <Box className="aml-detail-id">
                  <Box className="aml-detail-box">
                    <Typography variant="subtitle1">{t('common:search')} ID</Typography>
                    <Typography variant="number">{amlDetail?.search_id ? amlDetail?.search_id : '---'}</Typography>
                  </Box>
                  <Fab
                    size="small"
                    className="icon-global"
                    variant="action"
                    onClick={() => {
                      navigator.clipboard.writeText(amlDetail?.search_id)
                    }}
                  >
                    <ContentCopyOutlinedIcon />
                  </Fab>
                </Box>
              </Paper>

              <Paper elevation={0} variant="colorPrimary">
                <Box className="aml-detail-id">
                  <Box className="aml-detail-box">
                    <Typography variant="subtitle1">{t('common:monitored')}</Typography>
                    <Typography variant="h5">{amlDetail.is_monitored ? t('common:yes') : t('common:no')}</Typography>
                  </Box>
                  <Switch
                    key={id}
                    disabled={isFetching || !amlDetail?.search_id}
                    checked={amlDetail.is_monitored}
                    onChange={(e) => {
                      handleUpdate(e, amlDetail)
                    }}
                  />
                </Box>
              </Paper>
            </Box>

            {(!amlDetail || !amlDetail.search_term) && (
              <Box className="error-msg">
                <Button size="small" variant="outlined" color="error">
                  {t('messages:checkNotFound')}
                </Button>
              </Box>
            )}
          </Paper>

          {amlDetail && amlDetail.search_term && (
            <Paper elevation={0} className="aml-detail-wrapper">
              <Box className="aml-detail-id">
                <Typography variant="title">ID {t('common:basicDetails')}</Typography>
              </Box>

              <Box className="aml-detail-grid">
                <Paper elevation={0} variant="colorPrimary">
                  <Box className="aml-detail-id">
                    <Box className="aml-detail-box">
                      <Typography variant="subtitle1">{t('common:searchTerm')}</Typography>
                      <Typography variant="h5">{amlDetail.search_term ? amlDetail.search_term : '---'}</Typography>
                    </Box>

                    <Fab
                      size="small"
                      className="icon-global"
                      variant="action"
                      onClick={() => {
                        navigator.clipboard.writeText(amlDetail.search_term ?? '---')
                      }}
                    >
                      <ContentCopyOutlinedIcon />
                    </Fab>
                  </Box>
                </Paper>

                <Paper elevation={0} variant="colorPrimary">
                  <Box className="aml-detail-id">
                    <Box className="aml-detail-box">
                      <Typography variant="subtitle1">{t('common:matchStatus')}</Typography>
                      <Typography variant="h5">{amlDetail.match_status ? amlDetail.match_status : '---'}</Typography>
                    </Box>

                    <Box sx={{ width: 'fit-content' }}>
                      <TableChip noIcon={true} action={amlDetail.match_status || '---'} />
                    </Box>
                  </Box>
                </Paper>

                <Paper elevation={0} variant="colorPrimary" className="aml-detail-box">
                  <Typography variant="subtitle1">{t('common:searchDate')}</Typography>
                  <Typography variant="number">
                    {amlDetail.createdAt ? buildTimeStringFromTimestamp(amlDetail.createdAt) : '---'}
                  </Typography>
                </Paper>

                <Paper elevation={0} variant="colorPrimary" className="aml-detail-box">
                  <Typography variant="subtitle1">{t('common:totalMatches')}</Typography>
                  <Typography variant="number">{amlDetail.total_matches ? amlDetail.total_matches : '---'}</Typography>
                </Paper>

                <Paper elevation={0} variant="colorPrimary" className="aml-detail-box">
                  <Typography variant="subtitle1">{t('common:totalHits')}</Typography>
                  <Typography variant="number">{amlDetail.total_hits ? amlDetail.total_hits : '---'}</Typography>
                </Paper>

                <Paper elevation={0} variant="colorPrimary" className="aml-detail-box">
                  <Typography variant="subtitle1">{t('common:totalBlacklistHits')}</Typography>
                  <Typography variant="number">
                    {amlDetail.total_blacklist_hits || amlDetail.total_blacklist_hits === 0
                      ? amlDetail.total_blacklist_hits
                      : '---'}
                  </Typography>
                </Paper>
              </Box>
            </Paper>
          )}

          {amlDetail.filters && (
            <Paper elevation={0} className="aml-detail-wrapper">
              <Box className="aml-detail-id">
                <Typography variant="title">{t('common:filters')}</Typography>
              </Box>
              <Box className="aml-detail-grid">
                <Paper elevation={0} variant="colorPrimary" className="aml-detail-box">
                  <Typography variant="subtitle1">{t('common:birthYear')}</Typography>
                  <Typography variant="number">
                    {amlDetail.filters.birth_year ? amlDetail.filters.birth_year : '---'}
                  </Typography>
                </Paper>

                <Paper elevation={0} variant="colorPrimary" className="aml-detail-box">
                  <Typography variant="subtitle1">{t('common:countryCodes')}</Typography>
                  <Typography variant="number">
                    {amlDetail.filters.country_codes.length > 0 ? amlDetail.filters.country_codes.toString() : '---'}
                  </Typography>
                </Paper>

                <Paper elevation={0} variant="colorPrimary" className="aml-detail-box">
                  <Typography variant="subtitle1">{t('common:entityType')}</Typography>
                  <Typography variant="h5">
                    {amlDetail.filters.entity_type ? amlDetail.filters.entity_type.toCa : '---'}
                  </Typography>
                </Paper>

                <Paper elevation={0} variant="colorPrimary" className="aml-detail-box">
                  <Typography variant="subtitle1">{t('common:exactMatch')}</Typography>
                  <Typography variant="h5">
                    {amlDetail.filters.exact_match ? t('common:yes') : t('common:no')}
                  </Typography>
                </Paper>

                <Paper elevation={0} variant="colorPrimary" className="aml-detail-box">
                  <Typography variant="subtitle1">{t('common:fuzziness')}</Typography>
                  <Typography variant="number">
                    {amlDetail.filters.fuzziness || amlDetail.filters.fuzziness === 0
                      ? amlDetail.filters.fuzziness
                      : '---'}
                  </Typography>
                </Paper>

                <Paper elevation={0} variant="colorPrimary" className="aml-detail-box">
                  <Typography variant="subtitle1">{t('common:removeDeceased')}</Typography>
                  <Typography variant="number">
                    {amlDetail.filters.remove_deceased || amlDetail.filters.remove_deceased === 0
                      ? amlDetail.filters.remove_deceased
                      : '---'}
                  </Typography>
                </Paper>
              </Box>
            </Paper>
          )}

          <Paper elevation={0} className="aml-detail-wrapper">
            <Box className="aml-detail-id">
              <Typography variant="title"> {t('common:hits')}</Typography>

              {amlDetail && amlDetail.hits?.length !== 0 ? (
                <Button variant="outlined" onClick={() => setAllEqual()}>
                  {expanded.includes(true) ? t('common:hideAll') : t('common:expandAll')}
                </Button>
              ) : (
                <Typography variant="subtitle3">{t('common:noHitsToShow')}</Typography>
              )}
            </Box>
            {amlDetail &&
              amlDetail.hits?.length !== 0 &&
              amlDetail.hits.map((hit, index) => (
                <Accordion variant="medium" key={index} expanded={expanded[index]} onChange={() => handleChange(index)}>
                  {hit.match_types_details?.length !== 0 && (
                    <AccordionSummary expandIcon={null}>
                      <Box className="aml-detail-accordion-wrapper">
                        <Box className="aml-detail-input">
                          <InputLabel>{t('common:name')}:</InputLabel>
                          <Typography variant="subtitle3">{hit.match_types_details[0].matching_name}</Typography>
                        </Box>
                        <Box className="aml-detail-input">
                          <InputLabel>{t('common:types')}:</InputLabel>
                          <Typography variant="subtitle3">
                            {hit.match_types_details[0].aml_types?.toString()}
                          </Typography>
                        </Box>
                        <Box className="aml-detail-input">
                          <InputLabel>{t('common:sources')}:</InputLabel>
                          <Typography variant="subtitle3">{hit.match_types_details[0].sources?.toString()}</Typography>
                        </Box>
                        <Box className="aml-detail-input">
                          <InputLabel>{t('common:hitId')}:</InputLabel>
                          <Typography variant="subtitle3">{hit.doc?.id}</Typography>
                        </Box>
                      </Box>
                      <Button
                        variant="outlinedBlank"
                        onChange={(e) => {
                          e.stopPropagation()
                          return handleChange(index)
                        }}
                      >
                        {expanded[index] ? <KeyboardDoubleArrowDownIcon /> : <KeyboardDoubleArrowUpIcon />}
                      </Button>
                    </AccordionSummary>
                  )}
                  <AccordionDetails>
                    <Paper elevation={0} variant="colorSecondary" className="aml-detail-accordion-wrapper">
                      {hit.doc?.fields?.length !== 0 &&
                        hit.doc?.fields.map((field, index2) => (
                          <Box className="aml-detail-input" key={index2}>
                            <InputLabel>{field.name}:</InputLabel>

                            {field.name === 'Locationurl' || field.name === 'Related URL' ? (
                              <Link onClick={() => window.open(field.value, '_blank', 'rel=noopener noreferrer')}>
                                <Typography variant="title2">{field.value}</Typography>
                              </Link>
                            ) : (
                              <Typography variant="subtitle3">{field.value}</Typography>
                            )}
                          </Box>
                        ))}
                    </Paper>
                  </AccordionDetails>
                </Accordion>
              ))}
          </Paper>
        </Box>
      )}
    </Box>
  )
}

export default AmlDetail

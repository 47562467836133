export const getDescription = (row, rules) => {
  let desc = ''
  if (row.ruleDescriptionAlias) {
    desc = row.ruleDescriptionAlias
  } else if (row.description) {
    desc = row.description
  } else {
    desc = rules?.find((r) => r.id === row.ruleId)?.description || ''
  }

  return desc
}

import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateRequestsToAoR } from '../../features/requestsToAoRSlice/requestsToAoRSlice'

function buildUrl(baseUrl, queryState) {
  let url = baseUrl

  const { requestId, requestedBy, status, limit, offset, page, fromDate, toDate } = queryState
    ? queryState
    : { ...null }

  if (requestId) url += `?id=${requestId}`
  if (requestedBy) {
    url += requestId ? `&requestedBy=${requestedBy}` : `?requestedBy=${requestedBy}`
  }
  if (status) {
    url += requestId || requestedBy ? `&status=${status}` : `?status=${status}`
  }

  url +=
    requestId || requestedBy || status
      ? `&limit=${limit}&page=${page}&offset=${offset}`
      : `?limit=${limit}&page=${page}&offset=${offset}`

  if (fromDate && toDate) url += `&fromDate=${fromDate}&toDate=${toDate}`

  return url
}

export const useRequestsToAoR = (setIsLoading) => {
  const { email, accessToken } = useSelector((state) => state.login)
  const queryState = useSelector((state) => state.requestsToAoR.filters)

  const dispatch = useDispatch()

  const baseUrlR = `${process.env.REACT_APP_BASEURL}/requestToApproveOrReject/requestedBy/${email}`
  const baseUrlE = `${process.env.REACT_APP_BASEURL}/requestToApproveOrReject/evaluator/${email}`

  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  }

  useEffect(() => {
    fetch(process.env.REACT_APP_IS_DEMO ? '../../../data/cpo.json' : `${buildUrl(baseUrlR, queryState)}`, options)
      .then((res) => res.json())
      .then((res) => {
        const response = { isRequestedBy: true, requestedBy: res.data, requestedByPagination: res.paginationResult }
        dispatch(updateRequestsToAoR(response))
        setIsLoading(false)
      })
      .catch((error) => console.error('[HOOK: useRequestsToAoR] --> ', error))
  }, [queryState])

  useEffect(() => {
    fetch(process.env.REACT_APP_IS_DEMO ? '../../../data/cpo.json' : `${buildUrl(baseUrlE, queryState)}`, options)
      .then((res) => res.json())
      .then((res) => {
        const response = { isRequestedBy: false, evaluatedBy: res.data, evaluatedByPagination: res.paginationResult }
        dispatch(updateRequestsToAoR(response))
        setIsLoading(false)
      })
      .catch((error) => console.error('[HOOK: useRequestsToAoR] --> ', error))
  }, [queryState])
}

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: {},
  pagination: {},
  statistics: {},
  filters: {
    limit: 50,
    page: 0,
    offset: 0,
  },
}

const paymentMethodDetailSlice = createSlice({
  name: 'paymentMethodDetail',
  initialState,
  reducers: {
    setPaymentMethodDetailData: (state, action) => {
      state.data = action.payload.data
      state.statistics = action.payload.statistics
      state.pagination = action.payload.pagination
    },
    setPaymentMethodDetailFilters: (state, action) => {
      state.filters = { ...state.filters, ...action.payload }
    },
    resetPaymentMethodDetailFilters: (state) => {
      state.filters = initialState.filters
    },
  },
})

export const {
  setSelectedPaymentMethodDetail,
  setPaymentMethodDetailData,
  setPaymentMethodDetailFilters,
  resetPaymentMethodDetailFilters,
} = paymentMethodDetailSlice.actions

export default paymentMethodDetailSlice.reducer

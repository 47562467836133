import './generalAlerts.scss'
import { Box, Button, Fab, InputLabel, Link, MenuItem, Modal, Select, TableContainer, Typography } from '@mui/material'
import { useGeneralAlerts } from '../../pages-hooks/useGeneralAlerts'
import { buildTimeStringFromTimestamp } from '../../../components/common/time/timeHelper'
import { useEffect, useState } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { TableChip } from '../../../components/utilities/TableChip'
import { DataGridComponent } from '../../../components/common/datagrid/DataGridComponent'
import AlertFetchSpinner from '../../../components/common/alertFetchSpinner/alertFetchSpinner'

const alertStatusValues = [
  'PENDING',
  'REVIEW_IN_PROGRESS',
  'REVIEW_FINISHED',
  'CLOSED_WITH_ACTIONS',
  'CLOSED_WITHOUT_ACTIONS',
  'FALSE_POSITIVE',
]

const GeneralAlertsDatatable = ({
  isLoading,
  setIsLoading,
  setCount,
  withPagination,
  hasPermissions,
  type,
  hideActions,
  rows,
  setModalOpen,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  hasTabs,
  isFromDetail,
}) => {
  const { accessToken } = useSelector((state) => state.login)
  const generalAlertsTemp = useGeneralAlerts(setIsLoading, isLoading)
  const generalAlerts = rows ? [...rows] : Array.isArray(generalAlertsTemp?.data) ? [...generalAlertsTemp.data] : []
  const navigate = useNavigate()
  const { t } = useTranslation(['common', 'messages'])
  const [internalType, setInternalType] = useState('')
  const [openUpdateAlert, setOpenUpdateAlert] = useState(false)
  const [status, setStatus] = useState('')
  const [alertToUpdate, setAlertToUpdate] = useState({})
  const [withError, setWithError] = useState(false)
  const location = useLocation()
  const [isFetching, setIsFetching] = useState(false)
  const [fetchMessage, setFetchMessage] = useState('')
  const [fetchError, setFetchError] = useState(false)

  useEffect(() => {
    setInternalType(type)
  }, [isLoading])

  useEffect(() => {
    if (generalAlertsTemp && generalAlertsTemp?.pagination && withPagination) {
      setCount(generalAlertsTemp?.pagination?.totalElements)
    }
  }, [generalAlertsTemp])

  const handleAlertStatusChange = () => {
    setIsFetching(true)
    let url = `${process.env.REACT_APP_BASEURL}/generalAlerts/${alertToUpdate._id}/status/${status}`

    const options = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    }

    fetch(process.env.REACT_APP_IS_DEMO ? '../data/generalAlerts.json' : url, options)
      .then((res) => res.json())
      .then((res) => {
        setFetchMessage(res.message)
        setOpenUpdateAlert(false)
        setIsFetching(false)
      })
      .catch((error) => {
        console.error('[UPDATE USER ERROR]', error)
        setFetchMessage(error.message)
        setFetchError(true)
      })

    setTimeout(() => {
      setIsFetching(false)
      setFetchError(false)
      window.location.reload()
    }, 3000)
  }

  const columnsAml = [
    {
      field: 'id',
      headerName: t('common:alertId'),
      renderCell: (params) => <Box className="cellOverflow">{params.row.id}</Box>,
    },
    {
      field: 'updatedAt',
      headerName: t('common:updatedAt'),
    },
    {
      field: 'userId',
      headerName: t('common:userId'),
      hide: hideActions,
      renderCell: (params) => <Box className="cellOverflow">{params.row.details.consumerUserId}</Box>,
    },
    {
      field: 'name',
      headerName: t('common:name'),
      renderCell: (params) => <Box className="cellOverflow">{params.row.details.name}</Box>,
    },
    {
      field: 'countries',
      headerName: t('common:countries'),
      renderCell: (params) => <Box className="cellOverflow">{params.row.details.countries}</Box>,
    },
    {
      field: 'types',
      headerName: t('common:types'),
      renderCell: (params) => <Box className="cellOverflow">{params.row.details.types?.join(', ')}</Box>,
    },
    {
      field: 'details',
      headerName: t('common:details'),
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <Link
            onClick={() => window.open(params.row.details.shareUrl, '_blank', 'rel=noopener noreferrer')}
            disabled={!(params.row.details && params.row.details.shareUrl)}
          >
            {t('common:viewAmlDetails')}
          </Link>
        )
      },
    },
    {
      field: 'status',
      headerName: t('common:status'),
      renderCell: (params) => <Box className="cellOverflow">{params.row.status ?? 'PENDING'}</Box>,
    },
    {
      field: 'update',
      headerName: t('common:updateStatus'),
      renderCell: (params) => {
        return (
          <Link
            onClick={() => {
              setOpenUpdateAlert(true)
              setAlertToUpdate({ _id: params.row._id })
            }}
          >
            {t('common:updateAlert')}
          </Link>
        )
      },
    },
  ]

  const columnsAmlCrypto = [
    {
      field: 'id',
      headerName: t('common:alertId'),
      renderCell: (params) => <Typography variant="number">{params.row.id}</Typography>,
    },
    {
      field: 'updatedAt',
      headerName: t('common:updatedAt'),
      renderCell: (params) => {
        return <Typography variant="number">{buildTimeStringFromTimestamp(params.row.updatedAt)}</Typography>
      },
    },
    {
      field: 'transactionId',
      headerName: t('common:transactionId'),
      hide: hideActions,
      renderCell: (params) => <Typography variant="number">{params.row.details.transactionId ?? '---'}</Typography>,
    },
    {
      field: 'protocol',
      headerName: t('common:protocol'),
      renderCell: (params) => <Box>{params.row.details.protocol ?? '---'}</Box>,
    },
    {
      field: 'address',
      headerName: t('common:address'),
      renderCell: (params) => <Box>{params.row.details.address ?? '---'}</Box>,
    },
    {
      field: 'score',
      headerName: t('common:score'),
      renderCell: (params) => <Box>{params.row.details.score ?? '---'}</Box>,
    },
    {
      field: 'level',
      headerName: t('common:level'),
      renderCell: (params) => <Box>{params.row.details.level ?? '---'}</Box>,
    },
    {
      field: 'category',
      headerName: t('common:category'),
      renderCell: (params) => {
        return <Box>{params.row.details.category ? params.row.details.category?.join(', ') : '---'}</Box>
      },
    },

    {
      field: 'action',
      headerName: t('common:action'),
      flex: 1,
      hide: hideActions || (location?.pathname?.includes('case-management') && !hasPermissions),
      minWidth: 200,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <Link onClick={() => navigate(`/transactions/${params.row.details.transactionId}`)}>
            {t('common:viewTxsDetails')}
          </Link>
        )
      },
    },
    {
      field: 'status',
      headerName: t('common:status'),
      renderCell: (params) => <TableChip noIcon={true} action={params.row.status ?? 'PENDING'} />,
    },
    {
      field: 'update',
      headerName: t('common:updateStatus'),
      renderCell: (params) => {
        return (
          <Button
            variant="outlinedChip"
            onClick={() => {
              setOpenUpdateAlert(true)
              setAlertToUpdate({ _id: params.row._id })
            }}
          >
            {t('common:update')}
          </Button>
        )
      },
    },
  ]

  const columnsTransactions = [
    {
      field: 'id',
      headerName: t('common:alertId'),
      renderCell: (params) => <Typography variant="number">{params.row.id}</Typography>,
    },
    {
      field: 'updatedAt',
      headerName: t('common:updatedAt'),
      renderCell: (params) => {
        return <Typography variant="number">{buildTimeStringFromTimestamp(params.row.updatedAt)}</Typography>
      },
    },
    {
      hide: hideActions,
      field: 'transactionId',
      headerName: t('common:transactionId'),
      renderCell: (params) => <Typography variant="number">{params.row.details.transactionId ?? '---'}</Typography>,
    },
    {
      field: 'ruleId',
      headerName: t('common:ruleId'),
      renderCell: (params) => <Typography variant="number">{params.row.details.ruleId ?? '---'}</Typography>,
    },
    {
      field: 'ruleInstanceId',
      headerName: t('common:ruleInstanceId'),
      renderCell: (params) => <Typography variant="number">{params.row.details.ruleInstanceId ?? '---'}</Typography>,
    },
    {
      field: 'ruleName',
      headerName: t('common:ruleName'),
      renderCell: (params) => {
        return <Box>{params.row.details.ruleName ?? '---'}</Box>
      },
    },
    {
      field: 'ruleAction',
      headerName: t('common:ruleAction'),
      renderCell: (params) => {
        return (
          <Box className="table-body-cell">
            {params.row.details.ruleAction ? <TableChip action={params.row.details.ruleAction} /> : '---'}
          </Box>
        )
      },
    },
    {
      field: 'status',
      headerName: t('common:status'),
      renderCell: (params) => <TableChip noIcon={true} action={params.row.status ?? 'PENDING'} />,
    },
    {
      field: 'action',
      headerName: t('common:action'),
      flex: 1,
      hide: hideActions || (location?.pathname?.includes('case-management') && !hasPermissions),
      minWidth: 200,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <Link onClick={() => navigate(`/transactions/${params.row.details.transactionId}`)}>
            {t('common:viewTxsDetails')}
          </Link>
        )
      },
    },
    {
      field: 'update',
      headerName: t('common:updateStatus'),
      renderCell: (params) => {
        return (
          <Button
            variant="outlinedChip"
            onClick={() => {
              setOpenUpdateAlert(true)
              setAlertToUpdate({ _id: params.row._id })
            }}
          >
            {t('common:update')}
          </Button>
        )
      },
    },
  ]

  return (
    <Box>
      {isFetching && <AlertFetchSpinner message={fetchMessage} error={fetchError} />}
      {isFromDetail ? (
        <TableContainer>
          <DataGrid
            getRowId={(row) => row._id || row.id}
            getRowHeight={() => 'auto'}
            rows={
              generalAlerts && Array.isArray(generalAlerts.data) && generalAlerts.data.length > 0
                ? generalAlerts.data
                : generalAlerts && Array.isArray(generalAlerts) && generalAlerts.length > 0
                ? generalAlerts
                : []
            }
            columns={
              internalType === 'transactionRule'
                ? columnsTransactions
                : internalType === 'aml'
                ? columnsAml
                : columnsAmlCrypto
            }
            hideFooter={true}
            autoHeight={true}
          />
        </TableContainer>
      ) : (
        <DataGridComponent
          rows={
            generalAlerts && Array.isArray(generalAlerts.data) && generalAlerts.data.length > 0
              ? generalAlerts.data
              : generalAlerts && Array.isArray(generalAlerts) && generalAlerts.length > 0
              ? generalAlerts
              : []
          }
          columns={
            internalType === 'transactionRule'
              ? columnsTransactions
              : internalType === 'aml'
              ? columnsAml
              : columnsAmlCrypto
          }
          count={generalAlerts?.pagination?.totalElements || 0}
          page={page}
          rowsPerPage={rowsPerPage}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          setIsLoading={setIsLoading}
          setModalOpen={setModalOpen}
          hasTabs={hasTabs}
        />
      )}

      <Modal
        open={openUpdateAlert}
        onClose={() => {
          setOpenUpdateAlert(!openUpdateAlert)
        }}
      >
        <Box className="modal">
          <Box className="modal-top">
            <Box className="modal-titles">
              <Typography variant="title">{t('common:updateAlert')}</Typography>
            </Box>
            <Fab variant="close" onClick={() => setOpenUpdateAlert(false)}>
              <CloseOutlinedIcon />
            </Fab>
          </Box>

          <Box className="modal-box-filters">
            <Box className="modal-box">
              <Typography variant="subtitle3">
                {t('messages:doYouWantUpdateAlert')} {alertToUpdate._id}?
              </Typography>
            </Box>
          </Box>

          <Box className="modal-box">
            <InputLabel>{t('common:newStatus')}:</InputLabel>
            <Select
              fullWidth
              size="small"
              value={status}
              onChange={(e) => {
                if (e.target.value) {
                  setStatus(e.target.value)
                } else setStatus('')
              }}
              displayEmpty
            >
              <MenuItem disabled value="">
                <Typography variant="subtitle3">
                  {t('common:select')} {t('common:newStatus')}
                </Typography>
              </MenuItem>
              {alertStatusValues?.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box className="modal-filter-buttons">
            <Button variant="outlinedGrey" onClick={() => setOpenUpdateAlert(!openUpdateAlert)}>
              {t('common:close')}
            </Button>
            <Button disabled={status === ''} variant="contained" onClick={handleAlertStatusChange}>
              {t('common:save')}
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}

export default GeneralAlertsDatatable

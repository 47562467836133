import './kytManualCheck.scss'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import Button from '@mui/material/Button'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { useEffect, useState } from 'react'
import { Box, Fab, InputLabel, TextField, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

const TagsManualCheck = ({ setRuleParameters, isReset, setIsReset }) => {
  const [tags, setTags] = useState([])
  const [change, setChange] = useState(false)
  const [key, setKey] = useState('')
  const [valueState, setValueState] = useState('')
  const { t } = useTranslation(['common'])

  useEffect(() => {
    if (isReset) {
      setTags([])
      setIsReset(false)
    }
  }, [isReset])

  //   propertie

  const handleKeyChange = (value, index) => {
    const aux = [...tags]
    const aux2 = aux[index]
    aux2.key = value
    aux[index] = aux2
    setTags([...aux])
    setChange(true)
  }

  const handleValueChange = (value, index) => {
    const aux = [...tags]
    const aux2 = aux[index]
    aux2.value = value
    aux[index] = aux2
    setTags([...aux])
    setChange(true)
  }

  const handleServiceRemove = (index) => {
    const aux = [...tags]
    aux.splice(index, 1)
    setTags([...aux])
    setChange(true)
  }

  const handleServiceAdd = () => {
    let objAux = [...tags]
    objAux.push({ key, value: valueState })
    setTags([...objAux])
    setKey('')
    setValueState('')
    setChange(true)
  }

  useEffect(() => {
    if (change) {
      setRuleParameters([...tags])
      setChange(false)
    }
  }, [tags, change])

  return (
    <Box className="threshold-container">
      {tags &&
        tags.map((tag, index) => (
          <Box key={index}>
            <Box className="tag-row">
              <Box className="tag-pair">
                <Typography variant="subtitle1">{t('common:key')}</Typography>
                <TextField
                  className="Input"
                  size="small"
                  placeholder={t('common:newKey')}
                  value={tag.key}
                  onChange={(event) => handleKeyChange(event.target.value, index)}
                />
              </Box>
              <Box className="tag-pair">
                <Typography variant="subtitle1">{t('common:value')}</Typography>
                <TextField
                  size="small"
                  placeholder={t('common:newKey')}
                  value={tag.value}
                  onChange={(event) => handleValueChange(event.target.value, index)}
                />
              </Box>
              <Box className="button-right">
                <Fab variant="close" className="icon-fab" onClick={() => handleServiceRemove(index)}>
                  <DeleteOutlineOutlinedIcon />
                </Fab>
              </Box>
            </Box>
          </Box>
        ))}
      <Box className="tag-row">
        <Box className="tag-pair">
          <Typography variant="subtitle1">{t('common:key')}</Typography>
          <TextField
            className="Input"
            size="small"
            placeholder={t('common:newKey')}
            value={key}
            onChange={(event) => setKey(event.target.value)}
          />
        </Box>
        <Box className="tag-pair">
          <Typography variant="subtitle1">{t('common:value')}</Typography>
          <TextField
            className="Input"
            size="small"
            placeholder={t('common:newKey')}
            value={valueState}
            onChange={(event) => setValueState(event.target.value)}
          />
        </Box>
      </Box>
      <Box className="button-right">
        <Button variant="outlinedGrey" disabled={key === '' || valueState === ''} onClick={handleServiceAdd}>
          {t('common:add')}
          <AddCircleOutlineIcon />
        </Button>
      </Box>
    </Box>
  )
}

export default TagsManualCheck

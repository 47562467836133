import { Box, InputLabel, MenuItem, Select } from '@mui/material'
import { useState } from 'react'
import { kybStatusEnum } from '../kyb.enums'
import { useTranslation } from 'react-i18next'
import { LoadingButton } from '@mui/lab'

const KybEditStatusModal = ({ defaultValue, onSubmit }) => {
  const { t } = useTranslation(['common', 'messages'])
  const [newStatus, setNewStatus] = useState(defaultValue)

  return (
    <>
      <Box className="modal-box">
        <InputLabel>{t('common:status')}:</InputLabel>
        <Select
          size="small"
          value={newStatus}
          onChange={(e) => setNewStatus(e.target.value)}
          inputProps={{ 'aria-label': 'Without label' }}
        >
          {kybStatusEnum.map((item) => (
            <MenuItem key={item} value={item}>
              {t(`common:${item}`)}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box className="modal-button">
        <LoadingButton
          size="small"
          color="secondary"
          variant="contained"
          onClick={() => onSubmit({ status: newStatus })}
        >
          {t('common:save')}
        </LoadingButton>
      </Box>
    </>
  )
}

export default KybEditStatusModal

import './styles/ocPreview.scss'
import { useTranslation } from 'react-i18next'
import { Box, Button, Divider, Fab, Modal, Paper, SwipeableDrawer, Typography } from '@mui/material'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { TableChip } from '../../../components/utilities/TableChip'

export const OCDrawer = ({ open, currentRequestToAoR, isEvaluatedBy, handleAoRCurrentRequest }) => {
  const { isOpen, setIsOpen } = open
  const { t } = useTranslation(['common'])

  const handleRejectButton = (currentRequestToAoRId) => {
    handleAoRCurrentRequest(currentRequestToAoRId)
    setIsOpen(false)
  }

  const handleApproveButton = (currentRequestToAoRId) => {
    handleAoRCurrentRequest(currentRequestToAoRId, true)
    setIsOpen(false)
  }

  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)}>
      <Box className="modal">
        <Box className="modal-top">
          <Box className="modal-titles">
            <Typography variant="title">{t('common:request')}</Typography>
          </Box>
          <TableChip action={currentRequestToAoR?.status?.toLowerCase()} noIcon={true} />
        </Box>

        {/*             <Box className="top-item">
              <p className="drawer-title">
                {currentRequestToAoR?.status !== 'PENDING'
                  ? `Request ${currentRequestToAoR?.status}`
                  : `${isEvaluatedBy ? 'Approve or Reject pending request' : 'Request pending Approval or Rejection'}`}
              </p>
            </Box> */}
        {/*  <span>
            {currentRequestToAoR?.status !== 'PENDING'
              ? 'See the details'
              : `${isEvaluatedBy ? 'See all relevant information to evaluate it' : 'See the details'}`}
          </span> */}

        <Paper variant="rootOutlined" className="preview-block">
          <Box className="preview-item">
            <Typography variant="subtitle3" className="preview-subtitle">
              Request Id
            </Typography>
            <Typography variant="title2">{currentRequestToAoR?._id}</Typography>
          </Box>
          <Box className="preview-item">
            <Typography variant="subtitle3" className="preview-subtitle">
              Requested by
            </Typography>
            <Typography variant="title2">
              {currentRequestToAoR?.requestedBy || currentRequestToAoR?.email || '---'}
            </Typography>
          </Box>
          {currentRequestToAoR?.email && (
            <Box className="preview-item">
              <Typography variant="subtitle3" className="preview-subtitle">
                Evaluated by
              </Typography>
              <Typography variant="title2">
                {currentRequestToAoR?.evaluatedBy !== '------' && currentRequestToAoR.status !== 'PENDING'
                  ? currentRequestToAoR?.evaluatedBy
                  : 'Request not evaluated yet'}
              </Typography>
            </Box>
          )}
          <Box className="preview-item">
            <Typography variant="subtitle3" className="preview-subtitle">
              Created at
            </Typography>
            <Typography variant="title2">{currentRequestToAoR?.createdAt}</Typography>
          </Box>
          <Box className="preview-item">
            <Typography variant="subtitle3" className="preview-subtitle">
              Updated at
            </Typography>
            <Typography variant="title2">{currentRequestToAoR?.updatedAt}</Typography>
          </Box>
          <Box className="preview-item">
            <Typography variant="subtitle3" className="preview-subtitle">
              Status
            </Typography>
            <Typography variant="title2">{currentRequestToAoR?.status}</Typography>
          </Box>
          <Box className="preview-item">
            <Typography variant="subtitle3" className="preview-subtitle">
              Method
            </Typography>
            <Typography variant="title2">{currentRequestToAoR?.method || '---'}</Typography>
          </Box>
          <Box className="preview-item">
            <Typography variant="subtitle3" className="preview-subtitle">
              Url
            </Typography>
            <Box sx={{ wordWrap: 'break-word' }}>
              <Typography variant="title2">{currentRequestToAoR?.route || '---'}</Typography>
            </Box>
          </Box>
          <Box className="preview-item">
            <Typography variant="subtitle3" className="preview-subtitle">
              Params
            </Typography>
            {currentRequestToAoR?.params ? (
              <Typography variant="title2" className="preview-subtitle">
                {JSON.stringify(currentRequestToAoR?.params, null, 2)}
              </Typography>
            ) : (
              <Typography variant="title2" className="preview-subtitle">
                Request without params
              </Typography>
            )}
          </Box>
          <Box className="preview-item">
            <Typography variant="subtitle3" className="preview-subtitle">
              Query
            </Typography>
            {currentRequestToAoR?.query ? (
              <Typography variant="title2" className="preview-subtitle">
                {JSON.stringify(currentRequestToAoR?.query, null, 2)}
              </Typography>
            ) : (
              <Typography variant="title2" className="preview-subtitle">
                Request without query
              </Typography>
            )}
          </Box>
          <Box className="preview-item">
            <Typography variant="subtitle3" className="preview-subtitle">
              Body
            </Typography>
            {currentRequestToAoR?.body ? (
              <Typography variant="title2" className="preview-subtitle">
                {JSON.stringify(currentRequestToAoR?.body, null, 2)}
              </Typography>
            ) : (
              <Typography variant="title2" className="preview-subtitle">
                Request without body
              </Typography>
            )}
          </Box>
        </Paper>

        <Box
          className="preview-buttons"
          sx={{ justifyContent: isEvaluatedBy ? 'space-between!important' : 'flex-end!important' }}
        >
          <Button
            variant={isEvaluatedBy ? 'outlined' : 'contained'}
            color="primary"
            onClick={() => {
              setIsOpen(false)
            }}
          >
            {t('common:close')}
          </Button>
          {isEvaluatedBy && (
            <Box className="preview-sub-buttons">
              <Button
                variant="contained"
                disabled={!isEvaluatedBy || currentRequestToAoR?.status !== 'PENDING'}
                onClick={() => handleApproveButton(currentRequestToAoR._id)}
              >
                {t('common:approve')}
              </Button>

              <Button
                variant="contained"
                onClick={() => handleRejectButton(currentRequestToAoR._id)}
                disabled={!isEvaluatedBy || currentRequestToAoR?.status !== 'PENDING'}
              >
                {t('common:reject')}
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </Modal>
  )
}

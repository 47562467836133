import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpApi from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

const backendOpts = {
  loadPath: '/locales/{{lng}}/{{ns}}.json',
}

const storageRoot = localStorage.getItem('persist:root')
const storageLang = storageRoot ? JSON.parse(storageRoot).language : ''
const lang = storageLang ? JSON.parse(storageLang) : ''

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    keySeparator: false,
    fallbackLng: lang.lang || 'en',
    browserLanguageDetection: false,
    backend: backendOpts,
    detection: {
      order: ['localStorage', 'navigator'],
      caches: ['localStorage'],
    },
  })

export default i18n

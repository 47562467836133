import { useNavigate } from 'react-router-dom'
import './workflows.scss'
import { Box } from '@mui/material'

const WorkflowsOptions = () => {
  const navigate = useNavigate()
  return (
    <Box className="workflow-datatable">
      <Box className="workflow-wrapper">
        <Box className="workflow-head">
          <h2 className="workflow-text">Start Building!</h2>
          <Box></Box>
        </Box>
        <Box className="options-parent">
          <Box className="options-view">
            <Box className="item">
              <img
                src="https://res.cloudinary.com/g-eno/image/upload/v1695755038/Testing/group-ia.png"
                alt=""
                className="img-cursor"
                onClick={() => {
                  navigate('/workflows/ai')
                }}
              />
            </Box>
            <Box>
              <img
                src="https://res.cloudinary.com/g-eno/image/upload/v1695755038/Testing/group-scratch.png"
                alt=""
                className="img-cursor"
                onClick={() => {
                  navigate('/workflows/ai')
                }}
              />
            </Box>
            <Box>
              <img
                src="https://res.cloudinary.com/g-eno/image/upload/v1695755038/Testing/group-template.png"
                alt=""
                className="img-cursor"
                onClick={() => {
                  navigate('/workflows/F-143')
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default WorkflowsOptions

import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const getCleanEvents = (events) => {
  return events.map((e) => {
    return {
      id: e.eventId,
      transactionId: e.transactionId,
      createdDate:
        new Date(e.timestamp).toLocaleDateString() !== 'Invalid Date'
          ? new Date(e.timestamp).toLocaleDateString()
          : '---',
      transactionState: e.transactionState || '---',
      description: e?.eventDescription || '---',
      reason: e?.reason || '---',
    }
  })
}

export const useEvents = (clientEmail, transactionId) => {
  const [transactionEvents, setTransactionEvents] = useState([])
  const { accessToken } = useSelector((state) => state.login)

  useEffect(() => {
    let urlGetTransactionEvents

    const options = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
    if (transactionId) {
      urlGetTransactionEvents = `${process.env.REACT_APP_BASEURL}/kyt/retrieveAllClientTransactionEvents/${clientEmail}/${transactionId}`

      fetch(urlGetTransactionEvents, options)
        .then((res) => res.json())
        .then((res) => {
          if (res.data?.length > 0) {
            setTransactionEvents(getCleanEvents(res.data))
          }
        })
        .catch((error) => console.error('[HOOK: useEvents] --> ', error))
    }

    fetch(process.env.REACT_APP_IS_DEMO ? '../../data/transactionEvents.json' : urlGetTransactionEvents, options)
      .then((res) => res.json())
      .then((res) => {
        if (res.data?.length > 0) {
          setTransactionEvents(getCleanEvents(res.data))
        }
      })
      .catch((error) => console.error('[HOOK: useEvents] --> ', error))
  }, [])

  return transactionEvents
}

import './webhooks.scss'
import { Box, Button, Fab, InputLabel, MenuItem, Modal, Paper, Select, Typography } from '@mui/material'
import { TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DatatableWebhooks from '../../../components/common/datatable/DatatableWebhooks'
import { updateWebhooks, updateWebhooksFilters } from '../../../features/webhooks/webhooksSlice'
import { useTranslation } from 'react-i18next'
import MyDatePicker from '../../../components/utilities/MyDatePicker'
import { FilterModal } from '../../../components/utilities/FilterModal'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'

const Webhooks = () => {
  const { emailClient, accessToken } = useSelector((state) => state.login)
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [edit, setEdit] = useState(false)
  const [error, setError] = useState(false)
  const [webhookUrl, setWebhookUrl] = useState('')
  const [serviceName, setServiceName] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [fetchError, setFetchError] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [fetchMessage, setFetchMessage] = useState('')
  const [activeWebhook, setActiveWebhook] = useState({ id: null })
  const { t } = useTranslation(['common', 'messages'])
  const queryState = useSelector((state) => state.webhooks?.filters)
  const [isLoading, setIsLoading] = useState(true)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [id, setId] = useState('')
  const [selectedDate, setSelectedDate] = useState(queryState?.fromDate)
  const [toDate, setToDate] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)

  let isQueryOk = !id && !selectedDate

  const handleCloseDelete = () => {
    setOpenDelete(false)
    setEdit(false)
    setActiveWebhook({ id: null })
    setErrorMessage('')
    setError(false)
  }

  const handleFetch = (error, message) => {
    setIsFetching(true)
    setFetchError(error)
    setFetchMessage(message)
    setTimeout(() => {
      setIsFetching(false)
    }, 3000)
  }

  const handleResetButton = () => {
    if (id !== '') setId('')
    if (selectedDate) setSelectedDate(null)
    setPage(0)
    setIsLoading(true)

    return dispatch(
      updateWebhooksFilters({
        page: 0,
        limit: 25,
        offset: 0,
        id: '',
        fromDate: null,
        toDate: '',
      }),
    )
  }

  const handleDispatch = () => {
    setIsLoading(true)
    return dispatch(
      updateWebhooksFilters({
        limit: rowsPerPage,
        offset: rowsPerPage * page,
        page,
        id,
        fromDate: selectedDate?.toISOString(),
        toDate: new Date(toDate)?.toISOString(),
      }),
    )
  }

  const handleClose = () => {
    setOpen(false)
    setEdit(false)
    setWebhookUrl('')
    setServiceName('')
    setActiveWebhook({ id: null })
    setErrorMessage('')
    setError(false)
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    if (!process.env.REACT_APP_IS_DEMO) {
      const data = {
        emailClient,
        active: true,
        webhookUrl,
        service: serviceName,
      }

      const urlWebhooks = `${process.env.REACT_APP_BASEURL}/webhooks`
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(data),
      }

      fetch(urlWebhooks, options)
        .then((res) => res.json())
        .then((res) => {
          if (!res.success) throw Error(typeof res.data === 'string' ? res.data : res.message)
          handleClose()
          setPage(0)
          updateWebhookList()
          handleFetch(false, `Webhook ${t('messages:creationSuccess')}`)
        })
        .catch((error) => {
          console.error('[CREATE WEBHOOK ERROR] --> ', error)
          setError(true)
          setErrorMessage(error.message ? error.message : `Webhook ${t('messages:creationError')}`)
          handleFetch(true, error.message ? error.message : `Webhook ${t('messages:creationError')}`)
        })
    } else {
      handleClose()
      setPage(0)
      updateWebhookList()
      handleFetch(false, `Webhook ${t('messages:creationSuccess')}`)
    }
  }

  const handleDelete = (e) => {
    e.preventDefault()

    if (!process.env.REACT_APP_IS_DEMO) {
      const urlWebhooks = `${process.env.REACT_APP_BASEURL}/webhooks/${activeWebhook.id}`
      const options = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      }

      fetch(urlWebhooks, options)
        .then((res) => {
          if (!res.status || res.status !== 204)
            throw Error(`${t('messages:creationError')}. ${t('messages:contactSupport')}.`)
          handleCloseDelete()
          updateWebhookList()
          handleFetch(false, `Webhook ${t('messages:deletionSuccess')}`)
        })
        .catch((error) => {
          console.error('[DELETE WEBHOOK ERROR] --> ', error)
          handleFetch(true, error.message ? error.message : `Webhook ${t('messages:deletionError')}`)
        })
    } else {
      handleCloseDelete()
      updateWebhookList()
      handleFetch(false, `Webhook ${t('messages:deletionSuccess')}`)
    }
  }

  const handleUpdate = (e, row) => {
    e.preventDefault()
    let data

    if (row) {
      data = {
        active: row.active === true ? false : true,
      }
    } else {
      data = {
        service: serviceName,
        webhookUrl,
      }
    }

    const urlWebhooks = `${process.env.REACT_APP_BASEURL}/webhooks/${row ? row.id : activeWebhook.id}`
    if (!process.env.REACT_APP_IS_DEMO) {
      const options = {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(data),
      }

      fetch(urlWebhooks, options)
        .then((res) => res.json())
        .then((res) => {
          if (!res.success) throw Error(typeof res.data === 'string' ? res.data : res.message)
          handleClose()
          updateWebhookList()
          handleFetch(false, `Webhook ${t('messages:updateSuccess')}`)
        })
        .catch((error) => {
          console.error('[UPDATE WEBHOOK ERROR] --> ', error)
          handleFetch(true, error.message ? error.message : `Webhook ${t('messages:updateError')}`)
        })
    } else {
      handleClose()
      updateWebhookList()
      handleFetch(false, `Webhook ${t('messages:updateSuccess')}`)
    }
  }

  const updateWebhookList = () => {
    const { limit, offset, fromDate, toDate, page, id } = queryState
    const baseUrlWebhooks = `${process.env.REACT_APP_BASEURL}/webhooks`

    function buildUrl(baseUrl) {
      let url = `${baseUrl}?limit=${limit}&page=${page}&offset=${offset}`
      if (fromDate && toDate) url += `&fromDate=${fromDate}&toDate=${toDate}`
      if (id?.length) url += `&id=${id}`
      if (emailClient?.length) url += `&emailClient=${emailClient}`

      return url
    }
    let url = buildUrl(baseUrlWebhooks)

    fetch(process.env.REACT_APP_IS_DEMO ? '../data/webhooks.json' : url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res) {
          const { data, pagination } = res
          const aux = { data, pagination }
          dispatch(updateWebhooks(aux))
        }
      })
      .catch((error) => console.error('[UPDATE: webhooks] --> ', error))
  }

  useEffect(() => {
    handleDispatch()
  }, [rowsPerPage, page])

  useEffect(() => {
    if (selectedDate) {
      const formattedDate = new Date(selectedDate)
      setToDate(selectedDate ? formattedDate.setDate(formattedDate.getDate() + 1) : null)
    }
  }, [selectedDate])

  return (
    <Box>
      <Box className={`${isLoading && 'spinner-transition'} filter`}>
        <Box className="component-title-wrapper">
          <Box className="component-title">
            <Typography variant="h2">{t('common:webhooks')}</Typography>
            <Box className="download-bar">
              <Button
                variant="outlined"
                onClick={() => {
                  setOpen(true)
                }}
              >
                {`${t('common:create')} Webhook`}
              </Button>
            </Box>
          </Box>
          <Typography variant="subtitle3">
            {/* Loren ipsum dolor sit amet Loren ipsum dolor sit amet Loren  */}
          </Typography>
        </Box>

        <Paper elevation={0}>
          <DatatableWebhooks
            setModalOpen={setModalOpen}
            setIsLoading={setIsLoading}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            setOpenDelete={setOpenDelete}
            setOpen={setOpen}
            setEdit={setEdit}
            setError={setError}
            setErrorMessage={setErrorMessage}
            activeWebhook={activeWebhook}
            setActiveWebhook={setActiveWebhook}
          />
        </Paper>
      </Box>

      <FilterModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        handleResetButton={handleResetButton}
        setPage={setPage}
        handleDispatch={handleDispatch}
      >
        <Box className="modal-box-filters">
          <Box className="modal-box">
            <Typography variant="subtitle3">
              {t('common:by')} {t('common:date')}:
            </Typography>

            <MyDatePicker selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
          </Box>

          <Box className="modal-box">
            <Typography variant="subtitle3">{t('common:by')} ID:</Typography>

            <TextField
              variant="outlined"
              size="small"
              value={id}
              onChange={(event) => setId(event.target.value)}
              placeholder={`${t('common:insert')} ID`}
            />
          </Box>
        </Box>
      </FilterModal>

      <Modal open={open} onClose={handleClose}>
        <Box className="modal">
          <Box className="modal-top">
            <Box className="modal-titles">
              <Typography variant="title">
                {edit ? `${t('common:edit')} Webhook` : `${t('common:create')} Webhook`}
              </Typography>
              {edit && <Typography variant="subtitle3">ID: {activeWebhook.id}</Typography>}
            </Box>
            <Fab variant="close" onClick={handleClose}>
              <CloseOutlinedIcon />
            </Fab>
          </Box>

          <Box component="form" onSubmit={(e) => (edit ? handleUpdate(e) : handleSubmit(e))}>
            <Box className="modal-box-filters">
              <Box className="modal-box">
                <InputLabel htmlFor="email">Webhook Url</InputLabel>
                <TextField
                  size="small"
                  value={webhookUrl}
                  onChange={(e) => setWebhookUrl(e.target.value)}
                  required
                  id="webhookurl"
                  placeholder={`${t('common:insert')} Webhook Url`}
                ></TextField>
              </Box>

              <Box className="modal-box">
                <InputLabel htmlFor="service">{t('common:serviceUp')}:</InputLabel>
                <Select
                  displayEmpty
                  id="service"
                  className="currency"
                  size="small"
                  value={serviceName}
                  onChange={(e) => setServiceName(e.target.value)}
                >
                  <MenuItem disabled value="">
                    {t('common:select')} {t('common:serviceUp')}
                  </MenuItem>
                  {['AML', 'BATCH', 'KYB_COMPANY', 'KYB_PERSON'].map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </Box>

            {errorMessage ? (
              <Button size="small" variant="outlined" color="error">
                {errorMessage}
              </Button>
            ) : (
              ''
            )}
            <Box className="modal-filter-buttons">
              <Button variant="outlinedGrey" size="small" onClick={handleClose}>
                {t('common:clearAll')}
              </Button>

              <Button disabled={error} size="small" variant="contained" type="submit">
                {t('common:save')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>

      <Modal open={openDelete} onClose={handleCloseDelete}>
        <Box className="modal">
          <Box className="modal-top">
            <Box className="modal-titles">
              <Typography variant="title">{t('common:delete')} Webhook</Typography>
            </Box>
            <Fab variant="close" onClick={handleCloseDelete}>
              <CloseOutlinedIcon />
            </Fab>
          </Box>

          <Box component="form" onSubmit={(e) => handleDelete(e)}>
            <Box className="webhook-text-delete">
              <Typography variant="subtitle">Are you sure you want to delete the selected webhook?</Typography>
              <Typography variant="title">{activeWebhook?.id}</Typography>
              <Typography variant="subtitle3">This action is irreversible.</Typography>
            </Box>
            {errorMessage ? (
              <Button size="small" variant="outlined" color="error">
                {errorMessage}
              </Button>
            ) : (
              ''
            )}
            <Box className="modal-filter-buttons">
              <Button variant="outlinedGrey" onClick={() => handleCloseDelete()}>
                {t('common:no')}
              </Button>
              <Button variant="contained" type="submit">
                {t('common:yes')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}

export default Webhooks

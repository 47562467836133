import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import VisibilityIcon from '@mui/icons-material/Visibility'
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import Spinner from '../../components/common/spinner/spinner'
import { useTranslation } from 'react-i18next'

const Reports = () => {
  const [reports, setReports] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const { accessToken } = useSelector((state) => state.login)
  const { t } = useTranslation(['common'])

  // TODO: tiene que ir al BE
  useEffect(() => {
    const res = require('../../data/reportTypes.json')
    setReports(res.data)
  }, [accessToken])

  return (
    <Box className={`${isLoading && 'spinner-transition'} filter`}>
      {isLoading && <Spinner />}
      <Box className="component-title-wrapper">
        <Box className="component-title">
          <Typography variant="h2">{t('common:reports')}</Typography>
        </Box>
        <Typography variant="subtitle3">
          {/* Loren ipsum dolor sit amet Loren ipsum dolor sit amet Loren  */}
        </Typography>
      </Box>

      <Paper elevation={0}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('common:name')}</TableCell>
                <TableCell>{t('common:key')}</TableCell>
                <TableCell>{t('common:description')}</TableCell>
                <TableCell>{t('common:details')}</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {reports &&
                reports?.map((row, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.key}</TableCell>
                      <TableCell>{row.description}</TableCell>
                      <TableCell>
                        <Box
                          style={{
                            display: 'flex',
                            gap: '.5rem',
                            alignItems: 'center',
                          }}
                        >
                          <IconButton
                            onClick={() => {
                              navigate(`/reports/${row.key}`)
                            }}
                            style={{ color: '#0DDE4E' }}
                          >
                            <VisibilityIcon style={{ height: '1.45rem' }} />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  )
}

export default Reports

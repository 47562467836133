import './CreateFilters.scss'
import '../../common/spinner/spinner.scss'
import SelectSingle from '../rules/ruleParameters2/selectSingle/selectSingle'
import SelectMultiple from '../rules/ruleParameters2/selectMultiple/selectMultiple'
import FatherListOfStrings from '../rules/ruleParameters2/fatherListOfStrings/fatherListOfStrings'
import TimeRange from '../rules/ruleParameters2/timeRange/timeRange'
import LowTransactionValues from '../rules/ruleParameters2/lowTransactionValues/lowTransactionValues'
import { Box, Divider, Typography } from '@mui/material'
import Tags from '../rules/ruleParameters2/tags/tags'
import StringArrayInput from '../rules/ruleParameters2/StringArrayInput/stringArrayInput'
import PaymentFilters from '../rules/paymentFilters/paymentFilters'
import { useState } from 'react'
import { useRuleFilters } from '../../../pages/pages-hooks/useRuleFilters'
import { camelCaseFormatter } from '../../utilities/formatters/camelCase'

const CreateFilters = ({
  filterParameters,
  setFilterParameters,
  setError,
  errorList,
  setErrorList,
  open,
  isEditing,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [filters, setFilters] = useState({})

  useRuleFilters(isLoading, setIsLoading, filters, setFilters)

  return (
    <Box>
      {isLoading && (
        <Box className="spinner-filters">
          <img src="/guenoSpinnerMini-blue.gif" alt="spinner-loading-data" width="120px" />
        </Box>
      )}

      {!isLoading &&
        filters[0]?.data?.type === 'object' &&
        Object.keys(filters[0]?.data?.properties).map((property, index) => {
          const filter = filters[0]?.data?.properties[property]
          return (
            <Box key={index}>
              <Box>
                <Box className="title-parameter">
                  {filter.title && <Typography variant="title3">{camelCaseFormatter(filter.title)}</Typography>}
                </Box>
                {filter.type === 'object' && filter.properties?.listIds && (
                  <FatherListOfStrings
                    isEditing={isEditing}
                    propertie={property}
                    ruleParameters={filterParameters}
                    setRuleParameters={setFilterParameters}
                    setError={setError}
                    errorList={errorList}
                    setErrorList={setErrorList}
                  />
                )}
                {filter.type === 'object' && filter.title.includes('payment filters') && (
                  <PaymentFilters
                    isEditing={isEditing}
                    propertie={property}
                    ruleParameters={filterParameters}
                    setRuleParameters={setFilterParameters}
                    setError={setError}
                    errorList={errorList}
                    setErrorList={setErrorList}
                    possibleValues={filter.properties?.paymentMethods?.items?.enum}
                    possibleCountries={filter?.properties?.cardIssuedCountries?.items?.enum}
                  />
                )}
                {filter.type === 'array' && filter.items?.type === 'string' && filter.title === 'Target user ID' && (
                  <StringArrayInput
                    isEditing={isEditing}
                    propertie={property}
                    ruleParameters={filterParameters}
                    setRuleParameters={setFilterParameters}
                    setError={setError}
                    errorList={errorList}
                    setErrorList={setErrorList}
                  />
                )}
                {filter.type === 'array' && filter.title === 'Product types' && (
                  <StringArrayInput
                    isEditing={isEditing}
                    propertie={property}
                    ruleParameters={filterParameters}
                    setRuleParameters={setFilterParameters}
                    setError={setError}
                    errorList={errorList}
                    setErrorList={setErrorList}
                  />
                )}
                {filter.type === 'string' && filter.enum && (
                  <SelectSingle
                    isEditing={isEditing}
                    propertie={property}
                    ruleParameters={filterParameters}
                    setRuleParameters={setFilterParameters}
                    posibleValues={filter.enum}
                    desc={filter.description}
                    open={open}
                  />
                )}
                {filter.type === 'array' && filter.items.type === 'string' && filter.items.enum && (
                  <SelectMultiple
                    isEditing={isEditing}
                    propertie={property}
                    ruleParameters={filterParameters}
                    setRuleParameters={setFilterParameters}
                    posibleValues={filter.items?.enum}
                  />
                )}
                {filter.type === 'object' && filter.properties && Object.keys(filter.properties).includes('minAge') && (
                  <TimeRange
                    isEditing={isEditing}
                    propertie={property}
                    ruleParameters={filterParameters}
                    setRuleParameters={setFilterParameters}
                    schema={filter}
                    desc={filter.description}
                    setError={setError}
                    errorList={errorList}
                    setErrorList={setErrorList}
                  />
                )}
                {filter.type === 'object' && filter.additionalProperties?.type === 'object' && (
                  <LowTransactionValues
                    isEditing={isEditing}
                    propertie={property}
                    ruleParameters={filterParameters}
                    setRuleParameters={setFilterParameters}
                    setError={setError}
                    errorList={errorList}
                    setErrorList={setErrorList}
                  />
                )}
                {filter.type === 'object' && filter.title.includes('tags') && (
                  <Tags
                    isEditing={isEditing}
                    propertie={property}
                    ruleParameters={filterParameters}
                    setRuleParameters={setFilterParameters}
                    setError={setError}
                    errorList={errorList}
                    setErrorList={setErrorList}
                    isRequired={false}
                    description={filter.description}
                  />
                )}
                <Divider sx={{ margin: '1rem 0' }} />
              </Box>
            </Box>
          )
        })}
    </Box>
  )
}

export default CreateFilters

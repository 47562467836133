import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setExternalPaymentMethodsData } from '../../features/externalPaymentMethods/externalPaymentMethodsSlice'

export const useExternalPaymentMethods = (clientEmail, isLoading, setIsLoading) => {
  const { accessToken } = useSelector((state) => state.login)
  const queryState = useSelector((state) => state.externalPaymentMethods.filters)
  const sortedTransactions = useSelector((state) => state.externalPaymentMethods.sorting.sortTransactions)

  const dispatch = useDispatch()

  const baseUrlExternalPaymentMethods = `${process.env.REACT_APP_BASEURL}/kyt/externalPaymentMethods/${clientEmail}`
  function buildUrl(baseUrl) {
    let url = `${baseUrl}?limit=${queryState.limit}&page=${queryState.page}&offset=${queryState.offset}`
    if (queryState.fromDate && queryState.toDate) {
      url += `&fromDate=${queryState.fromDate}&toDate=${queryState.toDate}`
    }
    if (queryState.mode) url += `&mode=${queryState.mode}`
    if (sortedTransactions !== undefined) url += `&sortTransactions=${sortedTransactions}`
    if (queryState.paymentMethod) url += `&paymentMethod=${queryState.paymentMethod}`
    if (queryState.paymentMethodId) url += `&paymentMethodId=${queryState.paymentMethodId}`
    if (queryState.transactions) url += `&transactions=${queryState.transactions}`
    if (queryState.transactionType?.length) url += `&transactionType=${queryState.transactionType.join(',')}`
    if (queryState.transactionState?.length) url += `&transactionState=${queryState.transactionState.join(',')}`

    return url
  }

  let urlGetExternalPaymentMethods = buildUrl(baseUrlExternalPaymentMethods)
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  }

  useEffect(() => {
    if (isLoading) {
      fetch(process.env.REACT_APP_IS_DEMO ? '../../data/externalPaymentMethods.json': urlGetExternalPaymentMethods, options)
        .then((res) => {
          return res.json()
        })
        .then((res) => {
          if (res.success) {
            const { items, pagination } = res.data
            dispatch(setExternalPaymentMethodsData({ data: items, pagination }))
          }
          setIsLoading(false)
        })
        .catch((error) => {
          console.error('[HOOK: useExternalPaymentMethods] --> ', error)
          setIsLoading(false)
        })
    }
  }, [queryState, sortedTransactions])
}

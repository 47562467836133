import { createSlice } from '@reduxjs/toolkit'

export const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState: {
    onboardings: {},
    onboardingDetail: {},
    onboardingDetailType: 'document_check',
    filters: {
      page: 0,
      limit: 25,
      offset: 0,
      fromDate: null,
      toDate: '',
      checkId: '',
      type: null,
      entityName: '',
    },
  },
  reducers: {
    updateOnboardings: (state, action) => {
      state.onboardings = action.payload
    },
    updateOnboardingDetail: (state, action) => {
      state.onboardingDetail = action.payload
    },
    updateOnboardingFilters: (state, action) => {
      state.filters = action.payload
    },
    updateOnboardingDetailType: (state, action) => {
      state.onboardingDetailType = action.payload
    },
  },
})

export const { updateOnboardings, updateOnboardingDetail, updateOnboardingFilters, updateOnboardingDetailType } =
  onboardingSlice.actions
export default onboardingSlice.reducer

import { Box, CircularProgress } from '@mui/material'
import './spinner.scss'
import { useSelector } from 'react-redux'

const SuspenseSpinner = () => {
  const { theme } = useSelector((state) => state.theme)

  return (
    <Box className="suspense-spinner-wrapper" sx={{ backgroundColor: theme === 'light' ? '#FCFFFD' : '#020D06' }}>
      <Box className="suspense-spinner">
        <svg width={0} height={0}>
          <defs>
            <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor="#19DB55" />
              <stop offset="100%" stopColor="#19DB5542" />
            </linearGradient>
          </defs>
        </svg>
        <CircularProgress sx={{ 'svg circle': { stroke: 'url(#my_gradient)' } }} size={60} />
      </Box>
    </Box>
  )
}

export default SuspenseSpinner

import '../../home/home.scss'
import '../../onboarding/onboarding.scss'
import '../../kyb/kyb.scss'
import './lists.scss'
import {
  Box,
  Tab,
  TablePagination,
  TableContainer,
  Typography,
  InputLabel,
  Select,
  OutlinedInput,
  Modal,
  MenuItem,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  FormControlLabel,
  Paper,
  Chip,
  Fab,
} from '@mui/material'
import { TextField } from '@mui/material'
import Button from '@mui/material/Button'
import ManageSearchIcon from '@mui/icons-material/ManageSearch'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import '../../../components/common/alertFetchSpinner/alertFetchSpinner.scss'
import { buildTimeStringFromTimestamp } from '../../../components/common/time/timeHelper'
import Spinner from '../../../components/common/spinner/spinner'
import { useTranslation } from 'react-i18next'
import { useLists } from '../../pages-hooks/useLists'
import { updateListsFilters } from '../../../features/lists/listsSlice'
import AlertFetchSpinner from '../../../components/common/alertFetchSpinner/alertFetchSpinner'
import { useNavigate, useParams } from 'react-router-dom'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { MenuProps } from '../../../components/utilities/MenuProps'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { camelCaseFormatter } from '../../../components/utilities/formatters/camelCase'
import ProcessingSpinner from '../../../components/common/alertFetchSpinner/processingSpinner'
import { FilterModal } from '../../../components/utilities/FilterModal'
import MyDatePicker from '../../../components/utilities/MyDatePicker'
import CustomNoRowsOverlay from '../../../components/common/datagrid/CustomNoRowsOverlay'
import { DataGridComponent } from '../../../components/common/datagrid/DataGridComponent'

const Lists = () => {
  const { tab } = useParams()
  const { accessToken, emailClient } = useSelector((state) => state.login)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const queryState = useSelector((state) => state.lists.filters)
  const [isLoading, setIsLoading] = useState(true)
  const [isLoading2, setIsLoading2] = useState(false)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [pageModal, setPageModal] = useState(0)
  const [rowsPerPageModal, setRowsPerPageModal] = useState(5)
  const [toDate, setToDate] = useState(queryState.toDate)
  const [actualTab, setActualTab] = useState('whitelist')
  const [id, setId] = useState(queryState.id)
  const [status, setStatus] = useState(queryState.status ?? '')
  const [selectedDate, setSelectedDate] = useState(queryState.fromDate)
  const [description, setDescription] = useState(queryState.description ?? '')
  const [fetchError, setFetchError] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [fetchMessage, setFetchMessage] = useState('')
  const [isProcessing, setIsProcessing] = useState(false)
  const [listName, setListName] = useState(queryState.title ?? '')
  const [modalOpen, setModalOpen] = useState(false)
  const [modalOpen2, setModalOpen2] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [type, setType] = useState(tab === 'whitelist' ? 'white_list' : 'black_list')
  const [newTitle, setNewTitle] = useState('')
  const [newDescription, setNewDescription] = useState('')
  const [context, setContext] = useState('userId')
  const [usersList, setUsersList] = useState(null)
  const [userName, setUsername] = useState([])
  const [userType, setUserType] = useState('consumer')
  const [selectedUsers, setSelectedUsers] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [isViewDetail, setIsViewDetail] = useState(false)
  const [activeList, setActiveList] = useState(null)
  const [change, setChange] = useState(false)

  const { t } = useTranslation(['common', 'messages'])

  const whiteContext = [{ id: 'userId', title: t('common:userId') }]

  const blackContext = [
    { id: 'userId', title: t('common:userId') },
    { id: 'cardFingerprint', title: 'Card Fingerprint Number' },
    { id: 'bankAccount', title: 'Bank Account Number' },
    { id: 'iban', title: 'IBAN Number' },
    { id: 'achAccount', title: 'ACH Account Number' },
    { id: 'swiftAccount', title: 'SWIFT Account Number' },
    { id: 'walletId', title: 'Wallet ID' },
  ]

  const handleCheckboxChange = (userId) => {
    if (selectedUsers.includes(userId)) {
      setSelectedUsers(selectedUsers.filter((id) => id !== userId))
    } else {
      setSelectedUsers([...selectedUsers, userId])
    }
  }

  const handleFetch = (error, message) => {
    setIsFetching(true)
    setFetchError(error)
    setFetchMessage(message)
    setTimeout(() => {
      setIsFetching(false)
    }, 3000)
  }

  const columns = [
    {
      field: 'title',
      headerName: t('common:listName'),
      renderCell: (params) => {
        return <p>{params.row.title ?? '---'}</p>
      },
    },
    {
      field: 'description',
      headerName: t('common:description'),
      renderCell: (params) => {
        return <p>{params.row.description ?? '---'}</p>
      },
    },
    {
      field: 'createdAt',
      headerName: t('common:createdAt'),
      renderCell: (params) => {
        return <Typography variant="number">{buildTimeStringFromTimestamp(params.row.createdAt)}</Typography>
      },
    },
    {
      field: 'context',
      headerName: t('common:context'),
      renderCell: (params) => {
        return `${blackContext.filter((bc) => params.row?.context === bc.id)[0]?.title || '---'}`
      },
    },
    {
      field: 'actions',
      headerName: t('common:actions'),
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <Box>
            <Box style={{ display: 'flex', gap: '.5rem', alignItems: 'center', padding: '.5rem 0' }}>
              <Fab
                variant="close"
                className="icon-fab"
                onClick={() => {
                  setModalOpen(true)
                  setIsViewDetail(true)
                  setActiveList(params.row?.id)
                  setNewTitle(params.row?.title)
                  setContext(params.row?.context)
                  setSelectedUsers(params.row?.list)
                  setNewDescription(params.row?.description)
                }}
              >
                <VisibilityIcon />
              </Fab>

              <Fab
                variant="close"
                className="icon-fab"
                onClick={() => {
                  handleDelete(params.row.id)
                }}
              >
                <DeleteOutlineOutlinedIcon />
              </Fab>
            </Box>
          </Box>
        )
      },
    },
  ]

  useLists(setIsLoading, isLoading, tab)

  const lists = useSelector((state) => state.lists.lists)

  const handleResetButton = () => {
    if (id !== '') setId('')
    if (status) setStatus('')
    if (listName) setListName('')
    if (description) setDescription('')
    if (selectedDate) setSelectedDate(null)
    setPage(0)
    setIsLoading(true)

    return dispatch(
      updateListsFilters({
        page: 0,
        limit: 25,
        offset: 0,
        id: '',
        status: null,
        description: null,
        title: '',
        fromDate: null,
        toDate: null,
      }),
    )
  }

  const handleDispatch = () => {
    setIsLoading(true)
    return dispatch(
      updateListsFilters({
        limit: rowsPerPage,
        offset: rowsPerPage * page,
        page,
        id,
        status,
        description,
        title: listName,
        fromDate: selectedDate ?? selectedDate?.toISOString(),
        toDate: toDate ? new Date(toDate)?.toISOString() : null,
      }),
    )
  }

  const handleChangePageModal = (event, newPage) => {
    setPageModal(newPage)
  }

  const handleChangeRowsPerPageModal = (event) => {
    setRowsPerPageModal(parseInt(event.target.value, 10))
    setPageModal(0)
    setIsLoading2(true)
  }

  const handleChange = (event, newValue) => {
    handleResetButton()
    navigate(`/admin/lists/${newValue}`)
  }

  useEffect(() => {
    handleDispatch()
  }, [rowsPerPage, page])

  useEffect(() => {
    if (modalOpen && ((isEdit && isViewDetail) || (!isViewDetail && !isEdit))) {
      handleSearch()
    }
  }, [rowsPerPageModal, pageModal])

  useEffect(() => {
    if (selectedDate) {
      const formattedDate = new Date(selectedDate)
      setToDate(selectedDate ? formattedDate.setDate(formattedDate.getDate() + 1) : null)
    }
  }, [selectedDate])

  useEffect(() => {
    if (!tab || (tab !== 'whitelist' && tab !== 'blocklist')) {
      navigate('/admin/lists/whitelist')
      setActualTab('whitelist')
    } else {
      setActualTab(tab)
      setType(tab === 'whitelist' ? 'white_list' : 'black_list')
    }
  }, [tab])

  const handleDelete = (id) => {
    setIsLoading(true)

    if (!process.env.REACT_APP_IS_DEMO) {
      const urlAdminRoles = `${process.env.REACT_APP_BASEURL}/admin/lists/${id}`
      const options = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      }

      fetch(urlAdminRoles, options)
        .then((res) => {
          if (res) {
            if (!res.status || res.status !== 204) throw Error(`${t('common:list')} ${t('messages:deletionError')}`)
            handleFetch(false, `${t('common:list')} ${t('messages:deletionSuccess')}`)
          }
        })
        .catch((error) => {
          console.error('[LIST DELETION ERROR] --> ', error)
          handleFetch(true, error.message ? error.message : 'List deletion error')
        })
    } else {
      handleFetch(false, `${t('common:list')} ${t('messages:deletionSuccess')}`)
    }

    handleFetch(false)
  }

  const handleSubmit = (e, id) => {
    e.preventDefault()
    setIsProcessing(true)
    setIsLoading(true)
    const isUpdate = isEdit && id

    if (!process.env.REACT_APP_IS_DEMO) {
      let data = {
        title: newTitle,
        description: newDescription,
        type,
        list: selectedUsers,
        context,
      }
      if (!isUpdate) data['emailClient'] = emailClient

      const urlUser = isUpdate
        ? `${process.env.REACT_APP_BASEURL}/admin/lists/${id}`
        : `${process.env.REACT_APP_BASEURL}/admin/lists`

      const options = {
        method: isUpdate ? 'PATCH' : 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(data),
      }

      fetch(urlUser, options)
        .then((res) => res.json())
        .then((res) => {
          if (!res.success) throw Error(typeof res.data === 'string' ? res.data : res.message)
          setIsProcessing(false)
          setModalOpen(false)
          setPage(0)
          handleFetch(
            false,
            `${t('common:list')} ${isUpdate ? t('messages:updateSuccess') : t('messages:creationSuccess')}`,
          )
          handleModalClose()
        })
        .catch((error) => {
          console.error(`[${isUpdate ? 'UPDATE' : 'CREATE'} LIST ERROR] --> `, error)
          setIsProcessing(false)
          setErrorMessage(
            error.message
              ? error.message
              : `${t('common:list')} ${isUpdate ? t('messages:updateError') : t('messages:creationError')}`,
          )
          handleFetch(
            true,
            error.message
              ? error.message
              : `${t('common:list')} ${isUpdate ? t('messages:updateError') : t('messages:creationError')}`,
          )
        })
    } else {
      setIsProcessing(false)
      setModalOpen(false)
      setPage(0)
      handleFetch(
        false,
        `${t('common:list')} ${isUpdate ? t('messages:updateSuccess') : t('messages:creationSuccess')}`,
      )
      handleModalClose()
    }
  }

  const handleSearch = () => {
    try {
      setIsLoading2(true)
      let url = `${process.env.REACT_APP_BASEURL}/kyt/users?limit=${rowsPerPageModal}&offset=${
        rowsPerPageModal * pageModal
      }&type=${userType}&value=${userName}&page=${pageModal}`

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      }

      fetch(
        process.env.REACT_APP_IS_DEMO
          ? userType === 'business'
            ? '../../data/kyt.business.view.json'
            : '../../data/kyt.consumer.view.json'
          : url,
        options,
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.data) {
            setUsersList(res)
            setIsLoading2(false)
          }
        })
        .catch((error) => {
          console.error('[GET USERS ERROR] --> ', error)
          setErrorMessage(error.message ? error.message : 'Get users error')
          setIsLoading2(false)
        })
    } catch (error) {
      if (error.response) {
        console.error('[GET USERS ERROR] --> ', error)
      }
    }
  }

  const handleRemoveUser = (userId) => {
    setSelectedUsers(selectedUsers.filter((id) => id !== userId))
  }

  const handleModalClose = () => {
    setUsersList(null)
    setNewTitle('')
    setNewDescription('')
    setErrorMessage('')
    setUsername('')
    setSelectedUsers([])
    setIsEdit(false)
    setIsViewDetail(false)
    setActiveList(null)
    return setModalOpen(false)
  }

  useEffect(() => {
    if (change) {
      handleSearch()
      setChange(false)
    }
  }, [change])

  return (
    <Box className={`${isLoading && 'spinner-transition'} filter`}>
      {isLoading && <Spinner noTransform />}
      {isFetching && <AlertFetchSpinner message={fetchMessage} error={fetchError} spinner={isProcessing} />}
      <Box className="component-title-wrapper">
        <Box className="component-title">
          <Typography variant="h2">{t('common:lists')}</Typography>
          <Button
            variant="outlined"
            onClick={() => {
              setModalOpen(true)
              setContext('userId')
            }}
          >
            {`${t('common:create')} ${tab === 'whitelist' ? t('common:whitelistUp') : t('common:blacklistUp')}`}
          </Button>
        </Box>
        <Typography variant="subtitle3">
          {/* Loren ipsum dolor sit amet Loren ipsum dolor sit amet Loren  */}
        </Typography>
      </Box>

      <TabContext value={actualTab}>
        <TabList
          TabIndicatorProps={{
            style: { display: 'none' },
          }}
          sx={{ marginBottom: '1rem' }}
          onChange={handleChange}
        >
          <Tab
            label={<Chip label={t('common:whitelistUp')} variant={tab === 'whitelist' ? 'sliderSelected' : 'slider'} />}
            value="whitelist"
          />
          <Tab
            label={<Chip label={t('common:blacklistUp')} variant={tab === 'blocklist' ? 'sliderSelected' : 'slider'} />}
            value="blocklist"
          />
        </TabList>

        <Paper elevation={0}>
          <TabPanel value="whitelist">
            <DataGridComponent
              rows={Array.isArray(lists.data) && lists.data?.length ? lists.data : []}
              columns={columns}
              count={lists.pagination?.totalElements || 0}
              page={page}
              rowsPerPage={rowsPerPage}
              setPage={setPage}
              setRowsPerPage={setRowsPerPage}
              setIsLoading={setIsLoading}
              setModalOpen={setModalOpen}
              hasTabs="23rem"
            />
          </TabPanel>

          <TabPanel value="blocklist">
            <DataGridComponent
              rows={Array.isArray(lists.data) && lists.data?.length ? lists.data : []}
              columns={columns}
              count={lists.pagination?.totalElements || 0}
              page={page}
              rowsPerPage={rowsPerPage}
              setPage={setPage}
              setRowsPerPage={setRowsPerPage}
              setIsLoading={setIsLoading}
              setModalOpen={setModalOpen}
              hasTabs="23rem"
            />
          </TabPanel>
        </Paper>
      </TabContext>

      {/* MODAL */}
      <Modal
        open={modalOpen}
        onClose={() => {
          handleModalClose()
        }}
      >
        <Box className="modal">
          <Box className="modal-top">
            <Typography variant="title">
              {isViewDetail && !isEdit
                ? `${tab === 'whitelist' ? t('common:whitelistUp') : t('common:blacklistUp')} ${t('common:details')}`
                : isViewDetail && isEdit
                ? `${t('common:edit')} ${tab === 'whitelist' ? t('common:whitelistUp') : t('common:blacklistUp')}`
                : `${t('common:create')} ${tab === 'whitelist' ? t('common:whitelistUp') : t('common:blacklistUp')}`}
            </Typography>
            <Box className="modal-lists-top">
              {isViewDetail && (
                <Button
                  variant="outlinedGrey"
                  onClick={() => {
                    setIsEdit(!isEdit)
                  }}
                >
                  {t('common:editList')}
                </Button>
              )}
              <Fab variant="close" onClick={() => setModalOpen(false)} aria-label="sidebar-collapse">
                <CloseOutlinedIcon />
              </Fab>
            </Box>
          </Box>

          <Box className="modal-box-lists">
            <Box className="modal-lists-top">
              <Box className="lists-box">
                <InputLabel>
                  <span className="mandatory">* </span>
                  {t('common:type')}:
                </InputLabel>

                <Select
                  fullWidth
                  disabled
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                  input={<OutlinedInput />}
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  {['white_list', 'black_list'].map((name) => (
                    <MenuItem key={name} value={name}>
                      {camelCaseFormatter(name?.replace('_', ' '))}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Box className="lists-box">
                <InputLabel>
                  <span className="mandatory">* </span>
                  {t('common:listName')}
                </InputLabel>
                <TextField
                  required
                  disabled={isViewDetail && !isEdit}
                  value={newTitle}
                  onChange={(e) => setNewTitle(e.target.value)}
                ></TextField>
              </Box>
            </Box>
            <Box className="lists-box">
              <InputLabel>
                <span className="mandatory">* </span>
                {t('common:description')}:
              </InputLabel>
              <TextField
                required
                placeholder={t('common:description')}
                multiline
                rows={4}
                disabled={isViewDetail && !isEdit}
                value={newDescription}
                onChange={(e) => {
                  setNewDescription(e.target.value)
                }}
              ></TextField>
            </Box>

            <Box className="lists-box">
              <InputLabel htmlFor="type">
                <span className="mandatory">* </span>
                {t('common:context')}:
              </InputLabel>

              <Select
                id="context"
                size="small"
                fullWidth
                disabled={isViewDetail && !isEdit}
                value={context}
                renderValue={(selected) => {
                  return blackContext.filter((bc) => selected === bc.id)[0]?.title || '-'
                }}
                onChange={(e) => setContext(e.target.value)}
                input={<OutlinedInput />}
                MenuProps={MenuProps}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                {tab === 'whitelist' &&
                  whiteContext.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.title}
                    </MenuItem>
                  ))}

                {tab === 'blocklist' &&
                  blackContext.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.title}
                    </MenuItem>
                  ))}
              </Select>
            </Box>

            {((isEdit && isViewDetail) || (!isViewDetail && !isEdit)) && context === 'userId' && (
              <Box className="lists-box-users">
                <InputLabel>
                  {`${t('messages:searchUserToAdd')} ${
                    tab === 'whitelist' ? t('common:whitelistUp') : t('common:blacklistUp')
                  }`}
                </InputLabel>
                <Box className="lists-box-users-wrapper">
                  <Box className="lists-box">
                    <InputLabel>{t('common:type')}</InputLabel>
                    <Select
                      id="type"
                      size="small"
                      value={userType}
                      disabled={isViewDetail && !isEdit}
                      onChange={(e) => {
                        setUserType(e.target.value)
                        setChange(true)
                      }}
                      input={<OutlinedInput />}
                      MenuProps={MenuProps}
                      inputProps={{ 'aria-label': 'Without label' }}
                    >
                      {['consumer', 'business']?.map((name) => (
                        <MenuItem key={name} value={name}>
                          {camelCaseFormatter(name)}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>

                  <Box className="lists-box">
                    <InputLabel>{t('common:user')}</InputLabel>
                    <TextField
                      size="small"
                      value={userName}
                      disabled={isViewDetail && !isEdit}
                      onChange={(e) => setUsername(e.target.value)}
                      id="username"
                      placeholder="Write a Name or ID"
                    ></TextField>
                  </Box>

                  <Button
                    size="small"
                    onClick={() => handleSearch()}
                    variant="outlined"
                    endIcon={<ManageSearchIcon />}
                    disabled={(isViewDetail && !isEdit) || !userName}
                  >
                    {t('common:search')}
                  </Button>

                  <Button
                    size="small"
                    onClick={() => {
                      setUsername('')
                      setUserType('')
                      setUsersList([])
                      setPageModal(0)
                    }}
                    variant="contained"
                  >
                    {t('common:reset')}
                  </Button>
                </Box>

                {isLoading2 && <ProcessingSpinner message={t('common:processing')} />}

                {Array.isArray(usersList?.data) && usersList?.data?.length > 0 ? (
                  <Paper elevation={0} variant="colorPrimary">
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>{t('common:userId')}</TableCell>
                            <TableCell>{t('common:name')}</TableCell>
                            <TableCell>{t('common:action')}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {usersList?.data?.map((user, index) => {
                            return user?.legalName ? (
                              <TableRow key={index}>
                                <TableCell>{user.userId}</TableCell>
                                <TableCell>{user.legalName}</TableCell>
                                <TableCell>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={selectedUsers.includes(user.userId)}
                                        onChange={() => handleCheckboxChange(user.userId)}
                                      />
                                    }
                                    label={<Box style={{ fontSize: '.9rem' }}>{t('common:add')}</Box>}
                                  />
                                </TableCell>
                              </TableRow>
                            ) : user?.firstName || user?.middleName ? (
                              <TableRow key={index}>
                                <TableCell>{user.userId}</TableCell>
                                <TableCell>
                                  {`${user.firstName ? user.firstName : ''} ${user.middleName ? user.middleName : ''} ${
                                    user.lastName ? user.lastName : ''
                                  }`}
                                </TableCell>
                                <TableCell>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={selectedUsers?.includes(user.userId)}
                                        onChange={() => handleCheckboxChange(user.userId)}
                                      />
                                    }
                                    label={<Box style={{ fontSize: '.9rem' }}>{t('common:add')}</Box>}
                                  />
                                </TableCell>
                              </TableRow>
                            ) : (
                              <Box className="table-custom-overlay">
                                <CustomNoRowsOverlay />
                              </Box>
                            )
                          })}
                        </TableBody>
                      </Table>

                      <TablePagination
                        component="div"
                        labelRowsPerPage={[]}
                        rowsPerPageOptions={[5, 10, 25]}
                        count={usersList?.pagination?.totalElements || 0}
                        page={pageModal}
                        onPageChange={handleChangePageModal}
                        rowsPerPage={rowsPerPageModal}
                        onRowsPerPageChange={handleChangeRowsPerPageModal}
                      />
                    </TableContainer>
                  </Paper>
                ) : usersList === null ? (
                  ''
                ) : (
                  <Typography variant="title2">{t('common:noMatchesFound')}</Typography>
                )}
              </Box>
            )}

            {((isEdit && isViewDetail) || (!isViewDetail && !isEdit)) && context !== 'userId' && (
              <Box className="lists-box-users">
                <InputLabel>
                  {`${context === 'userId' ? t('messages:searchUserToAdd') : t('messages:textToAdd')} ${
                    tab === 'whitelist' ? t('common:whitelistUp') : t('common:blacklistUp')
                  }`}
                </InputLabel>

                <Box className="lists-box-wrapper">
                  <Box className="lists-box">
                    <InputLabel htmlFor="username">{t('common:text')}:</InputLabel>
                    <TextField
                      size="small"
                      variant="outlined"
                      value={userName}
                      disabled={isViewDetail && !isEdit}
                      onChange={(e) => setUsername(e.target.value)}
                      id="username"
                      placeholder={context === 'userId' ? 'Write a Name or ID' : 'Write text'}
                    ></TextField>
                  </Box>

                  <Box className="lists-box">
                    <Button
                      color="secondary"
                      size="small"
                      onClick={() => {
                        handleCheckboxChange(userName)
                        setUsername('')
                      }}
                      variant="contained"
                      disabled={(isViewDetail && !isEdit) || !userName}
                    >
                      {t('common:add')}
                    </Button>
                  </Box>

                  {isLoading2 && <ProcessingSpinner message={t('common:processing')} />}
                </Box>
              </Box>
            )}

            {selectedUsers && selectedUsers.length > 0 && (
              <Box>
                <InputLabel sx={{ marginBottom: '1rem' }}>{`${
                  context === 'userId' ? t('messages:usersAddedTo') : t('messages:textsAddedTo')
                } ${tab === 'whitelist' ? t('common:whitelistUp') : t('common:blacklistUp')}`}</InputLabel>
                <Paper elevation={0} variant="colorPrimary">
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>{context === 'userId' ? t('common:userId') : t('common:text')}</TableCell>
                          <TableCell>{t('common:action')}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {selectedUsers &&
                          selectedUsers.length > 0 &&
                          selectedUsers.map((user, index) => {
                            return (
                              <TableRow key={index}>
                                <TableCell>{user}</TableCell>
                                <TableCell>
                                  <Fab
                                    variant="close"
                                    className="icon-fab"
                                    disabled={isViewDetail && !isEdit}
                                    onClick={() => handleRemoveUser(user)}
                                  >
                                    <DeleteOutlineOutlinedIcon />
                                  </Fab>
                                </TableCell>
                              </TableRow>
                            )
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Box>
            )}

            {errorMessage ? (
              <Button size="small" variant="outlined" color="error">
                {errorMessage}
              </Button>
            ) : (
              ''
            )}
          </Box>
          <Box className="lists-buttons">
            <Button size="small" variant="outlinedGrey" onClick={() => handleModalClose()}>
              {t('common:cancel')}
            </Button>
            <Button
              disabled={
                errorMessage !== '' ||
                selectedUsers.length === 0 ||
                !newDescription.length ||
                !newTitle.length ||
                (!isEdit && isViewDetail)
              }
              size="small"
              variant="contained"
              type="submit"
              onClick={(e) => handleSubmit(e, activeList)}
            >
              {t('common:save')}
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* MODAL 2 */}
      <FilterModal
        modalOpen={modalOpen2}
        setModalOpen={setModalOpen2}
        handleResetButton={handleResetButton}
        setPage={setPage}
        handleDispatch={handleDispatch}
      >
        <Box className="modal-box-filters">
          <Box className="modal-box">
            <Typography variant="subtitle3">
              {t('common:by')} {t('common:date')}:
            </Typography>

            <MyDatePicker selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
          </Box>

          <Box className="modal-box">
            <Typography variant="subtitle3">
              {t('common:by')} {t('common:name')}:
            </Typography>
            <TextField
              fullWidth
              size="small"
              value={listName}
              onChange={(event) => setListName(event.target.value)}
              placeholder={`${t('common:insert')} ${t('common:name')}`}
            />
          </Box>

          <Box className="modal-box">
            <Typography variant="subtitle3">
              {t('common:by')} {t('common:description')}:
            </Typography>
            <TextField
              fullWidth
              size="small"
              value={description}
              onChange={(event) => setDescription(event.target.value)}
              placeholder={`${t('common:insert')} ${t('common:description')}`}
            />
          </Box>
        </Box>
      </FilterModal>
    </Box>
  )
}

export default Lists

import { createSlice } from '@reduxjs/toolkit'

export const reportSlice = createSlice({
  name: 'report',
  initialState: {
    reports: {},
    filters: {
      page: 0,
      limit: 10,
      offset: 0,
      fromDate: null,
      toDate: '',
      emailUser: '',
      actionResul: '',
      actionType: '',
      affectedUser: '',
      abmAction: '',
      reportRoleName: '',
      affectedUser2: '',
      status: '',
      extension: '',
      collection: '',
    },
  },
  reducers: {
    updateReports: (state, reports) => {
      state.reports = reports.payload
    },
    updateReportsFilters: (state, filters) => {
      state.filters = filters.payload
    },
  },
})

export const { updateReports, updateReportsFilters } = reportSlice.actions

export default reportSlice.reducer

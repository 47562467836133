/* eslint-disable react-hooks/exhaustive-deps */
import './datatable.scss'
import { useEffect } from 'react'
import { useTransactions } from '../../../pages/pages-hooks/useTransactions'
import { useSelector } from 'react-redux'
import { Box, Link, Typography } from '@mui/material'
import { TableChip } from '../../utilities/TableChip'
import { TableIcons } from '../../utilities/TableIcons'
import { Flags } from '../flag/flags'
import { useTranslation } from 'react-i18next'
import { buildTimeStringFromTimestamp } from '../time/timeHelper'
import { DataGridComponent } from '../datagrid/DataGridComponent'

const Datatable = ({ page, setPage, rowsPerPage, setRowsPerPage, setIsLoading, setModalOpen }) => {
  const { emailClient } = useSelector((state) => state.login)

  const allTransactions = useTransactions(emailClient, setIsLoading)
  const { t } = useTranslation(['common'])

  useEffect(() => {
    if (allTransactions?.data?.length) {
      setIsLoading(false)
    } else if (allTransactions?.pagination?.totalElements >= 0) {
      setIsLoading(false)
    }
  }, [allTransactions])

  const columns = [
    {
      field: 'id',
      headerName: 'Id',
      renderCell: (params) => {
        return (
          <Link href={`/transactions/${params.id}`} title={params.id}>
            <Typography variant="number">{params.id}</Typography>
          </Link>
        )
      },
    },
    {
      field: 'transactionType',
      headerName: t('common:type'),
      renderCell: (params) => {
        return params.row?.transactionType ? <TableIcons type={params.row?.transactionType} /> : '---'
      },
    },
    {
      field: 'transactionState',
      headerName: t('common:state'),
      renderCell: (params) => {
        return params.row.transactionState ? <TableIcons type={params.row.transactionState} /> : '---'
      },
    },
    {
      field: 'createdTimestamp',
      headerName: t('common:date'),
      renderCell: (params) => {
        return buildTimeStringFromTimestamp(params.row?.createdTimestamp)
      },
    },
    {
      field: 'alert',
      headerName: t('common:alert'),
      renderCell: (params) => {
        return <Box>{params.row.alert ? <TableChip action={params.row.alert} /> : '---'}</Box>
      },
    },
    {
      field: 'hitRules',
      headerName: t('common:hitRules'),
      renderCell: (params) => {
        return (
          <Box className="cellOverflow">
            {params.row.hitRules.length
              ? params.row.hitRules.map((item) => `${item.ruleId}(${item.ruleAction[0]})`).join(', ')
              : '---'}
          </Box>
        )
      },
    },
    {
      field: 'originUserId',
      headerName: t('common:originUserId'),
    },
    {
      field: 'originMethod',
      headerName: t('common:originMethod'),
      renderCell: (params) => {
        return params.row.originMethod ? (
          <TableChip action={params.row.originMethod.toUpperCase()} noIcon={true} />
        ) : (
          '---'
        )
      },
    },
    {
      field: 'originAmount',
      headerName: t('common:originAmount'),
      renderCell: (params) => {
        return <Typography variant="number">{params.row.originAmount?.toLocaleString()}</Typography>
      },
    },
    {
      field: 'originCurrency',
      headerName: t('common:originCurrency'),
    },
    {
      field: 'originCountry',
      headerName: t('common:originCountry'),
      renderCell: (params) => {
        return <Box>{Flags(params.row.originCountry, false)}</Box>
      },
    },
    {
      field: 'destinationUserId',
      headerName: t('common:destinationUserId'),
    },
    {
      field: 'destinationMethod',
      headerName: t('common:destinationMethod'),
      renderCell: (params) => {
        return (
          <Box>
            {params.row.destinationMethod ? <TableChip action={params.row.destinationMethod.toUpperCase()} /> : '---'}
          </Box>
        )
      },
    },
    {
      field: 'destinationAmount',
      headerName: t('common:destinationAmount'),
      renderCell: (params) => {
        return <Typography variant="number">{params.row.destinationAmount?.toLocaleString()}</Typography>
      },
    },
    {
      field: 'destinationCurrency',
      headerName: t('common:destinationCurrency'),
    },
    {
      field: 'destinationCountry',
      headerName: t('common:destinationCountry'),
      renderCell: (params) => {
        return <Box>{Flags(params.row.destinationCountry, false)}</Box>
      },
    },
  ]

  return (
    <DataGridComponent
      rows={Array.isArray(allTransactions?.data) && allTransactions?.data?.length ? allTransactions?.data : []}
      columns={columns}
      count={allTransactions?.pagination?.totalElements || 0}
      page={page}
      rowsPerPage={rowsPerPage}
      setPage={setPage}
      setRowsPerPage={setRowsPerPage}
      setIsLoading={setIsLoading}
      setModalOpen={setModalOpen}
    />
  )
}

export default Datatable

import '../reports.scss'
import '../../../components/common/datatable/datatable.scss'
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material'
import Spinner from '../../../components/common/spinner/spinner'
import { useNavigate } from 'react-router-dom'
import { useReports } from '../../pages-hooks/useReports'
import DataExportModal from '../../../components/common/dataExport/DataExportModal'
import { useState } from 'react'
import {
  blockedUserReportDataMapper,
  blockedUserReportDataMapperForPDF,
} from '../../../components/common/dataExport/dataMappers/blockedUserReportDataMapper'
import { useSelector } from 'react-redux'
import { buildTimeStringFromTimestamp } from '../../../components/common/time/timeHelper'
import { DownloadIcon } from '../../../components/utilities/DownloadIcon'
import { useTranslation } from 'react-i18next'
import CustomNoRowsOverlay from '../../../components/common/datagrid/CustomNoRowsOverlay'

const BlockUnblockUserReport = ({
  page,
  handleChangePage,
  rowsPerPage,
  handleChangeRowsPerPage,
  isLoading,
  setIsLoading,
}) => {
  const { activeServices } = useSelector((state) => state.login)
  const [showDownloadModal, setShowDownloadModal] = useState(false)
  const allReports = useReports('block_unblock_user_access', setIsLoading, isLoading)
  const { t } = useTranslation(['common', 'messages'])

  const navigate = useNavigate()

  return (
    <Box className="datatable">
      {isLoading && <Spinner noTransform />}

      <TableContainer className="wrapper">
        <Box className="title">
          <span>Dashboard Users Block/Unblock Reports</span>
          {activeServices?.includes('reportsitem') && allReports?.data?.length > 0 ? (
            <Button
              disabled={!activeServices.includes('reportsitem') || !allReports?.data?.length}
              onClick={() => setShowDownloadModal(true)}
            >
              <DownloadIcon />
            </Button>
          ) : (
            <div></div>
          )}
        </Box>
        <TablePagination
          labelRowsPerPage={t('common:rows')}
          className="pagination"
          component="div"
          count={allReports?.pagination?.totalElements || 0}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <Table className="table">
          <TableHead className="table-head">
            <TableRow>
              <TableCell>
                {t('common:action')} {t('common:owner')}
              </TableCell>
              <TableCell>Affected User</TableCell>
              <TableCell>{t('common:status')}</TableCell>
              <TableCell>{t('common:activity')}</TableCell>
              <TableCell>{t('common:date')}</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {allReports?.data?.length ? (
              allReports.data.map((row, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell className="table-body-cell">{row.emailUser}</TableCell>
                    <TableCell className="table-body-cell">{row.activityDetails?.userCredentials}</TableCell>
                    <TableCell className="table-body-cell">{row.activityDetails?.actionResult}</TableCell>
                    <TableCell className="table-body-cell">{row.activityDetails?.actionActivity}</TableCell>
                    <TableCell className="table-body-cell">{buildTimeStringFromTimestamp(row.createdAt)}</TableCell>
                  </TableRow>
                )
              })
            ) : (
              <Box className="table-custom-overlay">
                <CustomNoRowsOverlay />
              </Box>
            )}
          </TableBody>
        </Table>
        <TablePagination
          labelRowsPerPage={t('common:rows')}
          className="pagination"
          component="div"
          count={allReports?.pagination?.totalElements || 0}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>

      <DataExportModal
        show={showDownloadModal}
        setShow={setShowDownloadModal}
        data={allReports?.data}
        dataMapper={blockedUserReportDataMapper}
        dataMapperForPDF={blockedUserReportDataMapperForPDF}
        fileName={'block_unblock_user_report'}
        typeDataExport={'reports'}
        keyReports={'block_unblock_user_access'}
      />
    </Box>
  )
}

export default BlockUnblockUserReport

export const ruleQReportDataMapperForPDF = (data) => {
    const header = ['RuleId', 'RuleInstaceId', 'ExeQ', 'HitQ', "ExeRate", 'HitRate', 'hitExeRatio']
    const parsedData = data.map((report) => {
        return [
            report?.ruleId,
            report?.ruleInstanceId,
            report?.exeQ,
            report?.hitQ,
            report?.exeRate,
            report?.hitRate,
            report?.hitExeRatio,
        ]
    })

    return {
        columns: header,
        body: parsedData,
    }
}

export const ruleQReportDataMapper = (data) => {
    return data.map((report) => {
        return {
            RuleId: report?.ruleId,
            RuleInstanceId: report?.ruleInstanceId,
            ExeQ: report?.exeQ,
            HitQ: report?.hitQ,
            ExeRate: report?.exeRate,
            HitRate: report?.hitRate,
            HitExeRatio: report?.hitExeRatio,
        }
    })
}

import { Box } from '@mui/material'

const LinkedEntities = ({ linkedEntities }) => {
  return (
    <Box className="tl">
      <Box>
        <p className="datatable-modal-title">Parent User ID</p>
        <Box className="tags">
          <p>{linkedEntities?.parentUserId ? linkedEntities.parentUserId : '---'}</p>
        </Box>
      </Box>
      <Box>
        <Box>
          <p className="datatable-modal-title">Child User IDs</p>
          <Box className="tags">
            {linkedEntities.childUserIds.length &&
              linkedEntities.childUserIds.map((id) => {
                return <Box key={id}>{id}</Box>
              })}
            {!linkedEntities.childUserIds.length && <p className="tags">---</p>}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default LinkedEntities

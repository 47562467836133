import { useEffect, useState } from 'react'
import NumberInput from '../numberInput/numberInput'
import SelectSingle from '../selectSingle/selectSingle'
import { Box } from '@mui/material'

const Comparator = ({ property, ruleParameters, possibleValues, desc, schema, setRuleParameters, isEditing }) => {
  const [finalParameter, setFinalParameter] = useState({})
  const [change, setChange] = useState(false)

  useEffect(() => {
    if (ruleParameters[property] && Object.keys(finalParameter).length === 0) {
      setFinalParameter({ ...ruleParameters[property] })
    }
  }, [property, ruleParameters, finalParameter])

  useEffect(() => {
    if (change) {
      let all = { ...ruleParameters }
      all[property] = finalParameter
      setRuleParameters({ ...all })
      setChange(false)
    }
  }, [change, finalParameter])

  const handleChange = (value) => {
    setFinalParameter(value)
    setChange(true)
  }

  return (
    <Box className={'childContainer'}>
      <Box className={'label'}>
        <p>Value</p>
      </Box>
      <NumberInput
        isEditing={isEditing}
        propertie={'value'}
        ruleParameters={finalParameter}
        setRuleParameters={handleChange}
        schema={schema}
      />
      <SelectSingle
        isEditing={isEditing}
        propertie={'comparator'}
        ruleParameters={finalParameter}
        setRuleParameters={setFinalParameter}
        posibleValues={possibleValues}
        desc={desc}
      />
    </Box>
  )
}

export default Comparator

import '../reports.scss'
import '../../../components/common/datatable/datatable.scss'
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Spinner from '../../../components/common/spinner/spinner'
import { useNavigate } from 'react-router-dom'
import { useReports } from '../../pages-hooks/useReports'
import { useState } from 'react'
import DataExportModal from '../../../components/common/dataExport/DataExportModal'
import {
  dataExportReportDataMapper,
  dataExportReportDataMapperForPDF,
} from '../../../components/common/dataExport/dataMappers/dataExportReportDataMapper'
import { useSelector } from 'react-redux'
import { buildTimeStringFromTimestamp } from '../../../components/common/time/timeHelper'
import { DownloadIcon } from '../../../components/utilities/DownloadIcon'
import { useTranslation } from 'react-i18next'
import CustomNoRowsOverlay from '../../../components/common/datagrid/CustomNoRowsOverlay'

const createRow = (report) => {
  let result = ''
  for (const key in report?.activityDetails?.filters) {
    if (report.activityDetails.filters.hasOwnProperty(key)) {
      result += `${key}: ${report.activityDetails.filters[key]}, `
    }
  }
  return {
    status: report?.activityDetails?.actionResult,
    timestamp: report?.createdAt,
    email: report?.emailUser,
    extension: report?.activityDetails?.extension,
    filters: result,
    collection: report?.activityDetails?.data,
  }
}

const GeneratedReport = ({ page, handleChangePage, rowsPerPage, handleChangeRowsPerPage, isLoading, setIsLoading }) => {
  const { activeServices } = useSelector((state) => state.login)
  const allReports = useReports('generated_reports', setIsLoading, isLoading)
  const navigate = useNavigate()
  const [showDownloadModal, setShowDownloadModal] = useState(false)
  const { t } = useTranslation(['common', 'messages'])

  return (
    <Box className="datatable">
      {isLoading && <Spinner noTransform />}

      <TableContainer className="wrapper">
        <Box className="title">
          <span>Data Export Reports</span>
          {activeServices?.includes('reportsitem') && allReports?.data?.length > 0 ? (
            <Button
              disabled={!activeServices.includes('reportsitem') || !allReports?.data?.length}
              onClick={() => setShowDownloadModal(true)}
            >
              <DownloadIcon />
            </Button>
          ) : (
            <div></div>
          )}
        </Box>
        <TablePagination
          labelRowsPerPage={t('common:rows')}
          className="pagination"
          component="div"
          count={allReports?.pagination?.totalElements || 0}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <Table className="table">
          <TableHead className="table-head">
            <TableRow>
              <TableCell>{t('common:user')}</TableCell>
              <TableCell>{t('common:status')}</TableCell>
              <TableCell>Filters</TableCell>
              <TableCell>Extension</TableCell>
              <TableCell>Collection</TableCell>
              <TableCell>{t('common:date')}</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {Array.isArray(allReports?.data) && allReports.data.length > 0 ? (
              allReports.data
                .map((report) => createRow(report))
                .map((row, index) => {
                  const back = index % 2 === 0 ? '' : 'gray-back'
                  return (
                    <TableRow className={back} key={index}>
                      <TableCell className="table-body-cell">{row.email}</TableCell>
                      <TableCell className="table-body-cell">{row.status}</TableCell>
                      <TableCell className="table-body-cell">{row.filters}</TableCell>
                      <TableCell className="table-body-cell">{row.extension}</TableCell>
                      <TableCell className="table-body-cell">{row.collection}</TableCell>
                      <TableCell className="table-body-cell">{buildTimeStringFromTimestamp(row.timestamp)}</TableCell>
                    </TableRow>
                  )
                })
            ) : (
              <Box className="table-custom-overlay">
                <CustomNoRowsOverlay />
              </Box>
            )}
          </TableBody>
        </Table>
        <TablePagination
          labelRowsPerPage={t('common:rows')}
          className="pagination"
          component="div"
          count={allReports?.pagination?.totalElements || 0}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      <DataExportModal
        show={showDownloadModal}
        setShow={setShowDownloadModal}
        data={allReports?.data}
        dataMapper={dataExportReportDataMapper}
        dataMapperForPDF={dataExportReportDataMapperForPDF}
        fileName={'data-export-report'}
        typeDataExport={'reports'}
        keyReports={'generated_reports'}
      />
    </Box>
  )
}

export default GeneratedReport

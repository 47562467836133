import { useEffect, useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useFindUserMasterQuery, useUpdateUserMasterMutation } from '../../../services/guenoApi'
import AlertFetchSpinner from '../../../components/common/alertFetchSpinner/alertFetchSpinner'
import KybListOfStrings from './KybListOfStrings'
import KybChecksDefaultOrder from './KybChecksDefaultOrder.json'

const KybSettingsTabPanel = ({ currentCountry }) => {
  const { t } = useTranslation(['common', 'messages'])
  const { emailClient } = useSelector((state) => state.login)
  const { data: kybSettings, refetch } = useFindUserMasterQuery({ emailClient })
  const [updateUserMaster] = useUpdateUserMasterMutation({ fixedCacheKey: 'userMasterUpdate' })
  const [currentBussinesChecks, setCurrentBussinesChecks] = useState([])
  const [currentPersonChecks, setCurrentPersonChecks] = useState([])
  const [isFetching, setIsFetching] = useState(false)
  const [fetchResponse, setFetchResponse] = useState({
    message: '',
    error: '',
  })

  const setKybClusters = async (clusters) => {
    try {
      setIsFetching(true)
      setTimeout(() => {
        setIsFetching(false)
      }, 3000)
      const { data } = await updateUserMaster({
        emailClient,
        body: {
          clusters: clusters,
        },
      })
      if (data.success && data.data) {
        setFetchResponse({ ...fetchResponse, message: t('messages:updateSuccess') })
        refetch()
      } else {
        setFetchResponse({ ...fetchResponse, error: t('messages:updateError') })
      }
    } catch (error) {
      setFetchResponse({ ...fetchResponse, error: t('messages:updateError') })
    }
  }

  const setKybChecks = async (subject, kybChecks) => {
    try {
      setIsFetching(true)
      setTimeout(() => {
        setIsFetching(false)
      }, 3000)
      const { data } = await updateUserMaster({
        emailClient,
        body: {
          kybChecks: { [currentCountry]: { [subject]: kybChecks } },
        },
      })
      if (data.success && data.data) {
        setFetchResponse({ ...fetchResponse, message: t('messages:updateSuccess') })
        refetch()
      } else {
        setFetchResponse({ ...fetchResponse, error: t('messages:updateError') })
      }
    } catch (error) {
      setFetchResponse({ ...fetchResponse, error: t('messages:updateError') })
    }
  }

  useEffect(() => {
    if (kybSettings?.kybChecks) {
      setCurrentBussinesChecks(kybSettings.kybChecks[currentCountry].BUSINESS)
      setCurrentPersonChecks(kybSettings.kybChecks[currentCountry].PERSON)
    } else {
      setCurrentBussinesChecks(KybChecksDefaultOrder[currentCountry].BUSINESS)
      setCurrentPersonChecks(KybChecksDefaultOrder[currentCountry].PERSON)
    }
  }, [kybSettings, currentCountry])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {isFetching && <AlertFetchSpinner message={fetchResponse.message} error={fetchResponse.error} />}
      <Accordion variant="medium" defaultExpanded>
        <AccordionSummary aria-controls="panel1-content" id="panel1-header">
          <Typography variant="h3">{t('common:clusters')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <KybListOfStrings stringsList={kybSettings?.clusters} setStringsList={setKybClusters} columns={2} />
        </AccordionDetails>
      </Accordion>
      <Accordion variant="medium" defaultExpanded>
        <AccordionSummary aria-controls="panel2-content" id="panel2-header">
          <Typography variant="h3">{t('common:checks')}</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '2rem',
          }}
        >
          <Box width="100%">
            <KybListOfStrings
              title="business"
              stringsList={currentBussinesChecks}
              setStringsList={(newChecks) => setKybChecks('BUSINESS', newChecks)}
              translateStrings
              inputsDisabled
              noAddRemove
            />
          </Box>
          <Box width="100%">
            <KybListOfStrings
              title="person"
              stringsList={currentPersonChecks}
              setStringsList={(newChecks) => setKybChecks('PERSON', newChecks)}
              translateStrings
              inputsDisabled
              noAddRemove
            />
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

export default KybSettingsTabPanel

import React, { useEffect } from 'react'
import { DataGridPro, GridColumnMenu, useGridApiRef } from '@mui/x-data-grid-pro'
import { Box, Button, TablePagination } from '@mui/material'
import { useSelector } from 'react-redux'
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined'
import { useTranslation } from 'react-i18next'

export const DataGridComponent = ({
  rows,
  columns,
  count,
  page,
  rowsPerPage,
  setPage,
  setRowsPerPage,
  setIsLoading,
  setModalOpen,
  hasTabs,
  isLoading = false,
  NoRowsOverlay = undefined,
  showFilterButton = true,
  showHideColumnsButton = true,
}) => {
  const apiRef = useGridApiRef()
  const { t } = useTranslation(['common'])
  const { theme } = useSelector((state) => state.theme)

  const autosizeOptions = {
    includeHeaders: true,
    includeOutliers: true,
    expand: true,
  }

  useEffect(() => {
    if (apiRef.current) {
      apiRef.current.autosizeColumns(autosizeOptions)
    }
  }, [apiRef, rows, theme, autosizeOptions])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    setIsLoading(true)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
    setIsLoading(true)
  }

  function CustomColumnMenu(props) {
    return (
      <GridColumnMenu
        {...props}
        slots={{
          columnMenuColumnsItem: null,
          columnMenuFilterItem: null,
          columnMenuSortItem: null,
        }}
      />
    )
  }
  const slots = {
    columnMenu: CustomColumnMenu,
  }

  if (NoRowsOverlay) {
    slots.noRowsOverlay = NoRowsOverlay
  }


  return (
    <Box style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
      {(showFilterButton || showHideColumnsButton) && (
        <Box className="filter-bar">
          {showFilterButton && (
            <Button variant="label" endIcon={<MenuOutlinedIcon />} onClick={() => setModalOpen(true)}>
              {t('common:filters')}
            </Button>
          )}
          {showHideColumnsButton && (
            <Button
              variant="label"
              endIcon={<MenuOutlinedIcon />}
              onClick={() => apiRef.current.showPreferences('columns')}
            >
              {t('common:showHideColumns')}
            </Button>
          )}
        </Box>
      )}
      <DataGridPro
        getRowHeight={() => 'auto'}
        getRowId={(row) => row._id || row.id}
        rows={rows}
        columns={columns}
        apiRef={apiRef}
        slots={slots}
        hideFooter={true}
        autoHeight
        loading={isLoading}
        sx={{
          height: hasTabs ? `calc(100vh - ${hasTabs})` : 'calc(100vh - 19.5rem)',
          '& .MuiDataGrid-virtualScroller': {
            overflow: 'auto',
          },
        }}
      />

      <TablePagination
        labelRowsPerPage={[]}
        component="div"
        count={count}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  )
}

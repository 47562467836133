import './amlCrypto.scss'
import { Box, Button, TextField, Select, InputLabel, MenuItem, Typography, Paper, Link } from '@mui/material'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { RenderTooltip } from '../../components/utilities/RenderTooltip'
import ProcessingSpinner from '../../components/common/alertFetchSpinner/processingSpinner'

const AddressProtocols = [
  'btc',
  'eth',
  'xrp',
  'egld',
  'algo',
  'sol',
  'trx',
  'flow',
  'one',
  'ltc',
  'bch',
  'zec',
  'dash',
  'bsv',
  'bnb',
  'xvg',
  'btg',
  'matic',
  'avax',
  'xlm',
  'klay',
]

const CheckType = ['addressLabel', 'addressRiskScore', 'addressRiskActivity']

const AmlCryptoManualCheck = () => {
  const { accessToken } = useSelector((state) => state.login)
  const [isFetching, setIsFetching] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const { t } = useTranslation(['common', 'messages'])

  const [protocol, setProtocol] = useState('')
  const [address, setAddress] = useState('')
  const [checkType, setCheckType] = useState('')
  const [finish, setFinish] = useState(false)
  const [result, setResult] = useState({})

  const cantSend = () => {
    return protocol === '' || address === '' || address === ''
  }

  const handleSubmit = () => {
    setIsFetching(true)
    setFinish(false)
    setErrorMessage('')

    let url = `${process.env.REACT_APP_BASEURL}/amlCrypto/${checkType}?protocol=${protocol}&address=${address}`

    const options = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }

    fetch(process.env.REACT_APP_IS_DEMO ? '../data/amlCryptoDetails.json' : url, options)
      .then((res) => res.json())
      .then((res) => {
        setIsFetching(false)
        if (res.success && res.data) {
          setResult(res.data)
          setFinish(true)
        } else {
          throw new Error(
            res.data?.responseBody?.message
              ? res.data?.responseBody?.message
              : res.data?.info
              ? res.data?.info
              : res.message
              ? res.message
              : 'Unexpected',
          )
        }
      })
      .catch((err) => {
        console.error('[DOWNLOAD REPORT ERROR] --> ', err)
        setIsFetching(false)
        setErrorMessage(err.message ? err.message : t('messages:unexpectedErrorValues'))
      })
  }

  const handleReset = () => {
    setProtocol('')
    setAddress('')
    setCheckType('')

    setFinish(false)
    setErrorMessage('')
  }

  return (
    <Box>
      <Box className="component-title-wrapper">
        <Box className="component-title">
          <Typography variant="h2">
            {t('common:amlCrypto')} {t('common:manualCheck')}
          </Typography>
        </Box>
        <Typography variant="subtitle3">
          {/* Loren ipsum dolor sit amet Loren ipsum dolor sit amet Loren  */}
        </Typography>
      </Box>

      <Box className="aml-manual-wrapper-grid">
        <Box className="aml-manual-divider">
          <Paper
            elevation={0}
            variant="rootOutlined"
            sx={{ marginRight: '1rem', padding: '1.5rem 1rem 1.5rem 1.5rem' }}
          >
            <Box className="aml-manual-overflow">
              <Box sx={{ marginBottom: '1.5rem' }}>
                <Typography variant="title">{t('common:information')}</Typography>
              </Box>

              <Box className="aml-manual-wrapper">
                <Box className="aml-manual-flex">
                  <Box className="aml-manual-input">
                    <Box className="required">
                      <InputLabel>{t('common:protocol')}</InputLabel>
                      <Typography variant="subtitle3">({t('common:required')})</Typography>
                      <RenderTooltip title={'A string representing the protocol.'} />
                    </Box>

                    <Select
                      required
                      size="small"
                      displayEmpty
                      value={protocol}
                      onChange={(e) => setProtocol(e.target.value)}
                      options={AddressProtocols}
                    >
                      <MenuItem disabled value="">
                        <Typography variant="subtitle3">{`${t('common:select')} ${t('common:protocol')}`}</Typography>
                      </MenuItem>
                      {AddressProtocols.map((name, index) => (
                        <MenuItem key={index} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                  <Box className="aml-manual-input">
                    <Box className="required">
                      <InputLabel>{t('common:address')}</InputLabel>
                      <Typography variant="subtitle3">({t('common:required')})</Typography>
                      <RenderTooltip title={'A string representing the address.'} />
                    </Box>
                    <TextField
                      size="small"
                      value={address}
                      onChange={(event) => setAddress(event.target.value)}
                      placeholder={t('common:address')}
                      required
                    />
                  </Box>
                </Box>

                <Box className="aml-manual-input">
                  <Box className="required">
                    <InputLabel>{t('common:checkType')}</InputLabel>
                    <Typography variant="subtitle3">({t('common:required')})</Typography>
                    <RenderTooltip
                      title={
                        'Determines how closely the returned results must match the supplied name. Overridden by exact_match.'
                      }
                    />
                  </Box>
                  <Select
                    size="small"
                    value={checkType}
                    onChange={(e) => setCheckType(e.target.value)}
                    options={CheckType}
                    required
                    displayEmpty
                  >
                    <MenuItem disabled value="">
                      <Typography variant="subtitle3">{`${t('common:select')} ${t('common:checkType')}`}</Typography>
                    </MenuItem>
                    {CheckType.map((name, index) => (
                      <MenuItem key={index} value={name}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </Box>
            </Box>
          </Paper>

          <Paper elevation={0} variant="rootOutlined" className="aml-manual-results-wrapper" sx={{ padding: '1.5rem' }}>
            <Box sx={{ marginBottom: '1.5rem' }}>
              <Typography variant="title">
                {t('common:check')} {t('common:results')}
              </Typography>
            </Box>

            {result && finish && errorMessage === '' ? (
              <Box className="aml-manual-results">
                <Paper elevation={0}>
                  <Box className="aml-manual-sub-results">
                    <Box className="aml-manual-grid">
                      <Typography variant="subtitle3">{t('common:checkId')}</Typography>
                      {result.checkId ? (
                        <Link href={`/amlCrypto/${result.checkId}`}>
                          <Typography variant="number" color="action">
                            {result.checkId}
                          </Typography>
                        </Link>
                      ) : (
                        '---'
                      )}
                    </Box>
                    <Box className="aml-manual-grid">
                      <Typography variant="subtitle3">{t('common:address')}</Typography>
                      <Typography variant="title2">{address ? address : '---'}</Typography>
                    </Box>
                    <Box className="aml-manual-grid">
                      <Typography variant="subtitle3">{t('common:protocol')}</Typography>
                      <Typography variant="title2">{protocol ? protocol : '---'}</Typography>
                    </Box>
                    <Box className="aml-manual-grid">
                      <Typography variant="subtitle3">{t('common:checkType')}</Typography>
                      <Typography variant="title2">{checkType ? checkType : '---'}</Typography>
                    </Box>
                    <Box className="aml-manual-grid">
                      <Typography variant="subtitle3">
                        {t('common:isValid')} {t('common:address')}
                      </Typography>
                      <Typography variant="title2">
                        {result[address]?.is_address_valid ? t('common:yes') : t('common:no')}
                      </Typography>
                    </Box>
                    <Box className="aml-manual-grid">
                      <Typography variant="subtitle3">{t('common:categories')}</Typography>
                      <Typography variant="title2">
                        {result[address]?.self?.category ? result[address].self.category : '---'}
                      </Typography>
                    </Box>
                    <Box className="aml-manual-grid">
                      <Typography variant="subtitle3">{t('common:score')}</Typography>
                      <Typography variant="title2">
                        {result[address]?.risk?.score ? result[address].risk.score : '---'}
                      </Typography>
                    </Box>
                    <Box className="aml-manual-grid">
                      <Typography variant="subtitle3">{t('common:level')}</Typography>
                      <Typography variant="title2">
                        {result[address]?.risk?.level ? result[address].risk.level : '---'}
                      </Typography>
                    </Box>
                    <Box className="aml-manual-grid">
                      <Typography variant="subtitle3">{t('common:activity')}</Typography>
                      <Typography variant="title2">
                        {result[address]?.activity?.suspicious_activity
                          ? result[address].activity.suspicious_activity.length
                          : '---'}
                      </Typography>
                    </Box>
                  </Box>
                </Paper>

                <Paper elevation={0}>
                  <Box className="aml-manual-grid">
                    <Typography variant="subtitle3">{t('common:sourceInfo')}</Typography>
                    {result.share_url ? (
                      <Link onClick={() => window.open(result.share_url, '_blank', 'rel=noopener noreferrer')}>
                        <Typography color="action" variant="title2">
                          {t('common:viewSource')}
                        </Typography>
                      </Link>
                    ) : (
                      '---'
                    )}
                  </Box>
                </Paper>
              </Box>
            ) : (
              <Paper elevation={0} className="aml-manual-legend">
                <Typography variant="legend" textAlign="center">
                  {t('messages:manualCheckPreview')}
                </Typography>
              </Paper>
            )}
          </Paper>
        </Box>

        <Box className="modal-filter-buttons">
          <Button
            onClick={() => {
              handleReset()
            }}
            size="small"
            variant="outlinedGrey"
          >
            {t('common:reset')}
          </Button>
          <Button size="small" variant="contained" type="submit" disabled={cantSend()} onClick={(e) => handleSubmit()}>
            {t('common:startCheck')}
          </Button>
        </Box>
      </Box>

      {isFetching && <ProcessingSpinner message={t('common:processing')} />}
      {errorMessage !== '' && (
        <Box className="security-error">
          <Button size="small" variant="outlined" color="error">
            {errorMessage}
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default AmlCryptoManualCheck

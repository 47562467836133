import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateConsumerUserGeneralAlert, updateGeneralAlerts } from '../../features/generalAlerts/generalAlertsSlice'

export const useGeneralAlerts = (setIsLoading, isLoading) => {
  const { accessToken, emailClient } = useSelector((state) => state.login)
  const generalAlertsState = useSelector((state) => state.generalAlerts)
  const queryState = useSelector((state) => state.generalAlerts.filters)
  const { limit, offset, page, fromDate, toDate, id, type } = queryState
  const dispatch = useDispatch()

  function buildUrl(baseUrl) {
    let url = `${baseUrl}?emailClient=${emailClient}&limit=${limit}&page=${page}&offset=${offset}`
    if (id) url += `&id=${id}`
    if (type) url += `&type=${type}`
    if (fromDate && toDate) url += `&fromDate=${fromDate}&toDate=${toDate}`
    return url
  }

  useEffect(() => {
    if (isLoading) {
      const baseUrlGeneralAlerts = `${process.env.REACT_APP_BASEURL}/generalAlerts`

      let urlGetReports = buildUrl(baseUrlGeneralAlerts)

      const options = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }

      fetch(process.env.REACT_APP_IS_DEMO ? '../../data/all.general.alerts.json' : urlGetReports, options)
        .then((res) => res.json())
        .then((res) => {
          if (res.data) {
            const { data, pagination } = res
            const aux = { data, pagination }
            dispatch(updateGeneralAlerts(aux))
            setIsLoading(false)
          }
        })
        .catch((error) => {
          console.error('[HOOK: useGeneralAlerts] --> ', error)
          setIsLoading(false)
        })
    }
  }, [generalAlertsState, queryState, isLoading])

  return generalAlertsState.alerts
}

export const useGeneralAlertsByConsumerUserId = (consumerUserId, setIsLoading) => {
  const [rendered, setRendered] = useState(false)
  const { accessToken } = useSelector((state) => state.login)
  const dispatch = useDispatch()

  useEffect(() => {
    if (consumerUserId && rendered) {
      const baseUrlGeneralAlerts = `${process.env.REACT_APP_BASEURL}/generalAlerts/aml/consumerUser/${consumerUserId}`

      const options = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }

      fetch(process.env.REACT_APP_IS_DEMO ? '../data/alerts.general.json' : baseUrlGeneralAlerts, options)
        .then((res) => res.json())
        .then((res) => {
          if (res.data) {
            dispatch(updateConsumerUserGeneralAlert(res.data))
          }
          setIsLoading(false)
        })
        .catch((error) => {
          setIsLoading(false)
          console.error('[HOOK: useTransactions] --> ', error)
        })
    }
  }, [rendered, consumerUserId])

  useEffect(() => {
    setRendered(true)
  }, [])
}

import { createSlice } from '@reduxjs/toolkit'

export const alertSlice = createSlice({
  name: 'alert',
  initialState: {
    alerts: {},
    filters: {
      page: 0,
      limit: 50,
      offset: 0,
      alertId: '',
      ruleId: '',
      transactionId: '',
      fromDate: null,
      toDate: '',
    },
  },
  reducers: {
    updateAlerts: (state, alerts) => {
      state.alerts = alerts.payload
    },
    updateAlertsFilters: (state, filters) => {
      state.filters = filters.payload
    },
  },
})

export const { updateAlerts, updateAlertsFilters } = alertSlice.actions

export default alertSlice.reducer

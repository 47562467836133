const kybs = {
  "success": true,
  "message": "retrieve_kyb_success",
  "pagination": {
    "totalElements": 6234,
    "elementsPerPage": 100,
    "currentPage": 1
  },
  "data": [
    {
      "_id": "65df9b9059af913ca758dd29",
      "emailClient": "superadmin@gueno.com",
      "status": "APPROVED",
      "riskLevel": "MEDIUM",
      "companyDetails": {
        "name": "Stark Bank S.A.",
        "documentNumber": "20018183000180"
      },
      "createdAt": "2024-02-28T20:46:08.244Z"
    },
    {
      "_id": "65e68161f1992b0a95434f04",
      "status": "NOT_STARTED",
      "riskLevel": "MEDIUM",
      "companyDetails": {
        "name": "Slambda LLC",
        "documentNumber": 20732677923336
      },
      "createdAt": "Tue Jun 27 1978 15:13:42 GMT+0200 (hora de verano de Europa central)"
    },
    {
      "_id": "65e6816172b6cb54d7b53533",
      "status": "DENIED",
      "riskLevel": "VERY_LOW",
      "companyDetails": {
        "name": "Pasturia",
        "documentNumber": 20601033835374
      },
      "createdAt": "Tue Aug 29 1989 01:35:28 GMT+0200 (hora de verano de Europa central)"
    },
    {
      "_id": "65e6816149107ce6069dc9aa",
      "status": "DENIED",
      "riskLevel": "MEDIUM",
      "companyDetails": {
        "name": "Quantasis S.A",
        "documentNumber": 20630181881255
      },
      "createdAt": "Thu Apr 01 2004 15:40:06 GMT+0200 (hora de verano de Europa central)"
    },
    {
      "_id": "65e68161f21502a04a8cbc02",
      "status": "NOT_STARTED",
      "riskLevel": "NOT_DEFINED",
      "companyDetails": {
        "name": "Talkola",
        "documentNumber": 20530784443322
      },
      "createdAt": "Sun Jan 19 2014 22:31:37 GMT+0100 (hora estándar de Europa central)"
    },
    {
      "_id": "65e681611091a1c7f3309924",
      "status": "IN_PROGRESS",
      "riskLevel": "LOW",
      "companyDetails": {
        "name": "Geekus",
        "documentNumber": 20291544533993
      },
      "createdAt": "Wed Sep 27 2000 19:58:16 GMT+0200 (hora de verano de Europa central)"
    },
    {
      "_id": "65e68161609dece4a810eac4",
      "status": "APPROVED",
      "riskLevel": "MEDIUM",
      "companyDetails": {
        "name": "Xsports",
        "documentNumber": 20440562892132
      },
      "createdAt": "Fri Jan 13 2012 04:03:21 GMT+0100 (hora estándar de Europa central)"
    },
    {
      "_id": "65e6816139483245373896d0",
      "status": "DENIED",
      "riskLevel": "HIGH",
      "companyDetails": {
        "name": "Marqet LLC",
        "documentNumber": 20083457963994
      },
      "createdAt": "Sat Dec 30 2006 07:36:46 GMT+0100 (hora estándar de Europa central)"
    },
    {
      "_id": "65e681614f02f81cffeebcfe",
      "status": "DENIED",
      "riskLevel": "MEDIUM",
      "companyDetails": {
        "name": "Isologics",
        "documentNumber": 20647886886200
      },
      "createdAt": "Wed Mar 07 2001 22:04:30 GMT+0100 (hora estándar de Europa central)"
    },
    {
      "_id": "65e68161f705965db317c7c4",
      "status": "IN_PROGRESS",
      "riskLevel": "VERY_LOW",
      "companyDetails": {
        "name": "Rotodyne",
        "documentNumber": 20610946816493
      },
      "createdAt": "Thu Jul 14 2016 09:39:15 GMT+0200 (hora de verano de Europa central)"
    },
    {
      "_id": "65e681612384b9470d726f12",
      "status": "DENIED",
      "riskLevel": "HIGH",
      "companyDetails": {
        "name": "Comvey S.A.",
        "documentNumber": 20211884207402
      },
      "createdAt": "Sun Jun 14 1970 03:25:49 GMT+0100 (hora estándar de Europa central)"
    },
    {
      "_id": "65e68161b8375f61c6f09c18",
      "status": "NOT_STARTED",
      "riskLevel": "NOT_DEFINED",
      "companyDetails": {
        "name": "Netur",
        "documentNumber": 20756230311331
      },
      "createdAt": "Sun Jan 14 1979 15:02:04 GMT+0100 (hora estándar de Europa central)"
    },
    {
      "_id": "65e68161b4c35a5c5f638657",
      "status": "IN_PROGRESS",
      "riskLevel": "NOT_DEFINED",
      "companyDetails": {
        "name": "Squish",
        "documentNumber": 20159785715447
      },
      "createdAt": "Mon May 13 1996 09:06:21 GMT+0200 (hora de verano de Europa central)"
    },
    {
      "_id": "65e68161cab9ac03a01bc80c",
      "status": "DENIED",
      "riskLevel": "VERY_HIGH",
      "companyDetails": {
        "name": "Sunclipse",
        "documentNumber": 20409695936079
      },
      "createdAt": "Sun Oct 24 2010 09:35:23 GMT+0200 (hora de verano de Europa central)"
    },
    {
      "_id": "65e6816173e9084e6164124e",
      "status": "NOT_STARTED",
      "riskLevel": "VERY_LOW",
      "companyDetails": {
        "name": "Asimiline",
        "documentNumber": 20233765840334
      },
      "createdAt": "Tue Sep 27 1988 13:33:56 GMT+0100 (hora estándar de Europa central)"
    },
    {
      "_id": "65e6816129d17f69002ad516",
      "status": "NOT_STARTED",
      "riskLevel": "NOT_DEFINED",
      "companyDetails": {
        "name": "Koogle",
        "documentNumber": 20551372316914
      },
      "createdAt": "Thu Apr 14 2011 09:20:52 GMT+0200 (hora de verano de Europa central)"
    },
    {
      "_id": "65e68161c6a5ecf114162277",
      "status": "DENIED",
      "riskLevel": "VERY_HIGH",
      "companyDetails": {
        "name": "Prowaste",
        "documentNumber": 20398002039271
      },
      "createdAt": "Wed Mar 26 2008 19:19:37 GMT+0100 (hora estándar de Europa central)"
    },
    {
      "_id": "65e68161131c972ac20588c6",
      "status": "DENIED",
      "riskLevel": "MEDIUM",
      "companyDetails": {
        "name": "Solgan",
        "documentNumber": 20774223023769
      },
      "createdAt": "Mon Apr 13 1992 06:48:10 GMT+0200 (hora de verano de Europa central)"
    },
    {
      "_id": "65e6816130b1737c2d36163f",
      "status": "IN_PROGRESS",
      "riskLevel": "VERY_LOW",
      "companyDetails": {
        "name": "Caxt",
        "documentNumber": 20961459040123
      },
      "createdAt": "Sun Jun 18 2017 06:36:55 GMT+0200 (hora de verano de Europa central)"
    },
    {
      "_id": "65e68161110ce8627ceda1e3",
      "status": "IN_PROGRESS",
      "riskLevel": "VERY_LOW",
      "companyDetails": {
        "name": "Digigene",
        "documentNumber": 20198283097081
      },
      "createdAt": "Sat Oct 12 1991 09:47:50 GMT+0100 (hora estándar de Europa central)"
    },
    {
      "_id": "65e681616430936238bfb9c2",
      "status": "APPROVED",
      "riskLevel": "HIGH",
      "companyDetails": {
        "name": "Rocklogic",
        "documentNumber": 20573100209892
      },
      "createdAt": "Mon Jan 16 1989 11:51:27 GMT+0100 (hora estándar de Europa central)"
    },
    {
      "_id": "65e68161ed8f6bde2985ef5a",
      "status": "APPROVED",
      "riskLevel": "HIGH",
      "companyDetails": {
        "name": "Paragonia",
        "documentNumber": 20498207666221
      },
      "createdAt": "Mon Aug 14 2017 18:35:28 GMT+0200 (hora de verano de Europa central)"
    },
    {
      "_id": "65e68161ae7524f40fcde2a1",
      "status": "IN_PROGRESS",
      "riskLevel": "VERY_HIGH",
      "companyDetails": {
        "name": "Bytrex",
        "documentNumber": 20756463429313
      },
      "createdAt": "Sat Mar 12 1977 02:32:37 GMT+0100 (hora estándar de Europa central)"
    },
    {
      "_id": "65e68161206963a3619b7314",
      "status": "IN_PROGRESS",
      "riskLevel": "VERY_HIGH",
      "companyDetails": {
        "name": "Qimonk",
        "documentNumber": 20051024807525
      },
      "createdAt": "Tue Oct 25 1994 01:07:15 GMT+0100 (hora estándar de Europa central)"
    },
    {
      "_id": "65e681618ddb82eee294235d",
      "status": "APPROVED",
      "riskLevel": "MEDIUM",
      "companyDetails": {
        "name": "Capscreen",
        "documentNumber": 20975869225357
      },
      "createdAt": "Sat Jul 19 1986 19:06:26 GMT+0200 (hora de verano de Europa central)"
    },
    {
      "_id": "65e681611b3fe356d371539a",
      "status": "NOT_STARTED",
      "riskLevel": "LOW",
      "companyDetails": {
        "name": "Geofarm",
        "documentNumber": 20772581299733
      },
      "createdAt": "Wed Nov 28 2012 14:43:07 GMT+0100 (hora estándar de Europa central)"
    }
  ]
}


export default kybs;
import { Handle, Position } from 'react-flow-renderer'
import GuenoLogo from './../../../../../../assets/logos/gueno-logo.png'
import TrelloLogo from './../../../../../../assets/logos/trello-logo.png'
import SlackLogo from './../../../../../../assets/logos/slack-logo.png'
import JiraLogo from './../../../../../../assets/logos/jira-logo.png'
import ZappierLogo from './../../../../../../assets/logos/zappier-logo.png'
import './StandardNode.scss'

export const StandardCustomNode = ({ id, data }) => {
  const getLogo = (label) => {
    switch (label) {
      case 'Trello':
        return TrelloLogo
      case 'Slack':
        return SlackLogo
      case 'Jira':
        return JiraLogo
      case 'Zappier':
        return ZappierLogo
      default:
        return GuenoLogo
    }
  }

  return (
    <div className="dnd-node standard-node">
      <Handle type="target" position={Position.Left} id={`${id}-input`} isConnectable={true} />
      <div className="standard-node-body">
        <div style={{ background: data.category.color }} className="node-category-color" />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span>{data.label}</span>
          <img src={getLogo(data?.label)} alt="gueno's service" style={{ width: 18, height: 18, marginLeft: 4 }} />
        </div>
      </div>
      <Handle type="source" position={Position.Right} id={`${id}-output`} isConnectable={true} />
    </div>
  )
}

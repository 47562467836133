import jsPDF from 'jspdf'
import Papa from 'papaparse'
import csvToXml from 'csv-to-xml'
import 'jspdf-autotable'

export const transactionCSVtoPDF = (data, fileName) => {
  const doc = new jsPDF({ orientation: 'landscape' })
  // Set the table width to the full width of the page
  const pageWidth = doc.internal.pageSize.width
  const margin = 10 // You can adjust the margin as needed
  const tableWidth = pageWidth - 2 * margin

  doc.autoTable({
    showHead: 'firstPage',
    columns: data.columns,
    body: data.body,
    startY: 18,
    styles: { overflow: 'linebreak', cellWidth: 'wrap', cellPadding: 1, fontSize: 5 },
    columnStyles: { text: { cellWidth: 'auto' } },
    tableWidth: tableWidth,
  })
  doc.save(`${fileName}.pdf`)
}

export const transactionCSVtoXML = (csv, fileName) => {
  const xml = csvToXml(csv, { rowName: fileName })
  downloadFile(xml, fileName, 'xml', 'text/xml;charset=utf-8;')
}

export const convertToCSV = (data) => {
  return Papa.unparse(data)
}

export const downloadCSV = (csvData, fileName, type = 'text/csv;charset=utf-8;') => {
  downloadFile(csvData, fileName, 'csv', type)
}

const downloadFile = (data, fileName, extension, type) => {
  const blob = new Blob([data], { type })
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = `${fileName}.${extension}`
  a.click()
  window.URL.revokeObjectURL(url)
}

export const toFlatPropertyMap = (obj, keySeparator = '.') => {
  const flattenRecursive = (obj, parentProperty, propertyMap = {}) => {
    for (const [key, value] of Object.entries(obj)) {
      const property = parentProperty ? `${parentProperty}${keySeparator}${key}` : key
      if (value && typeof value === 'object') {
        flattenRecursive(value, property, propertyMap)
      } else {
        propertyMap[property] = value
      }
    }
    return propertyMap
  }
  return flattenRecursive(obj)
}

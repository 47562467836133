import React from 'react'
import Home from './views/Main/index'

import 'reactflow/dist/style.css'

const ReactFlowTest = () => {
  return <Home />
}

export default ReactFlowTest

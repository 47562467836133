import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { updateAml, updateAmlDetail } from '../../features/aml/amlSlice'

export const useAml = (emailClient, setIsLoading) => {
  const aml = useSelector((state) => state.aml)
  const queryState = useSelector((state) => state.aml?.filters)
  const { limit, offset, fromDate, toDate, page, id, search_term, search_id, match_status, hit_id } = queryState
    ? queryState
    : { ...null }
  const { accessToken } = useSelector((state) => state.login)

  const dispatch = useDispatch()

  function buildUrl(baseUrl) {
    let url = `${baseUrl}?limit=${limit}&page=${page}&offset=${offset}`
    if (fromDate && toDate) url += `&fromDate=${fromDate}&toDate=${toDate}`
    if (id?.length) url += `&id=${id}`
    if (search_id?.length) url += `&search_id=${search_id}`
    if (match_status?.length) url += `&match_status=${match_status}`
    if (hit_id?.length) url += `&hit_id=${hit_id}`
    if (search_term?.length) url += `&search_term=${search_term}`

    return url
  }

  useEffect(() => {
    const baseUrlAml = `${process.env.REACT_APP_BASEURL}/aml/getAllChecks/${emailClient}`
    let url = buildUrl(baseUrlAml)

    fetch(process.env.REACT_APP_IS_DEMO ? '../data/aml.json' : url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res) {
          const { data, pagination } = res
          const aux = { data, pagination }
          dispatch(updateAml(aux))
          setIsLoading(false)
        }
      })
      .catch((error) => {
        console.error('[HOOK: useAml] --> ', error)
        setIsLoading(false)
      })
  }, [queryState])

  return aml.aml
}

export const useAmlDetail = (id, setIsLoading) => {
  const aml = useSelector((state) => state.aml)
  const { accessToken } = useSelector((state) => state.login)

  const dispatch = useDispatch()

  useEffect(() => {
    const baseUrl = `${process.env.REACT_APP_BASEURL}/aml/getByReferenceId/${id}?viewMode=true`

    fetch(process.env.REACT_APP_IS_DEMO ? '../data/amlDetails.json' : baseUrl, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        //tanto success como no se guarda data
        dispatch(updateAmlDetail(res.data))
        setIsLoading(false)
      })
      .catch((error) => {
        console.error('[HOOK: useAmlDetail] --> ', error)
        dispatch(updateAmlDetail({ statusCode: 404 }))
        setIsLoading(false)
      })
  }, [id])

  return aml.aml
}

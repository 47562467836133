import { createSlice } from '@reduxjs/toolkit'
import { searchTypes } from '../../pages/kyb/constants'

export const kybSlice = createSlice({
  name: 'kyb',
  initialState: {
    filters: {
      page: 0,
      limit: 25,
      offset: 0,
      fromDate: null,
      toDate: '',
      status: 'ALL',
      showMyAssignments: false,
      searchType: searchTypes[0],
      searchText: '',
    },
  },
  reducers: {
    updateKybFilters: (state, action) => {
      state.filters = action.payload
    },
    addKybFilter: (state, action) => {
      state.filters = { ...state.filters, ...action.payload }
    },
  },
})

export const { updateKybFilters, addKybFilter } = kybSlice.actions

export default kybSlice.reducer

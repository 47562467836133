import { buildTimeStringFromTimestamp } from '../../time/timeHelper'

export const dataExportReportDataMapperForPDF = (data) => {
  const header = [
    'User',
    'Status',
    'Action Type',
    'Abm Action',
    'Action Result',
    'Affected User',
    'Affected User 2',
    'Collection',
    'Email User',
    'Extension',
    'From Date',
    'Limit',
    'Offset',
    'Page',
    'Report Role Name',
    'Filter Status',
    'To Date',
    'Extension',
    'Collection',
    'Date',
  ]
  const parsedData = data.map((report) => {
    return [
      report.emailUser,
      report.activityDetails?.actionResult,

      report.activityDetails?.filters?.ActionType,
      report.activityDetails?.filters?.abmAction,
      report.activityDetails?.filters?.actionResult,
      report.activityDetails?.filters?.affectedUser,
      report.activityDetails?.filters?.affectedUser2,
      report.activityDetails?.filters?.collection,
      report.activityDetails?.filters?.emailUser,
      report.activityDetails?.filters?.extension,
      buildTimeStringFromTimestamp(report.activityDetails?.filters?.fromDate),
      report.activityDetails?.filters?.limit,
      report.activityDetails?.filters?.offset,
      report.activityDetails?.filters?.page,
      report.activityDetails?.filters?.reportRoleName,
      report.activityDetails?.filters?.status,
      buildTimeStringFromTimestamp(report.activityDetails?.filters?.toDate),

      report.activityDetails?.extension,
      report.activityDetails?.data,
      buildTimeStringFromTimestamp(report.createdAt),
    ]
  })

  return {
    columns: header,
    body: parsedData,
  }
}

export const dataExportReportDataMapper = (data) => {
  return data.map((report) => {
    return {
      User: report.emailUser,
      Status: report.activityDetails?.actionResult,

      ActionType: report.activityDetails?.filters?.ActionType,
      AbmAction: report.activityDetails?.filters?.abmAction,
      ActionResult: report.activityDetails?.filters?.actionResult,
      AffectedUser: report.activityDetails?.filters?.affectedUser,
      AffectedUser2: report.activityDetails?.filters?.affectedUser2,
      FilterCollection: report.activityDetails?.filters?.collection,
      EmailUser: report.activityDetails?.filters?.emailUser,
      FilterExtension: report.activityDetails?.filters?.extension,
      FromDate: buildTimeStringFromTimestamp(report.activityDetails?.filters?.fromDate),
      Limit: report.activityDetails?.filters?.limit,
      Offset: report.activityDetails?.filters?.offset,
      Page: report.activityDetails?.filters?.page,
      ReportRoleName: report.activityDetails?.filters?.reportRoleName,
      filterStatus: report.activityDetails?.filters?.status,
      ToDate: buildTimeStringFromTimestamp(report.activityDetails?.filters?.toDate),

      Extension: report.activityDetails?.extension,
      Collection: report.activityDetails?.data,
      Date: buildTimeStringFromTimestamp(report.createdAt),
    }
  })
}

import '../../kyt/user/user.scss'
import '../cases/case.scss'
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown'
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp'
import { useEffect, useState } from 'react'
import { Box, Button, Checkbox, ListItemText, MenuItem, OutlinedInput, Select, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { Accordion, AccordionDetails, AccordionSummary } from '../../../components/common/Accordion/Accordion'
import { useCaseAdminPanel } from '../../pages-hooks/useCases'
import { useTranslation } from 'react-i18next'
import ListOfStrings from '../../../components/kyt/rules/ruleParameters2/listOfStrings/listOfStrings'
import EditIcon from '@mui/icons-material/Edit'
import { MenuProps } from '../../../components/utilities/MenuProps'
import { useRolesByEmailClient } from '../../pages-hooks/useRoles'
import ProcessingSpinner from '../../../components/common/alertFetchSpinner/processingSpinner'
import { RenderTooltip } from '../../../components/utilities/RenderTooltip'
import { DataGrid } from '@mui/x-data-grid'
import { useWorkflows } from '../../pages-hooks/useWorkflows'

const CaseAdminPanel = ({ isLoading, setIsLoading }) => {
  const { accessToken, emailClient } = useSelector((state) => state.login)
  const { caseAdminPanel } = useSelector((state) => state.case)
  const [isEditing, setIsEditing] = useState(false)
  const [defaultAssigned, setSelectDefaultAssigned] = useState(caseAdminPanel.defaultAssigned ?? [])
  const [defaultWorkflow, setSelectDefaultWorkflow] = useState(caseAdminPanel.defaultWorkflow ?? '')

  const [error, setError] = useState(false)
  const [errorList, setErrorList] = useState([])
  const [caseAdminPanelReload, setCaseAdminPanelReload] = useState(false)
  const rolesState = useSelector((state) => state.role?.roles)
  const rolesItems = rolesState.data !== 'Forbidden resource' ? rolesState?.data : []

  const [labels, setLabels] = useState({})
  const [categories, setCategories] = useState([])
  const [statusAccess, setStatusAccess] = useState([])

  useRolesByEmailClient(emailClient, isLoading, setIsLoading)
  useCaseAdminPanel(setIsLoading, isLoading)
  const workflows = useWorkflows(setIsLoading, isLoading)

  const roleNames = rolesState?.data?.map((r) => r.roleName)

  const [expanded, setExpanded] = useState([false, false, false, false, false])
  const [isFetching, setIsFetching] = useState(false)
  const { t } = useTranslation(['common', 'messages'])

  useEffect(() => {
    if (caseAdminPanel) {
      if (caseAdminPanel.labels) {
        setLabels({ labels: caseAdminPanel.labels })
      }
      if (caseAdminPanel.categories) setCategories({ categories: caseAdminPanel.categories })
      if (caseAdminPanel.statusAccess) setStatusAccess(caseAdminPanel.statusAccess)
    }
  }, [caseAdminPanel])

  const handleChange = (position) => {
    let aux = [...expanded]
    aux[position] = !aux[position]
    setExpanded([...aux])
  }

  const setAllEqual = () => {
    let aux = []
    if (!expanded.includes(true)) {
      expanded.forEach(() => aux.push(true))
    } else {
      expanded.forEach(() => aux.push(false))
    }
    setExpanded([...aux])
  }

  const handleCheckChange = (checked, obj) => {
    let aux = [...defaultAssigned]
    if (checked) {
      aux.push(obj)
    } else {
      aux = aux.filter((item) => item._id !== obj._id)
    }
    setSelectDefaultAssigned([...aux])
  }

  const handleCaseAdminPanelUpdate = () => {
    setIsFetching(true)
    setIsLoading(true)
    const body = {
      labels: labels.labels,
      categories: categories.categories,
      statusAccess,
      defaultAssigned: defaultAssigned.map((item) => item._id),
      defaultWorkflow: defaultWorkflow !== '' ? defaultWorkflow : undefined,
    }
    const options = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(body),
    }

    const userUrl = `${process.env.REACT_APP_BASEURL}/caseAdminPanel/${caseAdminPanel.id}`
    fetch(userUrl, options)
      .then((res) => res.json())
      .then(() => {
        setIsFetching(false)
        setIsEditing(false)
        setIsLoading(false)
      })
      .catch((error) => {
        console.error('[CASES: caseUser] --> ', error)
        setIsFetching(false)
        setCaseAdminPanelReload(true)
        setIsLoading(false)
      })
  }

  const columns2 = [
    {
      field: '_id',
      headerName: 'ID',
      flex: 1,
      minWidth: 100,
      hide: true,
    },
    {
      field: 'checkbox',
      headerName: t('common:action'),
      flex: 1,
      hide: !isEditing,
      minWidth: 100,
      renderCell: (params) => {
        return (
          <Box>
            <Checkbox
              checked={defaultAssigned.find((d) => d._id === params.row._id) !== undefined || false}
              onChange={(e) => {
                handleCheckChange(e.target.checked, params.row)
              }}
            />
          </Box>
        )
      },
    },
    {
      field: 'roleName',
      headerName: t('common:role'),
      flex: 1,
      minWidth: 100,
      valueGetter: (params) => {
        return params.row.roleName
      },
    },
  ]

  return (
    <Box className="case-detail">
      {isFetching && <ProcessingSpinner message={t('common:processing')} />}

      <Box className="case-admin-panel-header">
        <Button variant="outlined" onClick={() => setAllEqual()}>
          {expanded.includes(true) ? t('common:hideAll') : t('common:expandAll')}
        </Button>
        {!isEditing && (
          <Button
            onClick={() => {
              setIsEditing(true)
            }}
            variant="outlined"
            endIcon={<EditIcon sx={{ fill: '#00c43d' }} />}
          >
            {t('common:edit')}
          </Button>
        )}
      </Box>

      <Accordion variant="medium" expanded={expanded[0]} onChange={() => handleChange(0)}>
        <AccordionSummary expandIcon={null}>
          <Box className="case-detail-accordions-buttons">
            <Typography variant="text2">{t('common:labels')}</Typography>
            <Button
              variant="outlinedBlank"
              onChange={(e) => {
                e.stopPropagation()
                return handleChange(0)
              }}
            >
              {expanded[0] ? <KeyboardDoubleArrowDownIcon /> : <KeyboardDoubleArrowUpIcon />}
            </Button>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <ListOfStrings
            propertie={'labels'}
            ruleParameters={labels}
            setRuleParameters={setLabels}
            isEditing={isEditing}
            setError={setError}
            errorList={errorList}
            setErrorList={setErrorList}
            caseAdminPanelReload={caseAdminPanelReload}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion variant="medium" expanded={expanded[1]} onChange={() => handleChange(1)}>
        <AccordionSummary expandIcon={null}>
          <Box className="case-detail-accordions-buttons">
            <Typography variant="text2">{t('common:categories')}</Typography>
            <Button
              variant="outlinedBlank"
              onChange={(e) => {
                e.stopPropagation()
                return handleChange(1)
              }}
            >
              {expanded[1] ? <KeyboardDoubleArrowDownIcon /> : <KeyboardDoubleArrowUpIcon />}
            </Button>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <ListOfStrings
            propertie={'categories'}
            ruleParameters={categories}
            setRuleParameters={setCategories}
            isEditing={isEditing}
            setError={setError}
            errorList={errorList}
            setErrorList={setErrorList}
            caseAdminPanelReload={caseAdminPanelReload}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion variant="medium" expanded={expanded[2]} onChange={() => handleChange(2)}>
        <AccordionSummary expandIcon={null}>
          <Box className="case-detail-accordions-buttons">
            <Typography variant="text2">{t('common:statusAccess')}</Typography>
            <Button
              variant="outlinedBlank"
              onChange={(e) => {
                e.stopPropagation()
                return handleChange(2)
              }}
            >
              {expanded[2] ? <KeyboardDoubleArrowDownIcon /> : <KeyboardDoubleArrowUpIcon />}
            </Button>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box className="case-admin-panel-grid">
            <Box className="case-admin-status">
              <Typography variant="subtitle2">{t('common:pending')}</Typography>
              {!isEditing && (
                <Typography variant="subtitle3">
                  {statusAccess?.PENDING?.length ? statusAccess?.PENDING?.join(', ') : 'ALL'}
                </Typography>
              )}
              {isEditing && (
                <Select
                  required
                  id="roles"
                  size="small"
                  multiple
                  value={statusAccess?.PENDING ?? []}
                  renderValue={(selected) => selected.join(', ')}
                  onChange={(e) =>
                    setStatusAccess({
                      ...statusAccess,
                      PENDING: typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value,
                    })
                  }
                  input={<OutlinedInput />}
                  MenuProps={MenuProps}
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  {roleNames?.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox checked={statusAccess?.PENDING?.indexOf(name) > -1} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              )}
            </Box>
            <Box className="case-admin-status">
              <Typography variant="subtitle2">{t('common:review_in_progress')}</Typography>
              {!isEditing && (
                <Typography variant="subtitle3">
                  {statusAccess?.REVIEW_IN_PROGRESS?.length ? statusAccess?.REVIEW_IN_PROGRESS?.join(', ') : 'ALL'}
                </Typography>
              )}
              {isEditing && (
                <Select
                  required
                  id="roles"
                  size="small"
                  multiple
                  value={statusAccess?.REVIEW_IN_PROGRESS ?? []}
                  renderValue={(selected) => selected.join(', ')}
                  onChange={(e) =>
                    setStatusAccess({
                      ...statusAccess,
                      REVIEW_IN_PROGRESS:
                        typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value,
                    })
                  }
                  input={<OutlinedInput />}
                  MenuProps={MenuProps}
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  {roleNames?.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox checked={statusAccess?.REVIEW_IN_PROGRESS?.indexOf(name) > -1} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              )}
            </Box>
            <Box className="case-admin-status">
              <Typography variant="subtitle2">{t('common:review_finished')}</Typography>
              {!isEditing && (
                <Typography variant="subtitle3">
                  {statusAccess?.REVIEW_FINISHED?.length ? statusAccess?.REVIEW_FINISHED?.join(', ') : 'ALL'}
                </Typography>
              )}
              {isEditing && (
                <Select
                  required
                  id="roles"
                  size="small"
                  multiple
                  value={statusAccess?.REVIEW_FINISHED ?? []}
                  renderValue={(selected) => selected.join(', ')}
                  onChange={(e) =>
                    setStatusAccess({
                      ...statusAccess,
                      REVIEW_FINISHED: typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value,
                    })
                  }
                  input={<OutlinedInput />}
                  MenuProps={MenuProps}
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  {roleNames?.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox checked={statusAccess?.REVIEW_FINISHED?.indexOf(name) > -1} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              )}
            </Box>
            <Box className="case-admin-status">
              <Typography variant="subtitle2">{t('common:closed_with_actions')}</Typography>
              {!isEditing && (
                <Typography variant="subtitle3">
                  {statusAccess?.CLOSED_WITH_ACTIONS?.length ? statusAccess?.CLOSED_WITH_ACTIONS?.join(', ') : 'ALL'}
                </Typography>
              )}
              {isEditing && (
                <Select
                  required
                  id="roles"
                  size="small"
                  multiple
                  value={statusAccess?.CLOSED_WITH_ACTIONS ?? []}
                  renderValue={(selected) => selected.join(', ')}
                  onChange={(e) =>
                    setStatusAccess({
                      ...statusAccess,
                      CLOSED_WITH_ACTIONS:
                        typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value,
                    })
                  }
                  input={<OutlinedInput />}
                  MenuProps={MenuProps}
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  {roleNames?.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox checked={statusAccess?.CLOSED_WITH_ACTIONS?.indexOf(name) > -1} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              )}
            </Box>
            <Box className="case-admin-status">
              <Typography variant="subtitle2">{t('common:closed_without_actions')}</Typography>
              {!isEditing && (
                <Typography variant="subtitle3">
                  {statusAccess?.CLOSED_WITHOUT_ACTIONS?.length
                    ? statusAccess?.CLOSED_WITHOUT_ACTIONS?.join(', ')
                    : 'ALL'}
                </Typography>
              )}
              {isEditing && (
                <Select
                  required
                  id="roles"
                  size="small"
                  multiple
                  value={statusAccess?.CLOSED_WITHOUT_ACTIONS ?? []}
                  renderValue={(selected) => selected.join(', ')}
                  onChange={(e) =>
                    setStatusAccess({
                      ...statusAccess,
                      CLOSED_WITHOUT_ACTIONS:
                        typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value,
                    })
                  }
                >
                  {roleNames?.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox checked={statusAccess?.CLOSED_WITHOUT_ACTIONS?.indexOf(name) > -1} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              )}
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>

      <Accordion variant="medium" expanded={expanded[3]} onChange={() => handleChange(3)}>
        <AccordionSummary expandIcon={null}>
          <Box className="case-detail-accordions-buttons">
            <Box className="case-edit-wrapper">
              <Typography variant="text2">{t('common:defaultCaseAssignment')}</Typography>
              <RenderTooltip title={t('messages:updateAssignAdmin')} />
            </Box>
            <Button
              variant="outlinedBlank"
              onChange={(e) => {
                e.stopPropagation()
                return handleChange(3)
              }}
            >
              {expanded[3] ? <KeyboardDoubleArrowDownIcon /> : <KeyboardDoubleArrowUpIcon />}
            </Button>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          {!isEditing ? (
            <DataGrid
              getRowId={(row) => row._id || row.id}
              rows={Array.isArray(defaultAssigned) && defaultAssigned.length ? defaultAssigned : []}
              columns={columns2}
              hideFooter={true}
              autoHeight={true}
            />
          ) : (
            <DataGrid
              getRowId={(row) => row._id || row.id}
              rows={Array.isArray(rolesItems) && rolesItems.length ? rolesItems : []}
              columns={columns2}
              hideFooter={true}
              autoHeight={true}
            />
          )}
        </AccordionDetails>
      </Accordion>

      <Accordion variant="medium" expanded={expanded[4]} onChange={() => handleChange(4)}>
        <AccordionSummary expandIcon={null}>
          <Box className="case-detail-accordions-buttons">
            <Box className="case-edit-wrapper">
              <Typography variant="text2">{t('common:defaultWorkflowAssignment')}</Typography>
              <RenderTooltip title={t('messages:updateWorkflowAdmin')} />
            </Box>
            <Button
              variant="outlinedBlank"
              onChange={(e) => {
                e.stopPropagation()
                return handleChange(4)
              }}
            >
              {expanded[4] ? <KeyboardDoubleArrowDownIcon /> : <KeyboardDoubleArrowUpIcon />}
            </Button>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Select
            size="small"
            disabled={!isEditing}
            value={defaultWorkflow}
            onChange={(e) => setSelectDefaultWorkflow(e.target.value)}
            displayEmpty
          >
            <MenuItem disabled value="">
              <Typography variant="subtitle3">
                {t('common:select')} {t('common:workflow')}
              </Typography>
            </MenuItem>
            {Array.isArray(workflows?.workflows?.data) &&
              workflows?.workflows?.data?.length > 0 &&
              workflows?.workflows?.data?.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
          </Select>
        </AccordionDetails>
      </Accordion>

      {isEditing && (
        <Box className="case-admin-panel-header">
          <Button
            onClick={() => {
              setIsEditing(false)
              setCaseAdminPanelReload(true)
              setIsLoading(true)
            }}
            variant="outlinedGrey"
          >
            {t('common:cancel')}
          </Button>

          <Button
            onClick={() => {
              handleCaseAdminPanelUpdate()
            }}
            variant="outlined"
          >
            {t('common:save')}
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default CaseAdminPanel

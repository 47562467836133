export const buildTimeStringFromTimestamp = (timestamp) => {
  const date = new Date(timestamp)
  //NO VA CON DOBLE !== PORQUE NO LO TOMA SINO
  if (timestamp && date != 'Invalid Date')
    return `${date.toLocaleDateString()} - ${date.toTimeString().split('GMT')[0]}`
  return '---'
}

export const parseNiceDateAndTimeFromTimeString = (string) => {
  if (!string) {
    return 'Invalid Input';
  }
  try {
    const [datePart, timePart] = string.split('T');
    if (!datePart || !timePart) {
      return 'Invalid Input';
    }
    const [year, month, day] = datePart.split('-').map(num => parseInt(num, 10));
    if (isNaN(year) || isNaN(month) || isNaN(day)) {
      return 'Invalid Date';
    }
    const shortYear = year % 100;
    const [hours, minutes] = timePart.split(':');
    return `${month}/${day}/${shortYear}-${hours}:${minutes}`;
  } catch {
    return ''
  }
}

import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  IconButton,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import EditIcon from '@mui/icons-material/Edit'
import { useDispatch, useSelector } from 'react-redux'
import { performSetAlertSettings } from '../../../features/auth/loginSlice'
import AlertFetchSpinner from '../../../components/common/alertFetchSpinner/alertFetchSpinner'
import Spinner from '../../../components/common/spinner/spinner'
import { useNavigate } from 'react-router-dom'
import './alertSettings.scss'
import { useTranslation } from 'react-i18next'
import { useRolesByEmailClient } from '../../pages-hooks/useRoles'

const AlertSettings = () => {
  const { accessToken, alertSettings, activeServices, emailClient } = useSelector((state) => state.login)
  const rolesState = useSelector((state) => state.role?.roles)
  const roleNames = rolesState.data !== 'Forbidden resource' ? rolesState?.data?.map((r) => r.roleName) : []
  const [fetchError, setFetchError] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [fetchMessage, setFetchMessage] = useState('')
  const [formData, setFormData] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [edit, setEdit] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation(['common', 'messages'])

  useRolesByEmailClient(emailClient, isLoading, setIsLoading)

  if (!activeServices || !activeServices.includes('alertSettingsemailClient')) navigate('/', { replace: true })

  const handleFetch = (error, message) => {
    setIsFetching(true)
    setFetchError(error)
    setFetchMessage(message)
    setTimeout(() => {
      setIsFetching(false)
    }, 3000)
  }

  const handleSwitch = (name, key, value) => {
    const updatedFormData = formData.enabledAlerts.map((alert) => {
      if (alert.name === name) {
        if (key === 'service') {
          return { ...alert, enabled: !alert.enabled }
        } else {
          const updatedSettings = alert.paramSettings.map((setting) => {
            if (setting.key === key) {
              return { ...setting, value: value }
            }
            return setting
          })
          return { ...alert, paramSettings: updatedSettings }
        }
      }
      return alert
    })

    setFormData({ ...formData, enabledAlerts: updatedFormData })
  }

  useEffect(() => {
    if (!alertSettings || !Object.keys(alertSettings).length) {
      getAlertSettings()
    }
  }, [])

  useEffect(() => {
    if (alertSettings && Object.keys(alertSettings).length) {
      setErrorMessage('')
      setFormData(alertSettings.data)
    } else {
      setErrorMessage(t('messages:dontHavePermissions'))
    }
  }, [alertSettings])

  const getAlertSettings = () => {
    setIsLoading(true)
    const urlAlertSettings = `${process.env.REACT_APP_BASEURL}/alertSettings/emailClient`
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    }

    fetch(process.env.REACT_APP_IS_DEMO ? '../data/alertSettings.json' : urlAlertSettings, options)
      .then((res) => res.json())
      .then((res) => {
        if (res.data === 'Forbidden resource') {
          setErrorMessage(t('messages:dontHavePermissions'))
          setIsLoading(false)
        }
        if (!res.success && res.message && res.message.length !== 0) {
          setIsLoading(false)
          handleFetch(true, res.message ? res.message : t('messages:getAlertSettingsError'))
          throw Error(res.message.toString())
        } else {
          setErrorMessage('')
          dispatch(performSetAlertSettings(res))
          setIsLoading(false)
        }
      })
      .catch((error) => {
        console.error('[GET ALERT SETTINGS ERROR] --> ', error)
        handleFetch(true, error.message ? error.message : t('messages:getAlertSettingsError'))
      })
  }

  const updateAlertSettings = (id, data) => {
    setIsLoading(true)
    if (!process.env.REACT_APP_IS_DEMO) {
      const formatted = { enabledAlerts: data.enabledAlerts }
      const urlAlertSettings = `${process.env.REACT_APP_BASEURL}/alertSettings/${id}`
      const options = {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(formatted),
      }

      fetch(urlAlertSettings, options)
        .then((res) => res.json())
        .then((res) => {
          if (!res.success && res.message && res.message.length !== 0) {
            setIsLoading(false)
            handleFetch(true, res.message ? res.message : t('messages:updateAlertSettingsError'))
            throw Error(res.message.toString())
          }
          dispatch(performSetAlertSettings(res))
          setIsLoading(false)
          handleFetch(false, t('messages:updateAlertSettingsSuccess'))
        })
        .catch((error) => {
          console.error('[UPDATE ALERT SETTINGS ERROR] --> ', error)
          handleFetch(true, error.message ? error.message : t('messages:updateAlertSettingsError'))
          getAlertSettings()
        })
    } else {
      setIsLoading(false)
      handleFetch(false, t('messages:updateAlertSettingsSuccess'))
    }
  }

  const handleSubmit = (e, id) => {
    e.preventDefault()
    setEdit(false)

    updateAlertSettings(id, formData)
  }

  const NumberSelect = ({ min, max, defaultValue, type, alert, disabled }) => {
    const numbers = Array.from(
      { length: (type === 'filters.remove_deceased' ? 1 : max) - min + 1 },
      (_, index) => min + index,
    )

    return (
      <Box>
        {type === 'fuzziness' ? (
          <TextField
            inputProps={{
              min: 0.0,
              max: 1.0,
              step: '0.01',
            }}
            fullWidth
            disabled={disabled}
            id={type}
            key={type}
            type="number"
            size="small"
            value={defaultValue}
            onChange={(e) => handleSwitch(alert, type, parseFloat(e.target.value))}
          />
        ) : (
          <Select
            disabled={disabled}
            id={type}
            key={type}
            size="small"
            style={{ minWidth: '4rem' }}
            defaultValue={defaultValue ?? ''}
            fullWidth
            onChange={(e) => handleSwitch(alert, type, e.target.value)}
          >
            {numbers.map((number) => (
              <MenuItem key={number} value={number}>
                {number}
              </MenuItem>
            ))}
          </Select>
        )}
      </Box>
    )
  }

  return (
    <Box className={`${isLoading && 'spinner-transition'} filter`}>
      {isLoading && <Spinner noTransform />}

      {isFetching && <AlertFetchSpinner message={fetchMessage} error={fetchError} />}

      <Box className="component-title-wrapper">
        <Box className="component-title">
          <Typography variant="h2">{t('common:alertSettings')}</Typography>
        </Box>
        <Typography variant="subtitle3">
          {/* Loren ipsum dolor sit amet Loren ipsum dolor sit amet Loren  */}
        </Typography>
      </Box>

      {errorMessage ? (
        <Box className="security-error">
          <Button size="small" variant="outlined" color="error">
            {errorMessage}
          </Button>
        </Box>
      ) : (
        <Box className="alert-settings-wrapper alert-settings-overflow">
          {formData &&
            formData?.enabledAlerts?.length &&
            formData?.enabledAlerts?.map((alert, indexAlert) => {
              return (
                <Paper elevation={0} key={indexAlert}>
                  <Box className="alert-settings-service">
                    <Box className="security-flex">
                      <Box className="alert-settings-switch">
                        <Typography variant="title">
                          {t('common:service')}: {alert.name.toUpperCase()}
                        </Typography>

                        <Switch
                          disabled={edit !== indexAlert}
                          color="secondary"
                          name="service"
                          id="service"
                          onChange={(e) => handleSwitch(alert.name, 'service', e.target.checked)}
                          checked={alert.enabled}
                        />
                      </Box>
                      <IconButton
                        key={indexAlert}
                        onClick={() => {
                          setEdit(indexAlert)
                        }}
                        style={{ color: '#0DDE4E' }}
                      >
                        <EditIcon style={{ height: '1.45rem' }} />
                      </IconButton>
                    </Box>

                    <Box>
                      {alert.paramSettings.map((setting, index) => {
                        return (
                          <Box key={index} className="flex-alert">
                            <Typography variant="subtitle3">{setting.key || ''}</Typography>
                            {typeof setting.value === 'string' && (
                              <TextField
                                fullWidth
                                size="small"
                                type="text"
                                disabled={edit !== indexAlert}
                                value={setting.value}
                                onChange={(e) => handleSwitch(alert.name, setting.key, e.target.value)}
                              />
                            )}
                            {typeof setting.value === 'number' && (
                              <NumberSelect
                                min={0}
                                max={setting.default}
                                alert={alert.name}
                                defaultValue={setting?.value}
                                type={setting.key}
                                disabled={!edit}
                                indexAlert={indexAlert}
                              />
                            )}
                            {typeof setting.value === 'boolean' && (
                              <Switch
                                color="secondary"
                                disabled={edit !== indexAlert}
                                name={setting?.key}
                                onChange={(e) => handleSwitch(alert.name, setting.key, e.target.checked)}
                                checked={setting?.value}
                              />
                            )}
                            {Array.isArray(setting.value) &&
                              setting.key !== 'emailsToNotifyConsumers' &&
                              setting.key !== 'emailsToNotifyBusinesses' && (
                                <Select
                                  required
                                  style={{ minWidth: '10rem' }}
                                  multiple
                                  size="small"
                                  value={setting?.value}
                                  onChange={(e) =>
                                    handleSwitch(
                                      alert.name,
                                      setting.key,
                                      typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value,
                                    )
                                  }
                                  renderValue={(selected) => selected.join(', ')}
                                  defaultValue=""
                                  disabled={edit !== indexAlert}
                                >
                                  {setting.key !== 'rolesToNotify' &&
                                    setting.default?.map((name) => {
                                      return (
                                        <MenuItem key={name} value={name}>
                                          <Checkbox checked={setting?.value.indexOf(name) > -1} />
                                          <ListItemText primary={name} />
                                        </MenuItem>
                                      )
                                    })}
                                  {setting.key === 'rolesToNotify' &&
                                    roleNames &&
                                    roleNames?.map((name) => {
                                      return (
                                        <MenuItem key={name} value={name}>
                                          <Checkbox checked={setting?.value.indexOf(name) > -1} />
                                          <ListItemText primary={name} />
                                        </MenuItem>
                                      )
                                    })}
                                </Select>
                              )}

                            {(setting.key === 'emailsToNotifyConsumers' ||
                              setting.key === 'emailsToNotifyBusinesses') && (
                              <Autocomplete
                                disabled={edit !== indexAlert}
                                value={setting.value}
                                onChange={(event, newValue) => {
                                  handleSwitch(alert.name, setting.key, newValue)
                                }}
                                multiple
                                id="tags-filled"
                                className="tags-chips"
                                options={[]}
                                freeSolo
                                renderTags={(value, getTagProps) =>
                                  value.map((option, index) => (
                                    <Chip variant="filled" color="primary" label={option} {...getTagProps({ index })} />
                                  ))
                                }
                                renderInput={(params) => <TextField {...params} placeholder="New Value" />}
                              />
                            )}

                            <Divider style={{ margin: '.6rem 0rem 1.6rem 0rem', gridColumn: '1 / 3' }} />
                          </Box>
                        )
                      })}
                    </Box>
                    {edit === indexAlert ? (
                      <Box className="modal-buttons">
                        <Button
                          onClick={() => {
                            setEdit(false)
                            return getAlertSettings()
                          }}
                          size="small"
                          variant="outlinedGrey"
                        >
                          {t('common:cancel')}
                        </Button>
                        <Button
                          size="small"
                          variant="contained"
                          type="submit"
                          onClick={(e) => handleSubmit(e, formData.id)}
                        >
                          {t('common:save')}
                        </Button>
                      </Box>
                    ) : (
                      ''
                    )}
                  </Box>
                </Paper>
              )
            })}
        </Box>
      )}
    </Box>
  )
}

export default AlertSettings

import { Box, Button, InputLabel, MenuItem, Paper, Select, TextField, Tooltip, Typography } from '@mui/material'
import '../kytManualCheck.scss'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InfoIcon from '@mui/icons-material/Info'
import { countryDictionary } from '../../../../components/common/flag/countries'
import { RenderTooltip } from '../../../../components/utilities/RenderTooltip'

const cardFundings = ['CREDIT', 'DEBIT', 'PREPAID']
const cardTypes = ['VIRTUAL', 'PHYSICAL']

const CardDetails = ({ setPaymentDetails, isReset, setIsReset }) => {
  const [cardFingerprint, setCardFingerprint] = useState('')
  const [cardIssuedCountry, setCardIssuedCountry] = useState('')
  const [cardIssuedCountryView, setCardIssuedCountryView] = useState('')
  const [transactionReferenceField, setTransactionReferenceField] = useState('')
  const [a3dsDone, set3dsDone] = useState('')
  const [cardLast4Digits, setCardLast4Digits] = useState('')
  const [cardBrand, setCardBrand] = useState('')
  const [cardFunding, setCardFunding] = useState('')
  const [cardAuthenticated, setCardAuthenticated] = useState('')
  const [paymentChannel, setPaymentChannel] = useState('')
  const [cardType, setCardType] = useState('')
  const [firstName, setFirstName] = useState('')
  const [middleName, setMiddleName] = useState('')
  const [lastName, setLastName] = useState('')
  const [month, setMonth] = useState('')
  const [year, setYear] = useState('')
  const { t } = useTranslation(['common', 'messages'])
  const [change, setChange] = useState(false)

  useEffect(() => {
    if (change) {
      const body = {
        method: 'CARD',
        ...(cardFingerprint && { cardFingerprint }),
        ...(cardIssuedCountry && { cardIssuedCountry }),
        ...(transactionReferenceField && { transactionReferenceField }),
        ...(a3dsDone && { '3dsDone': a3dsDone }),
        ...(cardLast4Digits && { cardLast4Digits }),
        ...(cardBrand && { cardBrand }),
        ...(cardFunding && { cardFunding }),
        ...(cardAuthenticated && { cardAuthenticated }),
        ...(paymentChannel && { paymentChannel }),
        ...(cardType && { cardType }),
        ...(firstName && {
          nameOnCard: {
            firstName,
            ...(middleName && { middleName }),
            ...(lastName && { lastName }),
          },
        }),
        ...((month || year) && {
          cardExpiry: {
            ...(month && { month }),
            ...(year && { year }),
          },
        }),
      }

      setPaymentDetails(body)
      setChange(false)
    }
  }, [change])

  useEffect(() => {
    if (isReset) {
      setCardFingerprint('')
      setCardIssuedCountry('')
      setCardIssuedCountryView('')
      setTransactionReferenceField('')
      set3dsDone('')
      setCardLast4Digits('')
      setCardBrand('')
      setCardFunding('')
      setCardAuthenticated('')
      setPaymentChannel('')
      setCardType('')
      setFirstName('')
      setMiddleName('')
      setLastName('')
      setMonth('')
      setYear('')
      setIsReset(false)
    }
  }, [isReset])

  const getKeyByValue = (value) => {
    for (const key in countryDictionary) {
      if (countryDictionary[key] === value) {
        return key
      }
    }
    return null
  }

  return (
    <Paper elevation={0} className="kyt-form">
      <Box className="kyt-form-pair">
        <Box className="kyt-form-pair-label">
          <InputLabel>{t('common:cardFingerprint')} </InputLabel>
          <RenderTooltip
            title={
              'Unique card fingerprint that helps identify a specific card without having to use explicit card number. This is likely available at your card payment scheme provider.'
            }
          />
        </Box>

        <TextField
          fullWidth
          size="small"
          type="text"
          value={cardFingerprint}
          onChange={(event) => {
            setCardFingerprint(event.target.value)
            setChange(true)
          }}
          placeholder={`${t('common:insert')} ${t('common:cardFingerprint')} ${t('here')}`}
        />
      </Box>
      <Box className="kyt-form-pair">
        <Box className="kyt-form-pair-label">
          <InputLabel>{t('common:cardIssuedCountry')}</InputLabel>
          <RenderTooltip title={'The country of issuance for a specific card.'} />
        </Box>

        <Select
          fullWidth
          value={cardIssuedCountryView}
          onChange={(e) => {
            setCardIssuedCountry(getKeyByValue(e.target.value))
            setCardIssuedCountryView(e.target.value)
            return setChange(true)
          }}
          displayEmpty
        >
          <MenuItem disabled value="">
            <Typography variant="subtitle3">
              {t('common:select')} {t('common:cardIssuedCountry')}
            </Typography>
          </MenuItem>
          {Object.values(countryDictionary).map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </Box>

      <Box className="kyt-form-pair">
        <Box className="kyt-form-pair-label">
          <InputLabel>{t('common:transactionReferenceField')}</InputLabel>
        </Box>

        <TextField
          fullWidth
          size="small"
          type="text"
          value={transactionReferenceField}
          onChange={(event) => {
            setTransactionReferenceField(event.target.value)
            setChange(true)
          }}
          placeholder={`${t('common:insert')} ${t('common:transactionReferenceField')} ${t('here')}`}
        />
      </Box>
      <Box className="kyt-form-pair">
        <Box className="kyt-form-pair-label">
          <InputLabel>{t('common:a3dsDone')} </InputLabel>
          <RenderTooltip title={'Whether 3ds was successfully enforced for the transaction.'} />
        </Box>

        <TextField
          fullWidth
          size="small"
          type="text"
          value={a3dsDone}
          onChange={(event) => {
            set3dsDone(event.target.value)
            setChange(true)
          }}
          placeholder={`${t('common:insert')} ${t('common:a3dsDone')} ${t('here')}`}
        />
      </Box>

      <Box className="kyt-form-pair">
        <Box className="kyt-form-pair-label">
          <InputLabel>{t('common:cardLast4Digits')} </InputLabel>
        </Box>

        <TextField
          fullWidth
          size="small"
          type="text"
          value={cardLast4Digits}
          onChange={(event) => {
            setCardLast4Digits(event.target.value)
            setChange(true)
          }}
          placeholder={`${t('common:insert')} ${t('common:cardLast4Digits')} ${t('here')}`}
        />
      </Box>
      <Box className="kyt-form-pair">
        <Box className="kyt-form-pair-label">
          <InputLabel>{t('common:cardBrand')} </InputLabel>
        </Box>

        <TextField
          fullWidth
          size="small"
          type="text"
          value={cardBrand}
          onChange={(event) => {
            setCardBrand(event.target.value)
            setChange(true)
          }}
          placeholder={`${t('common:insert')} ${t('common:cardBrand')} ${t('here')}`}
        />
      </Box>
      <Box className="kyt-form-pair">
        <Box className="kyt-form-pair-label">
          <InputLabel>{t('common:cardFunding')} </InputLabel>
        </Box>

        <Select fullWidth value={cardFunding} onChange={(e) => setCardFunding(e.target.value)} displayEmpty>
          <MenuItem disabled value="">
            <Typography variant="subtitle3">
              {t('common:select')} {t('common:cardFunding')}
            </Typography>
          </MenuItem>
          {cardFundings.map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box className="kyt-form-pair">
        <Box className="kyt-form-pair-label">
          <InputLabel>{t('common:cardAuthenticated')} </InputLabel>
        </Box>

        <TextField
          fullWidth
          size="small"
          type="text"
          value={cardAuthenticated}
          onChange={(event) => {
            setCardAuthenticated(event.target.value)
            setChange(true)
          }}
          placeholder={`${t('common:insert')} ${t('common:cardBrand')} ${t('here')}`}
        />
      </Box>
      <Box className="kyt-form-pair">
        <Box className="kyt-form-pair-label">
          <InputLabel>{t('common:paymentChannel')} </InputLabel>
        </Box>

        <TextField
          fullWidth
          size="small"
          type="text"
          value={paymentChannel}
          onChange={(event) => {
            setPaymentChannel(event.target.value)
            setChange(true)
          }}
          placeholder={`${t('common:insert')} ${t('common:cardBrand')} ${t('here')}`}
        />
      </Box>
      <Box className="kyt-form-pair">
        <Box className="kyt-form-pair-label">
          <InputLabel>{t('common:cardType')} </InputLabel>
        </Box>

        <Select fullWidth value={cardType} onChange={(e) => setCardType(e.target.value)} displayEmpty>
          <MenuItem disabled value="">
            <Typography variant="subtitle3">
              {t('common:select')} {t('common:cardType')}
            </Typography>
          </MenuItem>
          {cardTypes.map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box className="kyt-form-pair">
        <Typography variant="subtitle3">{t('common:nameOnCard')}</Typography>

        <Box className="kyt-form-pair">
          <Box className="kyt-form-pair-label">
            <InputLabel>{t('common:firstName')} </InputLabel>
            <RenderTooltip title={'First name of the user.'} />
          </Box>

          <TextField
            fullWidth
            size="small"
            type="text"
            value={firstName}
            onChange={(event) => {
              setFirstName(event.target.value)
              setChange(true)
            }}
            placeholder={`${t('common:insert')} ${t('common:firstName')} ${t('here')}`}
          />
        </Box>
        <Box className="kyt-form-pair">
          <Box className="kyt-form-pair-label">
            <InputLabel>{t('common:middleName')} </InputLabel>
            <RenderTooltip title={'Middle name of the user.'} />
          </Box>

          <TextField
            fullWidth
            size="small"
            type="text"
            value={middleName}
            onChange={(event) => {
              setMiddleName(event.target.value)
              setChange(true)
            }}
            placeholder={`${t('common:insert')} ${t('common:middleName')} ${t('here')}`}
          />
        </Box>
        <Box className="kyt-form-pair">
          <Box className="kyt-form-pair-label">
            <InputLabel>{t('common:lastName')} </InputLabel>
            <RenderTooltip title={'Last name of the user.'} />
          </Box>

          <TextField
            fullWidth
            size="small"
            type="text"
            value={lastName}
            onChange={(event) => {
              setLastName(event.target.value)
              setChange(true)
            }}
            placeholder={`${t('common:insert')} ${t('common:lastName')} ${t('here')}`}
          />
        </Box>
      </Box>
      <Box className="kyt-form-pair">
        <Box className="kyt-form-pair-label">
          <Typography variant="subtitle3">{t('common:cardExpiry')}</Typography>
        </Box>

        <Box className="kyt-form-pair-label">
          <Box className="kyt-form-pair">
            <Box className="kyt-form-pair-label">
              <InputLabel>{t('common:month')} </InputLabel>
            </Box>

            <TextField
              fullWidth
              size="small"
              type="text"
              value={month}
              onChange={(event) => {
                setMonth(event.target.value)
                setChange(true)
              }}
              placeholder={`${t('common:insert')} ${t('common:month')} ${t('here')}`}
            />
          </Box>
          <Box className="kyt-form-pair">
            <InputLabel>{t('common:year')} </InputLabel>

            <TextField
              fullWidth
              size="small"
              type="text"
              value={year}
              onChange={(event) => {
                setYear(event.target.value)
                setChange(true)
              }}
              placeholder={`${t('common:insert')} ${t('common:year')} ${t('here')}`}
            />
          </Box>
        </Box>
      </Box>
    </Paper>
  )
}

export default CardDetails

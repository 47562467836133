import { useState, useEffect } from 'react'
import { Box } from '@mui/material'
import ListOfBlacklistIds from '../../../rules/ruleParameters2/blacklistids/listOfBlacklistIds'

const Lists = ({ propertie, ruleParameters, setRuleParameters, isEditing, filterTemplate }) => {
  const [finalParameter, setFinalParameter] = useState({})
  const [change, setChange] = useState(false)

  const getIndex = () => {
    if (Array.isArray(ruleParameters) && ruleParameters.length === 0) return -1
    if (ruleParameters) return ruleParameters.map((e) => e.filterTemplate).indexOf(filterTemplate)
    return -1
  }

  useEffect(() => {
    const aux = ruleParameters?.find((e) => e.filterTemplate === filterTemplate)

    if (aux) {
      setFinalParameter(aux)
    }
  }, [propertie])

  const hangleChange = (value) => {
    setFinalParameter(value)
    setChange(true)
  }

  useEffect(() => {
    if (change) {
      const index = getIndex()
      let all = [...ruleParameters]
      let previous = index >= 0 && all[index] ? { ...all[index] } : {}

      previous[propertie] = finalParameter[propertie]
      previous.filterTemplate = filterTemplate

      if (index !== -1) {
        all.splice(index, 1)
      }
      all.push(previous)

      setRuleParameters([...all])
    }
    setChange(false)
  }, [finalParameter])

  return (
    <Box className="childContainer">
      <ListOfBlacklistIds
        isEditing={isEditing}
        propertie={propertie}
        ruleParameters={finalParameter}
        setRuleParameters={hangleChange}
        type={'white_list'}
      />
    </Box>
  )
}

export default Lists

import { useState } from 'react'
import { Box, Button, Fab, InputLabel, MenuItem, Modal, Select, TextField, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { rolesTypesRelatedPersons } from '../constants'
import AlertFetchSpinner from '../../../components/common/alertFetchSpinner/alertFetchSpinner'

const AddRelatedPersonModal = ({ isOpen, onChangeIsOpen, onSubmit, statusAddSubject }) => {
  const { t } = useTranslation(['common', 'messages'])
  const [relatedPersonEntity, setRelatedPersonEntity] = useState('PERSON')
  const [roleTypeRelatedPerson, setRoleTypeRelatedPerson] = useState('')
  const [relatedPersonDocumentNumber, setRelatedPersonDocumentNumber] = useState('')
  const [relationshipRelatedPerson, setRelationshipRelatedPerson] = useState('')
  const [isEmptyRelatedPerson, setIsEmptyRelatedPerson] = useState(true)
  const { fetchError, fetchMessage, isProcessing, isFetchingModal } = statusAddSubject

  return (
    <Modal
      open={isOpen}
      onClose={() => onChangeIsOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
        <Box className="modal">
          <Box className="modal-top">
            <Box className="modal-titles">
              <Typography variant="title">{t('common:addNewRelatedPerson')}</Typography>
              <Typography variant="subtitle3">{t('messages:editLater')}</Typography>
            </Box>
            <Fab variant="close" onClick={() => onChangeIsOpen(false)}>
              <CloseOutlinedIcon />
            </Fab>
          </Box>
          <Box className="modal-box">
            <InputLabel>{t('common:documentNumber')}:</InputLabel>
            <TextField
              placeholder={t('messages:insertDocHere')}
              required
              type="text"
              value={relatedPersonDocumentNumber}
              onChange={(e) => {
                const value = e.target.value.replace(/[^0-9]/g, '')
                setRelatedPersonDocumentNumber(value)
              }}
              onBlur={() => setIsEmptyRelatedPerson(relatedPersonDocumentNumber.trim() === '')}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              error={isEmptyRelatedPerson}
              helperText={isEmptyRelatedPerson ? t('messages:fieldCannotBeEmpty') : ''}
            />
          </Box>
          <Box className="modal-box">
            <InputLabel>{t('common:entityType')}:</InputLabel>
            <Select
              size="small"
              value={'PERSON'}
              disabled
              onChange={(e) => setRelatedPersonEntity(e.target.value)}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {['PERSON', 'BUSINESS']?.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
            <Typography variant="subtitle4">{t('messages:shareholderDesc')}</Typography>
          </Box>
          <Box className="modal-box">
            <InputLabel>{t('common:roleType')}:</InputLabel>
            <Select
              value={roleTypeRelatedPerson}
              onChange={(e) => setRoleTypeRelatedPerson(e.target.value)}
              placeholder={`${t('common:insert')} ${t('common:roleType')}`}
            >
            {rolesTypesRelatedPersons.map((roleType) => (
              <MenuItem key={roleType} value={roleType}>
                {t(`messages:kyb.roleType.${roleType}`)}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box className="modal-box">
            <InputLabel>{t('common:relationship')}:</InputLabel>
            <TextField
              size="small"
              value={relationshipRelatedPerson}
              onChange={(e) => setRelationshipRelatedPerson(e.target.value)}
              placeholder={`${t('common:insert')} ${t('common:relationship')}`}
            />
          </Box>
          <Box className="modal-button">
            <Button
              size="small"
              color="secondary"
              variant="contained"
              disabled={isEmptyRelatedPerson}
              onClick={(e) =>
                onSubmit(
                  e,
                  relatedPersonDocumentNumber,
                  relatedPersonEntity,
                  roleTypeRelatedPerson,
                  relationshipRelatedPerson,
                )
              }
            >
              {t('common:save')}
            </Button>
            <Button size="small" color="secondary" variant="outlined" onClick={() => onChangeIsOpen(false)}>
              {t('common:cancel')}
          </Button>
          {isFetchingModal &&
            <Box className="modal-button">
              <AlertFetchSpinner message={fetchMessage} spinner={isProcessing} error={fetchError} />
            </Box>
          }
          </Box>
        </Box>
    </Modal>
  )
}

export default AddRelatedPersonModal
import { Box, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import './kytManualCheck.scss'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RenderTooltip } from '../../../components/utilities/RenderTooltip'

const vpnUseds = ['YES', 'NO']

const DeviceData = ({ setDeviceData, isReset, setIsReset }) => {
  const [batteryLevel, setBatteryLevel] = useState('')
  const [deviceLatitude, setDeviceLatitude] = useState('')
  const [deviceLongitude, setDeviceLongitude] = useState('')
  const [ipAddress, setIpAddress] = useState('')
  const [deviceIdentifier, setDeviceIdentifier] = useState('')
  const [vpnUsed, setVpnUsed] = useState('')
  const [vpnUsedView, setVpnUsedView] = useState('')
  const [operatingSystem, setOperatingSystem] = useState('')
  const [deviceMaker, setDeviceMaker] = useState('')
  const [deviceModel, setDeviceModel] = useState('')
  const [deviceYear, setDeviceYear] = useState('')
  const [appVersion, setAppVersion] = useState('')

  const { t } = useTranslation(['common', 'messages'])
  const [change, setChange] = useState(false)

  useEffect(() => {
    if (change) {
      const body = {
        ...(batteryLevel && { batteryLevel }),
        ...(deviceLatitude && { deviceLatitude }),
        ...(deviceLongitude && { deviceLongitude }),
        ...(ipAddress && { ipAddress }),
        ...(deviceIdentifier && { deviceIdentifier }),
        ...(vpnUsed !== '' && { vpnUsed }),
        ...(operatingSystem && { operatingSystem }),
        ...(deviceMaker && { deviceMaker }),
        ...(deviceModel && { deviceModel }),
        ...(deviceYear && { deviceYear }),
        ...(appVersion && { appVersion }),
      }

      setDeviceData(body)
      setChange(false)
    }
  }, [change])

  useEffect(() => {
    if (isReset) {
      setBatteryLevel('')
      setDeviceLatitude('')
      setDeviceLongitude('')
      setIpAddress('')
      setDeviceIdentifier('')
      setVpnUsed('')
      setVpnUsedView('')
      setOperatingSystem('')
      setDeviceMaker('')
      setDeviceModel('')
      setDeviceYear('')
      setAppVersion('')
      setIsReset(false)
    }
  }, [isReset])

  return (
    <Box className="kyt-sub-form">
      <Box className="kyt-form-pair">
        <Box className="kyt-form-pair-label">
          <InputLabel>{t('common:batteryLevel')}</InputLabel>
          <RenderTooltip title={'UPI Id number.'} />
        </Box>

        <TextField
          fullWidth
          type="number"
          placeholder={`${t('common:insert')} ${t('common:batteryLevel')} ${t('here')}`}
          size="small"
          value={batteryLevel}
          onChange={(event) => {
            setBatteryLevel(event.target.value !== '' ? parseInt(event.target.value) : '')
            setChange(true)
          }}
        />
      </Box>
      <Box className="kyt-form-pair">
        <Box className="kyt-form-pair-label">
          <InputLabel>{t('common:deviceLatitude')}</InputLabel>
          <RenderTooltip title={'Bank provider name.'} />
        </Box>
        <TextField
          fullWidth
          type="number"
          placeholder={`${t('common:insert')} ${t('common:deviceLatitude')} ${t('here')}`}
          size="small"
          value={deviceLatitude}
          onChange={(event) => {
            setDeviceLatitude(event.target.value !== '' ? parseInt(event.target.value) : '')
            setChange(true)
          }}
        />
      </Box>
      <Box className="kyt-form-pair">
        <Box className="kyt-form-pair-label">
          <InputLabel>{t('common:deviceLongitude')}</InputLabel>
          <RenderTooltip title={'Interface provider name.'} />
        </Box>
        <TextField
          fullWidth
          type="number"
          placeholder={`${t('common:insert')} ${t('common:deviceLongitude')} ${t('here')}`}
          size="small"
          value={deviceLongitude}
          onChange={(event) => {
            setDeviceLongitude(event.target.value !== '' ? parseInt(event.target.value) : '')
            setChange(true)
          }}
        />
      </Box>
      <Box className="kyt-form-pair">
        <Box className="kyt-form-pair-label">
          <InputLabel>{t('common:ipAddress')}</InputLabel>
          <RenderTooltip title={t('common:ipAddress')} />
        </Box>
        <TextField
          fullWidth
          size="small"
          value={ipAddress}
          onChange={(event) => {
            setIpAddress(event.target.value)
            setChange(true)
          }}
          placeholder={`${t('common:insert')} ${t('common:ipAddress')} ${t('here')}`}
        />
      </Box>
      <Box className="kyt-form-pair">
        <Box className="kyt-form-pair-label">
          <InputLabel>{t('common:deviceIdentifier')}</InputLabel>
          <RenderTooltip title={'UPI Id number.'} />
        </Box>
        <TextField
          fullWidth
          size="small"
          value={deviceIdentifier}
          onChange={(event) => {
            setDeviceIdentifier(event.target.value)
            setChange(true)
          }}
          placeholder={`${t('common:insert')} ${t('common:deviceIdentifier')} ${t('here')}`}
        />
      </Box>
      <Box className="kyt-form-pair">
        <Box className="kyt-form-pair-label">
          <InputLabel>{t('common:vpnUsed')}</InputLabel>
          <RenderTooltip title={'common:vpnUsed'} />
        </Box>

        <Select
          size="small"
          fullWidth
          value={vpnUsedView}
          onChange={(e) => {
            if (e.target.value) {
              setVpnUsed(e.target.value === 'YES')
              setVpnUsedView(e.target.value)
            } else {
              setVpnUsed('')
              setVpnUsedView('')
            }
            setChange(true)
          }}
          displayEmpty
        >
          <MenuItem disabled value="">
            <Typography variant="subtitle3">
              {t('common:select')} {t('common:vpnUsed')}
            </Typography>
          </MenuItem>
          {vpnUseds.map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box className="kyt-form-pair">
        <Box className="kyt-form-pair-label">
          <InputLabel>{t('common:operatingSystem')}</InputLabel>
          <RenderTooltip title={t('common:operatingSystem')} />
        </Box>

        <TextField
          fullWidth
          size="small"
          value={operatingSystem}
          onChange={(event) => {
            setOperatingSystem(event.target.value)
            setChange(true)
          }}
          placeholder={`${t('common:insert')} ${t('common:operatingSystem')} ${t('here')}`}
        />
      </Box>
      <Box className="kyt-form-pair">
        <Box className="kyt-form-pair-label">
          <InputLabel>{t('common:deviceMaker')}</InputLabel>
          <RenderTooltip title={t('common:deviceMaker')} />
        </Box>
        <TextField
          fullWidth
          size="small"
          value={deviceMaker}
          onChange={(event) => {
            setDeviceMaker(event.target.value)
            setChange(true)
          }}
          placeholder={`${t('common:insert')} ${t('common:deviceMaker')} ${t('here')}`}
        />
      </Box>
      <Box className="kyt-form-pair">
        <Box className="kyt-form-pair-label">
          <InputLabel>{t('common:deviceModel')}</InputLabel>
          <RenderTooltip title={t('common:deviceModel')} />
        </Box>
        <TextField
          fullWidth
          size="small"
          value={deviceModel}
          onChange={(event) => {
            setDeviceModel(event.target.value)
            setChange(true)
          }}
          placeholder={`${t('common:insert')} ${t('common:deviceModel')} ${t('here')}`}
        />
      </Box>
      <Box className="kyt-form-pair">
        <Box className="kyt-form-pair-label">
          <InputLabel>{t('common:deviceYear')}</InputLabel>
          <RenderTooltip title={t('common:deviceYear')} />
        </Box>
        <TextField
          fullWidth
          size="small"
          value={deviceYear}
          onChange={(event) => {
            setDeviceYear(event.target.value)
            setChange(true)
          }}
          placeholder={`${t('common:insert')} ${t('common:deviceYear')} ${t('here')}`}
        />
      </Box>
      <Box className="kyt-form-pair">
        <Box className="kyt-form-pair-label">
          <InputLabel>{t('common:appVersion')}</InputLabel>
          <RenderTooltip title={t('common:appVersion')} />
        </Box>
        <TextField
          fullWidth
          size="small"
          value={appVersion}
          onChange={(event) => {
            setAppVersion(event.target.value)
            setChange(true)
          }}
          placeholder={`${t('common:insert')} ${t('common:appVersion')} ${t('here')}`}
        />
      </Box>
    </Box>
  )
}

export default DeviceData

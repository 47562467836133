import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import IconLib from '../../../styles/icons/IconLib'
import './customNoRowsOverlay.scss'

const CustomNoRowsOverlay = () => {
  const { t } = useTranslation(['common'])

  return (
    <Box className="custom-overlay">
      <IconLib svg="sidebarView" classes="custom-overlay-icon" />
      <Typography sx={{ fontSize: '1rem', fontWeight: '500', color: '#66766B' }}>
        {t('common:nothingToShow')}
      </Typography>
    </Box>
  )
}

export default CustomNoRowsOverlay

import { Box, InputLabel, TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import './paymentFilters.scss'
import Select from '@mui/material/Select'
import OutlinedInput from '@mui/material/OutlinedInput'
import MenuItem from '@mui/material/MenuItem'
import { t } from 'i18next'
import { useTranslation } from 'react-i18next'

const PaymentFilters = ({
  propertie,
  ruleParameters,
  setRuleParameters,
  isEditing,
  possibleValues,
  possibleCountries,
  filterTemplate,
}) => {
  const [selectedMethods, setSelectedMethods] = useState([])
  const [cardIssuedCountries, setCardIssuedCountries] = useState([])
  const [paymentChannels, setPaymentChannels] = useState([])
  const [walletType, setWalletType] = useState('')
  const [showCardData, setShowCardData] = useState(false)
  const [showWalletData, setShowWalletData] = useState(false)
  const { t } = useTranslation(['common'])

  useEffect(() => {
    setShowCardData(selectedMethods.includes('CARD'))
    setShowWalletData(selectedMethods.includes('WALLET'))
  }, [selectedMethods])

  const getIndex = () => {
    if (Array.isArray(ruleParameters) && ruleParameters.length === 0) return -1
    if (ruleParameters) return ruleParameters.map((e) => e.filterTemplate).indexOf(filterTemplate)
    return -1
  }

  useEffect(() => {
    const index = getIndex()
    const buildedObject = buildObject()
    if (Object.keys(buildedObject).length !== 0) {
      let all = [...ruleParameters]
      let previous = index >= 0 && all[index] ? { ...all[index] } : {}
      previous[propertie] = buildedObject
      previous.filterTemplate = filterTemplate

      if (index !== -1) {
        all.splice(index, 1)
      }
      all.push(previous)

      setRuleParameters([...all])
    }
  }, [selectedMethods, cardIssuedCountries, paymentChannels, walletType])

  useEffect(() => {
    let aux = ruleParameters?.find((e) => e.filterTemplate === filterTemplate)
    if (aux && aux[propertie]) {
      aux = aux[propertie]
      setSelectedMethods(Array.isArray(aux.paymentMethods) ? aux.paymentMethods : [])
      setCardIssuedCountries(Array.isArray(aux.cardIssuedCountries) ? aux.cardIssuedCountries : [])
      setPaymentChannels(Array.isArray(aux.cardPaymentChannels) ? aux.cardPaymentChannels : [])
      setWalletType(aux.walletType ? aux.walletType : '')
    }
  }, [propertie])

  const buildObject = () => {
    const param = {
      paymentMethods: selectedMethods.length > 0 ? selectedMethods : null,
      walletType: selectedMethods.includes('WALLET') ? walletType : null,
      cardIssuedCountries: selectedMethods.includes('CARD') ? cardIssuedCountries : null,
      cardPaymentChannels: selectedMethods.includes('CARD') ? paymentChannels : null,
    }

    if (param.paymentMethods === null) delete param.paymentMethods
    if (param.walletType === null) delete param.walletType
    if (param.cardIssuedCountries === null) delete param.cardIssuedCountries
    if (param.cardPaymentChannels === null) delete param.cardPaymentChannels

    return param
  }

  const handlePaymentMethodsChange = (event) => {
    const {
      target: { value },
    } = event

    setSelectedMethods(typeof value === 'string' ? value.split(',') : value)
  }

  const handleCardIssuedCountriesChange = (event) => {
    const {
      target: { value },
    } = event

    setCardIssuedCountries(typeof value === 'string' ? value.split(',') : value)
  }

  const handlePaymentChannelChange = (event) => {
    const {
      target: { value },
    } = event

    setPaymentChannels(typeof value === 'string' ? value.split(',') : value)
  }

  const handleWalletTypeChange = (value) => {
    setWalletType(value)
  }

  return (
    <Box className="low-pair">
      <Box className="low-pair">
        <InputLabel>{t('common:paymentMethods')}</InputLabel>
        <Select
          fullWidth
          size="small"
          multiple
          displayEmpty
          disabled={!isEditing}
          value={selectedMethods}
          onChange={handlePaymentMethodsChange}
          input={<OutlinedInput />}
          renderValue={(selected) => {
            return selected.join(', ')
          }}
          inputProps={{ 'aria-label': 'Without label' }}
        >
          {possibleValues?.map((name) => (
            <MenuItem key={name} value={name}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </Box>

      {showCardData && (
        <Box className="low-pair">
          <Box className="low-pair">
            <InputLabel>{t('common:cardIssuedCountries')}</InputLabel>
            <Select
              fullWidth
              size="small"
              multiple
              displayEmpty
              disabled={!isEditing}
              value={cardIssuedCountries}
              onChange={handleCardIssuedCountriesChange}
              input={<OutlinedInput />}
              renderValue={(selected) => {
                return selected.join(', ')
              }}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {possibleCountries.map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box className="low-pair">
            <InputLabel>{t('common:paymentChannels')}</InputLabel>
            <Select
              fullWidth
              size="small"
              multiple
              displayEmpty
              disabled={!isEditing}
              value={paymentChannels}
              onChange={handlePaymentChannelChange}
              input={<OutlinedInput />}
              renderValue={(selected) => {
                return selected.join(', ')
              }}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {['GOOGLE_PAY'].map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
      )}
      {showWalletData && (
        <Box className="low-pair">
          <InputLabel>{t('common:walletType')}</InputLabel>

          <TextField
            size="small"
            type="text"
            disabled={!isEditing}
            variant="outlined"
            value={walletType}
            onChange={(event) => handleWalletTypeChange(event.target.value)}
          />
        </Box>
      )}
    </Box>
  )
}

export default PaymentFilters

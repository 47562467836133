import { Box, InputLabel, TextField, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { RenderTooltip } from "../../../utilities/RenderTooltip";
import styles from './TextInputForm.module.scss';

const TextInputForm = ({ name, control, label, placeholder = '', tooltip = '', required = false, type = 'text' }) => {
  const { t } = useTranslation(['common', 'messages'])

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field,
        fieldState,
      }) => {
        return (
          <Box className={styles.container}>
            <Box className={styles.label}>
              <InputLabel>{label}</InputLabel>
              {!!required && (
                <Typography variant="subtitle3">({t('common:required')})</Typography>
              )}
              {!!tooltip && (<RenderTooltip title={tooltip} />)}
            </Box>
  
            <TextField
              fullWidth
              helperText={fieldState.error ? fieldState.error.message : null}
              error={!!fieldState.error}
              type={type}
              placeholder={placeholder}
              size="small"
              {...field}
            />
          </Box>
        )
      }}
    />
  )
}

export default TextInputForm;

import { searchTypes } from "../pages/kyb/constants";

export const buildKybParams = (query = {}) => {
  const { email, fromDate, toDate, searchType, searchText, status } = query;
  const params = {};

  if (email) {
    params.assignedUsers = email;
  }

  if (fromDate && toDate) {
    params.fromDate = fromDate;
    params.toDate = toDate;
  }

  if (searchTypes.includes(searchType) && !!searchText) {
    params[searchType] = searchText;
  }

  if (status !== 'ALL' && !!status) {
    params.status = status;
  }

  return params;
}
import { ThemeProvider, createTheme } from '@mui/material'
import { forwardRef, useState } from 'react'

import MuiAlert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import Stack from '@mui/material/Stack'

const theme = createTheme({
  palette: {
    primary: {
      main: '#0ddd4e',
      light: '#67ff7f',
      dark: '#00aa18',
      contrastText: '#000000',
    },
    secondary: {
      main: '#1c1534',
      light: '#453b5e',
      dark: '#00000f',
      contrastText: '#ffffff',
    },
  },
})

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

export default function UserNotification({ notiUpdate, setNotiUpdate }) {
  const [open, setOpen] = useState(notiUpdate.notiUpdate)

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
    setNotiUpdate({ notiUpdate: false })
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Stack spacing={2} sx={{ width: '100%' }}>
          <Snackbar
            open={open}
            autoHideDuration={3000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          >
            {notiUpdate?.isCPO ? (
              <Alert onClose={handleClose} color={'secondary'}>
                'Request pending approval!'
              </Alert>
            ) : (
              <Alert onClose={handleClose} color={notiUpdate.status === 200 ? 'primary' : 'error'}>
                {notiUpdate.status === 200 ? 'Successful user update!' : 'Something went wrong. Updated incompleted.'}
              </Alert>
            )}
          </Snackbar>
        </Stack>
      </ThemeProvider>
    </>
  )
}

import './datatable.scss'
import { Link as ReactRouterLink } from 'react-router-dom'
import { Box, Button, Link, TablePagination, Typography } from '@mui/material'
import { buildTimeStringFromTimestamp } from '../time/timeHelper'
import { Status } from '../../utilities/Status'
import { Flags } from '../flag/flags'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { setIsLoading } from '../../../features/kyb/kybCountrySlice'
import KybNoRowsOverlay from '../../../pages/kyb/components/KybNoRowsOverlay'
import { useFindKycsQuery } from '../../../services/guenoApi'
import { useState } from 'react'
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined'
import { DataGridPro, GridColumnMenu, useGridApiRef } from '@mui/x-data-grid-pro'

const DatatableKyc = ({
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  setModalOpen,
  hasTabs,
  creationOnNoRows = false,
  documentNumber = '',
}) => {
  const { t } = useTranslation(['common'])
  const { email } = useSelector((state) => state.login)
  const queryState = useSelector((state) => state.kyc?.filters)
  const { currentCountry, countrySegment } = useSelector((state) => state.kybCountry)
  const [sortField, setSortField] = useState('createdAt')
  const [sortDirection, setSortDirection] = useState(-1)
  const { isFetching, isLoading, refetch, data } = useFindKycsQuery({
    email: queryState.showMyAssignments ? email : null,
    limit: rowsPerPage,
    sortField,
    sortDirection,
    countrySegment,
    ...queryState,
  })

  const columns = [
    {
      field: '_id',
      headerName: 'ID',
      flex: 4,
      renderCell: ({ row }) => {
        return <Typography variant="number">{row._id}</Typography>
      },
      sortable: false,
    },
    {
      field: 'name',
      headerName: t('common:name'),
      flex: 8,
      renderCell: ({ row }) => {
        return <p title={row.name ?? '---'}>{row.name ?? '---'}</p>
      },
    },
    {
      field: 'documentNumber',
      headerName: t('common:documentNumber'),
      flex: 3,
      renderCell: ({ row }) => {
        return <Typography variant="number">{row.documentNumber ?? '---'}</Typography>
      },
    },
    {
      field: 'nationality',
      headerName: t('common:nationality'),
      flex: 2,
      renderCell: ({ row }) => {
        return Flags(row.nationality === 'Brazil' ? 'BR' : row.nationality)
      },
    },
    {
      field: 'status',
      headerName: t('common:status'),
      flex: 3,
      renderCell: (params) => {
        return <Status type={params.row.status} />
      },
    },
    {
      field: 'createdAt',
      headerName: t('common:creationDate'),
      flex: 3,
      renderCell: ({ row }) => <Typography variant="number">{buildTimeStringFromTimestamp(row.createdAt)}</Typography>,
    },
    {
      field: 'actions',
      headerName: '',
      flex: 2,
      renderCell: ({ row }) => (
        <Link title={row._id} component={ReactRouterLink} to={`/kyc/${currentCountry}/detail/${row._id}`}>
          {t('common:viewDetails')}
        </Link>
      ),
      sortable: false,
    },
  ]

  function CustomColumnMenu(props) {
    return (
      <GridColumnMenu
        {...props}
        slots={{
          columnMenuColumnsItem: null,
          columnMenuFilterItem: null,
          columnMenuSortItem: null,
        }}
      />
    )
  }
  const slots = {
    columnMenu: CustomColumnMenu,
  }

  if (creationOnNoRows) {
    slots.noRowsOverlay = KybNoRowsOverlay
  }

  const apiRef = useGridApiRef()

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
    setIsLoading(true)
  }

  const handleSortModelChange = (sortModel) => {
    if (sortModel.length > 0) {
      const { field, sort } = sortModel[0]
      setSortField(field)
      setSortDirection(sort === 'asc' ? 1 : -1)
    } else {
      if (sortField === 'createdAt') {
        setSortDirection(sortDirection === -1 ? 1 : -1)
      } else {
        setSortField('createdAt')
        setSortDirection(-1)
      }
      refetch()
    }
    setPage(0)
    setIsLoading(true)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    setIsLoading(true)
  }

  return (
    <Box style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
      <Box className="filter-bar">
        <Button variant="label" endIcon={<MenuOutlinedIcon />} onClick={() => setModalOpen(true)}>
          {t('common:filters')}
        </Button>
        <Button
          variant="label"
          endIcon={<MenuOutlinedIcon />}
          onClick={() => apiRef.current.showPreferences('columns')}
        >
          {t('common:showHideColumns')}
        </Button>
      </Box>
      <DataGridPro
        getRowHeight={() => 'auto'}
        getRowId={(row) => row._id || row.id}
        rows={Array.isArray(data?.data) ? data?.data : []}
        columns={columns}
        apiRef={apiRef}
        slots={slots}
        slotProps={{
          noRowsOverlay: { defaultDocumentNumber: documentNumber, isKyb: false },
        }}
        hideFooter={true}
        autoHeight
        loading={isFetching || isLoading || false}
        sx={{
          height: hasTabs ? `calc(100vh - ${hasTabs})` : 'calc(100vh - 19.5rem)',
          '& .MuiDataGrid-virtualScroller': {
            overflow: 'auto',
          },
        }}
        sortingMode="server"
        sortModel={[{ field: sortField, sort: sortDirection === 1 ? 'asc' : 'desc' }]}
        onSortModelChange={handleSortModelChange}
      />

      <TablePagination
        labelRowsPerPage={[]}
        component="div"
        count={data?.pagination?.totalElements || 0}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  )
}

export default DatatableKyc

import { useEffect, useMemo, useState } from 'react'
import Button from '@mui/material/Button'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { Box, Fab, TextField, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined'
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined'

const KybListOfStrings = ({ title, stringsList, setStringsList, inputsDisabled, translateStrings, noAddRemove, columns = 1 }) => {
  const [receivedArray, setReceivedArray] = useState([])
  const [updatedList, setUpdatedList] = useState(receivedArray)
  const [change, setChange] = useState(false)
  const { t } = useTranslation(['common'])

  const handleSave = () => {
    setStringsList(updatedList)
    setChange(false)
  }

  const gridTemplateColumns = useMemo(() => Array(columns).fill().map(() => '1fr').join(' '), [columns]);

  const handleReset = () => {
    setUpdatedList(receivedArray)
    setChange(false)
  }

  const handleStringChange = (cluster, index) => {
    let aux = []
    updatedList.forEach((element) => {
      aux.push(element)
    })
    aux[index] = cluster

    setUpdatedList(aux)
    setChange(true)
  }

  const handleStringRemove = (index) => {
    const list = [...updatedList]
    list.splice(index, 1)
    setUpdatedList(list)

    if (list.every((item) => item !== '' && item !== undefined)) {
      setChange(true)
    }
  }

  const handleStringAdd = () => {
    setUpdatedList([...updatedList, ''])
  }

  const handleMovement = (up, index) => {
    let aux = []
    updatedList.forEach((element) => {
      aux.push(element)
    })
    let aux1 = updatedList[index]
    let aux2
    if (up) {
      aux2 = index - 1
    } else {
      aux2 = index + 1
    }
    aux[index] = aux[aux2]
    aux[aux2] = aux1
    setUpdatedList(aux)
    setChange(true)
  }

  useEffect(() => {
    if (stringsList) {
      setReceivedArray(stringsList)
    }
  }, [stringsList])

  useEffect(() => {
    setUpdatedList(receivedArray)
  }, [receivedArray])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
      }}
    >
      <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns }}>
        {title && <Typography variant="h4">{t(`common:${title}`)}</Typography>}
        {updatedList.map((item, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              gap: '1rem',
            }}
          >
            <TextField
              fullWidth
              type="text"
              variant="outlined"
              size="small"
              value={translateStrings? t(`${item}`): item}
              onChange={(event) => handleStringChange(event.target.value, index)}
              disabled={inputsDisabled}
            />
            <Box
              sx={{
                display: 'flex',
                gap: '0.5rem',
              }}
            >
              {index === 0 && updatedList.length > 1 && (
                <Fab variant="close" className="icon-fab" disabled>
                  <ExpandLessOutlinedIcon />
                </Fab>
              )}
              {index !== 0 && updatedList.length > 1 && (
                <Fab variant="close" className="icon-fab" onClick={() => handleMovement(true, index)}>
                  <ExpandLessOutlinedIcon />
                </Fab>
              )}
              {index !== updatedList.length - 1 && updatedList.length > 1 && (
                <Fab variant="close" className="icon-fab" onClick={() => handleMovement(false, index)}>
                  <ExpandMoreOutlinedIcon />
                </Fab>
              )}
              {index === updatedList.length - 1 && updatedList.length > 1 && (
                <Fab variant="close" className="icon-fab" disabled>
                  <ExpandMoreOutlinedIcon />
                </Fab>
              )}
              {!noAddRemove && (
                <Fab variant="close" className="icon-fab" onClick={() => handleStringRemove(index)}>
                  <DeleteOutlineOutlinedIcon />
                </Fab>
              )}
            </Box>
          </Box>
        ))}
      </Box>
      <Box display="flex" justifyContent="space-between">
        {!noAddRemove && (
          <Button endIcon={<AddCircleOutlineIcon />} variant="outlinedGrey" onClick={handleStringAdd}>
            {t('common:add')}
          </Button>
        )}
        <Box
          sx={{
            display: 'flex',
            gap: '1rem',
          }}
        >
          <Button variant="outlinedGrey" onClick={() => handleReset()} disabled={!change}>
            {t('common:cancel')}
          </Button>
          <Button variant="contained" onClick={() => handleSave()} disabled={!change}>
            {t('common:save')}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default KybListOfStrings

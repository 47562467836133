import React, { useState } from 'react'
import { Box, Button, Chip, Paper, Tab, Typography } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { Masonry, TabContext, TabList, TabPanel } from '@mui/lab'
import { useTranslation } from 'react-i18next'
import { HistoryOutlined } from '@mui/icons-material'

import './paymentMethodsDetails.scss'
import DatatableExternalPaymentMethodsDetail from '../../../components/common/datatable/DatatableExternalPaymentMethodsDetail'
import { usePaymentMethodDetail } from '../../pages-hooks/usePaymentMethodDetail'
import Spinner from '../../../components/common/spinner/spinner'
import DatatableExternalPaymentMethodsDetailUsers from '../../../components/common/datatable/DatatableExternalPaymentMethodsDetailUsers'
import { usePaymentMethodDetailUsers } from '../../pages-hooks/usePaymentMethodDetailUsers'

const ExternalPaymentMethodsDetail = () => {
  const { t } = useTranslation(['common'])
  const [isLoading, setIsLoading] = useState(true)
  const [selectedTab, setSelectedTab] = useState('origin')
  const location = useLocation()
  const [userType, setUserType] = useState('consumer')

  const paymentMethod = location.state

  usePaymentMethodDetail(paymentMethod, setIsLoading)
  usePaymentMethodDetailUsers(paymentMethod, userType, setIsLoading)

  const RenderObject = ({ obj }) => {
    if (Array.isArray(obj)) {
      return obj.map((item) => (
        <Box key={item.key} sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Typography variant="subtitle4">{t(`common:${item.key}`)}</Typography>
          <Typography variant="h6">{item.value}</Typography>
        </Box>
      ))
    }

    return Object.entries(obj).map(([key, value]) => {
      if (typeof value === 'object' && value !== null) {
        return (
          <Box key={key}>
            <Typography variant="subtitle1">{t(`common:${key}`)}</Typography>
            <RenderObject obj={value} />
          </Box>
        )
      }

      return (
        <Box key={key} sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Typography variant="subtitle4">{t(`common:${key}`)}</Typography>
          <Typography variant="h6">{String(value)}</Typography>
        </Box>
      )
    })
  }

  return (
    <Box className={`${isLoading && 'spinner-transition'} filter`}>
      {isLoading && <Spinner />}
      <Box className="payment-methods-details">
        <Paper elevation={0} className="payment-methods-details-top">
          <Typography variant="title">{t('common:PAYMENT_METHOD_DETAIL')}</Typography>
            {!!paymentMethod && (
              <Masonry columns={{ xs: 1, sm: 2, md: 4 }} spacing={2}>
                {Object.entries(paymentMethod).map(([key, value]) => (
                  <Paper key={key} elevation={0} variant="colorPrimary" className="payment-methods-box">
                    <Box>
                      <Typography variant="subtitle1">{t(`common:${key}`)}</Typography>
                      {typeof value === 'object' && value !== null ? (
                        <RenderObject obj={value} />
                      ) : (
                        <Typography>{String(value)}</Typography>
                      )}
                    </Box>
                  </Paper>
                ))}
              </Masonry>
            )}
            {!paymentMethod && (
              <Paper elevation={0} variant="colorPrimary" className="payment-methods-box" style={{ width: 250 }}>
                <Box>
                  <Typography variant="subtitle1">{t(`common:paymentMethod.notDefined`)}</Typography>
                </Box>
              </Paper>
            )}
        </Paper>

        <TabContext value={selectedTab}>
          <Box className="payment-methods-details-bottom">
            <Paper elevation={0} className="payment-methods-details-top">
              <Typography>{t('common:sections')}</Typography>
              <TabList
                TabIndicatorProps={{
                  style: { display: 'none' },
                }}
                className="payment-methods-tablist"
                onChange={(e, newValue) => setSelectedTab(newValue)}
              >
                <Tab
                  label={
                    <Chip
                      icon={selectedTab === 'origin' ? <HistoryOutlined /> : <></>}
                      label={t('common:transactions')}
                      variant={selectedTab === 'origin' ? 'sliderSelectedPurple' : 'sliderTransparent'}
                    />
                  }
                  value="origin"
                />
                <Tab
                  label={
                    <Chip
                      icon={selectedTab === 'users' ? <HistoryOutlined /> : <></>}
                      label={t('common:users')}
                      variant={selectedTab === 'users' ? 'sliderSelectedPurple' : 'sliderTransparent'}
                    />
                  }
                  value="users"
                />
              </TabList>
            </Paper>
            <Paper elevation={0}>
              <TabPanel value="origin" className="payment-methods-details-top">
                <Box>
                  <DatatableExternalPaymentMethodsDetail setIsLoading={setIsLoading} />
                </Box>
              </TabPanel>
              <TabPanel value="users" className="payment-methods-details-top">
                <Box className="users-type-buttons">
                  <Button
                    size="small"
                    variant={userType === 'consumer' ? 'contained' : 'outlined'}
                    onClick={() => setUserType('consumer')}
                    fullWidth
                  >
                    {t('common:consumer')}
                  </Button>
                  <Button
                    size="small"
                    variant={userType !== 'consumer' ? 'contained' : 'outlined'}
                    onClick={() => setUserType('business')}
                    fullWidth
                  >
                    {t('common:business')}
                  </Button>
                </Box>
                <Box>
                  <DatatableExternalPaymentMethodsDetailUsers setIsLoading={setIsLoading} userType={userType} />
                </Box>
              </TabPanel>
            </Paper>
          </Box>
        </TabContext>
      </Box>
    </Box>
  )
}

export default ExternalPaymentMethodsDetail

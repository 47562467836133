import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Autocomplete, TextField, CircularProgress } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { countryDictionary } from '../common/flag/countries'
import { selectAvailableCountries, setCurrentCountry } from '../../features/kyb/kybCountrySlice'

const CountrySelector = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['common', 'messages'])
  const { currentCountry } = useSelector((state) => state.kybCountry)
  const { emailClient } = useSelector((state) => state.login)
  const { countries, isLoading } = useSelector((state) => selectAvailableCountries(state, emailClient))

  const handleValueChange = useCallback(
    (newValue) => {
      const newCountryCode = Object.keys(countryDictionary).find((key) => countryDictionary[key] === newValue)
      if (newCountryCode) {
        dispatch(setCurrentCountry(newCountryCode))
      }
    },
    [dispatch],
  )

  const currentCountryValue = useMemo(() => {
    return countryDictionary[currentCountry] || ''
  }, [currentCountry])

  const options = useMemo(() => {
    if (countries.length) {
      return countries.map((countryCode) => countryDictionary[countryCode])
    }
    return [countryDictionary.BR]
  }, [countries])

  if (isLoading) {
    return <CircularProgress />
  }

  return (
    <Autocomplete
      size="small"
      className="kyb-country-selector"
      value={currentCountryValue}
      onChange={(event, newValue) => handleValueChange(newValue || '')}
      disablePortal
      options={options}
      renderInput={(params) => (
        <TextField variant="outlined" {...params} placeholder={`${t('common:selectCountry')}`} />
      )}
      disableClearable
    />
  )
}

export default CountrySelector

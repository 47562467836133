import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    consumerUsers: {},
    businessUsers: {},
    userDetails: {},
    filters: {
      viewMode: true,
      page: 0,
      limit: 50,
      fromDate: null,
      toDate: '',
      offset: 0,
      userId: '',
      fistName: '',
      lastName: '',
      birthDate: null,
      countryOfResidence: '',
      countryOfNationality: '',
      legalName: '',
      countryOfRegistration: '',
      userKey: '',
      userValue: '',
      documentNumber: '',
      selectedUserState: '',
      userKycStatus: '',
    },
  },
  reducers: {
    updateConsumerUsers: (state, action) => {
      state.consumerUsers = action.payload
    },
    updateBusinessUsers: (state, action) => {
      state.businessUsers = action.payload
    },
    updateUserDetails: (state, action) => {
      state.userDetails = action.payload
    },
    performLogoutUsers: (state) => {
      state.consumerUsers = {}
      state.businessUsers = {}
      state.userDetails = {}
    },
    updateUsersFilters: (state, filters) => {
      state.filters = filters.payload
    },
  },
})

export const { updateConsumerUsers, updateBusinessUsers, updateUserDetails, performLogoutUsers, updateUsersFilters } =
  userSlice.actions
export default userSlice.reducer

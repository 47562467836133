import { useDispatch, useSelector } from 'react-redux'
import { updateOnboardingDetail, updateOnboardings } from '../../features/onboarding/onboardingSlice'
import { useEffect } from 'react'

export const useOnboardings = (emailClient, setIsLoading) => {
  const onboarding = useSelector((state) => state.onboarding)
  const queryState = useSelector((state) => state.onboarding?.filters)
  const { limit, offset, fromDate, toDate, page, checkId, type, entityName } = queryState ? queryState : { ...null }
  const { accessToken } = useSelector((state) => state.login)

  const dispatch = useDispatch()

  function buildUrl(baseUrl) {
    let url = `${baseUrl}?limit=${limit}&page=${page}&offset=${offset}`
    if (fromDate && toDate) url += `&fromDate=${fromDate}&toDate=${toDate}`
    if (checkId?.length) url += `&checkId=${checkId}`
    if (type?.length) url += `&type=${type}`
    if (entityName?.length) url += `&entityName=${entityName}`

    return url
  }

  useEffect(() => {
    const baseUrlOnboardings = `${process.env.REACT_APP_BASEURL}/onboarding/getAllChecks/${emailClient}`
    let url = buildUrl(baseUrlOnboardings)

    fetch(process.env.REACT_APP_IS_DEMO ? '../../data/onboardings.json' : url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res) {
          const { data, pagination } = res
          let aux

          aux = { data, pagination }

          dispatch(updateOnboardings(aux))
          setIsLoading(false)
        }
      })
      .catch((error) => {
        console.error('[HOOK: useOnboardings] --> ', error)
        setIsLoading(false)
      })
  }, [queryState])

  return onboarding.onboardings
}

export const useOnboardingDetail = (id, setIsLoading) => {
  const onboarding = useSelector((state) => state.onboarding)
  const { accessToken } = useSelector((state) => state.login)

  const dispatch = useDispatch()

  useEffect(() => {
    const url = `${process.env.REACT_APP_BASEURL}/onboarding/getCheckById/${id}?viewMode=true`

    fetch(
      process.env.REACT_APP_IS_DEMO
        ? onboarding.onboardingDetailType === 'document_check'
          ? '../../data/onboardingDocumentDetails.json'
          : '../../data/onboardingIdentityDetails.json'
        : url,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    )
      .then((res) => res.json())
      .then((res) => {
        //tanto success como no se guarda data
        dispatch(updateOnboardingDetail(res.data))
        setIsLoading(false)
      })
      .catch((error) => {
        console.error('[HOOK: useOnboardingDetail] --> ', error)
        dispatch(updateOnboardingDetail({ statusCode: 404 }))
        setIsLoading(false)
      })
  }, [id])

  return onboarding.onboardings
}

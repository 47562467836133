import { Box, Button, Modal, Tooltip, Typography } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { useDeleteSubjectKybMutation } from '../../../services/guenoApi'
import { useGetCountrySegmentFromUrl } from '../kyb.hook-helpers'

const DeleteRelationModal = ({ kybId, subjectId, relationType, disabled = false }) => {
  const { t } = useTranslation(['common', 'messages'])
  const [_, countrySegment] = useGetCountrySegmentFromUrl()
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deleteSubjectKyb] = useDeleteSubjectKybMutation()

  const handleConfirmDelete = async () => {
    if (kybId && subjectId) {
      try {
        await deleteSubjectKyb({
          kybId,
          subjectId,
          relationType,
          countrySegment,
        }).unwrap()
        setShowDeleteModal(false)
      } catch (error) {
        console.error('Error al eliminar el sujeto:', error)
      }
    }
  }

  return (
    <>
      <Tooltip title={disabled ? t('common:kyb.actions.deleteLastBenecifiary') : ''} disabled={false}>
        <span>
          <Button
            onClick={() => setShowDeleteModal(true)}
            size="small"
            sx={{
              fontSize: 10,
            }}
            color="error"
            variant="outlined"
            disabled={disabled}
          >
            <DeleteOutlineIcon />
          </Button>
        </span>
      </Tooltip>
      <Modal open={showDeleteModal} onClose={() => setShowDeleteModal(!showDeleteModal)}>
        <Box className="modal" gap="1.5rem">
          <Typography variant="title">{t(`common:REMOVE_SUBJECT_KYB_MODAL_TITLE_${relationType}`)}</Typography>
          <Box display="flex" justifyContent="space-evenly" mt="1.5rem">
            <Button variant="outlined" color="secondary" onClick={() => setShowDeleteModal(!showDeleteModal)}>
              {t('common:cancel')}
            </Button>
            <Button variant="contained" color="primary" onClick={handleConfirmDelete}>
              {t('common:remove')}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  )
}

export default DeleteRelationModal

export const NodeCategoryType = {
  Basic: 'basics',
  Advanced: 'advanced',
  ThirdParties: '3rd-party libs',
  Other: 'others',
  All: 'all',
}

const NodeCategories = {
  [NodeCategoryType.Basic]: {
    name: "Güeno's basics",
    color: '#2196f3',
  },
  [NodeCategoryType.Advanced]: {
    name: "Güeno's advanced",
    color: '#0DDE4F',
  },
  // [NodeCategoryType.ThirdParties]: {
  //   name: 'Special',
  //   color: '#4caf50',
  // },
  [NodeCategoryType.Other]: {
    name: 'Other',
    color: '#f44336',
  },
  [NodeCategoryType.All]: {
    name: 'All',
    color: '#ede7f6',
  },
}

export default NodeCategories

import { createSlice } from '@reduxjs/toolkit'

export const amlSlice = createSlice({
  name: 'aml',
  initialState: {
    aml: {},
    amlDetail: {},
    filters: {
      page: 0,
      limit: 25,
      offset: 0,
      fromDate: null,
      toDate: '',
      id: '',
      search_id: '',
      hit_id: '',
      match_status: '',
      search_term: '',
    },
  },
  reducers: {
    updateAml: (state, action) => {
      state.aml = action.payload
    },
    updateAmlDetail: (state, action) => {
      state.amlDetail = action.payload
    },
    updateAmlFilters: (state, action) => {
      state.filters = action.payload
    },
  },
})

export const { updateAml, updateAmlDetail, updateAmlFilters } = amlSlice.actions
export default amlSlice.reducer

import './caseWorkflows.scss'
import '@xyflow/react/dist/style.css'
import { useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { Box, InputLabel, Button, TextField, Typography, Paper } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useCaseAdminPanel } from '../../pages-hooks/useCases'
import { ReactFlowProvider } from '@xyflow/react'
import AlertFetchSpinner from '../../../components/common/alertFetchSpinner/alertFetchSpinner'
import CaseFlow from './CaseFlow'
import { useRolesByEmailClient } from '../../pages-hooks/useRoles'

const WorkflowCreation = () => {
  const { accessToken, emailClient } = useSelector((state) => state.login)
  const workflows = useSelector((state) => state.workflows)
  const { id } = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const [workflow, setWorkflow] = useState([])
  const [name, setName] = useState('')
  const [steps, setSteps] = useState([])

  useCaseAdminPanel(setIsLoading, isLoading)
  useRolesByEmailClient(emailClient, isLoading, setIsLoading)

  const navigate = useNavigate()
  const { t } = useTranslation(['common', 'messages'])

  const [isFetching, setIsFetching] = useState(false)
  const [fetchMessage, setFetchMessage] = useState('')
  const [fetchError, setFetchError] = useState(false)

  const handleFetch = (error, message) => {
    setIsFetching(true)
    setFetchError(error)
    setFetchMessage(message)
    setTimeout(() => {
      setIsFetching(false)
    }, 3000)
  }

  const createWorkflow = () => {
    setIsLoading(true)
    let data = {
      emailClient,
      name,
      steps,
      status: 'ACTIVE',
    }

    const url = `${process.env.REACT_APP_BASEURL}/workflows`
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(data),
    }
    fetch(url, options)
      .then((res) => res.json())
      .then((res) => {
        if (!res.success) {
          if (res.data && typeof res.data === 'string') {
            handleFetch(true, res.data)
          }
          setIsLoading(false)
        } else {
          navigate('/case-workflows/all')
          handleFetch(false, res.message)
          setIsLoading(false)
        }
      })
      .catch((error) => {
        console.error('[CREATE WORKFLOW ERROR] --> ', error)
        setIsLoading(false)
        handleFetch(true, error.message ? error.message : 'Workflow creation error')
      })
  }

  const updateWorkflow = () => {
    setIsLoading(true)
    let data = {
      emailClient,
      name,
      steps,
      status: 'ACTIVE',
    }

    const urlRisk = `${process.env.REACT_APP_BASEURL}/workflows/${id}`
    const options = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(data),
    }
    fetch(urlRisk, options)
      .then((res) => res.json())
      .then((res) => {
        if (!res.success) {
          if (res.data && typeof res.data === 'string') {
            handleFetch(true, res.data)
          }
          setIsLoading(false)
        } else {
          handleFetch(false, res.message)
          setTimeout(() => {
            setIsLoading(false)
          }, 2000)
          navigate('/case-workflows/all')
        }
      })
      .catch((error) => {
        console.error('[CREATE RISK MATRIX ERROR] --> ', error)
        setIsLoading(false)
        handleFetch(true, error.message ? error.message : 'Risk Workflow creation error')
      })
  }

  useEffect(() => {
    if (id) {
      const workf = workflows.workflows?.data?.find((wrkf) => wrkf.id === id)

      if (workf) {
        setWorkflow(workf)
        setName(workf?.name)
      }
    }
  }, [id])

  return (
    <Box className="component-wrapper">
      {isFetching && <AlertFetchSpinner message={fetchMessage} error={fetchError} />}

      <Box>
        <Box className="component-title">
          <Typography variant="h2">
            {id ? t('common:edit') : t('common:create')} {t('common:workflow')}
          </Typography>
        </Box>
        <Typography variant="subtitle3">
          {/* Loren ipsum dolor sit amet Loren ipsum dolor sit amet Loren  */}
        </Typography>
      </Box>

      <Paper elevation={0} variant="rootOutlined" sx={{ padding: '1rem', height: '100%' }}>
        <Box className="workflow-manual-wrapper">
          <Box className="matrix-manual-input">
            <Box className="required">
              <InputLabel>{t('common:name')}</InputLabel>
              <Typography variant="subtitle3">({t('common:required')})</Typography>
            </Box>
            <TextField
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              placeholder={`${t('common:insert')} ${t('common:name')}`}
            />
          </Box>

          <ReactFlowProvider>
            <CaseFlow setSteps={setSteps} steps={id ? workflow.steps : steps} isEdit={id ? true : false} />
          </ReactFlowProvider>
        </Box>
      </Paper>

      <Box className="matrix-filter-buttons">
        <Button onClick={() => navigate('/case-workflows/all')} size="small" variant="outlinedGrey">
          {t('common:cancel')}
        </Button>

        <Button variant="contained" disabled={name === ''} onClick={() => (id ? updateWorkflow() : createWorkflow())}>
          {t('common:save')}
        </Button>
      </Box>
    </Box>
  )
}

export default WorkflowCreation

import { buildTimeStringFromTimestamp } from '../../time/timeHelper'

export const roleAdminDataMapperForPDF = (data) => {
  const header = ['Role Name', 'Description', 'Status', 'Created At', 'Updated At', 'Services']
  const parsedData = data.map((role) => {
    return [
      role.roleName,
      role.description,
      role.status,
      buildTimeStringFromTimestamp(role.createdAt),
      buildTimeStringFromTimestamp(role.updatedAt),
      role.services.map((service) => `${service.name}`),
    ]
  })

  return {
    columns: header,
    body: parsedData,
  }
}

export const roleAdminDataMapper = (data) => {
  return data.map((role) => {
    return {
      RoleName: role.roleName,
      Description: role.description,
      Status: role.status,
      CreatedAt: buildTimeStringFromTimestamp(role.createdAt),
      UpdatedAt: buildTimeStringFromTimestamp(role.updatedAt),
      Services: role.services.map((service) => `${service.name}`),
    }
  })
}
